import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { accountManagementClient } from 'src/apollo-clients'

import { User } from 'src/types/User'
import axiosBaseQuery from './axiosBaseQuery'
import graphQLBaseQuery from './graphQLBaseQuery'
import { Tags } from './types'
import { BaseAccount } from "@120wateraudit/envirio-components/dist/models";
import { PagedModel } from 'src/types/PagedModel'

const ONE_HOUR = 60 * 60

type UpdateRequest = Pick<User, 'firstName' | 'lastName'>

interface PasswordChangeRequest {
  currentPassword: string
  password: string
  passwordConfirm: string
}

interface AccountsRequest {
  pageNumber: number
  pageSize: number
  filter?: string
}

export const accountManagementApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: '/platform/account-management/rest',
    skipAccount: true
  }),
  reducerPath: 'accountManagementApi',
  tagTypes: [Tags.Users],
  endpoints: builder => ({
    getCurrentUser: builder.query<User, void>({
      keepUnusedDataFor: ONE_HOUR,
      providesTags: [{ id: 'CURRENT', type: Tags.Users }],
      query: () => ({ method: 'get', url: '/currentuser/@me' })
    }),
    getCurrentUserAccounts: builder.query<PagedModel<BaseAccount>, AccountsRequest>({
      providesTags: [{ id: 'CURRENT', type: Tags.Users }],
      query: ({ pageNumber, pageSize, filter }) => ({
        method: 'get',
        url: `/currentuser/accounts?pageNumber=${pageNumber}&pageSize=${pageSize}&type=2&filter=${filter}`
      })
    }),
    setDefaultAccount: builder.mutation<User, number>({
      invalidatesTags: [{ id: 'CURRENT', type: Tags.Users }],
      query: accountId => ({
        data: { accountId },
        method: 'put',
        url: '/currentuser/defaultaccount'
      })
    }),
    updateCurrentUser: builder.mutation<User, UpdateRequest>({
      invalidatesTags: [{ id: 'CURRENT', type: Tags.Users }],
      query: data => ({ data, method: 'put', url: '/currentuser/profile' })
    }),
    updatePassword: builder.mutation<void, PasswordChangeRequest>({
      invalidatesTags: [{ id: 'CURRENT', type: Tags.Users }],
      query: data => ({ data, method: 'put', url: '/currentuser/password' })
    })
  })
})

export const {
  useLazyGetCurrentUserQuery,
  useGetCurrentUserAccountsQuery,
  useSetDefaultAccountMutation,
  useUpdateCurrentUserMutation,
  useUpdatePasswordMutation
} = accountManagementApi

export const accountManagementGQL = createApi({
  baseQuery: graphQLBaseQuery({ client: accountManagementClient }),
  reducerPath: 'accountManagementGQL',
  tagTypes: [Tags.Users, Tags.Accounts],
  endpoints: () => ({})
})

export default accountManagementApi
