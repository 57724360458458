import React, { FC, memo } from 'react'
import styled from 'styled-components'
import { DetectionCounts } from 'src/containers/Communications/Dashboard/dataAccess'
import { verticalAlignElement, centerElement } from './shared'
import * as numeral from 'numeral'
import { ReactComponent as Exceedance } from 'src/assets/images/Exceedance.svg'
import { ReactComponent as Hazard } from 'src/assets/images/Hazard.svg'
import { ReactComponent as NonDetect } from 'src/assets/images/NonDetect.svg'

export const Detections: FC<Props> = memo(({ detectionCounts }) => (
  <Wrapper>
    <ExceedancesContainer>
      <ExceedanceIcon>
        <Exceedance />
      </ExceedanceIcon>
      <ExceedanceTotal>
        {numeral(detectionCounts.exceedance).format('0,0')}
      </ExceedanceTotal>
      <ExceedanceLabel>Exceedances</ExceedanceLabel>
    </ExceedancesContainer>
    <DetectContainer>
      <DetectIcon>
        <Hazard />
      </DetectIcon>
      <DetectTotal>{numeral(detectionCounts.detect).format('0,0')}</DetectTotal>
      <DetectLabel>Detects</DetectLabel>
    </DetectContainer>
    <NonDetectContainer>
      <NonDetectIcon>
        <NonDetect />
      </NonDetectIcon>
      <NonDetectTotal>
        {numeral(detectionCounts.nonDetect).format('0,0')}
      </NonDetectTotal>
      <NonDetectLabel>Non-Detects</NonDetectLabel>
    </NonDetectContainer>
  </Wrapper>
))

interface Props {
  detectionCounts: DetectionCounts
}

const Wrapper = styled.div`
  grid-area: Detections;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 48px 1fr 1fr;
  grid-template-areas: 'Exceedances Detects NonDetects' 'Exceedances Detects NonDetects' 'Exceedances Detects NonDetects';
  align-items: center;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1.5fr 1fr;
  height: 50px;
`

const ExceedancesContainer = styled(Container)`
  grid-area: Exceedances;
  grid-template-areas: 'ExceedanceIcon ExceedanceTotal' 'ExceedanceIcon ExceedanceLabel';
`

const DetectContainer = styled(Container)`
  grid-area: Detects;
  grid-template-areas: 'DetectIcon DetectTotal' 'DetectIcon DetectLabel';
`

const NonDetectContainer = styled(Container)`
  grid-area: NonDetects;
  grid-template-areas: 'NonDetectIcon NonDetectTotal' 'NonDetectIcon NonDetectLabel';
`

const Total = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: left;
  padding-left: 1rem;
  justify-content: flex-start;
  ${verticalAlignElement}
`

const ExceedanceTotal = styled(Total)`
  grid-area: ExceedanceTotal;
  color: #ef0900;
`

const DetectTotal = styled(Total)`
  grid-area: DetectTotal;
  color: #ffb000;
`

const NonDetectTotal = styled(Total)`
  grid-area: NonDetectTotal;
  color: #00B5E1;
`

const Label = styled.div`
  padding-left: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const ExceedanceLabel = styled(Label)`
  grid-area: ExceedanceLabel;
`

const DetectLabel = styled(Label)`
  grid-area: DetectLabel;
`

const NonDetectLabel = styled(Label)`
  grid-area: NonDetectLabel;
`

export const IconBackground = styled.div`
  height: 50px;
  width: 50px;
  margin: auto;
  border-radius: 100%;
  ${centerElement}
`

const ExceedanceIcon = styled(IconBackground)`
  grid-area: ExceedanceIcon;
  background: #fce6e5;
`

const DetectIcon = styled(IconBackground)`
  grid-area: DetectIcon;
  background: #fef7e5;
`

const NonDetectIcon = styled(IconBackground)`
  grid-area: NonDetectIcon;
  background: #ECF5F9;
`
