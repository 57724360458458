import { createContext } from 'react'
import { ViewType } from 'src/types/SavedView'

import { noop } from 'src/utils/noop'
import {
  ActiveFilters,
  Column,
  FilterDefinition,
  OnFilterChangeFunc,
  Sort,
  ViewPayload
} from './types'

interface TableContextState {
  softwarePackageName?: string
  activeFilters?: ActiveFilters
  activeViewName?: string
  allowHiddenColumns?: boolean
  // Using `any` here because we don't care about the data a column uses.
  // We just want to know the name and if we can hide it.
  columns?: Column<any>[]
  filters?: FilterDefinition[]
  hiddenColumns?: string[]
  onFilterChanged: OnFilterChangeFunc
  orderable: boolean
  savedViewType?: ViewType
  searchTerm?: string
  sort?: Sort
  onSearchChanged: (newTerm: string) => void
  onToggleColumnVisibility: (key: string, visible: boolean) => void
  setColumns?: (columns: Column[]) => void
  setView?: (view: ViewPayload) => void
}

export const TableContext = createContext<TableContextState>({
  allowHiddenColumns: false,
  onFilterChanged: noop,
  onSearchChanged: noop,
  onToggleColumnVisibility: noop,
  orderable: false,
  setColumns: noop
})

export default TableContext
