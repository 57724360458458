import { createApi } from '@reduxjs/toolkit/dist/query/react'

import { buildParameters } from 'src/services'
import { Attachment } from 'src/types/Attachment'
import axiosBaseQuery from './axiosBaseQuery'
import { PaginatedResponseV2, SearchParams, Tags } from './types'

interface CreateEntitiesParams {
  entityIds?: string
  images: File[]
  relatedEntityType:
  | 'inventory'
  | 'locations'
  | 'pwsSamples'
  | 'assets'
  | 'submissionTemplates'
}

interface CreateParams {
  images: File[]
  relatedEntityId: number
  relatedEntityType:
  | 'inventory'
  | 'locations'
  | 'pwsSamples'
  | 'assets'
  | 'submissionTemplates'
  | 'submissionsCommunicationsCertificates'
  | 'submissionsCommunicationsLetters'
}

interface GetParams extends SearchParams {
  includeChildren?: boolean
  relatedEntityId?: number
  relatedEntityType?:
  | 'inventory'
  | 'locations'
  | 'pwsSamples'
  | 'assets'
  | 'submissionTemplates'
  | 'externalSubmissions'
}

interface GetAssetAttachmentParams extends SearchParams {
  assetId: string
  includeChildren?: boolean;
}

interface RemoveAssociationParams {
  attachmentId: number
  relatedEntityId: number
  relatedEntityType:
  | 'inventory'
  | 'locations'
  | 'pwsSamples'
  | 'assets'
  | 'submissionTemplates'
}

export const attachmentsApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: `/platform/attachment`,
    skipAccount: true
  }),
  reducerPath: 'attachmentsApi',
  tagTypes: [Tags.Attachments],
  endpoints: builder => ({
    createAttachments: builder.mutation<Attachment[], CreateParams>({
      invalidatesTags: [Tags.Attachments],
      query: ({ images, relatedEntityId, relatedEntityType }) => {
        const formData = new FormData()
        images.forEach(image => formData.append('files', image))
        return {
          data: formData,
          method: 'post',
          url: `/${relatedEntityType}/${relatedEntityId}`
        }
      }
    }),
    getAssetAttachments: builder.query<
      PaginatedResponseV2<Attachment>,
      GetAssetAttachmentParams
    >({
      providesTags: [Tags.Attachments],
      query: ({
        assetId,
        activeFilters,
        page,
        pageSize,
        searchTerm,
        sort
      }) => {
        const params = buildParameters(
          {
            activeFilters,
            page,
            pageSize,
            searchTerm,
            sort
          },
        )
        return {
          method: 'get',
          url: `/asset-attachments/${assetId}?${params}`
        }
      }
    }),
    getAttachmentsByType: builder.query<
      PaginatedResponseV2<Attachment>,
      GetParams
    >({
      providesTags: [Tags.Attachments],
      query: ({
        activeFilters,
        includeChildren,
        page,
        pageSize,
        relatedEntityId,
        relatedEntityType,
        searchTerm,
        sort
      }) => {
        const params = buildParameters(
          {
            activeFilters,
            page,
            pageSize,
            searchTerm,
            sort
          },
          true
        )
        params.set('includeChildren', includeChildren ? 'true' : 'false')
        return {
          method: 'get',
          url: `/${relatedEntityType}/${relatedEntityId}?${params}`
        }
      }
    }),
    removeAttachmentAssociation: builder.mutation<
      void,
      RemoveAssociationParams
    >({
      invalidatesTags: [Tags.Attachments],
      query: ({ attachmentId, relatedEntityId, relatedEntityType }) => ({
        method: 'delete',
        url: `/${attachmentId}/${relatedEntityType}/${relatedEntityId}`
      })
    }),
    uploadAttachmentsToEntities: builder.mutation<
      Attachment[],
      CreateEntitiesParams
    >({
      invalidatesTags: [Tags.Attachments],
      query: ({ entityIds, images, relatedEntityType }) => {
        const formData = new FormData()

        images.forEach(image => formData.append('files', image))
        entityIds && formData.append(relatedEntityType, entityIds)

        return {
          data: formData,
          method: 'post',
          url: ``
        }
      }
    })
  })
})

export const {
  useCreateAttachmentsMutation,
  useGetAssetAttachmentsQuery,
  useGetAttachmentsByTypeQuery,
  useLazyGetAttachmentsByTypeQuery,
  useRemoveAttachmentAssociationMutation,
  useUploadAttachmentsToEntitiesMutation
} = attachmentsApi
