import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

import { ValidFilterValue } from '../types'
import { FilterInput, FilterLabel } from './shared'
import RemoveFilterButton from './RemoveFilterButton'

type OnChangeFunc = (
  value?: ValidFilterValue,
  operator?: string,
  value2?: null | ValidFilterValue
) => void

const OPERATORS = [
  { text: 'Greater than or equal to', value: 'greaterThanOrEqualTo' },
  { text: 'Less than or equal to', value: 'lessThanOrEqualTo' },
  { text: 'Between', value: 'between' }
]

const YEAR_OPERATORS = [
  { text: 'In', value: 'equals' },
  { text: 'After', value: 'greaterThanOrEqualTo' },
  { text: 'Before', value: 'lessThanOrEqualTo' },
  { text: 'Between', value: 'between' }
]

type DefaultValueGenerator = (
  value?: ValidFilterValue,
  value2?: ValidFilterValue
) => {
  operator: string
  value: ValidFilterValue
  value2?: null | ValidFilterValue
}

const OPERATOR_DEFAULTS: Record<string, DefaultValueGenerator> = {
  between: (value = 0, value2) => ({
    operator: 'between',
    value,
    value2
  }),
  greaterThanOrEqualTo: (value = 0) => ({
    operator: 'greaterThanOrEqualTo',
    value,
    value2: null
  }),
  lessThanOrEqualTo: (value = 0) => ({
    operator: 'lessThanOrEqualTo',
    value,
    value2: null
  }),
  equals: (value = 0) => ({
    operator: 'equals',
    value,
    value2: null
  })
}

interface Props {
  className?: string
  label: string
  onChange: OnChangeFunc
  operator?: string
  removeFilter?: () => void
  value?: ValidFilterValue
  value2?: ValidFilterValue
  isYearFilter?: boolean
}

const OperatorDropdown = ({
  onChange,
  operator,
  value,
  value2,
  isYearFilter
}: Omit<Props, 'label'>) => {
  const onOperatorChange = (_, data) => {
    const {
      operator,
      value: newValue,
      value2: newValue2
    } = OPERATOR_DEFAULTS[data.value](value, value2)
    onChange(newValue, operator, newValue2)
  }
  if (!operator) onOperatorChange(undefined, {value: 'equals'})
  const operatorToUse = isYearFilter ?  YEAR_OPERATORS : OPERATORS
  return (
    <Dropdown
      direction="left"
      onChange={onOperatorChange}
      options={operatorToUse}
      scrolling
      value={operator}
    />
  )
}

const NumberFilter = ({
  className,
  label,
  onChange,
  operator,
  removeFilter,
  value,
  value2,
  isYearFilter
}: Props): JSX.Element => {
  const isRange = operator === 'between'
  const onEndRangeChange = (newEnd?: ValidFilterValue) =>
    onChange(value, operator, newEnd)
  return (
    <FilterLabel>
      <span>{label}</span>
      <OperatorDropdown
        onChange={onChange}
        operator={operator}
        value={value}
        value2={value2}
        isYearFilter={isYearFilter}
      />
      <Input
        isEnabled
        onFilterChanged={onChange}
        showIcon={!isRange}
        value={value}
        numberOnly={true}
      />
      {isRange && (
        <>
          <span> to </span>
          <Input isEnabled onFilterChanged={onEndRangeChange} value={value2} numberOnly={true}/>
        </>
      )}
      <StyledRemoveFilterButton
        className={className}
        removeFilter={removeFilter}
      />
    </FilterLabel>
  )
}

const Input = styled(FilterInput)`
  & > input {
    max-width: 70px !important;
  }
`

const StyledRemoveFilterButton = styled(RemoveFilterButton)`
  &&& {
    margin-left: 4.57px;
  }
`

export default NumberFilter
