import React from 'react'
import { Checkbox } from 'semantic-ui-react'

import {
  ActiveFilters,
  CustomFieldFilterDefinition,
  FilterType,
  FilterableProps,
  ValidFilterValue
} from '../types'
import { FilterOption } from '../utils'
import DatePickerFilter from './DateFilter'
import ListSelectionFilter from './ListSelectionFilter'
import NumberFilter from './NumberFilter'
import TextMatchFilter from './TextMatchFilter'
import { useCustomFieldFilter } from './hooks'

interface FilterChoiceProps {
  activeFilters: ActiveFilters
  filter: CustomFieldFilterDefinition
  onFilterChanged?: FilterableProps['onFilterChanged']
}

export const CustomFieldFilterChoice = ({
  activeFilters,
  filter,
  onFilterChanged
}: FilterChoiceProps): JSX.Element => {
  const { isActive, onToggleFilter } = useCustomFieldFilter(
    activeFilters,
    filter,
    onFilterChanged
  )
  return (
    <FilterOption>
      <Checkbox
        checked={isActive}
        label={<label>{filter.label}</label>}
        onChange={(_, { checked }) => onToggleFilter(!!checked)}
      />
    </FilterOption>
  )
}

interface FilterProps {
  activeFilters: ActiveFilters
  filter: CustomFieldFilterDefinition
  onFilterChanged: FilterableProps['onFilterChanged']
  operator?: string
  value?: ValidFilterValue
  value2?: ValidFilterValue
}

export const CustomFieldFilter = ({
  activeFilters,
  filter,
  onFilterChanged,
  operator,
  value,
  value2
}: FilterProps): null | JSX.Element => {
  const { onChangeValue, removeFilter } = useCustomFieldFilter(
    activeFilters,
    filter,
    onFilterChanged
  )

  if (filter.type === FilterType.ListSelection) {
    return (
      <ListSelectionFilter
        defaultValue={filter.defaultValue}
        label={filter.label}
        onChange={onChangeValue}
        options={filter.options || []}
        value={value}
        removeFilter={removeFilter}
      />
    )
  }

  if (filter.type === FilterType.TextMatch) {
    return (
      <TextMatchFilter
        label={filter.label}
        onChange={onChangeValue}
        removeFilter={removeFilter}
        value={value}
      />
    )
  }

  if (filter.type === FilterType.Number) {
    return (
      <NumberFilter
        label={filter.label}
        onChange={onChangeValue}
        operator={operator}
        removeFilter={removeFilter}
        value={value}
        value2={value2}
      />
    )
  }

  if (
    filter.type === FilterType.DatePicker ||
    filter.type === FilterType.DateTimePicker
  ) {
    return (
      <DatePickerFilter
        label={filter.label}
        onChangeValue={onChangeValue}
        operator={operator}
        removeFilter={removeFilter}
        type={filter.type}
        value={value}
        value2={value2}
      />
    )
  }

  return null
}
