import GenerateCrudSaga from './helpers/CrudSaga'
import actions from '../actions/protocols'
import { fetchProtocols } from '../API'

const saga = GenerateCrudSaga({
  entityName: 'protocol',
  actions,
  fetchCollectionMethod: fetchProtocols,
  pagedCollection: false
})

export default saga
