import { Program } from "src/types/Programs";

interface ReplacementProgramStats {
    hasPreSampling: boolean
    hasPostSampling: boolean
    hasBothSamplingTypes: boolean
    hasEitherSamplingType: boolean
    hasNeitherSamplingType: boolean
    singleSelectedSamplingType?: 'pre' | 'post'
}

export const getReplacementProgramSettingsStats = (program: Program): ReplacementProgramStats => {
    const { replacementProgramSettings } = program
    let replacementProgramStats: ReplacementProgramStats = {
        hasPreSampling: false,
        hasPostSampling: false,
        hasBothSamplingTypes: false,
        hasEitherSamplingType: false,
        hasNeitherSamplingType: true
    }

    if (replacementProgramSettings) {
        const hasPreSampling = replacementProgramSettings.doesPreSampling
        const hasPostSampling = replacementProgramSettings.doesPostSampling
        const hasEitherSamplingType = hasPreSampling || hasPostSampling
        const hasBothSamplingTypes = hasPreSampling && hasPostSampling
        const hasNeitherSamplingType = !hasPreSampling && !hasPostSampling

        replacementProgramStats = {
            hasPreSampling,
            hasPostSampling,
            hasBothSamplingTypes,
            hasEitherSamplingType,
            hasNeitherSamplingType
        }

        if (hasEitherSamplingType) {
            replacementProgramStats = {
                ...replacementProgramStats,
                singleSelectedSamplingType: hasPreSampling ? 'pre' : 'post'
            }
        }
    }

    return replacementProgramStats
}