import { Button, Modal } from '@120wateraudit/envirio-components'
import { useMutation, useQuery } from '@apollo/client'
import React, { FC, memo, useState } from 'react'
import { Helmet } from 'react-helmet'

import { accountManagementClient } from 'src/apollo-clients'
import AppSkeleton from 'src/router/Skeleton'
import styled from 'src/theme'
import {
  ACCEPT_TERMS,
  AcceptTermsData,
  AcceptTermsVariables,
  HAS_ACCEPTED,
  HasAcceptedData,
  HasAcceptedVariables
} from './dataAccess'

const title = 'Terms of Service'

export const ToS: FC<Props> = ({ children, userId }) => {
  const [accepted, setAccepted] = useState<undefined | boolean>(undefined)

  const { loading } = useQuery<HasAcceptedData, HasAcceptedVariables>(
    HAS_ACCEPTED,
    {
      client: accountManagementClient,
      fetchPolicy: 'network-only',
      skip: !userId,
      variables: { userId: userId as number },
      onCompleted: ({ hasAccepted }) => setAccepted(hasAccepted)
    }
  )

  const [handleAccept] = useMutation<AcceptTermsData, AcceptTermsVariables>(
    ACCEPT_TERMS,
    {
      client: accountManagementClient,
      refetchQueries: [{ query: HAS_ACCEPTED, variables: { userId } }],
      variables: { userId: userId as number },
      onCompleted: ({ acceptTerms }) => setAccepted(acceptTerms)
    }
  )

  if (loading) {
    return <AppSkeleton />
  }

  if (accepted) {
    return children
  }

  return <TosModal handleAccept={handleAccept} />
}

interface Props {
  children: any
  userId?: number
}

const TosModal: FC<ModalProps> = memo(({ handleAccept }) => {
  return (
    <Modal
      content={
        <Wrapper>
          <Helmet>
            <title>Terms of Service - 120Water PWS</title>
          </Helmet>
          <ModalTextCenter>
            Please read and accept 120Water's{' '}
            <Link
              href="https://120water.com/terms-of-use/"
              target="_blank"
              rel="noopener noreferrer">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              href="https://120water.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer">
              Privacy Policy
            </Link>
            .
          </ModalTextCenter>
          {
            <Button
              variant="primary"
              onClick={handleAccept}
              style={{ marginTop: '3rem', borderRadius: '5px' }}>
              Accept
            </Button>
          }
        </Wrapper>
      }
      header={title}
      isOpen={true}
      toggle={() => undefined}
    />
  )
})

interface ModalProps {
  handleAccept: () => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ModalTextCenter = styled.p`
  padding-top: 2rem;
  font-size: 1.2rem;
  text-align: center;
`

export const Link = styled.a`
  text-decoration: underline;
  color: ${props => props.theme.colors.black};
  :hover {
    color: ${props => props.theme.colors.black};
  }
`
