import gql from 'graphql-tag'
import {
  CommunicationTrigger,
  DynamicFields,
  Communication,
  CommunicationStatus
} from 'src/types/Communications'
import { CommunicationType } from '@120wateraudit/communications-types'

export const COMMUNICATION_TEMPLATE = gql`
  query communicationTemplate($accountId: Int!, $templateId: Int!) {
    communicationTemplate(accountId: $accountId, templateId: $templateId) {
      template {
        id
        accountId
        communicationType
        name
        description
        triggerType
        triggerCondition
        trigger {
          accountType
          type
          conditionLabel
          conditions {
            code
            label
          }
          customFields {
            entities
            dataTypeExamples {
              key
              type
              example
            }
          }
          requireEventId
          toolbarItems {
            icon
            tooltip
            label
            insertType
            insertText
          }
        }
        dynamicFields {
          name
          key
          type
          example
          noValueText
        }
      }
      content
    }
  }
`
export interface CommunicationTemplate {
  id: number
  communicationType: CommunicationType
  templateName: string
  name: string
  description?: string
  triggerType?: string
  triggerCondition?: string
  content: string
  trigger?: CommunicationTrigger
  dynamicFields?: DynamicFields[]
}

export interface CommunicationTemplateData {
  communicationTemplate: {
    template: CommunicationTemplate
    content: string
  }
}

export interface CommunicationTemplateVariables {
  accountId: number
  templateId: number
}

export const COMMUNICATION = gql`
  query communication($accountId: Int!, $communicationId: Int!) {
    communication(accountId: $accountId, communicationId: $communicationId) {
      communication {
        id
        accountId
        communicationType
        name
        status
        templateId
        eventId
        triggerType
        triggerCondition
        trigger {
          accountType
          type
          conditionLabel
          conditions {
            code
            label
          }
          customFields {
            entities
            dataTypeExamples {
              key
              type
              example
            }
          }
          requireEventId
          toolbarItems {
            icon
            tooltip
            label
            insertType
            insertText
          }
        }
        dynamicFields {
          name
          key
          type
          example
          noValueText
        }
      }
      content
    }
  }
`

export interface CommunicationData {
  communication: {
    communication: Communication
    content: string
  }
}

export interface CommunicationVariables {
  accountId: number
  communicationId: number
}

export const CREATE_OR_UPDATE_COMMUNICATION = gql`
  mutation createOrUpdateCommunication(
    $accountId: Int!
    $communication: CommunicationInput!
    $content: String!
  ) {
    createOrUpdateCommunication(
      accountId: $accountId
      communication: $communication
      content: $content
    ) {
      communication {
        id
        accountId
        communicationType
        name
        status
        templateId
        eventId
        triggerType
        triggerCondition
        font
        trigger {
          accountType
          type
          conditionLabel
          conditions {
            code
            label
          }
          customFields {
            entities
            dataTypeExamples {
              key
              type
              example
            }
          }
          requireEventId
          toolbarItems {
            icon
            tooltip
            label
            insertType
            insertText
          }
        }
        dynamicFields {
          name
          key
          type
          example
          noValueText
        }
      }
      content
    }
  }
`

export interface CreateOrUpdateCommunicationData {
  createOrUpdateCommunication: {
    communication: Communication
    content: string
  }
}

export interface CreateOrUpdateCommunicationVariables {
  accountId: number
  communication: CommunicationInput
  content: string
}

export const PAUSE_AND_EDIT = gql`
  mutation pauseAndEdit($communicationId: Int!) {
    pauseAndEdit(communicationId: $communicationId) {
      status
    }
  }
`

export const COMMUNICATION_TEMPLATES = gql`
  query communicationTemplates($accountId: Int!) {
    communicationTemplates(accountId: $accountId) {
      id
      accountId
      communicationType
      name
      description
      triggerType
      triggerCondition
    }
  }
`

export interface PauseAndEditData {
  pauseAndEdit: Communication
}

export interface PauseAndEditVariables {
  communicationId: number
}

export interface CommunicationInput {
  id?: number
  communicationType: CommunicationType
  name?: string
  status: CommunicationStatus
  templateId: number
  triggerType?: string
  triggerCondition?: string
  eventId?: number
}
