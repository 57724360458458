import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { usePrevious, useToggle } from 'src/hooks'
import CheckCell from '../Cells/CheckCell'
import { SelectionHeaderCell } from '../Header'
import { Column, RowData, RowDataWithId, SelectionMode } from '../types'

export { useTableState } from './useTableState'

const SELECTION_COL_DEFAULTS = {
  headerProps: { collapsing: true },
  key: 'tri_internal_selectionColumn',
  rowProps: { collapsing: true },
  sortable: false
}

type UseColumnOptions = {
  areAllSelected?: boolean
  isSelectAllOn?: boolean
  hiddenColumns: string[]
  hideSelection?: boolean
  selected?: RowDataWithId | RowDataWithId[]
  selectionMode?: SelectionMode
  onSelectAll?(): void
  onSelectionChanged?(selected: RowDataWithId): void
}

export const useHasLoaded = (loading = false) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const previousLoading = usePrevious(loading)
  useEffect(() => {
    if (!hasLoaded && previousLoading && !loading) {
      setHasLoaded(true)
    }
  }, [hasLoaded, setHasLoaded, previousLoading, loading])
  return hasLoaded
}

function useSelectionColumns<T extends RowData>(
  columns: Column<T>[],
  options: UseColumnOptions
) {
  return useMemo(() => {
    const {
      areAllSelected,
      isSelectAllOn,
      hideSelection,
      onSelectAll,
      onSelectionChanged,
      selected,
      selectionMode
    } = options

    //Need to pass boolean here to hide selection or turn it off above

    if (!selectionMode || hideSelection === false) {
      return columns
    }

    const selectionColumn = {
      ...SELECTION_COL_DEFAULTS,
      Header: (
        <SelectionHeaderCell
          checked={!!areAllSelected}
          disabled={isSelectAllOn}
          onSelectAll={onSelectAll}
          selectionMode={selectionMode}
        />
      ),
      accessor: r => {
        return (
          <CheckCell
            disabled={isSelectAllOn}
            checked={
              Array.isArray(selected)
                ? selected.map(s => s.id).includes(r.id)
                : selected?.id === r.id
            }
            id={`check-${r.id}`}
            onCheck={() => {
              if (!onSelectionChanged) return
              onSelectionChanged(r)
            }}
          />
        )
      }
    }
    return [selectionColumn, ...columns]
  }, [columns, options])
}

export function useColumns<T extends RowData>(
  columns: Column<T>[],
  options: UseColumnOptions
) {
  const { hiddenColumns } = options
  const visibleColumns = useMemo(
    () => columns.filter(c => !hiddenColumns.includes(c.key)),
    [hiddenColumns, columns]
  )
  return useSelectionColumns(visibleColumns, options)
}

export function useMultiselection<T extends RowDataWithId>() {
  const [isSelectAllOn, toggleSelectAll] = useToggle(false)

  const [selected, setSelected] = useState<T[]>([])
  const selectedIds = useMemo(() => selected.map(s => s.id), [selected])
  const onSelect = useCallback(
    (newSelection: T) => {
      const copied = [...selected]
      const index = selectedIds.indexOf(newSelection.id)
      if (index > -1) {
        copied.splice(index, 1)
        setSelected(copied)
      } else {
        setSelected([newSelection, ...copied])
      }
    },
    [setSelected, selected, selectedIds]
  )
  const onSelectAll = useCallback(
    (selections: T[], removeFromSelection = false) => {
      if (removeFromSelection) {
        setSelected(differenceBy(selected, selections, 'id'))
      } else {
        setSelected(uniqBy([...selected, ...selections], 'id'))
      }
    },
    [setSelected, selected]
  )
  const onClearSelection = useCallback(() => {
    onSelectAll(selected, true)
  }, [onSelectAll, selected])
  return {
    onClearSelection,
    onSelect,
    onSelectAll,
    toggleSelectAll,
    isSelectAllOn,
    selected,
    selectedIds
  }
}
