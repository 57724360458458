import { call, put, takeLatest } from 'redux-saga/effects'

import { PWSSample } from 'src/types/Sample'
import {
  completeSamples,
  createInvalidatedSample,
  fetchSample,
  fetchSamples,
  updateSample
} from '../API'
import actions, {
  completeSamplesFailure,
  completeSamplesRequest,
  completeSamplesSuccess,
  createInvalidatedSampleFailure,
  createInvalidatedSampleRequest,
  createInvalidatedSampleSuccess
} from '../actions/samples'
import GenerateCrudSaga from './helpers/CrudSaga'

interface CreateSampleOptions {
  payload: {
    accountId: number
    sample: PWSSample
    sampleId: number
    callback?: () => any
  }
  type: string
}

interface CreateSamplesOptions {
  payload: {
    accountId: number
    isSendResultsLetters?: boolean
    sample: PWSSample
    sampleIds: number[]
    callback?: () => any
    completeFailureMessage?: () => string
    errorCallback?: (error: Error) => any
  }
  type: string
}

function* completeSamplesSaga({
  payload: { accountId, callback, errorCallback, sample, sampleIds }
}: CreateSamplesOptions) {
  try {
    yield call(completeSamples, {
      accountId,
      sampleIds
    })

    if (typeof callback === 'function') {
      callback()
    }

    yield put(completeSamplesSuccess({ completedSample: sample }))
  } catch (error) {
    yield put(completeSamplesFailure({ error }))
    if (typeof errorCallback === 'function') {
      errorCallback(error)
    }
  }
}

function* createInvalidatedSampleSaga({
  payload: { accountId, callback, sample, sampleId }
}: CreateSampleOptions) {
  try {
    yield call(createInvalidatedSample, {
      accountId,
      sampleId,
      sample
    })

    if (typeof callback === 'function') {
      callback()
    }

    yield put(createInvalidatedSampleSuccess({ invalidatedSample: sample }))
  } catch (error) {
    yield put(createInvalidatedSampleFailure({ error }))
  }
}

const saga = GenerateCrudSaga({
  actions,
  entityName: 'sample',
  fetchCollectionMethod: fetchSamples,
  fetchEntityMethod: fetchSample,
  updateEntityMethod: updateSample
})

export default saga

export function* sampleActionsSaga() {
  yield takeLatest(completeSamplesRequest.toString(), completeSamplesSaga)
  yield takeLatest(
    createInvalidatedSampleRequest.toString(),
    createInvalidatedSampleSaga
  )
}
