import actionCreatorFactory from 'typescript-fsa'

import { PWSSample } from 'src/types/Sample'
import GenerateCrudActions, { ICrudActions } from './helpers/CrudActions'

const action = actionCreatorFactory()

const actions: ICrudActions = GenerateCrudActions({
  collectionName: 'samples',
  entityName: 'sample'
})

export default actions

export const createInvalidatedSampleRequest = action<{
  accountId: number
  sampleId: number
  sample: any
  callback?: any
}>('CREATE_INVALIDATED_SAMPLE_REQUEST')
export const createInvalidatedSampleSuccess = action<{
  invalidatedSample: PWSSample
}>('CREATE_INVALIDATED_SAMPLE_SUCCESS')
export const createInvalidatedSampleFailure = action<{ error: string }>(
  'CREATE_INVALIDATED_SAMPLE_FAILURE'
)

export const completeSamplesRequest = action<{
  accountId: number
  sampleIds: any
  sample: any
  callback?: any
  errorCallback?: any
  completeFailureMessage?: any
}>('CREATE_COMPLETED_SAMPLES_REQUEST')

export const completeSamplesSuccess = action<{ completedSample: PWSSample }>(
  'CREATE_COMPLETED_SAMPLES_SUCCESS'
)

export const completeSamplesFailure = action<{ error: string }>(
  'CREATE_COMPLETED_SAMPLES_FAILURE'
)
