import React from 'react'

import { Step } from 'src/components/Forms/Wizard'
import SummaryPart1 from '../InventorySummarySteps/SummaryPart1'
import EpaPublicAccessibilityDoc from '../FederalWizard/EpaPublicAccessibilityDoc'
import EpaMethodsPart1 from '../FederalWizard/InventoryMethods/EpaMethodsPart1'
import EpaMethodsPart2 from '../FederalWizard/InventoryMethods/EpaMethodsPart2'
import EpaMethodsPart3 from '../FederalWizard/InventoryMethods/EpaMethodsPart3'
import { textValidationCheck } from 'src/modules/ServiceLines/dataAccess'
import {
  validSecondarySamplingChoices,
  createSamplingChoicesArray
} from './dataAccess'

interface Values {
  //Historical Records Review Page Values
  inventoryMethodsPart1PreviousMaterialsEvaluation?: string
  inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes?: string
  inventoryMethodsPart1WaterSystemRecords?: string
  inventoryMethodsPart1DistributionSystemInspectionsAndRecords?: string
  inventoryMethodsPart1AdditionalStateRecords?: string
  inventoryMethodsPart1otherRecords?: string
  //Identify Service Line Page Values
  inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices?: string[]
  inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation?: string
  //Service Line Investigations Page Values
  inventoryMethodsPart3Q1ServiceLineInvestigationChoices?: string[]
  inventoryMethodsPart3Q2PredictiveModelingExplanation?: string
  inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation?: string
  //General Information Page Values
  inventorySummarypart1Question1initialInventoryOrUpdate?: string
  inventorySummarypart1Question2aServiceLineOwnership?: string
  inventorySummarypart1Question2aServiceLineOwnershipOtherExplanation?: string
  inventorySummarypart1Question2bServiceLineOwnership?: string
  inventorySummarypart1Question2bServiceLineOwnershipOtherExplanation?: string
  inventorySummarypart1Q3aInstallationExplanation?: string
  inventorySummarypart1Q3bBanningExplanation?: string
  inventorySummarypart1Q4LeadGoosenecks?: string
  inventorySummarypart1Q5ConfidenceLevel?: string
}

//Todo It would be good to condense/refactor any relevant validation code in this file and the formValidation.ts

export function validateHistoricalRecordsReview(values: Values) {
  if (
    textValidationCheck(
      values.inventoryMethodsPart1PreviousMaterialsEvaluation
    ) ||
    textValidationCheck(
      values.inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes
    ) ||
    textValidationCheck(values.inventoryMethodsPart1WaterSystemRecords) ||
    textValidationCheck(
      values.inventoryMethodsPart1DistributionSystemInspectionsAndRecords
    ) ||
    textValidationCheck(values.inventoryMethodsPart1AdditionalStateRecords) ||
    textValidationCheck(values.inventoryMethodsPart1otherRecords)
  ) {
    return false
  }

  return true
}

export function validateIdentifyServiceLineCheckboxes(values: any) {
  //This checks if the user has selected at least one checkbox.
  return (
    values.inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices &&
    values.inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices
      .length > 0
  )
}

export function validateIdentifyServiceLineMaterials(values: Values) {
  const checkBoxCheck = validateIdentifyServiceLineCheckboxes(values)

  if (!checkBoxCheck) {
    return false
  }

  if (
    values.inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices &&
    values.inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices.includes(
      'Other'
    ) &&
    textValidationCheck(
      values.inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation
    )
  ) {
    return false
  }

  return true
}

export function validateServiceLineInvestigations(values: any) {
  const samplingChoicesArray = createSamplingChoicesArray(values)

  const hasNonSampleChoice = validSecondarySamplingChoices.some(o =>
    values?.inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(o)
  )

  if (
    !values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices ||
    (values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
      values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices.length ===
        0)
  ) {
    return false
  }

  if (samplingChoicesArray.length > 1) {
    return false
  }

  if (
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices.includes(
      'Predictive Modeling'
    ) &&
    textValidationCheck(
      values.inventoryMethodsPart3Q2PredictiveModelingExplanation
    )
  ) {
    return false
  }

  if (
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices.includes(
      'Other'
    ) &&
    textValidationCheck(
      values.inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation
    )
  ) {
    return false
  }

  if (
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
    samplingChoicesArray.length === 1 &&
    !hasNonSampleChoice
  ) {
    return false
  }

  return true
}

export function validateGeneralInformation(values: Values) {
  if (
    textValidationCheck(
      values.inventorySummarypart1Question1initialInventoryOrUpdate
    ) ||
    textValidationCheck(
      values.inventorySummarypart1Question2aServiceLineOwnership
    ) ||
    textValidationCheck(
      values.inventorySummarypart1Question2bServiceLineOwnership
    ) ||
    textValidationCheck(
      values.inventorySummarypart1Q3aInstallationExplanation
    ) ||
    textValidationCheck(values.inventorySummarypart1Q3bBanningExplanation) ||
    textValidationCheck(values.inventorySummarypart1Q4LeadGoosenecks) ||
    textValidationCheck(values.inventorySummarypart1Q5ConfidenceLevel)
  ) {
    return false
  }

  if (
    values.inventorySummarypart1Question2aServiceLineOwnership === 'Other' &&
    textValidationCheck(
      values.inventorySummarypart1Question2aServiceLineOwnershipOtherExplanation
    )
  ) {
    return false
  }

  if (
    values.inventorySummarypart1Question2bServiceLineOwnership === 'Yes' &&
    textValidationCheck(
      values.inventorySummarypart1Question2bServiceLineOwnershipOtherExplanation
    )
  ) {
    return false
  }

  return true
}

export const DELAWARE_STEPS: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Historical Records Review',
    render: () => (
      <EpaMethodsPart1
        questionOneRequired
        questionTwoRequired
        questionThreeRequired
        questionFourRequired
        questionFiveRequired
        questionSixRequired
      />
    ),
    validateStepValues: validateHistoricalRecordsReview
  },
  {
    nextButtonText: 'Next Step',
    title: 'Identifying Service Line Material During Normal Operations',
    render: () => (
      <EpaMethodsPart2 questionOneRequired questionTwoConditionallyRequired />
    ),
    validateStepValues: validateIdentifyServiceLineMaterials
  },
  {
    nextButtonText: 'Next Step',
    title: 'Service Line Investigations',
    render: () => (
      <EpaMethodsPart3
        questionOneRequired
        questionOneAdditionalText="If a Water Quality Sampling method is applicable, please also provide an alternative method used that is not Sampling based."
        questionTwoConditionallyRequired
        questionThreeConditionallyRequired
      />
    ),
    validateStepValues: validateServiceLineInvestigations
  },
  {
    nextButtonText: 'Next Step',
    title: 'General Information',
    render: () => (
      <SummaryPart1
        questionOneRequired
        questionTwoRequired
        questionFourRequired
        questionSixRequired
        questionSevenRequired
        questionEightRequired
        questionNineRequired
        questionThreeConditionallyRequired
        questionFiveConditionallyRequired
      />
    ),
    validateStepValues: validateGeneralInformation
  },
  {
    nextButtonText: 'Next Step',
    title: 'Public Accessibility Doc',
    render: () => <EpaPublicAccessibilityDoc />
  }
]
