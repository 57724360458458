import {
  Account,
  AccountType,
  PWSAccount
} from '@120wateraudit/envirio-components/dist/models'
import { AQUA_BLUE } from '@120wateraudit/waterworks'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import {
  Search as SUIRSearch,
  SearchProps,
  SearchResultProps
} from 'semantic-ui-react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'

import { useToggle } from 'src/hooks'
import { useAccount } from 'src/router/UserProvider'
import {
  useSetDefaultAccountMutation,
  useGetCurrentUserAccountsQuery
} from 'src/services'
import { LocalStorageItem, setItem } from 'src/utils/localStorage'
import { useAuth0 } from '@auth0/auth0-react'

interface AccountResult {
  id: number
  isRecent: boolean
  pwsid?: string
  title: string
}

const setRecentAccounts = (account: PWSAccount) => {
  let recentAccounts: AccountResult[] = []
  const recentAccount: AccountResult = {
    id: account.id,
    isRecent: true,
    pwsid: account.pwsid,
    title: account.name
  }
  const localStorageRecentAccounts = localStorage.getItem('recentAccounts')
  if (!localStorageRecentAccounts) {
    recentAccounts = recentAccounts.concat(recentAccount)
    localStorage.setItem('recentAccounts', JSON.stringify(recentAccounts))
  } else {
    let recentAccounts: AccountResult[] = JSON.parse(
      localStorageRecentAccounts || '[]'
    )

    if (!recentAccounts.some(item => item.id === account.id)) {
      recentAccounts = [recentAccount, ...recentAccounts].slice(0, 6)
      localStorage.setItem('recentAccounts', JSON.stringify(recentAccounts))
    }
  }
}

// Don't show non-PWS accounts, disabled accounts, or the current account.
const shouldShowAccount = (account: Account, currentAccountId: number) => {
  return (
    account.accountType === AccountType.PWS &&
    account.id !== currentAccountId &&
    !account.isAccountDisabled
  )
}

const useAccounts = () => {
  const currentAccount = useAccount()
  setRecentAccounts(currentAccount)
  const [searchTerm, onSearchChange] = useState(currentAccount.name)
  const onSearch = useCallback(
    (_, data) => onSearchChange(data?.value ?? ''),
    [onSearchChange]
  )

  const useAccountData = () => {
    const { data } = useGetCurrentUserAccountsQuery({
      filter: searchTerm === currentAccount.name ? '' : searchTerm,
      pageNumber: 0,
      pageSize: 25
    })
    return data?.items || []
  }

  const queryResult = useAccountData() ?? []

  const hasSearched =
    searchTerm !== currentAccount.name &&
    searchTerm !== currentAccount.pwsid &&
    searchTerm.length > 0

  const validAccounts = queryResult.filter(account => {
    if (!shouldShowAccount(account, currentAccount.id)) {
      return false
    }

    if (hasSearched) {
      const loweredName = account.name.toLowerCase()
      const loweredPWSID = account.pwsid?.toLowerCase()
      const loweredSearch = searchTerm.toLowerCase()
      return (
        loweredName.includes(loweredSearch) ||
        loweredPWSID?.includes(loweredSearch)
      )
    }

    return true
  })

  const createRecentAccounts = (): AccountResult[] => {
    if (hasSearched) {
      const accounts = JSON.parse(
        localStorage.getItem('recentAccounts') || '[]'
      ).filter(account => {
        if (account.id === currentAccount.id) {
          return false
        }

        const loweredName = account.title.toLowerCase()
        const loweredPWSID = account.pwsid?.toLowerCase()
        const loweredSearch = searchTerm.toLowerCase()
        return (
          loweredName.includes(loweredSearch) ||
          loweredPWSID?.includes(loweredSearch)
        )
      })

      return accounts
    }

    return JSON.parse(localStorage.getItem('recentAccounts') || '[]')
  }
  const recentAccounts = createRecentAccounts()

  const filteredValidAccounts = validAccounts.filter(
    account =>
      !recentAccounts.find(recentAccount => recentAccount.id === account.id)
  )
  const userAccounts: AccountResult[] = filteredValidAccounts.map(account => ({
    id: account.id,
    isRecent: false,
    pwsid: account.pwsid,
    title: account.name
  }))

  const results: AccountResult[] = recentAccounts
    .concat(userAccounts)
    .filter(account => account.id !== currentAccount.id)
    .map(account => ({
      className: account.isRecent ? 'recent' : '',
      description: account.pwsid,
      id: account.id,
      isRecent: account.isRecent,
      title: account.title
    }))

  return {
    currentAccount,
    onSearch,
    results,
    searchTerm
  }
}

const reload = () => {
  if (caches) {
    caches.keys().then(names => {
      for (const name of names) {
        caches.delete(name)
      }
    })
  }

  window.location.replace(window.location.origin)
}

const useAccountSwitch = (
  searchTerm: string,
  onSearch: (event: SyntheticEvent, data: SearchProps) => void,
  currentAccount: PWSAccount
) => {
  const { getAccessTokenSilently } = useAuth0()
  const [setAccount] = useSetDefaultAccountMutation()
  const [hasSwitched, toggle] = useToggle(false)
  const onBlur = (e: SyntheticEvent) => {
    if (
      currentAccount.name !== searchTerm &&
      currentAccount.pwsid !== searchTerm &&
      !hasSwitched
    ) {
      onSearch(e, { value: currentAccount.name })
    }
  }

  const onSelectAccount = async (
    event: SyntheticEvent,
    data: { result: { id: number; title: string } }
  ) => {
    toggle()
    onSearch(event, { value: data.result.title })
    await setAccount(data.result.id).unwrap()
    const token = await getAccessTokenSilently({ cacheMode: 'off' })
    setItem(LocalStorageItem.TOKEN, token)
    toggle()
    reload()
  }

  return { hasSwitched, onBlur, onSelectAccount, getAccessTokenSilently }
}

export const resultRenderer = ({
  isRecent,
  title,
  description
}: SearchResultProps) => {
  if (isRecent) {
    return (
      <div className="content">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="title" style={{ marginRight: '10px' }}>
            <FontAwesomeIcon icon={faClockRotateLeft} />
          </div>
          <div className="title">{title}</div>
        </div>
        <div style={{ marginLeft: '23px' }} className="description">
          {description}
        </div>
      </div>
    )
  }

  return (
    <div className="content">
      <div style={{ marginLeft: '23px' }}>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
    </div>
  )
}

const AccountSwitcher = (): JSX.Element => {
  const { currentAccount, onSearch, results, searchTerm } = useAccounts()
  const { hasSwitched, onBlur, onSelectAccount } = useAccountSwitch(
    searchTerm,
    onSearch,
    currentAccount
  )

  return (
    <Search
      disabled={hasSwitched}
      icon="caret down"
      input="search"
      loading={hasSwitched}
      minCharacters={0}
      onBlur={onBlur}
      onFocus={(e: SyntheticEvent) => (e.target as HTMLInputElement).select()}
      onResultSelect={onSelectAccount}
      onSearchChange={onSearch}
      resultRenderer={resultRenderer}
      results={results}
      value={searchTerm}
    />
  )
}

const Search = styled(SUIRSearch)`
  align-self: center;

  & .ui input.prompt {
    border-radius: 4px;
  }
  & .results {
    overflow: scroll;
    overflow-x: hidden;
    max-height: 20rem;
    & .recent .title {
      color: ${AQUA_BLUE} !important;
    }
  }

  .svg-inline--fa {
    font-size: 1em;
  }
` as typeof SUIRSearch

export default AccountSwitcher
