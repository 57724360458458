import { Values } from './types'

function isWholeNumber(n: number) {
  return n - Math.floor(n) === 0
}

const NAME_REGEXP = /^[\w\-()&\s]+$/

export function validateEvent(
  values: Values,
  isReplacementProgram: boolean,
  eventNames: string[],
  type: 'create' | 'edit'
) {
  const errors: Record<string, string> = {}
  const trimmedName = values.name?.trim() ?? ''
  const isCreatemodal = type === 'create'
  if (!trimmedName) {
    errors.name = 'Event Name is required'
  } else if (isCreatemodal && eventNames.includes(trimmedName)) {
    errors.name = 'Event Name cannot already exist in this Program'
  } else if (!NAME_REGEXP.test(trimmedName)) {
    errors.name = 'Event Name includes disallowed characters'
  }

  if (!isReplacementProgram) {
    if (
      values.totalGoal === undefined ||
      !isWholeNumber(parseInt(values.totalGoal, 10))
    ) {
      errors.totalGoal = 'Goal must be a whole number'
    }
  }

  return errors
}

function hasDuplicates(values: Values['samplingEventAnalytes']) {
  const analyteIds = values.map(sea => sea.analyteId)
  return new Set(analyteIds).size !== analyteIds.length
}

export function validateAnalytes(values: Values) {
  const errors: Record<string, unknown> = {}
  if (!values.samplingEventAnalytes.every(sea => sea.analyteId)) {
    errors.samplingEventAnalytes = ['All Analytes must be selected']
  }
  if (hasDuplicates(values.samplingEventAnalytes)) {
    errors.samplingEventAnalytes = ['No duplicate Analytes allowed']
  }
  return errors
}

export function validateKit(values: Values) {
  const errors: Record<string, string> = {}
  if (values.kit === undefined) {
    errors.kit = 'A kit must be selected'
  }
  return errors
}

export function createFormValidation(
  isReplacementProgram: boolean,
  eventNames: string[],
  type: 'create' | 'edit'
) {
  return function validateForm(values: Values) {
    const isPitcherFilterEvent = values.eventType === 'Pitcher/Filter'
    if (isPitcherFilterEvent) {
      return {
        ...validateEvent(values, isReplacementProgram, eventNames, type),
        ...validateKit(values)
      }
    }

    return {
      ...validateEvent(values, isReplacementProgram, eventNames, type),
      ...validateAnalytes(values),
      ...validateKit(values)
    }
  }
}
