import React from 'react'
import { colors } from '@120wateraudit/envirio-components'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as DownloadIcon } from 'src/assets/images/Download.svg'

import './DownloadStarted.css'

type DownloadType = 'comm' | 'export' | 'batch';
export interface DownloadTypeProps { type: DownloadType }

interface DownloadTypeInfo {
  title: string;
  message: string;
  link: string;
  linkText: string;
}

const DownloadTypeMap: Record<DownloadType, DownloadTypeInfo> = {
  'comm': {
    title: 'Download Started',
    message: 'Your PDF download has started. If it doesn\'t begin automatically',
    link: '/communications/letter-logs',
    linkText: 'click here to download from your Communications Letter Log.'
  },
  'export': {
    title: 'Export Started',
    message: 'Your export has started processing. When it is ready, you can download it from your',
    link: '/administration/exportlog',
    linkText: 'Export Log.'
  },
  'batch': {
    title: 'Batch Creation Started',
    message: 'Your batch is being created. When it is ready, you can access it from the',
    link: '/communications/bulk-downloads',
    linkText: 'Bulk Downloads page.'
  }
};

const getDownloadInfo = (type: DownloadType): DownloadTypeInfo => {
  return DownloadTypeMap[type]
};


export const DownloadStartedToast = ({ type }: DownloadTypeProps): JSX.Element => {
  const { title } = getDownloadInfo(type)
  return (
    <Wrapper>
      <Header>
        <Image />
        {title}
      </Header>
      <br />
      <Information>
        <Details type={type} />
      </Information>
    </Wrapper>
  )
}

const Details = ({ type }: DownloadTypeProps) => {
  const { message, link, linkText } = getDownloadInfo(type)
    return (
      <>
        {message}{' '}
        <AuditLogLink to={link}>
          {linkText}
        </AuditLogLink>
      </>
    )
}

const Wrapper = styled.div`
  margin-right: 3rem;
`
const Header = styled.div`
  color: ${colors.black};
  padding-top: 1rem;
  padding-left: 1rem;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Image = styled(DownloadIcon)`
  padding-right: 0.5rem;
`
const Information = styled.div`
  color: ${colors.black75};
  padding: 1rem, 3rem, 1rem;
  font-size: 1.2rem;
`

const AuditLogLink = styled(Link)`
  color: ${colors.primary};
`
