import React from 'react'
import { Field } from 'react-final-form'

import Dropdown from 'src/components/Forms/Dropdown'
import { yesNoDropdownOptions } from 'src/modules/ServiceLines/dataAccess'
import {
  DoubleInputGrid,
  FlexDiv,
  GeneralContainer,
  SuperAsterisk,
  NoRedHighlightTextField
} from '../../shared'

interface Props {
  questionOneRequired?: boolean
  questionTwoConditionallyRequired?: boolean
}

const EpaMethodsPart2 = ({
  questionOneRequired,
  questionTwoConditionallyRequired
}: Props): JSX.Element => {
  return (
    <GeneralContainer>
      <p style={{ marginBottom: '0px' }}>
        During which normal operating activities are you collecting information
        on service line material? Check all that apply.
        {questionOneRequired && <SuperAsterisk />}
      </p>
      <DoubleInputGrid>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Water meter reading"
          />
          <label>Water Meter Reading</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Water meter repair or replacement"
          />
          <label>Water meter repair or replacement</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Service line repair or replacement"
          />
          <label>Service line repair or replacement</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Water main repair or replacement"
          />
          <label>Water main repair or replacement</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Backflow prevention device inspection"
          />
          <label>Backflow prevention device inspection</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Other"
          />
          <label>Other</label>
        </FlexDiv>
      </DoubleInputGrid>
      <Field<string>
        component={NoRedHighlightTextField}
        displayConditionallyRequired={questionTwoConditionallyRequired}
        label="If other please explain:"
        name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation"
        textarea
      />
      <Field<string>
        component={Dropdown}
        fluid
        label="Did you develop or revise standard operating procedures to collect service line material information
    during normal operation?"
        name="inventoryMethodsPart2Q2StandardProceduresYesNo"
        options={yesNoDropdownOptions}
        textarea
      />
      <Field<string>
        component={NoRedHighlightTextField}
        label="If Yes, please describe:"
        name="inventoryMethodsPart2Q2StandardProceduresOtherExplanation"
        textarea
      />
    </GeneralContainer>
  )
}

export default EpaMethodsPart2
