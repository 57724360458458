import SubmissionPeriod from './SubmissionPeriod'

export enum SubmissionStatus {
  'notSubmitted' = 'Not Submitted',
  'submitted' = 'Submitted',
  'inReview' = 'In Review',
  'rejected' = 'Rejected',
  'approved' = 'Accepted',
  'voided' = 'Voided',
  'processingSubmission' = 'Processing Submission',
  'failed' = 'Failed'
}

export interface Submission extends Record<string, unknown> {
  id: number
  primacyAgencyId: number
  pwsId: number
  status: SubmissionStatus
  statusComment: string
  submissionPeriodId: number
  submissionPeriod: SubmissionPeriod
  submittedBy: string
  submittedByName: string
  submittedOn: string
  totalServiceLinesLead: number
  totalServiceLinesGalvanized: number
  totalServiceLinesNonLead: number
  totalServiceLinesUnknown: number
  totalServiceLinesSubmitted: number
  additionalInfo: string
  createdOn: string
  createdBy: string
  deletedOn: string
  deletedBy: string
  modifiedBy: string
  modifiedOn: string
  overdue: boolean
}
