import React, { FC, ReactNode } from 'react'
import { theme } from '@120wateraudit/envirio-components/dist/theme'
import { ThemeProvider as Provider } from 'styled-components'

const ThemeProvider: FC<Props> = props => <Provider theme={theme} {...props} />

interface Props {
  children: ReactNode
}

export default ThemeProvider
