import { textValidationCheck } from 'src/modules/ServiceLines/dataAccess'
import { validateIdentifyServiceLineCheckboxes } from '../DelawareWizard'
import {
  createSamplingChoicesArray,
  validSecondarySamplingChoices
} from './dataAccess'

//Todo It would be good to condense/refactor any relevant code in this file and the index.tsx for the Delaware Wizard

export function validateDelaware(values: any, errors: any) {
  const identifyServiceLinesCheckboxCheck =
    validateIdentifyServiceLineCheckboxes(values)

  //First Page (Historical Records Review)
  if (
    textValidationCheck(values.inventoryMethodsPart1PreviousMaterialsEvaluation)
  ) {
    errors.inventoryMethodsPart1PreviousMaterialsEvaluation = 'Required'
  }
  if (
    textValidationCheck(
      values.inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes
    )
  ) {
    errors.inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes = 'Required'
  }
  if (textValidationCheck(values.inventoryMethodsPart1WaterSystemRecords)) {
    errors.inventoryMethodsPart1WaterSystemRecords = 'Required'
  }
  if (
    textValidationCheck(
      values.inventoryMethodsPart1DistributionSystemInspectionsAndRecords
    )
  ) {
    errors.inventoryMethodsPart1DistributionSystemInspectionsAndRecords =
      'Required'
  }
  if (textValidationCheck(values.inventoryMethodsPart1AdditionalStateRecords)) {
    errors.inventoryMethodsPart1AdditionalStateRecords = 'Required'
  }
  if (textValidationCheck(values.inventoryMethodsPart1otherRecords)) {
    errors.inventoryMethodsPart1otherRecords = 'Required'
  }

  //Second Page (Identifying Service Line Material During Normal Operations)
  if (!identifyServiceLinesCheckboxCheck) {
    errors.identifyServiceLinesCheckboxPicklist = 'Required'
  }

  if (
    values.inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices &&
    values.inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices.includes(
      'Other'
    ) &&
    textValidationCheck(
      values.inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation
    )
  ) {
    errors.inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation =
      'Required'
  }
  //Third Page (Service line Investigations)

  const samplingChoicesArray = createSamplingChoicesArray(values)

  const hasNonSampleChoice = validSecondarySamplingChoices.some(o =>
    values?.inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(o)
  )

  if (
    !values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices ||
    (values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
      values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices.length ===
        0)
  ) {
    errors.serviceLineInvestigationsCheckboxPicklist = 'Required'
  }

  if (samplingChoicesArray.length > 1) {
    errors.serviceLineInvestigationsCheckboxPicklist =
      'Too many Water Sampling choices'
  }

  if (
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices.includes(
      'Predictive Modeling'
    ) &&
    textValidationCheck(
      values.inventoryMethodsPart3Q2PredictiveModelingExplanation
    )
  ) {
    errors.inventoryMethodsPart3Q2PredictiveModelingExplanation = 'Required'
  }

  if (
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices.includes(
      'Other'
    ) &&
    textValidationCheck(
      values.inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation
    )
  ) {
    errors.inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation =
      'Required'
  }

  if (
    values.inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
    samplingChoicesArray.length === 1 &&
    !hasNonSampleChoice
  ) {
    errors.serviceLineInvestigationsCheckboxPicklist =
      'Non-Sampling method is required'
  }

  //Fourth Page (General Information)

  if (
    textValidationCheck(
      values.inventorySummarypart1Question1initialInventoryOrUpdate
    )
  ) {
    errors.inventorySummarypart1Question1initialInventoryOrUpdate = 'Required'
  }
  if (
    textValidationCheck(
      values.inventorySummarypart1Question2aServiceLineOwnership
    )
  ) {
    errors.inventorySummarypart1Question2aServiceLineOwnership = 'Required'
  }
  if (
    textValidationCheck(
      values.inventorySummarypart1Question2bServiceLineOwnership
    )
  ) {
    errors.inventorySummarypart1Question2bServiceLineOwnership = 'Required'
  }
  if (
    textValidationCheck(values.inventorySummarypart1Q3aInstallationExplanation)
  ) {
    errors.inventorySummarypart1Q3aInstallationExplanation = 'Required'
  }
  if (textValidationCheck(values.inventorySummarypart1Q3bBanningExplanation)) {
    errors.inventorySummarypart1Q3bBanningExplanation = 'Required'
  }
  if (textValidationCheck(values.inventorySummarypart1Q4LeadGoosenecks)) {
    errors.inventorySummarypart1Q4LeadGoosenecks = 'Required'
  }
  if (textValidationCheck(values.inventorySummarypart1Q5ConfidenceLevel)) {
    errors.inventorySummarypart1Q5ConfidenceLevel = 'Required'
  }

  //Conditional (If the choice above equals) result validation logic

  if (
    values.inventorySummarypart1Question2aServiceLineOwnership === 'Other' &&
    textValidationCheck(
      values.inventorySummarypart1Question2aServiceLineOwnershipOtherExplanation
    )
  ) {
    errors.inventorySummarypart1Question2aServiceLineOwnershipOtherExplanation =
      'Required'
  }

  if (
    values.inventorySummarypart1Question2bServiceLineOwnership === 'Yes' &&
    textValidationCheck(
      values.inventorySummarypart1Question2bServiceLineOwnershipOtherExplanation
    )
  ) {
    errors.inventorySummarypart1Question2bServiceLineOwnershipOtherExplanation =
      'Required'
  }
}
