import React, { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'

import { SortDirection } from 'src/types/SortDirection'
import { titleCase } from 'src/utils/titleCase'
import CheckCell from './Cells/CheckCell'
import HeaderCell from './Cells/HeaderCell'
import { KebabHeader } from './Cells/KebabCell'
import { Column, Props, RowData, SelectionMode, SortableProps } from './types'

type Sort = undefined | 'ascending' | 'descending'

export const SelectionHeaderCell = ({
  checked,
  disabled,
  onSelectAll,
  selectionMode
}: {
  checked: boolean
  selectionMode: SelectionMode
  onSelectAll?: () => void
  disabled?: boolean
}): null | JSX.Element => {
  if (selectionMode === 'single') {
    return null
  }

  if (selectionMode === 'multi' && !!onSelectAll) {
    return (
      <CheckCell
        checked={checked}
        id="check-all"
        onCheck={onSelectAll}
        disabled={disabled}
      />
    )
  }

  return null
}

const getSorted = (key: string, sort: SortableProps['sort']): Sort => {
  if (!sort) {
    return undefined
  }

  return key === sort.key
    ? sort.direction === SortDirection.ASC
      ? 'ascending'
      : 'descending'
    : undefined
}

const getNewSortDirection = (sorted: Sort): null | SortDirection => {
  if (!sorted) {
    return SortDirection.ASC
  }

  if (sorted === 'ascending') {
    return SortDirection.DESC
  }

  return null
}

type HeaderProps<T extends RowData> = Omit<Props<T>, 'data'> & {
  hasRowActions: boolean
}

function TableHeader<T extends RowData>({
  columns,
  hasRowActions,
  onSortChanged,
  sort
}: HeaderProps<T>): JSX.Element {
  const changeSort = useCallback(
    (key: string, direction: null | SortDirection) => {
      if (onSortChanged && direction === null) {
        ReactGA.event({
          action: 'Change Sort',
          category: 'Table',
          label: `No Sort`
        })
        onSortChanged()
      } else if (onSortChanged && direction !== null) {
        ReactGA.event({
          action: 'Change Sort',
          category: 'Table',
          label: `${key}: ${direction}`
        })
        onSortChanged({ direction, key })
      }
    },
    [onSortChanged]
  )
  const createHeaderCells = useCallback(
    (
      {
        displayRequired = false,
        displayConditionallyRequired = false,
        Header,
        headerProps = {},
        key,
        sortable = true
      }: Column<T>,
      index: number
    ) => {
      const contents = Header || titleCase(key)
      const sorted = getSorted(key, sort)
      return (
        <HeaderCell
          {...headerProps}
          displayRequired={displayRequired}
          displayConditionallyRequired={displayConditionallyRequired}
          key={`header-${key}-${index}`}
          onClick={() => changeSort(key, getNewSortDirection(sorted))}
          sortable={sortable}
          sorted={sorted}>
          {contents}
        </HeaderCell>
      )
    },
    [sort, changeSort]
  )

  return (
    <StyledTableHeader>
      <Table.Row>
        {columns.map(createHeaderCells)}
        {hasRowActions && <KebabHeader />}
      </Table.Row>
    </StyledTableHeader>
  )
}

const StyledTableHeader = styled(Table.Header)`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
`

export default TableHeader
