import { Button, fontSize } from '@120wateraudit/envirio-components'
import React, { FC, useCallback, useState } from 'react'
import { Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'

import { ReactComponent as PaperCheckIcon } from 'src/assets/images/paper-check.svg'
import {
  ConfirmNumberOfSamples,
  FormText,
  ModalButtonWrapper,
  ModalFooterWrapper,
  ModalHeader,
  ModalWrapper
} from './shared'

interface Props {
  isSaving: boolean
  selectedSamples: unknown[]
  onCancel(): void
  onComplete(): void
}

const CertifyModal: FC<Props> = ({
  isSaving,
  onCancel,
  onComplete,
  selectedSamples
}) => {
  const [certified, setCertified] = useState(false)
  const closeModal = useCallback(() => {
    setCertified(false)
    onCancel()
  }, [setCertified, onCancel])
  return (
    <>
      <ModalWrapper>
        <PaperCheckIcon />
        <ModalHeader>Complete Samples</ModalHeader>
        <ConfirmNumberOfSamples>
          You are about to complete <b>{selectedSamples.length}</b> sample
          {selectedSamples.length === 1 ? '' : 's'}.
        </ConfirmNumberOfSamples>
        <FormText>
          A complete sample is from a valid location, has a complete chain of
          custody, and meets the hold time requirements.
        </FormText>
        <FormCheckboxWrapper>
          <Checkbox
            checked={certified}
            label="I certify all information is true and correct to the best of my knowledge"
            onChange={() => setCertified(!certified)}
          />
        </FormCheckboxWrapper>
      </ModalWrapper>
      <ModalFooterWrapper>
        <ModalButtonWrapper>
          <Button disabled={isSaving} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={isSaving || !certified}
            onClick={onComplete}
            variant="primary">
            Next
          </Button>
        </ModalButtonWrapper>
      </ModalFooterWrapper>
    </>
  )
}

const FormCheckboxWrapper = styled.div`
  font-size: ${fontSize.caption};
  font-weight: bold;
`

export default CertifyModal
