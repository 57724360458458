import type { Identifier, XYCoord } from 'dnd-core'
import update from 'immutability-helper'
import pluralize from 'pluralize'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ReactGA from 'react-ga4'
import { Checkbox, Icon, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import IconButton from 'src/components/Buttons/IconButton'
import { AQUA_BLUE } from 'src/constants/colors'
import { useToggle } from 'src/hooks'
import { titleCase } from 'src/utils/titleCase'
import { TableContext } from '../context'
import { Column } from '../types'
import { FilterOption, FilterOptions, setColumnsForUser } from '../utils'

interface Props {
  className?: string
}

const ColumnPicker = ({ className }: Props): JSX.Element => {
  const {
    softwarePackageName,
    allowHiddenColumns,
    columns = [],
    hiddenColumns = [],
    onToggleColumnVisibility,
    orderable,
    setColumns
  } = useContext(TableContext)
  const iconColor = hiddenColumns.length > 0 ? AQUA_BLUE : 'rgb(85, 91, 102)'
  const [showMenu, toggleMenu] = useToggle()
  const columnsString = pluralize('Column', hiddenColumns.length, true)
  return (
    <Container>
      <Popup
        on="click"
        onClose={toggleMenu}
        onOpen={toggleMenu}
        open={showMenu}
        position="bottom left"
        trigger={
          <IconButton
            className={className}
            name="table"
            iconColor={iconColor}
            basic={false}
            title={hiddenColumns.length > 0 ? `${columnsString} Hidden` : ''}
          />
        }>
        <ColumnOptions
          allowHiddenColumns={!!allowHiddenColumns}
          columns={setColumnsForUser(columns, softwarePackageName)}
          hiddenColumns={hiddenColumns}
          onToggleColumnVisibility={onToggleColumnVisibility}
          orderable={orderable && !!setColumns}
          setColumns={setColumns}
        />
      </Popup>
    </Container>
  )
}

interface ColumnOptionsProps {
  allowHiddenColumns: boolean
  columns: Column[]
  hiddenColumns: string[]
  orderable: boolean
  onToggleColumnVisibility(key: string, visible: boolean): void
  setColumns?(columns: Column[]): void
}

const ColumnOptions = ({
  allowHiddenColumns,
  columns,
  hiddenColumns,
  onToggleColumnVisibility,
  orderable,
  setColumns
}: ColumnOptionsProps) => {
  const [draggableColumns, setDraggableColumns] = useState<Column[]>(columns)
  const moveColumn = useCallback((dragIndex: number, hoverIndex: number) => {
    setDraggableColumns((prevColumns: Column[]) => {
      return update(prevColumns, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevColumns[dragIndex]]
        ]
      })
    })
  }, [])
  const onDrop = useCallback(() => {
    if (setColumns) {
      setColumns(draggableColumns)
    }
  }, [draggableColumns, setColumns])
  const renderColumn = useCallback(
    (column: Column, index: number) => (
      <ColumnRow
        allowHiddenColumns={allowHiddenColumns}
        checked={!hiddenColumns.includes(column.key)}
        column={column}
        index={index}
        key={column.key}
        onDrag={moveColumn}
        onDrop={onDrop}
        onToggleColumnVisibility={onToggleColumnVisibility}
        orderable={orderable}
      />
    ),
    [
      hiddenColumns,
      moveColumn,
      onToggleColumnVisibility,
      orderable,
      onDrop,
      allowHiddenColumns
    ]
  )
  return <FilterOptions>{draggableColumns.map(renderColumn)}</FilterOptions>
}

interface DragColumn {
  index: number
  key: string
}

interface ColumnRowProps {
  allowHiddenColumns: boolean
  checked: boolean
  column: Column
  index: number
  orderable: boolean
  onDrag(dragIndex: number, hoverIndex: number): void
  onDrop(): void
  onToggleColumnVisibility(key: string, visible: boolean): void
}

const ColumnRow = ({
  allowHiddenColumns,
  checked,
  column,
  index,
  onDrag,
  onDrop,
  onToggleColumnVisibility,
  orderable
}: ColumnRowProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragColumn,
    void,
    { handlerId: null | Identifier }
  >({
    accept: 'COLUMN',
    collect(monitor: any) {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(item: DragColumn, monitor: any) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      onDrag(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    end: onDrop,
    type: 'COLUMN',
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    item: () => {
      return { index, key: column.key }
    }
  })
  drag(drop(ref))

  return (
    <ColumnOption
      as="div"
      className="item"
      data-handler-id={orderable ? handlerId : undefined}
      ref={orderable ? ref : undefined}
      role="list-item"
      style={{ justifyContent: 'left', ...(isDragging ? DRAG_STYLE : {}) }}>
      {orderable && (
        <div className="icon">
          <Icon name="bars" />
        </div>
      )}
      {allowHiddenColumns && (
        <Checkbox
          checked={checked}
          disabled={column.required}
          label={<label>{column.name || titleCase(column.key)}</label>}
          onChange={(_, { checked }) => {
            ReactGA.event({
              category: 'Table',
              action: 'Change Column Visibility',
              label: `${column.key}: ${checked ? 'Shown' : 'Hidden'}`
            })
            onToggleColumnVisibility(column.key, !!checked)
          }}
        />
      )}
      {!allowHiddenColumns && (
        <label>{column.name || titleCase(column.key)}</label>
      )}
    </ColumnOption>
  )
}

const DRAG_STYLE = {
  border: '1px dashed #333',
  opacity: 0.5
}

const Container = styled.div`
  &&& {
    button {
      padding-top: 11px;
      padding-bottom: 12px;
      height: 38px;
      background-color: #fff;
      border: 1px solid #e5e7eb;
    }
    i {
      color: #555b66;
    }
  }
`

const ColumnOption = styled(FilterOption)`
  & > .icon {
    margin-top: 3px;
    width: 1.75rem;
    height: 1rem;
  }
  & > .ui.checkbox {
    max-width: 200px;
  }
`

export default ColumnPicker