import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'

import { AQUA_BLUE } from 'src/constants/colors'

interface ModalProps {
  cancelAction?: React.ReactNode
  className?: string
  icon?: IconDefinition
  iconOverrideColor?: string
  primaryAction: React.ReactNode
  secondaryActions?: React.ReactNode | React.ReactNode[]
  style?: CSSProperties
  title: string
}

const Modal: FC<ModalProps> = ({
  cancelAction,
  children,
  className,
  icon,
  iconOverrideColor,
  primaryAction,
  secondaryActions,
  style,
  title
}) => {
  return (
    <Wrapper
      className={className}
      singleAction={!cancelAction && !secondaryActions}
      style={style}>
      {icon && (
        <FontAwesomeIcon
          color={iconOverrideColor || AQUA_BLUE}
          icon={icon}
          size={'4x'}
        />
      )}
      <h2>{title}</h2>
      {children}
      <div className="md actions">
        {cancelAction}
        <div>
          {secondaryActions}
          {primaryAction}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 40px;
  text-align: center;
  font-size: 14px;
  line-height: 1.42857143;

  h2 {
    font-size: 24px;
    line-height: 1.33333333;
  }

  & > .md.actions {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: ${p => (p.singleAction ? 'center;' : 'space-between;')} & >
      div {
      ${p => (p.singleAction ? '' : 'margin-left: auto;')}
      & > * {
        margin-left: 8px;
        &:first-child {
          margin-left: 0px;
        }
      }
    }
  }
`

export default Modal
