import actionCreatorFactory from 'typescript-fsa'
import { Geocoding, GeocodingType } from '../types/Geocoding'
import { Address } from '@120wateraudit/envirio-components/dist/models'

const action = actionCreatorFactory()

export const geocodeAddressRequest = action<{
  type: GeocodingType
  id: number
  address: Partial<Address>
}>('GEOCODE_ADDRESS_REQUEST')

export const geocodeAddressSuccess = action<{ geocoding: Geocoding }>(
  'GEOCODE_ADDRESS_SUCCESS'
)
export const geocodeAddressFailure = action<{
  type: GeocodingType
  id: number
  error: string
}>('GEOCODE_ADDRESS_FAILURE')
