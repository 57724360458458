import {
  PWSProgram,
  PWSSamplingEvent,
  ProtocolDefinition
} from '@120wateraudit/envirio-components/dist/models'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'

import { post } from 'src/API'
import { useToggle } from 'src/hooks'
import { BatchType } from 'src/modules/FileProcessor/dataAccess'
import EventAssignmentModalContent from 'src/modules/Modal/EventAssignment/EventAssignmentModalContent'
import { ModalWrapper, Wrapper } from 'src/modules/Modal/shared'
import { ApiError } from 'src/types/ApiError'

export const useAssignEvents = (
  droppedFile: any,
  createSamplesOnImport: boolean,
  samplingEvent: PWSSamplingEvent,
  program: PWSProgram,
  setUploadProgress: Dispatch<SetStateAction<number>>,
  setUseLinkAsImportResponse: Dispatch<SetStateAction<boolean>>
) => {
  const [loading, toggleLoading] = useToggle()
  const [showFailedMessage, toggleShowFailedMessage] = useToggle()
  const [error, setError] = useState<ApiError>()

  const onAssignEventsClicked = useCallback(async () => {
    if (droppedFile.length > 0) {
      const document = droppedFile[0]
      const formData = new FormData()
      let samplingEventId

      formData.append('createSamplesOnImport', createSamplesOnImport as any)

      if (samplingEvent) {
        samplingEventId = samplingEvent.id
      }

      setUploadProgress(0)
      toggleLoading()

      try {
        formData.append('file', document)
        formData.append('programId', program.id.toString())
        formData.append('eventId', samplingEventId)

        await post(
          `/file-processor/upload/events/${BatchType.Import}`,
          formData
        )

        setUseLinkAsImportResponse(true)

        setUploadProgress(100)
      } catch (error) {
        setError(error as ApiError)
        toggleShowFailedMessage()
      }

      toggleLoading()
    }
  }, [
    createSamplesOnImport,
    droppedFile,
    program,
    samplingEvent,
    setUseLinkAsImportResponse,
    setUploadProgress,
    toggleLoading,
    toggleShowFailedMessage
  ])

  return {
    assignEventsLoading: loading,
    error,
    onAssignEventsClicked,
    showFailedMessageOnAssigningEvents: showFailedMessage
  }
}

type PWSSamplingEventWithProtocolDefinitions = PWSSamplingEvent & {
  protocolDefinitions?: ProtocolDefinition[]
}

interface Props {
  program: PWSProgram
  samplingEvent: PWSSamplingEventWithProtocolDefinitions
  onClose: () => void
}

const isVerificationEvent = (
  protocolDefinitions?: ProtocolDefinition[]
): boolean => {
  if (protocolDefinitions) {
    return protocolDefinitions.some(p => p.sku?.sku === 'LSL-FT-1')
  } else {
    return false
  }
}

const EventAssignmentModal = (props: Props) => {
  const { onClose, program, samplingEvent } = props
  const [dropzoneDisabled, toggleDropzoneDisabled] = useToggle()
  const [createSamplesOnImport, toggleCreateSamplesOnImport] = useToggle(false)
  const [
    disableLocationImportButton,
    toggleDisableLocationImportButton
  ] = useToggle(true)

  const [file, setFile] = useState<File>()
  const [droppedFile, setDroppedFile] = useState<File[]>([])
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [useLinkAsImportResponse, setUseLinkAsImportResponse] = useState<
    boolean
  >(false)

  const {
    assignEventsLoading,
    error,
    onAssignEventsClicked,
    showFailedMessageOnAssigningEvents
  } = useAssignEvents(
    droppedFile,
    createSamplesOnImport,
    samplingEvent,
    program,
    setUploadProgress,
    setUseLinkAsImportResponse
  )

  const onDropzoneDrop = (files: File[]) => {
    toggleDisableLocationImportButton()

    setFile(files[0])
    setDroppedFile(files)
    toggleDropzoneDisabled()
  }

  const hideCreateSamples = isVerificationEvent(
    samplingEvent?.protocolDefinitions
  )

  const eventName =
    samplingEvent && samplingEvent.name ? samplingEvent.name : ''

  return (
    <ModalWrapper>
      <Wrapper>
        <EventAssignmentModalContent
          createSamplesOnImport={createSamplesOnImport}
          disableLocationImportButton={disableLocationImportButton}
          dropzoneDisabled={dropzoneDisabled}
          error={error as ApiError}
          eventName={eventName}
          file={file}
          hideCreateSamplesToggle={hideCreateSamples}
          onDrop={onDropzoneDrop}
          onImportLocationsClicked={onAssignEventsClicked}
          onToggleClicked={toggleCreateSamplesOnImport}
          showFailedMessage={showFailedMessageOnAssigningEvents}
          toggleLocationImportModal={onClose}
          uploadProgress={uploadProgress}
          uploading={assignEventsLoading}
          useLinkAsImportResponse={useLinkAsImportResponse}
        />
      </Wrapper>
    </ModalWrapper>
  )
}

export default EventAssignmentModal
