import React, { useMemo } from 'react'
import { Loader } from 'semantic-ui-react'
import styled from 'styled-components'

import { ReactComponent as ArrowDownBlueIcon } from 'src/assets/images/ArrowDownBlue.svg'
import { Error } from 'src/components/Error'
import { PWSSample } from 'src/types/Sample'
import { Warning } from '../'

const getUniqueSamplingEvents = (selectedSamples: PWSSample[]) => {
  const uniqueSamplingEvents: string[] = []
  selectedSamples.forEach(sample => {
    if (!uniqueSamplingEvents.includes(sample.samplingEvent.name)) {
      uniqueSamplingEvents.push(sample.samplingEvent.name)
    }
  })
  return uniqueSamplingEvents
}

interface Props {
  error?: Error
  isSaving: boolean
  selectedEvent?: string
  selectedSamples: PWSSample[]
}

function ConfirmMove({
  error,
  isSaving,
  selectedEvent = 'Unknown',
  selectedSamples
}: Props): JSX.Element {
  const uniqueEvents = useMemo(() => getUniqueSamplingEvents(selectedSamples), [
    selectedSamples
  ])
  if (isSaving) {
    return <Loader />
  }

  return (
    <>
      <Warning>This action can not be undone.</Warning>
      <NumberOfSamples>
        You are moving {selectedSamples.length} <strong>Samples</strong> from:
      </NumberOfSamples>
      <SourceEvents>
        {uniqueEvents.map((event, index) => (
          <span key={`${event}-${index}`}>{event}</span>
        ))}
      </SourceEvents>
      <ArrowDownBlueIcon />
      <DestinationEvent>{selectedEvent}</DestinationEvent>
      {error && <Error error={error} />}
    </>
  )
}

const NumberOfSamples = styled.p`
  font-size: 14px;
`

const SourceEvents = styled.div`
  font-size: 20px;
  display: grid;
  grid-auto-columns: 1fr;
  gap: 1rem 0;
  justify-items: center;
  > span {
    display: inline-block;
  }
`

const DestinationEvent = styled.p`
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
`

export default ConfirmMove
