import * as React from 'react'
import ReactGA from 'react-ga4'
import { Button } from 'semantic-ui-react'
import { ExportAction } from '../types'


export const ExportButton = ({
  label = 'Export',
  onClick
}: ExportAction): JSX.Element => {
  return (
    <Button
      compact
      onClick={() => {
        ReactGA.event({
          action: `Action: ${label}`,
          category: 'Table'
        })
        onClick()
      }}>
      {label}
    </Button>
  )
}
