import React, { useCallback, useMemo } from 'react'
import { Button } from '@120wateraudit/waterworks'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form } from 'semantic-ui-react'
import { ValidationErrors } from 'final-form'

import { useCreateCommentMutation } from 'src/services/state-submission-api'
import { toastSuccess, toastError } from 'src/utils/toast'
import { isApiError } from '../hooks'
import { TritonError } from 'src/components/Error'

interface Values extends Record<string, unknown> {
  comment: string
}

export const validate = (
  values: Values | Record<string, any>
): ValidationErrors => {
  const errors: ValidationErrors = {}

  if (!values.comment) {
    errors.comment = 'Required'
  }

  return errors
}

interface Props {
  pwsId: number
  submissionPeriodId: number
  submissionId: number
}

const CommentForm = ({
  pwsId,
  submissionPeriodId,
  submissionId
}: Props): JSX.Element => {
  const [createComment, { error, isError, isLoading }] =
    useCreateCommentMutation()

  const onSubmit = useCallback(
    async (values: Values) => {
      const { comment } = values

      const newComment = {
        comment,
        originType: 'pws',
        originId: pwsId,
        commentType: 'comment',
        submissionId
      }

      try {
        createComment &&
          (await createComment({
            submissionPeriodId,
            pwsId,
            comment: newComment
          }).unwrap())
        toastSuccess('You successfully created your comment.')
      } catch {
        toastError('An error occurred, please try again.')
      }
    },
    [createComment, submissionPeriodId, pwsId, submissionId]
  )

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined
    }

    if (isApiError(error)) {
      return [error.data?.error]
    }

    return ['Please try again or contact support@120water.com']
  }, [error, isError])

  return (
    <FinalForm
      initialValues={{ comment: null }}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <Form
          onSubmit={() => {
            void handleSubmit()
          }}>
          <Field<string>
            component="textarea"
            name="comment"
            style={{
              maxHeight: '100px',
              marginBottom: '1.5em',
              fontSize: '14px'
            }}
          />
          <Button
            disabled={submitting || isLoading || !valid}
            variant="primary"
            type="submit">
            Add Comment
          </Button>
          {errors && (
            <TritonError style={{ margin: '12px 24px' }} messages={errors} />
          )}
        </Form>
      )}
      validate={values => validate(values)}
    />
  )
}

export default CommentForm
