import { ProgramStatus } from '@120wateraudit/envirio-components/dist/models'
import { ValidationErrors } from 'final-form'
import createDecorator from 'final-form-calculate'
import omit from 'lodash/omit'
import moment from 'moment'
import React from 'react'
import { Field } from 'react-final-form'
import { Form as SUIForm, FormGroup } from 'semantic-ui-react'

import { TritonError } from 'src/components/Error'
import Datepicker, { DATE_FORMAT } from 'src/components/Forms/Datepicker'
import Dropdown from 'src/components/Forms/Dropdown'
import TextField from 'src/components/Forms/TextField'
import Toggle from 'src/components/Forms/Toggle'
import { isEmptyObject } from 'src/utils/isEmptyObject'

export interface Values {
  assetTypeId: number
  doesPostSampling: boolean
  doesPreSampling: boolean
  endsOn: string
  name: string
  programTypeId?: number
  replacementIntervalMonths: number
  startsOn: string
  status: ProgramStatus
}

export const validate = (values: Values) => {
  const errors: Partial<Record<keyof Values, string>> = {}
  if (!values.programTypeId) {
    errors.programTypeId = 'A Program Type is required.'
  }

  if (!values.name) {
    errors.name = 'A Program Name is required.'
  }

  const start = moment(values.startsOn, DATE_FORMAT)
  const end = moment(values.endsOn, DATE_FORMAT)
  if (end.isSameOrBefore(start)) {
    errors.endsOn = 'A Program must end after it starts.'
  }

  if (values.assetTypeId <= 0) {
    errors.assetTypeId = 'A valid Asset Type must be selected'
  }

  return errors
}

export const endDateCalculator = createDecorator(
  {
    field: 'replacementIntervalMonths',
    updates: {
      endsOn: (replacementInterval: number, values: Values) =>
        moment(values.startsOn)
          .add(replacementInterval, 'month')
          .format(DATE_FORMAT)
    }
  },
  {
    field: 'startsOn',
    updates: {
      endsOn: (startsOn: string, values: Values) =>
        moment(startsOn)
          .add(values.replacementIntervalMonths, 'month')
          .format(DATE_FORMAT)
    }
  }
)

interface Props {
  errors: ValidationErrors
  pristine: boolean
  programType: string
  touched?: { [key: string]: boolean }
  type: 'create' | 'edit'
}

const Form = ({
  errors,
  pristine,
  programType,
  touched,
  type
}: Props): JSX.Element => {
  return (
    <>
      <FormGroup widths="equal">
        <SUIForm.Field>
          <Field<string>
            autoFocus
            component={TextField}
            label="Program Name"
            name="name"
          />
        </SUIForm.Field>
      </FormGroup>
      <FormGroup widths="equal">
        <SUIForm.Field>
          <Field<string>
            component={Datepicker}
            label="Start Date"
            name="startsOn"
            popupPosition="bottom center"
            style={{ width: '100%' }}
          />
        </SUIForm.Field>
        <SUIForm.Field>
          <Field<string>
            component={Datepicker}
            disabled={programType === 'REPLACEMENT'}
            label="End Date"
            name="endsOn"
            popupPosition="bottom center"
            style={{ width: '100%' }}
          />
        </SUIForm.Field>
      </FormGroup>
      {programType === 'REPLACEMENT' && (
        <>
          <FormGroup widths="equal">
            <SUIForm.Field>
              <Field<string>
                addUndefinedOption={false}
                component={Dropdown}
                disabled={type === 'edit'}
                fluid
                label="Asset Type"
                name="assetTypeId"
                options={[
                  // These are hardcoded for now since we have only one.
                  { value: 1, text: 'Service Lines' },
                  { value: 0, text: 'Meters - Coming Soon', disabled: true }
                ]}
              />
            </SUIForm.Field>
            <SUIForm.Field>
              <Field<string>
                addUndefinedOption={false}
                component={Dropdown}
                disabled={type === 'edit'}
                fluid
                label="Replacement Interval"
                name="replacementIntervalMonths"
                options={[
                  { value: 6, text: 'Semi-Annually (6 months)' },
                  { value: 12, text: 'Annually (12 months)' },
                  { value: 24, text: 'Biannually (24 months)' }
                ]}
              />
            </SUIForm.Field>
          </FormGroup>
          <FormGroup widths="equal">
            <SUIForm.Field>
              <Field<boolean>
                component={Toggle}
                disabled={type === 'edit'}
                label="Pre-Replacement Sampling"
                name="doesPreSampling"
                type="checkbox"
              />
            </SUIForm.Field>
            <SUIForm.Field>
              <Field<boolean>
                component={Toggle}
                disabled={type === 'edit'}
                label="Post-Replacement Sampling"
                name="doesPostSampling"
                type="checkbox"
              />
            </SUIForm.Field>
          </FormGroup>
        </>
      )}
      <ErrorMessage errors={errors} pristine={pristine} touched={touched} />
    </>
  )
}

interface ErrorMessageProps {
  errors?: Record<string, string>
  pristine: boolean
  touched?: { [key: string]: boolean }
}

const ErrorMessage = ({
  errors,
  pristine,
  touched = {}
}: ErrorMessageProps): null | JSX.Element => {
  if (!errors || pristine) {
    return null
  }

  const touchedFields = Object.entries(touched)
    .map(([key, wasTouched]) => (wasTouched ? null : key))
    .filter(Boolean) as string[]
  const displayedErrors = omit(errors, touchedFields)
  if (isEmptyObject(displayedErrors)) {
    return null
  }

  return (
    <TritonError
      header="Your Program cannot be created"
      messages={Object.values(omit(errors, touchedFields))}
    />
  )
}

export default Form
