import React from 'react'
import styled from 'styled-components'
import { Card } from 'src/components/Layout'
import { AQUA_BLUE, DARK_BLUE, GRAY, IGNIS_RED, GALVANIZED } from 'src/constants/colors'
import formatNumber from 'src/utils/formatNumber'
import { AssetStatistics } from '../dataAccess'

interface MaterialProps {
    materialData: AssetStatistics
}

export const MaterialBreakdown = ({ materialData }: MaterialProps): JSX.Element => {
    const {
        galvanizedAssets,
        leadAssets,
        nonLeadAssets,
        totalAssets,
        unknownAssets
    } = materialData

    return (
        <div style={{ minWidth: '375px' }}>
            <Grid>
                <Total>
                    <h4>
                        Lines submitted: <span>{formatNumber(totalAssets)}</span>
                    </h4>
                </Total>
                <MaterialCard style={{ backgroundColor: IGNIS_RED }}>
                    <h4>Lead</h4>
                    <p>{formatNumber(leadAssets)}</p>
                </MaterialCard>
                <MaterialCard style={{ backgroundColor: GALVANIZED }}>
                    <h4>GRR</h4>
                    <p>{formatNumber(galvanizedAssets)}</p>
                </MaterialCard>
                <MaterialCard style={{ backgroundColor: AQUA_BLUE }}>
                    <h4>Non-lead</h4>
                    <p>{formatNumber(nonLeadAssets)}</p>
                </MaterialCard>
                <MaterialCard style={{ background: DARK_BLUE }}>
                    <h4>Unknown</h4>
                    <p>{formatNumber(unknownAssets)}</p>
                </MaterialCard>
            </Grid>
        </div>
    )
}

const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  & > h3 {
    grid-column-start: span 2;
  }
  & > div.field {
    margin: 0 !important;
  }
`

const Total = styled.div`
grid-column-start: span 2;
background-color: ${GRAY};
color: white;
padding: 8px 24px;
border-radius: 4px;
text-align: start;
& h4 {
  font-size: 1.33rem;
  line-height: 3;
  font-weight: 600;
}
& span {
  font-weight: 400;
  line-height: 1.33333333;
  font-size: 2rem;
  margin-left: 8px;
}
`

const MaterialCard = styled(Card)`
text-align: start;
& > h4 {
  font-weight: 600;
  font-size: 1.33rem;
  line-height: 1.2;
  margin: 0;
  color: white;
}
& > p {
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
  color: white;
}
`

