import { faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { DARK_GRAY } from 'src/constants/colors'
import { formatPhoneString } from 'src/utils/formatPhoneNumber'
import { Value } from './Layout'

interface Props {
  extension?: string
  phoneNumber?: string
  type: 'landline' | 'mobile'
}

const PhoneNumber = ({ extension, phoneNumber, type }: Props): JSX.Element => {
  return (
    <Value>
      {formatPhoneString(phoneNumber)}
      {extension && (
        <span style={{ marginLeft: '6px', color: DARK_GRAY }}>
          ext. {extension}
        </span>
      )}
      {type === 'mobile' && (
        <FontAwesomeIcon
          icon={faMobileScreenButton}
          style={{ marginLeft: '6px' }}
        />
      )}
    </Value>
  )
}

export default PhoneNumber
