import { FilterType } from '@120wateraudit/envirio-components'
import { PWSSamplingEventStatus } from '@120wateraudit/envirio-components/dist/models'
import { useMemo } from 'react'

import { useEvents } from 'src/hooks'
import { SamplingEvent } from 'src/types/SamplingEvents'
import { EventType } from './dataAccess'

export const useEventNames = (programId: number): string[] => {
  const { events = [] } = useEvents(programId)
  return events.map(event => event.name)
}

export const useEventsFilter = (
  programId?: number,
  key = 'samplingEventId',
  multipleSelections = false,
  showAllOption = true,
  hasDefaultArrayValue = false
) => {
  const { events } = useEvents(programId)

  const allChoiceOption = useMemo(() => {
    const allChoice = showAllOption ? [{ text: 'All', value: 'All' }] : []
    return allChoice
  }, [showAllOption])

  return useMemo(
    () => ({
      defaultValue: hasDefaultArrayValue ? [] : 'All',
      key,
      label: 'Sampling Event',
      multipleSelections,
      options: [
        ...allChoiceOption,
        ...events.map(e => ({ text: e.name, value: e.id }))
      ],
      type: FilterType.ListSelection
    }),
    [hasDefaultArrayValue, events, key, multipleSelections, allChoiceOption]
  )
}

interface EventsByStatus {
  Active: SamplingEvent[]
  All: SamplingEvent[]
  Completed: SamplingEvent[]
  Inactive: SamplingEvent[]
}

interface EventsByType {
  Generic: SamplingEvent[]
  PitcherFilter: SamplingEvent[]
  PostSampling: SamplingEvent[]
  PreSampling: SamplingEvent[]
}

export const useEventsGroupedByStatus = (
  events: SamplingEvent[] = [],
  status: string,
  isReplacementProgram: boolean
): SamplingEvent[] => {
  return useMemo(() => {
    const groups: EventsByStatus = {
      Active: [],
      All: [],
      Completed: [],
      Inactive: []
    }

    events.forEach(e => {
      // Always add the event to All.
      groups.All.push(e)

      if (e.status === PWSSamplingEventStatus.Active) {
        groups.Active.push(e)
      } else if (e.status === PWSSamplingEventStatus.Completed) {
        groups.Completed.push(e)
      } else if (e.status === PWSSamplingEventStatus.Inactive) {
        groups.Inactive.push(e)
      }
    })

    if (isReplacementProgram) {
      const eventTypes: EventsByType = {
        Generic: [],
        PitcherFilter: [],
        PostSampling: [],
        PreSampling: []
      }

      const currentEvents = groups[status]

      currentEvents.forEach(e => {
        if (e.eventType === 'Pre-Replacement') {
          eventTypes.PreSampling.push(e)
        } else if (e.eventType === 'Pitcher/Filter') {
          eventTypes.PitcherFilter.push(e)
        } else if (e.eventType === 'Post-Replacement') {
          eventTypes.PostSampling.push(e)
        } else if (e.eventType === 'Sampling') {
          eventTypes.Generic.push(e)
        }
      })
      const { Generic, PitcherFilter, PostSampling, PreSampling } = eventTypes
      const orderedPreSampling = PreSampling.sort(
        (a, b) => Number(a.startsOn) - Number(b.startsOn)
      )
      const orderedPitcherFilter = PitcherFilter.sort(
        (a, b) => Number(a.startsOn) - Number(b.startsOn)
      )
      const orderedPostSampling = PostSampling.sort(
        (a, b) => Number(a.startsOn) - Number(b.startsOn)
      )
      const orderedGeneric = Generic.sort(
        (a, b) => Number(a.startsOn) - Number(b.startsOn)
      )

      return [
        ...orderedPreSampling,
        ...orderedPitcherFilter,
        ...orderedPostSampling,
        ...orderedGeneric
      ]
    }

    return groups[status]
  }, [events, status, isReplacementProgram])
}

export const getEventTypeOptions = (
  eventTypes: EventType[],
  hasBothSamplingTypes: boolean,
  selectedType?: 'post' | 'pre'
) => {
  let samplingEventTypes = eventTypes
  const eventTypeName =
    selectedType === 'pre' ? 'Pre-Replacement' : 'Post-Replacement'

  if (!hasBothSamplingTypes) {
    samplingEventTypes = samplingEventTypes.filter(
      et => et.name === eventTypeName
    )
  }

  return samplingEventTypes.map(et => ({
    key: et.id,
    text: et.name,
    value: et.name
  }))
}
