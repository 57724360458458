import React, { DragEvent } from 'react'
import { DropzoneProps, ImageFile } from 'react-dropzone'
import { Modal } from 'semantic-ui-react'

import FileUpload from 'src/components/FileUpload'

interface Props {
  multiple?: boolean
  saveImage: DropzoneProps['onDrop']
  uploadPrompt?: string
  uploading: boolean
  onClose(): void
}

const UploadModal = ({
  multiple = false,
  onClose,
  saveImage,
  uploadPrompt,
  uploading
}: Props): JSX.Element => {
  const uploadFile = (
    images: ImageFile[],
    rejected: ImageFile[],
    event: DragEvent<HTMLDivElement>
  ) => {
    if (saveImage) saveImage(images, rejected, event)
    onClose()
  }
  return (
    <>
      <Modal.Header>Upload Files</Modal.Header>
      <Modal.Content>
        <FileUpload
          headerText={uploadPrompt}
          multiple={multiple}
          uploadFile={uploadFile}
          uploading={uploading}
        />
      </Modal.Content>
    </>
  )
}

export default UploadModal
