import { ContactMethod } from '@120wateraudit/envirio-components/dist/models'
import { DeepPartial } from '@reduxjs/toolkit'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import gql from 'graphql-tag'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { ValidationErrors } from 'final-form'

import { contactsClient } from 'src/apollo-clients'
import { SearchParams } from 'src/hooks'
import {
  ContactUpdate,
  ContactUpdateResponse,
  UPDATE_CONTACT_MUTATION
} from 'src/modules/Contacts/dataAccess'
import {
  PaginatedResponseV2,
  Tags,
  buildParameters,
  pwsApi
} from 'src/services'
import { Location } from 'src/types/Location'
import { isValidPhone, isValidZip } from './utils'

type Contact = {
  accountId: number
  addressLine1?: string
  addressLine2?: string
  alternativePhone?: string
  alternativePhoneExt?: string
  city?: string
  contactMethod?: ContactMethod
  email?: string
  firstName?: string
  id: number
  isAlternativePhoneMobile?: boolean
  isPhoneMobile?: boolean
  lastName?: string
  primaryLanguageId?: number
  primaryPhone?: string
  primaryPhoneExt?: string
  state?: string
  zip?: string
}

type LocationUpdate = DeepPartial<Location & { contact?: Contact }> &
  Pick<Location, 'id'>

type CreateLocation = {
  contact?: Omit<Partial<Contact>, 'id'>
  location: Omit<Partial<Location>, 'id'>
}

const PRIMARY_CONTACT = 1
const OMITTED_CONTACT_PROPERTIES = [
  'accountId',
  'id',
  'status',
  'relationType',
  'relationId',
  // state and zip exist in the form, but our API insists on postalCode and stateOrProvince
  'zip',
  'state'
]

const updatePrimaryContact = async (locationId: number, contact?: Contact) => {
  if (!contact) {
    return
  }

  return await contactsClient.mutate<ContactUpdateResponse, ContactUpdate>({
    mutation: UPDATE_CONTACT_MUTATION,
    variables: {
      accountId: contact.accountId,
      contact: {
        ...omit(contact, OMITTED_CONTACT_PROPERTIES),
        designation: PRIMARY_CONTACT,
        postalCode: contact.zip,
        stateOrProvince: contact.state
      },
      contactId: contact.id,
      relationId: locationId,
      relationType: 'Locations'
    }
  })
}

const locationsApi = pwsApi.injectEndpoints({
  endpoints: builder => ({
    createLocation: builder.mutation<Location, CreateLocation>({
      invalidatesTags: [Tags.Locations],
      query: location => ({
        data: { ...location, createSamplesOnImport: false },
        method: 'post',
        url: '/locations'
      })
    }),
    getLocations: builder.query<PaginatedResponseV2<Location>, SearchParams>({
      providesTags: [Tags.Locations],
      query: arg => ({
        overrideBaseUrl: '/pws/accounts/',
        url: `/locations?${buildParameters(arg, true)}`
      })
    }),
    getLocation: builder.query<Location, number | string>({
      providesTags: (r, e, locationId) => [{id: locationId, type: Tags.Locations}],
      query: locationId => ({url: `/locations/${locationId}`})
    }),
    updateLocation: builder.mutation<Location, LocationUpdate>({
      invalidatesTags: [Tags.Locations],
      queryFn: async (update, _queryApi, _extraOptions, baseQuery) => {
        const { contact, ...locationUpdate } = update
        try {
          await updatePrimaryContact(update.id, contact)
        } catch (e) {
          return { error: { ...e } }
        }

        return (await baseQuery({
          data: locationUpdate,
          method: 'put',
          url: `/locations/${update.id}`
        })) as QueryReturnValue<Location>
      }
    })
  })
})

export const {
  useCreateLocationMutation,
  useGetLocationsQuery,
  useUpdateLocationMutation,
  useGetLocationQuery, 
} = locationsApi

export const ADD_LOCATIONS_TO_PROGRAM = gql`
  mutation addLocationsToProgram(
    $accountId: ID!
    $locationIds: [ID]!
    $programId: ID!
    $eventId: ID
  ) {
    addLocationsToProgram(
      accountId: $accountId
      locationIds: $locationIds
      programId: $programId
      eventId: $eventId
    )
  }
`

export interface Values extends Record<string, unknown> {
  addressLine1: string
  addressLine2?: string
  city: string
  contact?: {
    accountId: number
    addressLine1?: string
    addressLine2?: string
    alternativePhone?: string
    alternativePhoneExt?: string
    city?: string
    contactMethod?: ContactMethod
    email?: string
    firstName?: string
    id?: number
    isAlternativePhoneMobile?: boolean
    isPhoneMobile?: boolean
    lastName?: string
    primaryLanguageId?: number
    primaryPhone?: string
    primaryPhoneExt?: string
    salutation?: string
    state?: string
    zip?: string
  }
  disadvantagedNeighborhood?: boolean
  externalId?: string
  id?: number
  mailingAddressLine1?: string
  mailingAddressLine2?: string
  mailingCity?: string
  mailingState?: string
  mailingZip?: string
  profile?: {
    parcelNumber?: string
    propertyClassification?: string | null
    tier?: number | null
  }
  state: string
  zip: string
}

const validateAddress = (
  values: Record<string, unknown>,
  addressKeys: string[],
  optionalKeys: string[] = []
): ValidationErrors => {
  const address: Record<string, unknown> = pick(values, [
    ...addressKeys,
    ...optionalKeys
  ])

  const hasSomeAddress = Object.values(address).filter(Boolean).length > 0
  if (!hasSomeAddress) {
    return {}
  }

  const errors = Object.entries(address).reduce((errs, [key, value]) => {
    if (!value && !optionalKeys.includes(key)) {
      errs[key] = 'Required'
    }

    return errs
  }, {})

  addressKeys.forEach(key => {
    if (!address.hasOwnProperty(key)) {
      errors[key] = 'Required'
    }
  })

  return errors
}

const validateMailingAddress = (
  values: Values | Record<string, any>
): ValidationErrors => {
  return validateAddress(
    values,
    ['mailingAddressLine1', 'mailingCity', 'mailingState', 'mailingZip'],
    ['mailingAddressLine2']
  )
}

const validateContactAddress = (
  values: Values | Record<string, any>
): ValidationErrors => {
  const { contact } = values
  if (!contact) {
    return {}
  }

  return validateAddress(
    contact,
    ['addressLine1', 'city', 'state', 'zip'],
    ['addressLine2']
  )
}

const validateContact = (
  values: Values | Record<string, any>,
  type: 'create' | 'edit'
): ValidationErrors => {
  if (!values.contact) {
    return {}
  }

  const contactErrors: ValidationErrors = { ...validateContactAddress(values) }
  const { contact } = values
  const {
    addressLine1,
    addressLine2,
    alternativePhone,
    alternativePhoneExt,
    city,
    contactMethod,
    isAlternativePhoneMobile,
    isPhoneMobile,
    lastName,
    primaryLanguageId,
    primaryPhone,
    primaryPhoneExt,
    salutation,
    state,
    zip
  } = contact
  const firstName = contact.firstName?.trim()
  const email = contact.email?.trim()

  // We require at least first name or an email.
  if (!firstName && !email && type === 'edit') {
    contactErrors.email = 'Required'
    contactErrors.firstName = 'Required'
  }

  //If creating ,and they choose a contact info option they have to include a first name or email.
  if (
    (addressLine1 ||
      addressLine2 ||
      alternativePhone ||
      alternativePhoneExt ||
      city ||
      contactMethod ||
      isAlternativePhoneMobile ||
      isPhoneMobile ||
      lastName ||
      primaryLanguageId ||
      primaryPhone ||
      primaryPhoneExt ||
      salutation ||
      state ||
      zip) &&
    !firstName &&
    !email &&
    type === 'create'
  ) {
    contactErrors.email = 'Required'
    contactErrors.firstName = 'Required'
  }

  if (contact.primaryPhone && !isValidPhone(contact.primaryPhone)) {
    contactErrors.primaryPhone = 'Invalid'
  }

  if (contact.alternativePhone && !isValidPhone(contact.alternativePhone)) {
    contactErrors.alternativePhone = 'Invalid'
  }

  return Object.keys(contactErrors).length ? { contact: contactErrors } : {}
}

export const validate = (
  values: Values | Record<string, any>,
  type: 'create' | 'edit'
): ValidationErrors => {
  const errors: ValidationErrors = {
    ...validateMailingAddress(values),
    ...validateContact(values, type)
  }
  if (!values.addressLine1) {
    errors.addressLine1 = 'Required'
  }
  if (!values.city) {
    errors.city = 'Required'
  }
  if (!values.state) {
    errors.state = 'Required'
  }
  if (!values.zip) {
    errors.zip = 'Required'
  } else if (!isValidZip(values.zip)) {
    errors.zip = 'Invalid Format'
  }

  return errors
}
