import { Loader } from '@120wateraudit/envirio-components'
import React, { FC, Suspense, lazy, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactGA from 'react-ga4'
import { Route, Switch, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { PWSAccount } from '@120wateraudit/envirio-components/dist/models'
import Footer from 'src/components/Footer'
import { MediaContextProvider } from 'src/components/Media'
import PollingNotifications from 'src/components/PollingNotifications'
import { ToS } from 'src/components/ToS'
import useVersionNotification from 'src/components/VersionNotification'
import ModalSystem from 'src/modules/Modal'
import Navigation from 'src/modules/Navigation'
import { FeaturesProvider } from 'src/providers/FeaturesProvider'
import { User } from 'src/types/User'
import Logger from 'src/utils/Logger'
import { useCurrentUser } from './UserProvider'
import { useHasMoreThanReadPermission } from 'src/hooks'

import 'react-toastify/dist/ReactToastify.css'
import './App.css'

// Top Level
const Locations = lazy(() => import('src/pages/Locations'))
const ProgramHub = lazy(() => import('src/pages/ProgramHub'))
const AllSamples = lazy(() => import('src/pages/AllSamples'))
const Communications = lazy(() => import('src/pages/Communications'))
const ServiceLinesPage = lazy(() => import('src/pages/ServiceLines'))
const AdminPage = lazy(() => import('src/pages/Admin'))
const StateReporting = lazy(() => import('src/pages/StateReporting'))
const SwitchAccount = lazy(() => import('src/pages/SwitchAccount'))
// Details
const AddServiceLine = lazy(() => import('src/containers/ServiceLines/New'))
const ProgramDetails = lazy(() => import('src/pages/ProgramDetails'))
const EventDetails = lazy(() => import('src/pages/EventDetails'))
const Import = lazy(() => import('src/pages/Import'))
const ImportResults = lazy(() => import('src/pages/ImportResults'))
const LocationDetailsProvider = lazy(
  () => import('src/containers/Locations/detailsProvider')
)
const SampleDetailsProvider = lazy(
  () => import('src/containers/Samples/detailsProvider')
)

const usePageviews = () => {
  const history = useHistory()
  useEffect(() => {
    history.listen(location => {
      Logger.trackPageView(location.pathname)
    })
  }, [history])
}

const App = (): JSX.Element => {
  const { account, user } = useCurrentUser()
  const userHasPermission = useHasMoreThanReadPermission()
  ReactGA.set({ userId: user?.id })
  usePageviews()
  useVersionNotification()

  return (
    <AppContainer account={account} user={user}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/accounts/switch/:accountId">
            <SwitchAccount />
          </Route>
          <Route path="/state-reporting/:active?">
            <StateReporting />
          </Route>
          <Route path="/imports/results/:batchId">
            <ImportResults />
          </Route>
          <Route path="/imports/:importRoute/:batchTypeRoute">
            <Import />
          </Route>
          {userHasPermission && (
            <Route path="/administration/:activeItem?">
              <AdminPage />
            </Route>
          )}
          <Route path={'/inventory/new/:locationId?'}>
            <AddServiceLine />
          </Route>
          <Route path={'/inventory/:active?'}>
            <ServiceLinesPage />
          </Route>
          <Route
            path={[
              '/communications',
              '/communications/letters',
              '/communications/letters/create/:id',
              '/communications/letters/update/:id'
            ]}>
            <Communications />
          </Route>
          <Route path="/samples/:id">
            <SampleDetailsProvider />
          </Route>
          <Route path="/samples">
            <AllSamples />
          </Route>
          <Route path="/locations/:id/:activeItem?">
            <LocationDetailsProvider />
          </Route>
          <Route path="/locations">
            <Locations />
          </Route>
          <Route path="/events/:id/:active?">
            <EventDetails />
          </Route>
          <Route path="/programs/:id/:active?">
            <ProgramDetails />
          </Route>
          <Route path={['/', '/programs']}>
            <ProgramHub />
          </Route>
        </Switch>
      </Suspense>
    </AppContainer>
  )
}

const AppContainer: FC<{ account?: PWSAccount; user?: User }> = ({
  account,
  children,
  user
}) => {
  return (
    <ToS userId={user?.id}>
      <MediaContextProvider>
        <FeaturesProvider>
          <DndProvider backend={HTML5Backend}>
            <PollingNotifications account={account}>
              <ModalSystem>
                <Container>
                  <Navigation />
                  <ChildrenContainer>{children}</ChildrenContainer>
                  <Footer />
                </Container>
              </ModalSystem>
            </PollingNotifications>
          </DndProvider>
        </FeaturesProvider>
      </MediaContextProvider>
    </ToS>
  )
}

const Container = styled.main`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'header' 'main' 'footer';
`

const ChildrenContainer = styled.section`
  grid-area: main;
  background-color: #f6f7f7;
`

export default App
