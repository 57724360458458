import styled from 'styled-components'

const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  gap: 1.5rem 0;
`

export default ThreeColumnGrid
