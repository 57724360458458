import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'

import { Row } from 'src/components/Layout'
import { TableContext } from '../context'
import { CustomFieldFilterDefinition } from '../types'
import Filter from './Filter'
import { CustomFieldFilterValue, useCustomFieldFilters } from './hooks'

const getFilterValuesForCustomField = (
  filter: CustomFieldFilterDefinition,
  customFieldValues: CustomFieldFilterValue
) => {
  const value = filter
    ? customFieldValues.value.value1 ??
      filter.defaultValue
    : undefined
  const value2 = filter
    ? customFieldValues.value.value2 ?? undefined
    : undefined
  const operator = filter ? customFieldValues.value['operator'] : undefined
  return { operator, value, value2 }
}

const Filters = (): null | JSX.Element => {
  const {
    activeFilters = {},
    filters = [],
    onFilterChanged
  } = useContext(TableContext)
  const builtInFilterControls = useMemo(() => {
    return Object.entries(activeFilters).map(([key, value]) => (
      <Filter
        activeFilters={activeFilters}
        filter={filters.find(f => f.key === key)}
        key={key}
        onFilterChanged={onFilterChanged}
        value={value}
      />
    ))
  }, [activeFilters, filters, onFilterChanged])

  const customFieldFilters = useCustomFieldFilters(activeFilters)
  const customFieldControls = useMemo(() => {
    return customFieldFilters.map((cff, index) => {
      const filter = filters.find(
        f => f.customField && f.customField.name === cff.name
      ) as CustomFieldFilterDefinition
      const { operator, value, value2 } = getFilterValuesForCustomField(
        filter,
        cff
      )
      return (
        <Filter
          activeFilters={activeFilters}
          filter={filter}
          key={filter?.key || index}
          onFilterChanged={onFilterChanged}
          operator={operator}
          value={value}
          value2={value2}
        />
      )
    })
  }, [customFieldFilters, filters, onFilterChanged, activeFilters])

  const filterControls = [...builtInFilterControls, ...customFieldControls]

  if (filterControls.length === 0) {
    return null
  }

  return <Container>{filterControls}</Container>
}

const Container = styled(Row)`
  flex: 1;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 8px;

  &&& button {
    height: 38px;
    background-color: #fff;
    border: 1px solid #e5e7eb;
  }

  &&& i {
    color: #555b66;
  }
`

export default Filters
