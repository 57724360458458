import React, { FC, useState } from 'react'
import uniqBy from 'lodash/uniqBy'
import { DropdownItemProps } from 'semantic-ui-react'
import { Dropdown } from '@120wateraudit/envirio-components'
import styled from 'src/theme'

import { SampleData } from 'src/containers/Communications/Editor/types'
import SampleComponent from './SampleComponent'
import { useToggle } from 'src/hooks'
import { SamplingEventWithAnalytes } from 'src/components/Communications/CreateOrUpdateCommunication/OptionsCard'
import { ErrorMessage } from 'src/modules/Modal/shared'

interface Props {
  data: SampleData
  samplingEventData: SamplingEventWithAnalytes[]
  eventId: number | undefined
  onClick: (property: string) => void
}

const buildSampleDropDownOptions = (samples: number) => {
  const sampleOptions: DropdownItemProps = []
  for (let i = 1; i <= samples; i++) {
    sampleOptions.push({
      key: i,
      text: `Sample ${i}`,
      value: i
    })
  }

  return sampleOptions
}

export const getSampleNumbers = (selectedEvent: SamplingEventWithAnalytes): number => {
  const skuQuantities: number[] = uniqBy(
    selectedEvent.protocolDefinitions?.map(p => p.sku),
    'id'
  ).map(sku => sku.sampleQuantity)

  return skuQuantities.reduce((prev, curr) => prev + curr, 0)
}

const SampleDataModalContent: FC<Props> = ({
  data,
  samplingEventData,
  onClick,
  eventId
}) => {
  const [selectedSampleIndex, setSelectedSampleIndex] = useState<
    number | undefined
  >(undefined)

  const [isSampleDropdownEnabled, toggleSampleDropdown] = useToggle(true)

  const selectedEvent = samplingEventData.find(se => se.id === eventId)

  if (!selectedEvent) {
    return (
      <ErrorMessage>Please select a valid sampling event first</ErrorMessage>
    )
  }

  const sampleNumbers = getSampleNumbers(selectedEvent)

  return (
    <>
      {isSampleDropdownEnabled && (
        <SampleDropDown
          fluid
          onChange={(_, e) => {
            setSelectedSampleIndex(e.value)
            toggleSampleDropdown()
          }}
          optionTextMaxLength={45}
          options={buildSampleDropDownOptions(sampleNumbers)}
          placeholder="Select Sample Number"
          search
          value={selectedSampleIndex}
        />
      )}
      {selectedSampleIndex && (
        <SampleComponent
          data={data[0]}
          sampleAnalytes={selectedEvent.samplingEventAnalytes}
          sampleNumber={selectedSampleIndex}
          onClick={p => {
            p.startsWith('CF ')
              ? onClick(`{{[CF-Samples].[${p.replace('CF ', '')}]}}`)
              : p.startsWith('{{[CF')
              ? onClick(`${p}`)
              : onClick(`{{s.${selectedSampleIndex - 1}.${p}}}`)
          }}
        />
      )}
    </>
  )
}

export default SampleDataModalContent

const SampleDropDown = styled(Dropdown)`
  align-self: center;
  width: 60%;
  padding-bottom: 1.5rem;
`
