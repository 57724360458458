import moment from 'moment'
import React, { FC, memo } from 'react'
import { TableCell, TableRow } from 'semantic-ui-react'
import type {
  PredictionObject,
  ServiceLineFragment
} from 'src/containers/ServiceLines/dataAccess/types'
import { oneLineAddress } from 'src/utils/oneLineAddress'
import styled from 'styled-components'
import { pushRoute } from '../../../utils/navigation'
import { DataLink } from '../../Layout'
import PredictionBadge from '../PredictionBadge'
import ServiceLinesMaterial from '../ServiceLinesMaterial'

const ClickableTableRow = styled(TableRow)`
  cursor: pointer;
`

const TableCellBold = styled(TableCell)`
  font-weight: 700;
  font-size: 13px;
`

type Prediction = {
  predictionObject: PredictionObject
}

type ServiceLineRowProps = Prediction & ServiceLineFragment

function formatDate(date: Date) {
  if (!date) {
    return '-'
  }

  return moment(date).format('MM/DD/YYYY')
}

const shouldDisplayPrediction = (predictionObject?: PredictionObject) => {
  if (predictionObject && predictionObject.result !== undefined) {
    return true
  }
  return false
}

const ServiceLineRow: FC<ServiceLineRowProps> = ({
  children,
  predictionObject,
  ...line
}) => {
  const address = oneLineAddress(line.location)
  const publicMaterial = line.serviceLineAsset?.publicMaterial
  const privateMaterial = line.serviceLineAsset?.privateMaterial
  const tier = line.location.profile?.tier
  const programs = line.location.programs
  const events = line.location.samplingEvents

  const displayPrediction = shouldDisplayPrediction(predictionObject)

  const handleRowClick = () =>
    pushRoute(`/locations/${line.location.id}/inventory`)

  return (
    <ClickableTableRow>
      {children}
      <TableCellBold onClick={handleRowClick}>
        {line.externalId || '-'}
      </TableCellBold>
      <TableCell onClick={handleRowClick}>{address || '-'}</TableCell>
      <TableCell onClick={handleRowClick}>{tier || '-'}</TableCell>
      <TableCell>
        {(programs?.length &&
          programs.map(p => (
            <DataLink href={`/programs/${p.id}/overview`} key={p.id}>
              {p.name}
              <br />
            </DataLink>
          ))) ||
          '-'}
      </TableCell>
      <TableCell>
        {(events?.length &&
          events.map(e => (
            <DataLink
              href={`/programs/${e.programId}/overview`}
              key={e.programId}>
              {e.name}
              <br />
            </DataLink>
          ))) ||
          '-'}
      </TableCell>
      <TableCell>
        <PredictionBadge
          predictionLevel={predictionObject?.result}
          hasPredictionDate={!!predictionObject?.predictionDate}
        />
      </TableCell>
      <TableCell>
        {displayPrediction ? formatDate(predictionObject?.predictionDate) : '-'}
      </TableCell>
      <TableCell onClick={handleRowClick}>
        <ServiceLinesMaterial material={publicMaterial} />
      </TableCell>
      <TableCell onClick={handleRowClick}>
        <ServiceLinesMaterial material={privateMaterial} />
      </TableCell>
    </ClickableTableRow>
  )
}

export default memo(ServiceLineRow)
