import { Loader, Modal } from '@120wateraudit/envirio-components'
import React, { Suspense, memo, useEffect, useReducer, useState } from 'react'

import { CommunicationStatus } from '@120wateraudit/communications-types'
import { useLazyQuery } from '@apollo/client'
import { contactsClient } from 'src/apollo-clients'
import {
  COMMUNICATION_PREVIEW,
  CommunicationPreviewData,
  CommunicationPreviewVariables
} from 'src/components/Communications/PDFPreview/dataAccess'
import {
  CommunicationData,
  CommunicationInput
} from 'src/containers/Communications/EditorSuite/data-access'
import {
  DispatchType,
  layoutContainerReducer
} from 'src/containers/Communications/EditorSuite/layoutContainerReducer'

const PDFModal = React.lazy(
  () => import('src/components/Communications/PDFPreview')
)

interface PDFPreviewContainerProps {
  accountId: number
  data: CommunicationData
  isOpen: boolean
  setErrorMessage: React.Dispatch<React.SetStateAction<undefined | string>>
  setZIndex: React.Dispatch<React.SetStateAction<number>>
  closePreview: () => void
  toggle: () => void
}

interface State {
  communication: CommunicationInput
  content: string
}

const PDFModalContainer: React.FC<PDFPreviewContainerProps> = ({
  accountId,
  closePreview,
  data,
  isOpen,
  setErrorMessage,
  setZIndex,
  toggle
}) => {
  const { communication, content } = data.communication
  const [state, setState] = useState<undefined | State>(undefined)
  const [error, dispatch] = useReducer(layoutContainerReducer, {
    communication,
    isActive: communication.status === CommunicationStatus.Active,
    status: communication.status || CommunicationStatus.Draft,
    triggerCondition: undefined
  })

  const createCommunicationPreview = (): State => {
    return {
      communication: {
        communicationType: communication.communicationType,
        eventId: communication.eventId,
        id: communication.id,
        name: communication.name,
        status: communication.status,
        templateId: communication.templateId,
        triggerCondition: communication.triggerCondition,
        triggerType: communication.triggerType
      },
      content
    }
  }

  const [
    previewQuery,
    { data: previewData, error: previewError, loading: previewLoading }
  ] = useLazyQuery<CommunicationPreviewData, CommunicationPreviewVariables>(
    COMMUNICATION_PREVIEW,
    {
      client: contactsClient,
      fetchPolicy: 'network-only',
      variables: {
        accountId,
        ...(state as State)
      }
    }
  )

  useEffect(() => {
    setErrorMessage(undefined)
    const runQuery = async () => {
      const params = await createCommunicationPreview()
      setZIndex(-1)
      setState(params)

      previewQuery()
    }
    runQuery()
  }, [])

  useEffect(() => {
    setZIndex(1000)
    if (previewError) {
      setErrorMessage(previewError.graphQLErrors[0].message)
    }
  }, [previewError])

  useEffect(() => {
    if (previewError) {
      setTimeout(() => {
        dispatch({
          type: DispatchType.ErrorCleanup
        })
      }, 5000)
    }
  }, [error])

  const name = communication.name || communication.communicationType
  const title = `${name}-${new Date().toISOString()}`

  return (
    <>
      {!previewLoading && previewData && (
        <Suspense fallback={<></>}>
          <PDFModal
            isOpen={isOpen}
            loading={previewLoading}
            template={previewData && previewData.communicationPreview}
            title={title}
            toggle={closePreview}
          />
        </Suspense>
      )}
      {previewLoading && !previewError && (
        <Modal
          content={<Loader />}
          isOpen
          style={{
            content: {
              minHeight: '45%'
            }
          }}
          toggle={toggle}
        />
      )}
    </>
  )
}

export default memo(PDFModalContainer)
