import { colors, fontSize, lineHeight } from '@120wateraudit/envirio-components'
import styled from 'styled-components'

import { ButtonsWrapper } from 'src/components/Layout/ButtonsWrapper'

export const ModalWrapper = styled.div`
  margin: 3rem;
  display: grid;
  grid-template-columns: auto;
  gap: 1rem 0;
  justify-items: center;
`

export const ModalHeader = styled.h5`
  text-align: center;
  padding: 0;
  margin: 0;
`

export const ModalFooterWrapper = styled.div`
  display: block;
  text-align: center;
  margin: 0 3rem 3rem 3rem;
`

export const ModalButtonWrapper = styled(ButtonsWrapper)`
  margin-top: 2rem;
`

export const ConfirmNumberOfSamples = styled.div`
  font-size: 14px;
`

export const FormText = styled.div`
  font-size: ${fontSize.body};
  line-height: ${lineHeight.body};
  font-weight: 300;
  color: ${colors.black50};
  text-align: center;
  margin: 0;
`
