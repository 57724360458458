import { Button, Loader, fontSize } from '@120wateraudit/envirio-components'
import React, { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as PaperCheckIcon } from 'src/assets/images/paper-check.svg'
import { Error } from 'src/components/Error'
import {
  ConfirmNumberOfSamples,
  ModalButtonWrapper,
  ModalFooterWrapper,
  ModalHeader,
  ModalWrapper
} from './shared'

interface Props {
  error?: Error
  isSaving: boolean
  selectedSamples: unknown[]
  onCancel(): void
  onComplete(): void
  onSave(): Promise<boolean>
}

const ConfirmModal: FC<Props> = ({
  error,
  isSaving,
  onCancel,
  onComplete,
  onSave,
  selectedSamples
}) => {
  const save = useCallback(async () => {
    const saved = await onSave()
    if (saved) {
      onComplete()
    }
  }, [onSave, onComplete])
  return (
    <>
      <ModalWrapper>
        <PaperCheckIcon />
        <ModalHeader>Confirm</ModalHeader>
        <ConfirmMoveSamplesWrapper>
          {isSaving && <Loader />}
          {!isSaving && (
            <>
              <ConfirmNumberOfSamples>
                You are about to complete <b>{selectedSamples.length}</b> sample
                {selectedSamples.length === 1 ? '' : 's'}.
              </ConfirmNumberOfSamples>
              {error && (
                <>
                  <ErrorWrapper>
                    <Error error={error} />
                  </ErrorWrapper>
                  <ErrorLinkWrapper>
                    <Link to="/communications/letters">
                      Communications letters
                    </Link>
                  </ErrorLinkWrapper>
                </>
              )}
            </>
          )}
        </ConfirmMoveSamplesWrapper>
      </ModalWrapper>
      <ModalFooterWrapper>
        <ModalButtonWrapper>
          <Button disabled={isSaving} onClick={onCancel}>
            Back
          </Button>
          <Button disabled={isSaving} onClick={save} variant="primary">
            Confirm
          </Button>
        </ModalButtonWrapper>
      </ModalFooterWrapper>
    </>
  )
}

const ConfirmMoveSamplesWrapper = styled.div`
  display: block;
  text-align: center;
`

const ErrorWrapper = styled.div`
  padding-top: 25px;
`

const ErrorLinkWrapper = styled.div`
  padding: 5px 0;
  font-size: ${fontSize.caption};
`

export default ConfirmModal
