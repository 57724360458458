import React, { FC } from 'react'
import { SamplingEventAnalyteFragment } from 'src/components/Communications/CreateOrUpdateCommunication/OptionsCard'

import { SampleData } from 'src/containers/Communications/Editor/types'
import { useToggle } from 'src/hooks'
import { DataComponentsWrapper } from 'src/modules/Modal/shared'
import InsertableComponent from '../InsertableComponent'
import ModalTitle from '../ModalTitle'
import SampleArrayComponent from './SampleArrayComponent'

interface Props {
  data: SampleData
  sampleAnalytes: SamplingEventAnalyteFragment[] | undefined
  sampleNumber: number
  onClick: (property: string) => void
}

const SampleComponent: FC<Props> = ({
  sampleNumber,
  data,
  onClick,
  sampleAnalytes
}) => {
  const [isResultsArrayOpen, toggleResultsArray] = useToggle(false)
  const [isActive, toggleActive] = useToggle(true)

  //Here we don't need results so we need this linter-disable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { r, ...sampleProperties } = data

  return (
    <>
      <ModalTitle
        title="Sample"
        itemNumber={sampleNumber}
        onSelect={toggleActive}
        isSelected={isActive}
      />
      <DataComponentsWrapper>
        {!isResultsArrayOpen && isActive && (
          <>
            {Object.keys(sampleProperties).map((key, index) => (
              <InsertableComponent
                key={`${key}-${sampleProperties[key]}-${index}`}
                propertyName={key}
                onClick={() => onClick(key)}
              />
            ))}
            {sampleAnalytes && (
              <InsertableComponent
                propertyName="results"
                onClick={toggleResultsArray}
                isExpandable={true}
              />
            )}
          </>
        )}
      </DataComponentsWrapper>
      {isResultsArrayOpen && isActive && sampleAnalytes && (
        <SampleArrayComponent
          customFields={data.r[0].customFields}
          sampleAnalytes={sampleAnalytes}
          onClick={p => {
            const property = p.split('.')[1]
            if (property.startsWith('CF ')) {
              onClick(
                `{{[CF-Sample Results].[${property.replace('CF ', '')}]}}`
              )
            } else {
              onClick(`r.${p}`)
            }
          }}
        />
      )}
    </>
  )
}

export default SampleComponent
