import {
  Dropdown as EnvirioDropdown,
  colors
} from '@120wateraudit/envirio-components'
import React, { CSSProperties, FC, useRef } from 'react'
import { FieldRenderProps } from 'react-final-form'
import {
  Dropdown as SUIRDropdown,
  DropdownProps,
  FormField,
  Popup
} from 'semantic-ui-react'
import styled from 'styled-components'

export type Props = DropdownProps &
  FieldRenderProps<string, HTMLElement> & {
    className?: string
    label?: string
    style?: CSSProperties
    stateValidation?: boolean
    tooltip?: string
  }

const Dropdown: FC<Props> = ({
  input,
  meta: { error, pristine, touched },
  stateValidation,
  ...rest
}) => {
  const onChange: DropdownProps['onChange'] = (_event, { value }) => {
    input.onChange(value)
    input.onBlur()
  }

  return (
    <DropdownWithErrors
      {...input}
      error={!pristine && touched && !!error}
      onChange={onChange}
      {...rest}
      stateValidation={stateValidation}
    />
  )
}

const getBorderColor = props => (props.error ? colors.error : 'inherit')

const DropdownWithErrors = styled(EnvirioDropdown)`
  &&& {
    & > div {
      display: flex;
      justify-content: space-between;
    }
    .ui.search.dropdown div.text {
      font-size: 13px;
    }

    .ui.dropdown {
      border-color: ${({ stateValidation }) =>
        stateValidation ? '#ffc720' : 'rgba(0, 0, 0, 0.25)'}!important;
    }
    .ui.error {
      border-color: ${getBorderColor} !important;
    }
  }
`

export const TritonDropdown = ({
  className,
  input,
  label,
  meta: { error },
  style,
  stateValidation,
  ...rest
}: Props) => {
  const onChange: DropdownProps['onChange'] = (_event, { value }) => {
    input.onChange(value)
  }
  const onBlur: DropdownProps['onBlur'] = () => input.onBlur()
  const onFocus: DropdownProps['onFocus'] = () => input.onFocus()

  const labelRef = useRef(null);
  return (
    <Popup
      content={rest.tooltip}
      disabled={!rest.tooltip}
      context={labelRef}
      hideOnScroll
      trigger={
        <Field
          className={className}
          error={!!error}
          style={style}
          stateValidation={stateValidation}>
          {label && <label ref={labelRef}>{label}</label>}
          <SUIRDropdown
            {...input}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            selection
            {...rest}
          />
          <ValidationError>{error}</ValidationError>
        </Field>
      }
    />
  )
}

const Field = styled(FormField)`
  &&&.field {
    & > .ui.selection.dropdown {
      border-width: 2px;
      background-color: white;
      border-color: ${({ error, stateValidation }) =>
        error
          ? 'var(--ignis-red)'
          : stateValidation
          ? '#ffc720'
          : 'rgba(0, 0, 0, 0.25)'} !important;
      & > .text {
        font-size: 14px;
      }
    }
    & > label {
      color: #333;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 4px;
    }
  }
` as typeof FormField

const ValidationError = styled.p`
  position: absolute;
  color: var(--ignis-red);
`

export default Dropdown
