import { Button } from '@120wateraudit/envirio-components'
import uniqBy from 'lodash/uniqBy'
import React from 'react'

import ProtocolDefinitions from 'src/containers/ProtocolDefinitions'
import Modal from 'src/modules/Modal/ModalContents'
import { SamplingEvent } from 'src/types/SamplingEvents'

interface Props {
  event: SamplingEvent
  onClose(): void
}

const ViewProtocolModal = ({ event, onClose }: Props): JSX.Element => {
  const primaryAction = (
    <Button onClick={onClose} variant="primary">
      Close
    </Button>
  )
  return (
    <Modal primaryAction={primaryAction} title="Event Protocol">
      <ProtocolDefinitions
        protocols={uniqBy(event.protocolDefinitions, 'skuId').map(pd => ({
          name: pd.sku.name,
          quantity: pd.sku.sampleQuantity,
          skuId: pd.skuId
        }))}
        readOnly
        style={{ padding: '0 1rem' }}
      />
    </Modal>
  )
}

export default ViewProtocolModal
