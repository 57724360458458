import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@120wateraudit/envirio-components'
interface Props {
  icon: JSX.Element
  label: string
  text: string
  isSelected?: boolean
  onClick?: () => void
}

export const OptionCard = ({
  icon,
  label,
  text,
  isSelected,
  onClick
}: Props) => (
  <Wrapper isSelected={isSelected} onClick={onClick}>
    <IconWrapper isSelected={isSelected}>{icon}</IconWrapper>
    <ContentWrapper>
      <LabelWrapper>{label}</LabelWrapper>
      <TextWrapper>{text}</TextWrapper>
    </ContentWrapper>
  </Wrapper>
)

const Wrapper: any = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 76px;
  width: 392px;
  border: 1px solid ${(props: any) => getAccentColor(props.isSelected)};
  border-radius: 1px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  padding: 0.9rem;
  cursor: pointer;
`

const ContentWrapper = styled.div`
  margin-left: 0.5rem;
`

const IconWrapper: any = styled.div`
  & > svg {
    g {
      fill: ${(props: any) => getAccentColor(props.isSelected)};
    }
  }
`

const LabelWrapper = styled.div`
  margin-top: 3px;
  font-size: 1.33rem;
  color: ${colors.black75};
`

const TextWrapper = styled.div`
  font-size: 0.95rem;
  margin-top: 0.4rem;
  color: ${colors.black50};
`

const getAccentColor = (isSelected: boolean) =>
  isSelected ? colors.primary : colors.black50
