import get from 'lodash/get'
import React, { useCallback } from 'react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'

import KebabCell from './Cells/KebabCell'
import { Column, CommonProps, RowData } from './types'

function renderCells<T extends RowData>(record: T, index: number) {
  return ({ accessor, invalid, key, rowProps = {} }: Column<T>) => {
    let contents: React.ReactNode = null

    if (typeof accessor === 'string') {
      const value = get(record, accessor)
      if (value !== 0 && !value) {
        contents = '--'
      } else {
        // We're making a choice to cast to a string here and trusting the implementer.
        contents = value as string
      }
    } else if (typeof accessor === 'function') {
      contents = accessor(record)
    }

    if (invalid) {
      return (
        <InvalidCell {...rowProps} key={`row-${index}-${key}`}>
          {contents}
        </InvalidCell>
      )
    }

    return (
      <StyledCell {...rowProps} key={`row-${index}-${key}`}>
        {contents}
      </StyledCell>
    )
  }
}

const StyledCell = styled(Table.Cell)`
  &&& {
    font-size: 14px;
    line-height: 1.14285714;
    color: #333;
  }
` as typeof Table.Cell

const InvalidCell = styled(Table.Cell)`
  &&& {
    font-size: 14px;
    line-height: 1.14285714;
    color: #333;
    background-color: #fff8db !important;
    box-shadow: 0 0 0 1px #b58105 inset, 0 0 0 0 transparent;
  }
` as typeof Table.Cell

function EmptyBody(): JSX.Element {
  return (
    <Table.Body>
      <Table.Row>
        <Table.Cell>
          <div style={{ minHeight: '50vh' }} />
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  )
}

function TableBody<T extends RowData>({
  columns,
  data,
  rowActions = [],
  showEmptyBody = false,
  setValidatedColumns
}: CommonProps<T> & {
  showEmptyBody?: boolean
}): JSX.Element {
  const renderRow = useCallback(
    (record: T, index: number) => {
      let validatedColumns: Column<T>[] = []

      if (setValidatedColumns) {
        validatedColumns = setValidatedColumns(record, columns)
      }

      return (
        <Table.Row key={`row-${index}`}>
          {validatedColumns.length > 0
            ? validatedColumns.map(renderCells(record, index))
            : columns.map(renderCells(record, index))}
          {!!rowActions.length && (
            <KebabCell actions={rowActions} record={record} />
          )}
        </Table.Row>
      )
    },
    [columns, rowActions, setValidatedColumns]
  )
  if (showEmptyBody) {
    return <EmptyBody />
  }

  return <Table.Body>{data.map(renderRow)}</Table.Body>
}

export default TableBody
