import React from 'react'
import { Badge } from '@120wateraudit/waterworks'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Table from 'src/components/Table'
import { Column } from 'src/components/Table/types'
import Row from 'src/components/Row'
import { Submission, SubmissionStatus } from 'src/types/Submission'
import { formatDate } from 'src/utils/formatDate'
import formatNumber from 'src/utils/formatNumber'
import { getColorFromStatus } from '../StateInventory/SubmissionStatusBadge'
import { SubmitterCell } from 'src/modules/ServiceLines/StateInventory/Cells'

const StatusBadge = ({ status }: Submission) => {
  const convertedStatus = status as unknown as keyof typeof SubmissionStatus
  const color = getColorFromStatus(convertedStatus)

  return (
    <Badge
      style={{
        backgroundColor: color,
        color: convertedStatus === 'voided' ? 'white' : 'black',
        display: 'inline-block',
        textAlign: 'center',
        minWidth: '125px',
        width: '100%'
      }}>
      {SubmissionStatus[status]}
    </Badge>
  )
}

const calculateTotalLines = (submission: Submission): string => {
  const {
    totalServiceLinesLead,
    totalServiceLinesGalvanized,
    totalServiceLinesNonLead,
    totalServiceLinesUnknown
  } = submission
  const totalLines =
    totalServiceLinesLead +
    totalServiceLinesGalvanized +
    totalServiceLinesNonLead +
    totalServiceLinesUnknown

  return formatNumber(totalLines)
}

const columns: Column<Submission>[] = [
  {
    Header: 'Submitted Date',
    key: 'submittedOn',
    sortable: false,
    accessor: s => formatDate(s.submittedOn)
  },
  {
    Header: 'Submitted By',
    accessor: SubmitterCell,
    key: 'submitter',
    sortable: false
  },
  { accessor: StatusBadge, key: 'status', sortable: false },
  {
    Header: 'Total Lines',
    key: 'totalServiceLinesSubmitted',
    sortable: false,
    accessor: s => calculateTotalLines(s)
  }
]

interface Props {
  onClose(): void
  latestSubmission: Submission
}

const SubmittedSubmissionModal = ({
  onClose,
  latestSubmission
}: Props): JSX.Element => {
  return (
    <Container>
      <Row style={{ justifyContent: 'center' }}>
        <FontAwesomeIcon
          size="5x"
          icon={faTriangleExclamation}
          style={{ marginRight: '4px', color: '#396BAB' }}
        />
      </Row>

      <h3 style={{ textAlign: 'center', marginTop: '1rem' }}>Not So Fast</h3>
      <BlueText
        style={{
          textAlign: 'center',
          marginTop: '1rem'
        }}>
        You have already submitted during this submission period.
      </BlueText>
      <Table data={[latestSubmission]} columns={columns} />
      <h6
        style={{ textAlign: 'center', marginTop: '1rem', lineHeight: '1.75' }}>
        If you need to make changes to your submission, please reach out to your
        State Primacy Agency for assistance.
      </h6>
      <Row style={{ justifyContent: 'center' }}>
        <Button onClick={onClose} type="button">
          Close
        </Button>
      </Row>
    </Container>
  )
}

const BlueText = styled.h6`
  &&& {
    color: #396bab !important;
  }
`

const Container = styled.div`
  padding: 36px 40px;
`

export default SubmittedSubmissionModal
