import * as GoogleMaps from '@google/maps'
import { Address } from '@120wateraudit/envirio-components/dist/models'
import { GOOGLE_MAPS_API_KEY } from '../constants'

import { GeocodingType } from '../types/Geocoding'

const GMaps = GoogleMaps.createClient({
  key: GOOGLE_MAPS_API_KEY
})

export const geocodeAddress = (data: {
  type: GeocodingType
  id: number
  address: Partial<Address>
}): any => {
  const { type, id, address } = data

  address.city = address.city || 'Zionsville'
  address.state = address.state || 'IN'

  return new Promise((resolve, reject) => {
    GMaps.geocode(
      {
        address: `${address.addressLine1} ${address.addressLine2 || ''}, ${
          address.city
        }, ${address.state} ${address.zip}`
      },
      (err, response) => {
        if (!err) {
          if (response.json.results && response.json.results.length) {
            const { lat, lng } = response.json.results[0].geometry.location
            if (lat && lng) {
              resolve({
                type,
                id,
                lat,
                lng
              })
            } else {
              reject('No lat/lng received from geocoding service')
            }
          } else {
            reject('No results received from geocoding service')
          }
        } else {
          reject(err)
        }
      }
    )
  })
}
