import { Program } from '@120wateraudit/envirio-components/dist/models'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useCallback, useEffect, useState } from 'react'
import { DropdownItemProps } from 'semantic-ui-react'
import { pwsClient } from 'src/apollo-clients'
import { useCurrentUserUnsafe } from 'src/router/UserProvider'
import { SamplingEvent } from 'src/types/SamplingEvents'

const PROGRAMS_QUERY = gql`
  query programs(
    $accountId: Int!
    $programType: String
    $activeEventsOnly: Boolean
  ) {
    programs(
      accountId: $accountId
      programType: $programType
      activeEventsOnly: $activeEventsOnly
    ) {
      id
      name
      programTypeId
    }
  }
`
const SAMPLING_EVENTS_QUERY = gql`
  query samplingEvents(
    $accountId: Int!
    $programType: String
    $active: Boolean
  ) {
    samplingEvents(
      accountId: $accountId
      programType: $programType
      active: $active
    ) {
      id
      name
      programId
      startsOn
      status
      primaryAnalyte {
        id
        name
        displayName
      }
    }
  }
`

export type ProgramFragment = Pick<Program, 'id' | 'name' | 'programTypeId'>
export type SamplingEventFragment = Pick<
  SamplingEvent,
  'id' | 'name' | 'primaryAnalyte' | 'programId' | 'startsOn' | 'status'
>

interface ProgramsQuery {
  programs: ProgramFragment[]
}

interface SamplingEventsQuery {
  samplingEvents: SamplingEventFragment[]
}

export const useEvents = (programId?: number, active?: boolean) => {
  const {
    account: { id }
  } = useCurrentUserUnsafe()
  const [events, setEvents] = useState<SamplingEventFragment[]>([])
  const { loading } = useQuery<SamplingEventsQuery>(SAMPLING_EVENTS_QUERY, {
    client: pwsClient,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const eventData = data?.samplingEvents ?? []
      setEvents(eventData)
    },
    skip: !id,
    variables: { accountId: id, active }
  })

  if (programId) {
    return {
      events: events.filter(event => event.programId === programId),
      loading
    }
  }

  return { events, loading }
}

export const usePrograms = (programsWithActiveEventsOnly?: boolean) => {
  const {
    account: { id }
  } = useCurrentUserUnsafe()
  const [programs, setPrograms] = useState<ProgramFragment[]>([])

  const { loading } = useQuery<ProgramsQuery>(PROGRAMS_QUERY, {
    client: pwsClient,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const programData = data?.programs ?? []
      setPrograms(programData)
    },
    skip: !id,
    variables: {
      accountId: id,
      activeEventsOnly: programsWithActiveEventsOnly
    }
  })
  return { loading, programs }
}

interface HookProps {
  event: undefined | SamplingEventFragment
  events: SamplingEventFragment[]
  loading: boolean
  onEventSelect: DropdownItemProps['onChange']
  onProgramSelect: DropdownItemProps['onChange']
  program: undefined | ProgramFragment
  programs: ProgramFragment[]
}

const useProgramsAndEvents = (
  programsWithActiveEventsOnly?: boolean,
  programId?: number
): HookProps => {
  const [event, setEvent] = useState<undefined | SamplingEventFragment>()
  const { events, loading: loadingEvents } = useEvents(
    undefined,
    programsWithActiveEventsOnly
  )

  const [program, setProgram] = useState<undefined | ProgramFragment>()
  const { loading: loadingPrograms, programs } = usePrograms(
    programsWithActiveEventsOnly
  )

  const onEventSelect = useCallback(
    (_, data: DropdownItemProps) => {
      const selectedEvent = events?.find(event => event.id === data?.value)
      setEvent(selectedEvent)
    },
    [events]
  )

  const onProgramSelect = useCallback(
    (_, data: DropdownItemProps) => {
      const selectedProgram = programs?.find(
        program => program.id === data?.value
      )
      setProgram(selectedProgram)
    },
    [programs]
  )

  useEffect(() => {
    if (programId) {
      onProgramSelect(undefined, { value: programId })
    }
  }, [programId, onProgramSelect])

  return {
    event,
    events: program
      ? events?.filter(event => event.programId === program.id) ?? []
      : events,
    loading: loadingEvents || loadingPrograms,
    onEventSelect,
    onProgramSelect,
    program,
    programs
  }
}

export default useProgramsAndEvents
