import { Ownership } from 'src/types/enums/Ownership'
import { useServiceLineMaterials } from 'src/containers/ServiceLines/dataAccess'
import { getMaterialDropdownOptions } from 'src/utils/serviceLineUtils'
import { convertEnumToListItems } from 'src/utils/selectlist'
import { YesNoUnknownNA } from 'src/types/enums/YesNoUnknownNA'

// removes the 'N/A - Single Ownership' option from public/private materials if it doesn't apply
export const useMaterialDropdownOptionsByOwnership = (type, ownership) => {
  const { data: materials } = useServiceLineMaterials()
  const materialOptions = getMaterialDropdownOptions(
    materials?.serviceLineMaterials ?? []
  )

  const singleOwnershipValue = materials?.serviceLineMaterials.filter(
    val => val.selectionValue === 'N/A - Single Ownership'
  )

  const singleOwnershipSelectionValue = singleOwnershipValue?.[0].selectionValue

  if (type === 'public' && Ownership[ownership] === Ownership['System-Owned']) {
    return materialOptions?.filter(
      val => val.key !== singleOwnershipSelectionValue
    )
  }
  if (
    type === 'private' &&
    Ownership[ownership] === Ownership['Customer-Owned']
  ) {
    return materialOptions?.filter(
      val => val.key !== singleOwnershipSelectionValue
    )
  }
  if (Ownership[ownership] === Ownership['Split']) {
    return materialOptions?.filter(
      val => val.key !== singleOwnershipSelectionValue
    )
  }
  if (Ownership[ownership] === Ownership['Unknown']) {
    return materialOptions?.filter(
      val => val.key !== singleOwnershipSelectionValue
    )
  }
  return materialOptions
}

export const useInstalledAfterLeadBanDropdownOptionsByOwnership = (
  material,
  singleOwnershipId
) => {
  const yesNoUnknownNADropdownOptions = convertEnumToListItems(YesNoUnknownNA)

  if (material !== singleOwnershipId) {
    return yesNoUnknownNADropdownOptions?.filter(
      val => val.key !== YesNoUnknownNA['N/A']
    )
  }

  return yesNoUnknownNADropdownOptions
}
