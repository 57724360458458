import { useDatum } from 'src/hooks'
import { fileProcessorApi } from 'src/services'
import { ExportDetails } from 'src/types/ExportDetails'
import { ImportBatchDetails } from 'src/types/ImportBatchDetails'

export enum BulkImportType {
  None = 'None',
  Assets = 'Assets',
  ServiceLines = 'ServiceLines',
  Locations = 'Locations',
  Contacts = 'Contacts',
  UpdateLocations = 'UpdateLocations',
  Combined = 'Combined',
  CombinedIN = 'CombinedIN',
  CombinedGA = 'CombinedGA',
  CombinedDE = 'CombinedDE',
  CombinedAZ = 'CombinedAZ',
  CombinedRI = 'CombinedRI',
  CombinedOR = 'CombinedOR'
}

export enum ExportTypes {
  PwsSamples = 'pwssamples',
  Locations = 'locations',
  Kits = 'kits',
  ServiceLines = 'servicelines',
  Inventory = 'inventory'
}

interface ExportParams {
  exportType: ExportTypes
}

export enum BatchType {
  Import = 'Import',
  Enrich = 'Enrich'
}

export enum ImportIntentOptions {
  CreateAndUpdate = 'Both',
  Create = 'Insert',
  Update = 'Update'
}

interface BatchParams {
  batchType: BatchType
}

export interface ImportOptions {
  addressMatchingEnabled: boolean
  importIntent: ImportIntentOptions
}

const fileProcessor = fileProcessorApi.injectEndpoints({
  endpoints: builder => ({
    getExports: builder.query<ExportDetails[], void>({
      query: () => ({ url: '/export/list' })
    }),
    getExportByType: builder.query<ExportDetails[], ExportParams>({
      query: ({ exportType }) => ({ url: `/export/list/${exportType}` })
    }),
    getBatches: builder.query<ImportBatchDetails[], BatchParams>({
      query: ({ batchType }) => ({ url: `/batches/list/${batchType}` })
    }),
    getImportLoad: builder.query<string, void>({
      query: () => ({ url: '/importLoad' })
    })
  })
})

export const {
  useGetExportsQuery,
  useGetBatchesQuery,
  useGetExportByTypeQuery,
  useGetImportLoadQuery
} = fileProcessor

export const useImportDetail = (batchId: number) => {
  const url = `/file-processor/batches/${batchId}`
  const { data, loading } = useDatum<ImportBatchDetails>(url)

  return { batchDetail: data, loading }
}
