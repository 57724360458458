import { useCallback, useContext, useEffect, useMemo } from 'react'

import { ModalContext } from 'src/modules/Modal'
import { useAccount } from 'src/router/UserProvider'
import { toastError, toastSuccess } from 'src/utils/toast'
import { Values, useCreateLocationMutation } from '../../dataAccess'

export const useInitialValues = (): Values => {
  const { id: accountId } = useAccount()

  return useMemo(() => {
    const values: Values = {
      addressLine1: '',
      addressLine2: undefined,
      city: '',
      disadvantagedNeighborhood: undefined,
      externalId: undefined,
      name: undefined,
      mailingAddressLine1: undefined,
      mailingAddressLine2: undefined,
      mailingCity: undefined,
      mailingState: undefined,
      mailingZip: undefined,
      profile: undefined,
      state: '',
      zip: ''
    }

    values.contact = {
      accountId,
      addressLine1: undefined,
      addressLine2: undefined,
      alternativePhone: undefined,
      alternativePhoneExt: undefined,
      city: undefined,
      contactMethod: undefined,
      email: undefined,
      firstName: undefined,
      isAlternativePhoneMobile: undefined,
      isPhoneMobile: undefined,
      lastName: undefined,
      primaryLanguageId: undefined,
      primaryPhone: undefined,
      primaryPhoneExt: undefined,
      salutation: undefined,
      state: undefined,
      zip: undefined
    }

    return values
  }, [accountId])
}

interface ApiError {
  data: {
    errors?: { constraints: Record<string, string> }[]
  }
}

const isApiError = (error: unknown): error is ApiError => {
  return (error as ApiError).data !== undefined
}

export const useCreateLocation = () => {
  const { openModal } = useContext(ModalContext)
  const [
    trigger,
    { data, error, isError, isLoading, isSuccess, isUninitialized }
  ] = useCreateLocationMutation()
  useEffect(() => {
    if (!isUninitialized && !isLoading && isSuccess) {
      openModal('viewLocation', { location: data }, 'drawer')
      toastSuccess('Location has been created')
    }
  }, [data, isError, isLoading, isSuccess, isUninitialized, openModal])

  const onSubmit = useCallback(
    async (values: Values) => {
      const { contact } = values
      const location = {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        disadvantagedNeighborhood: values.disadvantagedNeighborhood,
        externalId: values.externalId,
        name: values.name,
        mailingAddressLine1: values.mailingAddressLine1,
        mailingAddressLine2: values.mailingAddressLine2,
        mailingCity: values.mailingCity,
        mailingState: values.mailingState,
        mailingZip: values.mailingZip,
        profile:
          (values.profile && {
            parcelNumber: values.profile?.parcelNumber,
            propertyClassification: values.profile?.propertyClassification ?? null,
            tier: values.profile?.tier ? Number(values.profile?.tier) : null
          }) ??
          undefined,
        state: values.state,
        zip: values.zip
      }

      const { state, zip, ...reducedContact } = contact || {}
      const newContact =
        !contact?.firstName && !contact?.email
          ? undefined
          : {
              ...reducedContact,
              postalCode: zip,
              stateOrProvince: state
            }

      const newValues = {
        contact: newContact,
        location
      }

      try {
        await trigger(newValues).unwrap()
      } catch {
        // TODO scroll to top of container to show errors OR list errors in Toast
        toastError('An error occurred. Location could not be created.')
      }
    },
    [trigger]
  )

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined
    }

    if (isApiError(error)) {
      const errors = error.data?.errors || []
      return errors.flatMap(e => Object.values(e.constraints))
    }

    return ['Please try again or contact support@120water.com']
  }, [error, isError])

  return useMemo(() => ({ errors, onSubmit }), [errors, onSubmit])
}
