import React from 'react'
import { Button, ButtonProps, Icon, IconProps } from 'semantic-ui-react'
import styled from 'styled-components'

interface Props extends Omit<ButtonProps, 'basic' | 'icon'> {
  className?: string
  name: IconProps['name']
  iconColor?: string
  basic?: boolean
  title?: string
}

const IconButton = ({
  className,
  name,
  onClick,
  iconColor,
  basic,
  title,
  ...props
}: Props): JSX.Element => (
  <BorderlessButton
    {...props}
    basic={!!basic}
    className={className}
    icon
    iconColor={iconColor}
    onClick={onClick}
    title={title}>
    <Icon name={name} />
  </BorderlessButton>
)

const BorderlessButton = styled(Button)<{
  iconColor?: string
}>`
  &&& {
    box-shadow: none;
  }

  &&& i.icon {
    color: ${props => props.iconColor};
  }
`

export default IconButton
