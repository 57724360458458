import React from 'react'
import styled from 'styled-components'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IGNIS_RED, AQUA_BLUE } from 'src/constants/colors'

interface Props {
  className?: string
  removeFilter?: () => void
}

const RemoveFilterButton = ({
  className,
  removeFilter
}: Props): null | JSX.Element => {
  return (
    <RemoveFilterIcon
      className={className}
      color={IGNIS_RED}
      icon={faTimesCircle}
      onClick={removeFilter}
      size="lg"
    />
  )
}

const RemoveFilterIcon = styled(FontAwesomeIcon)<{
  marginLeft: string
}>`
  &&& {
    cursor: pointer;
    :hover {
      color: ${AQUA_BLUE};
    }
    margin-left: 15px;
    margin-top: 3px;
  }
`

export default RemoveFilterButton
