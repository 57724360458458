import {useCallback, useMemo} from 'react'

import {
  ActiveFilters,
  CustomFieldFilterDefinition,
  FilterableProps,
  ValidFilterValue
} from '../types'
import {CustomFieldDefinition} from "@120wateraudit/custom-fields-ui-components";
import {CustomFieldDataType} from "@120wateraudit/envirio-components/dist/models";
import moment from "moment/moment";

export type CustomFieldFilterValue = {
  name: string
  value: {
    dataType: number
    operator: string
    value1?: ValidFilterValue
    value2?: ValidFilterValue | null
  }
}

export const useCustomFieldFilters = (
  filters: ActiveFilters
): CustomFieldFilterValue[] =>
  useMemo(
    () => (filters.customFields ? JSON.parse(filters.customFields) : []),
    [filters.customFields]
  )

const removeCustomFieldFilter = (
  fields: CustomFieldFilterValue[],
  indexToRemove: number
): null | string => {
  const newFields = [
    ...fields.slice(0, indexToRemove),
    ...fields.slice(indexToRemove + 1)
  ]
  return newFields.length ? JSON.stringify(newFields) : null
}

const addCustomFieldFilter = (
  fields: CustomFieldFilterValue[],
  customField: CustomFieldDefinition
): string => {
  const newFilter: CustomFieldFilterValue = {
    name: customField.name ?? '',
    value: getDefaultCustomFieldFilterValue(customField.dataType ?? '')
  }
  return JSON.stringify([...fields, newFilter])
}

const getDefaultCustomFieldFilterValue = (dataType: string) => {
  switch (dataType) {
    case 'DATE':
    case 'DATETIME':
      return {
        value: moment().toISOString(),
        dataType: CustomFieldDataType["Date/Time"].valueOf(),
        operator: 'lessThanOrEqualTo'
      }
    case 'HYPERLINK':
    case 'STRING':
      return {
        value: undefined,
        dataType: CustomFieldDataType.Text.valueOf(),
        operator: 'like'
      }
    case 'NUMBER':
      return {
        value2: null,
        value: '0',
        dataType: CustomFieldDataType.Number.valueOf(),
        operator: 'greaterThanOrEqualTo'
      }
    case 'BOOLEAN':
      return {
        value: undefined,
        dataType: CustomFieldDataType["True/False"].valueOf(),
        operator: 'greaterThanOrEqualTo'
      }
    case 'LIST':
    default:
      return {
        value: undefined,
        dataType: CustomFieldDataType["Pick List"].valueOf(),
        operator: 'equals'
      }
  }
}

const useToggleFilter = (
  fields: CustomFieldFilterValue[],
  filter: CustomFieldFilterDefinition,
  onFilterChanged: FilterableProps['onFilterChanged']
) => {
  const fieldIndex = useMemo(
    () =>
      fields.findIndex(
        cf => cf.name === filter.customField.name
      ),
    [filter.customField.id, fields]
  )
  const isActive = fieldIndex >= 0
  const onToggleFilter = useCallback(
    (checked: boolean) => {
      if (!onFilterChanged) {
        return
      }

      if (!checked && isActive) {
        onFilterChanged({
          key: 'customFields',
          value: removeCustomFieldFilter(fields, fieldIndex)
        })
      } else if (!isActive) {
        onFilterChanged({
          key: 'customFields',
          value: addCustomFieldFilter(fields, filter.customField)
        })
      }
    },
    [onFilterChanged, isActive, fields, filter.customField, fieldIndex]
  )
  return { isActive, onToggleFilter }
}

const changeCustomFieldFilter = (
  fields: CustomFieldFilterValue[],
  targetIndex: number,
  customField: CustomFieldDefinition,
  value?: ValidFilterValue,
  operator?: string,
  value2?: null | ValidFilterValue
): string => {
  const newFields = [...fields]
  const target = fields[targetIndex]
  const newValue = { ...target.value }
  if (operator !== undefined) {
    newValue.operator = operator
  }
  if (value !== undefined) {
    newValue.value1 = value
  }
  if (value2 !== undefined) {
    newValue.value2 = value2
  }
  const newFilter: CustomFieldFilterValue = {
    name: target.name,
    value: newValue
  }
  newFields.splice(targetIndex, 1, newFilter)
  return JSON.stringify(newFields)
}

const useChangeFilter = (
  fields: CustomFieldFilterValue[],
  filter: CustomFieldFilterDefinition,
  onFilterChanged: FilterableProps['onFilterChanged']
) => {
  const fieldIndex = useMemo(
    () =>
      fields.findIndex(
        cf => cf.name === filter.customField.name
      ),
    [filter.customField.id, fields]
  )
  const isActive = fieldIndex >= 0
  const onChangeValue = useCallback(
    (
      value: ValidFilterValue,
      operator?: string,
      value2?: null | ValidFilterValue
    ) => {
      if (!onFilterChanged || !isActive) {
        return
      }

      onFilterChanged({
        key: 'customFields',
        value: changeCustomFieldFilter(
          fields,
          fieldIndex,
          filter.customField,
          value,
          operator,
          value2
        )
      })
    },
    [onFilterChanged, isActive, fields, fieldIndex, filter.customField]
  )
  return { onChangeValue }
}

const useRemoveFilter = (
  fields: CustomFieldFilterValue[],
  filter: CustomFieldFilterDefinition,
  onFilterChanged: FilterableProps['onFilterChanged']
) => {
  const fieldIndex = useMemo(
    () =>
      fields.findIndex(
        cf => cf.name === filter.customField.name
      ),
    [filter.customField.name, fields]
  )
  const isActive = fieldIndex >= 0
  const removeFilter = useCallback(() => {
    if (!onFilterChanged || !isActive) {
      return
    }

    onFilterChanged({
      key: 'customFields',
      value: removeCustomFieldFilter(fields, fieldIndex)
    })
  }, [isActive, fields, fieldIndex, onFilterChanged])
  return { removeFilter }
}

export const useCustomFieldFilter = (
  activeFilters: ActiveFilters,
  filter: CustomFieldFilterDefinition,
  onFilterChanged: FilterableProps['onFilterChanged']
) => {
  const fields = useCustomFieldFilters(activeFilters)
  const { isActive, onToggleFilter } = useToggleFilter(
    fields,
    filter,
    onFilterChanged
  )

  const { onChangeValue } = useChangeFilter(fields, filter, onFilterChanged)
  const { removeFilter } = useRemoveFilter(fields, filter, onFilterChanged)
  return {
    isActive,
    onChangeValue,
    onToggleFilter,
    removeFilter
  }
}

interface ComplexValue {
  operator?: string
  value?: ValidFilterValue
  value2?: ValidFilterValue
}

const getComplexValue = (value?: ValidFilterValue) => {
  let complexValue: ComplexValue = {}
  if (typeof value === 'string') {
    complexValue = JSON.parse(value)
  }
  return complexValue
}

export const useComplexFieldFilter = (
  onFilterChanged: (value: string) => void,
  value?: ValidFilterValue
) => {
  const {
    operator,
    value: parsedValue,
    value2
  } = useMemo(() => getComplexValue(value), [value])
  const changeFilter = useCallback(
    (
      value?: ValidFilterValue,
      newOperator?: string,
      value2?: null | ValidFilterValue
    ) => {
      if (!onFilterChanged) return

      onFilterChanged(
        JSON.stringify({ operator: newOperator || operator, value, value2 })
      )
    },
    [onFilterChanged, operator]
  )
  return { changeFilter, operator, value: parsedValue, value2 }
}
