import { Button, Loader } from '@120wateraudit/envirio-components'
import {
  ProgramStatus,
  ProgramType
} from '@120wateraudit/envirio-components/dist/models'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { FORM_ERROR } from 'final-form'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { Card, Form as SUIForm } from 'semantic-ui-react'
import styled from 'styled-components'

import { post } from 'src/API'
import { DATE_FORMAT } from 'src/components/Forms/Datepicker'
import Modal from 'src/modules/Modal/ModalContents'
import { useAccount } from 'src/router/UserProvider'
import CreateForm, { Values, endDateCalculator, validate } from '../Form'
import {
  useGetHubMetricsQuery,
  useGetHubProgramsQuery,
  useGetPurchasedProgramTypesQuery
} from '../dataAccess'

const INITIAL_VALUES: Values = {
  assetTypeId: 1,
  doesPostSampling: true,
  doesPreSampling: true,
  endsOn: moment().add(1, 'year').format(DATE_FORMAT),
  name: '',
  programTypeId: undefined,
  replacementIntervalMonths: 12,
  startsOn: moment().format(DATE_FORMAT),
  status: ProgramStatus.Active
}

interface Props {
  onClose(): void
}

const CreateProgramModal = ({ onClose }: Props): JSX.Element => {
  const [programType, setProgramType] = useState<ProgramType>()
  const [step, setStep] = useState(0)
  if (step === 0 || !programType) {
    return (
      <TypeChoiceStep
        onClose={onClose}
        onNext={() => setStep(1)}
        selectedType={programType}
        setSelectedType={setProgramType}
      />
    )
  }

  return (
    <DetailsStep
      onClose={onClose}
      onPrevious={() => setStep(0)}
      programType={programType}
    />
  )
}

interface TypeChoiceProps extends Props {
  selectedType?: ProgramType
  onNext(): void
  setSelectedType(type: ProgramType): void
}

const TypeChoiceStep = ({
  onClose,
  onNext,
  selectedType,
  setSelectedType
}: TypeChoiceProps): JSX.Element => {
  const cancelAction = (
    <Button onClick={onClose} type="button">
      Cancel
    </Button>
  )
  const primaryAction = (
    <Button
      disabled={!selectedType}
      onClick={onNext}
      type="button"
      variant="primary">
      Next
    </Button>
  )

  const { data: programTypes = [], isLoading } =
    useGetPurchasedProgramTypesQuery()
  const options = programTypes.filter(pt => pt.name !== 'LSLR')

  return (
    <Modal
      cancelAction={cancelAction}
      primaryAction={primaryAction}
      title="What kind of Program are you running?">
      {isLoading && <Loader />}
      <CardGroup
        items={options.map(pt => ({
          raised: pt.id === selectedType?.id,
          color: pt.id === selectedType?.id ? 'blue' : undefined,
          header: pt.name,
          description: pt.description ?? '',
          onClick: () => setSelectedType(pt)
        }))}
      />
    </Modal>
  )
}

const CardGroup = styled(Card.Group)`
  justify-content: center;
`

interface DetailsProps extends Props {
  programType: ProgramType
  onPrevious(): void
}

const DetailsStep = ({
  onClose,
  onPrevious,
  programType
}: DetailsProps): JSX.Element => {
  const { id } = useAccount()
  const { refetch: refetchMetrics } = useGetHubMetricsQuery()
  const { refetch: refetchPrograms } = useGetHubProgramsQuery()
  const onSubmit = useCallback(
    async values => {
      const response = await post(`pws/rest/accounts/${id}/programs`, values)
      if (!response) {
        return { [FORM_ERROR]: 'Could not submit form' }
      }

      refetchMetrics()
      refetchPrograms()
      onClose()
      return undefined
    },
    [onClose, refetchMetrics, refetchPrograms, id]
  )
  return (
    <Form
      decorators={programType.name === 'REPLACEMENT' ? [endDateCalculator] : []}
      initialValues={{ ...INITIAL_VALUES, programTypeId: programType.id }}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        pristine,
        valid,
        submitting,
        errors,
        touched
      }) => (
        <SUIForm onSubmit={handleSubmit}>
          <Modal
            cancelAction={
              <Button type="button" onClick={onPrevious} disabled={submitting}>
                Back
              </Button>
            }
            icon={faFileInvoice}
            primaryAction={
              <Button
                type="submit"
                variant="primary"
                disabled={pristine || !valid || submitting}>
                {submitting ? 'Saving…' : 'Save'}
              </Button>
            }
            title={`Tell us about your ${programType.name} Program`}>
            <CreateForm
              programType={programType.name}
              type="create"
              pristine={pristine}
              errors={errors}
              touched={touched}
            />
          </Modal>
        </SUIForm>
      )}
      validate={validate}
    />
  )
}

export default CreateProgramModal
