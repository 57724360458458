import React, { FC } from 'react'
import styled from 'styled-components'
import { Badge, Icons } from '@120wateraudit/envirio-components'
import numeral from 'numeral'
import { IGNIS_RED } from 'src/constants/colors'

interface Props {
  predictionLevel?: number
  hasPredictionDate: boolean
  badgeTopMargin?: boolean
  emptyDisplay?: string
}

const PredictionBadge: FC<Props> = ({
  hasPredictionDate,
  predictionLevel,
  badgeTopMargin,
  emptyDisplay
}) => {
  if (!hasPredictionDate) {
    return <span>{emptyDisplay || '-'}</span>
  }

  if (predictionLevel || predictionLevel === 0) {
    if (predictionLevel === 1) {
      return (
        <InnerBadge
          badgeTopMargin={badgeTopMargin}
          style={{ background: IGNIS_RED }}>
          <Icons.Error color={'white'} />
          Verified Lead
        </InnerBadge>
      )
    }

    if (predictionLevel === 0) {
      return (
        <InnerBadge badgeTopMargin={badgeTopMargin} variant={'primary'}>
          <Icons.Drop color={'white'} />
          Verified Non-Lead
        </InnerBadge>
      )
    }

    if (predictionLevel >= 0.75) {
      return (
        <InnerBadge
          badgeTopMargin={badgeTopMargin}
          style={{ background: IGNIS_RED }}>
          <Icons.Error color={'white'} />
          {numeral(predictionLevel).format('0%')}
        </InnerBadge>
      )
    }

    if (predictionLevel < 0.75 && predictionLevel >= 0.25) {
      return (
        <InnerBadge badgeTopMargin={badgeTopMargin} variant={'warning'}>
          <Icons.Flag color={'white'} />
          {numeral(predictionLevel).format('0%')}
        </InnerBadge>
      )
    }

    if (predictionLevel < 0.25) {
      return (
        <InnerBadge badgeTopMargin={badgeTopMargin} variant={'primary'}>
          <Icons.Drop color={'white'} /> {numeral(predictionLevel).format('0%')}
        </InnerBadge>
      )
    }
  }

  return <span>{emptyDisplay || '-'}</span>
}

const InnerBadge = styled(Badge)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => (props.badgeTopMargin ? '10px' : '0px')};
  margin-left: 10px;
  border-radius: 10px;
`

export default PredictionBadge
