import { Location } from 'src/types/Location'

export const oneLineAddress = ({
  addressLine1,
  addressLine2,
  city,
  state,
  zip
}: LocationAddress) => {
  const trimmedAddressLine1 = addressLine1.trim()
  const cityStateZipStr = `${city.trim()}, ${state} ${zip}`

  if (!addressLine2) {
    return `${trimmedAddressLine1}, ${cityStateZipStr}`
  }
  return `${trimmedAddressLine1} ${addressLine2.trim()}, ${cityStateZipStr}`
}

type LocationAddress = Pick<
  Location,
  'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'
>
