import {
  TextField,
  colors,
  fontSize,
  lineHeight,
  spacing
} from '@120wateraudit/envirio-components'
import React, { FC } from 'react'
import styled from 'styled-components'

interface Analyte {
  name: string
  upperLimit: number
  isPrimary?: boolean
}

interface Props {
  analyte: Analyte
  showDetails: boolean
}

const SingleAnalyte: FC<Props> = ({ analyte, showDetails = true }) => {
  return (
    <DefinitionWrapper>
      {
        <SkuNameWrapper>
          {analyte.name}
          {analyte.isPrimary && <p>(primary)</p>}
        </SkuNameWrapper>
      }

      {showDetails && (
        <DetailsWrapper>
          <UpperLimitLabelWrapper>Upper limit</UpperLimitLabelWrapper>

          <UpperLimitValueWrapper>
            <TextField
              className="definitionUpperLimit"
              min={0}
              name="quantity"
              style={{ width: '6.25rem', height: '2.5rem', padding: 0 }}
              type="number"
              value={`${analyte.upperLimit}`}
              readOnly
            />
          </UpperLimitValueWrapper>
        </DetailsWrapper>
      )}
    </DefinitionWrapper>
  )
}

const DefinitionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacing.small};
`

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SkuNameWrapper = styled.div`
  font-size: ${fontSize.body};
  line-height: 1.714285714;
`

const UpperLimitLabelWrapper = styled.div`
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption};
  font-weight: 700;
  color: ${colors.black50};
`

const UpperLimitValueWrapper = styled.div`
  .definitionUpperLimit > input {
    padding: 8px !important;
    font-size: ${fontSize.caption} !important;
    line-height: ${lineHeight.caption} !important;
  }
`

export default SingleAnalyte
