import difference from 'lodash/difference'
import differenceBy from 'lodash/differenceBy'
import pluralize from 'pluralize'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { useMethods, usePrevious } from 'react-use'

import { SearchParams } from 'src/hooks'
import { SortDirection } from 'src/types/SortDirection'
import { LONG_TOAST } from 'src/utils/toast'
import {
  Column,
  ColumnVisibilityPayload,
  RowData,
  Sort,
  TableState,
  ValidFilterValue,
  ViewPayload
} from '../types'
import { convertSortToString, convertStringToSorts } from '../utils'
import { titleCase } from 'src/utils/titleCase'

const INITIAL_STATE: TableState = {
  activeFilters: {},
  columns: [],
  hiddenColumns: [],
  page: 1,
  pageSize: 20
}

function changePageSize(state: TableState, pageSize: number): TableState {
  localStorage.setItem('pwsStore/pageSize', `${pageSize}`)
  return { ...state, page: 1, pageSize }
}

function changeSearchValue(state: TableState, searchTerm?: string): TableState {
  return { ...state, page: 1, searchTerm }
}

function changeSort(state: TableState, sort?: Sort | Sort[]): TableState {
  if (!sort) {
    return { ...state, page: 1, sort: undefined }
  }

  if (Array.isArray(sort)) {
    const sortString = sort.map(s => convertSortToString(s)).join(',')
    return { ...state, page: 1, sort: sortString }
  }

  return {
    ...state,
    page: 1,
    sort: sort.direction === SortDirection.DESC ? `-${sort.key}` : sort.key
  }
}

type Filter = {
  key: string
  value?: null | ValidFilterValue
}

function addFilter(state: TableState, newFilter: Filter): TableState {
  if (newFilter.value === null) {
    throw Error('Cannot add a null filter value')
  }

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if (newFilter.value != null) {
    params.set(`filters.${newFilter.key}`, `${newFilter.value}`);
  } else {
    params.delete(`filters.${newFilter.key}`);
  }

  url.search = params.toString();
  
  window.history.pushState('', '', url.toString());

  return {
    ...state,
    activeFilters: { ...state.activeFilters, [newFilter.key]: newFilter.value },
    page: 1
  }
}

function removeFilter(state: TableState, filterToRemove: string): TableState {
  const newFilters = { ...state.activeFilters }
  delete newFilters[filterToRemove]

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  params.delete(`filters.${filterToRemove}`);

  url.search = params.toString();

  window.history.pushState('', '', url.toString());

  return {
    ...state,
    activeFilters: { ...newFilters },
    page: 1
  }
}

function changeHiddenColumns(
  state: TableState,
  { key, visible }: ColumnVisibilityPayload
): TableState {
  let columns = [...(state.hiddenColumns || [])]
  const columnIndex = columns.indexOf(key)
  if (columnIndex >= 0 && visible) {
    columns.splice(columnIndex, 1)
  } else if (columnIndex < 0 && !visible) {
    columns = [key, ...columns]
  }
  return { ...state, hiddenColumns: columns }
}

function removeInvalidColumns(
  data: string[],
  columns: Column[],
  triggerNotifications = true
): string[] {
  const customFieldColumns: string[] = []
  const stockColumns: string[] = []

  data.forEach(f => {
    if (!isNaN(Number(f))) {
      customFieldColumns.push(f)
    } else {
      stockColumns.push(f)
    }
  })

  const invalidCustomFieldColumns = customFieldColumns.filter(
    f => !columns.some(c => c.key === f)
  )
  const validCustomFieldColumns = customFieldColumns.filter(f =>
    columns.some(c => c.key === f)
  )

  const columnKeyValues = columns.map(c => c.key)
  const invalidColumns: string[] =
    stockColumns && difference(stockColumns, columnKeyValues)
  const validColumns: string[] =
    stockColumns && difference(stockColumns, invalidColumns)
  const pluralColumns =
    invalidColumns && pluralize('column', invalidColumns.length)
  const invalidColumnsText =
    invalidColumns && invalidColumns.map(f => titleCase(f)).join(', ')
  const pluralCustomFieldColumns = pluralize(
    'column',
    invalidCustomFieldColumns.length
  )

  const validViewColumns = [...validColumns, ...validCustomFieldColumns]
  if (!triggerNotifications) {
    return validColumns
  }

  if (
    invalidColumns &&
    invalidColumns.length > 0 &&
    invalidCustomFieldColumns.length > 0
  ) {
    toast(
      `The ${invalidColumnsText} ${pluralColumns} and ${invalidCustomFieldColumns.length} custom field ${pluralCustomFieldColumns}
       are no longer valid, everything else will still be applied in this view.`,
      LONG_TOAST
    )
  }
  if (
    invalidColumns &&
    invalidColumns.length === 0 &&
    invalidCustomFieldColumns.length > 0
  ) {
    toast(
      `${
        invalidCustomFieldColumns.length
      } custom field ${pluralCustomFieldColumns}
       ${
         invalidCustomFieldColumns.length > 1 ? 'are' : 'is'
       } no longer valid, everything else will still be applied in this view.`,
      LONG_TOAST
    )
  }
  if (
    invalidColumns &&
    invalidColumns.length > 0 &&
    invalidCustomFieldColumns.length === 0
  ) {
    toast(
      `The ${invalidColumnsText} ${pluralColumns} ${
        invalidColumns.length > 1 ? 'are' : 'is'
      } no longer valid, everything else will still be applied in this view.`,
      LONG_TOAST
    )
  }
  return validViewColumns
}

function buildColumnsForView(
  availableColumns: Column[],
  viewColumns: string[],
  viewHiddenColumns: string[]
): { columns: Column[]; hiddenColumns: string[] } {
  removeInvalidColumns(viewColumns, availableColumns)
  const hiddenColumns = removeInvalidColumns(
    viewHiddenColumns,
    availableColumns,
    false
  )
  const columns = viewColumns
    .map(key => availableColumns.find(c => c.key === key))
    .filter(Boolean) as Column[]
  const excludedColumns = differenceBy(availableColumns, columns, 'key')
  return {
    columns: [...columns, ...excludedColumns],
    hiddenColumns: [...hiddenColumns, ...excludedColumns.map(c => c.key)]
  }
}

function applyView(
  state: TableState,
  availableColumns: Column[],
  {
    activeFilters: viewActiveFilters,
    activeViewName: viewName,
    columns: viewColumns,
    hiddenColumns: viewHiddenColumns,
    sort
  }: ViewPayload
): TableState {
  const sortString = Array.isArray(sort)
    ? sort.map(s => convertSortToString(s)).join(',')
    : convertSortToString(sort)
  const { columns, hiddenColumns } = buildColumnsForView(
    availableColumns,
    viewColumns,
    viewHiddenColumns
  )
  return {
    ...state,
    activeFilters: { ...viewActiveFilters },
    activeViewName: viewName,
    columns,
    hiddenColumns: [...hiddenColumns, ...viewHiddenColumns],
    page: 1,
    sort: sortString
  }
}

function clearActiveView(state: TableState): TableState {
  return { ...state, activeViewName: '' }
}

export interface TableActions {
  changeFilter: ({ key, value }: Filter) => TableState
  changeHiddenColumns: (payload: ColumnVisibilityPayload) => TableState
  clear: () => TableState
  resetPage: () => TableState
  setColumns: (payload: Column[]) => TableState
  setCustomFields: (customFields?: string) => TableState
  setHiddenColumns: (hidden: string[]) => TableState
  setPage: (payload: number) => TableState
  setPageSize: (payload: number) => TableState
  setSearchTerm: (payload?: string) => TableState
  setSort: (payload?: Sort | Sort[]) => TableState
  setView: (payload: ViewPayload) => TableState
}

export function createMethods(canonicalColumns: Column[]) {
  return (state: TableState): TableActions => {
    return {
      changeFilter({ key, value }: Filter) {
        const viewClearedState = clearActiveView(state)
        if (value === null) {
          return removeFilter(viewClearedState, key)
        } else {
          return addFilter(viewClearedState, { key, value })
        }
      },
      changeHiddenColumns(payload) {
        return changeHiddenColumns(clearActiveView(state), payload)
      },
      clear() {
        return {
          ...INITIAL_STATE,
          columns: state.columns,
          pageSize: state.pageSize
        }
      },
      resetPage() {
        return { ...state, page: 1 }
      },
      setColumns(columns) {
        return { ...clearActiveView(state), columns: columns }
      },
      setCustomFields(customFields) {
        return { ...state, customFields }
      },
      setHiddenColumns(hidden) {
        return { ...state, hiddenColumns: hidden }
      },
      setPage(page) {
        return { ...state, page }
      },
      setPageSize(pageSize) {
        return changePageSize(state, pageSize)
      },
      setSearchTerm(term) {
        return changeSearchValue(state, term)
      },
      setSort(sort) {
        return changeSort(clearActiveView(state), sort)
      },
      setView(payload) {
        return applyView(state, canonicalColumns, payload)
      }
    }
  }
}

const useIncludedCustomFields = (customFields, hiddenColumns): string => {
  const included = useMemo(() => {
    const fields = customFields ? customFields.split(',') : []
    return difference(fields, hiddenColumns).join(',')
  }, [customFields, hiddenColumns])
  const previousIncluded = usePrevious(included)
  // If we removed includes we don't need to trigger a refetch.
  return previousIncluded && previousIncluded.length > included
    ? previousIncluded
    : included
}

/**
 * Gets the proper value of a filter
 * 
 * @param value The value to serializ
 */
function deserializeFilterValue(value) {
  const isBool = value === 'true' || value === 'false';
  const isNum = !Number.isNaN(Number(value));
  const isArr = value.includes(',');

  let deserialized = value;

  if (isBool) deserialized = value === 'true';
  if (isNum) deserialized = Number(value);
  if (isArr) {
    const values = value.split(',');

    deserialized = values.map(deserializeFilterValue);
  }

  return deserialized;
}

function useTableReducer<T extends RowData = RowData>(
  initialState?: Partial<TableState<T>>
) {
  const initial = useMemo(() => {
    const activeFilters = {};

    const params = new URLSearchParams(new URL(window.location.href).search);
  
    params.forEach((value, param) => {
      if (param.includes('filter')) {
        activeFilters[param.split('filters.')[1]] = deserializeFilterValue(value);
      }
    })

    const storedPageSize = localStorage.getItem('pwsStore/pageSize')
    return {
      ...INITIAL_STATE,
      ...initialState,
      pageSize: storedPageSize
        ? parseInt(storedPageSize, 10)
        : initialState?.pageSize || INITIAL_STATE.pageSize,
      activeFilters,
    }
    // We ignore this here to avoid recreating the initial state.
    // `useMethods` does not correctly handle changing of the state.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState?.columns, initialState?.hiddenColumns])
  const methods = useMemo(() => createMethods(initial.columns), [
    initial.columns
  ])
  return useMethods<TableActions, TableState>(methods, initial)
}

export const useTableState = (
  initialState?: Partial<TableState> & { sort?: Sort }
): {
  actions: TableActions
  params: SearchParams & { sort?: Sort }
  state: TableState
} => {
  const [state, actions] = useTableReducer(initialState)
  const includedCustomFields = useIncludedCustomFields(
    state.customFields,
    state.hiddenColumns
  )
  const params = useMemo(() => {
    return {
      activeFilters: state.activeFilters,
      customFields: includedCustomFields,
      page: state.page,
      pageSize: state.pageSize,
      searchTerm: state.searchTerm,
      sort: state.sort ? convertStringToSorts(state.sort)[0] : undefined
    }
  }, [
    state.page,
    state.pageSize,
    state.searchTerm,
    state.sort,
    state.activeFilters,
    includedCustomFields
  ])
  return { actions: actions as TableActions, params, state }
}
