import sortBy from 'lodash/sortBy'

import type {
  ServiceLineDiameterReseponse as ServiceLineDiameterResponse,
  ServiceLineMaterialResponse
} from 'src/containers/ServiceLines/dataAccess/types'
import type { DropdownItem } from 'src/types/Dropdown'
import { YesNoUnknown } from 'src/types/enums/YesNoUnknown'
import { convertEnumToListItems } from './selectlist'
import { Ownership } from 'src/types/enums/Ownership'

export const SERVICE_LINE_STATUS_VALUES: Record<string, string> = {
  Abandoned: 'Abandoned',
  InService: 'In Service',
  OutOfService: 'Out Of Service',
  Removed: 'Removed',
  Replaced: 'Replaced',
  Stubbed: 'Stubbed'
}

export const getServiceLineStatusValue = (status?: string): string => {
  return (status && SERVICE_LINE_STATUS_VALUES[status]) || '--'
}

const serviceLineStatusName = (statusChoice: string): string => {
  return SERVICE_LINE_STATUS_VALUES[statusChoice]
}

export const serviceLineStatusOptions: DropdownItem<any>[] = Object.keys(
  SERVICE_LINE_STATUS_VALUES
).map(key => ({
  key,
  text: serviceLineStatusName(key),
  value: key
}))

export const submissionServiceLineStatusOptions: DropdownItem<any>[] = serviceLineStatusOptions.filter((status) => !['Removed', 'Replaced'].includes(status.key))

export const getMaterialDropdownOptions = (
  serviceLineMaterials: ServiceLineMaterialResponse[],
  isFilter?: boolean
): DropdownItem<any>[] => {
  return sortBy(
    serviceLineMaterials.map(m => ({
      key: m.selectionValue,
      text: m.selectionValue,
      value: isFilter ? m.selectionValue : m.id
    })),
    'text'
  )
}

export const ownershipDropdownOptions = convertEnumToListItems(Ownership)

const leadSolderLeadLinedMaterialDataCodeValues: string[] = ['CU']

const materialOtherDataCodeValues: string[] = ['NL']

export const getServiceLineLeadSolderLeadLinedMaterialIds = (
  materials: ServiceLineMaterialResponse[]
): number[] => {
  return materials
    ?.filter(m =>
      leadSolderLeadLinedMaterialDataCodeValues.includes(m.dataCode)
    )
    .map(m => m.id)
}

export const getMaterialOtherIds = (
  materials: ServiceLineMaterialResponse[]
): number[] => {
  return materials
    ?.filter(m => materialOtherDataCodeValues.includes(m.dataCode))
    .map(m => m.id)
}

export const getValueForLeadSolderLeadLined = (values, materials, type) => {
  const materialOption = materials?.serviceLineMaterials.filter(
    option => option.id === values[`${type}Material`]
  )

  const isValidMaterialForLSLL = materialOption?.[0]?.dataCode === 'CU'

  if (isValidMaterialForLSLL) {
    if (!values[`${type}LeadSolderLeadLined`]) {
      return YesNoUnknown.Unknown
    }
    return values[`${type}LeadSolderLeadLined`]
  }

  return null
}

export const getDiameterDropdownOptions = (
  serviceLineDiameters: ServiceLineDiameterResponse[],
  isFilter?: boolean
): DropdownItem<any>[] => {
  return serviceLineDiameters.map(m => ({
    key: m.displayValue,
    text: m.displayValue,
    value: isFilter ? m.displayValue : m.id
  }))
}
export const getVerificationOptionsPrimacyExclude = primacyCode => {
  if (primacyCode === 'AZ') {
    return ['Customer Self-Identification']
  } else {
    return []
  }
}
