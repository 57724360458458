import { Button } from '@120wateraudit/envirio-components'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useMemo } from 'react'
import { Field, Form as FinalForm } from 'react-final-form'
import { Form, Message } from 'semantic-ui-react'
import styled from 'styled-components'

import { ValidationErrors } from 'final-form'
import { TritonTextField } from 'src/components/Forms/TextField'
import { Row } from 'src/components/Layout'
import Modal from 'src/modules/Modal/ModalContents'
import { useUser } from 'src/router/UserProvider'
import {
  useUpdateCurrentUserMutation,
  useUpdatePasswordMutation
} from 'src/services'
import { toastError, toastIndefiniteError, toastSuccess } from 'src/utils/toast'

interface Values {
  confirmPassword?: string
  currentPassword?: string
  email: string
  firstName: string
  lastName: string
  newPassword?: string
}

const SPECIAL_CHARACTER_CHECK = /[$&+,:;=?@#|{}`~'"_<>.^*()%!-]/
const UPPERCASE_LETTER_CHECK = /[A-Z]/
const NUMBER_CHECK = /\d/

const validate = (values: Values): ValidationErrors => {
  const errors: ValidationErrors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  }

  if (!values.lastName) {
    errors.lastName = 'Required'
  }

  if (!values.email) {
    errors.email = 'Required'
  }

  if (values.newPassword && values.newPassword.length < 10) {
    errors.newPassword = 'Must be 10 characters long'
  }

  if (
    values.newPassword &&
    values.newPassword.length >= 10 &&
    !UPPERCASE_LETTER_CHECK.test(values.newPassword)
  ) {
    errors.newPassword = 'Weak Password, No Capital Letter'
  }

  if (
    values.newPassword &&
    values.newPassword.length >= 10 &&
    !NUMBER_CHECK.test(values.newPassword)
  ) {
    errors.newPassword = 'Weak Password, No Number'
  }

  if (
    values.newPassword &&
    values.newPassword.length >= 10 &&
    !SPECIAL_CHARACTER_CHECK.test(values.newPassword)
  ) {
    errors.newPassword = 'Weak Password, No Special Character'
  }

  if (
    values.newPassword &&
    values.confirmPassword &&
    values.newPassword !== values.confirmPassword
  ) {
    errors.confirmPassword = 'Must match new password'
  }

  if (values.newPassword && !values.currentPassword) {
    errors.currentPassword = 'Required to set new password'
  }

  if (values.currentPassword && !values.newPassword) {
    errors.newPassword = 'Required to set new password'
  }

  if (values.newPassword && !values.confirmPassword) {
    errors.confirmPassword = 'Required to set new password'
  }

  return errors
}

const useInitialValues = (): Values => {
  const { user } = useUser()
  return useMemo(
    () => ({
      email: user?.email || 'user@example.com',
      firstName: user?.firstName || 'Current',
      lastName: user?.lastName || 'User'
    }),
    [user]
  )
}

const useUpdateProfile = (onSuccess: () => void) => {
  const [updateProfile] = useUpdateCurrentUserMutation()
  const [updatePassword] = useUpdatePasswordMutation()
  return useCallback(
    async (values: Values) => {
      const { confirmPassword, currentPassword, newPassword } = values
      const hasPasswordChange =
        currentPassword && newPassword && confirmPassword

      try {
        await updateProfile({
          firstName: values.firstName,
          lastName: values.lastName
        }).unwrap()
        toastSuccess('User profile was updated successfully')
      } catch {
        toastError('An error occurred while updating your profile')
      }

      try {
        if (hasPasswordChange) {
          await updatePassword({
            currentPassword,
            password: newPassword,
            passwordConfirm: confirmPassword
          }).unwrap()
        }
        toastSuccess('User Password was updated successfully')
        onSuccess()
      } catch (error) {
        const errorMessage =
          error.data.message || 'An error occurred while changing your password'
        toastIndefiniteError(errorMessage)
      }
    },
    [onSuccess, updatePassword, updateProfile]
  )
}

interface Props {
  onClose(): void
}

const EditProfileModal = ({ onClose }: Props): JSX.Element => {
  const initialValues = useInitialValues()
  const onSubmit = useUpdateProfile(onClose)

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ valid, submitting, dirtyFields, handleSubmit }) => {
        const showPasswordGuidelines =
          Object.keys(dirtyFields).includes('newPassword')
        return (
          <Modal
            icon={faUser}
            cancelAction={
              <Button onClick={onClose} disabled={submitting}>
                Cancel
              </Button>
            }
            primaryAction={
              <Button
                onClick={handleSubmit}
                disabled={!valid || submitting}
                type="submit"
                variant="primary">
                Save Profile
              </Button>
            }
            title="Profile Details">
            <FormGrid onSubmit={handleSubmit}>
              <Field<string>
                autoFocus
                name="firstName"
                component={TritonTextField}
                label="First Name"
              />
              <Field<string>
                name="lastName"
                component={TritonTextField}
                label="Last Name"
              />
              <Field<string>
                style={{ gridColumn: 'span 2' }}
                disabled
                name="email"
                component={TritonTextField}
                label="Email"
              />
              <h2 style={{ gridColumn: 'span 2', margin: 0 }}>
                Change My Password
              </h2>
              {showPasswordGuidelines && (
                <Message
                  info
                  header="Password must contain at least"
                  list={[
                    '10 characters',
                    '1 capital letter',
                    '1 number',
                    '1 special character'
                  ]}
                  style={{ gridColumn: 'span 2' }}
                />
              )}
              <Row
                style={{
                  gridColumn: 'span 2',
                  justifyContent: 'space-between'
                }}>
                <Field<string>
                  name="currentPassword"
                  component={TritonTextField}
                  label="Current Password"
                  type="password"
                />
                <Field<string>
                  name="newPassword"
                  component={TritonTextField}
                  label="New Password"
                  type="password"
                />
                <Field<string>
                  name="confirmPassword"
                  component={TritonTextField}
                  type="password"
                  label="Confirm New Password"
                />
              </Row>
            </FormGrid>
          </Modal>
        )
      }}
      validate={validate}
    />
  )
}

const FormGrid = styled(Form)`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;

  & .field {
    text-align: start;
  }
` as typeof Form

export default EditProfileModal
