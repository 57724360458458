import raygun from 'raygun4js'

import { RAYGUN_API_KEY, __DEV__ } from '../constants'
import { StaticImplements } from '../types/StaticImplements'

interface SetUserOptions {
  authId: string
  email: string
  firstName: string
  fullName: string
}

interface ErrorLogger {
  init(): void
  log(e: Error): void
  setUser(s: SetUserOptions): void
  trackPageView(path: string): void
}

@StaticImplements<ErrorLogger>()
class Logger {
  public static init() {
    raygun('apiKey', RAYGUN_API_KEY)
    raygun('enableCrashReporting', true)
    raygun('enablePulse', true)
    raygun('withTags', [window.location.host])
  }

  public static setUser({
    authId,
    email,
    firstName,
    fullName
  }: SetUserOptions) {
    raygun('setUser', {
      email,
      firstName,
      fullName,
      identifier: authId,
      isAnonymous: false
    })
  }

  public static log(error: Error) {
    raygun('send', error)
  }

  public static trackPageView(path: string) {
    raygun('trackEvent', { path, type: 'pageView' })
  }
}

@StaticImplements<ErrorLogger>()
class DevLogger {
  public static init(): void {
    /* VOID */
  }
  public static setUser(_: SetUserOptions): void {
    /* VOID */
  }
  public static log(e: Error): void {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  public static trackPageView(path: string): void {
    // eslint-disable-next-line no-console
    console.log(`Navigated to path: ${path}`)
  }
}

export default __DEV__ ? DevLogger : Logger
