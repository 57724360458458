import { useCallback, useState } from 'react'
import { get } from 'src/API'
import { useDebouncedEffect } from 'src/hooks/useData'

export function useDatum<T>(url: string) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<T>()

  const fetch = useCallback(async () => {
    setLoading(true)
    const response: T = (await get(url)) as T
    setData(response)
    setLoading(false)
  }, [setLoading, setData, url])

  useDebouncedEffect(
    () => {
      fetch()
    },
    [fetch],
    250
  )

  return { data, fetch, loading }
}
