import React, { FC, isValidElement, CSSProperties } from 'react'
import { Placeholder as SUIRPlaceholder } from 'semantic-ui-react'
import styled from 'styled-components'

import { DetailsLabel, Value } from './Layout'

type Props = {
  isLoading?: boolean
  label: string
  style?: CSSProperties
}

const Detail: FC<Props> = ({ children, isLoading = false, label, style }) => {
  if (!children) {
    return null
  }

  // Not sure if we actually need to check if the array is renderable...
  const wrapValue = Array.isArray(children)
    ? children.every(child => isValidElement(child))
    : isValidElement(children)
  return (
    <DetailContainer style={style}>
      <DetailsLabel>{label}</DetailsLabel>
      {isLoading ? (
        <Placeholder />
      ) : wrapValue ? (
        children
      ) : (
        <Value>{children}</Value>
      )}
    </DetailContainer>
  )
}

const DetailContainer = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
`

const Placeholder = (): JSX.Element => {
  return (
    <SUIRPlaceholder>
      <SUIRPlaceholder.Line style={{ fontSize: '14px', height: '14px' }} />
    </SUIRPlaceholder>
  )
}

export default Detail
