import { CustomFieldDataType } from '@120wateraudit/envirio-components/dist/models'
import { CustomFieldFilterValue } from 'src/components/Table/Filters/hooks'
import { ActiveFilters, ValidFilterValue } from 'src/components/Table/types' // Should these still be in the table?
import { convertSortToString } from 'src/components/Table/utils'
import { SearchParams } from './types'

const isValidCustomFieldFilter = (
  customFields: string,
  useNewFilters: boolean
) => {
  if (useNewFilters) {
    return true
  }

  try {
    const parsed = JSON.parse(customFields)
    return Array.isArray(parsed)
  } catch {
    return false
  }
}

export const addFilters = (
  params: URLSearchParams,
  activeFilters: ActiveFilters,
  useNewFilters = false
) => {
  const filterEntries = Object.entries(activeFilters)
  if (!useNewFilters) {
    filterEntries.forEach(([key, value]) => {
      if (value) params.set(key, `${value}`)
    })
  } else {
    filterEntries.forEach(([key, value]) => {
      if (key === 'customFields') {
        addCustomFieldFilters(params, value)
      } else if (value) {
        const converted = convertFilter(key, value)
        if (converted) {
          params.set(converted.key, converted.value)
        }
      }
    })
  }

  return params
}

const addCustomFieldFilters = (
  params: URLSearchParams,
  customFields?: ValidFilterValue
) => {
  if (!customFields || typeof customFields !== 'string') {
    return params
  }

  const customFieldFilters = JSON.parse(customFields)
  if (Array.isArray(customFieldFilters)) {
    customFieldFilters.forEach(({ name, value }: CustomFieldFilterValue) => {
      const key = `filter[customField][${name}]`
      const operatorKey = `${key}[${value.operator}]`
      switch (value.dataType) {
        case CustomFieldDataType.Date:
        case CustomFieldDataType['Date/Time']:
          if (value.operator === 'between' && value.value1 && value.value2) {
            params.set(operatorKey, [value.value1, value.value2].join(','))
          } else if (value.value1) {
            params.set(operatorKey, `${value.value1}`)
          }
          break
        case CustomFieldDataType.Hyperlink:
        case CustomFieldDataType.Text:
        case CustomFieldDataType['Pick List']:
          if (value.value1) params.set(key, `${value.value1}`)
          break
        case CustomFieldDataType.Number:
          if (value.operator === 'between' && value.value1 && value.value2) {
            params.set(operatorKey, [value.value1, value.value2].join(','))
          } else if (value.value1) {
            params.set(operatorKey, `${value.value1}`)
          }
          break
        case CustomFieldDataType['True/False']:
          if (value.value1 !== undefined || value.value1 !== null) {
            params.set(key, `${value.value1}`)
          }
          break
        default:
          break
      }
    })
  }
  return params
}

export const buildParameters = (
  {
    activeFilters,
    customFields,
    include,
    page,
    pageSize,
    searchTerm,
    sort,
    includeSubbatches
  }: SearchParams,
  useNewFilters = false
): URLSearchParams => {
  const params = new URLSearchParams()
  params.set('pageNumber', (page - 1).toString())
  params.set('pageSize', pageSize.toString())
  if (searchTerm) {
    params.set('search', searchTerm)
  }
  if (sort && typeof sort === 'object') {
    params.set('sort', convertSortToString(sort))
  } else if (sort && typeof sort === 'string') {
    params.set('sort', sort)
  }
  if (include) {
    params.set('include', include)
  }
  if (customFields && isValidCustomFieldFilter(customFields, useNewFilters)) {
    params.set('customFields', customFields)
  }
  if (includeSubbatches) {
    params.set('includeSubbatches', includeSubbatches.toString())
  }
  addFilters(params, activeFilters, useNewFilters)
  return params
}

export const convertFilter = (
  key: string,
  value: ValidFilterValue
): { key: string; value: string } | undefined => {
  let filter
  if (typeof value === 'string') {
    try {
      const possibleJSON = JSON.parse(value)
      if (typeof possibleJSON === 'object') {
        if (
          possibleJSON.operator === 'between' &&
          possibleJSON.value &&
          possibleJSON.value2
        ) {
          filter = {
            key: `filter[${key}][${possibleJSON.operator}]`,
            value: [possibleJSON.value, possibleJSON.value2].join(',')
          }
        } else if (possibleJSON.value) {
          filter = {
            key: `filter[${key}][${possibleJSON.operator}]`,
            value: `${possibleJSON.value}`
          }
        }
      } else {
        filter = {
          key: `filter[${key}]`,
          value: `${value}`
        }
      }
    } catch {
      // Not valid JSON, just give it to the filter
      filter = {
        key: `filter[${key}]`,
        value: `${value}`
      }
    }
  } else if (value) {
    filter = {
      key: `filter[${key}]`,
      value: `${value}`
    }
  }

  return filter
}
