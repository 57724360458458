import React, { FC } from 'react'
import { Icon } from 'semantic-ui-react'
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon'

interface Props {
  size: IconSizeProp
  checked?: boolean
}

const RadioOn: FC<Omit<Props, 'checked'>> = ({ size }) => (
  <Icon color="blue" name="check circle" size={size} />
)
const RadioOff: FC<Omit<Props, 'checked'>> = ({ size }) => (
  <Icon name="circle outline" size={size} />
)

const Radio: FC<Props> = ({ size, checked = false }) => {
  return checked ? <RadioOn size={size} /> : <RadioOff size={size} />
}

export default Radio
