import React, { FC } from 'react'
import { ContactExampleData } from 'src/containers/Communications/Editor/types'
import InsertableComponent from '../InsertableComponent'
import { DataComponentsWrapper } from 'src/modules/Modal/shared'

interface Props {
  data: ContactExampleData
  onClick: (property: string) => void
}

const ContactDataModalContent: FC<Props> = ({ data, onClick }) => {
  return (
    <DataComponentsWrapper>
      {Object.keys(data).map((key, index) => (
        <InsertableComponent
          key={`${key}-${data[key]}-${index}`}
          propertyName={key}
          onClick={() => {
            key.startsWith('CF ')
              ? onClick(`{{[CF-Contacts].[${key.replace('CF ', '')}]}}`)
              : onClick(`{{c.${key}}}`)
          }}
        />
      ))}
    </DataComponentsWrapper>
  )
}

export default ContactDataModalContent
