export const BooleanGridOptions = [
  {
    text: 'True',
    value: 'True'
  },
  {
    text: 'False',
    value: 'False'
  }
]

export const TrueBooleanGridOptions = [
  {
    text: 'True',
    value: true
  },
  {
    text: 'False',
    value: false
  }
]
