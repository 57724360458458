import { Button } from '@120wateraudit/envirio-components'
import { faClose, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import filesize from 'filesize'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom'
import { List } from 'semantic-ui-react'
import { get } from 'src/API'
import Detail from 'src/components/Detail'
import { DetailsLabel } from 'src/components/Layout'
import { DrawerContents } from 'src/modules/Modal/Drawer'
import { Attachment } from 'src/types/Attachment'
import { oneLineAddress } from 'src/utils/oneLineAddress'
import { getAttachmentFileUrl } from '../dataAccess'

const useDownloadLink = (attachmentId: number) => {
  const [fileUrl, setFileUrl] = useState<string>()
  useEffect(() => {
    ;(async () => {
      const s3url = await get(getAttachmentFileUrl(attachmentId))
      setFileUrl(s3url)
    })()
  }, [attachmentId])

  return fileUrl
}

type Props = {
  attachment: Attachment
  onClose(): void
}

const ViewAttachmentDrawer = ({ attachment, onClose }: Props): JSX.Element => {
  const downloadUrl = useDownloadLink(attachment.id)
  return (
    <DrawerContents
      header={<Header onClose={onClose} downloadUrl={downloadUrl} />}>
      <Section>
        {attachment.mediaType.startsWith('image/') && downloadUrl && (
          <Preview alt="A preview of the Attachment file." src={downloadUrl} />
        )}
        <Detail label="File Name">{attachment.originalFileName}</Detail>
        <Detail label="File Type">{attachment.mediaType}</Detail>
        <Detail label="File Size">{filesize(attachment.fileSize, 1)}</Detail>
      </Section>
      <Associations attachment={attachment} />
    </DrawerContents>
  )
}

type HeaderProps = {
  downloadUrl?: string
  onClose(): void
}

const Header = ({ downloadUrl, onClose }: HeaderProps): JSX.Element => {
  return (
    <>
      <div>
        <h3>View Attachment</h3>
      </div>
      <div>
        <a href={downloadUrl}>
          <Button style={{ marginRight: '7px' }} type="button">
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </a>
        <Button onClick={onClose} type="button" variant="error">
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </div>
    </>
  )
}

const Preview = styled.img`
  max-height: 300px;
  max-width: 292px;
`

const Associations = ({
  attachment
}: {
  attachment: Attachment
}): JSX.Element => {
  return (
    <Section>
      <DetailsLabel>Belongs to:</DetailsLabel>
      <List relaxed size="large">
        {attachment.locations.map(location => (
          <List.Item
            as={Link}
            key={location.id}
            to={`/locations/${location.id}/details`}>
            <List.Icon name="marker" />
            {oneLineAddress(location)}
          </List.Item>
        ))}
        {attachment.samples.map(({ id }) => (
          <List.Item as={Link} key={id} to={`/samples/${id}`}>
            <List.Icon name="magnify" />
            Sample {id}
          </List.Item>
        ))}
        {attachment.assets.map(({ assetId, locationId }) => (
            <List.Item as={Link} key={assetId} to={`/locations/${locationId}/inventory/${assetId}`}>
              <List.Icon name="magnify" />
              Asset {assetId}
            </List.Item>
        ))}
      </List>
    </Section>
  )
}

const Section = styled.div`
  margin: 12px 24px;
`

export default ViewAttachmentDrawer
