import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

import {
  BasicContainer,
  ParagraphInputGrid,
  QuestionTitle,
  NoRedHighlightTextField
} from '../shared'

const InventoryInformation = (): JSX.Element => {
  return (
    <StyledContainer>
      <StyledInputGrid>
        <StyledTitle>
          1. Is this the Initial Inventory or an Inventory Update?
        </StyledTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          name="initialInventoryOrInventoryUpdate"
          textarea
        />
      </StyledInputGrid>
      <StyledInputGrid>
        <StyledTitle>
          2a. Who owns the service lines in your system? If other, please
          explain below.
        </StyledTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          name="whoOwnsTheServiceLines"
          textarea
        />
      </StyledInputGrid>
      <StyledInputGrid>
        <StyledTitle>
          2b. Is there documentation that defines service line ownership in your
          system, such as a local ordinance? If yes, please describe below and
          explain where ownership is split (e.g., property line, curb stop).
        </StyledTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          name="isThereDocumentationThatDefinesOwnership"
          textarea
        />
      </StyledInputGrid>
      <StyledInputGrid>
        <StyledTitle>
          3. How much was spent on this year’s inventory submission?
          <sup>
            <Icon
              color="red"
              name="asterisk"
              style={{ fontSize: '6px', marginLeft: '1px' }}
            />
          </sup>
        </StyledTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="amountSpentOnInventorySubmission"
          textarea
        />
      </StyledInputGrid>
    </StyledContainer>
  )
}

const StyledInputGrid = styled(ParagraphInputGrid)`
  display: flex;
  flex-direction: column;
  gap: initial;
`

const StyledTitle = styled(QuestionTitle)`
  margin-bottom: auto;
`

const StyledContainer = styled(BasicContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export default InventoryInformation
