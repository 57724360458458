import { ApolloError } from '@apollo/client'
import React, { useMemo } from 'react'

import { ReactComponent as LetterIcon } from 'src/assets/images/Letter.svg'
import { ReactComponent as CheckIcon } from 'src/assets/images/Success_Check.svg'
import { ConfirmSend } from 'src/components/Communications/SendKitResultsModal/ConfirmSend'
import { LetterBreakdown } from 'src/components/Communications/SendKitResultsModal/LetterBreakdown'
import { SendCompleted } from 'src/modules/Kits/modal/SendResultsModal/SendCompleted'
import { SendOptions } from 'src/components/Communications/SendKitResultsModal/SendOptions'
import { StepHeader } from 'src/components/StepHeader'
import { useFeatures, Flags, useConfig } from 'src/hooks'
import { SendOption } from 'src/modules/Kits/modal/SendResultsModal'
export interface Props {
  activeStepIndex: number
  numberOfLetters: number
  allResultsCount: number
  nonDetectCount: number
  detectCount: number
  exceedanceCount: number
  sendError?: ApolloError | undefined
  downloadError?: ApolloError | undefined
  sendOption?: SendOption | undefined
  onSendOptionChanged: (sendOption: SendOption) => void
  confirmSendTitle: string
  isCertifiedMail: boolean
  toggleIsCertifiedMail: () => void
  markCompleteOnResultLetterSent: boolean
  toggleMarkCompleteResultLetterSent: () => void
}

const Content: React.FC<Props> = ({
  activeStepIndex,
  numberOfLetters,
  allResultsCount,
  nonDetectCount,
  detectCount,
  exceedanceCount,
  sendError,
  downloadError,
  sendOption,
  onSendOptionChanged,
  confirmSendTitle,
  isCertifiedMail,
  toggleIsCertifiedMail,
  markCompleteOnResultLetterSent,
  toggleMarkCompleteResultLetterSent
}) => {
  const commsTwoEnabled = useConfig(Flags.CommsTwoEnabled)
  const { accountHasCommsFeature, accountHasCommsAutomatedMailingFeature } =
    useFeatures()
  const hasAutomatedLetterMailingFeature = useMemo(() => {
    return !!(accountHasCommsFeature && accountHasCommsAutomatedMailingFeature)
  }, [accountHasCommsFeature, accountHasCommsAutomatedMailingFeature])

  if (activeStepIndex === 0) {
    return (
      <>
        <StepHeader text="Send Results Letter" Icon={LetterIcon} />
        <LetterBreakdown
          numberOfLetters={numberOfLetters}
          allResultsCount={allResultsCount}
          nonDetectCount={nonDetectCount}
          detectCount={detectCount}
          exceedanceCount={exceedanceCount}
          markCompleteOnResultLetterSent={markCompleteOnResultLetterSent}
          toggleMarkCompleteResultLetterSent={
            toggleMarkCompleteResultLetterSent
          }
        />
      </>
    )
  }

  if (activeStepIndex === 1) {
    return (
      <>
        <StepHeader text="Send Results Letter" Icon={LetterIcon} />
        <SendOptions
          numberOfLetters={numberOfLetters}
          sendOption={sendOption}
          onSendOptionChanged={onSendOptionChanged}
          hasAutomatedLetterMailingFeature={hasAutomatedLetterMailingFeature}
          isCertifiedMail={isCertifiedMail}
          toggleIsCertifiedMail={toggleIsCertifiedMail}
        />
      </>
    )
  }

  if (activeStepIndex === 2) {
    return (
      <>
        <StepHeader text={confirmSendTitle} Icon={LetterIcon} />
        <ConfirmSend
          numberOfLetters={numberOfLetters}
          sendOption={sendOption as SendOption}
          error={sendError || downloadError}
        />
      </>
    )
  }

  if (
    activeStepIndex === 3 &&
    commsTwoEnabled &&
    sendOption === SendOption.PDF
  ) {
    return (
      <>
        <StepHeader
          text="Your downloads have begun processing!"
          Icon={CheckIcon}
          imageStyle={{ maxWidth: 52, maxHeight: 52 }}
        />
        <SendCompleted
          numberOfLetters={numberOfLetters}
          sendOption={sendOption as SendOption}
        />
      </>
    )
  }

  if (activeStepIndex === 3) {
    return (
      <>
        <StepHeader
          text="Completed!"
          Icon={CheckIcon}
          imageStyle={{ maxWidth: 52, maxHeight: 52 }}
        />
        <SendCompleted
          numberOfLetters={numberOfLetters}
          sendOption={sendOption as SendOption}
        />
      </>
    )
  }

  return null
}

export default React.memo(Content)
