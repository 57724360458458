import { Badge } from '@120wateraudit/envirio-components'
import React from 'react'
import styled from 'styled-components'

import { ServiceLineMaterial } from '../../containers/ServiceLines/dataAccess/types'

export interface Props {
  material?: ServiceLineMaterial
  overrideName?: string
  simple?: boolean
}

const ServiceLinesMaterial = ({
  material,
  overrideName,
  simple,
  ...rest
}: Props) => {
  const name = overrideName || material?.selectionValue
  const variant =
    material?.overrideValues?.overrideValues?.displayColor ||
    material?.ptdDisplayDefault?.displayColor ||
    material?.PTDDisplayColor?.displayColor

  if (simple) {
    return (
      <MaterialBadgeSimple color={variant} {...rest}>
        {name}
      </MaterialBadgeSimple>
    )
  }
  return (
    <MaterialBadge backgroundColor={variant} {...rest}>
      {name}
    </MaterialBadge>
  )
}

const MaterialBadge = styled(Badge)`
  margin: 1px;
`

const MaterialBadgeSimple = styled.div`
  color: ${({ color }) => color};
`

export default ServiceLinesMaterial
