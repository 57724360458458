import React from 'react'

import { Step } from 'src/components/Forms/Wizard'
import SummaryPart1 from '../InventorySummarySteps/SummaryPart1'
import SummaryPart2 from '../InventorySummarySteps/SummaryPart2'
import EpaPublicAccessibilityDoc from './EpaPublicAccessibilityDoc'
import EpaMethodsPart1 from './InventoryMethods/EpaMethodsPart1'
import EpaMethodsPart2 from './InventoryMethods/EpaMethodsPart2'
import EpaMethodsPart3 from './InventoryMethods/EpaMethodsPart3'

export const FEDERAL_STEPS: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Historical Records Review',
    render: () => <EpaMethodsPart1 />
  },
  {
    nextButtonText: 'Next Step',
    title: 'Identifying Service Line Material During Normal Operations',
    render: () => <EpaMethodsPart2 />
  },
  {
    nextButtonText: 'Next Step',
    title: 'Service Line Investigations',
    render: () => <EpaMethodsPart3 />
  },
  {
    nextButtonText: 'Next Step',
    title: 'General Information',
    render: () => <SummaryPart1 />
  },
  {
    nextButtonText: 'Next Step',
    title: 'Inventory Format',
    render: () => <SummaryPart2 />
  },
  {
    nextButtonText: 'Next Step',
    title: 'Public Accessibility Doc',
    render: () => <EpaPublicAccessibilityDoc />
  }
]
