import React from 'react'
import { Field } from 'react-final-form'

import { TritonTextField } from 'src/components/Forms/TextField'
import { BasicContainer, BoldSpan } from '../shared'

const SummaryPart2 = (): JSX.Element => {
  return (
    <BasicContainer>
      <Field<string>
        label={
          <p>
            Describe your inventory format in the space provided below (e.g.,
            the <BoldSpan>Detailed Inventory worksheet</BoldSpan>, custom
            spreadsheet, GIS map). Provide the filename and/or web address if
            applicable.{' '}
            <BoldSpan>
              Note that the state may require you to submit your detailed
              inventory of each service line in your distribution system.
            </BoldSpan>
          </p>
        }
        name="inventorySummaryFormatDescription"
        component={TritonTextField}
        textarea
      />
    </BasicContainer>
  )
}

export default SummaryPart2
