import { Loader } from '@120wateraudit/envirio-components'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  AccountType,
  CommunicationType
} from '@120wateraudit/communications-types'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { contactsClient, pwsClient } from 'src/apollo-clients'
import { addBatchDownload } from 'src/actions/batchDownloads'
import { startPolling } from 'src/actions/polling'
import { ReactComponent as CheckIcon } from 'src/assets/images/Check.svg'
import { ReactComponent as DuplicateLetterIcon } from 'src/assets/images/DuplicateLetter.svg'
import { ReactComponent as LetterIcon } from 'src/assets/images/Letter.svg'
import { ReactComponent as HazardIcon } from 'src/assets/images/Hazard.svg'
import {
  COMMUNICATION,
  CommunicationData,
  CommunicationVariables
} from 'src/containers/Communications/EditorSuite/data-access'
import PDFModalContainer from 'src/containers/Locations/SendCommunicationButton/PDFModalContainer'
import {
  ADHOC_DOWNLOAD_BATCH,
  ADHOC_SEND_BATCH,
  AVAILABLE_COMMUNICATIONS_QUERY,
  AvailableCommunicationsData,
  AvailableCommunicationsVariables,
  PREVIOUSLY_SENT_COMMS,
  PreviouslySentCommsData,
  PreviouslySentCommsVariables,
  ProcessAdHocBatchDownloadData,
  ProcessAdHocBatchSendData,
  ProcessAdHocVariables
} from 'src/containers/Locations/SendCommunicationButton/data-access'
import { CommunicationOption } from 'src/containers/Locations/SendCommunicationButton/types'
import { useFeatures } from 'src/hooks'
import { useToggle } from 'src/hooks/useToggle'
import {
  ButtonsWrapper,
  ErrorMessage,
  ModalContent,
  ModalHeading,
  Wrapper
} from 'src/modules/Modal/shared'
import { ModalWrapper } from 'src/modules/Samples/modals/CompleteSamplesModal/shared'
import SendModalContent from './SendModalContent'
import SendModalFooter from './SendModalFooter'
import { SupportLink } from 'src/components/SupportLink'
import { NoTemplatesWarning } from 'src/components/Communications/NoTemplatesWarning'
import { BulkActionType } from 'src/types/enums/BulkActionType'
import { usePerformBulkActionByTypeMutation } from 'src/modules/ServiceLines/dataAccess'
import { Link } from 'react-router-dom'
import {
  AccountSoftwarePackage,
  GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY
} from 'src/containers/Communications/Dashboard/dataAccess'
import { SoftwarePackageName } from 'src/types/enums/SoftwarePackageName'

const SendCommunicationModal: React.FC<Props> = ({
  accountId,
  bulkActionType,
  isSelectAllOn,
  hideInventoryChoices,
  locationIds: baseLocationIds,
  onClose,
  onCompletion,
  paramsURL,
  selectAllCount
}) => {
  const { accountHasCommsAutomatedMailingFeature, accountHasCommsFeature } =
    useFeatures()
  const locationIds = useRef(baseLocationIds)
  const [skipDuplicates, toggleSkipDuplicates] = useToggle(false)
  const [openPreviewContainer, togglePreviewContainer] = useToggle(false)
  const [isCertifiedMail, toggleIsCertifiedMail] = useToggle(false)
  const [selectedCommunication, setSelectedCommunication] = useState<
    undefined | CommunicationOption
  >(undefined)
  const [selectedCommunicationType, setSelectedCommunicationType] = useState<
    undefined | CommunicationTypes
  >(undefined)
  const [stepNumber, setStepNumber] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()
  const [previousSentCommLocationIds, setPreviousSentCommLocationIds] =
    useState<undefined | number[]>(undefined)
  const [zIndex, setZIndex] = useState<number>(1000)
  const dispatch = useDispatch()

  const { data: accountSoftwarePackage } = useQuery<AccountSoftwarePackage>(
    GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY,
    {
      client: pwsClient,
      variables: { accountId: accountId },
      skip: !accountId
    }
  )

  const communicationsQuery = useQuery<
    AvailableCommunicationsData,
    AvailableCommunicationsVariables
  >(AVAILABLE_COMMUNICATIONS_QUERY, {
    client: contactsClient,
    fetchPolicy: 'network-only',
    variables: { accountId, hideInventoryChoices },
    onError: ({ graphQLErrors }) => setErrorMessage(graphQLErrors[0]?.message)
  })

  const [sendCommunications] = useMutation<
    ProcessAdHocBatchSendData,
    ProcessAdHocVariables
  >(ADHOC_SEND_BATCH, {
    client: contactsClient,
    onCompleted: () => {
      if (onCompletion) onCompletion()
      setIsLoading(false)
      onNextClicked()
    },
    onError: ({ graphQLErrors }) => {
      setIsLoading(false)
      setErrorMessage(graphQLErrors[0]?.message)
    }
  })

  const [downloadCommunications, { loading }] = useMutation<
    ProcessAdHocBatchDownloadData,
    ProcessAdHocVariables
  >(ADHOC_DOWNLOAD_BATCH, {
    client: contactsClient,
    onCompleted: ({ processAdHocBatch: { result: batchId } }) => {
      if (batchId) {
        setIsLoading(false)
        dispatch(addBatchDownload({ batchId }))
        dispatch(startPolling())
      }
      onNextClicked()
    },
    onError: ({ graphQLErrors }) => setErrorMessage(graphQLErrors[0]?.message)
  })

  const [previouslySentComms] = useLazyQuery<
    PreviouslySentCommsData,
    PreviouslySentCommsVariables
  >(PREVIOUSLY_SENT_COMMS, {
    client: contactsClient,
    fetchPolicy: 'cache-first',
    variables: {
      communicationId: selectedCommunication?.id as number,
      locationIds: baseLocationIds
    },
    onCompleted: ({ previouslySentComms: response }) =>
      setPreviousSentCommLocationIds(
        response.map(({ locationId }) => locationId)
      ),
    onError: ({ graphQLErrors }) => setErrorMessage(graphQLErrors[0].message)
  })

  const [
    getCommunication,
    { data: getCommunicationData, loading: getCommunicationLoading }
  ] = useLazyQuery<CommunicationData, CommunicationVariables>(COMMUNICATION, {
    client: contactsClient,
    fetchPolicy: 'no-cache',
    onError: ({ graphQLErrors }) => setErrorMessage(graphQLErrors[0].message)
  })

  const fetchCommunicationData = useCallback(async () => {
    if (selectedCommunication?.id) {
      getCommunication({
        variables: {
          accountId,
          communicationId: selectedCommunication.id
        }
      })

      togglePreviewContainer()
    }
  }, [
    accountId,
    getCommunication,
    selectedCommunication,
    togglePreviewContainer
  ])

  const communications = useMemo(
    () =>
      communicationsQuery.loading || !communicationsQuery.data
        ? []
        : communicationsQuery.data.availableAdHocCommunications,
    [communicationsQuery.data, communicationsQuery.loading]
  )

  const clearState = useCallback(() => {
    setSelectedCommunication(undefined)
    setSelectedCommunicationType(undefined)
    setStepNumber(0)
  }, [])

  const onNextClicked = useCallback(() => {
    if (
      stepNumber === 0 &&
      selectedCommunication &&
      selectedCommunicationType === 'Letter'
    ) {
      previouslySentComms()
    }

    if (stepNumber !== 2 && selectedCommunication) {
      setStepNumber(stepNumber + 1)
    }
  }, [
    previouslySentComms,
    stepNumber,
    selectedCommunication,
    selectedCommunicationType
  ])

  const onBackClicked = useCallback(() => {
    if (stepNumber === 1) {
      setStepNumber(0)
    }
  }, [stepNumber])

  const [sendBulkAssetCommunications, { isLoading: isBulkLoading, data }] =
    usePerformBulkActionByTypeMutation()

  const { batchId } = data ?? {}

  const onSend = useCallback(async () => {
    if (
      isSelectAllOn &&
      selectedCommunication !== undefined &&
      bulkActionType &&
      paramsURL
    ) {
      try {
        await sendBulkAssetCommunications({
          bulkActionType,
          accountType: AccountType.PWS,
          communicationId: selectedCommunication.id.toString(),
          isCertifiedMail,
          paramsURL,
          templateId: selectedCommunication.templateId?.toString()
        }).unwrap()

        if (onCompletion) onCompletion()
        onNextClicked()
      } catch (error) {
        const errorMessage = error.data?.errors[0].detail
          ? error.data?.errors[0].detail
          : 'An error occurred. Please try again.'

        setErrorMessage(errorMessage)
      }
    } else if (selectedCommunication !== undefined) {
      setIsLoading(true)
      await sendCommunications({
        variables: {
          isCertifiedMail,
          params: {
            accountId,
            accountType: AccountType.PWS,
            communicationId: selectedCommunication.id,
            locationIds: locationIds.current,
            templateId: selectedCommunication.templateId
          }
        }
      })
    }
  }, [
    accountId,
    bulkActionType,
    isSelectAllOn,
    isCertifiedMail,
    locationIds,
    onCompletion,
    onNextClicked,
    paramsURL,
    sendBulkAssetCommunications,
    selectedCommunication,
    sendCommunications
  ])

  const onCloseWindow = useCallback(() => {
    clearState()
    onClose()
  }, [clearState, onClose])

  const closePreview = useCallback(() => {
    setZIndex(1000)
    togglePreviewContainer()
  }, [togglePreviewContainer])

  const onDownload = useCallback(async () => {
    if (
      isSelectAllOn &&
      selectedCommunication !== undefined &&
      bulkActionType &&
      paramsURL
    ) {
      try {
        await sendBulkAssetCommunications({
          bulkActionType,
          accountType: AccountType.PWS,
          communicationId: selectedCommunication.id.toString(),
          download: true,
          paramsURL,
          templateId: selectedCommunication.templateId?.toString()
        }).unwrap()

        if (batchId) {
          dispatch(addBatchDownload({ batchId }))
          dispatch(startPolling())
        }
        onNextClicked()
      } catch (error) {
        const errorMessage = error.data?.errors[0].detail
          ? error.data?.errors[0].detail
          : 'An error occurred. Please try again.'

        setErrorMessage(errorMessage)
      }
    } else if (selectedCommunication !== undefined) {
      await downloadCommunications({
        variables: {
          download: true,
          params: {
            accountId,
            accountType: AccountType.PWS,
            communicationId: selectedCommunication.id,
            locationIds: baseLocationIds,
            templateId: selectedCommunication.templateId
          }
        }
      })
    }
  }, [
    accountId,
    batchId,
    bulkActionType,
    dispatch,
    downloadCommunications,
    isSelectAllOn,
    onNextClicked,
    paramsURL,
    sendBulkAssetCommunications,
    selectedCommunication,
    baseLocationIds
  ])

  const onCommunicationSelected = useCallback(
    ({ value }: { value: number }) => {
      const selected = communications.find(c => c.id === value)
      setSelectedCommunication(selected)
    },
    [communications]
  )

  const onCommunicationTypeSelected = useCallback(
    ({ value }: { value: CommunicationTypes }) => {
      setSelectedCommunicationType(CommunicationTypes[value])
    },
    []
  )

  useEffect(() => {
    if (skipDuplicates && previousSentCommLocationIds) {
      locationIds.current = locationIds.current.filter(
        location =>
          !previousSentCommLocationIds.some(
            prevLocation => prevLocation === location
          )
      )
    }

    if (
      !skipDuplicates &&
      previousSentCommLocationIds &&
      locationIds.current !== baseLocationIds
    ) {
      locationIds.current = baseLocationIds
    }
  }, [previousSentCommLocationIds, skipDuplicates, baseLocationIds])

  // Below is the original code, above is all the code pulled from one level above

  const isPlural =
    isSelectAllOn && selectAllCount
      ? selectAllCount > 1
      : baseLocationIds.length > 1
  const renderHeading = useCallback(() => {
    if (errorMessage) {
      return 'Error Occurred.'
    }

    if (selectedCommunicationType === CommunicationTypes['Letter as PDF']) {
      if (stepNumber === 2) {
        return 'Completed!'
      }

      if (stepNumber === 1) {
        return `Print Communication${isPlural ? 's' : ''}`
      }
    }

    if (
      stepNumber === 1 &&
      selectedCommunicationType === 'Letter' &&
      Array.isArray(previousSentCommLocationIds) &&
      previousSentCommLocationIds.length > 0 &&
      !isSelectAllOn
    ) {
      return `Duplicate Letter${isPlural ? 's' : ''}`
    }

    if (
      stepNumber === 1 &&
      selectedCommunicationType === 'Letter' &&
      isSelectAllOn
    ) {
      return `Send Letters`
    }

    if (stepNumber === 2) {
      return 'Completed!'
    }

    return `Send Communication${isPlural ? 's' : ''}`
  }, [
    errorMessage,
    isPlural,
    isSelectAllOn,
    stepNumber,
    previousSentCommLocationIds,
    selectedCommunicationType
  ])

  const renderIcon = useCallback(() => {
    if (errorMessage) {
      return HazardIcon
    }
    if (stepNumber === 2) {
      return CheckIcon
    }

    if (
      stepNumber === 1 &&
      selectedCommunicationType === 'Letter' &&
      Array.isArray(previousSentCommLocationIds) &&
      previousSentCommLocationIds.length > 0 &&
      !isSelectAllOn
    ) {
      return DuplicateLetterIcon
    }

    return LetterIcon
  }, [
    errorMessage,
    isSelectAllOn,
    stepNumber,
    previousSentCommLocationIds,
    selectedCommunicationType
  ])

  if (
    !accountHasCommsFeature &&
    accountSoftwarePackage?.accountSoftwarePackageName ===
      SoftwarePackageName.PwsPortal
  ) {
    return (
      <ModalWrapper style={{ gap: '0' }}>
        <ModalHeading>
          <h4 style={{ fontSize: '2.25rem' }}>{renderHeading()}</h4>
        </ModalHeading>
        <StyledHeader style={{ marginTop: '0px' }}>
          This feature is not included in the PWS Portal account procured on
          your behalf.
          <br />
          <br />
          If you would like to learn more about this feature, please reach out
          to{' '}
          <EmailLink href="mailto:support@120water.com">
            support@120water.com
          </EmailLink>
          . Communication templates can be downloaded in the{' '}
          <Link onClick={onClose} to={`/administration/resources`}>
            Resources
          </Link>{' '}
          tab on the Account Settings page.
        </StyledHeader>
      </ModalWrapper>
    )
  }

  if (!accountHasCommsFeature) {
    return (
      <ModalWrapper>
        <ModalHeading>
          <h5>{renderHeading()}</h5>
        </ModalHeading>
        <StyledHeader>
          Want to use this feature?{' '}
          <SupportLink>Reach out to support</SupportLink> to upgrade your
          package!
        </StyledHeader>
      </ModalWrapper>
    )
  }

  if (communicationsQuery.data && communications.length < 1) {
    return <NoTemplatesWarning onClose={onCloseWindow} />
  }

  if (!communicationsQuery.data) {
    return <Loader />
  }

  return (
    <ModalWrapper>
      <Wrapper
        style={{
          overlay: {
            zIndex: `${zIndex}`
          }
        }}>
        <ModalHeading>
          <Icon as={renderIcon()} />
          <h5>{renderHeading()}</h5>
        </ModalHeading>
        <React.Suspense fallback={<Loader />}>
          {(!loading && (
            <>
              {!errorMessage && (
                <ModalContent>
                  <SendModalContent
                    communications={communications}
                    fetchCommunicationData={fetchCommunicationData}
                    hasAutomatedLetterMailingFeature={
                      !!accountHasCommsAutomatedMailingFeature
                    }
                    isCertifiedMail={isCertifiedMail}
                    isPlural={isPlural}
                    isSelectAllOn={isSelectAllOn}
                    locationIds={baseLocationIds}
                    onCloseWindow={onCloseWindow}
                    onCommunicationSelected={onCommunicationSelected}
                    onCommunicationTypeSelected={onCommunicationTypeSelected}
                    previousSentCommLocationIds={previousSentCommLocationIds}
                    selectedCommunication={selectedCommunication}
                    selectedCommunicationType={selectedCommunicationType}
                    stepNumber={stepNumber}
                    toggleIsCertifiedMail={toggleIsCertifiedMail}
                  />
                </ModalContent>
              )}
              {errorMessage && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ErrorMessage style={{ fontSize: '14px' }}>
                    {errorMessage}
                  </ErrorMessage>
                  {errorMessage.includes('Bulk send') && (
                    <div
                      style={{
                        marginBottom: '15px',
                        fontSize: '14px'
                      }}>
                      Please do a quick check on the{' '}
                      <Link
                        onClick={onCloseWindow}
                        to={'/communications/letter-logs'}>
                        Letter Log
                      </Link>{' '}
                      page to make sure no letters were sent out.
                    </div>
                  )}
                </div>
              )}
              <ButtonsWrapper>
                <SendModalFooter
                  disabledForError={!!errorMessage}
                  isSaving={isLoading || isBulkLoading}
                  isSelectAllOn={isSelectAllOn}
                  locationIds={baseLocationIds}
                  onBack={onBackClicked}
                  onClose={onClose}
                  onDownload={onDownload}
                  onNext={onNextClicked}
                  onSend={onSend}
                  onSkipDuplicatesChange={toggleSkipDuplicates}
                  previousSentCommLocationIds={previousSentCommLocationIds}
                  selectedCommunication={selectedCommunication}
                  selectedCommunicationType={selectedCommunicationType}
                  stepNumber={stepNumber}
                />
              </ButtonsWrapper>
              {openPreviewContainer &&
                !getCommunicationLoading &&
                getCommunicationData && (
                  <PDFModalContainer
                    accountId={accountId}
                    closePreview={closePreview}
                    data={getCommunicationData}
                    isOpen={openPreviewContainer}
                    setErrorMessage={setErrorMessage}
                    setZIndex={setZIndex}
                    toggle={togglePreviewContainer}
                  />
                )}
            </>
          )) || <Loader />}
        </React.Suspense>
      </Wrapper>
    </ModalWrapper>
  )
}

interface Props {
  accountId: number
  bulkActionType?: BulkActionType
  isSelectAllOn?: boolean
  locationIds: number[]
  hideInventoryChoices?: boolean
  onClose: () => void
  onCompletion: () => void
  paramsURL?: string
  selectAllCount?: number
}

enum PDFSelection {
  'Letter as PDF' = 'Letter as PDF'
}

const CommunicationTypes = Object.assign({}, CommunicationType, PDFSelection)

// eslint-disable-next-line @typescript-eslint/no-redeclare
type CommunicationTypes = keyof typeof CommunicationTypes

const Icon = styled.img`
  width: 55px;
  height: 55px;
`
const StyledHeader = styled.h4`
  font-weight: 500;
`

const EmailLink = styled.a`
  text-decoration: none;
`

export default React.memo(SendCommunicationModal)
