import GenerateCrudSaga from './helpers/CrudSaga'
import actions from '../actions/locations'
import {
  fetchAccountLocations,
  fetchAccountLocation,
  createAccountLocation,
  updateAccountLocation
} from '../API'

const saga = GenerateCrudSaga({
  entityName: 'location',
  actions,
  fetchCollectionMethod: fetchAccountLocations,
  fetchEntityMethod: fetchAccountLocation,
  createEntityMethod: createAccountLocation,
  updateEntityMethod: updateAccountLocation
})

export default saga
