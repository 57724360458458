import React, { useCallback } from 'react'
import { Checkbox } from 'semantic-ui-react'

interface Props {
  checked?: boolean
  id?: string
  onCheck?: () => void
  disabled?: boolean
}

const CheckCell = ({
  checked = false,
  id,
  onCheck,
  disabled
}: Props): JSX.Element => {
  const onChange = useCallback(() => {
    if (!onCheck) return
    onCheck()
  }, [onCheck])
  return (
    <Checkbox
      checked={checked}
      id={id}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default CheckCell
