import gql from 'graphql-tag'

export const ACCEPT_TERMS = gql`
  mutation acceptTerms($userId: Int!) {
    acceptTerms(userId: $userId)
  }
`

export interface AcceptTermsData {
  acceptTerms: boolean
}

export interface AcceptTermsVariables {
  userId: number
}

export const HAS_ACCEPTED = gql`
  query hasAccepted($userId: Int!) {
    hasAccepted(userId: $userId)
  }
`

export interface HasAcceptedData {
  hasAccepted: boolean
}

export interface HasAcceptedVariables {
  userId: number
}
