import { toast } from 'react-toastify'
import { call, put, takeLatest } from 'redux-saga/effects'

import { ICrudActions } from 'src/actions/helpers/CrudActions'
import { pushRoute } from 'src/utils/navigation'
import { DEFAULT_TOAST } from 'src/utils/toast'

const postSuccessMessage = (msg: string) =>
  toast(msg, {
    ...DEFAULT_TOAST,
    type: 'success'
  })

const postFailureMessage = (msg: string) =>
  toast(msg, {
    ...DEFAULT_TOAST,
    type: 'error'
  })

interface CrudSagaOptions<T> {
  actions: ICrudActions
  createEntityMethod?: any
  deleteEntityMethod?: any
  entityName: string
  fetchCollectionMethod?: any
  fetchEntityMethod?: any
  pagedCollection?: boolean
  updateEntityMethod?: any
  createSuccessMessage?: (createdItem: T) => string
  deleteFailureMessage?: (error?: Error) => string
  deleteSuccessMessage?: () => string
  updateSuccessfulMessage?: (updatedItem: T) => string
}

export default function GenerateCrudSaga<T>({
  actions,
  createEntityMethod,
  deleteEntityMethod,
  deleteFailureMessage,
  entityName,
  fetchCollectionMethod,
  fetchEntityMethod,
  pagedCollection = true,
  updateEntityMethod,
  updateSuccessfulMessage
}: CrudSagaOptions<T>) {
  function* fetchIndexSaga(action: any) {
    try {
      const item: T = yield call(fetchEntityMethod, action.payload)
      yield put(actions.detailsActions.fetchSuccess({ [entityName]: item }))
    } catch (error) {
      yield put(actions.detailsActions.fetchFailure({ error }))
    }
  }

  function* fetchCollectionSaga(action: any) {
    try {
      const response = yield call(fetchCollectionMethod, action.payload)
      const data = pagedCollection ? response.items : response

      yield put(actions.collectionActions.fetchSuccess({ response: data }))
    } catch (error) {
      yield put(actions.collectionActions.fetchFailure({ error }))
    }
  }

  function* updateSaga(action: any) {
    try {
      const item: T = yield call(updateEntityMethod, action.payload)
      const { callback, redirect } = action.payload

      const updatedAccount = yield put(
        actions.updateActions.updateSuccess({ [entityName]: item })
      )

      if (redirect) {
        pushRoute(redirect)
      }

      if (typeof callback === 'function') {
        callback()
      }

      if (typeof updateSuccessfulMessage === 'function') {
        postSuccessMessage(updateSuccessfulMessage(updatedAccount))
      }
    } catch (error) {
      try {
        yield put(actions.updateActions.updateFailure({ error }))
        action.payload.onError(error)
      } catch (catchError) {
        /* eslint-disable no-console */
        console.log('Unhandled error encountered in updateSaga', catchError)
        console.log(error)
        yield put(
          actions.updateActions.updateFailure({ error: 'Unknown error' })
        )
      }
    }
  }

  function* deleteSaga(action: any) {
    try {
      const { callback, redirect } = action.payload

      yield call(deleteEntityMethod, action.payload)
      yield put(actions.deleteActions.deleteSuccess())

      if (redirect) {
        pushRoute(redirect)
      }

      if (typeof callback === 'function') {
        callback()
      }
    } catch (error) {
      yield put(actions.deleteActions.deleteFailure({ error }))
      postFailureMessage(
        (deleteFailureMessage as (error?: Error) => string)(error)
      )
    }
  }

  function* createSaga(action: any) {
    try {
      const { callback, redirect } = action.payload
      const item: T = yield call(createEntityMethod, action.payload)
      yield put(actions.createActions.createSuccess({ [entityName]: item }))

      if (redirect) {
        pushRoute(redirect)
      }

      if (typeof callback === 'function') {
        callback(item)
      }
    } catch (error) {
      try {
        yield put(actions.createActions.createFailure({ error }))
      } catch (catchError) {
        console.log('Unhandled error encountered in createSaga', catchError)
        console.log(error)
        yield put(
          actions.createActions.createFailure({ error: 'Unknown error' })
        )
      }
    }
  }

  return function* crudSaga() {
    if (fetchEntityMethod) {
      yield takeLatest(
        actions.detailsActions.fetchRequest.toString(),
        fetchIndexSaga
      )
    }

    if (updateEntityMethod) {
      yield takeLatest(
        actions.updateActions.updateRequest.toString(),
        updateSaga
      )
    }

    if (deleteEntityMethod) {
      yield takeLatest(
        actions.deleteActions.deleteRequest.toString(),
        deleteSaga
      )
    }

    if (fetchCollectionMethod) {
      yield takeLatest(
        actions.collectionActions.fetchRequest.toString(),
        fetchCollectionSaga
      )
    }

    if (createEntityMethod) {
      yield takeLatest(
        actions.createActions.createRequest.toString(),
        createSaga
      )
    }
  }
}
