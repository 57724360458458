import React from 'react'
import { Field } from 'react-final-form'

import {
  BasicContainer,
  ParagraphInputGrid,
  QuestionTitle,
  NoRedHighlightTextField,
  SuperAsterisk
} from '../../shared'

interface Props {
  questionOneRequired?: boolean
  questionTwoRequired?: boolean
  questionThreeRequired?: boolean
  questionFourRequired?: boolean
  questionFiveRequired?: boolean
  questionSixRequired?: boolean
}

const EpaMethodsPart1 = ({
  questionOneRequired,
  questionTwoRequired,
  questionThreeRequired,
  questionFourRequired,
  questionFiveRequired,
  questionSixRequired
}: Props): JSX.Element => {
  return (
    <BasicContainer>
      <ParagraphInputGrid>
        <p>Type of Record</p>
        <p>
          Describe the Records Reviewed for Your Inventory and Indicate Your
          Level of Confidence (e.g., Low, Medium, or High)
        </p>
        <QuestionTitle>
          1. Previous Materials Evaluation
          {questionOneRequired && <SuperAsterisk />}
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1PreviousMaterialsEvaluation"
          placeholder="Example: Locations of Tier 1 lead tap sampling locations that are
            served by a lead service line."
          textarea
        />
        <QuestionTitle>
          2. Construction Records and Plumbing Codes
          {questionTwoRequired && <SuperAsterisk />}
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes"
          placeholder="Examples: Local ordinance adopting an international plumbing code.
            Permits for replacing lead service lines."
          textarea
        />
        <QuestionTitle>
          3. Water System Records
          {questionThreeRequired && <SuperAsterisk />}
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1WaterSystemRecords"
          placeholder="Examples: Capital improvement plans. Standard operating procedures.
            Engineering standards."
          textarea
        />
        <QuestionTitle>
          4. Distribution System Inspections and Records
          {questionFourRequired && <SuperAsterisk />}
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1DistributionSystemInspectionsAndRecords"
          placeholder="Examples: Distribution system maps. Tap cards. Service line
            repair/replacement records. Inspection records. Meter installation
            records."
          textarea
        />
        <QuestionTitle>
          5. Additional Records Required by Your State
          {questionFiveRequired && <SuperAsterisk />}
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1AdditionalStateRecords"
          textarea
        />
        <QuestionTitle>
          6. Other Records
          {questionSixRequired && <SuperAsterisk />}
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1otherRecords"
          textarea
        />
      </ParagraphInputGrid>
    </BasicContainer>
  )
}

export default EpaMethodsPart1
