import noop from 'lodash/noop'
import React, {
  ComponentClass,
  FC,
  FunctionComponent,
  createContext,
  useCallback,
  useEffect,
  useState
} from 'react'
import ReactGA from 'react-ga4'
import { Modal, ModalProps, Sidebar, SidebarProps } from 'semantic-ui-react'
import styled from 'styled-components'

import Drawer from './Drawer'
import ModalNotFound from './NotFound'
import { MODALS } from './modals'

type ModalFormat = 'drawer' | 'window'

type ModalSize = ModalProps['size'] | SidebarProps['width']

type ModalTarget = Record<number | string | symbol, unknown> & {
  modalSize?: ModalSize
}

type ModalName = keyof typeof MODALS

interface ModalState {
  format?: ModalFormat
  name?: ModalName
  target?: null | ModalTarget
}

interface ModalTriggers {
  closeModal(): void
  openModal(name: ModalName, target?: ModalTarget, format?: ModalFormat): void
}

const getModalSize = (
  format: ModalFormat = 'window',
  size: ModalSize = 'small'
): string => {
  if (
    format === 'window' &&
    ['mini', 'tiny', 'small', 'large', 'fullscreen', 'wide'].includes(size)
  ) {
    return size
  } else if (
    format === 'drawer' &&
    ['very thin', 'thin', 'wide', 'very wide'].includes(size)
  ) {
    return size
  } else if (format === 'drawer') {
    return 'wide'
  }

  return 'small'
}

export const ModalContext = createContext<ModalTriggers>({
  closeModal: noop,
  openModal: noop
})

const ModalSystem: FC = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const closeDrawer = useCallback(() => setDrawerOpen(false), [setDrawerOpen])
  const [modalState, setModalState] = useState<ModalState>({
    format: undefined,
    name: undefined,
    target: undefined
  })
  const { format, name, target } = modalState
  const openModal = useCallback(
    (
      name: ModalName,
      target?: null | ModalTarget,
      format: ModalFormat = 'window'
    ) => {
      setModalState({ format, name, target })
      ReactGA.event({
        action: `Open Modal Event: ${name}`,
        category: 'Modal'
      })
    },
    [setModalState]
  )
  const closeModal = useCallback(() => {
    ReactGA.event({
      action: `Close Modal Event: ${name}`,
      category: 'Modal'
    })
    setModalState({ format: undefined, name: undefined, target: undefined })
  }, [setModalState, name])
  const modalComponent: ComponentClass<any> | FunctionComponent<any> = name
    ? MODALS[name] || ModalNotFound
    : ModalNotFound
  const isSystemModal = name === 'confirm' || modalComponent === ModalNotFound
  const modalSize = isSystemModal
    ? format === 'window'
      ? 'tiny'
      : 'wide'
    : getModalSize(format, target?.modalSize)
  const visibleDrawer = !!name && format === 'drawer'
  const visibleWindow = !!name && format === 'window'
  useEffect(() => setDrawerOpen(visibleDrawer), [visibleDrawer])
  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Sidebar.Pushable>
        <Drawer onClose={closeModal} visible={drawerOpen} width={modalSize}>
          {React.createElement(modalComponent, {
            ...target,
            onClose: closeDrawer
          })}
        </Drawer>
        <Pusher dimmed={drawerOpen}>
          <Modal
            centered={false}
            closeIcon
            onClose={closeModal}
            open={visibleWindow}
            size={modalSize as ModalProps['size']}>
            {React.createElement(modalComponent, {
              ...target,
              onClose: closeModal
            })}
          </Modal>
          {children}
        </Pusher>
      </Sidebar.Pushable>
    </ModalContext.Provider>
  )
}

const Pusher = styled(Sidebar.Pusher)`
  height: 100vh;
  overflow-y: scroll !important;

  //Hide the scrollbar for Chrome, Safari, Edge, and Opera
  ::-webkit-scrollbar {
    display: none !important;
  }

  //Hide the scrollbar for Internet Explorer
  -ms-overflow-style: none !important;

  //Hide the scrollbar for Firefox
  scrollbar-width: none !important;

  &.pusher.dimmed:after {
    position: fixed;
  }
` as typeof Sidebar.Pusher

export default ModalSystem
