import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

import { ViewPayload } from 'src/components/Table/types'
import { IGNIS_RED } from 'src/constants/colors'
import { ADMIN_ROLES } from 'src/constants/roles'
import { useHasRole } from 'src/hooks'
import { ViewType } from 'src/types/SavedView'
import { FilterOption } from '../utils'

export interface ViewOption {
  data: ViewPayload
  id: number
  name: string
  type: ViewType
}

interface Props {
  isMyView?: boolean
  view: ViewOption
  onClick(data: ViewOption['data']): void
  onDelete?: () => void
}

const SavedViewOption = ({ onClick, onDelete, view, isMyView }: Props) => {
  const { data, name } = view
  const isAdmin = useHasRole(ADMIN_ROLES)
  return (
    <Option onClick={() => onClick(data)}>
      <p>{name}</p>
      {(isAdmin || isMyView) && onDelete && (
        <FontAwesomeIcon
          color={IGNIS_RED}
          icon={faTrashCan}
          onClick={event => {
            event.stopPropagation()
            onDelete()
          }}
        />
      )}
    </Option>
  )
}

const Option = styled(FilterOption)`
  &&& {
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 5px !important;
    &:hover {
      background-color: rgb(194, 207, 224, 0.2);
    }
    & > p {
      flex: 1;
      padding-right: 8px;
      word-break: break-all;
      margin: 0;
    }
    & > svg:hover {
      color: var(--aqua-blue);
    }
  }
`

export default SavedViewOption
