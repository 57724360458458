export const formatFullName = (
  firstName: string,
  lastName?: string,
  salutation?: string
) => {
  let fullName = salutation ? `${salutation} ${firstName}` : firstName

  if (lastName) {
    fullName = fullName.concat(` ${lastName}`)
  }

  return fullName
}
