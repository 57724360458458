import AddServiceLineModal from './AddServiceLineModal'
import ApprovedSubmissionModal from './ApprovedSubmissionModal'
import DetailsDrawer from './AssetDrawerModal'
import ChangeTierModal from './ChangeTierModal'
import DeleteServiceLineModal from './DeleteServiceLineModal'
import EditDrawer from './EditDrawer'
import StateInventorySubmissionModal from './StateInventorySubmission'
import SubmittedSubmissionModal from './SubmittedSubmissionModal'
import AssetSendCommunicationModal from './AssetSendCommunicationModal'

export {
  ApprovedSubmissionModal,
  ChangeTierModal,
  DeleteServiceLineModal,
  DetailsDrawer,
  EditDrawer,
  StateInventorySubmissionModal,
  SubmittedSubmissionModal,
  AssetSendCommunicationModal
}

export const MODALS = {
  addServiceLine: AddServiceLineModal,
  approvedSubmission: ApprovedSubmissionModal,
  changeTiers: ChangeTierModal,
  deleteServiceLines: DeleteServiceLineModal,
  editServiceLine: EditDrawer,
  submitInventory: StateInventorySubmissionModal,
  submittedSubmission: SubmittedSubmissionModal,
  viewAsset: DetailsDrawer,
  sendAssetCommunication: AssetSendCommunicationModal
}
