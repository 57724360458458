import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from './axiosBaseQuery'
import { Tags, SearchParams, PaginatedResponseV2, ApiResponse } from './types'
import { buildParameters } from 'src/services'
import { Submission } from 'src/types/Submission'
import { SubmissionPeriod } from 'src/types/SubmissionPeriod'
import { SubmissionCommunication } from 'src/types/SubmissionCommunication'
import System from 'src/types/System'
import { Activity } from 'src/types/SubmissionActivity'
import { ExternalSubmission } from 'src/types/ExternalSubmission'
import { PtdPwsSettings } from 'src/types/PtdPwsSettings'
import { InventoryCommunicationsData } from 'src/types/InventoryCommunicationsData'
import { PtdTenantSettings } from 'src/types/PtdTenantSettings'

interface CreateSubmission {
  submission?: Omit<Partial<Submission>, 'id'>
}

interface CreateExternalSubmission {
  submission?: Omit<Partial<ExternalSubmission>, 'id'>
}

interface SubmissionPeriodCommentParams {
  submissionPeriodId: number
  pwsId: number
}
interface CommentFormData {
  comment: string
  commentType: string
  originType: string
  originId: number
  submissionId: number
}

interface CreateComment {
  submissionPeriodId: number
  pwsId: number
  comment: CommentFormData
}

interface CreateAdditionalInfo {
  id?: number
  pwsId: number
  additionalInfo: { [key: string]: any }
}

interface AdditionalInfo {
  data: string
  id: number
  pwsId: number
}

interface GetAllSubmissionsParams {
  submissionPeriodId?: number
  searchParams: SearchParams
}

interface ExternalSubmissionParams {
  externalSubmissionId: number
}

interface GetSubmissionCommunicationParams {
  pwsId: number
  submissionPeriodId: number
}

interface InventoryCommunicationsUserRemovedRequest {
  id: number
  userRemoved: boolean
}

export const stateSubmissionApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: '/pws/state-submission',
    skipAccount: true
  }),
  reducerPath: 'stateSubmissionApi',
  tagTypes: [
    Tags.System,
    Tags.Submissions,
    Tags.SubmissionPeriods,
    Tags.Comments,
    Tags.AdditionalInfo,
    Tags.ExternalSubmissions,
    Tags.InventoryCommunications
  ],
  endpoints: builder => ({
    getAllSubmissions: builder.query<
      PaginatedResponseV2<Submission>,
      GetAllSubmissionsParams
    >({
      providesTags: [Tags.Submissions],
      query: ({ submissionPeriodId, searchParams }) => {
        const params = buildParameters(searchParams, true).toString()
        return {
          method: 'GET',
          url: `/submissions/submission-period/${
            submissionPeriodId ?? ''
          }?${params}`
        }
      }
    }),
    getExternalSubmissions: builder.query<
      PaginatedResponseV2<ExternalSubmission>,
      GetAllSubmissionsParams
    >({
      providesTags: [Tags.ExternalSubmissions],
      query: ({ searchParams }) => {
        const params = buildParameters(searchParams, true).toString()
        return {
          method: 'GET',
          url: `/external-submissions?${params}`
        }
      }
    }),
    getExternalSubmission: builder.query<
      ExternalSubmission,
      ExternalSubmissionParams
    >({
      providesTags: [Tags.ExternalSubmissions],
      query: ({ externalSubmissionId }) => {
        return {
          method: 'GET',
          url: `/external-submissions/${externalSubmissionId}`
        }
      }
    }),
    completeExternalSubmission: builder.mutation<
      ExternalSubmission,
      ExternalSubmissionParams
    >({
      invalidatesTags: [Tags.ExternalSubmissions],
      query: ({ externalSubmissionId }) => ({
        method: 'POST',
        url: `/external-submissions/${externalSubmissionId}/complete`
      })
    }),
    getSystemInfo: builder.query<System, void>({
      providesTags: [Tags.System],
      query: () => {
        return {
          method: 'get',
          url: `/systems/account`
        }
      }
    }),
    getLatestSubmissionPeriod: builder.query<
      ApiResponse<SubmissionPeriod>,
      void
    >({
      providesTags: [Tags.SubmissionPeriods],
      query: () => {
        return {
          method: 'GET',
          url: `/submission-periods/latest`
        }
      }
    }),
    getLatestSubmission: builder.query<ApiResponse<Submission>, number>({
      providesTags: [Tags.Submissions],
      query: (systemId: number) => {
        return {
          method: 'GET',
          url: `/submissions/systems/${systemId}/latest`
        }
      }
    }),
    getAdditionalInfo: builder.query<ApiResponse<AdditionalInfo>, number>({
      providesTags: [Tags.AdditionalInfo],
      query: (pwsId: number) => {
        return {
          method: 'GET',
          url: `/systems/${pwsId}/submissiondata`
        }
      }
    }),
    upsertAdditionalInfo: builder.mutation<
      { [key: string]: any },
      CreateAdditionalInfo
    >({
      invalidatesTags: [Tags.AdditionalInfo],
      query: ({ id, pwsId, additionalInfo }) => {
        return {
          data: additionalInfo,
          method: id ? 'PUT' : 'POST',
          url: id
            ? `/systems/submissiondata/${id}`
            : `/systems/${pwsId}/submissiondata`
        }
      }
    }),
    triggerExternalSubmission: builder.mutation<
      ExternalSubmission,
      CreateExternalSubmission
    >({
      invalidatesTags: [Tags.ExternalSubmissions],
      query: ({ submission }) => ({
        data: submission,
        method: 'POST',
        url: `/external-submissions`
      })
    }),
    triggerSubmission: builder.mutation<Submission, CreateSubmission>({
      invalidatesTags: [Tags.Submissions],
      query: ({ submission }) => ({
        data: submission,
        method: 'POST',
        url: `/submissions`
      })
    }),
    createComment: builder.mutation<Activity, CreateComment>({
      invalidatesTags: [Tags.Comments],
      query: ({ submissionPeriodId, pwsId, comment }) => {
        return {
          data: comment,
          method: 'POST',
          url: `/submission-periods/${submissionPeriodId}/comments/${pwsId}`
        }
      }
    }),
    getAllSubmissionPeriodComments: builder.query<
      ApiResponse<Activity[]>,
      SubmissionPeriodCommentParams
    >({
      providesTags: [Tags.Comments],
      query: ({ submissionPeriodId, pwsId }) => {
        return {
          method: 'GET',
          url: `/submission-periods/${submissionPeriodId}/comments/${pwsId}`
        }
      }
    }),
    updatePWSPTDSettings: builder.mutation<string, PtdPwsSettings>({
      query: ptdPwsSettings => {
        return {
          data: ptdPwsSettings,
          method: 'PUT',
          url: `/ptd/pwsConfig`
        }
      }
    }),
    getPWSPTDSettings: builder.query<PtdPwsSettings, void>({
      query: () => {
        return {
          method: 'GET',
          url: `/ptd/pwsConfig`
        }
      }
    }),
    getTenantPTDSettings: builder.query<PtdTenantSettings, void>({
      query: () => {
        return {
          method: 'GET',
          url: `/ptd/pwsTenantSettings`
        }
      }
    }),
    getInventoryCommunicationsSubmission: builder.query<
      SubmissionCommunication,
      GetSubmissionCommunicationParams
    >({
      query: data => {
        const { pwsId, submissionPeriodId } = data
        return {
          method: 'GET',
          url: `/communications/${pwsId}/${submissionPeriodId}`
        }
      }
    }),
    createInventoryCommunicationsSubmission: builder.mutation<
      SubmissionCommunication,
      InventoryCommunicationsData
    >({
      invalidatesTags: [Tags.InventoryCommunications],
      query: data => ({ data, method: 'POST', url: `/communications` })
    }),
    updateInventoryCommunicationsUserRemoved: builder.mutation<
      boolean,
      InventoryCommunicationsUserRemovedRequest
    >({
      invalidatesTags: [Tags.InventoryCommunications],
      query: data => ({
        data: data.userRemoved,
        method: 'PUT',
        url: `/communications/userRemoved/${data.id}`
      })
    })
  })
})

export const {
  useGetSystemInfoQuery,
  useCreateCommentMutation,
  useUpsertAdditionalInfoMutation,
  useGetAdditionalInfoQuery,
  useGetAllSubmissionsQuery,
  useGetAllSubmissionPeriodCommentsQuery,
  useGetLatestSubmissionQuery,
  useGetLatestSubmissionPeriodQuery,
  useTriggerSubmissionMutation,
  useTriggerExternalSubmissionMutation,
  useGetExternalSubmissionsQuery,
  useGetExternalSubmissionQuery,
  useCompleteExternalSubmissionMutation,
  useUpdatePWSPTDSettingsMutation,
  useGetPWSPTDSettingsQuery,
  useGetTenantPTDSettingsQuery,
  useGetInventoryCommunicationsSubmissionQuery,
  useCreateInventoryCommunicationsSubmissionMutation,
  useUpdateInventoryCommunicationsUserRemovedMutation
} = stateSubmissionApi
