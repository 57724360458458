import React, { useCallback, useMemo } from 'react'
import ReactGA from 'react-ga4'
import { Pagination, PaginationProps } from 'semantic-ui-react'
import styled from 'styled-components'

import { Row } from 'src/components/Layout'
import { PRIMARY } from 'src/constants/colors'
import PageSizeSelection from './PageSizeSelection'

interface Props {
  currentPage: number
  pageSize: number
  totalRecords: number
  onPageChanged?: (newPage: number) => void
  onPageSizeChanged?(newSize: number): void
}

const TritonPagination = ({
  currentPage,
  onPageChanged,
  onPageSizeChanged,
  pageSize,
  totalRecords
}: Props): JSX.Element => {
  const totalPages = useMemo(
    () => Math.ceil(totalRecords / pageSize),
    [totalRecords, pageSize]
  )
  const changePage = useCallback(
    (_: unknown, { activePage }: PaginationProps) => {
      if (!onPageChanged) return
      const pageNumber = parseInt(`${activePage}`, 10)
      ReactGA.event({
        action: 'Change Page',
        category: 'Table',
        value: pageNumber
      })
      onPageChanged(activePage ? pageNumber : 1)
    },
    [onPageChanged]
  )

  return (
    <Row style={{ padding: '0 14px 14px 14px' }}>
      <StyledPagination
        activePage={currentPage}
        boundaryRange={0}
        ellipsisItem={null}
        firstItem={totalRecords > pageSize * 5 ? 1 : null}
        lastItem={totalRecords > pageSize * 5 ? Math.ceil(totalRecords / pageSize) : null}
        onPageChange={changePage}
        siblingRange={2}
        totalPages={totalPages}
      />
      <PageSizeSelection
        onPageSizeChanged={onPageSizeChanged}
        pageSize={pageSize}
      />
    </Row>
  )
}

const StyledPagination = styled(Pagination)`
  &&& {
    background-color: transparent !important;
    & > a.item[type$='Item'] {
      &:hover {
        background-color: ${PRIMARY};
        color: white;
        opacity: 0.5;
      }
      &.active {
        background-color: ${PRIMARY};
        color: white;
      }
      background-color: transparent;
    }
  }
` as typeof Pagination

TritonPagination.displayName = 'Pagination'

export default TritonPagination
