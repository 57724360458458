import GenerateCrudSaga from './helpers/CrudSaga'
import actions from '../actions/samplingEvents'
import {
  fetchProgramSamplingEvents,
  fetchAccountSamplingEvent,
  createAccountSamplingEvent,
  updateAccountSamplingEvent,
  deleteAccountSamplingEvent
} from '../API'

const saga = GenerateCrudSaga({
  entityName: 'samplingEvent',
  actions,
  fetchCollectionMethod: fetchProgramSamplingEvents,
  fetchEntityMethod: fetchAccountSamplingEvent,
  createEntityMethod: createAccountSamplingEvent,
  updateEntityMethod: updateAccountSamplingEvent,
  deleteEntityMethod: deleteAccountSamplingEvent,
  deleteFailureMessage: () =>
    `Sorry, you cannot delete an Event that has Locations and/or Samples attached to it.`
})

export default saga
