import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@120wateraudit/envirio-components'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataLink } from 'src/components/Layout'

interface Props {
  label: string
  text: string
  isSelected?: boolean
  onClick?: () => void
  onDownloadClick: () => void
}

export const OptionCard = ({
  label,
  text,
  isSelected,
  onClick,
  onDownloadClick
}: Props) => (
  <Wrapper isSelected={isSelected} onClick={onClick}>
    <ContentWrapper>
      <DataLink href="#" onClick={onDownloadClick}>
        <span>
          {label} <FontAwesomeIcon icon={faDownload} />
        </span>
      </DataLink>
      <TextWrapper>{text}</TextWrapper>
    </ContentWrapper>
  </Wrapper>
)

const Wrapper: any = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 76px;
  width: 392px;
  border: 1px solid ${(props: any) => getAccentColor(props.isSelected)};
  border-radius: 1px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  padding: 0.9rem;
  cursor: pointer;
`

const ContentWrapper = styled.div`
  margin-left: 0.5rem;
`

const TextWrapper = styled.div`
  font-size: 0.95rem;
  margin-top: 0.4rem;
  color: ${colors.black50};
`

const getAccentColor = (isSelected: boolean) =>
  isSelected ? colors.primary : colors.black50
