import {
  PWSCustomFieldEntity
} from '@120wateraudit/envirio-components/dist/models'
import { CustomFieldDefinition, retrieveDefinitions } from "@120wateraudit/custom-fields-ui-components";
import {FilterDefinition, FilterType} from './types'
import {getItem, LocalStorageItem} from "src/utils/localStorage";
import {AWS_BASE} from "src/API";

function combineFilters(
    filters: FilterDefinition[],
    customFieldDefinitions: CustomFieldDefinition[]
): FilterDefinition[] {
  if (customFieldDefinitions.length <= 0) {
    return filters
  }

  const customFieldFilters: FilterDefinition[] = customFieldDefinitions.map(
      definition => {
        return {
          customField: definition,
          key: `cf-${definition.name}`,
          label: definition.name ?? '',
          options: getFilterOptions(definition),
          type: getFilterType(definition.dataType ?? '')
        }
      }
  )
  return [...filters, ...customFieldFilters]
}

export const getFilter = (definition: CustomFieldDefinition) => {
  return {
    customField: definition,
    key: `cf-${definition.name}`,
    label: definition.name ?? '',
    options: getFilterOptions(definition),
    type: getFilterType(definition.dataType ?? '')
  }
}

const  getFilterOptions = (definition: CustomFieldDefinition): undefined | FilterDefinition['options'] => {
  if (definition.dataType === 'BOOLEAN') {
    return [
      {
        text: 'True',
        value: true
      },
      {
        text: 'False',
        value: false
      }
    ]
  }

  if (definition.dataType === 'LIST') {
    return Object.entries(definition.picklistValues)
    .map(entry => ({ text: entry[1], value: entry[0] }))
  }
  return undefined
}

const getFilterType = (dataType: string): FilterType  => {
  switch (dataType) {
    case 'DATE':
      return FilterType.DatePicker
    case 'HYPERLINK':
    case 'STRING':
      return FilterType.TextMatch
    case 'NUMBER':
      return FilterType.Number
    case 'DATETIME':
      return FilterType.DateTimePicker
    case 'LIST':
    case 'BOOLEAN':
      return FilterType.ListSelection
    default:
      return FilterType.TextMatch
  }
}

async function getCustomFieldFilters(
    entityName: PWSCustomFieldEntity,
): Promise<FilterDefinition[]> {
  const definitions = await retrieveDefinitions(AWS_BASE, getItem(LocalStorageItem.TOKEN) ?? '', entityName.toString())
  return combineFilters([], definitions)
}

export default getCustomFieldFilters
