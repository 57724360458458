import React from 'react'

import { Step } from 'src/components/Forms/Wizard'
import InventoryInformation from './InventoryInformation'

interface Values {
  amountSpentOnInventorySubmission?: string
}

export function validateInventoryInformation(values: Values) {
  if (
    !values.amountSpentOnInventorySubmission ||
    (values.amountSpentOnInventorySubmission &&
      values.amountSpentOnInventorySubmission.trim().length === 0)
  ) {
    return false
  }

  return true
}

export const GEORGIA_STEPS: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Inventory Information',
    render: () => <InventoryInformation />,
    validateStepValues: validateInventoryInformation
  }
]
