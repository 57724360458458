const ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/

export const isValidZip = (zip: string) => ZIP_REGEX.test(zip)

// TODO switch to this regex when we fix the regex in Comms API
// const PHONE_REGEX = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/i
const PHONE_REGEX = /^\d{10}$/

export const isValidPhone = (phoneNumber: string) =>
  PHONE_REGEX.test(phoneNumber)
