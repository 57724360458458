import React, { memo } from 'react'
import styled from 'styled-components'
import { Button } from './shared'
import {
  SamplingEventFragment,
  ProgramFragment
} from 'src/hooks/useProgramsAndEvents'
import { TwoButtonGrid } from 'src/modules/Modal/shared'

const SendModalFooter: React.FC<Props> = ({
  loading,
  selectedEvent,
  selectedProgram,
  stepNumber,
  onAssign,
  onBack,
  onNext,
  onClose,
  inProgram
}) => {
  if (stepNumber === 0) {
    return (
      <TwoButtonGrid>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="primary" disabled={!selectedProgram} onClick={onNext}>
          Next
        </Button>
      </TwoButtonGrid>
    )
  }

  if (stepNumber === 1) {
    return (
      <ButtonGrid>
        <Button onClick={onClose} fluid>
          Cancel
        </Button>
        {!inProgram && (
          <Button onClick={onBack} fluid>
            Back
          </Button>
        )}
        <Button
          variant="primary"
          disabled={!selectedEvent || loading}
          onClick={onAssign}
          fluid>
          Assign
        </Button>
      </ButtonGrid>
    )
  }

  return null
}

export interface Props {
  loading: boolean
  selectedEvent?: SamplingEventFragment
  selectedProgram?: ProgramFragment
  stepNumber: number
  inProgram: boolean
  onAssign: () => void
  onBack: () => void
  onNext: () => void
  onClose: () => void
}

const ButtonGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 1rem;
  justify-items: center;
`

export default memo(SendModalFooter)
