import { isEmpty } from 'lodash'
import pluralize from 'pluralize'
import React, { useCallback } from 'react'
import { Checkbox, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import IconButton from 'src/components/Buttons/IconButton'
import { AQUA_BLUE } from 'src/constants/colors'
import { useToggle } from 'src/hooks'
import {
  ActiveFilters,
  CustomFieldFilterDefinition,
  FilterDefinition,
  FilterableProps
} from '../types'
import { FilterOption, FilterOptions } from '../utils'
import { CustomFieldFilterChoice } from './CustomFieldFilter'

interface FilterChoiceProps {
  activeFilters: ActiveFilters
  filter: FilterDefinition
  onFilterChanged?: FilterableProps['onFilterChanged']
}

export const BuiltInFilterChoice = ({
  activeFilters,
  filter,
  onFilterChanged
}: FilterChoiceProps): JSX.Element => {
  const onToggleFilter = useCallback(
    (checked: boolean) => {
      // We send null to indicate the filter is being removed.
      if (onFilterChanged)
        onFilterChanged({ key: filter.key, value: checked ? undefined : null })
    },
    [onFilterChanged, filter.key]
  )

  return (
    <FilterOption>
      <Checkbox
        checked={activeFilters.hasOwnProperty(filter.key)}
        label={<label>{filter.label}</label>}
        onChange={(_, { checked }) => onToggleFilter(!!checked)}
      />
    </FilterOption>
  )
}

const getFilterPickerTitle = (activeFilters: ActiveFilters) => {
  let filterCount = Object.keys(activeFilters).length
  if (typeof activeFilters.customFields === 'string') {
    filterCount =
      // We subtract 1 to remove the custom field key
      filterCount - 1 + JSON.parse(activeFilters.customFields).length
  }

  if (filterCount > 0) {
    return `${pluralize('Filter', filterCount, true)} Applied`
  }

  return ''
}

interface Props {
  activeFilters: ActiveFilters
  className?: string
  filters: FilterDefinition[]
  onFilterChanged?: FilterableProps['onFilterChanged']
}

const FilterPicker = ({
  activeFilters,
  className,
  filters,
  onFilterChanged
}: Props): JSX.Element => {
  const [showMenu, toggleMenu] = useToggle()
  return (
    <Container>
      <Popup
        on="click"
        onClose={toggleMenu}
        onOpen={toggleMenu}
        open={showMenu}
        position="bottom left"
        trigger={
          <IconButton
            className={className}
            name="filter"
            iconColor={isEmpty(activeFilters) ? 'rgb(85, 91, 102)' : AQUA_BLUE}
            basic={false}
            title={getFilterPickerTitle(activeFilters)}
          />
        }>
        <FilterOptions>
          {filters
            .sort((a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0;
            })
            .map(filter =>
            filter.customField ? (
              <CustomFieldFilterChoice
                activeFilters={activeFilters}
                filter={filter as CustomFieldFilterDefinition}
                key={filter.key}
                onFilterChanged={onFilterChanged}
              />
            ) : (
              <BuiltInFilterChoice
                activeFilters={activeFilters}
                filter={filter}
                key={filter.key}
                onFilterChanged={onFilterChanged}
              />
            )
          )}
        </FilterOptions>
      </Popup>
    </Container>
  )
}

const Container = styled.div`
  margin-left: auto;
  &&& {
    button {
      padding-top: 11px;
      padding-bottom: 12px;
      height: 38px;
      background-color: #fff;
      border: 1px solid #e5e7eb;
    }
    i {
      color: #555b66;
    }
  }
`

export default FilterPicker
