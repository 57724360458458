import React from 'react'
import { Field, useFormState } from 'react-final-form'

import Dropdown from 'src/components/Forms/Dropdown'
import { yesNoDropdownOptions } from 'src/modules/ServiceLines/dataAccess'
import {
  DoubleInputGrid,
  FlexDiv,
  GeneralContainer,
  SuperAsterisk
} from '../../shared'
import { TritonTextField } from 'src/components/Forms/TextField'

const OREpaMethodsPart2 = (): JSX.Element => {
  const formState = useFormState();

  return (
    <GeneralContainer>
      <p style={{ marginBottom: '0px' }}>
        During which normal operating activities are you collecting information
        on service line material? Check all that apply.
        <SuperAsterisk />
      </p>
      <DoubleInputGrid>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Water meter reading"
          />
          <label>Water meter reading</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Water meter repair or replacement"
          />
          <label>Water meter repair or replacement</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Service line repair or replacement"
          />
          <label>Service line repair or replacement</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Water main repair or replacement"
          />
          <label>Water main repair or replacement</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Backflow prevention device inspection"
          />
          <label>Backflow prevention device inspection</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices"
            type="checkbox"
            value="Other"
          />
          <label>Other</label>
        </FlexDiv>
      </DoubleInputGrid>
      <Field<string>
        component={TritonTextField}
        label="If other please explain:"
        displayRequiredAsterisk={formState.values['inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices']?.indexOf('Other') > -1 ?? false}
        name="inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation"
        textarea
      />
      <p style={{ marginBottom: '0px' }}>
        Did you develop or revise standard operating procedures to collect service line material information
        during normal operation?
        <SuperAsterisk />
      </p>
      <Field<string>
        component={Dropdown}
        fluid
        name="inventoryMethodsPart2Q2StandardProceduresYesNo"
        options={yesNoDropdownOptions}
        textarea
      />
      <Field<string>
        component={TritonTextField}
        label="If Yes, please describe:"
        name="inventoryMethodsPart2Q2StandardProceduresOtherExplanation"
        displayRequiredAsterisk={formState.values['inventoryMethodsPart2Q2StandardProceduresYesNo'] === 'Yes'}
        textarea
      />
    </GeneralContainer>
  )
}

export default OREpaMethodsPart2
