import { Button, Loader, colors } from '@120wateraudit/envirio-components'
import { PWSProgram } from '@120wateraudit/envirio-components/dist/models'
import React, { FC, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { hardDelete } from 'src/API'
import Dropdown from 'src/components/Forms/Dropdown'
import { TwoButtonGrid } from 'src/modules/Modal/shared'
import { Location } from 'src/types/Location'

interface Props {
  accountId: number
  program: PWSProgram
  selectedLocations: Location[]
  onClose: () => void
  onCompletion: () => void
}

const removeLocationEvent = async (
  eventId: number,
  url: string,
  locationId: number
) => {
  const completeRemoveEventURL = `${url}${eventId}/locations/${locationId}`
  await hardDelete(completeRemoveEventURL)
}

const RemoveEventModal: FC<Props> = ({
  accountId,
  onClose,
  onCompletion,
  program,
  selectedLocations
}) => {
  const [removalError, setRemovalError] = useState<string>()
  const removeEventURL = `/pws/rest/accounts/${accountId}/samplingeventlocation/program/${program.id}/samplingevent/`
  const locationId = selectedLocations[0].id
  const locationSamplingEvents = selectedLocations[0].samplingEvents

  const eventChoices =
    locationSamplingEvents &&
    locationSamplingEvents
      .filter(event => program.id === event.programId)
      .map(event => ({
        text: event.name,
        value: event.id
      }))

  const changeEvents = async values => {
    try {
      if (locationId) {
        await removeLocationEvent(values.value, removeEventURL, locationId)
        onCompletion()
        onClose()
      }
    } catch (error) {
      if (
        error.response.data.message ===
        'Location cannot be removed due to having linked samples.'
      ) {
        setRemovalError(
          `This location cannot be removed from the event because samples have already been created.`
        )
      } else if (
        error.response.data.message ===
        'Location cannot be removed due to having linked kits.'
      ) {
        setRemovalError(
          `This location cannot be removed from the event because kits have already been created.`
        )
      } else {
        setRemovalError(
          'There seems to be an error, please try again or contact support for assistance.'
        )
      }
    }
  }

  const validate = e => {
    const errors: Record<string, string> = {}
    if (!e.value || e.value === undefined) {
      errors.value = 'Event Required'
    }

    return errors
  }

  const initialData = { value: undefined }

  return (
    <ModalWrapper>
      <ChangeEventModalHeader>
        {!removalError ? 'Remove Event' : 'Oops...'}
      </ChangeEventModalHeader>
      <Form
        initialValues={initialData}
        onSubmit={changeEvents}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            {removalError && (
              <>
                <ErrorHeader>{removalError}</ErrorHeader>
                <ErrorButton
                  type="button"
                  onClick={() => {
                    form.reset()
                    onClose()
                  }}
                  disabled={submitting}
                  variant="error">
                  Close
                </ErrorButton>
              </>
            )}
            {submitting && !removalError && (
              <>
                <Loader />
                <LoadingHeader>Events are updating!</LoadingHeader>
              </>
            )}
            {!submitting && !removalError && (
              <>
                <ChangeEventCheckboxContainer>
                  <ChangeEventDropdown
                    component={Dropdown}
                    fluid
                    name="value"
                    options={eventChoices}
                    placeholder="Event Choice..."
                  />
                </ChangeEventCheckboxContainer>
                <TwoButtonGrid>
                  <Button
                    type="button"
                    onClick={() => {
                      form.reset()
                      onClose()
                    }}
                    disabled={submitting}
                    variant="error">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={submitting || pristine}
                    variant="primary">
                    Submit
                  </Button>
                </TwoButtonGrid>
              </>
            )}
          </form>
        )}
        validate={validate}
      />
    </ModalWrapper>
  )
}

const ErrorButton = styled(Button)`
  display: block;
  margin: 0 auto;
`

const ChangeEventCheckboxContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`

const ChangeEventModalHeader = styled.h5`
  text-align: center;
`

const LoadingHeader = styled.h5`
  text-align: center;
  color: ${colors.primary};
`

const ErrorHeader = styled.h6`
  text-align: center;
  color: ${colors.warning};
`

const ChangeEventDropdown = styled(Field)`
  .ui.multiple.search.dropdown > input.search {
    display: none;
  }
`

const ModalWrapper = styled.div`
  margin: 3rem;
  display: grid;
  grid-template-columns: auto;
  gap: 1rem 0;
  justify-items: center;
`

export default RemoveEventModal
