import createDetailsReducer from './helpers/DetailsReducers'
import actions from '../actions/sampleStatusHistory'

const reducer = createDetailsReducer({
  entityName: 'sampleStatusHistory',
  fetchAction: actions.detailsActions.fetchRequest,
  fetchSuccessAction: actions.detailsActions.fetchSuccess,
  fetchFailureAction: actions.detailsActions.fetchFailure,
  unloadAction: actions.detailsActions.unload,
  updateActionRequest: actions.updateActions.updateRequest,
  updateActionSuccess: actions.updateActions.updateSuccess,
  updateActionFailure: actions.updateActions.updateFailure
})

export default reducer
