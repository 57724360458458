import GenerateCrudSaga from './helpers/CrudSaga'
import actions from '../actions/activities'
import {
  createActivity,
  deleteActivity,
  fetchActivities,
  updateActivity
} from '../API'

const saga = GenerateCrudSaga({
  entityName: 'activity',
  actions,
  fetchCollectionMethod: fetchActivities,
  createEntityMethod: createActivity,
  deleteEntityMethod: deleteActivity,
  updateEntityMethod: updateActivity
})

export default saga
