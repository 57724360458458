import createDetailsReducer from './helpers/DetailsReducers'
import actions from '../actions/leadServiceLines'

const reducer = createDetailsReducer({
  entityName: 'leadServiceLine',
  fetchAction: actions.detailsActions.fetchRequest,
  fetchSuccessAction: actions.detailsActions.fetchSuccess,
  fetchFailureAction: actions.detailsActions.fetchFailure,
  unloadAction: actions.detailsActions.unload,
  deleteAction: actions.deleteActions.deleteRequest,
  deleteSuccessAction: actions.deleteActions.deleteSuccess,
  deleteFailureAction: actions.deleteActions.deleteFailure,
  updateActionRequest: actions.updateActions.updateRequest,
  updateActionSuccess: actions.updateActions.updateSuccess,
  updateActionFailure: actions.updateActions.updateFailure
})

export default reducer
