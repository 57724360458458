import { Auth0Provider } from '@auth0/auth0-react'
import React, { FC, ReactNode, memo } from 'react'
import { Router } from 'react-router-dom'

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'src/constants'
import { browserHistory } from '../utils/navigation'
import ReduxProvider from './ReduxProvider'
import ThemeProvider from './ThemeProvider'

const GlobalProviders: FC<Props> = ({ children }) => {
  return (
    <ReduxProvider>
      <ThemeProvider>
        <Router history={browserHistory}>
          <Auth0Provider
            cacheLocation={'memory'}
            clientId={AUTH0_CLIENT_ID}
            domain={AUTH0_DOMAIN}
            onRedirectCallback={appState =>
              browserHistory.replace(
                (appState && appState.returnTo) || window.location.pathname
              )
            }
            authorizationParams={{
              redirect_uri: window.location.origin
            }}
            useRefreshTokens={true}
            useRefreshTokensFallback={ true }>
            {children}
          </Auth0Provider>
        </Router>
      </ThemeProvider>
    </ReduxProvider>
  )
}

interface Props {
  children: ReactNode
}

export default memo(GlobalProviders)
