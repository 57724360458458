import { Button } from '@120wateraudit/envirio-components'
import uniqBy from 'lodash/uniqBy'
import React from 'react'

import AnalyteDefinitions from 'src/containers/AnalyteDefinitions'
import Modal from 'src/modules/Modal/ModalContents'
import { SamplingEvent } from 'src/types/SamplingEvents'

interface Props {
  event: SamplingEvent
  onClose(): void
}

const ViewAnalyteModal = ({ event, onClose }: Props): JSX.Element => {
  const primaryAction = (
    <Button onClick={onClose} variant="primary">
      Close
    </Button>
  )
  return (
    <Modal primaryAction={primaryAction} title="Event Analytes">
      <AnalyteDefinitions
        analytes={uniqBy(
          event.samplingEventAnalytes,
          'analyte.displayName'
        ).map(pd => {
          const isPrimary = pd.analyteId === event.primaryAnalyte?.id
          return {
            name: pd.analyte.name,
            upperLimit: pd.exceedanceLimit,
            analyteId: pd.analyte.id,
            isPrimary: isPrimary
          }
        })}
        style={{ padding: '0 1rem' }}
      />
    </Modal>
  )
}

export default ViewAnalyteModal
