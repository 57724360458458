import { handleActions } from 'redux-actions'

export interface IndexReducerState<T> {
  items: T[]
  isFetching: boolean
  errors: any[]
  hasFetched?: boolean
}

interface IndexReducerOptions {
  fetchAction: any
  fetchSuccessAction: any
  fetchFailureAction: any
  createFailureAction: any
  updateSuccessAction: any
  updateFailureAction: any
  unloadAction?: any
  errorClearAction: any
  collectionName: string
  entityName: string
  additionalActions?: any
}

export const initialState = {
  items: [],
  isFetching: false,
  errors: [],
  hasFetched: false
}

const getErrorsFromPayload = payload => {
  return []
    .concat(payload.error ? payload.error : [])
    .concat(payload.errors ? payload.errors : [])
}

const createIndexReducer: any = <T>({
  fetchAction,
  fetchSuccessAction,
  fetchFailureAction,
  createFailureAction,
  updateSuccessAction,
  updateFailureAction,
  unloadAction,
  errorClearAction,
  entityName
}: IndexReducerOptions) => {
  const fetchActionString = fetchAction.toString()
  const fetchSuccessActionString = fetchSuccessAction.toString()
  const fetchFailureActionString = fetchFailureAction.toString()
  const createFailureActionString = createFailureAction.toString()
  const updateSuccessActionString = updateSuccessAction.toString()
  const unloadActionString = unloadAction.toString()
  const updateFailureActionString = updateFailureAction.toString()
  const errorClearActionString = errorClearAction.toString()

  return handleActions<any>(
    {
      [fetchActionString]: (state: IndexReducerState<T>, _action: any) => {
        return {
          ...state,
          isFetching: true,
          hasFetched: true
        }
      },
      [fetchSuccessActionString]: (
        state: IndexReducerState<T>,
        action: any
      ) => {
        return {
          ...state,
          items: action.payload.response,
          isFetching: false
        }
      },
      [fetchFailureActionString]: (
        state: IndexReducerState<T>,
        _action: any
      ) => {
        return {
          ...state,
          isFetching: false
        }
      },
      [createFailureActionString]: (
        state: IndexReducerState<T>,
        action: any
      ) => {
        return {
          ...state,
          errors: getErrorsFromPayload(action.payload)
        }
      },
      [updateSuccessActionString]: (
        state: IndexReducerState<T>,
        action: any
      ) => {
        return {
          ...state,
          items: state.items.map((item: any) => {
            return item.id === action.payload[entityName].id
              ? action.payload[entityName]
              : item
          })
        }
      },
      [updateFailureActionString]: (
        state: IndexReducerState<T>,
        action: any
      ) => {
        return {
          ...state,
          errors: getErrorsFromPayload(action.payload)
        }
      },
      [errorClearActionString]: (state: IndexReducerState<T>, _action: any) => {
        return {
          ...state,
          errors: []
        }
      },
      [unloadActionString]: (_state: IndexReducerState<T>, _action: any) =>
        initialState
    },
    initialState
  )
}

export default createIndexReducer
