import { PWSSamplingEventStatus } from '@120wateraudit/envirio-components/dist/models'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import omit from 'lodash/omit'
import moment from 'moment'
import React, { useState } from 'react'
import { Form } from 'react-final-form'

import { Program } from 'src/types/Programs'
import SamplingEventForm, {
  Values,
  createFormValidation,
  generateInitialValues
} from './Form'
import { useCreateEventMutation } from './dataAccess'
import { useEventNames } from './hooks'

function mapProtocolsFromKit(kit: Values['kit']) {
  if (!kit) {
    return []
  }

  return Array(kit.sampleQuantity > 0 ? kit.sampleQuantity : 1).fill({
    skuId: kit.id,
    willDeliver: false
  })
}

interface Props {
  program: Program
  onClose(): void
}

const NewSamplingEvent = ({ onClose, program }: Props): JSX.Element => {
  const programType = program.programType.name
  const isPitcherFilterProgram = programType === 'PF'
  const isReplacementProgram = programType === 'REPLACEMENT'
  const eventNames = useEventNames(program.id)
  const [error, setError] = useState<Error>()
  const [createEvent] = useCreateEventMutation()
  const createSamplingEvent = async (values: Values) => {
    const isPitcherFilterEvent = values.eventType === 'Pitcher/Filter'
    const goalNumber = parseInt(values.totalGoal || '0', 10)
    const newEvent = {
      ...values,
      endsOn: moment(values.endsOn).toISOString(),
      eventType: values.eventType,
      name: values.name?.trim(),
      primaryAnalyte: values.samplingEventAnalytes.find(sea => sea.primary)
        ?.analyteId,
      protocolDefinitions: mapProtocolsFromKit(values.kit),
      samplingEventAnalytes: values.samplingEventAnalytes.map(sea => ({
        ...sea,
        checkExceedance: sea.primary,
        isRange: !!sea.lowerLimit
      })),
      startsOn: moment(values.startsOn).toISOString(),
      status: PWSSamplingEventStatus.Active,
      totalPitchersGoal: goalNumber,
      totalSamplesGoal: goalNumber
    }
    const response = await createEvent(
      isPitcherFilterEvent
        ? omit(newEvent, 'samplingEventAnalytes', 'primaryAnalyte')
        : newEvent
    )

    if ('error' in response) {
      setError(new Error('Please try again or contact support@120water.com.'))
      return { [FORM_ERROR]: 'Could not submit form' }
    }

    onClose()
    return undefined
  }
  return (
    <Form
      initialValues={generateInitialValues(program)}
      // We are setting initialValuesEqual to always be true due to an issue with react-final-form-arrays
      // There may be a better solution in actually comparing the analyte arrays
      // https://github.com/final-form/react-final-form-arrays/issues/61
      initialValuesEqual={() => true}
      mutators={{ ...arrayMutators }}
      onSubmit={createSamplingEvent}
      render={({ handleSubmit, values, submitting, valid }) => (
        <SamplingEventForm
          valid={valid}
          error={error}
          program={{ ...program, events: eventNames }}
          onSubmit={handleSubmit}
          values={values}
          submitting={submitting}
          onCancel={onClose}
          type="create"
          isPitcherFilterProgram={isPitcherFilterProgram}
          isReplacementProgram={isReplacementProgram}
        />
      )}
      validate={createFormValidation(
        isReplacementProgram,
        eventNames,
        'create'
      )}
      validateOnBlur
    />
  )
}

export default NewSamplingEvent
