import {
  OptionsList,
  OptionsListItem as ListItem
} from '@120wateraudit/envirio-components'
import React, { FC, useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import { ReactComponent as ArrowDown } from 'src/assets/images/ArrowDown.svg'

const Text = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface ActionsProps {
  options: { name: string; onClick(): void }[]
}

const Actions: FC<ActionsProps> = ({ options }) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  return (
    <Popup
      basic
      on="click"
      onClose={toggleOpen}
      onOpen={toggleOpen}
      open={open}
      position="bottom right"
      trigger={
        <Button className="popupTrigger">
          <Text>
            Actions
            <ArrowDown />
          </Text>
        </Button>
      }>
      <OptionsList>
        {options.map(opt => (
          <ListItem
            key={opt.name}
            onClick={() => {
              toggleOpen()
              opt.onClick()
            }}>
            {opt.name}
          </ListItem>
        ))}
      </OptionsList>
    </Popup>
  )
}

export default Actions
