import createDetailsReducers from './helpers/DetailsReducers'
import actions from '../actions/samplingEvents'

const reducer = createDetailsReducers({
  entityName: 'samplingEvent',
  fetchAction: actions.detailsActions.fetchRequest,
  fetchSuccessAction: actions.detailsActions.fetchSuccess,
  fetchFailureAction: actions.detailsActions.fetchFailure,
  deleteAction: actions.deleteActions.deleteRequest,
  deleteSuccessAction: actions.deleteActions.deleteSuccess,
  deleteFailureAction: actions.deleteActions.deleteFailure,
  unloadAction: actions.detailsActions.unload,
  createActionRequest: actions.createActions.createRequest,
  createActionSuccess: actions.createActions.createSuccess,
  createActionFailure: actions.createActions.createFailure,
  updateActionRequest: actions.updateActions.updateRequest,
  updateActionSuccess: actions.updateActions.updateSuccess,
  updateActionFailure: actions.updateActions.updateFailure,
  errorClearAction: actions.errorActions.clear
})

export default reducer
