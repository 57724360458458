import { useCallback, useState } from 'react'

// We don't know what the arguments to the action can be, so we use any here.
// The implementor should define a better type.
export const useAction = (action: (...args: any[]) => Promise<unknown>) => {
  const [error, setError] = useState<Error>()
  const [isWorking, setWorking] = useState(false)
  const takeAction = useCallback(
    async (...args) => {
      try {
        setError(undefined)
        return await action(...args)
      } catch (error) {
        if (error instanceof Error) {
          setError(error)
        } else {
          setError(new Error('Something went wrong'))
        }
      } finally {
        setWorking(false)
      }
    },
    [action]
  )
  const clearError = () => {
    setError(undefined)
  }
  return { clear: clearError, error, isWorking, takeAction }
}
