import { ContactMethod } from '@120wateraudit/envirio-components/dist/models'
import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import { TritonDropdown } from 'src/components/Forms/Dropdown'
import { TritonTextField as TextField } from 'src/components/Forms/TextField'
import { TritonToggle as Toggle } from 'src/components/Forms/Toggle'
import { STATE_LIST } from 'src/constants/stateList'
import { useGetLanguagesQuery } from 'src/modules/Contacts/dataAccess'
import { convertEnumToListItems } from 'src/utils/selectlist'

const ContactInfo = (): JSX.Element => {
  const { languages = {}, loading } = useGetLanguagesQuery()
  const languageOptions = Object.entries(languages).map(([id, name]) => ({
    key: id,
    text: name,
    value: parseInt(id, 10)
  }))

  return (
    <div>
      <Field<string>
        component={StyledTextField}
        label="Primary Contact First Name"
        name="contact.firstName"
      />
      <Field<string>
        component={StyledTextField}
        label="Primary Contact Last Name"
        name="contact.lastName"
      />
      <Field<string>
        component={StyledTextField}
        label="Primary Contact Address Line 1"
        name="contact.addressLine1"
      />
      <Field<string>
        component={StyledTextField}
        label="Primary Contact Address Line 2"
        name="contact.addressLine2"
      />
      <Field<string>
        component={StyledTextField}
        label="Primary Contact City"
        name="contact.city"
      />
      <Field<string>
        component={StyledDropdown}
        label="Primary Contact State"
        name="contact.state"
        options={STATE_LIST}
        search
      />
      <Field<string>
        component={StyledTextField}
        label="Primary Contact ZIP Code"
        name="contact.zip"
      />
      <Field<string>
        component={StyledDropdown}
        label="Preferred Contact Method"
        name="contact.contactMethod"
        options={convertEnumToListItems(ContactMethod)}
      />
      <Field<string>
        component={StyledTextField}
        label="Primary Contact Phone"
        name="contact.primaryPhone"
      />
      <Field<boolean>
        component={StyledToggle}
        label="Is Primary Phone Mobile"
        name="contact.isPhoneMobile"
        type="checkbox"
      />
      <Field<string>
        component={StyledTextField}
        label="Primary Contact Alternative Phone"
        name="contact.alternativePhone"
      />
      <Field<boolean>
        component={StyledToggle}
        label="Is Alternative Phone Mobile"
        name="contact.isAlternativePhoneMobile"
        type="checkbox"
      />
      <Field<string>
        component={StyledTextField}
        label="Primary Contact Email"
        name="contact.email"
      />
      <Field<string>
        component={StyledDropdown}
        label="Primary Contact Language"
        loading={loading}
        name="contact.primaryLanguageId"
        options={languageOptions}
      />
      <Field<string>
        component={StyledTextField}
        label="Primary Contact Salutation"
        name="contact.salutation"
      />
    </div>
  )
}

const StyledTextField = styled(TextField)`
  &&& {
    margin: 24px;
  }
`

const StyledDropdown = styled(TritonDropdown)`
  &&& {
    margin: 24px;
  }
`

const StyledToggle = styled(Toggle)`
  &&& {
    margin: 24px;
  }
`

export default ContactInfo
