import { createApi } from '@reduxjs/toolkit/dist/query/react'
import axiosBaseQuery from './axiosBaseQuery'
import { Tags } from './types'

export const entityServiceApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: '/platform/entities',
    skipAccount: true
  }),
  reducerPath: 'entityService',
  tagTypes: [Tags.EntityDefinitions],
  endpoints: builder => ({
    getCurrentEntityDefinitionModels: builder.query<Record<string, unknown>, void>({
      providesTags: [Tags.EntityDefinitions],
      query: () => {
        return {
          method: 'get',
          url: `/definitions?types=location,pwsserviceline`
        }
      }
    })
  })
})

export const {
  useLazyGetCurrentEntityDefinitionModelsQuery
} = entityServiceApi

export default entityServiceApi
