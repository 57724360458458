import { Badge, LIGHTEST_GRAY, IGNIS_RED } from '@120wateraudit/waterworks'
import React from 'react'

import { SubmissionStatus } from 'src/types/Submission'

type Status = keyof typeof SubmissionStatus

export const getColorFromStatus = (status: Status): string => {
  switch (status) {
    case 'approved':
      return '#BBF7D0'
    case 'inReview':
      return '#FDE68A'
    case 'notSubmitted':
      return '#D3D3D3'
    case 'rejected':
      return '#FECACA'
    case 'submitted':
      return '#BFDBFE'
    case 'processingSubmission':
      return '#00B5E2'
    case 'voided':
      return '#ffc72c'
    case 'failed':
      return IGNIS_RED
    default:
      return LIGHTEST_GRAY
  }
}

interface StatusBadgeProps {
  status: Status
  style?: React.CSSProperties
}

export const SubmissionStatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  style
}) => {
  const statusLabel = SubmissionStatus[status]
  return (
    <Badge
      style={{ ...style, justifyContent: 'center' }}
      backgroundColor={getColorFromStatus(status)}>
      {statusLabel}
    </Badge>
  )
}
