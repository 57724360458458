import React from 'react'
import { Comment } from 'semantic-ui-react'
import { Badge, AQUA_BLUE, TERRA_GREEN } from '@120wateraudit/waterworks'

import { Activity } from 'src/types/SubmissionActivity'
import { formatDateTime } from 'src/utils/formatDate'
import { SubmissionStatusBadge } from '../SubmissionStatusBadge'

interface Props {
  activities: Activity[]
  activityFilter: string
}

const CommentSection: React.FC<Props> = ({ activities, activityFilter }) => {
  return (
    <Comment.Group
      style={{ maxWidth: '100%', overflowY: 'auto', height: '50vh' }}>
      {activities &&
        activities
          .filter(a =>
            activityFilter === 'all' ? true : a.type === activityFilter
          )
          .reverse()
          .map(activity => {
            return (
              <Comment key={activity.id}>
                <Comment.Content>
                  <Comment.Author style={{ fontSize: '14px' }}>
                    {activity.submittedByName}
                  </Comment.Author>
                  <Comment.Metadata style={{ marginLeft: '0px' }}>
                    <div style={{ color: 'black', fontSize: '14px' }}>
                      {formatDateTime(activity.timestamp)}
                    </div>
                    <div>
                      <Badge
                        color={'white'}
                        backgroundColor={
                          activity.type === 'notification'
                            ? AQUA_BLUE
                            : TERRA_GREEN
                        }>
                        {activity.type.charAt(0).toUpperCase() +
                          activity.type.slice(1)}
                      </Badge>
                    </div>
                    <div>
                      <Badge
                        color={'white'}
                        backgroundColor={
                          activity.originType === 'pws'
                            ? AQUA_BLUE
                            : TERRA_GREEN
                        }>
                        {activity.originName}
                      </Badge>
                    </div>
                    <div>
                      <SubmissionStatusBadge
                        style={{
                          color:
                            activity.submissionStatus === 'voided'
                              ? 'white'
                              : 'black'
                        }}
                        status={activity.submissionStatus}
                      />
                    </div>
                  </Comment.Metadata>
                  <Comment.Text>
                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                      {activity.comment}
                    </div>
                  </Comment.Text>
                </Comment.Content>
              </Comment>
            )
          })}
    </Comment.Group>
  )
}

export default CommentSection
