import React, { FC, useCallback, useState } from 'react'
import pluralize from 'pluralize'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import { Loader, spacing } from '@120wateraudit/envirio-components'

import { pwsClient } from 'src/apollo-clients'
import { ReactComponent as ProgramColorIcon } from 'src/assets/images/program-color.svg'
import { ModalWrapper } from 'src/modules/Samples/modals/CompleteSamplesModal/shared'
import {
  ButtonsWrapper,
  ErrorMessage,
  ModalContent,
  ModalHeading
} from '../../Modal/shared'
import { SERVICE_LINE_DELETE_MUTATION } from 'src/containers/ServiceLines/dataAccess/queries'
import {
  ServiceLineDeleteMutation,
  ServiceLineDeleteMutationVariables
} from 'src/containers/ServiceLines/dataAccess/types'
import { Asset } from 'src/types/Asset'
import { CenteringWrapper } from 'src/components/Layout'
import ModalButtonGrid from './ModalButtonGrid'
import ModalContentWrapper from './ModalContentWrapper'

const Title: FC<{ count: number, isCompleted: boolean }> = ({ count, isCompleted }) => (
  <>
    <ProgramColorIcon />
    <h5>{`${isCompleted ? 'Successfully deleted ' : 'Delete'} ${pluralize('Service Line', count)}`}</h5>
  </>
)

interface DeleteServiceLineProps {
  accountId: number
  assets: Asset[]
  onClose: () => void
  onCompletion: () => void
}

const DeleteServiceLineModal: FC<DeleteServiceLineProps> = ({
  accountId,
  assets,
  onCompletion,
  onClose
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] =
    useState<string | undefined>(undefined)
  const [completed, setCompleted] = useState(false)

  const [serviceLineDelete] = useMutation<
    ServiceLineDeleteMutation,
    ServiceLineDeleteMutationVariables
  >(SERVICE_LINE_DELETE_MUTATION, {
    client: pwsClient,
    onCompleted: () => {
      setCompleted(true)
      setLoading(false)
    },
    onError: ({ graphQLErrors }) => {
      setErrorMessage(graphQLErrors[0]?.message || "Error while deleting service line(s).")
      setLoading(false)
    }
  })

  const onDelete = useCallback(async () => {
    setLoading(true)
    await Promise.all(
      assets.map(async (asset) => {
        await serviceLineDelete({
          variables: {
            accountId: `${accountId}`,
            assetId: `${asset.id}`
          }
        })
      })
    )
    if (onCompletion) onCompletion()
  }, [accountId, assets, onCompletion, serviceLineDelete])

  const assetCount = assets.length

  return (
    <ModalWrapper>
      <ModalHeading>
        <Title count={assetCount} isCompleted={completed} />
      </ModalHeading>
      <React.Suspense fallback={<Loader />}>
        <ModalContentWrapper
          loading={loading}
          errorMessage={errorMessage}
          isCompleted={completed}
          onClose={onClose}
          modalContent={
            <>
              <ModalContent>
                <Body>
                  <CenteredParagraph>
                    Are you sure you want to delete{' '}
                    {assetCount > 1
                      ? 'these service lines'
                      : 'this service line'}
                    ? This action is immediate and cannot be reversed.
                  </CenteredParagraph>
                </Body>
              </ModalContent>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <ButtonsWrapper>
                <ModalButtonGrid
                  actionTitle="Delete"
                  buttonVariant="error"
                  loading={loading}
                  onSubmit={async () => {
                    await onDelete()
                  }}
                  onClose={onClose}
                />
              </ButtonsWrapper>
            </>
          }
        />
      </React.Suspense>
    </ModalWrapper>
  )
}

export default DeleteServiceLineModal

const CenteredParagraph = styled.p`
  text-align: center;
`
const Body = styled(CenteringWrapper)`
  margin-bottom: ${spacing.small};
`
