import {
  CustomFieldDefinition,
  retrieveDefinitions
} from "@120wateraudit/custom-fields-ui-components";
import { AWS_BASE} from "src/API";
import { getItem, LocalStorageItem} from "src/utils/localStorage";
import {PWSCustomFieldEntity} from "@120wateraudit/envirio-components/dist/models";
import {
  Tags,
  pwsApi
} from 'src/services'

export const customFieldsEndpoints = pwsApi.injectEndpoints({
  endpoints: (builder) => ({
    getDefinitions: builder.query<CustomFieldDefinition[], PWSCustomFieldEntity>({
      providesTags: [Tags.CustomFieldDefinitions],
      queryFn: async (arg, _queryApi, _extraOptions) => {
        try{
          const definitions = await retrieveDefinitions(AWS_BASE, getItem(LocalStorageItem.TOKEN) || '', arg)
          return { data: definitions }
        }
        catch(e) {
          return { error: { ...e } }
        }
    }
    }),
    getAllDefinitions: builder.query<CustomFieldDefinition[], void>({
      providesTags: [Tags.CustomFieldDefinitions],
      queryFn: async (_queryApi, _extraOptions) => {
        try{
          const definitions = await retrieveDefinitions(AWS_BASE, getItem(LocalStorageItem.TOKEN) || '')
          return { data: definitions }
        }
        catch(e) {
          return { error: { ...e } }
        }
      }
    })
  })
})

export const invalidateDefinitionCache = (dispatch): void => {
  dispatch(pwsApi.util?.invalidateTags([ { type: Tags.CustomFieldDefinitions, id: undefined }]))
}

export const {
  useGetDefinitionsQuery,
  useGetAllDefinitionsQuery
} = customFieldsEndpoints
