import { Button } from '@120wateraudit/envirio-components'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Field, Form as FinalForm, useField } from 'react-final-form'
import { Form, Loader } from 'semantic-ui-react'
import styled from 'styled-components'

import { ActivityRelationType } from '@120wateraudit/envirio-components/dist/models'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { isApiError } from 'src/API'
import { TritonError } from 'src/components/Error'
import DatePicker, { DATE_FORMAT } from 'src/components/Forms/Datepicker'
import { TritonDropdown } from 'src/components/Forms/Dropdown'
import { TritonTextField } from 'src/components/Forms/TextField'
import { useProgramsAndEvents } from 'src/hooks'
import { useGetContactsQuery } from 'src/modules/Contacts/dataAccess'
import {
  DrawerContents,
  Header as DrawerHeader
} from 'src/modules/Modal/Drawer'
import { Tags } from 'src/services'
import { formatFullName } from 'src/utils/formatFullName'
import { toastError, toastSuccess } from 'src/utils/toast'
import {
  Activity,
  generateOptions,
  invalidateActivitiesGQLTags,
  useActivityResolutionDetails,
  useActivityResolutions,
  useActivityTopics,
  useActivityTypes,
  useCreateActivityMutation
} from '../dataAccess'

type Values = Activity

const useInitialValues = (locationId: number): Values => {
  const types = useActivityTypes()
  const callType = types.find(t => t.name === 'Call')
  return useMemo(
    () => ({
      activityTypeId: callType?.id,
      date: moment().format(DATE_FORMAT),
      relationId: locationId,
      relationType: ActivityRelationType.Location
    }),
    [locationId, callType?.id]
  )
}

const useCreateActivity = (onClose: () => void) => {
  const dispatch = useDispatch()
  const [trigger, { error, isError, isLoading, isSuccess, isUninitialized }] =
    useCreateActivityMutation()
  useEffect(() => {
    if (!isUninitialized && !isLoading && isSuccess) {
      toastSuccess('Activity has been logged')
      onClose()
    }
  }, [isLoading, isSuccess, isUninitialized, onClose])

  const onSubmit = useCallback(
    async (values: Values) => {
      try {
        await trigger({
          ...values,
          date: moment(values.date).toISOString()
        }).unwrap()
        dispatch(invalidateActivitiesGQLTags([Tags.Activities]))
      } catch {
        toastError('An error occurred; the Activity could not be logged')
      }
    },
    [dispatch, trigger]
  )

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined
    }

    if (isApiError(error)) {
      const errors = error.data?.errors || []
      return errors.flatMap(e => Object.values(e.constraints))
    }

    return ['Please try again or contact support@120water.com']
  }, [error, isError])

  return useMemo(() => ({ errors, onSubmit }), [errors, onSubmit])
}

interface Props {
  locationId: number
  onClose(): void
}

const LogActivityDrawer = ({ locationId, onClose }: Props): JSX.Element => {
  const initialValues = useInitialValues(locationId)
  const { errors, onSubmit } = useCreateActivity(onClose)
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <Form onSubmit={handleSubmit}>
          <DrawerContents
            header={
              <Header onClose={onClose} submitting={submitting} valid={valid} />
            }>
            {errors && (
              <TritonError style={{ margin: '12px 24px' }} messages={errors} />
            )}
            <ActivityForm locationId={locationId} />
          </DrawerContents>
        </Form>
      )}
    />
  )
}

interface HeaderProps {
  submitting: boolean
  valid: boolean
  onClose(): void
}

const Header = ({ onClose, submitting, valid }: HeaderProps): JSX.Element => {
  return (
    <DrawerHeader title="Log Activity">
      <Button disabled={!valid || submitting} variant="primary">
        {submitting ? 'Saving…' : 'Save'}{' '}
        <Loader active={submitting} inline size="tiny" />
      </Button>
      <Button
        disabled={submitting}
        onClick={onClose}
        style={{ marginBottom: '2px' }}
        type="button"
        variant="error">
        <FontAwesomeIcon icon={faClose} />
      </Button>
    </DrawerHeader>
  )
}

const useActivityOptions = () => {
  const types = useActivityTypes()
  const resolutions = useActivityResolutions()
  const details = useActivityResolutionDetails()
  const topics = useActivityTopics()
  return useMemo(
    () => ({
      details: generateOptions(details),
      isLoading: [types, resolutions, topics, details].some(
        v => v.length === 0
      ),
      resolutions: generateOptions(resolutions),
      topics: generateOptions(topics),
      types: generateOptions(types)
    }),
    [types, resolutions, topics, details]
  )
}

const useProgramAndEventOptions = () => {
  const { events, loading, programs } = useProgramsAndEvents()
  const {
    input: { value: programId }
  } = useField('programId')
  const {
    input: { onChange: onEventChange }
  } = useField('eventId')
  useEffect(() => {
    if (!programId) {
      onEventChange(undefined)
    }
  }, [programId, onEventChange])

  return useMemo(
    () => ({
      disableEvents: !programId,
      events: events
        .filter(e => e.programId === programId)
        .map(e => ({ key: e.id, text: e.name, value: e.id })),
      isLoading: loading,
      programs: programs.map(p => ({ key: p.id, text: p.name, value: p.id }))
    }),
    [events, loading, programs, programId]
  )
}

const useContactOptions = (locationId: number) => {
  const { data, isLoading } = useGetContactsQuery(locationId)
  return useMemo(
    () => ({
      contacts: data.map(c => ({
        key: c.id,
        text: c.firstName
          ? formatFullName(c.firstName, c.lastName, c.salutation)
          : c.email,
        value: c.id
      })),
      isLoading
    }),
    [data, isLoading]
  )
}

const ActivityForm = ({ locationId }: { locationId: number }): JSX.Element => {
  const {
    details,
    isLoading: isLoadingOptions,
    resolutions,
    topics,
    types
  } = useActivityOptions()

  const {
    disableEvents,
    events,
    isLoading: isLoadingPrograms,
    programs
  } = useProgramAndEventOptions()

  const { contacts, isLoading: isLoadingContacts } =
    useContactOptions(locationId)

  return (
    <div>
      <Field<string>
        component={StyledDropdown}
        label="Activity*"
        loading={isLoadingOptions}
        name="activityTypeId"
        options={types}
      />
      <Field<string>
        component={StyledDatePicker}
        label="Activity Date"
        name="date"
      />
      <Field<string>
        component={StyledDropdown}
        label="Reason*"
        loading={isLoadingOptions}
        name="topicId"
        options={topics}
      />
      <Field<string>
        component={StyledDropdown}
        label="Outcome*"
        loading={isLoadingOptions}
        name="resolutionId"
        options={resolutions}
      />
      <Field<string>
        clearable
        component={StyledDropdown}
        label="Outcome Detail"
        loading={isLoadingOptions}
        name="resolutionDetailId"
        options={details}
      />
      <Field<string>
        component={StyledTextField}
        fluid
        label="Description"
        name="description"
        placeholder="Add some details"
        textarea
      />
      <Field<string>
        clearable
        component={StyledDropdown}
        label="Program"
        loading={isLoadingPrograms}
        name="programId"
        options={programs}
      />
      <Field<string>
        clearable
        component={StyledDropdown}
        disabled={disableEvents}
        label="Event"
        loading={isLoadingPrograms}
        name="eventId"
        options={events}
      />
      <Field<string>
        clearable
        component={StyledDropdown}
        label="Contact"
        loading={isLoadingContacts}
        name="contactId"
        options={contacts}
      />
    </div>
  )
}

const StyledTextField = styled(TritonTextField)`
  &&& {
    margin: 24px;
  }
`

const StyledDropdown = styled(TritonDropdown)`
  &&& {
    margin: 24px;
  }
`

const StyledDatePicker = styled(DatePicker)`
  &&& {
    margin: 24px;
  }
`

export default LogActivityDrawer
