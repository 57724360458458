import GenerateCrudSaga from './helpers/CrudSaga'
import actions from '../actions/leadServiceLines'
import {
  fetchLeadServiceLines,
  fetchLeadServiceLine,
  createLeadServiceLine,
  updateLeadServiceLine,
  deleteLeadServiceLine
} from '../API'

const saga = GenerateCrudSaga({
  entityName: 'leadServiceLine',
  actions,
  fetchCollectionMethod: fetchLeadServiceLines,
  fetchEntityMethod: fetchLeadServiceLine,
  createEntityMethod: createLeadServiceLine,
  updateEntityMethod: updateLeadServiceLine,
  deleteEntityMethod: deleteLeadServiceLine
})

export default saga
