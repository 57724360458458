import {
  Analyte,
  PWSKit,
  PWSLab,
  PWSLocation,
  PWSProgram,
  PWSSampleResult,
  PWSSamplingEvent
} from '@120wateraudit/envirio-components/dist/models'

// This is the old status enum
export enum PWSSampleStatus {
  New = 1,
  Analyzed = 3,
  Completed = 4,
  Invalidated = 5
}

// This is the new status enum.
export enum SampleStatus {
  'New' = 'New',
  'At Lab' = 'At Lab',
  'Analyzed' = 'Analyzed',
  'Collected' = 'Collected',
  'Completed' = 'Completed',
  'Invalidated' = 'Invalidated'
}

export type SampleType = 'PWS' | 'WW'

export interface StatusReason {
  id: number
  reason: string
  sampleType: SampleType
}

// TODO make the table component take a generic type to avoid this extends call
export interface PWSSample extends Record<string, unknown> {
  // audit fields
  createdBy: string
  createdOn: string // might be a date
  id: number
  lastModifiedBy: string
  lastModifiedOn: string // also might be a date

  // status
  newStatus: SampleStatus
  status: PWSSampleStatus

  // attributes
  analyzedOn?: string // date
  arrivedAtLabOn?: string // date?
  collectedOn: string // date?
  completedOn?: string // date
  customerRequestedOn: string // date?
  drawOrder?: number
  externalId?: string
  extractedOn?: string // date
  extractionMethod?: string
  fixtureType?: number
  fulfilledOn: string // date?
  invalidatedOn?: string // date
  labResultsUrl?: string
  matrix?: string
  orderedOn?: string // date
  preparedOn?: string // date
  pwsRequestedOn?: string // date?
  sampleLocation?: string
  statusReason?: string

  // relationships
  kit?: PWSKit
  kitId?: number
  lab?: PWSLab
  labId?: number
  labSampleId?: string
  location: PWSLocation
  locationId: number
  parentId?: number
  program: PWSProgram
  programId: number
  results?: PWSSampleResult[]
  samplingEvent: Omit<PWSSamplingEvent, 'primaryAnalyte'> & {
    primaryAnalyte?: Analyte
  }
  samplingEventId: number
}
