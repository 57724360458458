import filesize from 'filesize'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { Label } from 'semantic-ui-react'
import { DataLink } from 'src/components/Layout'
import {AQUA_BLUE, DARK_BLUE, TERRA_GREEN} from 'src/constants/colors'
import { Attachment } from 'src/types/Attachment'
import { getFileExtension, removeFileExtension } from 'src/utils/fileName'
import { ModalContext } from '../../Modal'
import { useAttachmentDownload } from '../dataAccess'

export const DownloadLinkCell = ({
  id,
  originalFileName
}: Attachment): JSX.Element => {
  const { download } = useAttachmentDownload(id)
  return (
    <DataLink href="#" onClick={download} style={{ wordBreak: 'break-all' }}>
      <span>
        {originalFileName ? removeFileExtension(originalFileName) : '--'}
      </span>
    </DataLink>
  )
}

export const FileExtensionCell = ({ fileName }: Attachment): JSX.Element => {
  return (
    <Flex>
      <Text>{fileName ? getFileExtension(fileName) : '--'}</Text>
    </Flex>
  )
}

export const FileSizeCell = ({ fileSize: size }: Attachment): JSX.Element => {
  return (
    <Flex>
      <Text>{size ? filesize(size, 1) : '--'}</Text>
    </Flex>
  )
}

export const AssociationsCell = (attachment: Attachment): JSX.Element => {
  const { locations, samples, assets } = attachment
  const { openModal } = useContext(ModalContext)
  const openAttachmentDetailDrawer = () => {
    openModal('viewAttachment', { attachment }, 'drawer')
  }
  return (
    <LabelWrapper onClick={openAttachmentDetailDrawer}>
      {locations.length > 0 && (
        <StyledLabel background={TERRA_GREEN} color="white">
          Locations
          <Label.Detail>{locations.length}</Label.Detail>
        </StyledLabel>
      )}
      {samples.length > 0 && (
        <StyledLabel background={AQUA_BLUE} color="white">
          Samples
          <Label.Detail>{samples.length}</Label.Detail>
        </StyledLabel>
      )}
      {assets.length > 0 && (
          <StyledLabel background={DARK_BLUE} color="white">
            Assets
            <Label.Detail>{assets.length}</Label.Detail>
          </StyledLabel>
      )}
    </LabelWrapper>
  )
}

const Flex = styled.div`
  display: flex;
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StyledLabel = styled(Label)`
  &&& {
    display: flex;
    justify-content: space-between;
    margin: 0 !important;
    font-size: 10px;
    ${props => props.color && `color: ${props.color};`}
    ${props => props.background && `background: ${props.background};`}
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`

export const Text = styled.p`
  max-width: 250px;
  overflow-wrap: break-word;
`
