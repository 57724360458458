import { PWSSamplingEventStatus } from '@120wateraudit/envirio-components/dist/models'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'

import { AWS_BASE, post } from 'src/API'
import { createClient } from 'src/apollo-clients'
import { FilterType } from 'src/components/Table/types'
import { SearchParams, useAction, useData } from 'src/hooks'
import { useAccount } from 'src/router/UserProvider'
import { PWSSample, SampleStatus } from 'src/types/Sample'

const pwsClient = createClient({
  uri: `${AWS_BASE}/pws/graphql`
})

const QUERY_STATUS_REASONS = gql`
  query sampleStatusReasons($status: String, $sampleType: String) {
    sampleStatusReasons(status: $status, sampleType: $sampleType) {
      id
      createdOn
      createdBy
      lastModifiedBy
      lastModifiedOn
      reason
      sampleType
    }
  }
`

export const useStatusReasons = (
  status?: SampleStatus,
  sampleType?: 'PWS' | 'WW'
) => {
  const { id } = useAccount()

  return useQuery(QUERY_STATUS_REASONS, {
    client: pwsClient,
    variables: { accountId: id, sampleType, status }
  })
}

export const useMoveSamplesAction = (
  samples: PWSSample[]
): {
  error?: Error
  isWorking: boolean
  clear(): void
  moveSamples(
    newEventId: number,
    reason: string,
    other?: string
  ): Promise<unknown>
} => {
  const sampleIds = useMemo(() => samples.map(s => s.id), [samples])
  const { id } = useAccount()
  const { clear, error, isWorking, takeAction } = useAction(
    async (newEventId: number, reason: string, other?: string) =>
      await post(`/pws/rest/accounts/${id}/samples/move`, {
        otherReasonValue: other,
        reasonForMove: reason,
        sampleIds,
        samplingEventId: newEventId
      })
  )
  return { clear, error, isWorking, moveSamples: takeAction }
}

export const useCompleteSamplesAction = (
  samples: PWSSample[]
): {
  error?: Error
  isWorking: boolean
  clear(): void
  completeSamples(): Promise<unknown>
} => {
  const sampleIds = useMemo(() => samples.map(s => s.id), [samples])
  const { id } = useAccount()
  const { clear, error, isWorking, takeAction } = useAction(
    async () =>
      await post(`/pws/rest/accounts/${id}/samples/complete`, { sampleIds })
  )
  return { clear, completeSamples: takeAction, error, isWorking }
}

export const useInvalidateSamplesAction = (
  samples: PWSSample[]
): {
  error?: Error
  isWorking: boolean
  clear(): void
  invalidateSamples(
    reason: string,
    resend: boolean,
    notes?: string
  ): Promise<unknown>
} => {
  const { id } = useAccount()
  const { clear, error, isWorking, takeAction } = useAction(
    async (reason: string, resend: boolean, notes?: string) => {
      return await Promise.all(
        samples.map(s =>
          post(`/pws/rest/accounts/${id}/samples/${s.id}/invalidate`, {
            notes,
            resend,
            statusReason: reason
          })
        )
      )
    }
  )
  return { clear, error, invalidateSamples: takeAction, isWorking }
}

const handleOldParams = (params: SearchParams): SearchParams => {
  const clone = { ...params }
  if (typeof clone.sort === 'object') {
    const direction = clone.sort.direction === 'DESC' ? '-' : ''
    clone.sort = `${direction}${clone.sort.key}`
  }
  return clone
}

export const useSamples = (
  params: SearchParams,
  defaultParams?: Partial<SearchParams>
) => {
  const { id } = useAccount()
  const newParams = useMemo(() => handleOldParams(params), [params])
  const {
    data: samples,
    exportData: exportSamples,
    loading,
    refetch,
    total: totalSamples
  } = useData<PWSSample>(`/pws/accounts/${id}/samples`, newParams, {
    defaultParams,
    exportUrl: `/file-processor/export/samples`,
    useNewExport: true,
    useNewFilters: true
  })
  return { exportSamples, loading, refetch, samples, totalSamples }
}

export const samplingEventStatusFilter = {
  defaultValue: 'All',
  key: 'samplingEventStatus',
  label: 'Sampling Event Status',
  options: [
    { text: 'All', value: 'All' },
    { text: 'Active', value: PWSSamplingEventStatus.Active },
    { text: 'Inactive', value: PWSSamplingEventStatus.Inactive }
  ],
  type: FilterType.ListSelection
}
