import React, { CSSProperties, FC } from 'react'
import { TritonTextField } from 'src/components/Forms/TextField'
import styled from 'styled-components'
import { Icon, DropdownProps } from 'semantic-ui-react'
import { Dropdown as EnvirioDropdown } from '@120wateraudit/envirio-components'
import { FieldRenderProps } from 'react-final-form'

export const FlexDiv = styled.div`
  display: flex;
  gap: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  align-items: center;
  max-width: 400px;
`

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  p,
  span,
  label,
  div {
    font-size: 14px;
    color: #333;
    font-weight: normal;
    opacity: inherit;
  }
`

export const BasicContainer = styled.div`
  p,
  span,
  label,
  div {
    font-size: 14px;
    color: #333;
    font-weight: normal;
    opacity: inherit;
  }
`

export const QuestionTitle = styled.p`
  text-decoration: underline;
`

export const DoubleInputGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  & > h3 {
    grid-column-start: span 2;
  }
  & > div.field {
    margin: 0 !important;
  }
`

export const ParagraphInputGrid = styled.section`
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  gap: 16px;
  & > h3 {
    grid-column-start: span 2;
  }
  & > div.field {
    margin: 0 !important;
  }
`

export const BoldSpan = styled.span`
  &&& {
    font-weight: bold;
  }
`

export const NoRedHighlightTextField = styled(TritonTextField)`
  div {
    border: 2px solid rgba(0, 0, 0, 0.25);
  }
  div:focus-within {
    border: 2px solid rgba(0, 181, 225, 1);
  }
`

export const SuperAsterisk = (): JSX.Element => {
  return (
    <sup>
      <Icon
        color="red"
        name="asterisk"
        style={{ fontSize: '6px', marginLeft: '1px' }}
      />
    </sup>
  )
}

export type Props = DropdownProps &
  FieldRenderProps<string, HTMLElement> & {
    className?: string
    label?: string
    style?: CSSProperties
  }

export const SimpleDropdown: FC<Props> = ({
  input,
  meta: { error, pristine, touched },
  ...rest
}) => {
  const onChange: DropdownProps['onChange'] = (_event, { value }) => {
    input.onChange(value)
    input.onBlur()
  }

  return (
    <NoErrorDropdown
      {...input}
      error={!pristine && touched && !!error}
      onChange={onChange}
      {...rest}
    />
  )
}

const NoErrorDropdown = styled(EnvirioDropdown)`
  &&& {
    & > div {
      display: flex;
      justify-content: space-between;
    }
    .ui.search.dropdown div.text {
      font-size: 13px;
    }

    .ui.dropdown {
      border-color: rgba(0, 0, 0, 0.25) !important;
    }
  }
`
