import React, { FC, useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Form } from 'semantic-ui-react'
import uniqBy from 'lodash/uniqBy'
import pluralize from 'pluralize'
import styled from 'styled-components'
import { Dropdown, Loader, spacing } from '@120wateraudit/envirio-components'

import {
  ButtonsWrapper,
  ModalContent,
  ModalHeading
} from '../../Modal/shared'
import { ModalWrapper } from 'src/modules/Samples/modals/CompleteSamplesModal/shared'
import { pwsClient } from 'src/apollo-clients'
import { PROFILE_UPDATE_MUTATION } from 'src/containers/ServiceLines/dataAccess/queries'
import {
  ProfileUpdateMutation,
  ProfileUpdateMutationVariables
} from 'src/containers/ServiceLines/dataAccess/types'
import { Asset } from 'src/types/Asset'
import { CenteringWrapper } from 'src/components/Layout'
import ModalButtonGrid from './ModalButtonGrid'
import ModalContentWrapper from './ModalContentWrapper'

interface ChangeTierProps {
  accountId: number
  assets: Asset[]
  onClose: () => void
  onCompletion: () => void
  setErrorMessage: (error: string) => void
}

const ChangeTierModal: FC<ChangeTierProps> = ({
  accountId,
  assets,
  onClose,
  onCompletion
}) => {
  const [tier, setTier] = useState(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] =
    useState<string | undefined>(undefined)
  const [completed, setCompleted] = useState(false)

  const [updateTier] = useMutation<
    ProfileUpdateMutation,
    ProfileUpdateMutationVariables
  >(PROFILE_UPDATE_MUTATION, {
    client: pwsClient,
    onCompleted: () => {
      setCompleted(true)
      setLoading(false)
    },
    onError: ({ graphQLErrors }) => {
      setErrorMessage(graphQLErrors[0]?.message || "Error while changing tier.")
      setLoading(false)
    }
  })

  const onSubmit = useCallback(
    async (tier: number) => {
      setLoading(true)
      await Promise.all(
        uniqBy(assets, 'location.id').map(async asset => {
          const profile = { ...(asset.location.profile || {}), tier }
          await updateTier({
            variables: {
              accountId: `${accountId}`,
              locationId: asset.location.id,
              profile: { tier: profile.tier }
            }
          })
        })
      )
      if (onCompletion) onCompletion()
    },
    [accountId, assets, onCompletion, updateTier]
  )

  const handleSubmit = async () => {
    await onSubmit(tier)
  }

  type TierOption = {
    key: string
    text: string
    value: number
  }

  const tierOptions: TierOption[] = [
    { key: 'one', text: 'Tier 1', value: 1 },
    { key: 'two', text: 'Tier 2', value: 2 },
    { key: 'three', text: 'Tier 3', value: 3 },
    { key: 'four', text: 'Tier 4', value: 4 },
    { key: 'five', text: 'Tier 5', value: 5 }
  ]

  const serviceLineCountText = `${pluralize('Service Line Location', assets.length, true)} Tier`

  return (
    <ModalWrapper>
      <ModalHeading>
        <h5>
          {`${completed ? 'Successfully changed' : 'Change'} ${serviceLineCountText}`}
        </h5>
      </ModalHeading>
      <React.Suspense fallback={<Loader />}>
        <ModalContentWrapper
          loading={loading}
          errorMessage={errorMessage}
          isCompleted={completed}
          onClose={onClose}
          modalContent={
            <>
              <ModalContent>
                <Body>
                  <Form.Field>
                    <Dropdown
                      addUndefinedOption={false}
                      fluid
                      label="Tier"
                      onChange={(_e, option) =>
                        setTier(parseInt(option.value, 10))
                      }
                      options={tierOptions}
                      value={tier}
                    />
                  </Form.Field>
                </Body>
              </ModalContent>
              <ButtonsWrapper>
                <ModalButtonGrid
                  actionTitle="Change Tier"
                  loading={loading}
                  onSubmit={handleSubmit}
                  onClose={onClose}
                />
              </ButtonsWrapper>
            </>
          }
        />
      </React.Suspense>
    </ModalWrapper>
  )
}

const Body = styled(CenteringWrapper)`
  margin-bottom: ${spacing.small};
`

export default React.memo(ChangeTierModal)
