import * as React from 'react'
import styled from 'styled-components'
import { colors, spacing, fontSize } from '@120wateraudit/envirio-components'
import { SendOption } from '.'
import { Error } from 'src/components/Error'
import { Flags, useConfig } from 'src/hooks'

interface Props {
  sendOption: SendOption
  numberOfLetters: number
  error?: any
}

export const ConfirmSend = ({ sendOption, numberOfLetters, error }: Props) => {
  const isCommsTwoEnabled = useConfig(Flags.CommsTwoEnabled)

  return (
    <Wrapper>
      <CountLabel>
        {getText(sendOption, numberOfLetters, isCommsTwoEnabled)}
      </CountLabel>
      {error && <Error error={error} />}
    </Wrapper>
  )
}

const getText = (
  sendOption: SendOption,
  numberOfLetters: number,
  isCommsTwoEnabled: boolean
) => {
  switch (sendOption) {
    case SendOption.USPS:
      return (
        <>
          You are about to send <b>{numberOfLetters} Results Letters</b> via
          USPS.
        </>
      )
    case SendOption.PDF:
      return (
        <PDFConfirmation>
          {isCommsTwoEnabled ? (
            <>Click below to start processing your letters</>
          ) : (
            <>
              You have <b>{numberOfLetters} Results Letters</b> ready to
              download.
            </>
          )}
        </PDFConfirmation>
      )
    default:
      return
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const CountLabel = styled.div`
  color: ${colors.black50};
  padding-bottom: ${spacing.tiny};
  font-size: ${fontSize.body};
`

const PDFConfirmation = styled.div`
  text-align: center;
`
