import React, { useContext } from 'react'
import styled from 'styled-components'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button } from '@120wateraudit/envirio-components'

import { TERRA_GREEN } from 'src/constants/colors'
import Row from 'src/components/Row'
import { useFeatures } from 'src/hooks'
import { ModalContext } from 'src/modules/Modal'

interface Props {
  onClose(): void
}

const ApprovedSubmissionModal = ({ onClose }: Props): JSX.Element => {
  const { accountHasCommsAutomatedMailingFeature } = useFeatures()
  const { openModal } = useContext(ModalContext)
  const openCreateLetterModal = () => {
    openModal('createLetterTemplate', {})
  }

  if (!accountHasCommsAutomatedMailingFeature) {
    return (
      <Container>
        <Row style={{ justifyContent: 'center' }}>
          <FontAwesomeIcon
            size="6x"
            icon={faCheckCircle}
            style={{
              marginRight: '4px',
              color: TERRA_GREEN
            }}
          />
        </Row>

        <h3
          style={{
            textAlign: 'center',
            marginTop: '2.5rem',
            fontSize: '3.5rem'
          }}>
          Submission Approved
        </h3>
        <HeaderText style={{ marginTop: '3rem' }}>
          Your submission has already been approved in the current <br />
          submission period. Your work here is done! &#x1F389;
        </HeaderText>
        <Row style={{ justifyContent: 'center', gap: '2rem' }}>
          <Button onClick={onClose} type="button">
            Close
          </Button>
          <Link to={'/state-reporting/submissions'}>
            <Button variant="primary" onClick={onClose} type="button">
              View Submissions
            </Button>
          </Link>
        </Row>
      </Container>
    )
  }

  return (
    <Container>
      <Row style={{ justifyContent: 'center' }}>
        <FontAwesomeIcon
          size="6x"
          icon={faCheckCircle}
          style={{ marginRight: '4px', color: TERRA_GREEN }}
        />
      </Row>

      <h3
        style={{
          textAlign: 'center',
          marginTop: '2.5rem',
          fontSize: '3.5rem'
        }}>
        Submission Approved
      </h3>

      <HeaderText
        style={{
          marginTop: '3rem',
          marginBottom: '10px'
        }}>
        Your submission has already been approved in the current <br />
        submission period. &#x1F389;
      </HeaderText>
      <HeaderText style={{ marginTop: '0px' }}>
        Continue your Compliance Journey by creating an Inventory Letter!
      </HeaderText>
      <Row style={{ justifyContent: 'center', gap: '2rem' }}>
        <Button onClick={onClose} type="button">
          Close
        </Button>
        <Link to={'/state-reporting/submissions'}>
          <Button variant="primary" onClick={onClose} type="button">
            View Submissions
          </Button>
        </Link>
        <Button onClick={openCreateLetterModal} type="button" variant="primary">
          Create Letter
        </Button>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  padding: 36px 40px;
  line-height: 1.75;
`
const HeaderText = styled.h6`
  text-align: center;
  font-size: 18px;
`

export default ApprovedSubmissionModal
