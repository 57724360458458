import { Button } from '@120wateraudit/envirio-components'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useContext } from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Form, Loader } from 'semantic-ui-react'

import { Row } from 'src/components/Layout'
import { useServiceLineCreateMutation } from 'src/containers/ServiceLines/dataAccess'
import { ModalContext } from 'src/modules/Modal'
import { DrawerContents } from 'src/modules/Modal/Drawer'
import { useAccount } from 'src/router/UserProvider'
import { toastSuccess } from 'src/utils/toast'
import { Values, useInitialValues, validate } from '../dataAccess'
import AssetDrawerForm from './AssetForm'

interface Props {
  className?: string
  onClose(): void
  refetch(): void
}

const AddServiceLineModal = ({
  className,
  onClose,
  refetch
}: Props): JSX.Element => {
  const { openModal } = useContext(ModalContext)
  const accountId = `${useAccount().id}`
  const initialValues = useInitialValues()

  const [serviceLineCreate] = useServiceLineCreateMutation()

  const onSubmitCreate = async (values: Values) => {
    let publicDiameterOverTwoInches = values.publicDiameterOverTwoInches
    let privateDiameterOverTwoInches = values.privateDiameterOverTwoInches
    if (publicDiameterOverTwoInches === 'Unknown') {
      publicDiameterOverTwoInches = null
    }
    if (privateDiameterOverTwoInches === 'Unknown') {
      privateDiameterOverTwoInches = null
    }

    const lineResponse = await serviceLineCreate({
      variables: {
        accountId: `${accountId}`,
        asset: {
          description: values.description,
          externalId: values.externalId,
          locationId: `${values.locationId}`
        },
        serviceLine: {
          fittingVerifiedBy: values.fittingVerifiedBy,
          fittingsVerificationMethod: values.fittingsVerificationMethod,
          fittingsVerifiedDate: values.fittingsVerifiedDate
            ? moment(values.fittingsVerifiedDate).toISOString()
            : null,
          leadFittings: values.leadFittings,
          leadSolder: values.leadSolder,
          otherLeadEquipment: values.otherLeadEquipment,
          privateInstalledDate: values.privateInstalledDate
            ? moment(values.privateInstalledDate).toISOString()
            : null,
          privateLineDepth: parseInt(values.privateLineDepth),
          privateLineDiameter: values.privateLineDiameter,
          privateDiameterOverTwoInches,
          privateMaterial: values.privateMaterial,
          privateLeadSolderLeadLined: values.privateLeadSolderLeadLined,
          privateRemovalDate: values.privateRemovalDate
            ? moment(values.privateRemovalDate).toISOString()
            : null,
          privateVerificationMethod: values.privateVerificationMethod,
          privateVerified: !!values.privateVerifiedDate,
          privateVerifiedBy: values.privateVerifiedBy,
          privateVerifiedDate: values.privateVerifiedDate
            ? moment(values.privateVerifiedDate).toISOString()
            : null,
          privateNotes: values.privateNotes,
          publicClassificationBasis: values.publicClassificationBasis,
          publicInstalledDate: values.publicInstalledDate
            ? moment(values.publicInstalledDate).toISOString()
            : null,
          publicLineDepth: parseInt(values.publicLineDepth, 10),
          publicLineDiameter: values.publicLineDiameter,
          publicDiameterOverTwoInches,
          publicMaterial: values.publicMaterial,
          publicLeadSolderLeadLined: values.publicLeadSolderLeadLined,
          publicNotes: values.publicNotes,
          publicPreviouslyLead: values.publicPreviouslyLead,
          publicRemovalDate: values.publicRemovalDate
            ? moment(values.publicRemovalDate).toISOString()
            : null,
          publicVerificationMethod: values.publicVerificationMethod,
          publicVerified: !!values.publicVerifiedDate,
          publicVerifiedBy: values.publicVerifiedBy,
          publicVerifiedDate: values.publicVerifiedDate
            ? moment(values.publicVerifiedDate).toISOString()
            : null
        }
      }
    })

    toastSuccess('Asset has been created')
    refetch()

    openModal(
      'viewAsset',
      { asset: lineResponse?.data?.serviceLineCreate, refetch },
      'drawer'
    )
  }

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmitCreate}
      render={({ handleSubmit, submitting, valid }) => (
        <Form onSubmit={handleSubmit}>
          <DrawerContents
            header={
              <Header submitting={submitting} valid={valid} onClose={onClose} />
            }>
            <AssetDrawerForm type="create" className={className} />
          </DrawerContents>
        </Form>
      )}
      validate={validate}
    />
  )
}

const Header = ({
  onClose,
  submitting,
  valid
}: {
  submitting: boolean
  valid: boolean
  onClose(): void
}): JSX.Element => {
  return (
    <>
      <div>
        <h3>Create Service Line</h3>
      </div>
      <Row>
        <Button
          disabled={!valid || submitting}
          style={{ marginRight: '7px' }}
          variant="primary">
          <span style={{ display: 'flex' }}>
            {submitting ? 'Saving…' : 'Save'}{' '}
            <Loader
              active={submitting}
              inline
              size="tiny"
              style={{ marginLeft: '3px' }}
            />
          </span>
        </Button>
        <Button
          disabled={submitting}
          onClick={onClose}
          type="button"
          variant="error">
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </Row>
    </>
  )
}

export default AddServiceLineModal
