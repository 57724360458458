import React, { FC } from 'react'
import {
  faFileCirclePlus,
  faMapLocationDot,
  IconDefinition,
  faAddressCard,
  faDroplet
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../ModalContents'
import { ModalButton } from '../shared'
import {
  CommunicationDataInterface,
  ContactExampleData,
  LocationData,
  SampleData
} from 'src/containers/Communications/Editor/types'

import {
  LocationDataModelContent,
  ContactDataModalContent,
  SampleDataModalContent
} from './DataTypeModalContents'
import { SamplingEventWithAnalytes } from 'src/components/Communications/CreateOrUpdateCommunication/OptionsCard'

interface Props {
  data: CommunicationDataInterface
  samplingEventData: SamplingEventWithAnalytes[]
  eventId: number
  type: string
  handleClick: (msg: string) => void
  onClose: () => void
  triggerType?: string
}

const parseEntityData = (
  data: CommunicationDataInterface,
  type: string
): SampleData | ContactExampleData | LocationData => {
  if (type === 'Contact') {
    return JSON.parse(data.example) as ContactExampleData
  } else if (type === 'Location') {
    return JSON.parse(data.example) as LocationData
  } else {
    return JSON.parse(data.example) as SampleData
  }
}

const renderIcon = (type: string): IconDefinition => {
  switch (type) {
    case 'Location':
      return faMapLocationDot
    case 'Contact':
      return faAddressCard
    case 'Sample':
      return faDroplet
    default:
      return faFileCirclePlus
  }
}

const AddDataToEditorModal: FC<Props> = ({
  samplingEventData,
  eventId,
  type,
  onClose,
  data,
  handleClick,
  triggerType
}) => {
  const renderModalType = (
    data: SampleData | ContactExampleData | LocationData,
    samplingEventData: SamplingEventWithAnalytes[],
    type: string
  ) => {
    switch (type) {
      case 'Location':
        return (
          <LocationDataModelContent
            data={data as LocationData}
            onClick={p => {
              handleClick(p)
              onClose()
            }}
            triggerType={triggerType}
          />
        )
      case 'Contact':
        return (
          <ContactDataModalContent
            data={data as ContactExampleData}
            onClick={p => {
              handleClick(p)
              onClose()
            }}
          />
        )
      case 'Sample':
        return (
          <SampleDataModalContent
            data={data as SampleData}
            samplingEventData={samplingEventData}
            eventId={eventId}
            onClick={p => {
              handleClick(p)
              onClose()
            }}
          />
        )
      default:
        return
    }
  }

  return (
    <Modal
      primaryAction={
        <ModalButton fontSize={'1.167rem'} onClick={onClose} variant="primary">
          Close
        </ModalButton>
      }
      icon={renderIcon(type)}
      title={`Add ${type} Data`}>
      {renderModalType(parseEntityData(data, type), samplingEventData, type)}
    </Modal>
  )
}

export default AddDataToEditorModal
