type ENV = 'development' | 'production' | 'staging'

const DEV_HOST = 'pws-dev.120wateraudit.com'
const STAGING_HOST = 'pws-staging.120wateraudit.com'

export const getEnvironment = (): ENV => {
  const hostname = window.location.hostname
  const isDev = hostname === 'localhost' || hostname === DEV_HOST
  const isStage = hostname === STAGING_HOST

  if (isDev) {
    return 'development'
  }

  if (isStage) {
    return 'staging'
  }

  return 'production'
}
