import { useEffect, useState } from 'react'
import { get, getDefaultHeaders } from 'src/API'

interface UseCreatePDFResourcesArgs {
  archiveUrl?: string
  htmlString?: string
  additionalHeaders?: { [key: string]: any }
}

export const useCreatePDFResources = ({
  archiveUrl,
  htmlString,
  additionalHeaders
}: UseCreatePDFResourcesArgs) => {
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState<{
    blob: undefined | Blob
    url: undefined | string
  }>({
    blob: undefined,
    url: undefined
  })

  useEffect(() => {
    const createResources = async () => {
      let blob

      if (archiveUrl) {
        blob = await getDocumentAsBlob(archiveUrl, additionalHeaders)
      }

      if (htmlString) {
        blob = blobPdfFromBase64String(htmlString)
      }

      setState({
        blob,
        url: URL.createObjectURL(blob)
      })

      setLoading(false)
    }

    createResources()
  }, [htmlString, archiveUrl])

  return { loading, ...state } as { blob: Blob; loading: boolean; url: string }
}

const blobPdfFromBase64String = (data: string) => {
  const byteArray = Uint8Array.from(
    atob(data)
      .split('')
      .map(char => char.charCodeAt(0))
  )

  return new Blob([byteArray], { type: 'application/pdf' })
}

const getDocumentAsBlob = async (
  url: string,
  additionalHeaders?: { [key: string]: any }
) => {
  try {
    const response = await get(url, {
      headers: {
        ...getDefaultHeaders().headers,
        Accept: 'application/octet-stream',
        ...additionalHeaders
      },
      responseType: 'blob'
    })

    const documentBlob: Blob = response || new Blob()
    return documentBlob.slice(0, documentBlob.size, 'application/pdf')
  } catch (error) {
    return undefined
  }
}
