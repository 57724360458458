import gql from 'graphql-tag'
import { CommunicationType } from '@120wateraudit/communications-types'
import { Program } from '@120wateraudit/envirio-components/dist/models'

export const GET_COMPLIANCE_NOTIFICATION_CENTER_DATA = gql`
  query getComplianceNotificationCenterData(
    $accountId: Int!
    $programId: Int!
    $eventId: Int!
  ) {
    getComplianceNotificationCenterData(
      accountId: $accountId
      programId: $programId
      eventId: $eventId
    ) {
      turnaroundTime
      totalCompletedKits
      totalResultsReady
      communicationData {
        totalSent
        dataSet {
          label
          dataSize
        }
      }
      detectionCounts {
        exceedance
        detect
        nonDetect
      }
    }
  }
`

export const ACTIVE_PROGRAMS = gql`
  query activePrograms($accountId: Int!) {
    activePrograms(accountId: $accountId) {
      id
      name
      samplingEvents {
        id
        name
        startsOn
        totalSamplesGoal
      }
    }
  }
`

export const GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY = gql`
  query accountSoftwarePackage($accountId: Int!) {
    accountSoftwarePackageName(accountId: $accountId)
  }
`

export const GET_PACKAGE_FOR_ACCOUNT_QUERY = gql`
  query accountSoftwarePackage($accountId: Int!) {
    accountSoftwarePackage(accountId: $accountId) {
      packageId
      packageName
    }
  }
`
export interface AccountSoftwarePackage {
  accountSoftwarePackageName: string
}

export const GET_ACCOUNT_LETTERS_TOTALS_QUERY = gql`
  query accountLettersTotals($accountId: Int!) {
    accountLettersTotals(accountId: $accountId) {
      totalLettersSentBy120
      totalLettersDownloadedToPrint
      totalLettersSent
    }
  }
`

export const ACCOUNT_HAS_FEATURES_QUERY = gql`
  query accountHasFeatures($accountId: Int!) {
    accountHasAlmFeature: accountHasFeature(
      accountId: $accountId
      code: "COMM:ALM"
    )
    accountHasEmailFeature: accountHasFeature(
      accountId: $accountId
      code: "COMM:EMAIL"
    )
  }
`

export interface GetComplianceNotificationCenterData {
  getComplianceNotificationCenterData: ComplianceNotificationCenterData
}

export interface GetComplianceNotificationCenterVariables {
  accountId: number
  programId: number
  eventId: number
}

export interface RecentProgramEventsData {
  activePrograms: Program[]
}

export interface RecentProgramEventsVariables {
  accountId: number
}

export interface ComplianceNotificationCenterData {
  communicationData: CommunicationData
  totalCompletedKits: number
  totalResultsReady: number
  turnaroundTime: string
  detectionCounts: DetectionCounts
}

export interface CommunicationData {
  totalSent: number
  dataSet: Array<{
    label: CommunicationType
    dataSize: number
  }>
}

export interface DetectionCounts {
  exceedance: number
  detect: number
  nonDetect: number
}

export const GET_RECENT_PROGRAM_ACTIVITY = gql`
  query recentProgramActivity($accountId: Int!) {
    recentProgramActivity(accountId: $accountId) {
      name
      status
      activityDate
    }
  }
`
export interface GetRecentProgramActivityData {
  recentProgramActivity: RecentProgramActivityCardData[]
}

export interface GetRecentProgramActivityVariables {
  accountId: number
}

export interface RecentProgramActivityCardData {
  name: string
  status: string
  activityDate: string
}
