import { colors, lineHeight } from '@120wateraudit/envirio-components'
import React, { FC, memo } from 'react'
import { ReactComponent as BaseFooterIcon } from 'src/assets/images/Footer.svg'
import styled from 'styled-components'

const Footer: FC = () => (
  <FooterWrapper>
    <FooterIcon />
    <ContentWrapper>
      <span>
        © {new Date().getFullYear()} 120Water™ •{' '}
        <a
          href="https://120water.com/terms-of-use"
          rel="noreferrer noopener"
          target="_blank">
          Terms and Conditions
        </a>{' '}
        •{' '}
        <a
          href="https://120water.com/privacy-policy"
          rel="noreferrer noopener"
          target="_blank">
          Privacy Policy
        </a>
      </span>
    </ContentWrapper>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.black25};
  padding: 2rem 0;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  line-height: ${lineHeight.small};

  & a {
    color: inherit;
    &:hover {
      color: rgba(0, 181, 225, 1);
    }
  }
`

const FooterIcon = styled(BaseFooterIcon)`
  opacity: 0.25;
  height: 1.5rem;
  margin-bottom: 1rem;
`

export default memo(Footer)
