/** Set item in local storage
 *
 * Note - Stringifies any value that is not already a string */
export const setItem = <T>(name: string, data: T): void =>
  window.localStorage.setItem(
    name,
    typeof data === 'string' ? data : JSON.stringify(data)
  )

/** Retrieve single item from local storage */
export const getItem = (name: string): null | string =>
  window.localStorage.getItem(name)

/** Remove single item from local storage */
export const clearItem = (name: string): void =>
  window.localStorage.removeItem(name)

export enum LocalStorageItem {
  /** 120 JWT encoded authentication token */
  TOKEN = '120_pws_token'
}
