import { isObject, merge } from 'lodash'

/**
 * Given a form state that includes initial fields this function replaces empty values with
 * null to support deleting values.
 * @param initialValues
 * @param newValues
 * @returns
 */
export const handleClearedFields = (initialValues, newValues) => {
  // library issue via https://github.com/final-form/react-final-form/issues/130#issuecomment-493447888
  const emptiedData = Object.keys(initialValues).reduce((acc, key) => {
    if (isObject(newValues[key])) {
      acc[key] = handleClearedFields(initialValues[key], newValues[key])
    } else {
      acc[key] = typeof newValues[key] === 'undefined' ? null : newValues[key]
    }
    return acc
  }, {})

  // need to deep merge to get new child properties
  return merge(emptiedData, newValues)
}
