import React, { memo } from 'react'
import styled from 'styled-components'
import { Dropdown } from '@120wateraudit/envirio-components/dist/components/Dropdown'
import { Button, CenteredTextContent } from './shared'
import { EnvirioDropdownProps } from '@120wateraudit/envirio-components'
import {
  SamplingEventFragment,
  ProgramFragment
} from 'src/hooks/useProgramsAndEvents'

const AssignmentList = styled.ul`
  text-align: left;
  display: inline-block;
  margin: 0 auto;
`

const ModalDropdown = styled(Dropdown)`
  width: 300px;
`

const SendModalContent: React.FC<Props> = ({
  locationIds,
  stepNumber,
  optionsEvents,
  optionsPrograms,
  selectedEvent,
  selectedProgram,
  onEventSelect,
  onProgramSelect,
  onClose,
  totalBulkAssignedLocations
}) => {
  if (stepNumber === 0) {
    return (
      <ModalDropdown
        label="Program"
        placeholder="Select..."
        fluid
        value={selectedProgram?.id}
        options={optionsPrograms}
        onChange={onProgramSelect}
        search
      />
    )
  }

  if (stepNumber === 1) {
    return (
      <ModalDropdown
        label="Events"
        placeholder="Select..."
        fluid
        value={selectedEvent?.id}
        options={optionsEvents}
        onChange={onEventSelect}
        search
      />
    )
  }

  if (stepNumber === 2) {
    const locationsCount = totalBulkAssignedLocations
      ? totalBulkAssignedLocations
      : locationIds.length

    return (
      <CenteredTextContent>
        <p>
          <strong>
            You have assigned {locationsCount} location(s) as follows:
          </strong>
        </p>
        <div style={{ marginBottom: 20 }}>
          <AssignmentList>
            <li>Program: {selectedProgram?.name}</li>
            <li>Event: {selectedEvent?.name}</li>
          </AssignmentList>
        </div>
        <Button variant="primary" onClick={onClose}>
          Close
        </Button>
      </CenteredTextContent>
    )
  }

  return null
}

export interface Props {
  stepNumber: number
  optionsEvents: any
  optionsPrograms: any
  onEventSelect: EnvirioDropdownProps['onChange']
  onProgramSelect: EnvirioDropdownProps['onChange']
  selectedEvent?: SamplingEventFragment
  selectedProgram?: ProgramFragment
  locationIds: number[]
  totalBulkAssignedLocations?: number
  onClose: () => void
}

export default memo(SendModalContent)
