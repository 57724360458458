import {
  ActivityRelationType,
  Contact,
  PWSActivity
} from '@120wateraudit/envirio-components/dist/models'
import gql from 'graphql-tag'
import { useEffect, useState } from 'react'

import { get, hardDelete } from 'src/API'
import { useAccount } from 'src/router/UserProvider'
import { Tags, pwsApi, pwsGQL } from 'src/services'
import { DateInterval } from 'src/utils/selectlist'

interface BaseAuditableFields {
  createdBy: string
  createdOn: string
  id: number
  lastModifiedBy: string
  lastModifiedOn: string
}

export interface ActivityType extends BaseAuditableFields {
  accountType: number
  iconColor?: string
  iconName?: string
  name: string
  order: number
  secondaryIconName?: string
}

export type ActivityRelatedItemsBase = Omit<ActivityType, 'accountType'>

export interface Activity {
  activityTypeId?: number
  date?: string
  description?: string
  eventId?: number
  friendlyUserName?: string
  id?: number
  programId?: number
  relationId: number
  relationType: ActivityRelationType
  resolutionDetailId?: number
  resolutionId?: number
  title?: string
  topicId?: number
}

interface ActivityResponse {
  count: number
  items: PWSActivity[]
}

interface ActivityIconAttributes {
  color: string
  name: string
  secondaryName?: null | string
}

interface SearchParams {
  interval: DateInterval
  resolution?: number
  search?: string
  topic?: number
  type?: number
}

const buildParameters = (
  locationId: string,
  { interval, resolution, search, topic, type }: SearchParams
): URLSearchParams => {
  const params = new URLSearchParams()
  params.set('dateInterval', interval.toString())
  params.set('relationType', ActivityRelationType.Location.toString())
  params.set('relationId', locationId)
  if (type) {
    params.set('activityTypeId', type.toString())
  }
  if (resolution) {
    params.set('resolutionId', resolution.toString())
  }
  if (topic) {
    params.set('topicId', topic.toString())
  }
  if (search) {
    params.set('search', search)
  }
  return params
}

const baseUrl = '/pws/rest/accounts'

export const useActivityTypes = () => {
  const [types, setTypes] = useState<ActivityType[]>([])
  const { id } = useAccount()
  const url = `${baseUrl}/${id}/activitytypes`
  useEffect(() => {
    ;(async () => {
      const activityTypes = (await get(url)) as ActivityType[]
      setTypes(activityTypes)
    })()
  }, [url])
  return types
}

export const useActivityResolutions = () => {
  const [resolutions, setResolutions] = useState<ActivityRelatedItemsBase[]>([])
  const { id } = useAccount()
  const url = `${baseUrl}/${id}/activityresolutions`

  useEffect(() => {
    ;(async () => {
      const activityResolutions = (await get(url)) as ActivityRelatedItemsBase[]
      setResolutions(activityResolutions)
    })()
  }, [url])
  return resolutions
}

export const useActivityResolutionDetails = () => {
  const [details, setDetails] = useState<ActivityRelatedItemsBase[]>([])
  const { id } = useAccount()
  const url = `${baseUrl}/${id}/activityresolutiondetails`

  useEffect(() => {
    ;(async () => {
      const resolutionDetails = (await get(url)) as ActivityRelatedItemsBase[]
      setDetails(resolutionDetails)
    })()
  }, [url])
  return details
}

export const useActivityTopics = () => {
  const [topics, setTopics] = useState<ActivityRelatedItemsBase[]>([])
  const { id } = useAccount()
  const url = `${baseUrl}/${id}/activitytopics`

  useEffect(() => {
    ;(async () => {
      const activityTopics = (await get(url)) as ActivityRelatedItemsBase[]
      setTopics(activityTopics)
    })()
  }, [url])
  return topics
}

export const useLocationContacts = (locationId: number) => {
  const [contacts, setContacts] = useState<Contact[]>([])
  const { id } = useAccount()
  const url = `${baseUrl}/${id}/locations/${locationId}/contacts`

  useEffect(() => {
    ;(async () => {
      const locationContacts = (await get(url)) as Contact[]
      setContacts(locationContacts)
    })()
  }, [url])
  return contacts
}

export const getActivityRelatedItemName = (
  activityRelatedItems: ActivityRelatedItemsBase[],
  id?: number
) => {
  const resolutionBase = activityRelatedItems.find(r => r.id === id)

  if (!resolutionBase) {
    return '--'
  }

  return resolutionBase.name
}

const defaultIcon: ActivityIconAttributes = {
  color: '#000000',
  name: 'question circle'
}

const buildIconAttributes = (
  activityItem: undefined | ActivityRelatedItemsBase | ActivityType
): ActivityIconAttributes => {
  if (activityItem && activityItem.iconName && activityItem.iconColor) {
    return {
      color: activityItem.iconColor,
      name: activityItem.iconName,
      secondaryName: activityItem.secondaryIconName
        ? activityItem.secondaryIconName
        : null
    }
  } else {
    return defaultIcon
  }
}

export const getIconAttributes = (
  { activityTypeId, resolutionId, topicId }: PWSActivity,
  activityRelatedItems: ActivityRelatedItemsBase[] | ActivityType[],
  iconOrder: 'ActivityResolution' | 'ActivityTopic' | 'ActivityType'
) => {
  let icon: ActivityIconAttributes

  const activityType = activityRelatedItems.find(ar => ar.id === activityTypeId)
  const activityTopic = activityRelatedItems.find(ar => ar.id === topicId)
  const activityResolution = activityRelatedItems.find(
    ar => ar.id === resolutionId
  )

  switch (iconOrder) {
    case 'ActivityResolution':
      icon = buildIconAttributes(activityResolution)
      return icon
    case 'ActivityTopic':
      icon = buildIconAttributes(activityTopic)
      return icon
    case 'ActivityType':
      icon = buildIconAttributes(activityType)
      return icon
    default:
      return defaultIcon
  }
}

export const generateOptions = (
  valuesArray: Array<ActivityRelatedItemsBase | ActivityType>
) => {
  const optionsArray = valuesArray

  if (optionsArray.every(opt => opt.order !== undefined)) {
    optionsArray.sort((a, b) => (a.order > b.order ? 1 : -1))
  }

  return optionsArray.map(opt => ({
    key: opt.id,
    text: opt.name,
    value: opt.id
  }))
}

export const deleteActivity = (accountId: number, activityId: number) =>
  hardDelete(`/pws/rest/accounts/${accountId}/activities/${activityId}`)

interface LocationActivity {
  activityDate: string
  activityTypeId: number
  name: string
  status: string
}

const activitiesGraphAPI = pwsGQL.injectEndpoints({
  endpoints: builder => ({
    getActivitiesForLocation: builder.query<
      LocationActivity[],
      { accountId: number; locationId: number }
    >({
      providesTags: [Tags.Activities],
      query: arg => ({
        query: GET_LOCATION_ACTIVITY_AND_COMMS,
        variables: arg
      }),
      transformResponse: response => response?.locationActivitiesAndComms || []
    })
  })
})

export const {
  useGetActivitiesForLocationQuery,
  util: { invalidateTags: invalidateActivitiesGQLTags }
} = activitiesGraphAPI

interface GetParams {
  locationId: number | string
  searchParams: SearchParams
}

const activitiesAPI = pwsApi.injectEndpoints({
  endpoints: builder => ({
    createActivity: builder.mutation<PWSActivity, Activity>({
      invalidatesTags: [Tags.Activities],
      query: activity => ({
        data: activity,
        method: 'post',
        url: '/activities'
      })
    }),
    getActivities: builder.query<ActivityResponse, GetParams>({
      providesTags: [Tags.Activities],
      query: ({ locationId, searchParams }) => ({
        url: `/activities?${buildParameters(`${locationId}`, searchParams)}`
      })
    })
  })
})

export const { useCreateActivityMutation, useGetActivitiesQuery } =
  activitiesAPI

const GET_LOCATION_ACTIVITY_AND_COMMS = gql`
  query locationActivitiesAndComms($accountId: Int!, $locationId: Int!) {
    locationActivitiesAndComms(accountId: $accountId, locationId: $locationId) {
      name
      status
      activityDate
      activityType
      activityId
    }
  }
`
