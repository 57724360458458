import React from 'react'
import { Icon } from 'semantic-ui-react'

import { Row } from 'src/components/Layout'

const TableKey = (): JSX.Element => {
  return (
    <Row
      style={{
        padding: '0 14px 14px 14px',
        color: '#6b7280',
        fontWeight: '500'
      }}>
      <>
        Required Field ={' '}
        <Icon
          style={{
            marginLeft: '2px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '6px'
          }}
          color="red"
          name="asterisk"
        />
        Conditionally Required Fields ={' '}
        <Icon
          style={{
            marginLeft: '2px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '6px'
          }}
          color="red"
          name="asterisk"
        />
        <Icon
          style={{ display: 'flex', alignItems: 'center', fontSize: '6px' }}
          color="red"
          name="asterisk"
        />
      </>
    </Row>
  )
}

export default TableKey
