import { PWSAccount } from '@120wateraudit/envirio-components/dist/models'
import { useAuth0 } from '@auth0/auth0-react'
import jwt from 'jsonwebtoken'
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { FullStory } from '@fullstory/browser'
import { pwsClient } from 'src/apollo-clients'
import { useQuery } from '@apollo/client'

import { assignUser } from 'src/modules/Users/slice'
import {
  useLazyGetCurrentUserQuery,
  useLazyGetCurrentEntityDefinitionModelsQuery
} from 'src/services'
import { User } from 'src/types/User'
import { getDefaultAccount } from 'src/utils/getDefaultAccount'
import { LocalStorageItem, clearItem, setItem } from 'src/utils/localStorage'
import MissingUser from './MissingUser'
import AppSkeleton from './Skeleton'
import {
  AccountSoftwarePackage,
  GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY
} from 'src/containers/Communications/Dashboard/dataAccess'

interface UserContextValue {
  account?: PWSAccount
  user?: User
  entityDefinitions?: Record<string, unknown> | undefined
}

declare global {
  interface Window {
    Appcues: any
  }
}
declare global {
  interface Window {
    AppcuesSettings: any
  }
}

const UserContext = createContext<UserContextValue>({})

const isTokenExpired = (token?: string) => {
  if (!token) {
    return true
  }

  const current = Math.floor(Date.now() / 1000)
  const decoded = jwt.decode(token)
  return decoded.exp < current
}

const useToken = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState<string>()
  const [isLoading, setLoading] = useState(true)
  const expired = isTokenExpired(token)
  useEffect(() => {
    ;(async () => {
      if (expired) {
        setLoading(true)
        const token = await getAccessTokenSilently()
        setToken(token)
        setItem(LocalStorageItem.TOKEN, token)
        setLoading(false)
      }
    })()
  }, [getAccessTokenSilently, expired])
  return { isLoading, token }
}

const useGetUserQuery = () => {
  const dispatch = useDispatch()
  // Get a JWT from Auth0. If we've gotten this far it should return quickly.
  const { isLoading: isTokenLoading, token } = useToken()
  // We use a lazy query to wait until we get the above token to call our API.
  const [getUser, { data: user, isLoading: isUserLoading }] =
    useLazyGetCurrentUserQuery()
  const [
    getEntityDefinitions,
    { data: entityDefinitions, isLoading: isEntityDefinitionsLoading }
  ] = useLazyGetCurrentEntityDefinitionModelsQuery()

  const { data: accountSoftwarePackage } = useQuery<AccountSoftwarePackage>(
    GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY,
    {
      client: pwsClient,
      variables: { accountId: user?.defaultAccount?.id },
      skip: !user
    }
  )

  // Once we've got a token we want to fetch the user from our database.
  useEffect(() => {
    if (token) {
      getUser()
      getEntityDefinitions()
    }
  }, [token, getUser, getEntityDefinitions])

  // Once we have the user, we want to identify them to FullStory
  if (process.env.REACT_APP_FULLSTORY_ID && accountSoftwarePackage) {
    FullStory('setIdentity', {
      uid: user?.id.toString(),
      properties: {
        displayName: user?.firstName + ' ' + user?.lastName,
        email: user?.email,
        phoneNumber: user?.defaultAccount?.contactPhone,
        application: 'PWS',
        auth0Id: user?.authId,
        accountCreationDate: user?.defaultAccount?.createdOn,
        userCreationDate: user?.createdOn,
        accountId: user?.defaultAccount?.id,
        accountName: user?.defaultAccount?.companyName,
        primacyCode: user?.defaultAccount?.primacyCode,
        package: accountSoftwarePackage?.accountSoftwarePackageName
      }
    })
  }

  if (user && accountSoftwarePackage) {
    window.Appcues.identify(
      user?.id.toString(), // unique, required
      {
        displayName: user?.firstName + ' ' + user?.lastName,
        email: user?.email,
        phoneNumber: user?.defaultAccount?.contactPhone,
        application: 'PWS',
        auth0Id: user?.authId,
        accountCreationDate: user?.defaultAccount?.createdOn,
        userCreationDate: user?.createdOn,
        accountId: user?.defaultAccount?.id,
        accountName: user?.defaultAccount?.companyName,
        primacyCode: user?.defaultAccount?.primacyCode,
        package: accountSoftwarePackage?.accountSoftwarePackageName
      }
    )
  }

  // We want to write the user to our Redux store so RTKQ can access it.
  useEffect(() => {
    if (user) {
      dispatch(assignUser(user))
    }
  }, [user, dispatch])

  return useMemo(
    () => ({
      isLoading: isTokenLoading || isUserLoading || isEntityDefinitionsLoading,
      user: user,
      entityDefinitions: entityDefinitions
    }),
    [
      isUserLoading,
      isTokenLoading,
      isEntityDefinitionsLoading,
      user,
      entityDefinitions
    ]
  )
}

const UserProvider: FC = props => {
  const { isLoading, user, entityDefinitions } = useGetUserQuery()
  const account = getDefaultAccount(user)

  if (isLoading) {
    return <AppSkeleton />
  }

  if (!user || !account) {
    return <MissingUser />
  }

  return (
    <UserContext.Provider
      value={{ user, account, entityDefinitions }}
      {...props}
    />
  )
}

export const useCurrentUser = () => useContext(UserContext)

/**
 * This hook can be used inside components nested below the UserProvider to
 * _ensure_ a user and account exist without needing checks.
 *
 * This can be dangerous, but should mostly be safe if used inside authenticated routes.
 * @returns An object with user and account marked as defined.
 */
export const useCurrentUserUnsafe = () =>
  useContext(UserContext) as { account: PWSAccount; user: User }

/**
 * Returns the authenticated user.
 * Will crash if used outside of authenticated paths.
 * @returns Authenticated user
 */
export const useUser = () => {
  const { logout } = useAuth0()
  const { user } = useCurrentUser()
  const decoratedLogout = useCallback(() => {
    clearItem(LocalStorageItem.TOKEN)
    logout({ logoutParams: { returnTo: window.location.origin } })
  }, [logout])
  if (!user) {
    throw new Error('Attempted to access users without authentication')
  }

  return { logout: decoratedLogout, user }
}

/**
 * Returns the authenticated user's default account.
 * Will crash if used outside of authenticated paths.
 * @returns Authenticated user's default account
 */
export const useAccount = () => {
  const { account } = useCurrentUser()
  if (!account) {
    throw new Error('Attempted to access account without authentication')
  }

  return account
}

export const useEntityDefinitions = () => {
  const { entityDefinitions } = useCurrentUser()
  if (!entityDefinitions) {
    throw new Error(
      'Attempted to access entity definitions without authentication'
    )
  }
  return entityDefinitions
}

export default UserProvider
