import { TriggerType } from '@120wateraudit/communications-types'
import { Dropdown, Loader, TextField } from '@120wateraudit/envirio-components'
import { useMutation, useQuery } from '@apollo/client'
import { faFileCircleCheck } from '@fortawesome/free-solid-svg-icons'
import React, { FC, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { contactsClient } from 'src/apollo-clients'
import { DataLink } from 'src/components/Layout'
import {
  COMMUNICATION_TEMPLATES,
  CREATE_OR_UPDATE_COMMUNICATION,
  CommunicationInput,
  CreateOrUpdateCommunicationData,
  CreateOrUpdateCommunicationVariables
} from 'src/containers/Communications/EditorSuite/data-access'
import { useProgramsAndEvents } from 'src/hooks'
import { SamplingEventFragment } from 'src/hooks/useProgramsAndEvents'
import { useCommunicationsTemplate } from 'src/modules/Communications/useCommunicationTemplates'
import { useGetPurchasedProgramTypesQuery } from 'src/modules/Programs/dataAccess'
import { useAccount } from 'src/router/UserProvider'
import { CommunicationStatus } from 'src/types/Communications'
import { buildCommunicationDropdownOptions } from 'src/utils/buildCommunicationDropdownOptions'
import { pushRoute } from 'src/utils/navigation'
import Modal from '../ModalContents'
import { ErrorMessage, ModalButton } from '../shared'

interface Props {
  onClose: () => void
}

const CreateLetterTemplateModal: FC<Props> = ({ onClose }) => {
  const { id: accountId } = useAccount()
  const {
    events,
    loading: loadingProgramsAndEvents,
    programs
  } = useProgramsAndEvents(true)

  const [templateType, setTemplateType] = useState({
    key: '',
    text: 'Letter',
    value: ''
  })

  const [selectedProgramId, setSelectedProgramId] = useState<
    undefined | number
  >(undefined)

  const [selectedEvent, setSelectedEvent] = useState<
    undefined | SamplingEventFragment
  >()

  const [selectedCommunicationTemplate, setSelectedCommunicationTemplate] =
    useState()
  const [communicationName, setCommunicationName] = useState('')
  const { data: programTypes = [] } = useGetPurchasedProgramTypesQuery()
  const [isResultLetter, setIsResultLetter] = useState()

  const { data: templateData, loading: loadingTemplates } = useQuery(
    COMMUNICATION_TEMPLATES,
    {
      client: contactsClient,
      variables: {
        accountId
      }
    }
  )

  const isButtonDisabled =
    (templateType.text === 'All Results Letter' &&
      selectedEvent === undefined) ||
    communicationName.length < 1 ||
    selectedCommunicationTemplate === undefined

  const samplingEventOptions = useMemo(
    () =>
      buildCommunicationDropdownOptions(
        events.filter(se => se.programId === selectedProgramId)
      ),
    [events, selectedProgramId]
  )

  const programOptions = useMemo(
    () => buildCommunicationDropdownOptions(Array.from(new Set(programs))),
    [programs]
  )

  const templateOptions = useMemo(() => {
    if (templateData) {
      let templates = templateData.communicationTemplates

      if (
        programTypes &&
        !programTypes.every(pt => pt.name === 'PF') &&
        isResultLetter
      ) {
        const primaryAnalyte = selectedEvent?.primaryAnalyte
        if (!primaryAnalyte) {
          templates = templates.filter(
            ct => ct.triggerCondition === 'allResults'
          )
        } else {
          templates = templates.filter(
            ct =>
              ct.triggerType === TriggerType.SendKitResult &&
              ct.triggerCondition !== 'allResults'
          )
        }
      } else {
        templates = templates.filter(
          ct => ct.triggerType === TriggerType.AdHocLocation
        )
      }

      return buildCommunicationDropdownOptions(templates)
    } else {
      return {
        key: '',
        text: '',
        value: ''
      }
    }
  }, [isResultLetter, templateData, selectedEvent, programTypes])

  const [
    createOrUpdateCommunicationMutation,
    { error: createOrUpdateError, loading: isSaving }
  ] = useMutation<
    CreateOrUpdateCommunicationData,
    CreateOrUpdateCommunicationVariables
  >(CREATE_OR_UPDATE_COMMUNICATION, {
    client: contactsClient,
    onCompleted(data) {
      const id = data?.createOrUpdateCommunication.communication.id
      pushRoute(`/communications/letters/update/${id}`)
      onClose()
    }
  })

  const {
    communicationTemplate,
    communicationTemplateQuery,
    loading: templateLoading
  } = useCommunicationsTemplate(selectedCommunicationTemplate)

  const createCommunicationInput = useCallback((): {
    communication: CommunicationInput
  } => {
    const selectedTemplate = templateData.communicationTemplates.find(
      t => t.id === selectedCommunicationTemplate
    )

    return {
      communication: {
        communicationType: selectedTemplate.communicationType,
        eventId: selectedEvent?.id,
        name: communicationName,
        status: CommunicationStatus.Draft,
        templateId: selectedTemplate.id,
        triggerCondition: selectedTemplate.triggerCondition,
        triggerType: selectedTemplate.triggerType
      } as CommunicationInput
    }
  }, [
    communicationName,
    selectedEvent,
    selectedCommunicationTemplate,
    templateData
  ])

  const handleSave = useCallback(async () => {
    await createOrUpdateCommunicationMutation({
      variables: {
        accountId,
        content: communicationTemplate?.communicationTemplate.content || '',
        ...createCommunicationInput()
      }
    })
  }, [
    createOrUpdateCommunicationMutation,
    accountId,
    communicationTemplate?.communicationTemplate.content,
    createCommunicationInput
  ])

  if (
    isSaving ||
    loadingProgramsAndEvents ||
    loadingTemplates ||
    templateLoading
  ) {
    return <Loader />
  }

  return (
    <Modal
      cancelAction={
        <CreateLetterTemplateModalButton fontSize={'1.15rem'} onClick={onClose}>
          Cancel
        </CreateLetterTemplateModalButton>
      }
      icon={faFileCircleCheck}
      primaryAction={
        <CreateLetterTemplateModalButton
          fontSize={'1.15rem'}
          disabled={isButtonDisabled}
          variant="primary"
          onClick={handleSave}>
          Create Letter Template
        </CreateLetterTemplateModalButton>
      }
      title={`Create Letter Template`}>
      <ModalDropdown
        default
        fluid
        label="Is this a Result Letter?"
        onChange={(_, { value }) => {
          setIsResultLetter(value)

          if (selectedProgramId) {
            setSelectedProgramId(undefined)
          }

          if (selectedEvent) {
            setSelectedEvent(undefined)
          }

          setTemplateType({
            key: '',
            text: 'Letter',
            value: ''
          })
          setSelectedCommunicationTemplate(undefined)
          setCommunicationName('')
        }}
        options={[
          {
            key: 'Yes',
            text: 'Yes',
            value: true
          },
          {
            key: 'No',
            text: 'No',
            value: false
          }
        ]}
        value={isResultLetter}
      />
      {/* If it is isn't a result letter show the  conditional steps below */}
      {isResultLetter === false && (
        <>
          <ModalDropdown
            default
            fluid
            label="Choose your Template Type"
            onChange={(_, { value }) => {
              const templateData = templateOptions.find(t => t.value === value)
              setTemplateType(templateData)
              setSelectedCommunicationTemplate(value)
              communicationTemplateQuery({
                variables: {
                  accountId,
                  templateId: value
                }
              })
            }}
            options={templateOptions}
            value={selectedCommunicationTemplate}
          />
          {templateType.text === 'Company Letterhead' && (
            <InputFieldsWrapper>
              <TextField
                label="Communication Name"
                onChange={e => setCommunicationName(e.target.value)}
                placeholder="Add Communication Name"
                required
                style={{ width: '100%', marginBottom: '1rem' }}
                value={communicationName}
              />
            </InputFieldsWrapper>
          )}
          {templateType.text === 'Inventory Letter' && (
            <InputFieldsWrapper>
              <TextField
                label="Communication Name"
                onChange={e => setCommunicationName(e.target.value)}
                placeholder="Add Communication Name"
                required
                style={{ width: '100%', marginBottom: '1rem' }}
                value={communicationName}
              />
            </InputFieldsWrapper>
          )}
        </>
      )}
      {/* If it is a result letter we show the component steps in this conditional */}
      {isResultLetter && (
        <InputFieldsWrapper>
          <ModalDropdown
            disabled={programOptions.length < 1}
            fluid
            label="Program"
            onChange={(_, { value }) => {
              setSelectedProgramId(value)
              setSelectedEvent(undefined)
              setTemplateType({
                key: '',
                text: 'Letter',
                value: ''
              })
              setSelectedCommunicationTemplate(undefined)
              setCommunicationName('')
            }}
            optionTextMaxLength={45}
            options={programOptions}
            placeholder={
              programOptions.length < 1
                ? 'No Programs found'
                : 'Select a Program'
            }
            search
            value={selectedProgramId}
          />
          {selectedProgramId && (
            <ModalDropdown
              disabled={samplingEventOptions.length < 1}
              fluid
              label="Related Event"
              onChange={(_, { value }) => {
                setSelectedEvent(events.find(e => e.id === value))
                setTemplateType({
                  key: '',
                  text: 'Letter',
                  value: ''
                })
                setSelectedCommunicationTemplate(undefined)
              }}
              optionTextMaxLength={45}
              options={samplingEventOptions}
              placeholder={
                samplingEventOptions.length < 1
                  ? 'No Sampling Events found for this program'
                  : 'Select a Sampling Event'
              }
              search
              value={selectedEvent?.id}
            />
          )}
          {selectedProgramId && selectedEvent && (
            <ModalDropdown
              default
              fluid
              label="Choose your Template Type"
              onChange={(_, { value }) => {
                const templateData = templateOptions.find(
                  t => t.value === value
                )
                setTemplateType(templateData)
                setSelectedCommunicationTemplate(value)
                communicationTemplateQuery({
                  variables: {
                    accountId,
                    templateId: value
                  }
                })
              }}
              options={templateOptions}
              value={
                selectedCommunicationTemplate
                  ? selectedCommunicationTemplate
                  : ''
              }
            />
          )}
          {selectedEvent && (
            <div>
              <TextField
                label="Communication Name"
                onChange={e => setCommunicationName(e.target.value)}
                placeholder="Add Communication Name"
                required
                style={{ width: '100%', marginBottom: '1rem' }}
                value={communicationName}
              />
              {selectedEvent.primaryAnalyte && (
                <PrimaryAnalyteText>
                  Primary Analyte: {selectedEvent.primaryAnalyte.displayName}
                </PrimaryAnalyteText>
              )}
              {!selectedEvent.primaryAnalyte && (
                <PrimaryAnalyteText>
                  A primary analyte has not been selected for this event. If you
                  want Non-Detect, Detect, or Exceedance templates, turn on the
                  primary analyte
                  <DataLink
                    href={`/events/${selectedEvent.id}/locations`}
                    key={selectedEvent.id}>
                    {' '}
                    here
                  </DataLink>
                  .
                </PrimaryAnalyteText>
              )}
            </div>
          )}
        </InputFieldsWrapper>
      )}
      {createOrUpdateError && (
        <ErrorMessage>
          {createOrUpdateError.graphQLErrors[0].message}
        </ErrorMessage>
      )}
    </Modal>
  )
}

export default CreateLetterTemplateModal

const InputFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ModalDropdown = styled(Dropdown)`
  margin-bottom: 1rem;
`

const CreateLetterTemplateModalButton = styled(ModalButton)`
  width: 181px;
  padding: 5px;
`

const PrimaryAnalyteText = styled.div`
  text-align: center;
  padding: 5px;
  font-size: 15px;
`
