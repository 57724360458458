import React from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import {
  Button,
  Container as SUIRContainer,
  DropdownProps,
  Form,
  Icon
} from 'semantic-ui-react'
import Toggle from 'src/components/Forms/Toggle'
import styled from 'styled-components'

import { Error as ErrorMessage } from 'src/components/Error'
import Dropdown from 'src/components/Forms/Dropdown'
import TextField from 'src/components/Forms/TextField'
import { AccountAnalyte } from 'src/types/Analyte'

const ANALYTES_NAME = 'samplingEventAnalytes'
const NEW_ANALYTE = {
  analyteId: null,
  exceedanceLimit: null,
  lowerLimit: null,
  primary: false
}

const mapAnalytesToOptions = (analytes: AccountAnalyte[]) =>
  analytes.map(a => ({
    text: a.analyte.displayName,
    value: a.analyteId,
    upperLimit: a.exceedanceLimit,
    lowerLimit: a.lowerLimit
  }))

interface Props {
  analyteOptions: DropdownProps['options']
  fieldName: string
  index: number
  rangedAnalytes: number[]
  onDelete(): void
}

const Analyte = ({
  analyteOptions,
  fieldName,
  index,
  onDelete,
  rangedAnalytes
}: Props): JSX.Element => {
  const analyteIdField = useField(`${fieldName}analyteId`)
  const analyteId = analyteIdField.input.value
  const rangedAnalyte = rangedAnalytes.includes(analyteId)
  const upperLimit: number | string =
    analyteOptions?.find(a => a.value === analyteId)?.upperLimit ||
    'Add a limit'
  const lowerLimit: number | string =
    analyteOptions?.find(a => a.value === analyteId)?.lowerLimit ||
    'Add a limit'

  return (
    <AnalyteWrapper>
      <Field<string>
        component={Dropdown}
        fluid
        label={'Analyte'}
        name={`${fieldName}analyteId`}
        options={analyteOptions}
        style={{ width: 200 }}
      />
      {rangedAnalyte && (
        <Field<string>
          component={TextField}
          fluid
          label="Lower Limit"
          placeholder={lowerLimit}
          min="0"
          name={`${fieldName}lowerLimit`}
          step="0.1"
          style={{ width: 90 }}
          type="number"
        />
      )}
      <Field<string>
        component={TextField}
        fluid
        label="Upper Limit"
        min="0"
        name={`${fieldName}exceedanceLimit`}
        placeholder={upperLimit}
        step="0.1"
        style={{ width: rangedAnalyte ? 90 : undefined }}
        type="number"
      />
      {index !== 0 && (
        <DeleteButton basic circular icon onClick={onDelete} type="button">
          <Icon color="red" name="trash alternate" />
        </DeleteButton>
      )}
      {index === 0 && (
        <PrimaryAnalyteWrapper inline>
          <Field<boolean>
            component={Toggle}
            label="Primary"
            name={`${fieldName}primary`}
            type="checkbox"
          />
        </PrimaryAnalyteWrapper>
      )}
    </AnalyteWrapper>
  )
}

const ValidationError = ({
  dirty,
  error
}: {
  dirty?: boolean
  error?: string[]
}) => {
  if (!dirty || !error) {
    return null
  }

  if (error[0] !== 'No duplicate Analytes allowed') {
    return null
  }

  return (
    <ErrorMessage
      error={new Error(error[0])}
      header="Invalid Analyte Selection"
    />
  )
}

const AnalytesForm = ({
  analytes
}: {
  analytes: AccountAnalyte[]
}): JSX.Element => {
  const rangedAnalytes = analytes.filter(a => a.isRange).map(a => a.analyteId)
  const options = mapAnalytesToOptions(analytes)
  const { mutators } = useForm()
  const addAnalyte = () => mutators.push(ANALYTES_NAME, NEW_ANALYTE)

  return (
    <Container>
      <FieldArray name={ANALYTES_NAME}>
        {({ fields, meta }) => (
          <>
            {fields.map((name, index) => (
              <Analyte
                analyteOptions={options}
                fieldName={name}
                index={index}
                key={name}
                onDelete={() => fields.remove(index)}
                rangedAnalytes={rangedAnalytes}
              />
            ))}
            <AddButton
              basic
              circular
              disabled={fields.length === analytes.length}
              icon
              onClick={addAnalyte}
              size="small">
              <Icon color="blue" name="plus" />
              <span>Add Analyte</span>
            </AddButton>
            <ValidationError dirty={meta.dirty} error={meta.error} />
          </>
        )}
      </FieldArray>
    </Container>
  )
}

const Container = styled(SUIRContainer)`
  &&& {
    max-width: 500px !important;
    margin: -30px 0 0 0;
  }
`

const AnalyteWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  & > * {
    flex: 1;
    margin: 0 12px;
  }
`

const AddButton = styled(Button)`
  &&& {
    box-shadow: none;
    & > span {
      margin-left: 7px;
      color: #2563eb;
    }
  }
`

const DeleteButton = styled(Button)`
  &&& {
    box-shadow: none;
    align-self: normal;
    flex: 0;
    margin-top: 15px;
  }
`

const PrimaryAnalyteWrapper = styled(Form.Field)`
  &&& {
    align-items: center;
    justify-content: space-between;
    margin-top: -10px;
    & > * {
      flex: 1;
    }
    div {
      flex-direction: column-reverse;
      display: flex;
      label {
        margin: 0;
      }
      div {
        margin-top: 10px;
      }
    }
  }
`

export default AnalytesForm
