import { all, fork } from 'redux-saga/effects'
import activities from './activities'
import activityTypes from './activityTypes'
import geocoding from './geocoding'
import leadServiceLines from './leadServiceLines'
import locations from './locations'
import getProgramTotals from './programTotals'
import programs from './programs'
import protocols from './protocols'
import samples, { sampleActionsSaga } from './samples'
import samplingEvents from './samplingEvents'
import skus from './skus'
import weeklyStats from './weeklyStats'

export default function* sagas() {
  yield all([
    fork(activities),
    fork(activityTypes),
    fork(geocoding),
    fork(leadServiceLines),
    fork(locations),
    fork(programs),
    fork(protocols),
    fork(samplingEvents),
    fork(skus),
    fork(getProgramTotals),
    fork(samples),
    fork(sampleActionsSaga),
    fork(weeklyStats)
  ])
}
