import sortBy from 'lodash/sortBy'
import moment from 'moment'
import React, { useMemo } from 'react'
import { FormRenderProps } from 'react-final-form'
import { Form, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { PWSProgram } from '@120wateraudit/envirio-components/dist/models'
import { Error } from 'src/components/Error'
import Wizard, { Step } from 'src/components/Forms/Wizard'
import { Program } from 'src/types/Programs'
import { SamplingEvent, SamplingEventAnalyte } from 'src/types/SamplingEvents'
import { useGetAccountAnalytesQuery, useGetSkusQuery } from '../dataAccess'
import AnalytesForm from './Analytes'
import EventForm from './Event'
import KitsForm from './Kits'
import type { Values } from './types'
import {
  createFormValidation,
  validateAnalytes,
  validateEvent,
  validateKit
} from './validation'

export { Values, createFormValidation }

const EMPTY_ANALYTES = [
  {
    analyteId: undefined,
    exceedanceLimit: undefined,
    lowerLimit: undefined,
    primary: true
  }
]

const getEventAnalytes = (
  analytes?: SamplingEventAnalyte[],
  primaryAnalyteID?: number
) => {
  if (!analytes || analytes.length <= 0) {
    return EMPTY_ANALYTES
  }

  const eventAnalytes = sortBy(
    analytes.map(sea => ({
      analyteId: sea.analyteId,
      exceedanceLimit:
        sea.exceedanceLimit !== null ? sea.exceedanceLimit : undefined,
      id: sea.id,
      lowerLimit: sea.lowerLimit !== null ? sea.lowerLimit : undefined,
      primary: sea.analyteId === primaryAnalyteID
    })),
    [sea => !sea.primary]
  )
  return eventAnalytes
}

export function generateInitialValues(
  program: PWSProgram,
  samplingEvent?: Omit<SamplingEvent, 'protocolDefinitions'>
): Values {
  const today = new Date()
  const nextYear = new Date()
  nextYear.setUTCFullYear(today.getUTCFullYear() + 1)
  const programType = program.programType.name

  return {
    endsOn: samplingEvent ? moment(samplingEvent.endsOn).toDate() : nextYear,
    eventType: samplingEvent
      ? samplingEvent.eventType
      : programType === 'PF'
      ? 'Pitcher/Filter'
      : 'Sampling',
    kit: samplingEvent
      ? { id: 0, name: 'Placeholder', sampleQuantity: 0, sku: 'PLACEHOLDER' }
      : undefined,
    name: samplingEvent?.name,
    programId: program.id,
    samplingEventAnalytes: getEventAnalytes(
      samplingEvent?.samplingEventAnalytes,
      samplingEvent?.primaryAnalyte?.id
    ),
    startsOn: samplingEvent ? moment(samplingEvent.startsOn).toDate() : today,
    totalGoal: samplingEvent
      ? `${samplingEvent?.totalPitchersGoal || samplingEvent?.totalSamplesGoal}`
      : '0'
  }
}

const useKitChoices = (
  isPitcherFilterEvent: boolean,
  isReplacementProgram: boolean,
  programTypeId: number
) => {
  const { data: kits = [], isLoading: loadingKits } =
    useGetSkusQuery(programTypeId)

  const pitcherKits = kits.filter(
    f => f.sku && (f.sku.includes('PFK') || f.sku === 'KS-CUSTOM')
  )

  const replacementKits = kits.filter(
    f =>
      f.sku &&
      (f.sku.includes('WTK') || f.sku.includes('WST') || f.sku === 'KS-CUSTOM')
  )

  const kitChoices = useMemo(() => {
    if (pitcherKits.length > 0 && isPitcherFilterEvent) {
      return pitcherKits
    }
    if (isReplacementProgram && !isPitcherFilterEvent) {
      return replacementKits
    }
    return kits
  }, [
    isReplacementProgram,
    kits,
    pitcherKits,
    replacementKits,
    isPitcherFilterEvent
  ])

  return { kitChoices, loadingKits }
}

interface Props {
  error?: Error
  isPitcherFilterProgram: boolean
  isReplacementProgram: boolean
  onSubmit: FormRenderProps['handleSubmit']
  program: Program & { events: string[] }
  submitting: boolean
  type: 'create' | 'edit'
  valid: boolean
  values: Values
  onCancel(): void
}

const SamplingEventForm = ({
  error,
  isPitcherFilterProgram,
  isReplacementProgram,
  onCancel,
  onSubmit,
  program,
  submitting,
  type,
  valid,
  values
}: Props): JSX.Element => {
  const isPitcherFilterEvent = values.eventType === 'Pitcher/Filter'
  const { kitChoices, loadingKits } = useKitChoices(
    isPitcherFilterEvent,
    isReplacementProgram,
    program.programTypeId
  )
  const { data: analytes = [], isLoading: loadingAnalytes } =
    useGetAccountAnalytesQuery()

  const steps: Step[] = [
    {
      nextButtonText: isPitcherFilterEvent
        ? 'Next - Select Pitcher Filter Kits'
        : 'Next - Select Analytes',
      title: type === 'create' ? 'Create New Event' : 'Edit Sampling Event',
      render: () => (
        <EventForm
          isPitcherFilterProgram={isPitcherFilterEvent}
          isReplacementProgram={isReplacementProgram}
          program={program}
          type={type}
        />
      ),
      validate: () =>
        !Object.keys(
          validateEvent(values, isReplacementProgram, program.events, type)
        ).length
    }
  ]
  if (
    (isReplacementProgram && !isPitcherFilterEvent) ||
    (!isPitcherFilterProgram && !isReplacementProgram)
  ) {
    steps.push({
      nextButtonText: 'Next - Select Sample Kits',
      subtitle:
        'Limits are set at the account level, but you can override here.',
      title: 'Select Analytes and Limits',
      render: () => <AnalytesForm analytes={analytes} />,
      validate: () => !Object.keys(validateAnalytes(values)).length
    })
  }
  if (type === 'create') {
    steps.push({
      title: 'Select Kit',
      render: () => <KitsForm kits={kitChoices} />,
      validate: () => !Object.keys(validateKit(values)).length
    })
  }

  return (
    <Form onSubmit={onSubmit}>
      <Wizard
        icon={<Icon color="blue" name="flask" />}
        loading={submitting || loadingAnalytes || loadingKits}
        onCancel={onCancel}
        steps={steps}
        valid={valid}
      />
      {error && (
        <ErrorContainer>
          <Error error={error} />
        </ErrorContainer>
      )}
    </Form>
  )
}

const ErrorContainer = styled.div`
  margin: 1.5rem;
`

export default SamplingEventForm
