import * as React from 'react'
import styled from 'styled-components'
import { colors, spacing, fontSize } from '@120wateraudit/envirio-components'
import { ConditionCountCard } from './ConditionCountCard'
import { ReactComponent as Exceedance } from 'src/assets/images/Exceedance.svg'
import { ReactComponent as Hazard } from 'src/assets/images/Hazard.svg'
import { ReactComponent as NonDetect } from 'src/assets/images/NonDetect.svg'
import { IconBackground } from '../Dashboard/ComplianceNotificationProgramCard/Detections'
import { RadioComponent } from 'src/components/Radio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'

interface Props {
  numberOfLetters: number
  allResultsCount: number
  nonDetectCount: number
  detectCount: number
  exceedanceCount: number
  markCompleteOnResultLetterSent: boolean
  toggleMarkCompleteResultLetterSent: () => void
}

export const LetterBreakdown = ({
  numberOfLetters,
  allResultsCount,
  nonDetectCount,
  detectCount,
  exceedanceCount,
  markCompleteOnResultLetterSent,
  toggleMarkCompleteResultLetterSent
}: Props) => (
  <Wrapper>
    <CountLabel>
      You have selected <b>{numberOfLetters} Results Letters.</b>
    </CountLabel>
    <div>
      {allResultsCount > 0 &&
        <ConditionCountCard
          icon={
            <IconBackground>
              <FontAwesomeIcon icon={faEnvelopeOpenText} style={{marginBottom: 5}} size='2x' color='#06CC87'/>
            </IconBackground>
          }
          color={'#E6FBF3'}
          iconColor={'#06CC87'}
          label={'All Results'}
          value={allResultsCount}
        />
      }
      {
        nonDetectCount + detectCount + exceedanceCount > 0 &&
        <>
          <ConditionCountCard
            icon={
              <IconBackground>
                <NonDetect />
              </IconBackground>
            }
            color={'#ECF5F9'}
            iconColor={'#00B5E1'}
            label={'Non-Detects'}
            value={nonDetectCount}
          />
          <ConditionCountCard
            icon={
              <IconBackground>
                <Hazard style={{marginBottom: 5, marginLeft: 2}}/>
              </IconBackground>
            }
            color={'#FFF7E6'}
            iconColor={'#FFB000'}
            label={'Detects'}
            value={detectCount}
          />
          <ConditionCountCard
            icon={
              <IconBackground>
                <Exceedance style={{marginLeft: 2}}/>
              </IconBackground>
            }
            color={'rgba(239,9,0,0.1)'}
            iconColor={'rgba(239,9,0,1)'}
            label={'Exceedances'}
            value={exceedanceCount}
          />
        </>
      }
      <RadioWrapper>
        <RadioComponent
          checked={markCompleteOnResultLetterSent}
          label={'Mark Samples as complete after sending communication'}
          onChange={toggleMarkCompleteResultLetterSent}
          size={'small'}
        />
      </RadioWrapper>
    </div>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const CountLabel = styled.div`
  color: ${colors.black50};
  padding-bottom: ${spacing.tiny};
  font-size: ${fontSize.body};
`
const RadioWrapper = styled.div`
  margin-bottom: 25px;
}
`