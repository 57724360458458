import { geocodeAddress } from '../utils/geocoding'
import { call, put, takeLatest } from 'redux-saga/effects'

import {
  geocodeAddressRequest,
  geocodeAddressSuccess,
  geocodeAddressFailure
} from '../actions/geocoding'

function* geocodeAddressSaga(action: any) {
  try {
    const geocoding = yield call(geocodeAddress, action.payload)

    yield put(geocodeAddressSuccess({ geocoding }))
  } catch (error) {
    yield put(
      geocodeAddressFailure({
        type: action.type,
        id: action.id,
        error: error.message
      })
    )
  }
}

export default function* geocodingSaga() {
  yield takeLatest(geocodeAddressRequest.toString(), geocodeAddressSaga)
}
