import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Input, InputProps } from 'semantic-ui-react'

import { TableContext } from '../context'

const ICON = { circular: true, name: 'search' }

const Search = (): JSX.Element => {
  const { onSearchChanged, searchTerm } = useContext(TableContext)
  const onSearch = onSearchChanged || noop
  // We disable the linter on this next line because we know what the dependencies are.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchDelay = useCallback(debounce(onSearch, 500), [onSearch])
  const [actualTerm, setTerm] = useState(searchTerm)
  useEffect(() => {
    setTerm(searchTerm)
  }, [searchTerm, setTerm])
  const onChange = useCallback(
    (_: unknown, data: InputProps) => {
      setTerm(data.value)
      onSearchDelay(data.value)
    },
    [setTerm, onSearchDelay]
  )
  return (
    <Input
      icon={ICON}
      onChange={onChange}
      placeholder="Search…"
      style={{ width: '25%', margin: '0 7px 0 0' }}
      value={actualTerm || ''}
    />
  )
}

export default Search
