import React, { useState, FC } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import { Error as ErrorMessage } from 'src/components/Error'

interface Props {
  body?: string
  confirmButtonText?: string | undefined
  error?: Error | string
  title?: string
  onClose(): void
  onConfirm: () => Promise<unknown>
  disabled?: boolean
}

const ConfirmationModal = ({
  body = 'This cannot be undone',
  confirmButtonText = 'Confirm',
  error,
  onClose,
  onConfirm,
  title = 'Confirm',
  disabled
}: Props): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const confirm = async () => {
    setLoading(true)
    if (await onConfirm()) {
      onClose()
    }
    setLoading(false)
  }

  return (
    <>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {!error && (
            <>
              <P>{body}</P>
              <P>Are you sure?</P>
            </>
          )}
          <ModalError modalError={error} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            disabled={disabled}
            loading={loading}
            onClick={confirm}
            primary>
            {confirmButtonText}
          </Button>
        </Modal.Actions>
      </Modal.Actions>
    </>
  )
}

const ModalError: FC<{
  modalError?: Error | string
}> = ({ modalError }) => {
  if (!modalError) {
    return null
  }

  if (typeof modalError === 'string') {
    return <ErrorMessage error={new Error(modalError)} />
  }
  return <ErrorMessage error={modalError} />
}

const P = styled.p`
  font-size: 16px;
`

export default ConfirmationModal
