import { colors } from '@120wateraudit/envirio-components'
import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormField, Radio as SemanticRadio } from 'semantic-ui-react'
import styled from 'styled-components'

interface Props extends FieldRenderProps<boolean, HTMLElement> {
  className?: string
  type?: 'checkbox' | 'radio'
}

const Toggle: FC<Props> = ({
  _type,
  input: { checked, name, ...input },
  label,
  ...rest
}) => {
  const onChange = () => input.onChange(!checked)
  return (
    <InputWrapper>
      <SemanticRadio
        {...input}
        {...rest}
        checked={checked}
        inline="true"
        onChange={onChange}
        toggle
        type="checkbox"
        value={name}
      />
      <InputLabel>{label}</InputLabel>
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const InputLabel = styled.label`
  color: ${colors.black};
  opacity: 0.5;
  margin-left: 16px;
  font-weight: 800;
  font-size: 1rem;
`

export const TritonToggle = ({
  className,
  input: { checked, name, ...input },
  label
}: Props) => {
  const onChange = () => input.onChange(!checked)
  return (
    <ToggleField className={className}>
      <input
        {...input}
        checked={checked}
        id={name}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={name}
      />
      <label htmlFor={name}>{label}</label>
    </ToggleField>
  )
}

const ToggleField = styled(FormField)`
  &&&.field {
    [type='checkbox'] {
      position: absolute;
      top: auto;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
      width: 1px;
      height: 1px;
      white-space: nowrap;
    }

    [type='checkbox'] + label {
      color: #333;
      display: block;
      font-size: 14px;
      font-weight: normal;
      position: relative;
      padding: 0.5em;
      padding-left: 4em;
      max-width: calc(100% - 2em);
    }

    [type='checkbox'] + label::before,
    [type='checkbox'] + label::after {
      content: '';
      position: absolute;
      height: 1.5em;
      transition: all 0.25s ease;
    }

    [type='checkbox'] + label::before {
      left: 0;
      top: 0.3em;
      width: 3em;
      border: 0.2em solid #767676;
      background: #767676;
      border-radius: 1.1em;
    }

    [type='checkbox'] + label::after {
      left: 0;
      top: 0.25em;
      background-color: #fff;
      background-position: center center;
      border-radius: 50%;
      width: 1.5em;
      border: 0.15em solid #767676;
    }

    [type='checkbox']:checked + label::after {
      left: 1.6em;
      border-color: #0033a0;
      color: #0033a0;
    }

    [type='checkbox']:checked + label::before {
      background-color: #0033a0;
      border-color: #0033a0;
    }

    [type='checkbox']:focus + label::before,
    [type='checkbox']:hover + label::before {
      box-shadow: 0 0 0.5em #333;
    }

    [type='checkbox']:focus + label::after,
    [type='checkbox']:hover + label::after {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='50' cy='50' r='50' fill='rgba(0,0,0,.25)'/%3E%3C/svg%3E");
      background-size: 30%;
      background-repeat: no-repeat;
      background-position: center center;
    }

    [type='checkbox'][disabled] + label::before {
      background-color: transparent;
      border-color: #ddd;
    }

    [type='checkbox'][disabled] + label::after {
      border-color: #ddd;
    }

    [disabled]:hover + label {
      color: #999; /* case for CSS custom property if not supporting IE/Edge */
    }

    [type='checkbox'][disabled]:hover + label::before {
      box-shadow: none;
    }

    [type='checkbox'][disabled]:hover + label::after {
      background-image: none;
    }

    [type='checkbox']:indeterminate + label::after {
      left: 0.8em;
    }

    [type='checkbox']:indeterminate + label::before {
      background-color: #ddd;
    }

    @media screen and (prefers-reduced-motion: reduce) {
      [type='checkbox'] + label::before,
      [type='checkbox'] + label::after {
        transition: none;
      }
    }
  }
`

export default Toggle
