import { useLazyQuery } from '@apollo/client'
import { useState } from 'react'

import { contactsClient } from 'src/apollo-clients'
import {
  COMMUNICATION_TEMPLATE,
  CommunicationTemplateData
} from 'src/containers/Communications/EditorSuite/data-access'
import { useAccount } from 'src/router/UserProvider'

export const useCommunicationsTemplate = (templateId?: number) => {
  const { id } = useAccount()
  const [communicationTemplate, setCommunicationTemplate] =
    useState<CommunicationTemplateData>()

  const [communicationTemplateQuery, { loading }] =
    useLazyQuery<CommunicationTemplateData>(COMMUNICATION_TEMPLATE, {
      client: contactsClient,
      fetchPolicy: 'cache-and-network',
      onCompleted(data) {
        setCommunicationTemplate(data)
      },
      variables: {
        accountId: id,
        templateId: templateId
      }
    })
  return { communicationTemplate, communicationTemplateQuery, loading }
}
