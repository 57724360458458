import React from 'react'
import ReactGA from 'react-ga4'
import { Button } from 'semantic-ui-react'

import { Action, ExportAction, RowDataWithId, SelectionMode } from '../types'

type ActionButtonProps = Action & { disabled?: boolean; selectionCount: number }

const ActionButton = ({
  disabled = false,
  label,
  onClick,
  selectionCount
}: ActionButtonProps): JSX.Element => {
  return (
    <Button
      compact
      disabled={disabled}
      onClick={() => {
        ReactGA.event({
          action: `Action: ${label}`,
          category: 'Table',
          value: selectionCount
        })
        onClick()
      }}>
      {label}
    </Button>
  )
}

function hasSelectedItems(
  selectionMode?: SelectionMode,
  selected?: RowDataWithId | RowDataWithId[]
): boolean {
  if (selectionMode === 'single') {
    return !!selected
  }

  if (selectionMode === 'multi') {
    return Array.isArray(selected) && !!selected.length
  }

  return false
}

function isActionDisabled(
  action: Action,
  tableIsEmpty: boolean,
  selected?: RowDataWithId | RowDataWithId[],
  selectionMode?: 'multi' | 'single'
): boolean {
  if (tableIsEmpty) {
    return true
  }
  if (action.requiresSelection === false) {
    return false
  }

  if (action.disabledForSelectAll) {
    return true
  }

  const hasSelection = hasSelectedItems(selectionMode, selected)
  const selectionCount = Array.isArray(selected) ? selected.length : 1
  const hasMultiple = hasSelection && selectionCount > 1
  if (action.singleItemOnly && hasMultiple) {
    return true
  }

  return !hasSelection
}

export interface Props {
  actions?: Action[]
  isSelectAllOn?: boolean
  hasSelectAllToggle?: boolean
  exportAction?: ExportAction
  exportButton?: (props: ExportAction) => JSX.Element
  onClearSelection?(): void
  onSelectAll?: any
  selected?: RowDataWithId | RowDataWithId[]
  selectionMode?: 'multi' | 'single'
  toggleSelectAll?: () => void
  tableIsEmpty: boolean
  totalCount?: number
}

const Actions = ({
  actions,
  hasSelectAllToggle,
  selected,
  selectionMode,
  tableIsEmpty
}: Props): JSX.Element => {
  const selectionCount = Array.isArray(selected) ? selected.length : 1
  const actionButtons = (actions ?? []).map(action => (
    <ActionButton
      key={action.label}
      {...action}
      disabled={isActionDisabled(action, tableIsEmpty, selected, selectionMode)}
      selectionCount={selectionCount}
    />
  ))

  return (
    <div
      style={{
        marginRight: 'auto',
        marginLeft: hasSelectAllToggle ? '45px' : 'none'
      }}>
      {actionButtons}
    </div>
  )
}

export default Actions
