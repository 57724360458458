import React, { FC } from 'react'
import styled from 'styled-components'
import {
  spacing,
  colors,
  fontSize,
  lineHeight,
  Button
} from '@120wateraudit/envirio-components'
import { Container as BaseContainer, Icon } from 'semantic-ui-react'
import {
  mediaScreen,
  Device
} from '@120wateraudit/envirio-components/dist/utils/mediaScreen'

interface Props {
  clearAll?: () => void
  message?: string
}

const EmptyState: FC<Props> = ({ message, clearAll }) => {
  return (
    <Wrapper>
      <EmptyStateCard>
        <Icon name="search" size="big" />
        <Message>
          {message ? message : 'No data found, please try again.'}
        </Message>
        {clearAll && (
          <Button
            onClick={() => clearAll && clearAll()}
            variant="primary"
            size="small">
            <Icon name="cancel" />
            Clear Search
          </Button>
        )}
      </EmptyStateCard>
    </Wrapper>
  )
}

const Wrapper = styled(BaseContainer)`
  margin-top: 2rem;

  ${mediaScreen({
    device: Device.Tablet,
    style: {
      marginTop: '1rem'
    }
  })}
`

const EmptyStateCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem;
  background: ${colors.white};
  border-radius: 0.33rem;
  width: 100%;
`

const Message = styled.div`
  font-size: ${fontSize.subHeader};
  line-height: ${lineHeight.subHeader};
  font-weight: 300;
  color: ${colors.black50};
  padding-top: ${spacing.tiny};
  padding-bottom: ${spacing.tiny};
`

export default EmptyState
