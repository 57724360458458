import { fontSize, lineHeight } from '@120wateraudit/envirio-components'
import noop from 'lodash/noop'
import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'

import SingleProtocol from './SingleProtocol'

interface Protocol {
  name: string
  quantity: number
  skuId: number
}

interface Props {
  className?: string
  protocols: Protocol[]
  readOnly?: boolean
  selectedProtocol?: Protocol
  style?: CSSProperties
  onSelectProtocol?: (protocol: Protocol) => void
}

const ProtocolDefinitions: FC<Props> = ({
  className,
  onSelectProtocol,
  protocols,
  readOnly = false,
  selectedProtocol,
  style
}) => {
  const editable = !readOnly && !!onSelectProtocol
  return (
    <div className={className} style={style}>
      <ProtocolHeader data-test="protocol-header">Protocol</ProtocolHeader>
      {protocols.map(protocol => (
        <SingleProtocol
          key={protocol.name}
          onSelect={
            typeof onSelectProtocol === 'function'
              ? () => onSelectProtocol(protocol)
              : noop
          }
          protocol={protocol}
          readOnly={!editable}
          selected={readOnly || protocol.skuId === selectedProtocol?.skuId}
          showDetails={protocol.quantity !== 0}
        />
      ))}
    </div>
  )
}

const ProtocolHeader = styled.div`
  font-size: ${fontSize.subHeader};
  font-weight: 700;
  line-height: ${lineHeight.subHeader};
  padding-bottom: 1rem;
`

export default ProtocolDefinitions
