import { Dropdown, TextField } from '@120wateraudit/envirio-components'
import { PWSSamplingEventStatus } from '@120wateraudit/envirio-components/dist/models'
import { pascalToFriendly } from '@120wateraudit/envirio-components/dist/utils'
import React, { useMemo } from 'react'
import { DropdownItemProps, Form } from 'semantic-ui-react'
import styled from 'styled-components'

import { SamplingEventFragment } from 'src/hooks/useProgramsAndEvents'
import { convertStringEnumToDropdownOptions } from 'src/utils/selectlist'

enum SampleMoveReasonType {
  EventChange = 'Event Changed',
  ImportedIncorrectly = 'Imported Incorrectly',
  CollectionDelay = 'Collection Delay',
  Other = 'Other'
}

const MOVE_REASONS = convertStringEnumToDropdownOptions(
  SampleMoveReasonType
).map(r => ({ ...r, text: pascalToFriendly(r.text) }))

const getEventLabel = (event: SamplingEventFragment): string => {
  return event.status === PWSSamplingEventStatus.Inactive
    ? `${event.name} - Inactive`
    : event.name
}

interface Props {
  event?: { id: number }
  events: SamplingEventFragment[]
  otherReason?: string
  reason?: string
  onEventSelect(_: never, data: DropdownItemProps): void
  onReasonSelect(_: never, data: DropdownItemProps): void
  setOtherReason(value: string): void
}

function MoveForm({
  event,
  events,
  onEventSelect,
  onReasonSelect,
  otherReason,
  reason,
  setOtherReason
}: Props): JSX.Element {
  const eventOptions = useMemo(
    () => events.map(e => ({ text: getEventLabel(e), value: e.id })),
    [events]
  )
  return (
    <>
      <StyledForm>
        <Form.Field>
          <Dropdown
            fluid
            label="Select Event"
            onChange={onEventSelect}
            options={eventOptions}
            placeholder="Select an Event…"
            required
            search
            value={event?.id}
          />
          <Sublabel>Select the Event to move the selected Samples to.</Sublabel>
        </Form.Field>
        <Form.Field>
          <Dropdown
            fluid
            label="Reason for move"
            onChange={onReasonSelect}
            options={MOVE_REASONS}
            placeholder="Select a reason…"
            search
            value={reason}
          />
        </Form.Field>
        {reason === 'Other' && (
          <Form.Field>
            <TextField
              label="Other Reason"
              onChange={(_, { value }) => setOtherReason(value)}
              textarea
              value={otherReason}
            />
          </Form.Field>
        )}
      </StyledForm>
    </>
  )
}

const StyledForm = styled(Form)`
  width: 300px;
`

const Sublabel = styled.p`
  padding-bottom: 3%;
  padding-top: 2%;
  color: rgba(0, 36, 45, 0.5);
`

export default MoveForm
