import { createApi } from '@reduxjs/toolkit/dist/query/react'

import axiosBaseQuery from './axiosBaseQuery'

export interface ExportSession {
  token: string
  error?: string
}

// We create an empty API here to allow for code-splitting.
// Individual endpoints are created in the `dataAccess` files of modules.
export const fileProcessorApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '/file-processor', skipAccount: true }),
  reducerPath: 'fileProcessorApi',
  endpoints: (builder) => ({
    getExportSession: builder.query<ExportSession, number>({
      query: (request) => ({
        method: "get",
        url: `/one-schema/external-submission/${request}`
      })
    })
  })
})

export const {
  useGetExportSessionQuery
} = fileProcessorApi
