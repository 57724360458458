import { AggregateSamplesByMonth } from '@120wateraudit/envirio-components'
import { ProgramType } from '@120wateraudit/envirio-components/dist/models'
import { Tags } from 'src/services'

import pwsApi, { pwsApiV2 } from 'src/services/pws-api'
import { Program, ProgramLocationStatusOptionsQuery } from 'src/types/Programs'
import { SamplingEvent } from 'src/types/SamplingEvents'
import {
  ProgramHubTotals,
  ProgramTotals,
  ReplacementProgramStatistics,
  SamplesByMonth
} from 'src/types/Statistics'

interface DescribedProgramType extends ProgramType {
  description?: string
}

export interface ProgramHubData {
  program: Program
  samplingEvent?: SamplingEvent
}

const ONE_HOUR = 60 * 60

const reduceSamplesByMonthIntoAggregate = (
  data: SamplesByMonth[]
): AggregateSamplesByMonth => {
  const detects: number[] = []
  const exceedances: number[] = []
  const nonDetects: number[] = []

  data.forEach(month => {
    detects.push(month.Detects)
    exceedances.push(month.Exceedances)
    nonDetects.push(month.NonDetects)
  })

  return {
    detects,
    exceedances,
    nonDetects
  }
}

const programsApiV2 = pwsApiV2.injectEndpoints({
  endpoints: builder => ({
    getProgramLocationStatusOptions: builder.query<
      ProgramLocationStatusOptionsQuery,
      number
    >({
      keepUnusedDataFor: ONE_HOUR,
      query: programTypeId => ({
        method: 'get',
        url: `/programs/${programTypeId}/locationStatuses`
      })
    })
  })
})

const programsApi = pwsApi.injectEndpoints({
  endpoints: builder => ({
    getAllProgramTypes: builder.query<DescribedProgramType[], void>({
      keepUnusedDataFor: ONE_HOUR,
      query: () => ({ method: 'get', url: '/programtypes/all' })
    }),
    getHubMetrics: builder.query<ProgramHubTotals, void>({
      providesTags: [Tags.Programs],
      query: () => ({ method: 'get', url: '/programhub/totals' }),
      transformResponse: (original: ProgramHubTotals[]) => original[0]
    }),
    getHubPrograms: builder.query<ProgramHubData[], void>({
      providesTags: [Tags.Programs],
      query: () => ({
        method: 'get',
        url: '/programhub/programs'
      })
    }),
    getProgramMetrics: builder.query<
      ProgramTotals | ReplacementProgramStatistics,
      number
    >({
      providesTags: (r, e, id) => [{ id, type: Tags.Programs }],
      query: (programId: number) => ({
        url: `/programs/${programId}/totals`
      }),
      transformResponse: (original: unknown[]) =>
        original[0] as ProgramTotals | ReplacementProgramStatistics
    }),
    getPurchasedProgramTypes: builder.query<DescribedProgramType[], void>({
      keepUnusedDataFor: ONE_HOUR,
      query: () => ({ url: '/programtypes' })
    }),
    getSamplesByMonth: builder.query<AggregateSamplesByMonth, void>({
      transformResponse: reduceSamplesByMonthIntoAggregate,
      query: () => ({ url: '/programhub/samplesbymonth' })
    })
  })
})

export const {
  useGetAllProgramTypesQuery,
  useGetHubMetricsQuery,
  useGetHubProgramsQuery,
  useGetProgramMetricsQuery,
  useGetPurchasedProgramTypesQuery,
  useGetSamplesByMonthQuery
} = programsApi

export const { useGetProgramLocationStatusOptionsQuery } = programsApiV2
