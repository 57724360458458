import React from 'react'
import { Field } from 'react-final-form'
import { BasicContainer, ParagraphInputGrid, QuestionTitle, NoRedHighlightTextField, SuperAsterisk } from '../../shared'

const OregonEpaMethodsPart1 = (): JSX.Element => {
  return (
    <BasicContainer>
      <ParagraphInputGrid>
        <p>Type of Record</p>
        <p>
          Describe the Records Reviewed for Your Inventory and Indicate Your
          Level of Confidence (e.g., Low, Medium, or High)
        </p>
        <QuestionTitle>
          1. Previous Materials Evaluation
          <SuperAsterisk />
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1PreviousMaterialsEvaluation"
          placeholder="Example: Locations of Tier 1 lead tap sampling locations that are
            served by a lead service line."
          textarea
        />
        <QuestionTitle>
          2. Construction Records and Plumbing Codes
          <SuperAsterisk />
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes"
          placeholder="Examples: Local ordinance adopting an international plumbing code.
            Permits for replacing lead service lines."
          textarea
        />
        <QuestionTitle>
          3. Water System Records
          <SuperAsterisk />
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1WaterSystemRecords"
          placeholder="Examples: Capital improvement plans. Standard operating procedures.
            Engineering standards."
          textarea
        />
        <QuestionTitle>
          4. Distribution System Inspections and Records
          <SuperAsterisk />
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1DistributionSystemInspectionsAndRecords"
          placeholder="Examples: Distribution system maps. Tap cards. Service line
            repair/replacement records. Inspection records. Meter installation
            records."
          textarea
        />
        <QuestionTitle>
          5. Other Records
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="inventoryMethodsPart1otherRecords"
          textarea
        />
      </ParagraphInputGrid>
    </BasicContainer>
  )
}

export default OregonEpaMethodsPart1
