import React, { useCallback, useState } from 'react'

import { PWSSample } from 'src/types/Sample'
import { useCompleteSamplesAction } from '../../dataAccess'
import CertifyStep from './CertifyStep'
import CompleteStep from './CompleteStep'
import ConfirmStep from './ConfirmStep'

type ModalType = 'certify' | 'complete' | 'confirm'

interface Props {
  selectedSamples: PWSSample[]
  onClose(): void
  onCompletion?(): void
}

const CompleteSamplesModal = ({
  onClose,
  onCompletion,
  selectedSamples
}: Props): JSX.Element => {
  const { completeSamples, error, isWorking } = useCompleteSamplesAction(
    selectedSamples
  )
  const onSave = useCallback(async () => {
    if (await completeSamples()) {
      if (onCompletion) onCompletion()
      return true
    }

    return false
  }, [completeSamples, onCompletion])
  const [modal, setModal] = useState<ModalType>('certify')
  const advance = useCallback(() => {
    if (modal === 'certify') {
      setModal('confirm')
    } else if (modal === 'confirm') {
      setModal('complete')
    } else {
      onClose()
    }
  }, [modal, setModal, onClose])
  const cancel = useCallback(() => {
    if (modal === 'certify' || modal === 'complete') {
      onClose()
    } else {
      setModal('certify')
    }
  }, [modal, onClose])

  if (modal === 'complete') {
    return (
      <CompleteStep
        onCancel={advance}
        onComplete={advance}
        selectedSamples={selectedSamples}
      />
    )
  }

  if (modal === 'confirm') {
    return (
      <ConfirmStep
        error={error}
        isSaving={isWorking}
        onCancel={cancel}
        onComplete={advance}
        onSave={onSave}
        selectedSamples={selectedSamples}
      />
    )
  }

  return (
    <CertifyStep
      isSaving={isWorking}
      onCancel={cancel}
      onComplete={advance}
      selectedSamples={selectedSamples}
    />
  )
}

export default CompleteSamplesModal
