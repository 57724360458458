import React, {useEffect, useState} from 'react'
import { Icon, Input, Label } from 'semantic-ui-react'
import styled from 'styled-components'

import { ValidFilterValue } from '../types'

export const FilterLabel = styled(Label)`
  &&& {
    font-size: inherit;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    max-height: 38px;
    padding: 0.745em 0.833em;
    > span {
      color: #9ca3af;
      margin-right: 7px;
    }
  }
`

export const FilterInput = ({
  className,
  isEnabled,
  onFilterChanged,
  showIcon = true,
  numberOnly,
  value
}: {
  className?: string
  isEnabled?: boolean
  showIcon?: boolean
  value?: ValidFilterValue
  numberOnly?: boolean
  onFilterChanged: (value?: null | ValidFilterValue) => void
}): null | JSX.Element => {
  const [filterValue, setFilterValue] = useState(value)
  useEffect(() => {
    // Sets default value so onChange activates
    if (value === 0 || value === '' || value === undefined) {
      setFilterValue('');
    }
  }, [value]);

  if (!isEnabled) {
    return null
  }
  
  const onChange = e => {
    const inputValue = e.target.value
    if (!numberOnly) {
      setFilterValue(inputValue)
      return
    }

    const re = /^[0-9\b]*$/;
    if (re.test(inputValue)) {
      setFilterValue(inputValue)
    }
}

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      onFilterChanged(filterValue)
    }
  }

  const onBlur = () => {
    if (filterValue !== value) {
      onFilterChanged(filterValue)
    }
  }

  let icon: null | JSX.Element = (
    <Icon
      link
      name="arrow circle right"
      onClick={() => {
        onFilterChanged(filterValue)
      }}
    />
  )
  if (!showIcon) {
    icon = null
  }

  return (
    <Input
      className={className}
      icon={icon}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder="..."
      style={{ marginLeft: '1em' }}
      transparent
      value={filterValue}
    />
  )
}
