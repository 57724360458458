import { ApiError } from '../types/ApiError'
import { ValidationError } from 'class-validator'

export const getFriendlyNameForDataProperty = (property: string) => {
  const map = {
    addressLine1: 'Address',
    addressLine2: 'Address 2',
    city: 'City',
    contactEmail: 'Email',
    contactFirstName: 'First Name',
    contactLastName: 'Last Name',
    contactPhone: 'Phone',
    fullName: 'Full Name',
    email: 'Email',
    name: 'Name',
    phone: 'Phone',
    state: 'State',
    stateOrProvince: 'State',
    totalSamplesGoal: 'Total Samples Goal',
    zip: 'Zip',
    postalCode: 'Postal Code',
    titleCompanyOrganization: 'Title/Company/Organization',
    primaryPhone: 'Phone Number',
    primaryPhoneExt: 'Phone Ext.',
    firstName: 'First Name',
    lastName: 'Last Name',
    title: 'Title',
    activityTypeId: 'Type',
    protocolDefinitions: 'Protocol Definitions',
    startsOn: 'Starts On',
    endsOn: 'Ends On',
    mailingAddressLine1: 'Mailing Address',
    mailingCity: 'Mailing City',
    mailingState: 'Mailing State',
    mailingZip: 'Mailing Zip',
    programTypeId: 'Type'
  }

  return map[property] || property
}

const getErrorMessageFromErrors = (
  errors: Array<Partial<ValidationError>>
): string => {
  if (errors.length === 1) {
    switch (errors[0].property) {
      case 'protocolDefinitions':
        return getErrorMessageForProtocolDefinitions(errors[0])
      case 'location':
        errors =
          errors[0].children && errors[0].children.length > 0
            ? errors[0].children
            : errors
        break
      default:
        break
    }
  }

  return `The following fields are invalid: ${errors
    .map(dataError => {
      return getFriendlyNameForDataProperty(dataError.property as string)
    })
    .join(', ')}`
}

export const getErrorMessageFromResponse = (error: ApiError): string => {
  const noMatch = 'Unknown error'
  if (!error) {
    return noMatch
  } else if (!error.response || !error.response.data) {
    return error.defaultErrorMessage || error.message || noMatch
  }

  const {
    response: { data }
  } = error

  if (data.name === 'BadRequestError') {
    if (data.errors && data.errors.length > 0) {
      return getErrorMessageFromErrors(data.errors)
    }
  }

  return data.message || error.defaultErrorMessage || error.message || noMatch
}

export const getRowErrorMessagesFromResponse = (error: ApiError): string[] => {
  if (
    !error ||
    !error.response ||
    !error.response.data ||
    error.response.data.name !== 'BadRequestError' ||
    !error.response.data.errors ||
    !error.response.data.errors.length
  ) {
    return [getErrorMessageFromResponse(error)]
  }

  return error.response.data.errors.map(dataError => {
    return `The ${getFriendlyNameForDataProperty(
      ((dataError as unknown) as any).property as string
    ).toLowerCase()} is not valid for row #${dataError.rowNumber}`
  })
}

const getErrorMessageForProtocolDefinitions = (error: any) => {
  if (error.constraints && error.constraints.arrayMinSize) {
    return 'No protocol definitions were provided.'
  }
  return ''
}
