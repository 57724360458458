import React, { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'

import Body from './Body'
import LinkCell from './Cells/LinkCell'
import EmptyState from './Empty'
import Header from './Header'
import Loader from './Loader'
import Pagination from './Pagination'
import TableKey from './Key'
import Toolbar from './Toolbar'
import TableContext from './context'
import {
  useColumns,
  useHasLoaded,
  useMultiselection,
  useTableState
} from './hooks'
import type { Column, Props, RowData, ValidFilterValue } from './types'
import withCustomFields from './withCustomFields'
import getCustomFieldFilters from './getCustomFieldFilters'
import {
  AccountSoftwarePackage,
  GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY
} from 'src/containers/Communications/Dashboard/dataAccess'
import { pwsClient } from 'src/apollo-clients'
import { useQuery } from '@apollo/client'
import { useAccount } from 'src/router/UserProvider'
import { setColumnsForUser } from './utils'

export { Column, LinkCell, useMultiselection, useTableState, withCustomFields, getCustomFieldFilters }

function TritonTable<T extends RowData>({
  actions = [],
  activeFilters,
  activeViewName,
  allowHiddenColumns = false,
  areAllSelected,
  clearAll,
  columns,
  data,
  isSelectAllOn,
  filters,
  hasTableKey,
  hiddenColumns = [],
  hideSelection,
  loading = false,
  onClearSelection,
  onFilterChanged,
  onHiddenColumnsChanged,
  onPageChanged,
  onPageSizeChanged,
  onSearchChanged,
  onSelectAll,
  onSelectionChanged,
  onSortChanged,
  orderable = false,
  page = 1,
  pageSize = 25,
  paginated = false,
  rowActions = [],
  savedViewType,
  setValidatedColumns,
  searchTerm,
  selected,
  selectionMode,
  setColumns,
  setView,
  showTopCount = true,
  sort,
  totalRecords = 25,
  ...props
}: Props<T>): JSX.Element {
  const collatedColumns = useColumns<T>(columns, {
    areAllSelected,
    isSelectAllOn,
    hiddenColumns,
    hideSelection,
    onSelectAll,
    onSelectionChanged,
    selected,
    selectionMode
  })
  const onSearch = useCallback(
    (term?: string) => {
      if (onSearchChanged) {
        onSearchChanged(term)
        if (onClearSelection) onClearSelection()
      }
    },
    [onSearchChanged, onClearSelection]
  )
  const onFilter = useCallback(
    ({ key, value }: { key: string; value?: null | ValidFilterValue }) => {
      if (onFilterChanged) {
        ReactGA.event({
          action: 'Change Filter',
          category: 'Table',
          label: `${key}: ${value}`
        })
        onFilterChanged({ key, value })
        if (onClearSelection) onClearSelection()
      }
    },
    [onFilterChanged, onClearSelection]
  )
  const onChangeVisibility = useCallback(
    (key, visible) => {
      if (onHiddenColumnsChanged) onHiddenColumnsChanged({ key, visible })
    },
    [onHiddenColumnsChanged]
  )
  const hasLoadedOnce = useHasLoaded(loading)
  const showEmptyBody = !hasLoadedOnce && data.length === 0
  const isEmpty =
    !loading && ((paginated && totalRecords === 0) || data.length === 0)

  const { id: accountId } = useAccount()

  const { data: accountSoftwarePackage } = useQuery<AccountSoftwarePackage>(
    GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY,
    { client: pwsClient, variables: { accountId: accountId }, skip: !accountId }
  )

  return (
    <TableContext.Provider
      value={{
        softwarePackageName: accountSoftwarePackage?.accountSoftwarePackageName,
        allowHiddenColumns,
        activeViewName,
        onSearchChanged: onSearch,
        searchTerm,
        activeFilters,
        filters,
        hiddenColumns,
        columns,
        onFilterChanged: onFilter,
        onToggleColumnVisibility: onChangeVisibility,
        setColumns,
        setView,
        sort,
        orderable,
        savedViewType
      }}>
      <Wrapper>
        <Toolbar
          {...props}
          actions={actions}
          allowHiddenColumns={allowHiddenColumns}
          isSelectAllOn={isSelectAllOn}
          onSelectAll={onSelectAll}
          onClearSelection={onClearSelection}
          orderable={orderable}
          pageSize={paginated ? Math.min(pageSize, data.length) : data.length}
          selected={selected}
          selectionMode={selectionMode}
          showTopCount={showTopCount}
          tableIsEmpty={loading || isEmpty}
          totalCount={paginated ? totalRecords : data.length}
        />
        {isEmpty && (
          <EmptyState message={'No data found.'} clearAll={clearAll} />
        )}
        {!isEmpty && (
          <TableWrapper>
            <StyledTable basic="very" sortable>
              <Header
                columns={setColumnsForUser(
                  collatedColumns,
                  accountSoftwarePackage?.accountSoftwarePackageName
                )}
                hasRowActions={!!rowActions.length}
                onSortChanged={onSortChanged}
                sort={sort}
              />
              <Body
                columns={setColumnsForUser(
                  collatedColumns,
                  accountSoftwarePackage?.accountSoftwarePackageName
                )}
                data={data}
                rowActions={rowActions}
                showEmptyBody={showEmptyBody}
                setValidatedColumns={setValidatedColumns}
              />
            </StyledTable>
            {loading && <Loader simple />}
          </TableWrapper>
        )}
        {hasTableKey && <TableKey />}
        {paginated && (
          <Pagination
            currentPage={page}
            onPageChanged={onPageChanged}
            onPageSizeChanged={onPageSizeChanged}
            pageSize={pageSize}
            totalRecords={totalRecords}
          />
        )}
      </Wrapper>
    </TableContext.Provider>
  )
}

TritonTable.displayName = 'Table'

const Wrapper = styled.div`
  background: white;
  box-shadow: 0px 1px 20px rgba(31, 41, 55, 0.06);
`

const TableWrapper = styled.div`
  max-height: 57vh;
  overflow-x: auto;
  margin: 16px auto;
  background: white;
  position: relative;

  ${({ loading }) =>
    loading &&
    `
    height: 57vh;
    overflow: hidden;
  `}
`

const StyledTable = styled(Table)`
  &&& {
    background: transparent;
    position: relative;
    filter: none;
    border: 0;
  }
`

export default TritonTable
