import React, { FC } from 'react'
import { DataComponentsWrapper } from 'src/modules/Modal/shared'
import InsertableComponent from '../InsertableComponent'
import ModalTitle from '../ModalTitle'
import { AnalyteResultOption } from './SampleArrayComponent'

interface Props {
  analyteResult: AnalyteResultOption
  onClick: (property: string) => void
  isSelected: boolean
  onSelect: () => void
}

const AnalyteResultExpandableComponent: FC<Props> = ({
  analyteResult,
  onClick,
  isSelected,
  onSelect
}) => {
  const { analyteId, displayName, ...analyteProperties } = analyteResult

  const handleClick = (key: string) => {
    switch (key) {
      case 'upperLimit':
        return onClick('analyteLimit')
      case 'name':
        return onClick('analyte.displayName')
      default:
        return onClick(key)
    }
  }

  return (
    <DataComponentsWrapper>
      <ModalTitle
        title={displayName}
        isSelected={isSelected}
        onSelect={onSelect}
      />
      {isSelected &&
        Object.keys(analyteProperties).map((key, index) => (
          <InsertableComponent
            key={`${analyteId}-${key}-${analyteProperties[key]}-${index}`}
            propertyName={key}
            onClick={() => handleClick(key)}
          />
        ))}
    </DataComponentsWrapper>
  )
}

export default AnalyteResultExpandableComponent
