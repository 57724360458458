import { Loader as EnvirioLoader } from '@120wateraudit/envirio-components'
import React from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
  showLabel?: boolean
  simple?: boolean
}

const Loader = ({
  className,
  showLabel = true,
  simple = false
}: Props): JSX.Element => {
  return (
    <Wrapper className={className}>
      {showLabel && !simple && <LoadingLabel>Loading…</LoadingLabel>}
      {simple && <SimpleLoader />}
      {!simple && <DropLoader />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: default;
  background: #3498db;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5;
`

// top, transform, and margin properties combine to align vertically and horizontally
const SimpleLoader = styled(EnvirioLoader)`
  top: 50%;
  transform: translateY(-50%);
  margin: auto;

  & > div {
    background: #fff;
  }
`

const DropLoader = (): JSX.Element => {
  return (
    <>
      <Drop />
      <Wave />
    </>
  )
}

const LoadingLabel = styled.p`
  position: absolute;
  color: white;
  font-size: 32px;
  font-weight: bold;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Drop = styled.div`
  margin: 0px;
  position: relative;
  width: 30px;
  height: 30px;
  top: -40px;
  margin: 0 auto;
  background: #fff;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  -moz-animation-name: drip;
  -webkit-animation-name: drip;
  animation-name: drip;
  -moz-animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  -moz-animation-duration: 4s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 30px solid rgba(255, 255, 255, 1);
    top: -18px;
  }

  @keyframes drip {
    to {
      top: 72%;
    }
  }
`

const Wave = styled.div`
  margin: 175px auto;
  position: relative;
  opacity: 0;
  top: 50%;
  width: 2px;
  height: 1px;
  border: #fff 7px solid;
  -moz-border-radius: 300px / 150px;
  -webkit-border-radius: 300px / 150px;
  border-radius: 300px / 150px;
  -moz-animation-name: ripple;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -moz-animation-delay: 4s;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -moz-animation-duration: 4s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    top: -5px;
    left: -5px;
    width: 2px;
    height: 1px;
    border: #fff 5px solid;
    -moz-border-radius: 300px / 150px;
    -webkit-border-radius: 300px / 150px;
    border-radius: 300px / 150px;
    -moz-animation-name: ripple-2;
    -webkit-animation-name: ripple-2;
    animation-name: ripple-2;
    -moz-animation-duration: 4s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  @keyframes ripple {
    0% {
      opacity: 1;
    }
    50% {
      width: 600px;
      height: 300px;
      border-width: 1px;
      top: 40%;
      opacity: 0;
    }
  }

  @keyframes ripple-2 {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    50% {
      width: 200px;
      height: 100px;
      border-width: 1px;
      top: 100px;
      left: 200px;
    }
    100% {
      opacity: 0;
    }
  }
`

export default Loader
