import { FlatButton } from '@120wateraudit/waterworks'
import {
  faPersonWalkingDashedLineArrowRight,
  faSliders
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import md5 from 'md5'
import React, { useCallback, useContext, useMemo } from 'react'
import { Divider, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import { PRIMARY, IGNIS_RED } from 'src/constants/colors'
import { useToggle } from 'src/hooks'
import { ModalContext } from 'src/modules/Modal'
import { useUser } from 'src/router/UserProvider'

const getGravatarSrc = (email: string): string => {
  const hash: string = md5(email)
  return `https://www.gravatar.com/avatar/${hash}?s=200&d=mp`
}

const useProfile = () => {
  const {
    user: { email, firstName = 'Unknown', lastName = 'User', useSSO = false }
  } = useUser()

  return useMemo(
    () => ({
      avatar: getGravatarSrc(email),
      email,
      name: `${firstName} ${lastName}`,
      useSSO
    }),
    [email, firstName, lastName, useSSO]
  )
}

const useUserControls = () => {
  const [popupMenu, toggle] = useToggle()
  const { openModal } = useContext(ModalContext)
  const { logout } = useUser()

  const openEditProfile = useCallback(() => {
    toggle()
    openModal('editProfile')
  }, [toggle, openModal])
  const onLogoutClick = useCallback(() => {
    toggle()
    logout()
  }, [logout, toggle])

  return useMemo(
    () => ({
      onLogoutClick,
      openEditProfile,
      popupMenu,
      toggle
    }),
    [onLogoutClick, openEditProfile, popupMenu, toggle]
  )
}

const User = (): JSX.Element => {
  const { avatar, email, name, useSSO } = useProfile()
  const { onLogoutClick, openEditProfile, popupMenu, toggle } =
    useUserControls()

  return (
    <Popup
      on="click"
      onClose={toggle}
      onOpen={toggle}
      open={popupMenu}
      trigger={
        <AvatarButton>
          <img src={avatar} alt="Your user" />
        </AvatarButton>
      }>
      <UserInfo>
        <p>{name}</p>
        <p>{email}</p>
      </UserInfo>
      <Divider style={{ margin: 0 }} />
      {!useSSO && (
        <PopupButton borderless onClick={openEditProfile}>
          <FontAwesomeIcon icon={faSliders} />
          <span>Profile Settings</span>
        </PopupButton>
      )}
      <PopupButton borderless className="destructive" onClick={onLogoutClick}>
        <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} />
        <span>Logout</span>
      </PopupButton>
    </Popup>
  )
}

const AvatarButton = styled(FlatButton)`
  padding: 0;
  display: flex;
  border-radius: 8px;

  &:hover {
    background-color: transparent !important;
    box-shadow: 0 0 0 3px ${PRIMARY};
  }

  & > img {
    width: 44px;
    height: 44px;
    border-radius: 8px;
  }
`

const PopupButton = styled(FlatButton)`
  width: 100%;
  height: 44px;
  display: flex;
  & svg:first-child {
    margin-right: 12px;
  }

  &:hover {
    background-color: transparent !important;
    color: ${PRIMARY};
  }

  &.destructive:hover {
    color: ${IGNIS_RED};
  }
`

const UserInfo = styled.div`
  width: 20vw;
  padding: 8px 16px;

  & p {
    font-size: 1.16rem;
    font-weight: 300;
  }
  & p:first-child {
    font-weight: bold;
    margin-bottom: 0;
  }
`

export default User
