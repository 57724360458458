import React, { useContext } from 'react'
import styled from 'styled-components'

import { Row } from 'src/components/Layout'
import Count from '../Count'
import FilterPicker from '../Filters/FilterPicker'
import SavedViewPicker from '../SavedViews'
import TableContext from '../context'
import ColumnPicker from './ColumnPicker'
import Search from './Search'
import { setFiltersForUser } from '../utils'

export interface Props {
  activeViewName?: string
  allowHiddenColumns: boolean
  filterable?: boolean
  itemName?: string
  orderable: boolean
  pageSize: number
  toggleSelectAll?: () => void
  searchable?: boolean
  showTopCount?: boolean
  totalCount: number
}

const TopRow = ({
  allowHiddenColumns,
  filterable,
  itemName,
  orderable,
  pageSize,
  searchable,
  showTopCount,
  totalCount
}: Props): null | JSX.Element => {
  const {
    softwarePackageName,
    activeFilters = {},
    filters = [],
    onFilterChanged,
    savedViewType
  } = useContext(TableContext)
  return (
    <Container>
      {searchable && <Search />}
      {showTopCount && (
        <Count name={itemName} shownCount={pageSize} totalCount={totalCount} />
      )}
      {filterable && (
        <FilterPicker
          activeFilters={activeFilters}
          filters={setFiltersForUser(filters, softwarePackageName)}
          onFilterChanged={onFilterChanged}
        />
      )}
      {(allowHiddenColumns || orderable) && <ColumnPicker />}
      {savedViewType && <SavedViewPicker />}
    </Container>
  )
}

const Container = styled(Row)`
  margin-bottom: 1rem;
  align-items: baseline;
`

export default TopRow
