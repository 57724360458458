import React from 'react'

import { Step } from 'src/components/Forms/Wizard'
import HistoricalRecordsReview from './HistoricalRecordsReview'
import IdentifyingServiceLines from './IdentifyingServiceLines'
import ServiceLineInvestigations from './ServiceLineInvestigations'
import Signature from './Signature'

interface Values {
  previousMaterialsEvaluation?: string
  constructionRecordsAndPlumbingCodes?: string
  waterSystemRecords?: string
  distributionSystemInspectionsAndRecords?: string
  historicalReviewOther?: string
  waterMeterReading?: boolean
  waterMeterRepairOrReplacement?: boolean
  serviceLineRepairOrReplacement?: boolean
  waterMainRepairOrReplacement?: boolean
  backflowPreventionDeviceInspection?: boolean
  otherInspection?: boolean
  otherInspectionExplanation?: string
  operatingProcedures?: string
  operatingProceduresExplanation?: string
  predictiveModeling?: boolean
  predictiveModelingExplanation?: string
  otherInvestigation?: boolean
  otherInvestigationExplanation?: string
}

export function validateHistoricalRecordsReview(values: Values) {
  if (
    !values.previousMaterialsEvaluation ||
    (values.previousMaterialsEvaluation &&
      values.previousMaterialsEvaluation.trim().length === 0) ||
    !values.constructionRecordsAndPlumbingCodes ||
    (values.constructionRecordsAndPlumbingCodes &&
      values.constructionRecordsAndPlumbingCodes.trim().length === 0) ||
    !values.waterSystemRecords ||
    (values.waterSystemRecords &&
      values.waterSystemRecords.trim().length === 0) ||
    !values.distributionSystemInspectionsAndRecords ||
    (values.distributionSystemInspectionsAndRecords &&
      values.distributionSystemInspectionsAndRecords.trim().length === 0) ||
    !values.historicalReviewOther ||
    (values.historicalReviewOther &&
      values.historicalReviewOther.trim().length === 0)
  ) {
    return false
  }

  return true
}

export function validateServiceLineInvestigations(values: Values) {
  if (
    values.predictiveModeling &&
    (!values.predictiveModelingExplanation ||
      (values.predictiveModelingExplanation &&
        values.predictiveModelingExplanation.trim().length === 0))
  ) {
    return false
  }

  if (
    values.otherInvestigation &&
    (!values.otherInvestigationExplanation ||
      (values.otherInvestigationExplanation &&
        values.otherInvestigationExplanation.trim().length === 0))
  ) {
    return false
  }

  return true
}

export function validateIdentifyServiceLinesCheckboxes(values: any) {
  //This checks if any of the below values exist and returns true and false if not.
  return (
    values.waterMeterReading ||
    values.waterMeterRepairOrReplacement ||
    values.serviceLineRepairOrReplacement ||
    values.waterMainRepairOrReplacement ||
    values.backflowPreventionDeviceInspection ||
    values.otherInspection
  )
}

export function validateIdentifyServiceLines(values: Values) {
  const checkBoxCheck = validateIdentifyServiceLinesCheckboxes(values)

  if (
    values.otherInspection &&
    (!values.otherInspectionExplanation ||
      (values.otherInspectionExplanation &&
        values.otherInspectionExplanation.trim().length === 0))
  ) {
    return false
  }

  if (!checkBoxCheck) {
    return false
  }

  if (!values.operatingProcedures) {
    return false
  }

  if (
    values.operatingProcedures === 'Yes' &&
    (!values.operatingProceduresExplanation ||
      (values.operatingProceduresExplanation &&
        values.operatingProceduresExplanation.trim().length === 0))
  ) {
    return false
  }

  return true
}

export const INDIANA_STEPS: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Historical Records Review',
    render: () => <HistoricalRecordsReview />,
    validateStepValues: validateHistoricalRecordsReview
  },
  {
    nextButtonText: 'Next Step',
    title: 'Identifying Service Line Material During Normal Operations',
    render: () => <IdentifyingServiceLines />,
    validateStepValues: validateIdentifyServiceLines
  },
  {
    nextButtonText: 'Next Step',
    title: 'Service Line Investigations',
    render: () => <ServiceLineInvestigations />,
    validateStepValues: validateServiceLineInvestigations
  }
]

export const INDIANA_SIGN_STEP: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Certificate of Service Line Inventory Submission',
    render: () => <Signature />
  }
]
