import AddLocationModal from './AddLocationDrawer'
import AssignProgramsModal from './AssignProgramsModal'
import DetailsDrawer from './DetailsDrawer'
import EditDrawer from './EditDrawer'
import SendCommunicationModal from './SendCommunicationModal'
import SendCommunicationTwoModal from './SendCommunicationModalTwo'

export {
  AddLocationModal,
  AssignProgramsModal,
  DetailsDrawer,
  EditDrawer,
  SendCommunicationModal,
  SendCommunicationTwoModal
}

export const MODALS = {
  addLocation: AddLocationModal,
  assignPrograms: AssignProgramsModal,
  editLocation: EditDrawer,
  sendCommunication: SendCommunicationModal,
  sendCommunicationTwo: SendCommunicationTwoModal,
  viewLocation: DetailsDrawer
}
