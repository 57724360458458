export const isEmail = (email: string): boolean => {
  if (!/@/.test(email)) {
    return false
  }

  const backportion = email.split('@').reverse()[0]

  if (backportion.length < 1) {
    return false
  }

  if (!backportion.includes('.')) {
    return false
  }

  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}
