import { useUser } from 'src/router/UserProvider'

/**
 * `useHasRole` - Checks whether or not a user has the provided roles.
 */
export const useHasRole = (roles: string[]): boolean => {
  const { user } = useUser()

  if (!user || !user.roles) {
    return false
  }

  return user.roles.some(({ name }) => roles.some(role => role === name))
}
