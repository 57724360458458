import React, { useState } from 'react'
import { Search as SUIRSearch } from 'semantic-ui-react'
import styled from 'styled-components'

import { ActiveFilters } from 'src/components/Table/types'
import useDebounce from 'src/hooks/useDebounce'
import { Location } from 'src/types/Location'
import { oneLineAddress } from 'src/utils/oneLineAddress'
import { useGetLocationsQuery } from './dataAccess'

interface Props {
  className?: string
  defaultValue?: string
  disabled?: boolean
  filters?: ActiveFilters
  fluid?: boolean
  placeholder?: string
  onSelectLocation(location?: Location): void
}

const LocationLookup = ({
  className,
  defaultValue,
  disabled,
  filters = {},
  fluid,
  onSelectLocation,
  placeholder
}: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState<string>()
  const debouncedSearch = useDebounce(searchTerm, 250)
  const { data: locations = [], isFetching } = useGetLocationsQuery(
    {
      activeFilters: filters,
      page: 1,
      pageSize: 5,
      searchTerm: debouncedSearch
    },
    {
      skip: !debouncedSearch,
      selectFromResult: ({ data, ...result }) => ({
        data: data
          ? data.data.map(l => ({ ...l, title: oneLineAddress(l) }))
          : [],
        ...result
      })
    }
  )

  return (
    <Search
      className={className}
      defaultValue={defaultValue}
      disabled={disabled}
      fluid={fluid}
      input="search"
      loading={isFetching}
      noResultsMessage="No Locations Found"
      onBlur={() => {
        if (!searchTerm) {
          // If we end up without a search term we assume the user is clearing the search
          onSelectLocation(undefined)
        }
      }}
      onKeyPress={(e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          return false
        }
        return
      }}
      onResultSelect={(_, data) => onSelectLocation(data.result)}
      onSearchChange={(_, data) => setSearchTerm(data.value)}
      placeholder={placeholder}
      results={locations}
    />
  )
}

const Search = styled(SUIRSearch)`
  & .ui input.prompt {
    border-radius: 4px;
  }
` as typeof SUIRSearch

export default LocationLookup
