import { Step } from 'src/components/Forms/Wizard'
import React from 'react'
import EpaPublicAccessibilityDoc from '../FederalWizard/EpaPublicAccessibilityDoc'
import RIHistoricalRecordsReview from './steps/RIHistoricalRecordsReview'
import {
  validateInventoryMethodsPart2Schema,
  validateHistoricalRecordsReviewSchema,
  validateInventoryMethodsPart3Schema,
  validateGeneralInformationSchema
} from './Validation'
import RIEpaMethodsPart2 from './steps/RIEpaMethodsPart2'
import RISummaryPart1 from './steps/RISummaryPart1'
import RIEpaMethodsPart3 from './steps/RIEpaMethodsPart3'

const validateHistoricalStep = async (values: any) => await validateStep(validateHistoricalRecordsReviewSchema, values)

const validateInventoryMethodsPart2Step = async (values: any) => await validateStep(validateInventoryMethodsPart2Schema, values)

const validateInventoryMethodsPart3Step = async (values: any) => await validateStep(validateInventoryMethodsPart3Schema, values)

const validateGeneralInformationStep = async (values: any) => await validateStep(validateGeneralInformationSchema, values)

const validateStep = async (schemaValidator: (values: any) => Promise<any>, values: any) => {
  const historicalStepErrors = await schemaValidator
    (
      values
    )
  if (historicalStepErrors) {
    return false
  }
  return true
}

export const RHODE_ISLAND_STEPS: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Historical Records Review',
    render: () => <RIHistoricalRecordsReview />,
    validateStepValues: validateHistoricalStep
  },
  {
    nextButtonText: 'Next Step',
    title: 'Identifying Service Line Material During Normal Operations',
    render: () => <RIEpaMethodsPart2 />,
    validateStepValues: validateInventoryMethodsPart2Step
  },
  {
    nextButtonText: 'Next Step',
    title: 'Service Line Investigations',
    render: () => <RIEpaMethodsPart3 />,
    validateStepValues: validateInventoryMethodsPart3Step
  },
  {
    nextButtonText: 'Next Step',
    title: 'General Information',
    render: () => <RISummaryPart1 />,
      validateStepValues: validateGeneralInformationStep
  },
  {
    nextButtonText: 'Next Step',
    title: 'Public Accessibility Doc',
    render: () => <EpaPublicAccessibilityDoc
      publicAccessChoicesLabelOverride={`How are you making your inventory publicly accessible? Check all that apply. 
                            Remember that if your system serves > 50,000 people or has a website, you must provide the inventory online in the form of a map. 
                            RIDOH will fulfill this requirement but the water system must link to their online map when it becomes available. 
                            Systems serving < 50,000 people that don't have a website must make the inventory available in a publicly accessible location in each community they serve in the form of a map, spreadsheet, report or other format.`} />
  }
]
