import React from 'react'
import { Field } from 'react-final-form'

import {
  initialInventoryOrUpdateOptions,
  ownershipTypeOptions,
  yesNoDropdownOptions,
  yesNoUnknownDropdownOptions
} from 'src/modules/ServiceLines/dataAccess'
import { Divider } from 'semantic-ui-react'
import { GeneralContainer, BoldSpan, SuperAsterisk, SimpleDropdown } from '../../shared'
import { TritonTextField } from 'src/components/Forms/TextField'

const RISummaryPart1 = (): JSX.Element => {
  return (
    <GeneralContainer>
      <Field<string>
        label={
          <p>
            Is this the <BoldSpan>Initial Inventory</BoldSpan> or an{' '}
            <BoldSpan>Inventory Update</BoldSpan>?
            <SuperAsterisk />
          </p>
        }
        name="inventorySummarypart1Question1initialInventoryOrUpdate"
        component={SimpleDropdown}
        fluid
        options={initialInventoryOrUpdateOptions}
      />
      <Field<string>
        label={
          <p>
            Who <BoldSpan>owns the service lines</BoldSpan> in your system? If
            other, please explain below.
            <SuperAsterisk />
          </p>
        }
        name="inventorySummarypart1Question2aServiceLineOwnership"
        component={SimpleDropdown}
        options={ownershipTypeOptions}
        fluid
      />
      <Field<string>
        label="If other please explain:"
        name="inventorySummarypart1Question2aServiceLineOwnershipOtherExplanation"
        component={TritonTextField}
        textarea
      />
      <Divider />
      <Field<string>
        label={
          <p>
            Is there documentation that defines service line ownership in your
            system, such as a local ordinance? If yes, please describe below and
            explain where ownership is split (e.g., property line, curb stop).
            <SuperAsterisk />
          </p>
        }
        name="inventorySummarypart1Question2bServiceLineOwnership"
        component={SimpleDropdown}
        fluid
        options={yesNoDropdownOptions}
      />
      <Field<string>
        label="If Yes, please describe:"
        name="inventorySummarypart1Question2bServiceLineOwnershipOtherExplanation"
        component={TritonTextField}
        textarea
      />
      <Divider />
      <Field<string>
        displayRequiredAsterisk={true}
        label="Describe when lead service lines were generally installed in your system."
        name="inventorySummarypart1Q3aInstallationExplanation"
        component={TritonTextField}
        textarea
      />
      <Field<string>
        displayRequiredAsterisk={true}
        label="When were lead service lines banned in your system? Reference the state or local ordinance that banned the use of lead in your system."
        name="inventorySummarypart1Q3bBanningExplanation"
        component={TritonTextField}
        textarea
      />
      <Divider />
      <Field<string>
        displayRequiredAsterisk={true}
        label={
          <p>
            Do you have lead goosenecks, pigtails or connectors in your system?
            <SuperAsterisk />
          </p>
        }
        name="inventorySummarypart1Q4LeadGoosenecks"
        component={SimpleDropdown}
        textarea
        options={yesNoUnknownDropdownOptions}
      />
      <Field<string>
        displayRequiredAsterisk={true}
        label="What is your overall level of confidence in the inventory (i.e., Low, Medium, or High.) Please explain your rationale below."
        name="inventorySummarypart1Q5ConfidenceLevel"
        component={TritonTextField}
        textarea
      />
    </GeneralContainer>
  )
}

export default RISummaryPart1
