import React from 'react'
import { Field } from 'react-final-form'
import { Divider, Icon } from 'semantic-ui-react'

import Dropdown from 'src/components/Forms/Dropdown'
import { yesNoNADropdownOptions } from '../../../dataAccess'
import {
  DoubleInputGrid,
  FlexDiv,
  GeneralContainer,
  NoRedHighlightTextField
} from '../shared'

const IdentifyingServiceLines = (): JSX.Element => {
  return (
    <GeneralContainer>
      <p style={{ marginBottom: '0px' }}>
        During which normal operating activities are you collecting information
        on service line material? Check all that apply.
        <sup>
          <Icon
            color="red"
            name="asterisk"
            style={{ fontSize: '6px', marginLeft: '1px' }}
          />
        </sup>
      </p>
      <DoubleInputGrid>
        <FlexDiv>
          <Field<string>
            component="input"
            name="waterMeterReading"
            type="checkbox"
          />
          <label>Water meter reading</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="waterMeterRepairOrReplacement"
            type="checkbox"
          />
          <label>Water meter repair or replacement</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="serviceLineRepairOrReplacement"
            type="checkbox"
          />
          <label>Service line repair or replacement</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="waterMainRepairOrReplacement"
            type="checkbox"
          />
          <label>Water main repair or replacement</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="backflowPreventionDeviceInspection"
            type="checkbox"
          />
          <label>Backflow prevention device inspection</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="otherInspection"
            type="checkbox"
          />
          <label>Other</label>
        </FlexDiv>
      </DoubleInputGrid>
      <Field<string>
        component={NoRedHighlightTextField}
        displayConditionallyRequired
        hideError
        label="If other please explain:"
        name="otherInspectionExplanation"
        textarea
      />
      <Divider />
      <div>
        <p style={{ marginBottom: '0px' }}>
          Did you develop or revise standard operating procedures to collect
          service line material information during normal operation?
          <sup>
            <Icon
              color="red"
              name="asterisk"
              style={{ fontSize: '6px', marginLeft: '1px' }}
            />
          </sup>
        </p>
        <Field<string>
          component={Dropdown}
          fluid
          name="operatingProcedures"
          options={yesNoNADropdownOptions}
        />
      </div>
      <Field<string>
        component={NoRedHighlightTextField}
        displayConditionallyRequired
        hideError
        label="If Yes, please describe:"
        name="operatingProceduresExplanation"
        textarea
      />
    </GeneralContainer>
  )
}

export default IdentifyingServiceLines
