import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  Resolvers,
  split
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { createUploadLink as UploadLink } from 'apollo-upload-client'

import { __DEV__ } from './constants'

import { AWS_BASE } from 'src/API'
import { LocalStorageItem, getItem } from 'src/utils/localStorage'

export const contactsClient = createClient({
  uri: `${AWS_BASE}/platform/communications`
})

export const accountManagementClient = createClient({
  uri: `${AWS_BASE}/platform/account-management/graphql`
})

export const pwsClient = createClient({
  uri: `${AWS_BASE}/pws/graphql`
})

export const piBaseClient = createClient({
  uri: `${AWS_BASE}/pws/predictions`
})

export const featuresClient = createClient({
  noBatch: true,
  uri: `${AWS_BASE}/platform/features/graphql`
})

export function createClient({
  noBatch = false,
  resolvers = {},
  typeDefs,
  uri
}: {
  noBatch?: boolean
  resolvers?: Resolvers | Resolvers[]
  typeDefs?: any
  uri: string
}): ApolloClient<NormalizedCacheObject> {
  const isFile = value =>
    (File !== undefined && value instanceof File) ||
    (Blob !== undefined && value instanceof Blob)

  const isUpload = ({ variables }) => Object.values(variables).some(isFile)
  const uploadLink = new UploadLink({ uri })
  const httpLink = new BatchHttpLink({ batchMax: noBatch ? 1 : undefined, uri })
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      const token = getItem(LocalStorageItem.TOKEN)
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : ''
        }
      }
    })
    return forward(operation)
  })

  return new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: __DEV__,
    link: authLink.concat(split(isUpload, uploadLink, httpLink)),
    resolvers,
    typeDefs
  })
}
