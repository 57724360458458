import * as React from 'react'
import styled from 'styled-components'
import { Container, Form } from 'semantic-ui-react'
import {
  fontSize,
  lineHeight,
  spacing
} from '@120wateraudit/envirio-components'
import { pushRoute } from '../../utils/navigation'
import { ReactComponent as ZoomSplit } from 'src/assets/images/zoom-split.svg'

const NoLocationDetails = () => (
  <>
    <Container>
      <Wrapper>
        <BodyWrapper>
          <Image>
            <ZoomSplit
              style={{ margin: 'auto', width: '8rem', height: '8rem' }}
            />
          </Image>
          <Header>
            <HeaderText>Location Not Found</HeaderText>
          </Header>
          <Form>
            <Instructions>
              <InstructionsText>
                It looks like the location you're trying to view doesn't exist.
              </InstructionsText>
            </Instructions>
          </Form>
        </BodyWrapper>
      </Wrapper>
      <div
        style={{
          display: 'flex',
          padding: `${spacing.mini}`,
          marginTop: `${spacing.small}`
        }}>
        <Link
          href="#"
          onClick={(e: any) => {
            e.preventDefault()
            pushRoute(`/locations`)
          }}>
          Back to Locations
        </Link>
      </div>
    </Container>
  </>
)

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BodyWrapper = styled.div`
  max-height: 27.167rem;
  max-width: 48.667rem;
  background: ${props => props.theme.colors.white};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.66rem;
  padding: ${spacing.huge};
  margin-top: ${spacing.massive};
`

export const Header = styled.div`
  display: flex;
  margin-top: ${spacing.small};
`

export const HeaderText = styled.h5`
  margin: auto;
  text-align: center;
  padding-bottom: ${spacing.tiny};
`

export const InstructionsText = styled.span`
  margin: auto;
  font-weight: normal;
  font-size: 1.167rem;
  text-align: center;
`

export const Image = styled.div`
  display: flex;
`

export const Instructions = styled.div`
  display: flex;
  margin: auto;
`

export const Link = styled.a`
  margin: auto;
  font-size: ${fontSize.body};
  line-height: ${lineHeight.subHeader};
  font-weight: 300;
  color: ${props => props.theme.colors.primary};
`

export default NoLocationDetails
