import React from 'react'
import { Helmet } from 'react-helmet'
import { Slide, ToastContainer } from 'react-toastify'

import Routes from 'src/router'
import { getEnvironment } from 'src/utils/getEnvironment'

import devFavicon from 'src/assets/images/favicons/dev-favicon.svg'
import stageFavicon from 'src/assets/images/favicons/stage-favicon.svg'

const Entry = () => {
  return (
    <>
      <DefaultHelmet />
      <ToastContainer key="toast-notifications" transition={Slide} />
      <Routes />
    </>
  )
}

const DefaultHelmet = (): JSX.Element => {
  const environment = getEnvironment()
  const isDev = environment === 'development'
  const isStage = environment === 'staging'
  return (
    <Helmet>
      <title>120Water PWS</title>
      {isDev && <link href={devFavicon} rel="icon" />}
      {isStage && <link href={stageFavicon} rel="icon" />}
    </Helmet>
  )
}

export default Entry
