import gql from 'graphql-tag'
import { MutationTuple, useMutation, useQuery } from '@apollo/client'

import { contactsClient } from 'src/apollo-clients'
import {
  ContactUpdate,
  ContactUpdateResponse,
  UPDATE_CONTACT_MUTATION
} from 'src/modules/Contacts/dataAccess'
import { ContactCreateMutation, ContactCreateMutationVariables } from './types'

export const GET_CONTACTS_QUERY = gql`
  query contacts(
    $accountId: Int!
    $relationType: String!
    $relationId: Int!
    $status: String
  ) {
    contacts(
      accountId: $accountId
      relationType: $relationType
      relationId: $relationId
      status: $status
    ) {
      id
      accountId
      relationType
      relationId
      firstName
      lastName
      email
      primaryPhone
      primaryPhoneExt
      isPhoneMobile
      alternativePhone
      alternativePhoneExt
      isAlternativePhoneMobile
      addressLine1
      addressLine2
      city
      stateOrProvince
      postalCode
      fax
      salutation
      contactTypeId
      contactMethod
      customerBillingNumber
      designation
      primaryLanguageId
      status
      hasRelationships
      titleCompanyOrganization
    }
  }
`
export const GET_CONTACT_TYPES_QUERY = gql`
  query contactTypesByAccountType($accountType: Int!) {
    contactTypesByAccountType(accountType: $accountType) {
      id
      contactType
    }
  }
`
export const GET_CONTACT_LANGUAGES_QUERY = gql`
  query contactLanguages {
    contactLanguages {
      id
      language
    }
  }
`

export const GET_CONTACT_DESIGNATIONS_QUERY = gql`
  query contactDesignations {
    contactDesignations {
      id
      designation
    }
  }
`

export const GET_CONTACT_DESIGNATION_BY_LOCATION_QUERY = gql`
  query contactDesignationByLocation(
    $accountId: Int!
    $relationId: Int!
    $designation: Int!
  ) {
    contactDesignationByLocation(
      accountId: $accountId
      relationId: $relationId
      designation: $designation
    ) {
      id
      accountId
      relationType
      relationId
      designation
    }
  }
`

export const GET_CONTACT_STATUS_HISTORY_QUERY = gql`
  query contactStatusHistory($accountId: Int!, $contactId: Int!) {
    contactStatusHistory(accountId: $accountId, contactId: $contactId) {
      id
      status
      createdOn
    }
  }
`

export const CREATE_CONTACT_QUERY = gql`
  mutation createContact(
    $accountId: Int!
    $relationType: String!
    $relationId: Int!
    $contact: ContactInput!
  ) {
    createContact(
      accountId: $accountId
      relationType: $relationType
      relationId: $relationId
      contact: $contact
    ) {
      id
      accountId
      relationType
      relationId
      firstName
      lastName
      email
      primaryPhone
      primaryPhoneExt
      isPhoneMobile
      alternativePhone
      alternativePhoneExt
      isAlternativePhoneMobile
      addressLine1
      addressLine2
      city
      stateOrProvince
      postalCode
      fax
      salutation
    }
  }
`

export const DELETE_CONTACT_QUERY = gql`
  mutation deleteContact($accountId: Int!, $contactId: Int!) {
    deleteContact(accountId: $accountId, contactId: $contactId)
  }
`

export const UPDATE_NEW_PRIMARY_CONTACT_QUERY = gql`
  mutation updateNewPrimaryContact(
    $accountId: Int!
    $contactId: Int!
    $contact: ContactInput!
    $previousContactId: Int!
  ) {
    updateNewPrimaryContact(
      accountId: $accountId
      contactId: $contactId
      contact: $contact
      previousContactId: $previousContactId
    ) {
      id
      accountId
      relationType
      relationId
      fullName
      email
      primaryPhone
      primaryPhoneExt
      addressLine1
      addressLine2
      city
      stateOrProvince
      postalCode
      fax
      salutation
    }
  }
`

export const useContactCreateMutation = (): MutationTuple<
  ContactCreateMutation,
  ContactCreateMutationVariables
> => {
  const [mutation, result] = useMutation<
    ContactCreateMutation,
    ContactCreateMutationVariables
  >(CREATE_CONTACT_QUERY, { client: contactsClient })
  return [mutation, result]
}

export const useContactUpdateMutation = (): MutationTuple<
  ContactUpdateResponse,
  ContactUpdate
> => {
  const [mutation, result] = useMutation<ContactUpdateResponse, ContactUpdate>(
    UPDATE_CONTACT_MUTATION,
    { client: contactsClient }
  )

  return [mutation, result]
}

export const useContactDesignations = () => {
  return useQuery(GET_CONTACT_DESIGNATIONS_QUERY, {
    client: contactsClient
  })
}
