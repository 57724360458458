import { Button } from '@120wateraudit/envirio-components'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { Container, Divider, Header } from 'semantic-ui-react'

import { Card, Row } from 'src/components/Layout'
import { SupportLink } from 'src/components/SupportLink'
import Logger from 'src/utils/Logger'
import AppSkeleton from './Skeleton'

const MissingUser = (): JSX.Element => {
  const { logout, user } = useAuth0()
  useEffect(() => {
    Logger.log(
      new Error(`Unable to find authenticated user: ${JSON.stringify(user)}`)
    )
  }, [user])
  return (
    <AppSkeleton>
      <Container style={{ marginTop: '32px' }}>
        <Card>
          <Header as="h2" style={{ marginBottom: 0 }}>
            Unable to authenticate your account
          </Header>
          <Divider />
          <p style={{ fontSize: '1.5rem' }}>
            We could not identify your user account. Please try logging in
            again. If this issue persists, contact our{' '}
            <SupportLink>Support Team.</SupportLink>
          </p>
          <Row style={{ justifyContent: 'flex-end', padding: '0 4rem' }}>
            <Button
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
              variant="primary">
              Try Again
            </Button>
          </Row>
        </Card>
      </Container>
    </AppSkeleton>
  )
}

export default MissingUser
