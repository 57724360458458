import React, { FC } from 'react'
import { Loader } from '@120wateraudit/envirio-components'
import { Button } from '../../Modal/shared'
import { Error as TritonError } from 'src/components/Error'

interface Props {
  loading: boolean
  errorMessage: string | undefined
  isCompleted: boolean
  modalContent: React.ReactNode
  onClose: () => void
}

const ModalContentWrapper: FC<Props> = ({
  loading,
  errorMessage,
  modalContent,
  isCompleted,
  onClose
}) => {
  if (loading) {
    return <Loader />
  }

  if (isCompleted) {
      return <Button onClick={onClose}>Close</Button>
  }

  if (errorMessage) {
    return (
      <>
        <TritonError error={new Error(errorMessage)} />
        <Button onClick={onClose}>Close</Button>
      </>
    )
  }

  return <>{modalContent}</>
}

export default ModalContentWrapper
