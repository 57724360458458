import assignWith from 'lodash/assignWith'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { downloadDocument, get } from 'src/API'
import { ActiveFilters, RowData, Sort } from 'src/components/Table/types'
import { downloadExcel } from 'src/utils/excel'
import { displayDownloadStarted } from 'src/utils/toast'
import { addFilters } from 'src/services'

export const useDebouncedEffect = (
  effect: () => void,
  deps: unknown[],
  delay: number
) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay)

    return () => clearTimeout(handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay])
}

export interface SearchParams {
  activeFilters: ActiveFilters
  customFields?: string
  include?: string
  page: number
  pageSize: number
  searchTerm?: string
  sort?: Sort | string
}
interface Response<T> {
  count: number
  data?: T[]
  items?: T[]
}

const isValidCustomFieldFilter = (
  customFields: string,
  useNewFilters: boolean
) => {
  if (useNewFilters) {
    return true
  }

  try {
    const parsed = JSON.parse(customFields)
    return Array.isArray(parsed)
  } catch {
    return false
  }
}

const buildParameters = (
  {
    activeFilters,
    customFields,
    include,
    page,
    pageSize,
    searchTerm,
    sort
  }: SearchParams,
  useNewFilters = false
): URLSearchParams => {
  const params = new URLSearchParams()
  params.set('pageNumber', (page - 1).toString())
  params.set('pageSize', pageSize.toString())
  if (searchTerm) {
    params.set('search', searchTerm)
  }
  if (sort && typeof sort === 'object') {
    params.set('sort', `${sort.key} ${sort.direction}`)
  } else if (sort && typeof sort === 'string') {
    params.set('sort', sort)
  }
  if (include) {
    params.set('include', include)
  }
  if (customFields && isValidCustomFieldFilter(customFields, useNewFilters)) {
    params.set('customFields', customFields)
  }
  addFilters(params, activeFilters, useNewFilters)
  return params
}

const mergeParams = (objValue, srcValue) => {
  if (isEmpty(srcValue)) {
    return objValue
  }

  if (isPlainObject(objValue) && isPlainObject(srcValue)) {
    const newObj = { ...objValue }
    Object.keys(srcValue).forEach(key => {
      // If we have a default value we should use that when the passed in value is nullish
      if (srcValue[key] == null && objValue[key] != null) {
        newObj[key] = objValue[key];
      } else {
        newObj[key] = srcValue[key]
      }
    })
    return newObj
  }

  return undefined
}

export const useMergedParams = (
  params?: SearchParams,
  defaultParams: Partial<SearchParams> = {}
): undefined | SearchParams =>
  useMemo(() => {
    if (isEmpty(defaultParams)) {
      return params
    }
    return params
      ? assignWith({}, defaultParams, params, mergeParams)
      : undefined
  }, [params, defaultParams])

function useFetch<T>(
  baseUrl: string,
  params?: SearchParams,
  isPaginated = true,
  useNewFilters = false
) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<T[]>([])
  const [total, setTotal] = useState(0)
  const paramsURL = `${
    params ? `?${buildParameters(params, useNewFilters).toString()}` : ''
  }`

  const fetch = useCallback(async () => {
    setLoading(true)

    const url = `${baseUrl}${
      params ? `?${buildParameters(params, useNewFilters).toString()}` : ''
    }`

    let response: undefined | Response<T> | T[]
    if (isPaginated) {
      response = await get<Response<T>>(url)
      if (!response) {
        setData([])
        setTotal(0)
      } else {
        setData(response.items || response.data || [])
        setTotal(response.count)
      }
    } else {
      response = await get<T[]>(url)
      if (!response) {
        setData([])
        setTotal(0)
      } else {
        setData(response)
        setTotal(response.length)
      }
    }

    setLoading(false)
  }, [
    setLoading,
    setData,
    setTotal,
    params,
    baseUrl,
    isPaginated,
    useNewFilters
  ])
  return { data, fetch, loading, total, paramsURL }
}

export function useExport(
  baseUrl: string,
  params?: SearchParams,
  prefix = 'export',
  isCsv = false,
  useNewExport?: boolean
) {
  return useCallback(async () => {
    const url = useNewExport
      ? `${baseUrl}/${
          params ? `?${buildParameters(params, true).toString()}` : ''
        }`
      : `${baseUrl}/export/${
          params ? `?${buildParameters(params).toString()}` : ''
        }`
    if (useNewExport) {
      displayDownloadStarted({ type: 'export' })
      get(url)
    } else if (isCsv) {
      await downloadDocument(url, `${prefix}.csv`)
    } else {
      await downloadExcel(url, prefix)
    }
  }, [useNewExport, baseUrl, params, isCsv, prefix])
}

interface UseDataOptions {
  defaultParams?: Partial<SearchParams>
  exportCsv?: boolean
  exportPrefix?: string
  exportUrl?: string
  isPaginated?: boolean
  useNewExport?: boolean
  useNewFilters?: boolean
}

const BASE_SEARCH_PARAMS = { activeFilters: {}, page: 0, pageSize: 20 }

export function useData<T = RowData>(
  baseUrl: string,
  params: SearchParams = BASE_SEARCH_PARAMS,
  options: UseDataOptions = {}
) {
  const {
    defaultParams = {},
    exportCsv = false,
    exportPrefix = 'export',
    exportUrl = undefined,
    isPaginated = true,
    useNewExport,
    useNewFilters = false
  } = options
  const mergedParams = useMergedParams(params, defaultParams)
  const exportData = useExport(
    exportUrl || baseUrl,
    mergedParams,
    exportPrefix,
    exportCsv,
    useNewExport
  )
  const { data, fetch, loading, total, paramsURL } = useFetch<T>(
    baseUrl,
    mergedParams,
    isPaginated,
    useNewFilters
  )

  useDebouncedEffect(
    () => {
      fetch()
    },
    [fetch],
    250
  )

  return { data, exportData, loading, refetch: fetch, total, paramsURL, mergedParams }
}
