import { Button as BaseButton } from '@120wateraudit/envirio-components'
import { colors } from '@120wateraudit/envirio-components/dist/theme'
import styled from 'styled-components'

export const ModalWrapper = styled.div`
  margin: 3rem;
  display: grid;
  grid-template-columns: auto;
  gap: 1rem 0;
  justify-items: center;
`

export const ModalHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  position: relative;

  .dropdown {
    margin-bottom: 2rem;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Button = styled(BaseButton)`
  width: 150px;
  margin: 0;
`

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.error};
  margin-bottom: 1.5rem;
`

export const Wrapper = styled.div`
  width: 350px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const TwoButtonGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 1rem;
  justify-items: center;
`
export const ModalButton = styled(Button)`
  height: 36px;

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const DataComponentsWrapper = styled.div`
  max-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.5rem 3rem;
`
