import * as React from 'react'
import { HazardCircle } from './HazardCircle'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import { Button as BaseButton } from "@120wateraudit/envirio-components";
interface Props {
    onClose: () => void;
}

export const NoTemplatesWarning = ({ onClose }: Props) => {
    const history = useHistory();
    function handleClick() {
        history.push("/communications/letters");
        onClose();
    }
    return (
        <div style={{ textAlign: 'center', width: '100%', padding: '20px 0' }}>
            <HazardCircle />
            <LabelWrapper>
                Uh Oh!
            </LabelWrapper>
            <ErrorWrapper>
                <ErrorLable>Something went wrong</ErrorLable>
                <ErrorText>No compatible letter templates available</ErrorText>
            </ErrorWrapper>
            <ButtonContainer>
                <Button onClick={handleClick} variant="primary">
                    Visit Letter Templates
                </Button>
            </ButtonContainer>
            <ButtonContainer>
                <Button onClick={onClose} variant="default">
                    Close Window
                </Button>
            </ButtonContainer>
        </div>)
}

const LabelWrapper = styled.div`
  font-size: 2.5rem;
  margin: 2rem;
  align-content: center;
  font-weight: bold;
`

const ErrorLable = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  margin: 1.5rem;
`

const ErrorText = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
`

const ErrorWrapper = styled.div`
  border: 1px solid #f7d5d0;
  border-radius: 6px; 
  background-color: #fff2f0;
  width: 50%;
  margin: auto;
  color: #823e34;
  margin-bottom: 2rem;
`

const ButtonContainer = styled.div`
  font-size: 1.4rem;
  display: block;
`

const Button = styled(BaseButton)`
  margin: .7rem;
  width: 45%;
`

