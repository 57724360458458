import React, { useCallback } from 'react'

import {
  ActiveFilters,
  CustomFieldFilterDefinition,
  FilterableProps,
  FilterDefinition,
  FilterType,
  ValidFilterValue
} from '../types'
import { CustomFieldFilter } from './CustomFieldFilter'
import DatePickerFilter from './DateFilter'
import ListSelectionFilter from './ListSelectionFilter'
import TextMatchFilter from './TextMatchFilter'
import { useComplexFieldFilter } from './hooks'
import NumberFilter from './NumberFilter'

interface Props {
  activeFilters: ActiveFilters
  filter?: FilterDefinition
  onFilterChanged: FilterableProps['onFilterChanged']
  operator?: string
  value?: ValidFilterValue
  value2?: ValidFilterValue
}

const Filter = ({
  activeFilters,
  filter,
  onFilterChanged,
  operator,
  value,
  value2
}: Props): null | JSX.Element => {
  if (!filter) {
    return null
  }

  if (filter.customField) {
    const customFieldFilter = filter as CustomFieldFilterDefinition
    return (
      <CustomFieldFilter
        activeFilters={activeFilters}
        filter={customFieldFilter}
        onFilterChanged={onFilterChanged}
        operator={operator}
        value={value}
        value2={value2}
      />
    )
  }

  if (filter.type === FilterType.YearPicker) {
    return (
      <BuiltInYearFilter
        filter={filter}
        onFilterChanged={onFilterChanged}
        value={value}
      />
    )
  }

  return (
    <BuiltInFilter
      filter={filter}
      onFilterChanged={onFilterChanged}
      value={value}
    />
  )
}

interface BuiltInProps {
  filter: FilterDefinition
  onFilterChanged: FilterableProps['onFilterChanged']
  value?: ValidFilterValue
}

const BuiltInFilter = ({
  filter,
  onFilterChanged,
  value
}: BuiltInProps): null | JSX.Element => {
  const changeFilter = useCallback(
    (value: ValidFilterValue) => {
      if (filter && onFilterChanged) {
        onFilterChanged({
          key: filter.key,
          value: value === filter.defaultValue ? undefined : value
        })
      }
    },
    [onFilterChanged, filter]
  )

  const removeFilter = useCallback(() => {
    if (filter && onFilterChanged) {
      onFilterChanged({
        key: filter.key,
        value: null
      })
    }
  }, [onFilterChanged, filter])

  if (filter.type === FilterType.ListSelection) {
    return (
      <ListSelectionFilter
        defaultValue={filter.defaultValue}
        label={filter.label}
        multiple={filter.multipleSelections}
        onChange={changeFilter}
        options={filter.options || []}
        removeFilter={removeFilter}
        scrolling={filter.scrollingSelections}
        value={value}
      />
    )
  }

  if (filter.type === FilterType.TextMatch) {
    return (
      <TextMatchFilter
        label={filter.label}
        onChange={changeFilter}
        removeFilter={removeFilter}
        value={value}
      />
    )
  }

  if (
    filter.type === FilterType.DatePicker ||
    filter.type === FilterType.DateTimePicker
  ) {
    return (
      <BuiltInDateFilter
        filter={filter}
        onFilterChanged={changeFilter}
        removeFilter={removeFilter}
        value={value}
      />
    )
  }

  return null
}

const BuiltInYearFilter = ({
  filter,
  onFilterChanged,
  value
}: BuiltInProps): JSX.Element => {
  const changeFilter = useCallback(
    (value: ValidFilterValue) => {
      if (filter && onFilterChanged) {
        onFilterChanged({
          key: filter.key,
          value: value === filter.defaultValue ? undefined : value
        })
      }
    },
    [onFilterChanged, filter]
  )
  const removeFilter = useCallback(() => {
    if (filter && onFilterChanged) {
      onFilterChanged({
        key: filter.key,
        value: null
      })
    }
  }, [onFilterChanged, filter])

  const {
    changeFilter: complexChangeFilter,
    operator,
    value: start,
    value2: end
  } = useComplexFieldFilter(changeFilter, value)

  return  (
    <NumberFilter
      label={filter.label}
      onChange={complexChangeFilter}
      operator={operator}
      removeFilter={removeFilter}
      value={start}
      value2={end}
      isYearFilter={true}
    />
  )
}

interface DateFilterProps {
  filter: FilterDefinition
  value?: ValidFilterValue
  onFilterChanged(value: ValidFilterValue): void
  removeFilter: () => void
}

const BuiltInDateFilter = ({
  filter,
  onFilterChanged,
  removeFilter,
  value
}: DateFilterProps): null | JSX.Element => {
  const {
    changeFilter,
    operator,
    value: start,
    value2: end
  } = useComplexFieldFilter(onFilterChanged, value)
  if (
    filter.type !== FilterType.DatePicker &&
    filter.type !== FilterType.DateTimePicker
  ) {
    return null
  }

  return (
    <DatePickerFilter
      label={filter.label}
      onChangeValue={changeFilter}
      operator={operator}
      removeFilter={removeFilter}
      type={filter.type}
      value={start}
      value2={end}
    />
  )
}

export default Filter
