import React from 'react'
import { CheckboxProps, Radio } from 'semantic-ui-react'
import styled from 'styled-components'
import { colors } from '@120wateraudit/envirio-components'

interface Props {
  checked?: boolean
  defaultChecked?: boolean
  label: string
  onChange?: (e: React.FormEvent<HTMLInputElement>, d: CheckboxProps) => void
  size?: string
  subLabel?: string
  subLabelColor?: string
}

export const RadioComponent = ({
  checked,
  defaultChecked,
  label,
  onChange,
  size,
  subLabel,
  subLabelColor
}: Props) => {
  return (
    <>
      <RadioDiv>
        <Radio
          checked={checked}
          defaultChecked={defaultChecked}
          label={label}
          onChange={onChange}
          size={size}
          toggle
        />
      </RadioDiv>
      {subLabel && <SubLabelDiv color={subLabelColor}>{subLabel}</SubLabelDiv>}
    </>
  )
}

const RadioDiv = styled.div`
  padding-top: 8px;

  .ui.toggle.checkbox input: focus: checked~.box: before;
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: ${colors.primary} !important;
  }

  .ui.toggle.checkbox input: checked ~.box: before;
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: ${colors.primary} !important;
  }

  .ui.toggle.checkbox .box: before;
  .ui.toggle.checkbox label:before {
    background-color: grey;
  }
`
const SubLabelDiv = styled.div`
  margin-top: 1rem;
  color: ${props => (props.color && props.color) || 'black'};
`
