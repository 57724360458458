import React from 'react'

import { Step } from 'src/components/Forms/Wizard'
import OREpaMethodsPart1 from './steps/OREpaMethodsPart1'
import OREpaMethodsPart2 from './steps/OREpaMethodsPart2'
import OREpaMethodsPart3 from './steps/OREpaMethodsPart3'
import { validateHistoricalRecordsReviewSchema, validateInventoryMethodsPart2Schema, validateInventoryMethodsPart3Schema } from './Validation'

const validateHistoricalStep = async (values: any) => await validateStep(validateHistoricalRecordsReviewSchema, values)

const validateInventoryMethodsPart2Step = async (values: any) => await validateStep(validateInventoryMethodsPart2Schema, values)

const validateInventoryMethodsPart3Step = async (values: any) => await validateStep(validateInventoryMethodsPart3Schema, values)

const validateStep = async (schemaValidator: (values: any) => Promise<any>, values: any) => {
  const historicalStepErrors = await schemaValidator
    (
      values
    )
  if (historicalStepErrors) {
    return false
  }
  return true
}

export const OREGON_STEPS: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Historical Records Review',
    render: () => <OREpaMethodsPart1 />,
    validateStepValues: validateHistoricalStep
  },
  {
    nextButtonText: 'Next Step',
    title: 'Identifying Service Line Material During Normal Operations',
    render: () => <OREpaMethodsPart2 />,
    validateStepValues: validateInventoryMethodsPart2Step
  },
  {
    nextButtonText: 'Next Step',
    title: 'Service Line Investigations',
    render: () => <OREpaMethodsPart3 />,
    validateStepValues: validateInventoryMethodsPart3Step
  }
]
