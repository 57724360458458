import styled from 'styled-components'
import MapErrorImg from 'src/assets/images/MapError.png'
import { colors } from '@120wateraudit/envirio-components'

const NoMap = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.black25};
  background-image: url(${MapErrorImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export default NoMap
