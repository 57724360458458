import gql from 'graphql-tag'
import { CommunicationOption } from './types'

export const AVAILABLE_COMMUNICATIONS_QUERY = gql`
  query availableAdHocCommunications(
    $accountId: Int!
    $hideInventoryChoices: Boolean
  ) {
    availableAdHocCommunications(
      accountId: $accountId
      communicationType: Letter
      hideInventoryChoices: $hideInventoryChoices
    ) {
      id
      name
      templateId
    }
  }
`

export interface AvailableCommunicationsData {
  availableAdHocCommunications: CommunicationOption[]
}

export interface AvailableCommunicationsVariables {
  accountId: number
  hideInventoryChoices?: boolean
}

export const PREVIOUSLY_SENT_COMMS = gql`
  query previouslySentComms($communicationId: Int!, $locationIds: [Int!]!) {
    previouslySentComms(
      communicationId: $communicationId
      locationIds: $locationIds
    ) {
      locationId
    }
  }
`
export interface PreviouslySentCommsData {
  previouslySentComms: Array<{
    locationId: number
  }>
}

export interface PreviouslySentCommsVariables {
  communicationId: number
  locationIds: number[]
}

export const ADHOC_SEND_BATCH = gql`
  mutation processAdHocBatch(
    $params: ProcessAdHocBatchParams!
    $download: Boolean
    $isCertifiedMail: Boolean
  ) {
    processAdHocBatch(
      params: $params
      download: $download
      isCertifiedMail: $isCertifiedMail
    ) {
      ... on Send {
        result
      }
    }
  }
`

export const ADHOC_DOWNLOAD_BATCH = gql`
  mutation processAdHocBatch(
    $params: ProcessAdHocBatchParams!
    $download: Boolean
    $isCertifiedMail: Boolean
  ) {
    processAdHocBatch(
      params: $params
      download: $download
      isCertifiedMail: $isCertifiedMail
    ) {
      ... on Download {
        result
      }
    }
  }
`

export interface ProcessAdHocBatchSendData {
  processAdHocBatch: {
    result: boolean
  }
}

export interface ProcessAdHocBatchDownloadData {
  processAdHocBatch: {
    result: number
  }
}

export interface ProcessAdHocVariables {
  params: {
    accountId: number
    communicationId: number
    accountType: number
    locationIds: number[]
    templateId?: number
  }
  download?: boolean
  isCertifiedMail?: boolean
}
