import { Button } from '@120wateraudit/envirio-components'
import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Form, Loader } from 'semantic-ui-react'

import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TritonError } from 'src/components/Error'
import ExternalLink from 'src/components/ExternalLink'
import { Contact } from 'src/modules/Contacts/dataAccess'
import { DrawerContents } from 'src/modules/Modal/Drawer'
import { Location } from 'src/types/Location'
import ContactInfo from '../ContactInfo'
import LocationInfo from '../LocationInfo'
import { useInitialValues, useUpdateLocation } from './hooks'
import { validate } from '../../dataAccess'
import { useFeatures } from "src/hooks";

interface Props {
  contact?: Contact
  location: Location
  onClose(): void
}

const EditDrawer = ({ contact, location, onClose }: Props): JSX.Element => {
  const {
    accountHasContactsFeature
  } = useFeatures()
  const initialValues = useInitialValues(location, contact)
  const { errors, onSubmit } = useUpdateLocation()
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, valid, values }) => (
        <Form onSubmit={handleSubmit}>
          <DrawerContents
            header={
              <Header
                onClose={onClose}
                locationId={location.id}
                submitting={submitting}
                valid={valid}
              />
            }>
            {errors && (
              <TritonError style={{ margin: '12px 24px' }} messages={errors} />
            )}
            <LocationInfo location={ location }/>
            {accountHasContactsFeature && values.contact && <ContactInfo />}
          </DrawerContents>
        </Form>
      )}
      validate={data => validate(data, 'edit')}
    />
  )
}

const Header = ({
  locationId,
  onClose,
  submitting,
  valid
}: {
  locationId: number
  submitting: boolean
  valid: boolean
  onClose(): void
}): JSX.Element => {
  return (
    <>
      <div>
        <h3>Edit Record</h3>
        <ExternalLink to={`/locations/${locationId}/details`}>
          View All Details
        </ExternalLink>
      </div>
      <div>
        <Button
          disabled={!valid || submitting}
          style={{ marginRight: '7px' }}
          variant="primary">
          {submitting ? 'Saving…' : 'Save'}{' '}
          <Loader active={submitting} inline size="tiny" />
        </Button>
        <Button
          disabled={submitting}
          onClick={onClose}
          type="button"
          variant="error">
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </div>
    </>
  )
}

export default EditDrawer
