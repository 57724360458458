import createDetailsReducer from './helpers/DetailsReducers'
import actions, {
  createInvalidatedSampleRequest,
  createInvalidatedSampleSuccess,
  createInvalidatedSampleFailure,
  completeSamplesRequest,
  completeSamplesSuccess,
  completeSamplesFailure
} from '../actions/samples'

const reducer = createDetailsReducer({
  entityName: 'sample',
  fetchAction: actions.detailsActions.fetchRequest,
  fetchSuccessAction: actions.detailsActions.fetchSuccess,
  fetchFailureAction: actions.detailsActions.fetchFailure,
  unloadAction: actions.detailsActions.unload,
  createAction: actions.createActions.createRequest,
  createSuccess: actions.createActions.createSuccess,
  createFailure: actions.createActions.createFailure,
  deleteAction: actions.deleteActions.deleteRequest,
  deleteSuccessAction: actions.deleteActions.deleteSuccess,
  deleteFailureAction: actions.deleteActions.deleteFailure,
  updateActionRequest: actions.updateActions.updateRequest,
  updateActionSuccess: actions.updateActions.updateSuccess,
  updateActionFailure: actions.updateActions.updateFailure,

  // Additional Actions
  additionActions: {
    [createInvalidatedSampleRequest.toString()]: (
      state: any,
      _action: any
    ) => ({
      ...state,
      isSaving: true
    }),
    [createInvalidatedSampleSuccess.toString()]: (
      state: any,
      _action: any
    ) => ({
      ...state,
      isSaving: false
    }),
    [createInvalidatedSampleFailure.toString()]: (
      state: any,
      _action: any
    ) => ({
      ...state,
      isSaving: false
    }),
    [completeSamplesRequest.toString()]: (state: any, _action: any) => ({
      ...state,
      isSaving: true
    }),
    [completeSamplesSuccess.toString()]: (state: any, _action: any) => ({
      ...state,
      isSaving: false
    }),
    [completeSamplesFailure.toString()]: (state: any, _action: any) => ({
      ...state,
      isSaving: false
    })
  }
})

export default reducer
