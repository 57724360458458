import {
  colors,
  fontSize,
  lineHeight,
  spacing
} from '@120wateraudit/envirio-components'
import React from 'react'
import { Icon, IconProps, Popup, SemanticICONS } from 'semantic-ui-react'
import styled from 'styled-components'

import { useToggle } from 'src/hooks'
import IconButton from './Buttons/IconButton'

interface Action {
  disabled?: boolean
  icon?: string
  label: string
  onClick(): void
  title?: string
}

export interface Props {
  actions: Action[]
  className?: string
  triggerIcon?: SemanticICONS
}

const OverflowMenu = ({
  actions,
  className,
  triggerIcon = 'ellipsis vertical'
}: Props): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [popupMenu, toggle, setPopupMenu] = useToggle()
  return (
    <Popup
      basic
      on="click"
      onClose={() => setPopupMenu(false)}
      onOpen={() => setPopupMenu(true)}
      open={popupMenu}
      position="bottom right"
      trigger={
        <IconButton
          type="button"
          className={className}
          name={triggerIcon}
          basic={true}
        />
      }>
      <Menu>
        {actions.map(action => (
          <MenuItem
            key={action.label}
            onClick={() => {
              setPopupMenu(false)
              action.onClick()
            }}>
            {action.icon && (
              <Icon
                name={action.icon as IconProps['name']}
                style={ICON_STYLE}
              />
            )}
            <span>{action.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </Popup>
  )
}

const Menu = styled.ul`
  background: ${colors.white};
  border-radius: 4px;
  width: 184px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
`

const MenuItem = styled.li`
  font-size: ${fontSize.body};
  font-weight: 300;
  padding: ${spacing.mini};
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 8px;

  span {
    display: inline-block;
    line-height: ${lineHeight.body};
  }

  &:hover {
    background: ${colors.black05};
  }
`

const ICON_STYLE = {
  height: '20px',
  marginLeft: '8px',
  marginRight: '8px',
  opacity: 0.25,
  width: '24px'
}

export default OverflowMenu
