import { Button } from '@120wateraudit/envirio-components'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import styled from 'styled-components'

import Detail from 'src/components/Detail'
import ExternalLink from 'src/components/ExternalLink'
import { Value } from 'src/components/Layout'
import PhoneNumber from 'src/components/PhoneNumber'
import {
  Contact,
  useGetPrimaryContactQuery
} from 'src/modules/Contacts/dataAccess'
import { ModalContext } from 'src/modules/Modal'
import { DrawerContents } from 'src/modules/Modal/Drawer'
import { Location } from 'src/types/Location'
import { formatFullName } from 'src/utils/formatFullName'
import formatNumber from 'src/utils/formatNumber'
import { useHasMoreThanReadPermission } from 'src/hooks'
import { useEntityDefinitions } from "src/router/UserProvider";
import {
  getPropertyClassificationEnumText
} from 'src/utils/entityDefintions/locationEnums'

type Props = {
  contact?: Contact
  location: Location
  onClose(): void
}

const DetailsDrawer = ({ location, onClose }: Props): JSX.Element => {
  const { isLoading, primaryContact: contact } = useGetPrimaryContactQuery(
    location.id
  )
  return (
    <DrawerContents
      header={
        <Header onClose={onClose} location={location} contact={contact} />
      }>
      <LocationSummary location={location} />
      <ContactSummary contact={contact} isLoading={isLoading} />
    </DrawerContents>
  )
}

const Header = ({ contact, location, onClose }: Props): JSX.Element => {
  const { openModal } = useContext(ModalContext)
  const userHasPermission = useHasMoreThanReadPermission()

  return (
    <>
      <div>
        <h3>View Record</h3>
        <ExternalLink to={`/locations/${location?.id}/details`}>
          View All Details
        </ExternalLink>
      </div>
      <div>
        {userHasPermission && (
          <Button
            onClick={() =>
              openModal('editLocation', { location, contact }, 'drawer')
            }
            style={{ marginRight: '7px' }}
            variant="primary">
            Edit
          </Button>
        )}

        <Button onClick={onClose} type="button" variant="error">
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </div>
    </>
  )
}

const LocationSummary = ({ location }: { location: Location }): JSX.Element => {
  const entityDefinitions = useEntityDefinitions()
  return (
    <Section>
      <Detail label="120Water Location ID">{formatNumber(location.id)}</Detail>
      <Detail label="External ID">{location.externalId}</Detail>
      <Detail label="Location Name">{location.name}</Detail>
      <Detail label="LCR Tier">{location.profile?.tier}</Detail>
      <Detail label="Property Classification">
        { getPropertyClassificationEnumText(location.profile?.propertyClassification, entityDefinitions) }
      </Detail>
      <Detail label="Parcel Number">{location.profile?.parcelNumber}</Detail>
      <Detail label="Address">
        <Value>{location.addressLine1}</Value>
        {location.addressLine2 && <Value>{location.addressLine2}</Value>}
        <Value>
          {location.city}, {location.state} {location.zip}
        </Value>
      </Detail>
      <Detail label="Mailing Address">
        {location.mailingAddressLine1 && (
          <>
            <Value>{location.mailingAddressLine1}</Value>
            {location.mailingAddressLine2 && (
              <Value>{location.mailingAddressLine2}</Value>
            )}
            <Value>
              {location.mailingCity}, {location.mailingState}{' '}
              {location.mailingZip}
            </Value>
          </>
        )}
      </Detail>
      <Detail label="Is Disadvantaged Neighborhood">
        {(location.disadvantagedNeighborhood ||
          location.disadvantagedNeighborhood === false) && (
          <Value>{location.disadvantagedNeighborhood ? 'Yes' : 'No'}</Value>
        )}
      </Detail>
    </Section>
  )
}

const ContactSummary = ({
  contact,
  isLoading
}: {
  contact?: Contact
  isLoading: boolean
}): JSX.Element => {
  return (
    <Section>
      <Detail isLoading={isLoading} label="Primary Contact Name">
        {contact?.firstName &&
          formatFullName(
            contact?.firstName,
            contact?.lastName,
            contact?.salutation
          )}
      </Detail>
      <Detail isLoading={isLoading} label="Primary Contact Address">
        {contact?.addressLine1 && (
          <>
            <Value>{contact?.addressLine1}</Value>
            <Value>{contact?.addressLine2}</Value>
            <Value>
              {contact?.city}, {contact?.stateOrProvince} {contact?.postalCode}
            </Value>
          </>
        )}
      </Detail>
      <Detail isLoading={isLoading} label="Preferred Contact Method">
        {contact?.contactMethod}
      </Detail>
      <Detail isLoading={isLoading} label="Primary Phone">
        {contact?.primaryPhone && (
          <PhoneNumber
            phoneNumber={contact.primaryPhone}
            type={contact.isPhoneMobile ? 'mobile' : 'landline'}
          />
        )}
      </Detail>
      <Detail isLoading={isLoading} label="Alternate Phone">
        {contact?.alternativePhone && (
          <PhoneNumber
            phoneNumber={contact.alternativePhone}
            type={contact.isAlternativePhoneMobile ? 'mobile' : 'landline'}
          />
        )}
      </Detail>
      <Detail isLoading={isLoading} label="Email">
        {contact?.email}
      </Detail>
      <Detail isLoading={isLoading} label="Primary Language">
        {contact?.preferredLanguage}
      </Detail>
    </Section>
  )
}

const Section = styled.div`
  margin: 12px 24px;
`

export default DetailsDrawer
