import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import { TritonDropdown } from 'src/components/Forms/Dropdown'
import { TritonTextField as TextField } from 'src/components/Forms/TextField'
import { TritonToggle as Toggle } from 'src/components/Forms/Toggle'
import { STATE_LIST } from 'src/constants/stateList'
import {
  LocationProfileTierType
} from 'src/types/enums'
import { convertEnumToListItems } from 'src/utils/selectlist'
import { useEntityDefinitions } from "src/router/UserProvider";
import {
    getPropertyClassificationEnums
} from 'src/utils/entityDefintions/locationEnums'
import {Location} from "src/types/Location";

const TIER_OPTIONS = convertEnumToListItems(LocationProfileTierType, {
  numericValues: true
})

interface Props {
    location?: Location
}

const LocationInfo = ({ location }: Props): JSX.Element => {
  const entityDefinitions = useEntityDefinitions()
  return (
    <div>
      <Field<string>
        component={StyledTextField}
        label="External ID"
        name="externalId"
      />
      <Field<string>
        component={StyledTextField}
        label="Location Name"
        name="name"
      />
      <Field<string>
        component={StyledDropdown}
        label="LCR Tier"
        name="profile.tier"
        options={[{ value: null, text: 'None' }, ...TIER_OPTIONS]}
      />
      <Field<string>
        component={StyledDropdown}
        label="Property Classification"
        name="profile.propertyClassification"
        options={[{ value: null, text: 'None' }, ...getPropertyClassificationEnums(location?.profile?.propertyClassification, entityDefinitions)]}
      />
      <Field<string>
        component={StyledTextField}
        label="Parcel Number"
        name="profile.parcelNumber"
      />
      <Field<string>
        component={StyledTextField}
        label="Address Line 1"
        name="addressLine1"
      />
      <Field<string>
        component={StyledTextField}
        label="Address Line 2"
        name="addressLine2"
      />
      <Field<string> component={StyledTextField} label="City" name="city" />
      <Field<string>
        component={StyledDropdown}
        label="State"
        name="state"
        options={STATE_LIST}
        search
      />
      <Field<string> component={StyledTextField} label="ZIP Code" name="zip" />
      <Field<string>
        component={StyledTextField}
        label="Mailing Address Line 1"
        name="mailingAddressLine1"
      />
      <Field<string>
        component={StyledTextField}
        label="Mailing Address Line 2"
        name="mailingAddressLine2"
      />
      <Field<string>
        component={StyledTextField}
        label="Mailing City"
        name="mailingCity"
      />
      <Field<string>
        component={StyledDropdown}
        label="Mailing State"
        name="mailingState"
        options={STATE_LIST}
        search
      />
      <Field<string>
        component={StyledTextField}
        label="Mailing ZIP Code"
        name="mailingZip"
      />
      <Field<boolean>
        component={StyledToggle}
        label="Is Disadvantaged Neighborhood"
        name="disadvantagedNeighborhood"
        type="checkbox"
      />
    </div>
  )
}

const StyledTextField = styled(TextField)`
  &&& {
    margin: 24px;
  }
`

const StyledDropdown = styled(TritonDropdown)`
  &&& {
    margin: 24px;
  }
`

const StyledToggle = styled(Toggle)`
  &&& {
    margin: 24px;
  }
`

export default LocationInfo
