export const validSecondarySamplingChoices = [
  'Visual Inspection at the Meter Pit',
  'CCTV Inspection at Curb Box - External',
  'Mechanical Excavation',
  'Predictive Modeling',
  'Customer Self-Identification',
  'CCTV Inspection at Curb Box - Internal',
  'Vacuum Excavation',
  'Other'
]

export function createSamplingChoicesArray(values: any) {
  return (
    values?.inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.filter(
      o =>
        o === 'Water Quality Sampling - Targeted' ||
        o === 'Water Quality sampling - Sequential' ||
        o === 'Water Quality Sampling - Flushed' ||
        o === 'Water Quality Sampling - Other'
    ) ?? []
  )
}
