import * as React from 'react'
import {
  Modal,
  Wizard,
  WizardState,
  Loader,
  Button
} from '@120wateraudit/envirio-components'
import { PWSKit } from '@120wateraudit/envirio-components/dist/models'
import { LetterBreakdown } from './LetterBreakdown'
import { SendOptions } from './SendOptions'
import { ConfirmSend } from './ConfirmSend'
import { SendCompleted } from './SendCompleted'
import DataAccess, { SendKitResultsMutation } from './DataAccess'
import { CountsError } from './CountsError'
import { startPolling } from 'src/actions/polling'
import { addBatchDownload } from 'src/actions/batchDownloads'
import { connect } from 'react-redux'
import { ApplicationState } from 'src/reducers'
import { StepHeader } from 'src/components/StepHeader'
import { ReactComponent as LetterIcon } from 'src/assets/images/Letter.svg'
import { ReactComponent as CheckIcon } from 'src/assets/images/Success_Check.svg'

interface Props {
  accountId: number
  isOpen: boolean
  toggle: () => void
  selectedKits: PWSKit[]
  hasAutomatedLetterMailingFeature: boolean
  onSendKitResultsCompleted: () => void
  startPolling: typeof startPolling
  addBatchDownload: typeof addBatchDownload
}

interface State {
  modalOverflowStyle: 'visible' | 'auto'
  sendOption?: SendOption
  activeStepIndex: number
  confirmSendTitle: string
  showCountErrorModal: boolean
  isCertifiedMail: boolean
  isComplete: boolean
  markCompleteOnResultLetterSent: boolean
}

export enum SendOption {
  USPS = 'USPS',
  PDF = 'PDF'
}

enum SendStep {
  'Letter Breakdown' = 0,
  'Send Options' = 1,
  'Confirm Send' = 2,
  'Send Completed' = 3
}

class SendKitResultsModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      modalOverflowStyle: 'visible',
      sendOption: undefined,
      activeStepIndex: 0,
      confirmSendTitle: 'Confirm',
      showCountErrorModal: false,
      isCertifiedMail: false,
      isComplete: false,
      markCompleteOnResultLetterSent: true
    }
  }

  toggleIsCertifiedMail = () => {
    this.setState({
      isCertifiedMail: !this.state.isCertifiedMail
    })
  }

  toggleCountErrorModal = () => {
    this.setState({
      showCountErrorModal: !this.state.showCountErrorModal
    })
  }

  toggleComplete = () => {
    this.setState(p => ({ isComplete: !p.isComplete }))
  }

  toggleMarkCompleteResultLetterSent = () => {
    this.setState(p => ({
      markCompleteOnResultLetterSent: !p.markCompleteOnResultLetterSent
    }))
  }

  onSendOptionChanged = (sendOption: SendOption): void => {
    this.setState({ sendOption })
    if (sendOption === SendOption.PDF) {
      this.setState({ isCertifiedMail: false })
    }
  }

  isNextDisabled = (step: WizardState): boolean => {
    const { sendOption } = this.state

    if (step.activeStepIndex === SendStep['Send Options']) {
      return !sendOption
    }

    return false
  }

  getCompleteButtonText = (): string => {
    const { sendOption } = this.state
    return sendOption === SendOption.USPS
      ? 'Confirm & Send'
      : 'Start Processing'
  }

  onNextClicked = async ({
    activeStepIndex
  }: {
    activeStepIndex: number
  }): Promise<void> => {
    const targetStep = activeStepIndex + 1

    if (targetStep === SendStep['Confirm Send']) {
      this.updateConfirmSendTitle()
    }

    this.setState({ activeStepIndex: targetStep })
  }

  updateConfirmSendTitle = (): void => {
    const { sendOption } = this.state

    switch (sendOption) {
      case SendOption.USPS:
        this.setState({
          confirmSendTitle: 'Confirm'
        })
        return
      case SendOption.PDF:
        this.setState({
          confirmSendTitle: 'Your PDFs are Ready for Processing'
        })
        return
      default:
        return
    }
  }

  onCompleted = async ({
    sendKitResults,
    downloadKitResults
  }: {
    sendKitResults: SendKitResultsMutation
    downloadKitResults: any
  }): Promise<void> => {
    const { onSendKitResultsCompleted } = this.props

    const targetFunction =
      this.state.sendOption === SendOption.USPS
        ? sendKitResults
        : downloadKitResults

    try {
      if (this.state.sendOption === SendOption.PDF) {
        const batchId = await targetFunction()
        this.props.addBatchDownload({ batchId })
        this.props.startPolling()
        onSendKitResultsCompleted()
        this.toggleComplete()
      } else {
        await targetFunction()
        onSendKitResultsCompleted()
        this.toggleComplete()
      }
    } catch (error) {
      /* do nothing */
    }
  }

  render(): JSX.Element {
    const {
      accountId,
      isOpen,
      toggle,
      selectedKits,
      hasAutomatedLetterMailingFeature
    } = this.props
    const { sendOption, confirmSendTitle, isComplete } = this.state

    if (!isOpen && !isComplete) {
      return <></>
    }

    const completeButtonText = this.getCompleteButtonText()
    const kitIds = selectedKits.map(k => k.id) // todo: move to state
    const isDownload = sendOption === SendOption.PDF ? true : false

    return (
      <>
        <DataAccess
          accountId={accountId}
          kitIds={kitIds}
          isCertifiedMail={this.state.isCertifiedMail}
          isDownload={isDownload}
          markCompleteOnResultLetterSent={
            this.state.markCompleteOnResultLetterSent
          }>
          {({
            loading,
            numberOfLetters,
            allResultsCount,
            nonDetectCount,
            detectCount,
            exceedanceCount,
            countsError,
            sendKitResults,
            sendError,
            downloadKitResults,
            downloadError
          }) => {
            return (
              <Modal
                isOpen={isOpen}
                toggle={toggle}
                overflowStyle={this.state.modalOverflowStyle}
                closeIcon
                content={
                  isComplete ? (
                    <CompleteStep
                      numberOfLetters={numberOfLetters}
                      sendOption={sendOption}
                      toggle={toggle}
                    />
                  ) : loading ? (
                    <Loader />
                  ) : countsError ? (
                    <CountsError error={countsError} toggle={toggle} />
                  ) : (
                    <Wizard
                      onNextClicked={args => {
                        this.setModalOverflowStyle('auto')
                        if (args && typeof args.activeStepIndex === 'number') {
                          this.onNextClicked({
                            activeStepIndex: args.activeStepIndex
                          })
                        }
                      }}
                      onBackClicked={args => {
                        this.setModalOverflowStyle('visible')
                        if (args && typeof args.activeStepIndex === 'number') {
                          this.setState({
                            activeStepIndex: args.activeStepIndex - 1
                          })
                        }
                      }}
                      onCancel={toggle}
                      onCompleted={async () =>
                        this.onCompleted({ sendKitResults, downloadKitResults })
                      }
                      hideTimeline
                      style={{
                        wrapper: { border: 'none' },
                        footer: {
                          background: 'white',
                          justifyContent: 'center'
                        }
                      }}
                      completeButtonText={completeButtonText}
                      disableNext={this.isNextDisabled}
                      startingIndex={
                        sendError || downloadError
                          ? SendStep['Confirm Send']
                          : 0
                      }
                      steps={[
                        {
                          title: 'Letter Breakdown',
                          render: () => (
                            <>
                              <StepHeader
                                text="Send Results Letter"
                                Icon={LetterIcon}
                              />
                              <LetterBreakdown
                                numberOfLetters={numberOfLetters}
                                allResultsCount={allResultsCount}
                                nonDetectCount={nonDetectCount}
                                detectCount={detectCount}
                                exceedanceCount={exceedanceCount}
                                markCompleteOnResultLetterSent={
                                  this.state.markCompleteOnResultLetterSent
                                }
                                toggleMarkCompleteResultLetterSent={
                                  this.toggleMarkCompleteResultLetterSent
                                }
                              />
                            </>
                          )
                        },
                        {
                          title: 'Send Options',
                          render: () => (
                            <>
                              <StepHeader
                                text="Send Results Letter"
                                Icon={LetterIcon}
                              />
                              <SendOptions
                                numberOfLetters={numberOfLetters}
                                sendOption={sendOption}
                                onSendOptionChanged={this.onSendOptionChanged}
                                hasAutomatedLetterMailingFeature={
                                  hasAutomatedLetterMailingFeature
                                }
                                isCertifiedMail={this.state.isCertifiedMail}
                                toggleIsCertifiedMail={
                                  this.toggleIsCertifiedMail
                                }
                              />
                            </>
                          )
                        },
                        {
                          title: 'Confirm Send',
                          render: () => (
                            <>
                              <StepHeader
                                text={confirmSendTitle}
                                Icon={LetterIcon}
                              />
                              <ConfirmSend
                                numberOfLetters={numberOfLetters}
                                sendOption={sendOption as SendOption}
                                error={sendError || downloadError}
                              />
                            </>
                          )
                        }
                      ]}
                    />
                  )
                }
              />
            )
          }}
        </DataAccess>
      </>
    )
  }

  private setModalOverflowStyle = (style: 'visible' | 'auto') => {
    this.setState({
      modalOverflowStyle: style
    })
  }
}

const CompleteStep = ({
  numberOfLetters,
  sendOption,
  toggle
}: {
  numberOfLetters: number
  sendOption?: SendOption
  toggle: () => void
}) => (
  <>
    <StepHeader
      text="Completed!"
      Icon={CheckIcon}
      imageStyle={{ maxWidth: 52, maxHeight: 52 }}
    />
    <SendCompleted
      numberOfLetters={numberOfLetters}
      sendOption={sendOption as SendOption}
    />
    <div style={{ textAlign: 'center' }}>
      <Button variant="primary" onClick={() => toggle()}>
        Close Window
      </Button>
    </div>
  </>
)

const mapStateToProps = (state: ApplicationState) => ({
  isPolling: state.polling.isPolling
})

const mapDispatchToProps = {
  startPolling,
  addBatchDownload
}

export default connect(
  mapStateToProps as any,
  mapDispatchToProps
)(SendKitResultsModal)
