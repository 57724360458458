import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ToastOptions, toast } from 'react-toastify'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import { DEFAULT_TOAST } from 'src/utils/toast'

const reload = () => {
  if (caches) {
    caches.keys().then(names => {
      for (const name of names) {
        caches.delete(name)
      }
    })
  }

  window.location.reload()
}

const VERSION_KEY = '120_PWS_Version'
const CURRENT_VERSION = `${process.env.GIT_COMMIT}` || '0.0.0'
const TOAST: ToastOptions = {
  ...DEFAULT_TOAST,
  autoClose: false,
  onClick: reload,
  toastId: 'update-notification',
  type: 'success',
  icon: () => <FontAwesomeIcon color="white" icon={faStar} size="2x" />
}

const useVersionNotification = () => {
  const recordedVersion = localStorage.getItem(VERSION_KEY)
  localStorage.setItem(VERSION_KEY, CURRENT_VERSION)
  if (recordedVersion !== CURRENT_VERSION) {
    toast(<Message />, TOAST)
  }
}

const Message = () => (
  <Wrapper>
    <span>A new version is available.</span>
    <Button inverted onClick={reload}>
      Update Now
    </Button>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
`

export default useVersionNotification
