import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Loader } from '@120wateraudit/envirio-components'

import Table, { useTableState } from 'src/components/Table'
import { Column } from 'src/components/Table/types'
import Wrapper from 'src/components/Wrapper'
import {
  DownloadLinkCell,
  FileExtensionCell,
  FileSizeCell
} from 'src/modules/Attachments/Table/Cells'
import { useGetAttachmentsByTypeQuery } from 'src/services/attachments-api'
import { AttachableType, Attachment } from 'src/types/Attachment'
import { formatDate } from 'src/utils/formatDate'
import { useRowActions } from 'src/modules/Attachments/Table/hooks'

const getColumns = (): Column<Attachment>[] => {
  return [
    {
      Header: 'Name',
      key: 'originalFileName',
      sortable: false,
      accessor: attachment => <DownloadLinkCell {...attachment} />
    },
    {
      Header: 'Type',
      accessor: FileExtensionCell,
      key: 'mediaType',
      sortable: false
    },
    {
      Header: 'Size',
      accessor: FileSizeCell,
      key: 'fileSize',
      sortable: false
    },
    {
      Header: 'Uploaded On',
      key: 'lastModifiedOn',
      sortable: false,
      accessor: a => formatDate(a.lastModifiedOn)
    }
  ]
}

interface Props {
  includeChildren?: boolean
  relatedEntityId: number
  relatedEntityType: AttachableType
  title?: ReactNode
  uploadHeaderText?: string
}

export const MiniAttachmentTable = ({
  includeChildren,
  relatedEntityId,
  relatedEntityType
}: Props): JSX.Element => {
  const { params } = useTableState()

  const {
    data: queryData,
    isFetching,
    isLoading
  } = useGetAttachmentsByTypeQuery(
    {
      activeFilters: { ...params.activeFilters },
      includeChildren,
      page: params.page,
      pageSize: params.pageSize,
      relatedEntityId,
      relatedEntityType
    },
    { skip: !relatedEntityId }
  )

  const attachments = queryData?.data ?? []

  const { actions: rowActions } = useRowActions({relatedEntityId, relatedEntityType})

  const filteredActions = rowActions.filter(a => a.label === 'Download')

  return (
    <StyledWrapper topMargin={'0px'}>
      {(isLoading || isFetching) && <Loader />}
      {!isLoading && !isFetching && (
        <Table
          columns={getColumns()}
          data={attachments}
          loading={isLoading || isFetching}
          rowActions={[...filteredActions]}
        />
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Wrapper)`
  margin-right: 0px;
  margin-left: 0px;
`
