import { Button } from '@120wateraudit/envirio-components'
import filesize from 'filesize'
import pluralize from 'pluralize'
import React, { CSSProperties, DragEvent, FC, useCallback } from 'react'
import Dropzone, { DropzoneProps, ImageFile } from 'react-dropzone'
import styled from 'styled-components'

import {
  ALL_ACCEPTED_FILE_TYPES,
  getFriendlyFileTypeList
} from 'src/utils/fileUploads'
import { toastError } from 'src/utils/toast'

const displayError = (numberAllowed: number) => {
  toastError(
    `Only ${pluralize(
      'file',
      numberAllowed,
      true
    )} are accepted at a time. Please select fewer files.`
  )
}

interface Props {
  className?: string
  description?: string
  fileTypes?: string
  headerText?: string
  helpText?: null | string
  maxFiles?: number
  maxSize?: number
  maxWidth?: string
  multiple?: boolean
  style?: CSSProperties
  uploadFile: DropzoneProps['onDrop']
  uploading?: boolean
}

const FileUpload: FC<Props> = ({
  children,
  className,
  description = 'Drag and drop a file here or click to browse.',
  fileTypes,
  helpText,
  maxFiles = 5,
  maxSize,
  maxWidth,
  multiple = false,
  style,
  uploadFile,
  uploading = false
}) => {
  const onDrop = useCallback(
    (
      images: ImageFile[],
      rejected: ImageFile[],
      event: DragEvent<HTMLDivElement>
    ) => {
      if (multiple && images.length > maxFiles) {
        displayError(maxFiles)
      } else if (uploadFile) {
        uploadFile(images, rejected, event)
      }
    },
    [maxFiles, multiple, uploadFile]
  )
  return (
    <FlexWrapper className={className} maxWidth={maxWidth} style={style}>
      <ImageDropzone
        accept={fileTypes || ALL_ACCEPTED_FILE_TYPES}
        className="file-upload"
        disableClick={uploading}
        disabled={uploading}
        maxSize={maxSize}
        multiple={multiple}
        onDrop={onDrop}>
        {children || <DefaultBody description={description} />}
      </ImageDropzone>
      {helpText !== null && (
        <FileHelp
          fileTypes={fileTypes}
          helpText={helpText}
          maxFiles={maxFiles}
          maxSize={maxSize}
          multiple={multiple}
        />
      )}
    </FlexWrapper>
  )
}

const DefaultBody = ({ description }: { description: string }): JSX.Element => (
  <UploadBox>
    <TextDescription>{description}</TextDescription>
    <Button type="button" variant="primary">
      Browse
    </Button>
  </UploadBox>
)

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'inherit')};
`

const ImageDropzone = styled(Dropzone)`
  width: 90%;
  min-height: 164px;
  cursor: pointer;
  margin-bottom: 8px;
  background: #f9fafb;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #e5e7eb;
  padding: 10px;
`
const UploadBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const TextDescription = styled.p`
  margin-top: 0px;
  margin-bottom: 15px;
`

type FileHelpProps = Pick<
  Props,
  'fileTypes' | 'helpText' | 'maxFiles' | 'maxSize' | 'multiple'
>

const FileHelp = ({
  fileTypes,
  helpText,
  maxFiles = 5,
  maxSize,
  multiple
}: FileHelpProps): null | JSX.Element => {
  if (!helpText && !maxSize && !fileTypes && !multiple) {
    return null
  }

  return (
    <HelpWrapper>
      {helpText && <HelpText>{helpText}</HelpText>}
      {fileTypes && (
        <HelpText>
          Support file types: {getFriendlyFileTypeList(fileTypes.split(','))}
        </HelpText>
      )}
      {maxSize && <HelpText>Max file size: {filesize(maxSize, 1)}</HelpText>}
      {multiple && maxFiles > 1 && (
        <HelpText>Only {maxFiles} files at a time</HelpText>
      )}
    </HelpWrapper>
  )
}

const HelpWrapper = styled.div`
  padding: 10px 0;
  text-align: left;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 90%;
`

const HelpText = styled.p`
  font-size: 12px;
  line-height: 1.33333333;
  color: #6b7280;
`

export default FileUpload
