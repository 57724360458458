import React, { useMemo } from 'react'
import { Popup } from 'semantic-ui-react'
import { useToggle } from 'src/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { SupportLink } from 'src/components/SupportLink'
import { PRIMARY } from 'src/constants/colors'
import { FlatButton } from '@120wateraudit/waterworks'
import { faCommentDots, faFileLines } from '@fortawesome/free-solid-svg-icons'

const helpCenterUrl = 'https://pws-hc.120water.com/pws-helpcenter'

const useHelpControls = () => {
  const [popupMenu, toggle] = useToggle()

  return useMemo(
    () => ({
      popupMenu,
      toggle
    }),
    [popupMenu, toggle]
  )
}

const Help = ({ icon, size }): JSX.Element => {
  const { popupMenu, toggle } = useHelpControls()

  return (
    <Popup
      on="click"
      onClose={toggle}
      onOpen={toggle}
      open={popupMenu}
      style={{ marginLeft: '5px' }}
      trigger={
        <Wrapper>
          <FontAwesomeIcon icon={icon} size={size} />
        </Wrapper>
      }>
      <PopupButton borderless>
        <SupportLink url={helpCenterUrl}>
          <FontAwesomeIcon icon={faFileLines} size={size} />
          <span>Help Center</span>
        </SupportLink>
      </PopupButton>

      <PopupButton borderless>
        <SupportLink>
          <FontAwesomeIcon icon={faCommentDots} size={size} />
          <span>Contact Support</span>
        </SupportLink>
      </PopupButton>
    </Popup>
  )
}

const Wrapper = styled(FlatButton)`
  width: 100%;
  height: 44px;
  display: flex;

  &.flat {
    box-shadow: none;
  }
  &:hover {
    background-color: transparent !important;
    color: ${PRIMARY};
  }
  a {
    display: flex;
    color: unset;
  }
`

const PopupButton = styled(FlatButton)`
  width: 100%;
  height: 44px;
  display: flex;

  & svg:first-child {
    margin-right: 12px;
  }
  &:hover {
    background-color: transparent !important;
    color: ${PRIMARY};
  }
  a {
    display: flex;
    color: unset;
  }
`

export default Help
