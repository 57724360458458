import { colors, fontSize } from '@120wateraudit/envirio-components'
import React, { CSSProperties } from 'react'
import { Message, SemanticCOLORS } from 'semantic-ui-react'
import styled from 'styled-components'

import { ApolloError } from '@apollo/client'
import { ApiError } from '../../types/ApiError'
import {
  getErrorMessageFromResponse,
  getRowErrorMessagesFromResponse
} from '../../utils/apiErrors'
import { getErrorMessagesFromResponse as getGqlErrorMessageFromResponse } from '../../utils/graphQLErrors'
import { SupportLink } from '../SupportLink'

const hasCode = (error: ApiError): boolean => {
  return error &&
    error.response &&
    error.response.data &&
    error.response.data.code
    ? true
    : false
}

const getErrorFromCode = (
  error: ApiError | ApolloError,
  numberOfRowsWhenTruncating?: null | number
): JSX.Element => {
  const code = (error as any).response.data.code
  switch (code) {
    case 'ShippingVendorNotSet':
      return (
        <Message negative>
          <Message.Header>Something went wrong</Message.Header>
          <p>
            Unable to order kits because the 120WaterAudit shipping account is
            not setup yet. Please contact <SupportLink>support</SupportLink>.
          </p>
        </Message>
      )
    default:
      return getErrorFromResponse(
        error,
        'Something went wrong',
        numberOfRowsWhenTruncating
      )
  }
}

const getErrorFromResponse = (
  error: ApiError | ApolloError,
  header = 'Something went wrong',
  numberOfRowsWhenTruncating?: null | number
): JSX.Element => {
  const errorGenerator =
    error.message && error.message.startsWith('GraphQL')
      ? getGqlErrorMessageFromResponse
      : getErrorMessageFromResponse

  return (
    <Message negative>
      <Message.Header>{header}</Message.Header>
      {numberOfRowsWhenTruncating ? (
        <TruncatedText
          style={{
            WebkitLineClamp: `${numberOfRowsWhenTruncating}`,
            LineClamp: `${numberOfRowsWhenTruncating}`
          }}>
          {errorGenerator(error)}
        </TruncatedText>
      ) : (
        <p>{errorGenerator(error)}</p>
      )}
    </Message>
  )
}

const getErrorRowsFromResponse = (
  error: ApiError | ApolloError,
  header = 'Something has gone wrong'
): JSX.Element => {
  const errorMessages = getRowErrorMessagesFromResponse(error)
  return (
    <Message negative>
      <Message.Header>{header}</Message.Header>
      {errorMessages.map((errorMessage, i) => {
        return <p key={`ErrorRow${i}`}>{errorMessage}</p>
      })}
    </Message>
  )
}

export const TritonError = ({
  className,
  header = 'Something has gone wrong',
  messages,
  style,
  color
}: {
  className?: string
  header?: string
  messages: string[]
  style?: CSSProperties
  color?: SemanticCOLORS
}) => {
  return (
    <Message className={className} negative style={style} color={color}>
      <Message.Header>{header}</Message.Header>
      {messages.map((message, index) => (
        <p key={`error_msg_${index}`}>{message}</p>
      ))}
    </Message>
  )
}

export const Error = ({
  error,
  errorRows = false,
  header,
  numberOfRowsWhenTruncating
}: {
  error: ApiError | ApolloError
  errorRows?: boolean
  header?: string
  numberOfRowsWhenTruncating?: null | number
}): JSX.Element => {
  if (hasCode(error)) {
    return getErrorFromCode(error, numberOfRowsWhenTruncating)
  }

  return errorRows
    ? getErrorRowsFromResponse(error)
    : getErrorFromResponse(error, header, numberOfRowsWhenTruncating)
}

export const GQLErrorMessage = styled.div`
  text-align: center;
  font-size: ${fontSize.body};
  color: ${colors.error};
  font-weight: 300;
  padding: 1rem;
`

const TruncatedText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`
