import React, { FC, useState } from 'react'
import { SamplingEventAnalyteFragment } from 'src/components/Communications/CreateOrUpdateCommunication/OptionsCard'
import AnalyteResultExpandableComponent from './AnalyteResultExpandableComponent'

interface Props {
  customFields: unknown
  sampleAnalytes: SamplingEventAnalyteFragment[]
  onClick: (property: string) => void
}

export type AnalyteResultOption = {
  id: number
  upperLimit: number
  displayName: string
  analyteId: number
  name: string
  result: number
  unit: string
  lowerLimit: number
  reportingLimit: number
  detectionLimit: number
  isExceedance: boolean
  belowDetectionLimit: boolean
}

const camelCaseAnalyteName = (analyteName: string) =>
  analyteName.substring(0, 1).toLowerCase() + analyteName.substring(1)

const buildResultOptions = (
  sampleAnalytes: SamplingEventAnalyteFragment[],
  customFields: unknown
) => {
  const customFieldsToAdd = typeof customFields === 'object' ? customFields : {}
  return sampleAnalytes.map(sa => ({
    id: 123,
    analyteId: sa.analyte.id,
    displayName: sa.analyte.displayName,
    name: sa.analyte.name,
    result: 1.0,
    unit: 'ppb',
    upperLimit: 2.0,
    lowerLimit: 1.0,
    reportingLimit: 1.5,
    detectionLimit: 1.0,
    isExceedance: false,
    belowDetectionLimit: true,
    ...customFieldsToAdd
  }))
}

const SampleArrayComponent: FC<Props> = ({
  customFields,
  onClick,
  sampleAnalytes
}) => {
  const [selectedAnalyte, setSelectedAnalyte] = useState<
    AnalyteResultOption | undefined
  >(undefined)
  const resultAnalytes: (AnalyteResultOption & typeof customFields)[] =
    buildResultOptions(sampleAnalytes, customFields)

  const handleSelectAnalyte = (analyte: AnalyteResultOption) => {
    if (analyte.analyteId === selectedAnalyte?.analyteId) {
      return setSelectedAnalyte(undefined)
    }

    setSelectedAnalyte(analyte)
  }

  return (
    <>
      {resultAnalytes.map((analyte, index) => (
        <AnalyteResultExpandableComponent
          key={`result-analytes-${analyte.analyteId}-${index}`}
          analyteResult={analyte}
          onClick={p => onClick(`${camelCaseAnalyteName(analyte.name)}.${p}`)}
          isSelected={analyte.analyteId === selectedAnalyte?.analyteId}
          onSelect={() => handleSelectAnalyte(analyte)}
        />
      ))}
    </>
  )
}

export default SampleArrayComponent
