import {
  Button,
  Icons,
  colors,
  fontSize,
  spacing
} from '@120wateraudit/envirio-components'
import { ApolloError } from '@apollo/client'
import * as React from 'react'

import { Error } from 'src/components/Error'
import { CenteringWrapper } from 'src/components/Layout'
import { Link } from 'src/components/Locations/noLocationDetails'
import { MetricCircle } from 'src/components/MetricCircle'
import { getErrorMessageFromResponse } from 'src/utils/apiErrors'
import { pushRoute } from 'src/utils/navigation'
import styled from 'styled-components'

interface Props {
  error: ApolloError
  toggle: () => void
}

export const CountsError = ({ error, toggle }: Props) => {
  const { text, title } = getErrorComponents(error, toggle)

  return (
    <>
      <CenteringWrapper>
        <MetricCircle
          circleIcon={<Icons.Error />}
          color={'rgba(239,9,0,0.1)'}
          iconColor={'rgba(239,9,0,1)'}
          style={{ maxWidth: '46px', maxHeight: '460px' }}
        />
      </CenteringWrapper>
      <ModalHeader>{title}</ModalHeader>
      <Wrapper>
        <CountLabel>{text}</CountLabel>

        <ButtonWrapper>
          <Button
            fullWidth
            onClick={() => goToCommunications(toggle)}
            style={{ marginBottom: '10px' }}
            variant="primary">
            Visit Communications
          </Button>
          <Button fullWidth onClick={() => toggle()} variant="default">
            Close Window
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </>
  )
}

const getErrorComponents = (error: ApolloError, toggle: () => void) => {
  const parsedError = getErrorMessageFromResponse(error)

  if (parsedError.toLowerCase().includes('templates')) {
    return {
      text: (
        <>
          <Error error={error} />
          <br />
          <Link href="#" onClick={() => goToCommunications(toggle)}>
            Please visit Communications
          </Link>{' '}
          to assign templates to the relevant Events.
        </>
      ),
      title: 'Missing Communications'
    }
  }

  return {
    text: <Error error={error} />,
    title: 'Communications Error'
  }
}

const goToCommunications = (toggle: () => void) => {
  toggle()
  pushRoute('/communications')
}

const ButtonWrapper = styled.div`
  max-width: 225px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const CountLabel = styled.div`
  color: ${colors.black50};
  padding-bottom: ${spacing.tiny};
  font-size: ${fontSize.body};
  text-align: center;
  max-width: 300px;
`

const ModalHeader = styled.h5`
  text-align: center;
  padding-bottom: ${spacing.tiny};
  margin-bottom: 0;
`
