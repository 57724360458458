import * as Yup from 'yup'

const historicalRecordsReviewSchema = Yup.object().shape(
  {
    inventoryMethodsPart1PreviousMaterialsEvaluation: Yup.string().when(
      [
        'inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes',
        'inventoryMethodsPart1WaterSystemRecords',
        'inventoryMethodsPart1DistributionSystemInspectionsAndRecords',
        'inventoryMethodsPart1otherRecords'
      ],
      {
        is: (
          inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes,
          inventoryMethodsPart1WaterSystemRecords,
          inventoryMethodsPart1DistributionSystemInspectionsAndRecords,
          inventoryMethodsPart1otherRecords
        ) =>
          !inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes &&
          !inventoryMethodsPart1WaterSystemRecords &&
          !inventoryMethodsPart1DistributionSystemInspectionsAndRecords &&
          !inventoryMethodsPart1otherRecords,
        then: () =>
          Yup.string().required({
            inventoryMethodsPart1PreviousMaterialsEvaluation: 'Required'
          })
      }
    ),
    inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes: Yup.string().when(
      [
        'inventoryMethodsPart1PreviousMaterialsEvaluation',
        'inventoryMethodsPart1WaterSystemRecords',
        'inventoryMethodsPart1DistributionSystemInspectionsAndRecords',
        'inventoryMethodsPart1otherRecords'
      ],
      {
        is: (
          inventoryMethodsPart1PreviousMaterialsEvaluation,
          inventoryMethodsPart1WaterSystemRecords,
          inventoryMethodsPart1DistributionSystemInspectionsAndRecords,
          inventoryMethodsPart1otherRecords
        ) =>
          !inventoryMethodsPart1PreviousMaterialsEvaluation &&
          !inventoryMethodsPart1WaterSystemRecords &&
          !inventoryMethodsPart1DistributionSystemInspectionsAndRecords &&
          !inventoryMethodsPart1otherRecords,
        then: () =>
          Yup.string().required({
            inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes: 'Required'
          })
      }
    ),
    inventoryMethodsPart1WaterSystemRecords: Yup.string().when(
      [
        'inventoryMethodsPart1PreviousMaterialsEvaluation',
        'inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes',
        'inventoryMethodsPart1DistributionSystemInspectionsAndRecords',
        'inventoryMethodsPart1otherRecords'
      ],
      {
        is: (
          inventoryMethodsPart1PreviousMaterialsEvaluation,
          inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes,
          inventoryMethodsPart1DistributionSystemInspectionsAndRecords,
          inventoryMethodsPart1otherRecords
        ) =>
          !inventoryMethodsPart1PreviousMaterialsEvaluation &&
          !inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes &&
          !inventoryMethodsPart1DistributionSystemInspectionsAndRecords &&
          !inventoryMethodsPart1otherRecords,
        then: () =>
          Yup.string().required({
            inventoryMethodsPart1WaterSystemRecords: 'Required'
          })
      }
    ),
    inventoryMethodsPart1DistributionSystemInspectionsAndRecords:
      Yup.string().when(
        [
          'inventoryMethodsPart1PreviousMaterialsEvaluation',
          'inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes',
          'inventoryMethodsPart1WaterSystemRecords',
          'inventoryMethodsPart1otherRecords'
        ],
        {
          is: (
            inventoryMethodsPart1PreviousMaterialsEvaluation,
            inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes,
            inventoryMethodsPart1WaterSystemRecords,
            inventoryMethodsPart1otherRecords
          ) =>
            !inventoryMethodsPart1PreviousMaterialsEvaluation &&
            !inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes &&
            !inventoryMethodsPart1WaterSystemRecords &&
            !inventoryMethodsPart1otherRecords,
          then: () =>
            Yup.string().required({
              inventoryMethodsPart1DistributionSystemInspectionsAndRecords:
                'Required'
            })
        }
      ),
    inventoryMethodsPart1otherRecords: Yup.string().when(
      [
        'inventoryMethodsPart1PreviousMaterialsEvaluation',
        'inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes',
        'inventoryMethodsPart1WaterSystemRecords',
        'inventoryMethodsPart1DistributionSystemInspectionsAndRecords'
      ],
      {
        is: (
          inventoryMethodsPart1PreviousMaterialsEvaluation,
          inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes,
          inventoryMethodsPart1WaterSystemRecords,
          inventoryMethodsPart1DistributionSystemInspectionsAndRecords
        ) =>
          !inventoryMethodsPart1PreviousMaterialsEvaluation &&
          !inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes &&
          !inventoryMethodsPart1WaterSystemRecords &&
          !inventoryMethodsPart1DistributionSystemInspectionsAndRecords,
        then: () =>
          Yup.string().required({
            inventoryMethodsPart1otherRecords: 'Required'
          })
      }
    )
  },
  [
    [
      'inventoryMethodsPart1PreviousMaterialsEvaluation',
      'inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes'
    ],
    [
      'inventoryMethodsPart1PreviousMaterialsEvaluation',
      'inventoryMethodsPart1WaterSystemRecords'
    ],
    [
      'inventoryMethodsPart1PreviousMaterialsEvaluation',
      'inventoryMethodsPart1DistributionSystemInspectionsAndRecords'
    ],
    [
      'inventoryMethodsPart1PreviousMaterialsEvaluation',
      'inventoryMethodsPart1otherRecords'
    ],
    [
      'inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes',
      'inventoryMethodsPart1WaterSystemRecords'
    ],
    [
      'inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes',
      'inventoryMethodsPart1DistributionSystemInspectionsAndRecords'
    ],
    [
      'inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes',
      'inventoryMethodsPart1otherRecords'
    ],
    [
      'inventoryMethodsPart1WaterSystemRecords',
      'inventoryMethodsPart1DistributionSystemInspectionsAndRecords'
    ],
    [
      'inventoryMethodsPart1WaterSystemRecords',
      'inventoryMethodsPart1otherRecords'
    ],
    [
      'inventoryMethodsPart1DistributionSystemInspectionsAndRecords',
      'inventoryMethodsPart1otherRecords'
    ]
  ]
)

const identifyingServiceLineMaterialDuringNormalOperationsSchema = Yup.object().shape(
  {
    inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation:
      Yup.string().when(
        ['inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices'],
        {
          is: inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices =>
            inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices &&
            inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices.includes(
              'Other'
            ),
          then: () =>
            Yup.string().required({
              inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation:
                'Required'
            })
        }
      )
  },
  [
    [
      'inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices',
      'inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation'
    ]
  ]
)

const serviceLineInvestigationsSchema = Yup.object().shape(
  {
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices: Yup.array()
      .min(1, {
        inventoryMethodsPart3Q1ServiceLineInvestigationChoices: 'Required'
      })
      .required({
        inventoryMethodsPart3Q1ServiceLineInvestigationChoices: 'Required'
      }),
    inventoryMethodsPart3InterpolationExplanation: Yup.string().when(
      ['inventoryMethodsPart3Q1ServiceLineInvestigationChoices'],
      {
        is: inventoryMethodsPart3Q1ServiceLineInvestigationChoices =>
          inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
          inventoryMethodsPart3Q1ServiceLineInvestigationChoices.includes(
            'Interpolation'
          ),
        then: () =>
          Yup.string().required({
            inventoryMethodsPart3InterpolationExplanation: 'Required'
          })
      }
    ),
    inventoryMethodsPart3SequentialExplanation: Yup.string().when(
      ['inventoryMethodsPart3Q1ServiceLineInvestigationChoices'],
      {
        is: inventoryMethodsPart3Q1ServiceLineInvestigationChoices =>
          inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
          inventoryMethodsPart3Q1ServiceLineInvestigationChoices.includes(
            'Water Quality Sampling - Sequential'
          ),
        then: () =>
          Yup.string().required({
            inventoryMethodsPart3SequentialExplanation: 'Required'
          })
      }
    ),
    inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation:
      Yup.string().when(
        ['inventoryMethodsPart3Q1ServiceLineInvestigationChoices'],
        {
          is: inventoryMethodsPart3Q1ServiceLineInvestigationChoices =>
            inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
            inventoryMethodsPart3Q1ServiceLineInvestigationChoices.includes(
              'Other'
            ),
          then: () =>
            Yup.string().required({
              inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation:
                'Required'
            })
        }
      )
  },
  [
    [
      'inventoryMethodsPart3Q1ServiceLineInvestigationChoices',
      'inventoryMethodsPart3InterpolationExplanation'
    ],
    [
      'inventoryMethodsPart3Q1ServiceLineInvestigationChoices',
      'inventoryMethodsPart3SequentialExplanation'
    ],
    [
      'inventoryMethodsPart3Q1ServiceLineInvestigationChoices',
      'inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation'
    ]
  ]
)

const arizonaSchema = historicalRecordsReviewSchema
  .concat(identifyingServiceLineMaterialDuringNormalOperationsSchema)
  .concat(serviceLineInvestigationsSchema)

export const validateHistoricalRecordsReviewSchema = async values => {
  return historicalRecordsReviewSchema
    .validate(values, { abortEarly: false })
    .then(() => {
      return undefined
    })
    .catch(err => {
      return Object.assign({}, ...err.errors)
    })
}

export const validateIdentifyingServiceLineMaterialDuringNormalOperationsSchema = async values => {
  return identifyingServiceLineMaterialDuringNormalOperationsSchema
    .validate(values, { abortEarly: false })
    .then(() => {
      return undefined
    })
    .catch(err => {
      return Object.assign({}, ...err.errors)
    })
}

export const validateServiceLineInvestigationsSchema = async values => {
  return serviceLineInvestigationsSchema
    .validate(values, { abortEarly: false })
    .then(() => {
      return undefined
    })
    .catch(err => {
      return Object.assign({}, ...err.errors)
    })
}

// method to trigger validation and return the error message
export const validateArizona = async values => {
  return arizonaSchema
    .validate(values, { abortEarly: false })
    .then(() => {
      return undefined
    })
    .catch(err => {
      return Object.assign({}, ...err.errors)
    })
}
