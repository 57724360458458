import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
  addBatchDownload,
  removeBatchDownload
} from '../actions/batchDownloads'

export interface DownloadState {
  pendingDownloads: number[]
}

const initialDownloadState = {
  pendingDownloads: []
}

const reducer = reducerWithInitialState<DownloadState>(initialDownloadState)
  .case(addBatchDownload, (state, { batchId }: { batchId: number }) => {
    return {
      ...state,
      pendingDownloads: [...state.pendingDownloads, batchId]
    }
  })
  .case(
    removeBatchDownload,
    (state, { batchId }: { batchId: number | undefined }) => ({
      ...state,
      pendingDownloads: [
        ...state.pendingDownloads.filter(batch => batch !== batchId)
      ]
    })
  )

export default reducer
