import React, { useState } from 'react'
import { Dropdown } from '@120wateraudit/waterworks'
import { Loader } from '@120wateraudit/envirio-components'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { Submission } from 'src/types/Submission'
import CommentSection from './CommentSection'
import { useGetAllSubmissionPeriodCommentsQuery } from 'src/services/state-submission-api'
import CommentForm from './CommentForm'
import { EmptyStateCard } from 'src/components/EmptyStateCard'
import { useHasMoreThanReadPermission } from 'src/hooks'

interface SubmissionPeriodActivityProps {
  submission: Submission
}

const NoComments = () => (
  <EmptyStateCard
    icon={<Icon name="comments" size="huge" />}
    style={{
      paddingTop: '0px',
      paddingBottom: '10px'
    }}
    title="No Comments at this time."
  />
)

const SubmissionPeriodActivityModal = ({
  submission
}: SubmissionPeriodActivityProps): JSX.Element => {
  const userHasPermission = useHasMoreThanReadPermission()
  const { pwsId, submissionPeriod, id: submissionId } = submission
  const { id: submissionPeriodId } = submissionPeriod
  const [activityFilter, setActivityFilter] = useState('all')

  const { data: response, isFetching } = useGetAllSubmissionPeriodCommentsQuery(
    {
      submissionPeriodId,
      pwsId
    }
  )

  const comments = response?.data ?? []

  if (isFetching) {
    return <Loader />
  }

  return (
    <ModalContentContainer>
      {comments.length > 0 && (
        <Dropdown
          style={{ gap: '0px' }}
          onChange={event => setActivityFilter(event.target.value)}
          options={[
            { text: 'Show All Activity', value: 'all' },
            { text: 'Show Comments Only', value: 'comment' },
            { text: 'Show Notifications Only', value: 'notification' }
          ]}
          value={activityFilter}
        />
      )}
      {comments.length === 0 ? (
        <NoComments />
      ) : (
        <CommentSection activities={comments} activityFilter={activityFilter} />
      )}
      {userHasPermission && (
        <CommentForm
          pwsId={pwsId}
          submissionPeriodId={submissionPeriodId}
          submissionId={submissionId}
        />
      )}
    </ModalContentContainer>
  )
}

const ModalContentContainer = styled.div`
  margin: 2rem;
`

export default SubmissionPeriodActivityModal
