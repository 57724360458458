import {
  PWSCustomFieldEntity
} from '@120wateraudit/envirio-components/dist/models'
import React from 'react'
import { FilterDefinition, Props, RowData } from './types'
import { useGetDefinitionsQuery } from "src/services/custom-fields-api-v2";
import { CustomFieldDefinition } from "@120wateraudit/custom-fields-ui-components";
import { getFilter } from "src/components/Table/getCustomFieldFilters";

function combineFilters(
  filters: FilterDefinition[],
  customFieldDefinitions: CustomFieldDefinition[]
): FilterDefinition[] {
  if (customFieldDefinitions.length <= 0) {
    return filters
  }

  const customFieldFilters: FilterDefinition[] = customFieldDefinitions.map(
    definition => getFilter(definition)
  )
  return [...filters, ...customFieldFilters]
}

type PropsAreEqual<D extends RowData> = (
  prev: Readonly<Props<D>>,
  next: Readonly<Props<D>>
) => boolean

function withCustomFields<D extends RowData>(
  Component: {
    displayName?: string
    (props: Props<D>): Exclude<React.ReactNode, undefined>
  },
  entityName: PWSCustomFieldEntity,
  propsAreEqual?: PropsAreEqual<D> | false,
  componentName = Component.displayName ?? Component.name
): { displayName: string; (props: Props<D>): JSX.Element } {
  function WithCustomFields(props: Props<D>) {
    const { data } = useGetDefinitionsQuery(entityName)
    const definitions = data || []
    if (!props.filterable) {
      // If the component hasn't been made filterable we should exit early.
      return Component(props) as JSX.Element
    }

    const combinedFilters = combineFilters(props.filters || [], definitions)
    return Component({
      ...props,
      filters: combinedFilters
    }) as JSX.Element
  }

  WithCustomFields.displayName = `withCustomFields(${componentName})`
  const wrappedComponent =
    propsAreEqual === false
      ? WithCustomFields
      : React.memo(WithCustomFields, propsAreEqual)
  return wrappedComponent as typeof WithCustomFields
}

export default withCustomFields
