import { useAuth0 } from '@auth0/auth0-react'
import React, { FC } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import App from './App'
import Login from './Login'
import AppSkeleton from './Skeleton'
import UserProvider from './UserProvider'
import MissingUser from "src/router/MissingUser";

const Routes = (): JSX.Element => {

  return (
    <Switch>
      <Route path="/login" >
        <Login />
      </Route>
      <Route path="/error" >
        <MissingUser />
      </Route>
      <Route
        render={() => (
          <RequireAuth>
            <App />
          </RequireAuth>
        )}
      />
    </Switch>
  )
}

const RequireAuth: FC<{ redirectTo?: string }> = ({
  children,
  redirectTo = '/login'
}) => {
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return <AppSkeleton />
  }
  if (queryParameters.has("error") && queryParameters.get("error") === "unauthorized") {
    return <Redirect
        to={{ pathname: '/error', state: { referrer: location.pathname } }}
    />
  }

  return isAuthenticated ? (
    <UserProvider>{children}</UserProvider>
  ) : (
    <Redirect
      to={{ pathname: redirectTo, state: { referrer: location.pathname + location.search } }}
    />
  )
}

export default Routes
