import React from 'react'
import styled from 'styled-components'

import { ValidFilterValue } from '../types'
import { FilterInput, FilterLabel } from './shared'
import RemoveFilterButton from './RemoveFilterButton'

interface Props {
  className?: string
  defaultValue?: ValidFilterValue
  label: string
  onChange(value?: null | ValidFilterValue): void
  removeFilter: () => void
  value?: ValidFilterValue
}

const TextMatchFilter = ({
  className,
  label,
  onChange,
  value,
  removeFilter
}: Props): null | JSX.Element => {
  return (
    <FilterLabel>
      <span>{label}</span>
      <FilterInput isEnabled onFilterChanged={onChange} value={value} key={ value?.toString() }/>
      <StyledRemoveFilterButton
        className={className}
        removeFilter={removeFilter}
      />
    </FilterLabel>
  )
}

const StyledRemoveFilterButton = styled(RemoveFilterButton)`
  &&& {
    margin-left: 4.57px;
  }
`

export default TextMatchFilter
