import moment from 'moment'

const TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short'
}

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
}

const FULL_OPTIONS: Intl.DateTimeFormatOptions = {
  ...TIME_OPTIONS,
  ...DATE_OPTIONS
}

const SHORT_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
}

// /**
//  * These formatters can be re-used to format any date or time (or both) into
//  * a format appropriate for our platform.
//  *
//  * datetimeFormatter.format(new Date())
//  * datetimeFormatter.format(moment().toDate())
//  */
export const datetimeFormatter = new Intl.DateTimeFormat('en-US', FULL_OPTIONS)
export const dateFormatter = new Intl.DateTimeFormat('en-US', DATE_OPTIONS)
export const shortDateFormatter = new Intl.DateTimeFormat(
  'en-US',
  SHORT_DATE_OPTIONS
)
export const timeFormatter = new Intl.DateTimeFormat('en-US', TIME_OPTIONS)

export const parseDateString = (dateStr?: null | string): Date =>
  dateStr ? new Date(dateStr) : new Date()

export const formatDateTime = (dateStr?: null | string): string =>
  dateStr ? datetimeFormatter.format(new Date(dateStr)) : '--'

export const formatDate = (dateStr?: null | string): string =>
  dateStr ? moment.utc(dateStr).format('MM-DD-YYYY') : '--'

export const formatTime = (dateStr?: null | string): string =>
  dateStr ? timeFormatter.format(new Date(dateStr)) : '--'

export const formatShortDate = (dateStr?: null | string) => {
  return dateStr ? shortDateFormatter.format(new Date(dateStr)) : '--'
}

// deprecated 2021/10/08
export const formatUTC = (
  date: string,
  format = 'MMMM DD, YYYY',
  emptyValue = '--'
) => (date ? moment(date).utc().format(format) : emptyValue)
