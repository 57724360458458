import actionCreatorFactory from 'typescript-fsa'
import {
  WeeklyExceedances,
  WeeklySamples,
  WeeklyStatsDatasetNames
} from '../reducers/weeklyStats'
const action = actionCreatorFactory()

export const fetchWeeklyStatsDatasetsRequest = action<{
  datasetNames: WeeklyStatsDatasetNames[]
  accountId: number
}>('FETCH_WEEKLY_STATS_DATASETS_REQUEST')

export const fetchWeeklyStatsDatasetsSuccess = action<{
  datasetNames: WeeklyStatsDatasetNames[]
  datasets: {
    [key in WeeklyStatsDatasetNames]: WeeklyExceedances | WeeklySamples
  }
}>('FETCH_WEEKLY_STATS_DATASETS_SUCCESS')

export const fetchWeeklyStatsDatasetsFailure = action<{
  datasetNames: WeeklyStatsDatasetNames[]
  error: string
}>('FETCH_WEEKLY_STATS_DATASETS_FAILURE')
