import { Button } from '@120wateraudit/envirio-components'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { Form as SUIForm } from 'semantic-ui-react'

import ProgramActions from 'src/actions/programs'
import { Error } from 'src/components/Error'
import { DATE_FORMAT } from 'src/components/Forms/Datepicker'
import Modal from 'src/modules/Modal/ModalContents'
import { useAccount } from 'src/router/UserProvider'
import { getProgramError } from 'src/selectors/error'
import { Program } from 'src/types/Programs'
import { pushRoute } from 'src/utils/navigation'
import EditForm, { Values, endDateCalculator, validate } from '../Form'

const useUpdateProgram = (programId: number, onClose: () => void) => {
  const { id: accountId } = useAccount()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(ProgramActions.errorActions.clear())
  }, [dispatch])

  return useCallback(
    (values: Values) => {
      dispatch(
        ProgramActions.updateActions.updateRequest({
          accountId,
          callback: onClose,
          id: programId,
          program: {
            endsOn: moment(values.endsOn).toISOString(),
            name: values.name,
            startsOn: moment(values.startsOn).toISOString()
          }
        })
      )
    },
    [dispatch, accountId, onClose, programId]
  )
}

const useDeleteProgram = (program: Program, onClose: () => void) => {
  const { id: accountId } = useAccount()
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(
      ProgramActions.deleteActions.deleteRequest({
        accountId,
        id: program.id,
        program,
        callback: () => {
          onClose()
          pushRoute('/programs')
        }
      })
    )
  }, [dispatch, accountId, onClose, program])
}

const getInitialValues = (program: Program): Values => {
  const { replacementProgramSettings: settings } = program
  return {
    assetTypeId: settings?.assetTypeId ?? 1,
    doesPostSampling: settings?.doesPostSampling ?? true,
    doesPreSampling: settings?.doesPreSampling ?? true,
    endsOn: moment(program.endsOn).format(DATE_FORMAT),
    name: program.name,
    programTypeId: program.programTypeId,
    replacementIntervalMonths: settings?.replacementIntervalMonths ?? 12,
    startsOn: moment(program.startsOn).format(DATE_FORMAT),
    status: program.status
  }
}

interface Props {
  program: Program
  onClose(): void
}

const EditProgramModal = ({ onClose, program }: Props): JSX.Element => {
  const updateProgram = useUpdateProgram(program.id, onClose)
  const deleteProgram = useDeleteProgram(program, onClose)
  const programError = useSelector(getProgramError)
  const programType = program.programType?.name ?? ''

  return (
    <Form
      decorators={programType === 'REPLACEMENT' ? [endDateCalculator] : []}
      initialValues={getInitialValues(program)}
      onSubmit={updateProgram}
      render={({
        handleSubmit,
        pristine,
        valid,
        submitting,
        errors,
        touched
      }) => {
        const cancelAction = (
          <Button disabled={submitting} onClick={onClose} type="button">
            Cancel
          </Button>
        )
        const saveAction = (
          <Button
            disabled={!valid || submitting}
            variant="primary"
            type="submit">
            {submitting ? 'Saving…' : 'Save'}
          </Button>
        )
        const deleteAction = (
          <Button
            disabled={submitting}
            key="deleteProgram"
            onClick={deleteProgram}
            variant="error"
            type="button">
            Delete Program
          </Button>
        )

        return (
          <SUIForm onSubmit={handleSubmit}>
            <Modal
              cancelAction={cancelAction}
              icon={faFileInvoice}
              primaryAction={saveAction}
              secondaryActions={[deleteAction]}
              title={`${programType} Program Details`}>
              <EditForm
                programType={programType}
                type="edit"
                pristine={pristine}
                errors={errors}
                touched={touched}
              />
              {programError && <Error error={programError} />}
            </Modal>
          </SUIForm>
        )
      }}
      validate={validate}
    />
  )
}

export default EditProgramModal
