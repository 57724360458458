import {difference, keys, omit} from 'lodash'
import pluralize from 'pluralize'
import React, {useCallback, useContext} from 'react'
import {toast} from 'react-toastify'
import {Button, ButtonProps, Icon, IconProps, Popup} from 'semantic-ui-react'
import styled from 'styled-components'
import {useToggle} from 'react-use'
import {FilterDefinition, ViewPayload} from 'src/components/Table/types'
import {AQUA_BLUE} from 'src/constants/colors'
import {ModalContext} from 'src/modules/Modal'
import {SavedView} from 'src/types/SavedView'
import {LONG_TOAST} from 'src/utils/toast'
import {titleCase} from 'src/utils/titleCase'
import TableContext from '../context'
import {FilterOption, FilterOptions} from '../utils'
import CreateViewButton from './CreateViewButton'
import SavedViewOption from './SavedViewOption'
import {useTableViews} from './dataAccess'
import { useGetAllDefinitionsQuery } from "src/services/custom-fields-api-v2";
import { CustomFieldDefinition } from "@120wateraudit/custom-fields-ui-components";

const removeInvalidFilters = (
  data: ViewPayload,
  validFilters: FilterDefinition[],
  customFieldDefinitions?: CustomFieldDefinition[]
) => {
  const activeViewFilterKeys: string[] = keys(data.activeFilters || {})
  const keysMinusCustomField = activeViewFilterKeys.filter(
    f => f !== 'customFields'
  )

  const parsedCustomFields =
    (data.activeFilters.customFields &&
      JSON.parse(data.activeFilters.customFields)) ||
    []

  const customFieldFilters = validFilters
    .filter(f => f.customField)
    .map(f => f.customField && f.customField.id)

  const validCustomFieldFilters = getValidCustomFields(data.activeFilters.customFields, customFieldDefinitions)

  const invalidCustomFieldFilters = parsedCustomFields.filter(
    f => {
      const def = customFieldDefinitions ? customFieldDefinitions.find( cfd => cfd.name === f.name) : undefined
      return def ? !customFieldFilters.includes(def.id) : false
    }
  )

  const filterKeyValues = validFilters.map(f => f.key)
  const invalidFilters: string[] = difference(
    keysMinusCustomField,
    filterKeyValues
  )
  const validViewFilters = omit(data.activeFilters, invalidFilters)
  validViewFilters.customFields = JSON.stringify(validCustomFieldFilters)

  const pluralFilters = pluralize('filter', invalidFilters.length)
  const pluralCustomFieldFilters = pluralize(
    'filter',
    invalidCustomFieldFilters.length
  )
  const invalidFiltersText = invalidFilters.map(f => titleCase(f)).join(', ')

  if (invalidCustomFieldFilters.length > 0 && invalidFilters.length > 0) {
    toast(
      `The ${invalidFiltersText} ${pluralFilters} and ${invalidCustomFieldFilters.length} custom field ${pluralCustomFieldFilters}
       are no longer valid, everything else will still be applied in this view.`,
      LONG_TOAST
    )
  }

  if (invalidCustomFieldFilters.length > 0 && invalidFilters.length === 0) {
    toast(
      `${
        invalidCustomFieldFilters.length
      } custom field ${pluralCustomFieldFilters}
       ${
         invalidCustomFieldFilters.length > 1 ? 'are' : 'is'
       } no longer valid, everything else will still be applied in this view.`,
      LONG_TOAST
    )
  }

  if (invalidFilters.length > 0 && invalidCustomFieldFilters.length === 0) {
    toast(
      `The ${invalidFiltersText} ${pluralFilters} ${
        invalidFilters.length > 1 ? 'are' : 'is'
      } no longer valid, everything else will still be applied in this view.`,
      LONG_TOAST
    )
  }

  return validViewFilters
}

const getValidCustomFields = (customFieldFilters?: string, 
                              definitions?: CustomFieldDefinition[]): FilterDefinition[] => {
  const retVal: FilterDefinition[] = []
  if (customFieldFilters) {
    const parsedCustomFields =
        (customFieldFilters &&
            JSON.parse(customFieldFilters)) ||
        []
    const validCustomFieldFiltersWithId = parsedCustomFields.filter(f =>
        customFieldFilters.includes(f.customFieldDefinitionId)
    )
    const validCustomFieldFiltersWithName = parsedCustomFields.filter(f =>
        customFieldFilters.includes(f.name)
    )
    validCustomFieldFiltersWithName.forEach( cf => {
      const definition = definitions ? definitions.find( d => d.name === cf.name) : undefined
      if (definition) {
        cf.customField = definition
        retVal.push(cf)
      }
    })
    validCustomFieldFiltersWithId.forEach( cf => {
      const definition = definitions ? definitions.find( d => d.id === cf.customFieldDefinitionId) : undefined
      if (definition) {
        cf.name = definition.name
        cf.customField = definition
        retVal.push(cf)
      }
    })
  }
  return retVal
}

const mapViewToOption = (view: SavedView) => ({
  data: { ...JSON.parse(view.view), activeViewName: view.name },
  id: view.id,
  name: view.name,
  type: view.type
})

interface Props {
  className?: string
}

const SavedViewPicker = ({ className }: Props): JSX.Element => {
  const {
    activeFilters = {},
    activeViewName,
    columns = [],
    filters = [],
    hiddenColumns = [],
    savedViewType,
    setView,
    sort = {}
  } = useContext(TableContext)
  const [open, toggleOpen] = useToggle(false)
  const { closeModal, openModal } = useContext(ModalContext)
  const { loading, myViews, refetch, teamViews, platformViews } =
    useTableViews(savedViewType)
  const myViewOptions = myViews.map(mapViewToOption)
  const teamViewOptions = teamViews.map(mapViewToOption)
  const platformViewOptions = platformViews.map(mapViewToOption)
  const { data: customFieldDefinitions } = useGetAllDefinitionsQuery()

  const changeView = useCallback(
    (data: ViewPayload) => {
      if (setView) {
        setView({
          ...data,
          columns: convertColumns(data.columns, customFieldDefinitions),
          hiddenColumns: convertColumns(data.hiddenColumns, customFieldDefinitions),
          activeFilters: removeInvalidFilters(data, filters, customFieldDefinitions)
        })
      }
    },
    [filters, setView]
  )
  
  const convertColumns = (columns: string[], customFieldDefinitions?: CustomFieldDefinition[]): string[] => {
    return columns.map( c => {
      let converted: string = c
      const isNumber = !isNaN(parseInt(c))
      if (isNumber && customFieldDefinitions) {
        const def = customFieldDefinitions.find( d => d.id === parseInt(c));
        if (def) {
          converted = def.name ?? c
        }
      }
      return converted;
    })
  }

  const resetNameView = {
    activeFilters,
    activeViewName: '',
    columns: columns.map(c => c.key),
    hiddenColumns: hiddenColumns,
    sort
  }

  return (
    <Popup
      on="click"
      onClose={toggleOpen}
      onOpen={toggleOpen}
      open={open}
      position="bottom right"
      trigger={
        <ViewButton
          className={className}
          name={activeViewName ? 'bookmark' : 'bookmark outline'}
          basic={false}
          text="Views"
          iconColor={AQUA_BLUE}
          loading={loading}
          title={activeViewName ? activeViewName : ''}
        />
      }>
      <FilterOptions>
        <CreateViewButton
          disabled={!!activeViewName}
          refetch={refetch}
          savedViewType={savedViewType}
          toggle={toggleOpen}
        />
        <hr />
        <SectionHeader>
          <p>My Views</p>
        </SectionHeader>
        {myViewOptions.length === 0 && (
          <EmptyLabel>
            <p>No Views</p>
          </EmptyLabel>
        )}
        {myViewOptions.map(view => (
          <SavedViewOption
            isMyView={true}
            key={view.id}
            onClick={changeView}
            onDelete={() => {
              toggleOpen()
              openModal('deleteSavedView', {
                modalSize: 'tiny',
                activeViewName,
                viewName: view.name,
                viewId: view.id,
                refetch,
                resetNameView,
                setView,
                onClose: closeModal
              })
            }}
            view={view}
          />
        ))}
        <hr />
        <SectionHeader>
          <p>Team Views</p>
        </SectionHeader>
        {teamViewOptions.length === 0 && (
          <EmptyLabel>
            <p>No Views</p>
          </EmptyLabel>
        )}
        {teamViewOptions.map(view => (
          <SavedViewOption
            key={view.id}
            onClick={changeView}
            onDelete={() => {
              toggleOpen()
              openModal('deleteSavedView', {
                modalSize: 'tiny',
                activeViewName,
                viewName: view.name,
                viewId: view.id,
                refetch,
                resetNameView,
                setView,
                onClose: closeModal
              })
            }}
            view={view}
          />
        ))}
        <hr />
        <SectionHeader>
          <p>Platform Views</p>
        </SectionHeader>
        {platformViewOptions.length === 0 && (
          <EmptyLabel>
            <p>No Views</p>
          </EmptyLabel>
        )}
        {platformViewOptions.length > 0 && (
          <>
            <SectionHeader
              style={{ color: 'grey', textDecoration: 'underline' }}>
              <p>Annual LCRR Communication</p>
            </SectionHeader>

            {platformViewOptions.map(view => (
              <SavedViewOption key={view.id} onClick={changeView} view={view} />
            ))}
          </>
        )}
      </FilterOptions>
    </Popup>
  )
}

interface ViewButtonProps extends Omit<ButtonProps, 'basic' | 'icon'> {
  className?: string
  loading: boolean
  name: IconProps['name']
  text?: string
}

const ViewButton = ({
  className,
  iconColor,
  loading,
  name,
  onClick,
  text,
  ...props
}: ViewButtonProps): JSX.Element => {
  return (
    <StyledButton
      {...props}
      basic={false}
      className={className}
      iconColor={iconColor}
      loading={loading}
      name={name}
      onClick={onClick}
      text={text}>
      <Icon name={name} />
      {text}
    </StyledButton>
  )
}

const StyledButton = styled(Button)<{
  iconColor?: string
}>`
  &&& {
    padding-top: 11px;
    padding-bottom: 12px;
    height: 38px;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    box-shadow: none;
  }

  &&& i.icon {
    margin: 0 3px 0 0 !important;
    color: ${props => props.iconColor};
  }
`

const EmptyLabel = styled(FilterOption)`
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px !important;
`

const SectionHeader = styled(EmptyLabel)`
  font-weight: 700;
`

export default SavedViewPicker
