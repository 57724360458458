import CreateProgramModal from './Create'
import EditProgramModal from './Edit'
import LocationStatusUpdateModal from './LocationStatusUpdate'
import OrderKitsModal from './OrderKitsModal'
import RemoveEventModal from './RemoveEventModal'
import RemoveProgramModal from './RemoveProgramModal'

export {
  CreateProgramModal,
  EditProgramModal,
  LocationStatusUpdateModal,
  OrderKitsModal,
  RemoveEventModal,
  RemoveProgramModal
}

export const MODALS = {
  createKits: OrderKitsModal,
  createProgram: CreateProgramModal,
  editProgram: EditProgramModal,
  removeEvent: RemoveEventModal,
  removeProgram: RemoveProgramModal,
  updateProgramLocationStatus: LocationStatusUpdateModal
}
