import { Loader } from '@120wateraudit/envirio-components'
import { PWSKit } from '@120wateraudit/envirio-components/dist/models'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { addBatchDownload } from 'src/actions/batchDownloads'
import { startPolling } from 'src/actions/polling'
import { NoTemplatesWarning } from 'src/components/Communications/NoTemplatesWarning'
import DataAccess, {
  SendKitResultsMutation
} from 'src/components/Communications/SendKitResultsModal/DataAccess'
import { useToggle, Flags, useConfig } from 'src/hooks'
import Content from 'src/modules/Kits/modal/SendResultsModal/Content'
import Footer from 'src/modules/Kits/modal/SendResultsModal/Footer'
import { ModalWrapper, Wrapper } from 'src/modules/Modal/shared'
import { useAccount } from 'src/router/UserProvider'
interface Props {
  selected: PWSKit[]
  closeModal: () => void
  onSendResultsCompleted: () => void
}

export enum SendOption {
  USPS = 'USPS',
  PDF = 'PDF'
}

enum SendStep {
  'Letter Breakdown' = 0,
  'Send Options' = 1,
  'Confirm Send' = 2,
  'Send Completed' = 3
}

const SendResultsModal: React.FC<Props> = ({
  closeModal,
  onSendResultsCompleted,
  selected
}) => {
  const commsTwoEnabled = useConfig(Flags.CommsTwoEnabled)
  const dispatch = useDispatch()
  const [sendOption, setSendOption] = useState<SendOption>()

  const [markCompleteOnResultLetterSent, toggleMarkCompleteResultLetterSent] =
    useToggle(true)

  const [isCertifiedMail, toggleIsCertifiedMail] = useToggle(false)

  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)
  const [confirmSendTitle, setConfirmSendTitle] = useState<string>('Confirm')

  const { id: accountId } = useAccount()

  const onSendOptionChanged = (sendOption: SendOption): void => {
    setSendOption(sendOption)
  }

  const isNextDisabled = (step: number): boolean => {
    return step === SendStep['Send Options'] ? !sendOption : false
  }

  const updateConfirmSendTitle = useCallback((): void => {
    switch (sendOption) {
      case SendOption.PDF:
        setConfirmSendTitle('Your PDFs are Ready for Processing')
        return
      case SendOption.USPS:
        setConfirmSendTitle('Confirm')
        return
      default:
        return
    }
  }, [sendOption])

  const onNavigationClick = useCallback(
    async ({ navigation }: { navigation: 'back' | 'next' }): Promise<void> => {
      const targetStep =
        navigation === 'next' ? activeStepIndex + 1 : activeStepIndex - 1
      if (targetStep === SendStep['Confirm Send']) {
        updateConfirmSendTitle()
      }
      setActiveStepIndex(targetStep)
    },
    [activeStepIndex, updateConfirmSendTitle]
  )

  const onCompleted = useCallback(
    async ({
      downloadKitResults,
      sendKitResults
    }: {
      downloadKitResults: any
      sendKitResults: SendKitResultsMutation
    }): Promise<void> => {
      const targetFunction =
        sendOption === SendOption.USPS ? sendKitResults : downloadKitResults

      const batchId = await targetFunction()
      if (sendOption === SendOption.PDF && !commsTwoEnabled) {
        //If Comms 1.0 make these calls to download
        dispatch(addBatchDownload({ batchId }))
        dispatch(startPolling())
      }
      onSendResultsCompleted()
      setActiveStepIndex(SendStep['Send Completed'])
    },
    [onSendResultsCompleted, commsTwoEnabled, dispatch, sendOption]
  )

  const kitIds = useMemo(() => {
    return selected.map(k => k.id)
  }, [selected])

  const isDownload = useMemo(() => {
    return sendOption === SendOption.PDF ? true : false
  }, [sendOption])

  return (
    <DataAccess
      accountId={accountId}
      isCertifiedMail={isCertifiedMail}
      isDownload={isDownload}
      kitIds={kitIds}
      markCompleteOnResultLetterSent={markCompleteOnResultLetterSent}>
      {data => {
        const {
          countsError,
          downloadKitResults,
          loading,
          sendKitResults,
          allResultsCount,
          detectCount,
          nonDetectCount,
          exceedanceCount
        } = data

        if (loading) {
          return <Loader />
        }

        if (countsError) {
          return <NoTemplatesWarning onClose={closeModal} />
        }

        if (
          !allResultsCount &&
          !detectCount &&
          !nonDetectCount &&
          !exceedanceCount
        ) {
          return <NoTemplatesWarning onClose={closeModal} />
        }

        return (
          <ModalWrapper>
            <Wrapper
              style={{
                overlay: {
                  zIndex: '1000'
                }
              }}>
              <React.Suspense fallback={<Loader />}>
                <Content
                  {...data}
                  activeStepIndex={activeStepIndex}
                  confirmSendTitle={confirmSendTitle}
                  isCertifiedMail={isCertifiedMail}
                  markCompleteOnResultLetterSent={
                    markCompleteOnResultLetterSent
                  }
                  onSendOptionChanged={onSendOptionChanged}
                  sendOption={sendOption}
                  toggleIsCertifiedMail={toggleIsCertifiedMail}
                  toggleMarkCompleteResultLetterSent={
                    toggleMarkCompleteResultLetterSent
                  }
                />
                <Footer
                  activeStepIndex={activeStepIndex}
                  closeModal={closeModal}
                  isNextDisabled={isNextDisabled(activeStepIndex)}
                  onCompleted={async () =>
                    await onCompleted({
                      sendKitResults,
                      downloadKitResults
                    })
                  }
                  onNavigation={(navigation: 'back' | 'next') =>
                    onNavigationClick({ navigation })
                  }
                  sendOption={sendOption}
                />
              </React.Suspense>
            </Wrapper>
          </ModalWrapper>
        )
      }}
    </DataAccess>
  )
}

export default React.memo(SendResultsModal)
