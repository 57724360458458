import React from 'react'
import { Button } from 'semantic-ui-react'

import styled from 'src/theme'
import { pushRoute } from 'src/utils/navigation'

interface Props {
  toggleLocationImportModal: () => void
  useLinkAsImportResponse?: boolean
}

const ImportResponse = ({
  toggleLocationImportModal,
  useLinkAsImportResponse = false
}: Props): JSX.Element | null => {
  if (!useLinkAsImportResponse) {
    return null
  }

  return (
    <span>
      Your data is being loaded into the 120Water platform and will be
      immediately available when it has been imported. You can check the status
      of the upload in the&nbsp;
      <LinkButton
        onClick={() => {
          toggleLocationImportModal()
          pushRoute('/administration/importlog')
        }}>
        <Button.Content>Import Log</Button.Content>
      </LinkButton>
    </span>
  )
}

export default ImportResponse

const LinkButton = styled(Button)`
  border: none;
  cursor: pointer;
  &&& {
    color: #00b5e1;
    text-decoration: underline;
    padding: 0;
    background: none;
  }
`
