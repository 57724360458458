import { colors, ProgressBar, spacing } from '@120wateraudit/envirio-components'
import React from 'react'
import Dropzone from 'react-dropzone'
import { Button, Form, Radio } from 'semantic-ui-react'

import { downloadDocument } from 'src/API'
import { ReactComponent as DotsUploadIcon } from 'src/assets/images/Dots-upload.svg'
import { Error } from 'src/components/Error'
import styled from 'src/theme'
import { ApiError } from 'src/types/ApiError'
import ImportResponse from './ImportResponse'
import { BatchType } from 'src/modules/FileProcessor/dataAccess'

interface Props {
  createSamplesOnImport?: boolean
  disableLocationImportButton: boolean
  dropzoneDisabled: boolean
  error: ApiError
  eventName: string
  file: undefined | File
  hideCreateSamplesToggle: boolean
  showFailedMessage: boolean
  uploadProgress: number
  uploading: boolean
  onDrop: (files: File[]) => void
  onImportLocationsClicked: () => void
  onToggleClicked: (e: any, d: any) => void
  toggleLocationImportModal: () => void
  useLinkAsImportResponse?: boolean
}

const EventAssignmentModalContent = ({
  createSamplesOnImport = true,
  disableLocationImportButton,
  dropzoneDisabled,
  error,
  eventName,
  file,
  hideCreateSamplesToggle,
  onDrop,
  onImportLocationsClicked,
  onToggleClicked,
  showFailedMessage,
  toggleLocationImportModal,
  uploadProgress,
  uploading,
  useLinkAsImportResponse = false
}: Props) => {
  return (
    <>
      <ImageDiv>
        <DotsUploadIcon style={{ margin: 'auto' }} />
      </ImageDiv>
      <HeaderDiv>
        <ModalHeader>Assign Locations to Event {eventName}</ModalHeader>
      </HeaderDiv>
      <Form>
        <InstructionsDiv>
          <ModalText>
            Assign multiple locations in bulk by using the
            <LinkButton
              as="a"
              onClick={async () =>
                await downloadDocument(
                  `/file-processor/templates/events/${BatchType.Import.toString()}`,
                  'pwsEventAssignment.csv',
                  'text/csv'
                )
              }>
              <Button.Content>Event Assignment Template</Button.Content>
            </LinkButton>
            .
          </ModalText>
        </InstructionsDiv>
        <Dropzone
          disabled={dropzoneDisabled}
          onDrop={onDrop.bind(this)}
          style={DropzoneStyle}>
          <DropzoneInstructionsText
            style={{ cursor: 'pointer', textAlign: 'center' }}>
            {file ? (
              <>
                <FileNameSpan>{file.name}</FileNameSpan>
                {uploading && uploadProgress ? (
                  <ProgressBarContainer>
                    <ProgressBar
                      emptyColor={colors.white}
                      percent={uploadProgress}
                      variant="success"
                      width={8}
                    />
                  </ProgressBarContainer>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                Drag and Drop a file, or click to <LinkText>Browse</LinkText>.
              </>
            )}
          </DropzoneInstructionsText>
        </Dropzone>

        <FinishDiv>
          <FinishText>
            <ImportResponse
              toggleLocationImportModal={toggleLocationImportModal}
              useLinkAsImportResponse={useLinkAsImportResponse}
            />
          </FinishText>
        </FinishDiv>

        {showFailedMessage ? (
          error ? (
            <FinishDiv>
              <Error error={error} errorRows />
            </FinishDiv>
          ) : (
            <>
              <FinishDiv>
                <FailedText>
                  The Import of your file failed, please check your import file.
                </FailedText>
              </FinishDiv>
            </>
          )
        ) : (
          ''
        )}
        {useLinkAsImportResponse ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2rem'
            }}>
            <Button
              fontSize="1.167rem"
              onClick={toggleLocationImportModal}
              variant="primary">
              Finish
            </Button>
          </div>
        ) : (
          !hideCreateSamplesToggle && (
            <>
              <ToggleDiv>
                <Radio
                  defaultChecked={createSamplesOnImport}
                  label="Create Kits Now"
                  onChange={onToggleClicked}
                  toggle
                />
              </ToggleDiv>
            </>
          )
        )}
        {!useLinkAsImportResponse && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2rem'
            }}>
            <Button fontSize={'1.167rem'} onClick={toggleLocationImportModal}>
              Cancel
            </Button>
            <div style={{ paddingLeft: '0.417rem' }}>
              <Button
                fontSize={'1.167rem'}
                disabled={disableLocationImportButton || uploading}
                variant="primary"
                onClick={onImportLocationsClicked}>
                Assign Locations
              </Button>
            </div>
          </div>
        )}
      </Form>
    </>
  )
}

export default EventAssignmentModalContent

const ImageDiv = styled.div`
  display: flex;
`

const LinkText = styled.span`
  color: #00b5e1;
`

const ModalHeader = styled.h5`
  margin: auto;
  text-align: center;
  padding-bottom: ${spacing.tiny};
`
const ToggleDiv = styled.div`
  padding-top: ${spacing.tiny};

  .ui.toggle.checkbox input: focus: checked~.box: before;
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: ${colors.primary} !important;
  }

  .ui.toggle.checkbox input: checked ~.box: before;
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: ${colors.primary} !important;
  }

  .ui.toggle.checkbox .box: before;
  .ui.toggle.checkbox label:before {
    background-color: grey;
  }
`

const DropzoneStyle = {
  borderColor: '#00b5e1',
  borderRadius: '4px',
  borderStyle: 'dashed',
  borderWidth: '2px',
  display: 'flex',
  height: '116px',
  marginTop: '2rem',
  width: '100%'
}

const HeaderDiv = styled.div`
  display: flex;
  margin-top: ${spacing.small};
`

const InstructionsDiv = styled.div`
  display: flex;
  margin: auto;
  background-color: ${props => props.theme.colors.white};
  color: ${colors.black50};
`

const FinishDiv = styled.div`
  display: flex;
  margin: auto;
  background-color: ${props => props.theme.colors.white};
  flex-direction: column;
  max-height: 16rem;
  margin-top: ${spacing.tiny};
  overflow: auto;
`

const FinishText = styled.span`
  margin: auto;
  font-weight: normal;
  font-size: 1.167rem;
  text-align: center;
  margin-top: 2rem;
`

const FailedText = styled.span`
  margin: auto;
  font-weight: normal;
  font-size: 1.167rem;
  text-align: center;
  color: ${colors.error};
  margin-top: 2rem;
`

const FileNameSpan = styled.span`
  margin: auto;
  font-weight: bold;
  color: ${colors.black};
`

const ModalText = styled.span`
  margin: auto;
  font-weight: normal;
  font-size: 1.167rem;
  text-align: center;
`

const DropzoneInstructionsText = styled.span`
  margin: auto;
  font-weight: normal;
  font-size: 1.167rem;
  color: ${colors.black50};
`

const ProgressBarContainer = styled.div`
  margin: auto;
  width: 100%;
`

const LinkButton = styled(Button)`
  border: none;
  cursor: pointer;
  &&& {
    color: #00b5e1;
    text-decoration: underline;
    padding: 0;
    background: none;
  }
`
