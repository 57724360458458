import { TriggerType } from '@120wateraudit/communications-types'
import {
  Dropdown,
  Loader,
  TextField,
  colors
} from '@120wateraudit/envirio-components'
import { SamplingEvent } from '@120wateraudit/envirio-components/dist/models'
import { useMutation, useQuery } from '@apollo/client'
import { faCopy, faWarning } from '@fortawesome/free-solid-svg-icons'
import React, { FC, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { contactsClient } from 'src/apollo-clients'
import {
  COMMUNICATION_TEMPLATES,
  CREATE_OR_UPDATE_COMMUNICATION,
  CommunicationInput,
  CreateOrUpdateCommunicationData,
  CreateOrUpdateCommunicationVariables
} from 'src/containers/Communications/EditorSuite/data-access'
import { Flags, useConfig, usePrograms } from 'src/hooks'
import { useAccount } from 'src/router/UserProvider'
import { Communication } from 'src/types/Communications'
import { buildCommunicationDropdownOptions } from 'src/utils/buildCommunicationDropdownOptions'
import { pushRoute } from 'src/utils/navigation'
import Modal from '../ModalContents'
import { ErrorMessage, ModalButton } from '../shared'

interface Props {
  communication?: Partial<Communication>
  content: string
  samplingEventId: undefined | number
  samplingEvents: SamplingEvent[]
  onClose: () => void
}

const CopyCommunicationModal: FC<Props> = ({
  communication,
  content,
  onClose,
  samplingEventId,
  samplingEvents
}) => {
  const { id: accountId } = useAccount()
  const { loading: loadingPrograms, programs } = usePrograms()
  const [selectedProgramId, setSelectedProgramId] = useState<
    undefined | number
  >(undefined)
  const [selectedCommunicationTemplate, setSelectedCommunicationTemplate] =
    useState(communication?.templateId)
  const [eventId, setEventId] = useState<undefined | number>(undefined)
  const [communicationName, setCommunicationName] = useState('')
  const [isSameProgramType, setIsSameProgramType] = useState(true)
  const [isSameTemplate, setIsSameTemplate] = useState(true)
  const showAllResultsTemplate = useConfig(Flags.ShowAllResultsLetter)

  const [
    createOrUpdateCommunicationMutation,
    { error: createOrUpdateError, loading: isSaving }
  ] = useMutation<
    CreateOrUpdateCommunicationData,
    CreateOrUpdateCommunicationVariables
  >(CREATE_OR_UPDATE_COMMUNICATION, {
    client: contactsClient
  })

  const { data: templateData, loading: loadingTemplates } = useQuery(
    COMMUNICATION_TEMPLATES,
    {
      client: contactsClient,
      variables: {
        accountId
      }
    }
  )

  const isButtonDisabled = eventId === undefined || communicationName.length < 1

  const createCommunicationInput = useCallback((): {
    communication: CommunicationInput
  } => {
    const selectedTemplate = templateData.communicationTemplates.find(
      t => t.id === selectedCommunicationTemplate
    )

    return {
      communication: {
        communicationType: communication?.communicationType,
        eventId,
        name: communicationName,
        status: communication?.status,
        templateId: selectedTemplate.id,
        triggerCondition: selectedTemplate.triggerCondition,
        triggerType: selectedTemplate.triggerType
      } as CommunicationInput
    }
  }, [
    communication,
    communicationName,
    eventId,
    selectedCommunicationTemplate,
    templateData
  ])

  const checkSave = () => {
    const originalProgramTypeId = programs.find(
      p =>
        p.id === samplingEvents.find(se => se.id === samplingEventId)?.programId
    )?.programTypeId

    const selectedProgramToCopyProgramTypeId = programs.find(
      p => p.id === selectedProgramId
    )?.programTypeId

    const isSameTemplateId =
      communication?.templateId === selectedCommunicationTemplate

    const isSameProgramTypeId =
      originalProgramTypeId === selectedProgramToCopyProgramTypeId

    if (!isSameTemplateId) {
      setIsSameTemplate(false)
    }

    if (!isSameProgramTypeId) {
      setIsSameProgramType(false)
    }

    if (isSameProgramTypeId && isSameTemplateId) {
      handleSave()
    }
  }

  const handleSave = useCallback(async () => {
    //Setting these to true so the modal displays the correct error
    setIsSameProgramType(true)
    setIsSameTemplate(true)
    await createOrUpdateCommunicationMutation({
      variables: {
        accountId,
        content,
        ...createCommunicationInput()
      }
    })

    if (!createOrUpdateError) {
      pushRoute('/communications/letters')
      onClose()
    }
  }, [
    createOrUpdateCommunicationMutation,
    accountId,
    content,
    createCommunicationInput,
    createOrUpdateError,
    onClose
  ])

  const samplingEventOptions = useMemo(
    () =>
      buildCommunicationDropdownOptions(
        samplingEvents.filter(se => se.programId === selectedProgramId)
      ),
    [samplingEvents, selectedProgramId]
  )

  const programOptions = useMemo(
    () => buildCommunicationDropdownOptions(programs),
    [programs]
  )

  const templateOptions = useMemo(() => {
    if (templateData) {
      const isCompanyLetterhead =
        templateData.communicationTemplates.filter(
          ct => ct.id === communication?.templateId
        )?.[0].triggerType === TriggerType.AdHocLocation

      if (isCompanyLetterhead) {
        return buildCommunicationDropdownOptions(
          templateData.communicationTemplates
        )
      }
      let templates = templateData.communicationTemplates.filter(
        ct => ct.triggerType === TriggerType.SendKitResult
      )
      if (!showAllResultsTemplate) {
        templates = templates.filter(ct => ct.triggerCondition !== 'allResults')
      }
      return buildCommunicationDropdownOptions(templates)
    } else {
      return {
        key: '',
        text: '',
        value: ''
      }
    }
  }, [templateData, showAllResultsTemplate])

  if (isSaving || loadingPrograms || loadingTemplates) {
    return <Loader />
  }

  if (!isSameProgramType || !isSameTemplate) {
    return (
      <Modal
        cancelAction={
          <CopyLetterModalButton fontSize={'1.15rem'} onClick={onClose}>
            Cancel
          </CopyLetterModalButton>
        }
        icon={faWarning}
        iconOverrideColor={colors.warning}
        primaryAction={
          <CopyLetterModalButton
            fontSize={'1.15rem'}
            variant="primary"
            onClick={handleSave}>
            Copy Letter Template
          </CopyLetterModalButton>
        }
        title="Warning">
        <>
          {!isSameProgramType && (
            <ErrorMessage>
              Warning: You are copying a letter template to a different program
              type.
            </ErrorMessage>
          )}
          {!isSameTemplate && (
            <ErrorMessage>
              Warning: You are copying a letter template to a different template
              type.
            </ErrorMessage>
          )}
          <ErrorMessage>
            Make sure you update the text of the letter template to reflect
            these changes.
          </ErrorMessage>
        </>
      </Modal>
    )
  }

  return (
    <Modal
      cancelAction={
        <CopyLetterModalButton fontSize={'1.15rem'} onClick={onClose}>
          Cancel
        </CopyLetterModalButton>
      }
      icon={faCopy}
      primaryAction={
        <CopyLetterModalButton
          fontSize={'1.15rem'}
          disabled={isButtonDisabled || isSaving || createOrUpdateError}
          variant="primary"
          onClick={checkSave}>
          Copy Letter Template
        </CopyLetterModalButton>
      }
      title="Copy Letter Template">
      {!createOrUpdateError && (
        <InputFieldsWrapper>
          <TextField
            label="Communication Name"
            onChange={e => setCommunicationName(e.target.value)}
            placeholder="Add Communication Name"
            required
            style={{ width: '100%', marginBottom: '1rem' }}
            value={communicationName}
          />
          <ModalDropdown
            fluid
            label="Template"
            onChange={(_, { value }) => setSelectedCommunicationTemplate(value)}
            optionTextMaxLength={45}
            options={templateOptions}
            placeholder="Select a Template"
            search
            value={selectedCommunicationTemplate}
          />
          <ModalDropdown
            fluid
            label="Program"
            onChange={(_, { value }) => setSelectedProgramId(value)}
            optionTextMaxLength={45}
            options={programOptions}
            placeholder="Select a Program"
            search
            value={selectedProgramId}
          />
          {selectedProgramId && (
            <ModalDropdown
              disabled={samplingEventOptions.length < 1}
              fluid
              label="Related Event"
              onChange={(_, { value }) => setEventId(value)}
              optionTextMaxLength={45}
              options={samplingEventOptions}
              placeholder={
                samplingEventOptions.length < 1
                  ? 'No Sampling Events found for this program'
                  : 'Select a Sampling Event'
              }
              search
              value={eventId}
            />
          )}
        </InputFieldsWrapper>
      )}
      {createOrUpdateError && (
        <ErrorMessage>
          {createOrUpdateError.graphQLErrors[0].message}
        </ErrorMessage>
      )}
    </Modal>
  )
}

export default CopyCommunicationModal

const InputFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ModalDropdown = styled(Dropdown)`
  margin-bottom: 1rem;
`

const CopyLetterModalButton = styled(ModalButton)`
  width: 181px;
`
