import { fontSize, lineHeight } from '@120wateraudit/envirio-components'
import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'

import SingleAnalyte from './SingleAnalyte'

interface Analyte {
  name: string
  upperLimit: number
  skuId: number
  isPrimary?: boolean
}

interface Props {
  className?: string
  analytes: Analyte[]
  style?: CSSProperties
}

const AnalyteDefinitions: FC<Props> = ({ className, analytes, style }) => {
  return (
    <div className={className} style={style}>
      <AnalyteHeader data-test="analyte-header">Analyte</AnalyteHeader>
      {analytes.map(analyte => (
        <SingleAnalyte
          key={analyte.name}
          analyte={analyte}
          showDetails={analyte.upperLimit >= 0}
        />
      ))}
    </div>
  )
}

const AnalyteHeader = styled.div`
  font-size: ${fontSize.subHeader};
  font-weight: 700;
  line-height: ${lineHeight.subHeader};
  padding-bottom: 1rem;
`

export default AnalyteDefinitions
