import { DropdownItemProps } from 'semantic-ui-react'
import { ProgramFragment } from 'src/hooks/useProgramsAndEvents'
import { SamplingEvent } from '@120wateraudit/envirio-components/dist/models'
import { CommunicationTemplate } from 'src/types/Communications'

export function buildCommunicationDropdownOptions<
  T extends (
    | ProgramFragment
    | SamplingEvent
    | Pick<
        CommunicationTemplate,
        'id' | 'name' | 'triggerType' | 'triggerCondition'
      >
  )[]
>(obj: T): DropdownItemProps {
  return obj.map(o => ({
    key: o.id,
    text: o.name,
    value: o.id
  }))
}
