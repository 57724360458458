import { useUser } from 'src/router/UserProvider'

/**
 * `useHasMoreThanReadPermission` - Checks whether or not a user only has read permission or other permissions.
 */
export const useHasMoreThanReadPermission = (): boolean => {
  const { user } = useUser()

  if (!user || !user.permissions) {
    return false
  }

  //Returns true if any of the permission values below are noticed as a substring.
  //If false the user is a read only user.
  return user.permissions.some(p =>
    p.includes('pws:create' || 'pws:update' || 'pws:delete' || 'pws:import')
  )
}
