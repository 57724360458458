import {
  TextField,
  colors,
  fontSize,
  lineHeight,
  spacing
} from '@120wateraudit/envirio-components'
import React, { FC } from 'react'
import styled from 'styled-components'

import Radio from 'src/components/Forms/Radio'

interface Protocol {
  name: string
  quantity: number
  skuId: number
}

interface Props {
  protocol: Protocol
  readOnly: boolean
  selected: boolean
  showDetails: boolean
  onSelect(): void
}

const SingleProtocol: FC<Props> = ({
  onSelect,
  protocol,
  readOnly,
  selected = false,
  showDetails = true
}) => {
  return (
    <DefinitionWrapper>
      {readOnly && <SkuNameWrapper>{protocol.name}</SkuNameWrapper>}
      {!readOnly && (
        <CheckableWrapper onClick={onSelect}>
          <Radio checked={selected} size="large" />
          <SkuNameWrapper>{protocol.name}</SkuNameWrapper>
        </CheckableWrapper>
      )}
      {showDetails && (
        <DetailsWrapper>
          <QuantityLabelWrapper>Quantity in Kit</QuantityLabelWrapper>
          <QuantityValueWrapper>
            <TextField
              className="definitionQuantity"
              min={0}
              name="quantity"
              style={{ width: '6.25rem', height: '2.5rem', padding: 0 }}
              type="number"
              value={`${protocol.quantity}`}
            />
          </QuantityValueWrapper>
        </DetailsWrapper>
      )}
    </DefinitionWrapper>
  )
}

const DefinitionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacing.small};
`

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SkuNameWrapper = styled.div`
  font-size: ${fontSize.body};
  line-height: 1.714285714;
`

const QuantityLabelWrapper = styled.div`
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption};
  font-weight: 700;
  color: ${colors.black50};
`

const QuantityValueWrapper = styled.div`
  .definitionQuantity > input {
    padding: 8px !important;
    font-size: ${fontSize.caption} !important;
    line-height: ${lineHeight.caption} !important;
  }
`

const CheckableWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  & > :first-child {
    margin-right: 4px;
  }
`

export default SingleProtocol
