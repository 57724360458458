import React, { useCallback, useEffect, useState } from 'react'
import { Button, DropdownItemProps } from 'semantic-ui-react'

import { ReactComponent as MoveIcon } from 'src/assets/images/icon-move-location.svg'
import { useProgramsAndEvents, useToggle } from 'src/hooks'
import { PWSSample } from 'src/types/Sample'
import { Controls, Wrapper } from '../'
import { useMoveSamplesAction } from '../../dataAccess'
import Confirm from './Confirm'
import Form from './Form'

const useReasonState = (): [
  string | undefined,
  (_: unknown, data: DropdownItemProps) => void
] => {
  const [reason, setReason] = useState<string>()
  const onReasonChange = useCallback(
    (_: unknown, data: DropdownItemProps) => {
      setReason(data?.value as string)
    },
    [setReason]
  )
  return [reason, onReasonChange]
}

interface Props {
  programId: number
  selectedSamples: PWSSample[]
  onClose(): void
  onCompletion?(): void
}

function MoveSamplesModal({
  onClose,
  onCompletion,
  programId,
  selectedSamples
}: Props): JSX.Element {
  const {
    event,
    events,
    loading,
    onEventSelect,
    onProgramSelect,
    program
  } = useProgramsAndEvents()
  useEffect(() => {
    if (!loading && !program) {
      onProgramSelect(undefined, { value: programId })
    }
  }, [loading, program, onProgramSelect, programId])
  const [reason, setReason] = useReasonState()
  const [otherReason, setOtherReason] = useState<string>()
  const [isConfirming, toggleConfirming] = useToggle()
  const { error, isWorking, moveSamples } = useMoveSamplesAction(
    selectedSamples
  )
  const onConfirm = useCallback(async () => {
    // We are using null assertions here because this button should be disabled if those don't exist.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (await moveSamples(event!.id, reason!, otherReason)) {
      onClose()
      if (onCompletion) onCompletion()
    }
  }, [moveSamples, event, reason, otherReason, onClose, onCompletion])
  const disableNextButton =
    isWorking || !event || !reason || (reason === 'Other' && !otherReason)
  return (
    <Wrapper>
      <MoveIcon />
      <h6>{isConfirming ? 'Confirm' : 'Move Samples'}</h6>
      {!isConfirming && (
        <Form
          event={event}
          events={events}
          onEventSelect={onEventSelect}
          onReasonSelect={setReason}
          otherReason={otherReason}
          reason={reason}
          setOtherReason={setOtherReason}
        />
      )}
      {isConfirming && (
        <Confirm
          error={error}
          isSaving={isWorking}
          selectedEvent={event?.name}
          selectedSamples={selectedSamples}
        />
      )}
      <Controls>
        <Button onClick={isConfirming ? toggleConfirming : onClose}>
          {isConfirming ? 'Back' : 'Cancel'}
        </Button>
        <Button
          disabled={disableNextButton}
          onClick={isConfirming ? onConfirm : toggleConfirming}
          primary>
          {isConfirming ? 'Confirm' : 'Move Samples'}
        </Button>
      </Controls>
    </Wrapper>
  )
}

export default MoveSamplesModal
