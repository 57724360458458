import React, { useState, useEffect } from 'react'
import {
  Button,
  Container as SUIRContainer,
  Header,
  Progress
} from 'semantic-ui-react'
import styled from 'styled-components'
import { useFormState } from 'react-final-form'

function useSteps(numberOfSteps) {
  const [activeStep, setActiveStep] = useState(0)
  const canAdvance = activeStep !== numberOfSteps - 1
  const canGoBack = activeStep > 0
  const goBack = () => canGoBack && setActiveStep(activeStep - 1)
  const advance = () => canAdvance && setActiveStep(activeStep + 1)
  return { activeStep, advance, canAdvance, canGoBack, goBack }
}

export interface Step {
  backButtonText?: string
  icon?: React.ReactNode
  nextButtonText?: string
  subtitle?: string
  title: string
  render(): JSX.Element
  validate?(): boolean
  validateStepValues?(values: any): boolean | Promise<boolean>
}

interface Props {
  backButtonText?: string
  hasProgressBar?: boolean
  icon?: React.ReactNode
  loading?: boolean
  nextButtonText?: string
  steps: Step[]
  submitButtonText?: string
  valid?: boolean
  onCancel(): void
}

function Wizard({
  backButtonText = 'Back',
  hasProgressBar,
  icon,
  loading = false,
  nextButtonText = 'Next',
  onCancel,
  steps,
  submitButtonText = 'Submit',
  valid = true
}: Props): null | JSX.Element {
  const { values } = useFormState()
  const { activeStep, advance, canAdvance, canGoBack, goBack } = useSteps(
    steps.length || 0
  )
  const [areStepValuesValid, setAreStepValuesValid] = useState(false)
  const currentStep = steps[activeStep]

  useEffect(() => {
    const checkValid = async () => {
      const isValid = currentStep.validateStepValues
        ? await currentStep.validateStepValues(values)
        : true

      setAreStepValuesValid(isValid)
    }

    checkValid()
  }, [currentStep, values])

  const isStepValid = currentStep.validate ? currentStep.validate() : true
  const progressPercent = ((activeStep + 1) / steps.length) * 100

  return (
    <Container>
      {hasProgressBar && (
        <Progress style={{ width: '100%' }} percent={progressPercent} success />
      )}
      <Header as="h2">
        {currentStep.icon || icon || null}
        {currentStep.title}
        {currentStep.subtitle && (
          <Header as="p" sub>
            {currentStep.subtitle}
          </Header>
        )}
      </Header>
      <div className="wizard-content">{currentStep.render()}</div>
      <div className="wizard-controls">
        <Button onClick={onCancel} type="button">
          Cancel
        </Button>
        <div>
          {canGoBack && (
            <Button onClick={goBack} type="button">
              {currentStep.backButtonText || backButtonText}
            </Button>
          )}
          {canAdvance && (
            <Button
              disabled={!isStepValid || !areStepValuesValid}
              loading={loading}
              onClick={advance}
              primary
              type="button">
              {currentStep.nextButtonText || nextButtonText}
            </Button>
          )}
          {!canAdvance && (
            <Button
              disabled={!isStepValid || !valid}
              loading={loading}
              primary
              type="submit">
              {submitButtonText}
            </Button>
          )}
        </div>
      </div>
    </Container>
  )
}

const Container = styled(SUIRContainer)`
  &&& {
    margin: 3rem;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > h2 {
      align-items: center;
      display: flex;
      flex-direction: column;
      & > p {
        text-transform: none;
        color: #9ca3af;
        font-size: 12px;
        line-height: 20px;
      }
    }
    & > div.wizard-content {
      margin: 2rem 0 3rem 0;
    }
    & > div.wizard-controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 500px;
    }
  }
`

export default Wizard
