import moment from 'moment'
import { getExcel, postExcel } from '../API'
// eslint-disable-next-line
const FileSaver = require('file-saver')

export const downloadExcel = async (
  exportURL: string,
  prefix: string,
  body?: any
) => {
  try {
    const results: any = body
      ? await postExcel(exportURL, body)
      : await getExcel(exportURL)

    const blob = new Blob([results.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    FileSaver.saveAs(
      blob,
      `${prefix}_${moment().format('DD-MM-YYYY')}.xlsx`,
      true
    )
  } catch {
    /* */
  }
}
