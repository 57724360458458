import { useCallback, useState } from 'react'

export function useDropdown<T>(
  initialValue?: T
): [undefined | T, (_: unknown, data: { value?: T }) => void] {
  const [value, setValue] = useState<undefined | T>(initialValue)
  const onDropdownChange = useCallback(
    (_: unknown, data: { value?: T }) => {
      setValue(data.value)
    },
    [setValue]
  )
  return [value, onDropdownChange]
}

export default useDropdown
