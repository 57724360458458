import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { DocumentNode } from 'graphql'

interface GraphQLParams {
  mutation?: DocumentNode
  query?: DocumentNode
  variables: unknown
}

export const graphQLBaseQuery =
  ({ client }: { client: ApolloClient<NormalizedCacheObject> }) =>
  async ({ mutation, query, variables }: GraphQLParams) => {
    try {
      let result
      if (query) {
        result = await client.query({
          fetchPolicy: 'no-cache',
          query,
          variables
        })
      } else if (mutation) {
        result = await client.mutate({ mutation, variables })
      } else {
        throw new Error('No GraphQL Query or Mutation provided')
      }

      return { data: result.data }
    } catch (error) {
      return { error: { data: error, status: 500 } }
    }
  }

export default graphQLBaseQuery
