import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { DateInput } from 'semantic-ui-calendar-react'
import styled from 'styled-components'

export const DATE_FORMAT = 'MM-DD-YYYY'

type Props = FieldRenderProps<string, HTMLElement>

const Datepicker: FC<Props> = ({
  className,
  input,
  meta: { error },
  ...rest
}) => {
  const onChange = (_, data) => input.onChange(data.value)
  return (
    <div className={className}>
      <StyledInput
        error={!!error}
        closable
        closeOnMouseLeave={false}
        {...input}
        {...rest}
        dateFormat={DATE_FORMAT}
        onChange={onChange}
      />
      {error && <ValidationError>{error}</ValidationError>}
    </div>
  )
}

const StyledInput = styled(DateInput)`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    & > label {
      color: #333;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 4px;

      ${StyleProps => StyleProps}
    }
    & > div {
      height: 36px;
    }
    & input {
      font-size: 1rem;
      border-width: 2px;
      border-color: ${props =>
        props.error ? 'var(--ignis-red)' : props.stateValidation ? '#ffc720' : 'rgba(0, 0, 0, 0.25)'} !important;
    }
  }
`

const ValidationError = styled.p`
  position: absolute;
  color: var(--ignis-red);
`

export default Datepicker
