import styled from 'styled-components'

export const Wrapper = styled.div<{
  topMargin?: string
}>`
  margin-top: ${props => (props.topMargin ? props.topMargin : '2rem')};
  margin-right: 2rem;
  margin-left: 2rem;
`

export default Wrapper
