import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import {
  BoldSpan,
  DoubleInputGrid,
  FlexDiv,
  GeneralContainer,
  NoRedHighlightTextField
} from '../../shared'

const ArizonaMethodsPart3 = (): JSX.Element => {
  return (
    <GeneralContainer>
      <p style={{ marginBottom: '0px' }}>
        {`Identify the service line investigation methods your system used to
        prepare the inventory (check all that apply). If a water system chooses
        an investigation method not specified by the state under 40 CFR
        §141.84(a)(3)(iv), state approval is required. `}
        <BoldSpan>
          Note that investigations are not required by the LCRR but can be used
          by systems to assess accuracy of historical records and gather
          information when service line material is unknown.
        </BoldSpan>
        <Message>At least one option must be checked.</Message>
      </p>
      <DoubleInputGrid>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Visual Inspection at the Meter Pit"
          />
          <label>Visual Inspection at the Meter Pit</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="CCTV Inspection at Curb Box - External"
          />
          <label>CCTV Inspection at Curb Box - External</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="CCTV Inspection at Curb Box - Internal"
          />
          <label>CCTV Inspection at Curb Box - Internal</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Water Quality Sampling - Sequential"
          />
          <label>Water Quality Sampling - Sequential</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Mechanical Excavation"
          />
          <label>Mechanical Excavation</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Vacuum Excavation"
          />
          <label>Vacuum Excavation</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Interpolation"
          />
          <label>Interpolation</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="No field verification work done"
          />
          <label>No field verification work done</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Visual"
          />
          <label>Visual</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Visual - Swab"
          />
          <label>Visual - Swab</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Visual Inspection at/inside Building"
          />
          <label>Visual Inspection at/inside Building</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Other"
          />
          <label>Other</label>
        </FlexDiv>
      </DoubleInputGrid>
      <Field<string>
        component={NoRedHighlightTextField}
        displayConditionallyRequired
        hideError
        placeholder="Yes / No"
        name="inventoryMethodsPart3InterpolationExplanation"
        label="If “interpolation”, were the following used to properly interpolate? Selected area is a homogenous community with less than 1,500 service connections AND two or more similar factors including: construction year, location, or contractor/developer. ADEQ requires 20 percent of the total service lines (randomly selected) in the homogenous area to be visually or physically investigated with a 95% confidence rate to produce accurate results for the area."
        textarea
      />
      <Field<string>
        component={NoRedHighlightTextField}
        label={`If "sequential sampling", was the approved guidance followed? 
          Sequential sampling was used as a basis of material classification to determine the presence of lead as described below: 
          Minimum of ten 1 liter sequential samples from a single ground floor source (interior, usually kitchen, sink faucet with cold water) to determine if a single service line is lead, additional samples may be needed based on pipe volume to reach the service line. 
          Before the first sample is drawn the lines must be stagnant for a minimum of 8 hours. Results from testing that demonstrate a combined lead mass in the total samples of 5 µg or greater indicate a lead line.
          Systems must provide an approximation of pipe volume to reach the service line, a map to show where the samples are coming from, and the lab results for every sample in an appendix to the water system narrative before collecting samples. 
          Every sample drawn and used as a basis of material classification must be included in the appendix. This method is only applicable for service connections that can reliably perform the required stagnation period, for example single family residences or a vacant multi family residence.`}
        displayConditionallyRequired
        hideError
        placeholder="Yes / No"
        name="inventoryMethodsPart3SequentialExplanation"
        textarea
      />
      <Field<string>
        component={NoRedHighlightTextField}
        displayConditionallyRequired
        hideError
        label="If other please explain:"
        name="inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation"
        textarea
      />
    </GeneralContainer>
  )
}

const Message = styled.p`
  text-decoration: underline;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
`

export default ArizonaMethodsPart3
