import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import React, { useState } from 'react'
import { Form } from 'react-final-form'

import omit from 'lodash/omit'
import { useGetAllProgramTypesQuery } from 'src/modules/Programs/dataAccess'
import { Program, ProgramTypeName } from 'src/types/Programs'
import { SamplingEvent } from 'src/types/SamplingEvents'
import SamplingEventForm, {
  Values,
  createFormValidation,
  generateInitialValues
} from './Form'
import { useUpdateEventMutation } from './dataAccess'
import { useEventNames } from './hooks'

interface Props {
  program: Program
  programType: ProgramTypeName
  samplingEvent: SamplingEvent
  onClose(): void
}

const getUpdatedSamplingEventAnalytes = (values: Values) => {
  return values.samplingEventAnalytes.map(sea => ({
    ...sea,
    checkExceedance: sea.primary,
    exceedanceLimit:
      sea.exceedanceLimit === undefined ? null : sea.exceedanceLimit,
    isRange: !!sea.lowerLimit,
    lowerLimit: sea.lowerLimit === undefined ? null : sea.lowerLimit
  }))
}

const EditSamplingEvent = ({
  onClose,
  program,
  programType,
  samplingEvent
}: Props): JSX.Element => {
  const isPitcherFilterProgram = programType === 'PF'
  const isReplacementProgram = programType === 'REPLACEMENT'
  const eventNames = useEventNames(program.id)
  const [error, setError] = useState<Error>()
  const [triggerUpdate] = useUpdateEventMutation()
  const { data: programTypes = [] } = useGetAllProgramTypesQuery()

  const pitcherFilterTypeId = programTypes.find(pt => pt.name === 'PF')?.id
  const hasPitcherFilterSku = samplingEvent.protocolDefinitions?.some(
    p =>
      p.sku &&
      p.sku.skuProgramTypes &&
      p.sku.skuProgramTypes.some(
        spt => spt.programTypeId === pitcherFilterTypeId
      )
  )

  const editSamplingEvent = async (values: Values) => {
    const goalNumber = parseInt(values.totalGoal || '0', 10)
    const updatedEvent = {
      endsOn: moment(values.endsOn).toISOString(),
      id: samplingEvent.id,
      name: values.name?.trim(),
      primaryAnalyte:
        values.samplingEventAnalytes.find(sea => sea.primary)?.analyteId ??
        null,
      samplingEventAnalytes: getUpdatedSamplingEventAnalytes(values),
      startsOn: moment(values.startsOn).toISOString(),
      totalPitchersGoal: goalNumber,
      totalSamplesGoal: goalNumber
    }
    const response = await triggerUpdate(
      isPitcherFilterProgram || hasPitcherFilterSku
        ? omit(updatedEvent, 'primaryAnalyte', 'samplingEventAnalytes')
        : updatedEvent
    )

    if ('error' in response) {
      setError(new Error('Please try again or contact support@120water.com.'))
      return { [FORM_ERROR]: 'Could not submit form' }
    }

    onClose()
    return undefined
  }

  return (
    <Form
      initialValues={generateInitialValues(program, samplingEvent)}
      // We are setting initialValuesEqual to always be true due to an issue with react-final-form-arrays
      // There may be a better solution in actually comparing the analyte arrays
      // https://github.com/final-form/react-final-form-arrays/issues/61
      initialValuesEqual={() => true}
      mutators={{ ...arrayMutators }}
      onSubmit={editSamplingEvent}
      render={({ handleSubmit, values, submitting, valid }) => (
        <SamplingEventForm
          error={error}
          valid={valid}
          onSubmit={handleSubmit}
          program={{ ...program, events: eventNames }}
          values={values}
          submitting={submitting}
          onCancel={onClose}
          type="edit"
          isPitcherFilterProgram={isPitcherFilterProgram || hasPitcherFilterSku}
          isReplacementProgram={isReplacementProgram}
        />
      )}
      validate={createFormValidation(isReplacementProgram, eventNames, 'edit')}
      validateOnBlur
    />
  )
}

export default EditSamplingEvent
