import { Button, Loader } from '@120wateraudit/envirio-components'
import { PWSSamplingEventStatus } from '@120wateraudit/envirio-components/dist/models'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import { uniqBy } from 'lodash'
import pluralize from 'pluralize'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Segment } from 'semantic-ui-react'
import styled from 'styled-components'

import { createLocationSamples } from 'src/API'
import Radio from 'src/components/Forms/Radio'
import { Row } from 'src/components/Layout'
import { useGetEventsInProgramQuery } from 'src/modules/Events/dataAccess'
import Modal from 'src/modules/Modal/ModalContents'
import { useGetPurchasedProgramTypesQuery } from 'src/modules/Programs/dataAccess'
import { useAccount } from 'src/router/UserProvider'
import { Location } from 'src/types/Location'
import { SamplingEvent } from 'src/types/SamplingEvents'
import { DEFAULT_TOAST } from 'src/utils/toast'

interface Props {
  collectionName?: 'Pitcher Filter' | 'Sample'
  programId: number
  selectedLocations: Location[]
  onClose(): void
  onCompletion(): void
}

const OrderKitsModal = ({
  collectionName = 'Sample',
  onClose,
  onCompletion,
  programId,
  selectedLocations
}: Props): JSX.Element => {
  const { id: accountId } = useAccount()
  const [event, setEvent] = useState<SamplingEvent>()
  const [collectionUnit, setCollectionUnit] = useState<string>(collectionName)
  const [step, setStep] = useState<'confirm' | 'select'>('select')
  const [isSaving, setIsSaving] = useState(false)
  const locationCount = pluralize('Location', selectedLocations.length, true)
  const title = `Order Kits for ${locationCount}`
  const { data: allProgramTypes = [] } = useGetPurchasedProgramTypesQuery()
  const pitcherFilterTypeId = allProgramTypes.find(pt => pt.name === 'PF')?.id

  const onPrimary = async () => {
    if (!event) {
      throw new Error('No event was selected in Order Kits')
    }

    if (step === 'select') {
      return setStep('confirm')
    }

    try {
      setIsSaving(true)
      await createLocationSamples({
        accountId,
        locationIds: selectedLocations.map(l => l.id),
        programId,
        samplingEventId: event.id
      })
      onCompletion()
      onClose()
      toast(`Created ${pluralize(collectionUnit)} for ${locationCount}`, {
        ...DEFAULT_TOAST,
        type: 'success'
      })
    } catch (e) {
      toast('Something went wrong. Please try again.', {
        ...DEFAULT_TOAST,
        type: 'error'
      })
    } finally {
      setIsSaving(false)
    }
  }

  const onBack = () => {
    if (step === 'confirm') {
      setEvent(undefined)
      setStep('select')
    } else {
      onClose()
    }
  }

  return (
    <Modal
      cancelAction={
        <Button onClick={onClose} disabled={isSaving}>
          Cancel
        </Button>
      }
      icon={faBoxOpen}
      primaryAction={
        <Button
          type="submit"
          variant="primary"
          disabled={!event || isSaving}
          onClick={onPrimary}>
          {isSaving
            ? 'Ordering...'
            : step === 'confirm'
            ? 'Order Kits'
            : 'Next'}
        </Button>
      }
      secondaryActions={
        <Button disabled={isSaving} onClick={onBack}>
          Back
        </Button>
      }
      title={title}>
      {step === 'select' && (
        <EventPicker
          collectionName={collectionName}
          pitcherProgramType={pitcherFilterTypeId}
          programId={programId}
          selectEvent={setEvent}
          selectedEvent={event}
          setCollectionUnit={setCollectionUnit}
        />
      )}
      {step === 'confirm' && (
        <Confirmation
          collectionName={collectionUnit}
          event={event}
          selectedLocations={selectedLocations}
        />
      )}
    </Modal>
  )
}

interface PickerProps {
  collectionName: 'Pitcher Filter' | 'Sample'
  pitcherProgramType?: number
  programId: number
  selectedEvent?: SamplingEvent
  selectEvent(event?: SamplingEvent): void
  setCollectionUnit: (unit: string) => void
}

const EventPicker = ({
  collectionName,
  pitcherProgramType,
  programId,
  selectEvent,
  selectedEvent,
  setCollectionUnit
}: PickerProps) => {
  const { data: events = [] } = useGetEventsInProgramQuery(programId, {
    selectFromResult: ({ data, ...result }) => ({
      data: data
        ? data.items.filter(e => e.status === PWSSamplingEventStatus.Active)
        : [],
      ...result
    })
  })

  if (!pitcherProgramType) {
    return <Loader />
  }

  return (
    <>
      <p>Select an Event to order kits from.</p>
      <p>Locations will also be assigned to the selected Event.</p>
      <Options>
        {events.map(event => (
          <EventOption
            checked={selectedEvent?.id === event.id}
            collectionName={collectionName}
            event={event}
            key={event.id}
            onSelect={() => selectEvent(event)}
            pitcherProgramType={pitcherProgramType}
            setCollectionUnit={setCollectionUnit}
          />
        ))}
      </Options>
    </>
  )
}

const Options = styled(Segment.Group)`
  max-height: 260px;
  overflow: auto;
`

interface OptionProps {
  checked?: boolean
  collectionName: string
  event: SamplingEvent
  pitcherProgramType?: number
  onSelect(): void
  setCollectionUnit: (unit: string) => void
}

const EventOption = ({
  checked = false,
  collectionName,
  event,
  onSelect,
  pitcherProgramType,
  setCollectionUnit
}: OptionProps): JSX.Element => {
  const skus = uniqBy(
    event.protocolDefinitions?.map(p => p.sku),
    'id'
  ).filter(Boolean)

  const hasPitcherFilterSkus =
    pitcherProgramType &&
    skus?.some(s =>
      s.skuProgramTypes.some(spt => spt.programTypeId === pitcherProgramType)
    )
  const unit = hasPitcherFilterSkus ? 'Pitcher Filter' : 'Sample'

  return (
    <Option
      onClick={() => {
        setCollectionUnit(unit)
        onSelect()
      }}>
      <Radio checked={checked} size="big" />
      <Row>
        <EventName>{event.name}</EventName>
        {skus.length >= 1 && (
          <SkuDescription>
            <p>
              This Event uses SKU{skus.length > 1 ? 's' : ''}: {skus[0].name} (
              {skus[0].sku})
            </p>
            <p>
              {pluralize(unit ? unit : collectionName)} per Kit:{' '}
              {event.protocolDefinitions.length}
            </p>
          </SkuDescription>
        )}
      </Row>
    </Option>
  )
}

const Option = styled(Segment)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background-color: rgba(194, 207, 224, 0.2);
  }
  & > div {
    margin-left: 16px;
    flex: 1;
    justify-content: space-between;
    & * {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const EventName = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 1.33333333;
  text-align: left;
  max-width: 250px;
  word-break: break-all;
`

const SkuDescription = styled.div`
  font-style: italic;
  text-align: right;
`

const Confirmation = ({
  collectionName,
  event,
  selectedLocations
}: {
  collectionName: string
  event?: SamplingEvent
  selectedLocations: Location[]
}) => {
  return (
    <>
      <p>
        You are about to create{' '}
        <strong>
          {pluralize(
            collectionName,
            selectedLocations.length *
              (event?.protocolDefinitions?.length ?? 0),
            true
          )}
        </strong>{' '}
        for{' '}
        <strong>{pluralize('Location', selectedLocations.length, true)}</strong>
        .
      </p>
      <p>Are you sure?</p>
    </>
  )
}

export default OrderKitsModal
