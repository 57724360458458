import React from 'react'

import { Button } from '../../Locations/modals/AssignProgramsModal/shared'
import { TwoButtonGrid } from 'src/modules/Modal/shared'

interface Props {
  actionTitle: string
  loading: boolean
  onSubmit: () => Promise<unknown>
  onClose: () => void
  buttonVariant?: string
}

const ModalButtonGrid: React.FC<Props> = ({
  actionTitle,
  buttonVariant,
  loading,
  onSubmit,
  onClose
}) => {
  return (
    <TwoButtonGrid>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        variant={buttonVariant || 'primary'}
        disabled={loading}
        onClick={onSubmit}>
        {actionTitle}
      </Button>
    </TwoButtonGrid>
  )
}

export default ModalButtonGrid
