import { useMemo } from 'react'

import {
  Contact as ECContact,
  ContactRelationType,
  ContactStatus
} from '@120wateraudit/envirio-components/dist/models'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import { contactsClient } from 'src/apollo-clients'
import {
  GET_CONTACTS_QUERY,
  GET_CONTACT_LANGUAGES_QUERY
} from 'src/containers/Contacts/data-access'
import { ContactLanguageQuery } from 'src/containers/Contacts/data-access/types'
import { useAccount } from 'src/router/UserProvider'

export const useGetLanguagesQuery = () => {
  const { data, loading } = useQuery<ContactLanguageQuery>(
    GET_CONTACT_LANGUAGES_QUERY,
    { client: contactsClient }
  )
  return {
    languages: data?.contactLanguages?.reduce<Record<number, string>>(
      (acc, curr) => {
        acc[curr.id] = curr.language
        return acc
      },
      {}
    ),
    loading
  }
}

export type Contact = ECContact & { preferredLanguage?: string }

export const useGetContactsQuery = (
  locationId: number,
  skip = false
): { data: Contact[]; isLoading: boolean } => {
  const { id: accountId } = useAccount()
  const { data, loading: isLoading } = useQuery<{ contacts: Contact[] }>(
    GET_CONTACTS_QUERY,
    {
      client: contactsClient,
      fetchPolicy: 'cache-and-network',
      skip,
      variables: {
        accountId,
        relationId: locationId,
        relationType: ContactRelationType.Locations,
        status: ContactStatus.Active
      }
    }
  )
  return { data: data?.contacts || [], isLoading }
}

export const useGetPrimaryContactQuery = (
  locationId: number,
  skip = false
): {
  isLoading: boolean
  primaryContact?: Contact
} => {
  const { languages, loading: languageLoading } = useGetLanguagesQuery()
  const { data, isLoading } = useGetContactsQuery(locationId, skip)
  const primaryContact = useMemo(
    // Just hardcoding this to find the first primary contact.
    () => data.find(contact => contact.designation === 1),
    [data]
  )
  return {
    isLoading: languageLoading || isLoading,
    primaryContact: primaryContact && {
      ...primaryContact,
      preferredLanguage: languages?.[primaryContact?.primaryLanguageId || -1]
    }
  }
}

export const UPDATE_CONTACT_MUTATION = gql`
  mutation updateContact(
    $accountId: Int!
    $contactId: Int!
    $contact: ContactInput!
    $relationType: String
    $relationId: Int
  ) {
    updateContact(
      accountId: $accountId
      contactId: $contactId
      contact: $contact
      relationId: $relationId
      relationType: $relationType
    ) {
      id
      accountId
      relationType
      relationId
      firstName
      lastName
      email
      primaryPhone
      primaryPhoneExt
      isPhoneMobile
      alternativePhone
      alternativePhoneExt
      isAlternativePhoneMobile
      addressLine1
      addressLine2
      city
      stateOrProvince
      postalCode
      fax
      salutation
    }
  }
`

interface ContactInput {
  addressLine1?: null | string
  addressLine2?: null | string
  alternativePhone?: null | string
  alternativePhoneExt?: null | string
  city?: null | string
  contactMethod?: null | string
  contactTypeId?: number
  customerBillingNumber?: null | string
  designation?: null | number
  email?: null | string
  fax?: null | string
  firstName?: null | string
  isAlternativePhoneMobile?: null | boolean
  isPhoneMobile?: null | boolean
  lastName?: null | string
  postalCode?: null | string
  primaryLanguageId?: number
  primaryPhone?: null | string
  primaryPhoneExt?: null | string
  salutation?: null | string
  stateOrProvince?: null | string
  status?: null | string
  titleCompanyOrganization?: null | string
}

export interface ContactUpdate {
  accountId: number
  contact: ContactInput
  contactId: number
  relationId: number
  relationType: string
}

export interface ContactUpdateResponse {
  updateContact: {
    accountId: number
    addressLine1?: string
    addressLine2?: string
    city?: string
    contactTypeId?: number
    designation?: number
    email?: string
    fax?: string
    firstName: string
    id: number
    lastName: string
    postalCode?: string
    primaryPhone?: string
    primaryPhoneExt?: string
    relationId: number
    relationType: string
    salutation?: string
    stateOrProvince?: string
  }
}
