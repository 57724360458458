import React, { FC, useState } from 'react'
import { toast } from 'react-toastify'

import { hardDelete } from 'src/API'
import { ViewPayload } from 'src/components/Table/types'
import ConfirmationModal from 'src/modules/Modal/ConfirmationModal'
import { LONG_TOAST } from 'src/utils/toast'

interface Props {
  activeViewName?: string
  resetNameView: ViewPayload
  viewId: number
  viewName: string
  onClose: () => void
  refetch: () => void
  setView?: (view: ViewPayload) => void
}

const DeleteSavedViewModal: FC<Props> = ({
  activeViewName,
  onClose,
  refetch,
  resetNameView,
  setView,
  viewId,
  viewName
}) => {
  const [deleteViewError, setDeleteViewError] = useState<string>()
  const deleteSavedView = async (savedViewId: number) => {
    try {
      toast('Deleting View...', LONG_TOAST)
      await hardDelete(`/pws/rest/savedViews/${savedViewId}`)
      if (setView && activeViewName && activeViewName === viewName) {
        setView(resetNameView)
      }
      refetch()
      onClose()
    } catch (error) {
      setDeleteViewError(
        'There seems to be an error, please try again or contact support for assistance.'
      )
      toast('View failed deleting, please try again.', {
        ...LONG_TOAST,
        type: 'error'
      })
    }
  }

  return (
    <ConfirmationModal
      body={`You are about to delete ${viewName} saved view.`}
      disabled={!!deleteViewError}
      error={deleteViewError}
      onClose={onClose}
      onConfirm={() => deleteSavedView(viewId)}
      title={!deleteViewError ? 'Delete This View' : 'Oops...'}
    />
  )
}

export default DeleteSavedViewModal
