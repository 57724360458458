import { titleCase } from '@120wateraudit/envirio-components/dist/utils/titleCase'

interface ErrorMessageOptions {
  ignoreReviewText?: boolean
}

const getReviewText = (options: ErrorMessageOptions = {}) => {
  return options.ignoreReviewText ? '' : 'Please review the following errors: '
}

const hasGraphqlErrors = (response: any) =>
  response.graphQLErrors && Array.isArray(response.graphQLErrors)

const hasGraphqlValidationErrors = (response: any) =>
  response.graphQLErrors &&
  Array.isArray(response.graphQLErrors) &&
  response.graphQLErrors.length &&
  response.graphQLErrors[0].validationErrors &&
  response.graphQLErrors[0].validationErrors[0].constraints

const getErrorMessageFromValidationErrors = (
  response: any,
  options: ErrorMessageOptions = {}
) => {
  const responseErrors = response.graphQLErrors[0].validationErrors
  const errorMessage = `${getReviewText(options)}${responseErrors
    .map(e => {
      return titleCase(e.constraints[Object.keys(e.constraints)[0]])
    })
    .join(', ')}`
  return errorMessage
}

export const getErrorMessagesFromResponse = (
  response: any,
  options: ErrorMessageOptions = {}
) => {
  if (!response) {
    throw new Error('No error response/status returned from server')
  }

  let errorMessage = ''

  if (hasGraphqlValidationErrors(response)) {
    errorMessage = getErrorMessageFromValidationErrors(response, options)
  }

  if (errorMessage === '' && hasGraphqlErrors(response)) {
    errorMessage = response.graphQLErrors.map(e => e.message).join(', ')
  }

  if (errorMessage === '' && response.message) {
    errorMessage = response.message
  }

  return errorMessage
}
