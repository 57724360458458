import styled from 'styled-components'
import {
  Device,
  mediaScreen
} from '@120wateraudit/envirio-components/dist/utils/mediaScreen'

const Column = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: 2rem;

  ${mediaScreen({
    device: Device.Tablet,
    style: { gap: '1rem' }
  })}
`

export default Column
