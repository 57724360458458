import { Container } from 'semantic-ui-react'
import styled from 'styled-components'

export { default as Card } from './Card'
export { default as Column } from './Column'
export { default as DataLink } from './DataLink'
export { default as DetailsLabel } from './DetailsLabel'
export { default as MapWrapper } from './MapWrapper'
export { default as NoMap } from './NoMap'
export { default as ThreeColumnGrid } from './ThreeColumnGrid'
export { default as TwoColumnGrid } from './TwoColumnGrid'
export { default as Value } from './Value'

export const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const TopPaddedContainer = styled(Container)`
  padding-top: 56px;
`
