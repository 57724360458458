import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  DateInput,
  DatesRangeInput,
  DateTimeInput,
  YearInput
} from 'semantic-ui-calendar-react'
import { Dropdown, Label } from 'semantic-ui-react'
import styled from 'styled-components'

import { FilterType, ValidFilterValue } from '../types'
import RemoveFilterButton from './RemoveFilterButton'

const YEAR_FORMAT = 'YYYY'
const DATE_FORMAT = 'MM-DD-YYYY'
const DATETIME_FORMAT = `${DATE_FORMAT} hh:mm A`

const OPERATORS = [
  {
    text: 'Today',
    value: 'today'
  },
  {
    text: 'Yesterday',
    value: 'yesterday'
  },
  {
    text: 'Last Week',
    value: 'lastWeek'
  },
  {
    text: 'Last Month',
    value: 'lastMonth'
  },
  {
    text: 'Before',
    value: 'lessThanOrEqualTo'
  },
  {
    text: 'After',
    value: 'greaterThanOrEqualTo'
  },
  {
    text: 'Between',
    value: 'between'
  }
]

const YEAR_OPERATORS = [
  {
    text: 'Before',
    value: 'lessThanOrEqualTo'
  },
  {
    text: 'After',
    value: 'greaterThanOrEqualTo'
  }
]

type DefaultValueGenerator = () => {
  operator: string
  value: ValidFilterValue
  value2?: null | ValidFilterValue
}

const OPERATOR_DEFAULTS: Record<string, DefaultValueGenerator> = {
  between: () => ({
    operator: 'between',
    value: moment().startOf('day').toISOString(),
    value2: moment().endOf('day').toISOString()
  }),
  greaterThanOrEqualTo: () => ({
    operator: 'greaterThanOrEqualTo',
    value: moment().endOf('day').toISOString(),
    value2: null
  }),
  lastMonth: () => ({
    operator: 'greaterThanOrEqualTo',
    value: moment().subtract(1, 'month').startOf('day').toISOString(),
    value2: null
  }),
  lastWeek: () => ({
    operator: 'greaterThanOrEqualTo',
    value: moment().subtract(7, 'days').startOf('day').toISOString(),
    value2: null
  }),
  lessThanOrEqualTo: () => ({
    operator: 'lessThanOrEqualTo',
    value: moment().startOf('day').toISOString(),
    value2: null
  }),
  today: () => ({
    operator: 'between',
    value: moment().startOf('day').toISOString(),
    value2: moment().endOf('day').toISOString()
  }),
  yesterday: () => ({
    operator: 'between',
    value: moment().subtract(1, 'days').startOf('day').toISOString(),
    value2: moment().subtract(1, 'days').endOf('day').toISOString()
  })
}

const getValueForDatePicker = (
  format: string,
  operator?: string,
  value?: ValidFilterValue,
  value2?: ValidFilterValue
) => {
  if (!value || !operator || typeof value !== 'string') {
    return ''
  }

  if (operator !== 'between') {
    return moment(value).format(format)
  }

  if (typeof value2 !== 'string') {
    return `${moment(value).format(format)} - ${moment().format(format)}`
  }

  return `${moment(value).format(format)} - ${moment(value2).format(format)}`
}

interface Props {
  label: string
  operator?: string
  removeFilter: () => void
  type:
    | FilterType.DatePicker
    | FilterType.DateTimePicker
    | FilterType.YearPicker
  value?: ValidFilterValue
  value2?: ValidFilterValue
  onChangeValue(
    value?: ValidFilterValue,
    operator?: string,
    value2?: null | ValidFilterValue
  ): void
}

const DatePickerFilter = ({
  label,
  onChangeValue,
  operator,
  removeFilter,
  type,
  value,
  value2
}: Props): JSX.Element => {
  const isDateTimePicker = type === FilterType.DateTimePicker
  const isYearPicker = type === FilterType.YearPicker
  const DatePicker = isDateTimePicker
    ? DateTimeInput
    : isYearPicker
    ? YearInput
    : DateInput
  const format = isDateTimePicker
    ? DATETIME_FORMAT
    : isYearPicker
    ? YEAR_FORMAT
    : DATE_FORMAT
  const [displayedOperator, setOperator] = useState(
    operator || 'lessThanOrEqualTo'
  )
  const pickerValue = getValueForDatePicker(
    format,
    displayedOperator,
    value,
    value2
  )
  const onDropDownChange = (_, data) => {
    setOperator(data.value)
    const { operator, value, value2 } = OPERATOR_DEFAULTS[data.value]()
    if (isYearPicker) {
      const val =
        value && typeof value === 'string' && moment(value).format(format)
      const val2 =
        value2 && typeof value2 === 'string' && moment(value2).format(format)
      onChangeValue(val, operator, val2)
    } else {
      onChangeValue(value, operator, value2)
    }
  }

  useEffect(() => {
    if (isYearPicker) {
      const val =
        value && typeof value === 'string' && moment(value).format(format)
      const val2 =
        value2 && typeof value2 === 'string' && moment(value2).format(format)
      onChangeValue(val, displayedOperator, val2)
    } else {
      onChangeValue(value, displayedOperator, value2)
    }
  }, [])

  const onDatePickerChange = (_, data) => {
    if (isYearPicker) {
      onChangeValue(moment(data.value).format(format))
    } else {
      onChangeValue(moment(data.value, format).toISOString())
    }
  }

  switch (displayedOperator) {
    case 'between':
      return (
        <DateRangePicker
          isYearPicker={isYearPicker}
          label={label}
          onChangeValue={onChangeValue}
          onOperatorChange={onDropDownChange}
          operator={displayedOperator}
          removeFilter={removeFilter}
          value={value}
          value2={value2}
        />
      )
    case 'lastMonth':
    case 'lastWeek':
    case 'today':
    case 'yesterday':
      return (
        <PickerFilter>
          <OperatorLabel
            isYearPicker={isYearPicker}
            label={label}
            onChange={onDropDownChange}
            operator={displayedOperator}
          />
          <StyledRemoveFilterButton removeFilter={removeFilter} />
        </PickerFilter>
      )
    default:
      return (
        <PickerFilter>
          <DatePicker
            dateFormat={isYearPicker ? YEAR_FORMAT : DATE_FORMAT}
            dateTimeFormat={DATETIME_FORMAT}
            iconPosition="right"
            inlineLabel
            label={
              <OperatorLabel
                isYearPicker={isYearPicker}
                label={label}
                operator={displayedOperator}
                onChange={onDropDownChange}
              />
            }
            onChange={onDatePickerChange}
            placeholder="Date"
            timeFormat="AMPM"
            transparent
            value={pickerValue}
          />
          <StyledRemoveFilterButton removeFilter={removeFilter} />
        </PickerFilter>
      )
  }
}

const OperatorLabel = ({ label, onChange, operator, isYearPicker }) => {
  return (
    <PickerFilterLabel>
      {label}
      <Dropdown
        onChange={onChange}
        options={isYearPicker ? YEAR_OPERATORS : OPERATORS}
        placeholder="Operator"
        style={{ paddingLeft: '5px' }}
        transparent
        value={operator}
      />
    </PickerFilterLabel>
  )
}

const DateRangePicker = ({
  isYearPicker,
  label,
  onChangeValue,
  onOperatorChange,
  operator,
  removeFilter,
  value,
  value2
}) => {
  const [start, setStart] = useState(moment(value).format(DATE_FORMAT))
  const [end, setEnd] = useState(
    moment(value2 || undefined).format(DATE_FORMAT)
  )
  const onRangeChange = (_, data) => {
    const [start, end] = data.value.split(' - ')

    setStart(start)
    setEnd(end || '')
  }

  useEffect(() => {
    if (start && end) {
      onChangeValue(
        moment(start, DATE_FORMAT),
        operator,
        moment(end, DATE_FORMAT).endOf('day')
      )
    }
    // I'm disabling this check here because I know `onChangeValue` is shifting too often.
    // I don't think it actually changes, but the reference does. It needs memoized higher
    // but I really just want to get this out. :(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end])
  return (
    <PickerFilter>
      <DatesRangeInput
        iconPosition="right"
        label={
          <OperatorLabel
            isYearPicker={isYearPicker}
            label={label}
            operator={operator}
            onChange={onOperatorChange}
          />
        }
        dateFormat={DATE_FORMAT}
        onChange={onRangeChange}
        placeholder="From - To"
        style={{ width: '200px' }}
        transparent
        value={`${start} - ${end}`}
      />
      <StyledRemoveFilterButton removeFilter={removeFilter} />
    </PickerFilter>
  )
}

const PickerFilter = styled.div`
   {
    background-color: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 0.33rem;
    max-height: 38px;
    padding: 0.5em 0.75em;
    margin: 4px;
    display: flex;
    align-items: center;
  }
`

const PickerFilterLabel = styled(Label)`
  &&& {
    font-size: inherit;
    background-color: #fff;
    color: #9ca3af;
  }
`

const StyledRemoveFilterButton = styled(RemoveFilterButton)`
  &&& {
    margin-left: 14px;
  }
`

export default DatePickerFilter
