import { colors } from '@120wateraudit/envirio-components'
import {
  Device,
  mediaScreen
} from '@120wateraudit/envirio-components/dist/utils/mediaScreen'
import styled from 'styled-components'

const responsive = mediaScreen({
  device: Device.Tablet,
  style: {
    padding: '1rem'
  }
})

const Card = styled.section<{ uiBordered?: boolean }>`
  background: ${colors.white};
  border-radius: 4px;
  border: ${props => (props.uiBordered ? `2px solid #f6f7f7` : 'inherit')};
  padding: 2rem 1.25rem;
  display: grid;
  grid-auto-flow: row;
  gap: 1rem 0;
  & h6 {
    margin: 0;
  }

  ${responsive}
`

export default Card
