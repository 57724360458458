import { colors } from '@120wateraudit/envirio-components'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1rem 0;
  justify-items: center;
  margin: 3rem 0;
  > .close.icon {
    justify-self: right;
    cursor: pointer;
  }
  > h6 {
    text-align: center;
    padding: 0;
    margin: 0;
  }
`

export const Controls = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0 1rem;
  margin-top: 2rem;
`

export const Warning = styled.p`
  font-size: 14px;
  color: ${colors.warning};
  margin-bottom: 7px;
`
