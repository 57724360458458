import createIndexReducer from './helpers/IndexReducer'
import actions from '../actions/samples'

const reducer = createIndexReducer({
  collectionName: 'samples',
  entityName: 'sample',
  fetchAction: actions.collectionActions.fetchRequest,
  fetchSuccessAction: actions.collectionActions.fetchSuccess,
  fetchFailureAction: actions.collectionActions.fetchFailure,
  unloadAction: actions.collectionActions.unload,
  createAction: actions.createActions.createRequest,
  createSuccess: actions.createActions.createSuccess,
  createFailureAction: actions.createActions.createFailure,
  updateActionRequest: actions.updateActions.updateRequest,
  updateSuccessAction: actions.updateActions.updateSuccess,
  updateFailureAction: actions.updateActions.updateFailure,
  errorClearAction: actions.errorActions.clear
})

export default reducer
