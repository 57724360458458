import { Button } from '@120wateraudit/envirio-components'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useContext } from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Form, Loader } from 'semantic-ui-react'
import { DATE_FORMAT } from 'src/components/Forms/Datepicker'
import ExternalLink from 'src/components/ExternalLink'
import {
  useServiceLineMaterials,
  useServiceLineUpdateMutation
} from 'src/containers/ServiceLines/dataAccess'
import { ModalContext } from 'src/modules/Modal'
import { DrawerContents } from 'src/modules/Modal/Drawer'
import { useAccount } from 'src/router/UserProvider'
import { Asset } from 'src/types/Asset'
import { toastSuccess } from 'src/utils/toast'
import StateSubmissionValidationErrors from './StateSubmissionValidationErrors'
import {
  Values,
  useInitialValues,
  validate,
  useStateSubmissionValidationStatus
} from '../dataAccess'
import AssetDrawerForm from './AssetForm'
import styled from 'styled-components'
import { getValueForLeadSolderLeadLined } from 'src/utils/serviceLineUtils'

interface Props {
  asset: Asset
  onClose(): void
  refetch(): void
}

const EditDrawer = ({ asset, onClose, refetch }: Props): JSX.Element => {
  const { openModal } = useContext(ModalContext)
  const accountId = `${useAccount().id}`
  const initialValues = useInitialValues(asset)
  const [serviceLineUpdate] = useServiceLineUpdateMutation()

  const { stateValidationErrors, stateValidationErrorsList } =
    useStateSubmissionValidationStatus(asset.serviceLineAsset.id)

  const { data: materials } = useServiceLineMaterials()

  const onSubmitEdit = async (values: Values) => {
    let publicDiameterOverTwoInches = values.publicDiameterOverTwoInches
    let privateDiameterOverTwoInches = values.privateDiameterOverTwoInches
    if (publicDiameterOverTwoInches === 'Unknown') {
      publicDiameterOverTwoInches = null
    }
    if (privateDiameterOverTwoInches === 'Unknown') {
      privateDiameterOverTwoInches = null
    }

    const privateLeadSolderCalculation = getValueForLeadSolderLeadLined(
      values,
      materials,
      'private'
    )
    const publicLeadSolderCalculation = getValueForLeadSolderLeadLined(
      values,
      materials,
      'public'
    )

    const lineResponse = await serviceLineUpdate({
      variables: {
        accountId,
        asset: {
          description: values.description ?? '',
          externalId: values.externalId ?? '',
          locationId: values.locationId
        },
        assetId: `${asset.id}`,
        serviceLine: {
          fittingVerifiedBy: values.fittingVerifiedBy ?? '',
          fittingsVerificationMethod: values.fittingsVerificationMethod,
          fittingsVerifiedDate: values.fittingsVerifiedDate
            ? moment(values.fittingsVerifiedDate, DATE_FORMAT).toISOString()
            : null,
          leadFittings: values.leadFittings,
          leadSolder: values.leadSolder,
          otherLeadEquipment: values.otherLeadEquipment,
          privateInstalledDate: values.privateInstalledDate
            ? moment(values.privateInstalledDate, DATE_FORMAT).toISOString()
            : null,
          privateLineDepth: parseInt(values.privateLineDepth, 10),
          privateLineDiameter: values.privateLineDiameter,
          privateDiameterOverTwoInches,
          privateMaterial: values.privateMaterial,
          privateLeadSolderLeadLined: privateLeadSolderCalculation,
          privateRemovalDate: values.privateRemovalDate
            ? moment(values.privateRemovalDate, DATE_FORMAT).toISOString()
            : null,
          privateVerificationMethod: values.privateVerificationMethod,
          privateVerified: values.privateVerified,
          privateVerifiedBy: values.privateVerifiedBy ?? '',
          privateVerifiedDate: values.privateVerifiedDate
            ? moment(values.privateVerifiedDate, DATE_FORMAT).toISOString()
            : null,
          privateClassificationBasis: values.privateClassificationBasis,
          privateNotes: values.privateNotes,
          publicClassificationBasis: values.publicClassificationBasis,
          publicInstalledDate: values.publicInstalledDate
            ? moment(values.publicInstalledDate, DATE_FORMAT).toISOString()
            : null,
          publicLineDepth: parseInt(values.publicLineDepth, 10),
          publicLineDiameter: values.publicLineDiameter,
          publicDiameterOverTwoInches,
          publicMaterial: values.publicMaterial,
          publicLeadSolderLeadLined: publicLeadSolderCalculation,
          publicPreviouslyLead: values.publicPreviouslyLead,
          publicRemovalDate: values.publicRemovalDate
            ? moment(values.publicRemovalDate, DATE_FORMAT).toISOString()
            : null,
          publicVerificationMethod: values.publicVerificationMethod,
          publicVerified: values.publicVerified,
          publicVerifiedBy: values.publicVerifiedBy ?? '',
          publicVerifiedDate: values.publicVerifiedDate
            ? moment(values.publicVerifiedDate, DATE_FORMAT).toISOString()
            : null,
          publicNotes: values.publicNotes
        }
      }
    })

    toastSuccess('Asset has been updated')
    refetch()

    openModal(
      'viewAsset',
      { asset: lineResponse?.data?.serviceLineUpdate, refetch },
      'drawer'
    )
  }

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmitEdit}
      render={({ handleSubmit, submitting, valid }) => (
        <Form onSubmit={handleSubmit}>
          <DrawerContents
            header={
              <Header
                assetId={asset.id}
                locationId={asset.location.id}
                submitting={submitting}
                valid={valid}
                onClose={onClose}
              />
            }>
            <StateSubmissionValidationErrorsContainer>
              <StateSubmissionValidationErrors
                stateValidationErrors={stateValidationErrors}
                scroll
              />
            </StateSubmissionValidationErrorsContainer>

            <AssetDrawerForm
              type="edit"
              stateValidationErrorsList={stateValidationErrorsList}
            />
          </DrawerContents>
        </Form>
      )}
      validate={validate}
    />
  )
}

const Header = ({
  assetId,
  locationId,
  onClose,
  submitting,
  valid
}: {
  assetId: number
  locationId: number
  submitting: boolean
  valid: boolean
  onClose(): void
}): JSX.Element => {
  return (
    <>
      <div>
        <h3>Edit Record</h3>
        <ExternalLink to={`/locations/${locationId}/inventory/${assetId}`}>
          View All Details
        </ExternalLink>
      </div>
      <div>
        <Button
          disabled={!valid || submitting}
          style={{ marginRight: '7px' }}
          variant="primary">
          {submitting ? 'Saving…' : 'Save'}{' '}
          <Loader active={submitting} inline size="tiny" />
        </Button>
        <Button
          disabled={submitting}
          onClick={onClose}
          type="button"
          variant="error">
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </div>
    </>
  )
}

const StateSubmissionValidationErrorsContainer = styled.div`
  margin: 10px 24px 0 24px;
`

export default EditDrawer
