import { Analyte } from '@120wateraudit/envirio-components/dist/models'

import { useData } from '.'

export const useAnalytes = (id: number, type: string) => {
  const api = type === 'program' ? `program/${id}` : `$account/${id}`
  const url = `/pws/rest/analytes/${api}`
  const {
    data: analytes,
    loading,
    refetch,
    total: totalAnalytes
  } = useData<Analyte>(url, undefined, { isPaginated: false })
  return { analytes, loading, refetch, totalAnalytes }
}
