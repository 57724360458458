import { useUser } from 'src/router/UserProvider'

export const useHasPermissions = (permissions: string[]): boolean => {
  const { user } = useUser()

  if (!user || !user.permissions) {
    return false
  }
  return permissions.some( p => user.permissions.includes(p))
}
