import React, { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'

interface Props {
  className?: string
  style?: React.CSSProperties
  to: LinkProps['to']
}

const LinkCell: FC<Props> = ({ children, className, style, to }) => {
  if (!children) {
    return <>--</>
  }

  return (
    <Link className={className} style={style} to={to}>
      {children}
    </Link>
  )
}

export default LinkCell
