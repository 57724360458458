import {
  MutationFunctionOptions,
  MutationResult,
  MutationTuple,
  QueryHookOptions,
  useMutation,
  useQuery
} from '@apollo/client'

import { pwsClient } from 'src/apollo-clients'
import { useAccount } from 'src/router/UserProvider'
import {
  SERVICE_LINES_QUERY,
  SERVICE_LINE_CREATE_MUTATION,
  SERVICE_LINE_DIAMETER_QUERY,
  SERVICE_LINE_MATERIAL_QUERY,
  SERVICE_LINE_UPDATE_MUTATION
} from './queries'
import {
  ServiceLineCreateMutation,
  ServiceLineCreateMutationVariables,
  ServiceLineMaterialQuery,
  ServiceLineMaterialQueryVariables,
  ServiceLineUpdateMutation,
  ServiceLineUpdateMutationVariables,
  ServiceLinesQuery,
  ServiceLinesQueryVariables,
  ServiceLineDiameterQuery,
  ServiceLineDiameterQueryVariables
} from './types'

export const useInventory = (locationId?: string) => {
  const { id: accountId } = useAccount()
  const queryOptions: QueryHookOptions<
    ServiceLinesQuery,
    ServiceLinesQueryVariables
  > = {
    client: pwsClient,
    fetchPolicy: 'cache-and-network',
    skip: !accountId || !locationId,
    variables: { accountId: `${accountId}`, locationId }
  }
  const { data, loading, refetch } = useQuery<
    ServiceLinesQuery,
    ServiceLinesQueryVariables
  >(SERVICE_LINES_QUERY, queryOptions)
  const serviceLines = data?.serviceLines
  return { loading, refetch, serviceLines }
}

export const useServiceLineMaterials = () => {
  const { id: accountId } = useAccount()
  return useQuery<ServiceLineMaterialQuery, ServiceLineMaterialQueryVariables>(
    SERVICE_LINE_MATERIAL_QUERY,
    {
      client: pwsClient,
      variables: {
        accountId: `${accountId}`
      }
    }
  )
}

export const useServiceLineDiameters = () => {
  const { id: accountId } = useAccount()
  return useQuery<ServiceLineDiameterQuery, ServiceLineDiameterQueryVariables>(
    SERVICE_LINE_DIAMETER_QUERY,
    {
      client: pwsClient,
      variables: {
        accountId: accountId
      }
    }
  )
}

type ServiceLineCreateMutationFunction = (
  options?: MutationFunctionOptions<
    ServiceLineCreateMutation,
    ServiceLineCreateMutationVariables
  >
) => Promise<MutationResult<ServiceLineCreateMutation>>

type ServiceLineUpdateMutationFunction = (
  options?: MutationFunctionOptions<
    ServiceLineUpdateMutation,
    ServiceLineUpdateMutationVariables
  >
) => Promise<MutationResult<ServiceLineUpdateMutation>>

const makeServiceLineMutationFunction = mutation => {
  return (
    options?: MutationFunctionOptions<
      ServiceLineCreateMutation | ServiceLineUpdateMutation,
      ServiceLineCreateMutationVariables | ServiceLineUpdateMutationVariables
    >
  ) => {
    return mutation(options)
  }
}

export const useServiceLineCreateMutation = (): MutationTuple<
  ServiceLineCreateMutation,
  ServiceLineCreateMutationVariables
> => {
  const [mutation, result] = useMutation<
    ServiceLineCreateMutation,
    ServiceLineCreateMutationVariables
  >(SERVICE_LINE_CREATE_MUTATION, { client: pwsClient })
  return [
    makeServiceLineMutationFunction(
      mutation
    ) as ServiceLineCreateMutationFunction,
    result
  ]
}

export const useServiceLineUpdateMutation = (): MutationTuple<
  ServiceLineUpdateMutation,
  ServiceLineUpdateMutationVariables
> => {
  const [mutation, result] = useMutation<
    ServiceLineUpdateMutation,
    ServiceLineUpdateMutationVariables
  >(SERVICE_LINE_UPDATE_MUTATION, { client: pwsClient })

  return [
    makeServiceLineMutationFunction(
      mutation
    ) as ServiceLineUpdateMutationFunction,
    result
  ]
}
