import { useCallback, useContext, useMemo } from 'react'

import { get } from 'src/API'
import { RowAction } from 'src/components/Table/types'
import { useHasRole } from 'src/hooks'

import { ModalContext } from 'src/modules/Modal'
import { useRemoveAttachmentAssociationMutation } from 'src/services'
import { Attachment } from 'src/types/Attachment'
import { toastError, toastSuccess } from 'src/utils/toast'
import { getAttachmentFileUrl } from '../dataAccess'

const ROLES_WITH_PERMISSION_TO_DELETE = ['PWS Account Admin']

const useViewAction = () => {
  const { openModal } = useContext(ModalContext)
  return useCallback(
    (attachment: Attachment) => {
      openModal('viewAttachment', { attachment }, 'drawer')
    },
    [openModal]
  )
}

const useDownloadAction = () => {
  return useCallback(async (attachment: Attachment) => {
    const s3url = await get(getAttachmentFileUrl(attachment.id))
    if (s3url) {
      const s3urlTag = document.createElement('a')
      s3urlTag.href = s3url
      s3urlTag.click()
    }
  }, [])
}

const useDeleteAction = ({relatedEntityId, relatedEntityType}: {relatedEntityId: number, relatedEntityType: 'inventory' | 'locations' | 'pwsSamples' | 'assets' | 'submissionTemplates'}): {
  action: RowAction<Attachment>[]
  isDeleting: boolean
} => {
  const { closeModal, openModal } = useContext(ModalContext)

  const [removeAttachmentAssociation, { isLoading: isDeleting }] =
  useRemoveAttachmentAssociationMutation()
  const onDelete = useCallback(
    async (attachment: Attachment) => {
      try {
        await removeAttachmentAssociation({
          attachmentId: attachment.id,
          relatedEntityId: relatedEntityId,
          relatedEntityType: relatedEntityType
        }).unwrap()
        toastSuccess('Successfully deleted document')
      } catch {
        toastError('An error occurred during deletion')
      }
    },
    [removeAttachmentAssociation]
  )

  const confirmDelete = useCallback(
    (attachment: Attachment) => {
      openModal('confirm', {
        body: `You are about to delete ${attachment.originalFileName}. This cannot be undone and will remove this document entirely.`,
        confirmButtonText: 'Delete',
        onConfirm: async () => {
          onDelete(attachment)
          closeModal()
        }
      })
    },
    [openModal, closeModal, onDelete]
  )

  const hasDeletePermission = useHasRole(ROLES_WITH_PERMISSION_TO_DELETE)
  return useMemo(
    () => ({
      action: hasDeletePermission
        ? [{ label: 'Delete', onClick: confirmDelete }]
        : [],
      isDeleting
    }),
    [confirmDelete, hasDeletePermission, isDeleting]
  )
}

export const useRowActions = ({relatedEntityId, relatedEntityType}: {relatedEntityId: number, relatedEntityType: 'inventory' | 'locations' | 'pwsSamples' | 'assets' | 'submissionTemplates'}): {
  actions: RowAction<Attachment>[]
  isDeleting: boolean
} => {
  const viewAttachment = useViewAction()
  const download = useDownloadAction()
  const { action: deleteAction, isDeleting } = useDeleteAction({relatedEntityId, relatedEntityType})
  return useMemo(
    () => ({
      actions: [
        {
          label: 'View',
          onClick: viewAttachment
        },
        {
          label: 'Download',
          onClick: download
        },
        ...deleteAction
      ],
      isDeleting
    }),
    [deleteAction, download, isDeleting, viewAttachment]
  )
}
