import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { startPolling, stopPolling } from '../actions/polling'

export interface PollingState {
  isPolling: boolean
  error: string | null
}

const initialPollingState = {
  isPolling: false,
  error: null
}

const reducer = reducerWithInitialState<PollingState>(initialPollingState)
  .case(startPolling, state => ({
    ...state,
    isPolling: true
  }))
  .case(stopPolling, state => ({
    ...state,
    isPolling: false
  }))

export default reducer
