import GenerateCrudSaga from './helpers/CrudSaga'
import actions from '../actions/programs'
import {
  fetchAccountPrograms,
  fetchAccountProgram,
  createAccountProgram,
  updateAccountProgram,
  deleteAccountProgram
} from '../API'

const saga = GenerateCrudSaga({
  entityName: 'program',
  actions,
  fetchCollectionMethod: fetchAccountPrograms,
  fetchEntityMethod: fetchAccountProgram,
  createEntityMethod: createAccountProgram,
  updateEntityMethod: updateAccountProgram,
  deleteEntityMethod: deleteAccountProgram,
  deleteFailureMessage: () =>
    `Sorry, you cannot delete a Program that has Locations and/or Events attached to it.`
})

export default saga
