import React from 'react'
import { Field } from 'react-final-form'

import { TritonTextField } from 'src/components/Forms/TextField'
import styled from 'styled-components'

const Signature = (): JSX.Element => {
  return (
    <Grid style={{ textAlign: 'start' }}>
      <Field<string>
        component={TritonTextField}
        label="Signature"
        name="signature"
      />
      <Field<string> component={TritonTextField} label="Name" name="name" />
      <Field<string> component={TritonTextField} label="Phone" name="phone" />
      <Field<string> component={TritonTextField} label="Email" name="email" />
      <div
        style={{
          gridColumnStart: 'span 2',
          display: 'flex',
          gap: '8px'
        }}>
        <Field<boolean>
          component="input"
          id="verified"
          name="verified"
          type="checkbox"
        />
        <label htmlFor="verified">
          I have compiled a service line inventory for this water system based
          upon a review of the above referenced records and investigations in
          accordance with 40 CFR Part 141, Subpart 1
        </label>
      </div>
    </Grid>
  )
}

const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  & > h3 {
    grid-column-start: span 2;
  }
  & > div.field {
    margin: 0 !important;
  }
`

export default Signature
