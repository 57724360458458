import React from 'react'
import { Field } from 'react-final-form'
import { Divider, Icon } from 'semantic-ui-react'

import Dropdown from 'src/components/Forms/Dropdown'
import { TritonTextField as TextField } from 'src/components/Forms/TextField'
import { yesOption } from '../../../dataAccess'
import { BasicContainer, DoubleInputGrid } from '../shared'

const Signature = (): JSX.Element => {
  return (
    <BasicContainer style={{ marginTop: '15px' }}>
      <DoubleInputGrid style={{ marginBottom: '15px' }}>
        <Field<string>
          component={TextField}
          hideError
          label={
            <p style={{ marginTop: '-24px' }}>
              Public Water System Contact <br /> (First Name + Last Name){' '}
              <sup>
                <Icon
                  color="red"
                  name="asterisk"
                  style={{ fontSize: '6px', marginLeft: '1px' }}
                />
              </sup>
            </p>
          }
          name="certificationName"
        />
        <Field<string>
          component={TextField}
          displayRequiredAsterisk
          hideError
          label="Title"
          name="certificationTitle"
        />
        <Field<string>
          component={TextField}
          displayRequiredAsterisk
          label="Phone"
          name="certificationPhone"
          trimmedInput
        />
        <Field<string>
          component={TextField}
          displayRequiredAsterisk
          label="E-mail"
          name="certificationEmail"
          trimmedInput
        />
      </DoubleInputGrid>
      <Divider style={{ marginTop: '25px' }} />
      <Field<string>
        component={TextField}
        label="Service Provider Authorized Representative Signature (First Name + Last Name)"
        name="alternativeCertificationName"
      />
      <DoubleInputGrid>
        <Field<string>
          component={TextField}
          label="Title"
          name="alternativeCertificationTitle"
        />
        <Field<string>
          component={TextField}
          label="Organization"
          name="certificationOrganization"
        />
        <Field<string>
          component={TextField}
          label="Phone"
          name="alternativeCertificationPhone"
          trimmedInput
        />
        <Field<string>
          component={TextField}
          label="E-mail"
          name="altenativeCertificationEmail"
          trimmedInput
        />
      </DoubleInputGrid>
      <Divider style={{ marginTop: '25px' }} />
      <Field<string>
        component={Dropdown}
        fluid
        label={
          <p>
            I have compiled a service line inventory for this water system based
            upon a review of the above referenced records and investigations in
            accordance with 40 CFR Part 141, Subpart 1
            <sup>
              <Icon
                color="red"
                name="asterisk"
                style={{ fontSize: '6px', marginLeft: '1px' }}
              />
            </sup>
          </p>
        }
        name="certificationStatement"
        options={yesOption}
      />
    </BasicContainer>
  )
}

export default Signature
