import {
  Dropdown,
  TextField,
  colors,
  fontSize,
  lineHeight,
  spacing
} from '@120wateraudit/envirio-components'
import pluralize from 'pluralize'
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import { ReactComponent as CheckboxOffIcon } from 'src/assets/images/Checkbox-Off.svg'
import { ReactComponent as CheckboxOnIcon } from 'src/assets/images/Checkbox-On.svg'
import { ReactComponent as InvalidateIcon } from 'src/assets/images/Invalidate.svg'
import { Error } from 'src/components/Error'
import { useToggle } from 'src/hooks'
import { PWSSample, SampleStatus } from 'src/types/Sample'
import { useInvalidateSamplesAction, useStatusReasons } from '../dataAccess'
import { Controls, Warning, Wrapper } from './'

const CHECK_STYLE = { height: '20px', width: '20px' }

type TextChangeEvent = ChangeEvent<HTMLTextAreaElement>

interface Props {
  selectedSamples: PWSSample[]
  onClose(): void
  onCompletion?(): void
}

const InvalidateSamplesModal = ({
  onClose,
  onCompletion,
  selectedSamples
}: Props): JSX.Element => {
  const [checked, toggleChecked] = useToggle(false)
  const [notes, setNotes] = useState<string>()
  const [reason, setReason] = useState<string>()
  const { data, loading } = useStatusReasons(SampleStatus.Invalidated, 'PWS')
  const reasons = useMemo(() => {
    if (loading || !data) {
      return []
    }

    return data.sampleStatusReasons.map(r => ({
      text: r.reason,
      value: r.reason
    }))
  }, [loading, data])
  const { error, invalidateSamples, isWorking } =
    useInvalidateSamplesAction(selectedSamples)
  const onConfirm = useCallback(async () => {
    // The button to call this is disabled if reason is null, so we can ignore this.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (await invalidateSamples(reason!, checked, notes)) {
      onClose()
    }
    if (onCompletion) onCompletion()
  }, [checked, invalidateSamples, reason, notes, onClose, onCompletion])

  const shippingVendorId = selectedSamples[0].program.shippingVendorId

  return (
    <Wrapper>
      <InvalidateIcon />
      <h6>Invalidate Samples</h6>
      <FormText>
        Are you sure you want to invalidate{' '}
        {pluralize('Sample', selectedSamples.length, true)}?
      </FormText>
      <Warning>This action can not be undone.</Warning>
      <CheckboxWrapper onClick={toggleChecked}>
        {checked ? (
          <CheckboxOnIcon style={CHECK_STYLE} />
        ) : (
          <CheckboxOffIcon style={CHECK_STYLE} />
        )}
        <CheckboxLabel>Resend samples?</CheckboxLabel>
      </CheckboxWrapper>
      {checked && !shippingVendorId && (
        <Error
          error={{
            message:
              'The Program for this sample needs to have a Shipping Vendor Id to resend, please reach out to support to add one.'
          }}
        />
      )}
      <Field>
        <Dropdown
          fluid
          label="Reason for invalidation"
          onChange={(_, { value }) => setReason(value)}
          options={reasons}
          value={reason}
        />
      </Field>
      <Field>
        <TextField
          onChange={(e: TextChangeEvent) => setNotes(e.target.value)}
          placeholder="Invalidation notes…"
          textarea
          value={notes}
        />
      </Field>
      {error && <Error error={error} />}
      <Controls>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={
            loading || isWorking || !reason || (checked && !shippingVendorId)
          }
          onClick={onConfirm}
          primary>
          Confirm
        </Button>
      </Controls>
    </Wrapper>
  )
}

const FormText = styled.p`
  font-size: ${fontSize.body};
  line-height: ${lineHeight.body};
  font-weight: 300;
  color: ${colors.black50};
  text-align: center;
  margin: 0;
`

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`

const CheckboxLabel = styled.p`
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption};
  font-weight: 300;
  color: ${colors.error};
  text-align: center;
  padding-left: ${spacing.mini};
  padding-top: 3px;
`

const Field = styled.div`
  width: 300px;
`

export default InvalidateSamplesModal
