import * as React from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import SendCommunicationButton from 'src/components/Locations/SendCommunicationButton'
import { contactsClient } from 'src/apollo-clients'
import { useToggle } from 'src/hooks/useToggle'
import {
  AVAILABLE_COMMUNICATIONS_QUERY,
  AvailableCommunicationsData,
  AvailableCommunicationsVariables,
  PREVIOUSLY_SENT_COMMS,
  PreviouslySentCommsData,
  PreviouslySentCommsVariables,
  ADHOC_SEND_BATCH,
  ADHOC_DOWNLOAD_BATCH,
  ProcessAdHocBatchSendData,
  ProcessAdHocVariables,
  ProcessAdHocBatchDownloadData
} from './data-access'
import { CommunicationOption } from './types'
import {
  COMMUNICATION,
  CommunicationData,
  CommunicationVariables
} from 'src/containers/Communications/EditorSuite/data-access'
import {
  AccountType,
  CommunicationType
} from '@120wateraudit/communications-types'
import { useDispatch } from 'react-redux'
import { startPolling } from 'src/actions/polling'
import { addBatchDownload } from 'src/actions/batchDownloads'
import { useFeatures } from 'src/hooks'

const SendCommunicationButtonContainer: React.FC<
  SendCommunicationButtonContainerProps
> = ({
  accountId,
  locationIds: baseLocationIds,
  onSendAdHocLetterCompleted
}) => {
    const { accountHasCommsAutomatedMailingFeature } = useFeatures()
    const locationIds = React.useRef(baseLocationIds)
    const [showSendingModal, toggleSendingModal] = useToggle(false)
    const [skipDuplicates, toggleSkipDuplicates] = useToggle(false)
    const [openPreviewContainer, togglePreviewContainer] = useToggle(false)
    const [isCertifiedMail, toggleIsCertifiedMail] = useToggle(false)
    const [selectedCommunication, setSelectedCommunication] = React.useState<
      CommunicationOption | undefined
    >(undefined)
    const [selectedCommunicationType, setSelectedCommunicationType] =
      React.useState<CommunicationTypes | undefined>(undefined)
    const [stepNumber, setStepNumber] = React.useState<number>(0)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
      undefined
    )
    const [previousSentCommLocationIds, setPreviousSentCommLocationIds] =
      React.useState<number[] | undefined>(undefined)
    const [zIndex, setZIndex] = React.useState<number>(1000)
    const dispatch = useDispatch()

    const communicationsQuery = useQuery<
      AvailableCommunicationsData,
      AvailableCommunicationsVariables
    >(AVAILABLE_COMMUNICATIONS_QUERY, {
      variables: { accountId },
      client: contactsClient,
      fetchPolicy: 'network-only',
      onError: ({ graphQLErrors }) => setErrorMessage(graphQLErrors[0]?.message)
    })

    const [sendCommunications] = useMutation<
      ProcessAdHocBatchSendData,
      ProcessAdHocVariables
    >(ADHOC_SEND_BATCH, {
      client: contactsClient,
      onCompleted: () => {
        setIsLoading(false)
        onNextClicked()
      },
      onError: ({ graphQLErrors }) => {
        setIsLoading(false)
        setErrorMessage(graphQLErrors[0]?.message)
      }
    })

    const [downloadCommunications, { loading }] = useMutation<
      ProcessAdHocBatchDownloadData,
      ProcessAdHocVariables
    >(ADHOC_DOWNLOAD_BATCH, {
      client: contactsClient,
      onCompleted: ({ processAdHocBatch: { result: batchId } }) => {
        if (batchId) {
          setIsLoading(false)
          dispatch(addBatchDownload({ batchId }))
          dispatch(startPolling())
        }
        onNextClicked()
      },
      onError: ({ graphQLErrors }) => setErrorMessage(graphQLErrors[0]?.message)
    })

    const [previouslySentComms] = useLazyQuery<
      PreviouslySentCommsData,
      PreviouslySentCommsVariables
    >(PREVIOUSLY_SENT_COMMS, {
      variables: {
        communicationId: selectedCommunication?.id as number,
        locationIds: baseLocationIds
      },
      client: contactsClient,
      fetchPolicy: 'cache-first',
      onCompleted: ({ previouslySentComms: response }) =>
        setPreviousSentCommLocationIds(
          response.map(({ locationId }) => locationId)
        ),
      onError: ({ graphQLErrors }) => setErrorMessage(graphQLErrors[0].message)
    })

    const [
      getCommunication,
      { loading: getCommunicationLoading, data: getCommunicationData }
    ] = useLazyQuery<CommunicationData, CommunicationVariables>(COMMUNICATION, {
      fetchPolicy: 'no-cache',
      client: contactsClient,
      onError: ({ graphQLErrors }) => setErrorMessage(graphQLErrors[0].message)
    })

    const fetchCommunicationData = React.useCallback(async () => {
      if (selectedCommunication?.id) {
        getCommunication({
          variables: {
            accountId,
            communicationId: selectedCommunication.id
          }
        })

        togglePreviewContainer()
      }
    }, [
      accountId,
      getCommunication,
      selectedCommunication,
      togglePreviewContainer
    ])

    const communications = React.useMemo(
      () =>
        communicationsQuery.loading || !communicationsQuery.data
          ? []
          : communicationsQuery.data.availableAdHocCommunications,
      [communicationsQuery.data, communicationsQuery.loading]
    )

    const clearState = React.useCallback(() => {
      if (showSendingModal) {
        toggleSendingModal()
      }
      setSelectedCommunication(undefined)
      setSelectedCommunicationType(undefined)
      setStepNumber(0)
    }, [toggleSendingModal, showSendingModal])

    const onNextClicked = React.useCallback(() => {
      if (
        stepNumber === 0 &&
        selectedCommunication &&
        selectedCommunicationType === 'Letter'
      ) {
        previouslySentComms()
      }

      if (stepNumber !== 2 && selectedCommunication) {
        setStepNumber(stepNumber + 1)
      }
    }, [
      previouslySentComms,
      stepNumber,
      selectedCommunication,
      selectedCommunicationType
    ])

    const onBackClicked = React.useCallback(() => {
      if (stepNumber === 1) {
        setStepNumber(0)
      }
    }, [stepNumber])

    const onSend = React.useCallback(async () => {
      if (selectedCommunication !== undefined) {
        setIsLoading(true)
        await sendCommunications({
          variables: {
            params: {
              accountId,
              locationIds: locationIds.current,
              communicationId: selectedCommunication.id,
              accountType: AccountType.PWS,
              templateId: selectedCommunication.templateId
            },
            isCertifiedMail
          }
        })
      }
    }, [
      accountId,
      isCertifiedMail,
      locationIds,
      selectedCommunication,
      sendCommunications
    ])

    const onCloseWindow = React.useCallback(() => {
      clearState()
      onSendAdHocLetterCompleted && onSendAdHocLetterCompleted()
    }, [clearState, onSendAdHocLetterCompleted])

    const closePreview = React.useCallback(() => {
      setZIndex(1000)
      togglePreviewContainer()
    }, [togglePreviewContainer])

    const onDownload = React.useCallback(async () => {
      if (selectedCommunication !== undefined) {
        await downloadCommunications({
          variables: {
            params: {
              accountId,
              locationIds: baseLocationIds,
              communicationId: selectedCommunication.id,
              accountType: AccountType.PWS,
              templateId: selectedCommunication.templateId
            },
            download: true
          }
        })
      }
    }, [
      accountId,
      downloadCommunications,
      selectedCommunication,
      baseLocationIds
    ])

    const onCommunicationSelected = React.useCallback(
      ({ value }: { value: number }) => {
        const selected = communications.find(c => c.id === value)
        setSelectedCommunication(selected)
      },
      [communications]
    )

    const onCommunicationTypeSelected = React.useCallback(
      ({ value }: { value: CommunicationTypes }) => {
        setSelectedCommunicationType(CommunicationTypes[value])
      },
      []
    )

    React.useEffect(() => {
      if (skipDuplicates && previousSentCommLocationIds) {
        locationIds.current = locationIds.current.filter(
          location =>
            !previousSentCommLocationIds.some(
              prevLocation => prevLocation === location
            )
        )
      }

      if (
        !skipDuplicates &&
        previousSentCommLocationIds &&
        locationIds.current !== baseLocationIds
      ) {
        locationIds.current = baseLocationIds
      }
    }, [previousSentCommLocationIds, skipDuplicates, baseLocationIds])

    React.useEffect(() => {
      if (errorMessage) {
        setTimeout(() => {
          setErrorMessage(undefined)
        }, 5000)
      }
    }, [errorMessage])

    return (
      <SendCommunicationButton
        showModal={showSendingModal}
        toggleModal={toggleSendingModal}
        communications={communications}
        selectedCommunication={selectedCommunication}
        onCommunicationSelected={onCommunicationSelected}
        selectedCommunicationType={selectedCommunicationType}
        onCommunicationTypeSelected={onCommunicationTypeSelected}
        stepNumber={stepNumber}
        onNext={onNextClicked}
        onBack={onBackClicked}
        onSend={onSend}
        onDownload={onDownload}
        isSaving={isLoading}
        accountId={accountId}
        fetchCommunicationData={fetchCommunicationData}
        isCertifiedMail={isCertifiedMail}
        toggleIsCertifiedMail={toggleIsCertifiedMail}
        hasAutomatedLetterMailingFeature={
          !!accountHasCommsAutomatedMailingFeature
        }
        loading={loading}
        previousSentCommLocationIds={previousSentCommLocationIds}
        onCloseWindow={onCloseWindow}
        getCommunicationLoading={getCommunicationLoading}
        communicationData={getCommunicationData}
        openPreviewContainer={openPreviewContainer}
        togglePreviewContainer={togglePreviewContainer}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        zIndex={zIndex}
        setZIndex={setZIndex}
        closePreview={closePreview}
        locationIds={baseLocationIds}
        onSkipDuplicatesChange={toggleSkipDuplicates}
      />
    )
  }

interface SendCommunicationButtonContainerProps {
  accountId: number
  locationIds: number[]
  onSendComplete?: () => void
  onSendAdHocLetterCompleted?: () => void
}

enum PDFSelection {
  'Letter as PDF' = 'Letter as PDF'
}

export const CommunicationTypes = Object.assign(
  {},
  CommunicationType,
  PDFSelection
)

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CommunicationTypes = keyof typeof CommunicationTypes

export default SendCommunicationButtonContainer
