import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import styled from 'src/theme'

interface Props {
  propertyName: string
  onClick: () => void
  isExpandable?: boolean
}

const camelCaseToNormal = (str: string) => {
  let normalString = str
    .replace(/([.])/g, ' ')
    .replace(/(\s[a-z])/g, str1 => str1.toUpperCase())
    .replace(/^./, str2 => str2.toUpperCase())

  if (!str.startsWith('CF ')) {
    normalString = normalString.replace(/([A-Z0-9])/g, ' $1')
  }
  return normalString
}

const InsertableComponent: FC<Props> = ({
  propertyName,
  onClick,
  isExpandable
}) => {
  return (
    <DisplayWrapper onClick={onClick}>
      <PropertyDisplay isExpandable={isExpandable}>
        {camelCaseToNormal(propertyName)}
      </PropertyDisplay>
      <FontAwesomeIcon
        color={isExpandable ? 'black' : 'green'}
        icon={isExpandable ? faChevronRight : faCirclePlus}
        size="lg"
      />
    </DisplayWrapper>
  )
}

export default InsertableComponent

const PropertyDisplay = styled.h6`
  font-weight: ${p => (p.isExpandable ? '600' : '100')};
  margin: 0.5rem 0.25rem;
`

const DisplayWrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 5rem;
  font-size: 1.25rem;
  padding-bottom: 1rem;
`
