import { DropdownItemProps } from 'semantic-ui-react'
import jsonpointer from 'jsonpointer'


export const getEnums = (entityType: 'location' | 'pwsserviceline', key: string, value: string | null | undefined,
    entityDefinitions: Record<never, unknown> | undefined)
: DropdownItemProps[] => {
  const enumList: DropdownItemProps[] = []
  if (entityDefinitions) {
    const entityDefinition = entityDefinitions[entityType] as Record<string, unknown>
    const enums: string[] = jsonpointer.get(entityDefinition, key + '/enum')
    const enumLabels: Record<string, string> = jsonpointer.get(entityDefinition, key + '/meta:enum')
    const enumDeprecated: string[]  = jsonpointer.get(entityDefinition, key + '/meta:enum:deprecated')
    enums.forEach( enumItem => {
        if (enumItem && (!enumDeprecated || !enumDeprecated.includes(enumItem))) {
          enumList.push({
            key: enumItem,
            value: enumItem,
            text: enumLabels[enumItem],
            disabled: false })
        }
      })
      if (value && !enums.includes(value)) {
        enumList.push({
          key: value,
          value: value,
          text: enumLabels[value],
          disabled: true })
      }
      enumList.sort((a, b): number => {
        if ((!a.disabled && !b.disabled) ||
            (a.disabled && b.disabled)) {
          return a.text && b.text ? a.text < b.text ? -1 : 1 : 0
        }
        else if (a.disabled) {
          return -1
        }
        else if (b.disabled) {
          return 1
        }
        return 0
      })
      return enumList;
  }
  return enumList;
}

export const getEnumText = (entityType: 'location' | 'pwsserviceline', key: string, value: string | null | undefined,
                         entityDefinitions: Record<never, unknown> | undefined): string => {
  if (entityDefinitions && value) {
    const entityDefinition = entityDefinitions[entityType] as Record<string, unknown>
    const enumLabels: Record<string, string> = jsonpointer.get(entityDefinition, key + '/meta:enum')
    return value ? enumLabels[value] : '--'
  }
  return '--'
}

/**
 * Get a list of enum labels, useful for displaying these enum values in a drop down
 */
export const getEnumLabels = (entityType: 'location' | 'pwsserviceline', key: string, entityDefinitions: Record<never, unknown> | undefined) => {
  if (entityDefinitions) {
    const entityDefinition = entityDefinitions[entityType] as Record<string, unknown>;

    const enumLabels: Record<string, string> = jsonpointer.get(entityDefinition, key + '/meta:enum')

    return enumLabels;
  }

  return undefined;
}
