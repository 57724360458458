import { Button } from '@120wateraudit/envirio-components'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'

import Detail from 'src/components/Detail'
import ExternalLink from 'src/components/ExternalLink'
import { Row, Value } from 'src/components/Layout'
import { ServiceLinesMaterial } from 'src/components/ServiceLines'
import { ModalContext } from 'src/modules/Modal'
import { Asset } from 'src/types/Asset'
import { formatDate } from 'src/utils/formatDate'
import formatNumber from 'src/utils/formatNumber'
import StateSubmissionValidationErrors from './StateSubmissionValidationErrors'
import { useStateSubmissionValidationStatus } from '../dataAccess'
import { useHasMoreThanReadPermission } from 'src/hooks'
import { useEntityDefinitions } from 'src/router/UserProvider'
import {
  getVerificationMethodEnumText
} from 'src/utils/entityDefintions/serviceLineEnums'

type Props = {
  asset: Asset
  onClose(): void
  refetch: () => void
}

const DetailsDrawer = ({ asset, refetch, onClose }: Props): JSX.Element => {
  const { stateValidationErrors } = useStateSubmissionValidationStatus(
    asset.serviceLineAsset.id
  )

  return (
    <Column>
      <Header asset={asset} refetch={refetch} onClose={onClose} />
      <StateSubmissionValidationErrorsContainer>
        <StateSubmissionValidationErrors
          stateValidationErrors={stateValidationErrors}
        />
      </StateSubmissionValidationErrorsContainer>
      <AssetSummary asset={asset} />
    </Column>
  )
}

const Header = ({
  asset,
  onClose,
  refetch
}: {
  asset: Asset
  refetch: () => void
  onClose(): void
}): JSX.Element => {
  const { openModal } = useContext(ModalContext)
  const userHasPermission = useHasMoreThanReadPermission()

  return (
    <HeaderRow>
      <div>
        <h3>View Record</h3>
        <ExternalLink
          to={`/locations/${asset.location.id}/inventory/${asset.id}`}>
          View All Details
        </ExternalLink>
      </div>
      <div>
        {userHasPermission && (
          <Button
            onClick={() =>
              openModal('editServiceLine', { asset, refetch }, 'drawer')
            }
            style={{ marginRight: '7px' }}
            variant="primary">
            Edit
          </Button>
        )}
        <Button onClick={onClose} type="button" variant="error">
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </div>
    </HeaderRow>
  )
}

const Column = styled(Row)`
  flex-direction: column;
  align-items: unset;
  margin: 0 0 12px 0;
`

const HeaderRow = styled(Row)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  & h3 {
    margin: 0;
    font-size: 20px;
    line-height: 1.1;
  }
`

const StateSubmissionValidationErrorsContainer = styled.div`
  margin: 10px 24px 0 24px;
`

const AssetSummary = ({ asset }: { asset: Asset }): JSX.Element => {
  const entityDefinitions = useEntityDefinitions()
  const { location, serviceLineAsset } = asset
  const { addressLine1, city, state, zip } = location
  const {
    fittingVerifiedBy,
    fittingsVerificationMethod,
    fittingsVerifiedDate,
    leadFittings,
    leadSolder,
    otherLeadEquipment,
    privateClassificationBasis,
    privateInstalledDate,
    privateLineDepth,
    privateLineDiameter,
    privateDiameterOverTwoInches,
    privateMaterial,
    privateLeadSolderLeadLined,
    privateNotes,
    privateRemovalDate,
    privateVerifiedBy,
    privateVerifiedDate,
    privateVerificationMethod,
    publicClassificationBasis,
    publicInstalledDate,
    publicLineDepth,
    publicLineDiameter,
    publicDiameterOverTwoInches,
    publicMaterial,
    publicLeadSolderLeadLined,
    publicNotes,
    publicPreviouslyLead,
    publicRemovalDate,
    publicVerifiedBy,
    publicVerifiedDate,
    publicVerificationMethod
  } = serviceLineAsset

  const publicDiameterDisplay = useMemo(() => {
    if (
      publicLineDiameter?.displayValue &&
      publicLineDiameter?.displayValue !== 'Unknown'
    ) {
      return publicLineDiameter?.displayValue
    } else if (publicDiameterOverTwoInches) {
      return 'Greater Than 2 Inches'
    } else if (publicDiameterOverTwoInches === false) {
      return 'Less Than 2 Inches'
    }
    return 'Unknown'
  }, [publicLineDiameter?.displayValue, publicDiameterOverTwoInches])

  const privateDiameterDisplay = useMemo(() => {
    if (
      privateLineDiameter?.displayValue &&
      privateLineDiameter?.displayValue !== 'Unknown'
    ) {
      return privateLineDiameter?.displayValue
    } else if (privateDiameterOverTwoInches) {
      return 'Greater Than 2 Inches'
    } else if (privateDiameterOverTwoInches === false) {
      return 'Less Than 2 Inches'
    }
    return 'Unknown'
  }, [privateLineDiameter?.displayValue, privateDiameterOverTwoInches])

  return (
    <Section>
      <Detail label="120Water Asset ID">{formatNumber(asset.id)}</Detail>
      <Detail label="External ID">{asset.externalId}</Detail>
      <Detail label="Address">
        <Value>{addressLine1}</Value>
        <Value>
          {city}, {state} {zip}
        </Value>
      </Detail>
      <Detail label="Description">{asset.description}</Detail>
      <Detail label="System-Owned Classification Basis">
        {publicClassificationBasis}
      </Detail>
      <Detail label="System-Owned Material">
        <Value>
          <ServiceLinesMaterial material={publicMaterial} />
        </Value>
      </Detail>
      <Detail label="System-Owned Lead Solder">
        {publicLeadSolderLeadLined}
      </Detail>
      <Detail label="System-Owned Previously Lead">
        {publicPreviouslyLead}
      </Detail>
      <Detail label="System-Owned Install Date">
        {publicInstalledDate && formatDate(publicInstalledDate)}
      </Detail>
      <Detail label="System-Owned Verification Method">
        {publicVerificationMethod &&
            getVerificationMethodEnumText(publicVerificationMethod, entityDefinitions)}
      </Detail>
      <Detail label="System-Owned Verification Date">
        {publicVerifiedDate && formatDate(publicVerifiedDate)}
      </Detail>
      <Detail label="System-Owned Verified By">{publicVerifiedBy}</Detail>
      <Detail label="System-Owned Depth">{publicLineDepth}</Detail>
      <Detail label="System-Owned Diameter">{publicDiameterDisplay}</Detail>
      <Detail label="System-Owned Removal Date">
        {publicRemovalDate && formatDate(publicRemovalDate)}
      </Detail>
      <Detail label="System-Owned Notes">{publicNotes}</Detail>
      <Detail label="Customer-Owned Classification Basis">
        {privateClassificationBasis}
      </Detail>
      <Detail label="Customer-Owned Material">
        <Value>
          <ServiceLinesMaterial material={privateMaterial} />
        </Value>
      </Detail>
      <Detail label="Customer-Owned Lead Solder">
        {privateLeadSolderLeadLined}
      </Detail>
      <Detail label="Customer-Owned Install Date">
        {privateInstalledDate && formatDate(privateInstalledDate)}
      </Detail>
      <Detail label="Customer-Owned Verification Method">
        {privateVerificationMethod &&
            getVerificationMethodEnumText(privateVerificationMethod, entityDefinitions)}
      </Detail>
      <Detail label="Customer-Owned Verification Date">
        {privateVerifiedDate && formatDate(privateVerifiedDate)}
      </Detail>
      <Detail label="Customer-Owned Verified By">{privateVerifiedBy}</Detail>
      <Detail label="Customer-Owned Depth">{privateLineDepth}</Detail>
      <Detail label="Customer-Owned Diameter">{privateDiameterDisplay}</Detail>
      <Detail label="Customer-Owned Removal Date">
        {privateRemovalDate && formatDate(privateRemovalDate)}
      </Detail>
      <Detail label="Customer-Owned Notes">{privateNotes}</Detail>
      <Detail label="Lead Fittings">{leadFittings}</Detail>
      <Detail label="Lead Solder">{leadSolder}</Detail>
      <Detail label="Fittings Verification Method">
        {fittingsVerificationMethod &&
            getVerificationMethodEnumText(fittingsVerificationMethod, entityDefinitions)}
      </Detail>
      <Detail label="Fittings Verification Date">
        {fittingsVerifiedDate && formatDate(fittingsVerifiedDate)}
      </Detail>
      <Detail label="Fittings Verified By">{fittingVerifiedBy}</Detail>
      <Detail label="Other Lead Equipment">{otherLeadEquipment}</Detail>
    </Section>
  )
}

const Section = styled.div`
  margin: 12px 24px;
  & h4 {
    margin: 0 0 8px 0;
    font-size: 16px;
    line-height: 1.1;
  }
`

export default DetailsDrawer
