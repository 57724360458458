import {
  PWSAccount,
  User
} from '@120wateraudit/envirio-components/dist/models'

export const getDefaultAccount = (user?: User): PWSAccount | undefined => {
  if (!user || !user.defaultAccount) {
    return undefined
  }
  return user.defaultAccount as PWSAccount
}
