import {
  DropdownItemProps,
  TableCellProps,
  TableHeaderCellProps
} from 'semantic-ui-react'
import { SearchParams } from 'src/hooks'

import { ViewType } from 'src/types/SavedView'
import { SortDirection } from 'src/types/SortDirection'
import {CustomFieldDefinition} from "@120wateraudit/custom-fields-ui-components";

export type RowData = Record<string, unknown>

export type RowDataWithId = RowData & { id: number | string }

export interface Sort {
  direction: SortDirection
  key: string
}
interface BaseColumn<T extends RowData> {
  Header?: React.ReactNode
  accessor: ((record: T) => React.ReactNode) | string
  displayRequired?: boolean
  displayConditionallyRequired?: boolean
  headerProps?: TableHeaderCellProps
  invalid?: boolean
  key: string // Passed to the API for sorting & internal use in React
  name?: string // Displayed to the user in Column controls
  rowProps?: TableCellProps
  sortable?: boolean
}
type RequiredColumn<T extends RowData> = BaseColumn<T> & {
  hiddenByDefault?: false
  required: true
}
type HiddenColumn<T extends RowData> = BaseColumn<T> & {
  hiddenByDefault: true
  required?: false
}
type RegularColumn<T extends RowData> = BaseColumn<T> & {
  hiddenByDefault?: never
  required?: never
}
export type Column<T extends RowData = RowData> =
  | HiddenColumn<T>
  | RegularColumn<T>
  | RequiredColumn<T>

export interface Action {
  label: string
  requiresSelection?: boolean
  singleItemOnly?: boolean
  disabledForSelectAll?: boolean
  onClick(): void
}

export interface ExportAction extends Action {
  params?: SearchParams
}

export interface RowAction<T extends RowData> {
  label: string
  onClick(row: T): void
}

export interface ColumnVisibilityPayload {
  key: string
  visible: boolean
}

export interface ViewPayload {
  activeFilters: ActiveFilters
  activeViewName: string
  columns: string[]
  hiddenColumns: string[]
  sort: Sort | Sort[]
}

export interface CommonProps<T extends RowData> {
  actions?: Action[]
  activeViewName?: string
  columns: Column<T>[]
  data: T[]
  exportAction?: ExportAction
  exportButton?: (props: ExportAction) => JSX.Element
  hasTableKey?: boolean
  hideSelection?: boolean
  itemName?: string
  loading?: boolean
  rowActions?: RowAction<T>[]
  savedViewType?: ViewType
  showTopCount?: boolean
  clearAll?: () => void
  setView?: (view: ViewPayload) => void
  setValidatedColumns?: (record: T, columns: Column<T>[]) => Column<T>[]
}

export type ControllableColumnsProps = {
  allowHiddenColumns?: boolean
  hiddenColumns?: string[]
  onHiddenColumnsChanged?: (ColumnVisibilityPayload) => void
}

export type SortableProps =
  | {
      sort?: Sort
      sortable: true
      onSortChanged?: (sort?: Sort) => void
    }
  | { onSortChanged?: never; sort?: never; sortable?: false }

type PaginationProps =
  | {
      onPageChanged?: never
      onPageSizeChanged?: never
      page?: never
      pageSize?: never
      paginated?: false
      totalRecords?: never
    }
  | {
      page: number
      pageSize?: number
      paginated: true
      totalRecords: number
      onPageChanged(newPage: number): void
      onPageSizeChanged?(newSize: number): void
    }

export type SearchProps =
  | {
      searchTerm?: string
      searchable: true
      onSearchChanged?: (newTerm?: string) => void
    }
  | { onSearchChanged?: never; searchTerm?: never; searchable?: false }

export type SelectionMode = undefined | 'multi' | 'single'

export type SelectableProps =
  | {
      areAllSelected: boolean
      isSelectAllOn?: boolean
      hasSelectAllToggle?: boolean
      selected?: RowDataWithId[]
      selectionMode: 'multi'
      onClearSelection(): void
      onSelectAll?: () => void
      onSelectionChanged(selected: RowDataWithId): void
      toggleSelectAll?: () => void
    }
  | {
      areAllSelected?: never
      isSelectAllOn?: boolean
      hasSelectAllToggle?: boolean
      onClearSelection?: never
      onSelectAll?: never
      onSelectionChanged?: never
      selected?: never
      selectionMode?: never
      toggleSelectAll?: () => void
    }
  | {
      areAllSelected?: never
      isSelectAllOn?: boolean
      hasSelectAllToggle?: boolean
      onSelectAll?: never
      selected?: RowDataWithId
      selectionMode: 'single'
      onClearSelection?(): void
      onSelectionChanged?(selected?: RowDataWithId): void
      toggleSelectAll?: () => void
    }

export enum FilterType {
  TextMatch = 0,
  ListSelection = 1,
  DatePicker = 2,
  Number = 3,
  DateTimePicker = 4,
  YearPicker = 5
}

export interface FilterDefinition {
  customField?: CustomFieldDefinition
  defaultValue?: ValidFilterValue
  key: string
  label: string
  name?: string
  multipleSelections?: boolean
  options?: DropdownItemProps[]
  scrollingSelections?: boolean
  type: FilterType
}

export type CustomFieldFilterDefinition = FilterDefinition & {
  customField: CustomFieldDefinition
}

export type ValidFilterValue =
  | undefined
  | (string | number | boolean)[]
  | boolean
  | number
  | string
export type ActiveFilters = Record<string, undefined | ValidFilterValue> & {
  customFields?: string
}
export type OnFilterChangeFunc = (payload: {
  key: string
  value?: null | ValidFilterValue
}) => void

export type FilterableProps =
  | {
      activeFilters?: ActiveFilters
      filterable: true
      filters: FilterDefinition[]
      onFilterChanged: OnFilterChangeFunc
    }
  | {
      activeFilters?: never
      filterable?: false
      filters?: never
      onFilterChanged?: OnFilterChangeFunc
    }

export type OrderableProps =
  | { orderable: true; setColumns(columns: Column[]): void }
  | { orderable?: false; setColumns?: (columns: Column[]) => void }

export type Props<T extends RowData> = CommonProps<T> &
  ControllableColumnsProps &
  FilterableProps &
  OrderableProps &
  PaginationProps &
  SearchProps &
  SelectableProps &
  SortableProps

export interface TableState<T extends RowData = RowData> {
  activeFilters: ActiveFilters
  activeViewName?: string
  columns: Column<T>[]
  customFields?: string
  hiddenColumns?: string[]
  page: number
  pageSize: number
  searchTerm?: string
  sort?: string
}

export interface TableParams<T extends RowData>
  extends Omit<TableState<T>, 'sort'> {
  onFilterChanged: OnFilterChangeFunc
  sort?: Sort
  clearAll(): void
  setHiddenColumns?: (ColumnVisibilityPayload) => void
  setPage(page: number): void
  setPageSize(size: number): void
  setSearchTerm(term?: string): void
  setSort(sort?: Sort): void
  setView?: (view: ViewPayload) => void
}
