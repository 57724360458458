import React from 'react'
import { Button, Header, Modal } from 'semantic-ui-react'

interface Props {
  onClose(): void
}

const ModalNotFound = ({ onClose }: Props): JSX.Element => {
  return (
    <>
      <Header content="Uh oh!" icon="dont" />
      <Modal.Content>
        <p>We could not find what you're looking for. We're sorry!</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </>
  )
}

export default ModalNotFound
