import { DropdownItemProps } from 'semantic-ui-react'
import { getEnumLabels, getEnums, getEnumText } from 'src/utils/entityDefintions/entityDefinitionUtils'

export const getPropertyClassificationEnumText = (value: string | undefined, entityDefinitions: Record<never, unknown> | undefined): string => {
  return getEnumText('location', '/properties/lcr/properties/propertyClassification', value, entityDefinitions)
}

export const getPropertyClassificationEnums = (value: string | undefined, entityDefinitions: Record<never, unknown> | undefined): DropdownItemProps[] => {
  return getEnums('location', '/properties/lcr/properties/propertyClassification', value, entityDefinitions)
}

export const getPropertyClassificationEnumLabels = (entityDefinitions: Record<never, unknown> | undefined): Record<never, unknown> | undefined => {
  return getEnumLabels('location', '/properties/lcr/properties/propertyClassification', entityDefinitions)
}
