import { FilterType } from '@120wateraudit/envirio-components'
import { TrueBooleanGridOptions } from 'src/containers/Grid/gridFilters/common'
import { YesNoUnknown } from 'src/types/enums/YesNoUnknown'
import { convertEnumToListItems } from 'src/utils/selectlist'

const serviceLineFilters = [
  {
    key: 'city',
    label: 'City',
    type: FilterType.TextMatch,
    value: 'city'
  },
  {
    key: 'state',
    label: 'State',
    type: FilterType.TextMatch,
    value: 'state'
  },
  {
    key: 'zip',
    label: 'Zip',
    type: FilterType.TextMatch,
    value: 'zip'
  },
  {
    key: 'publicMaterial',
    label: 'System-Owned Material',
    options: [],
    type: FilterType.ListSelection,
    value: 'publicMaterial'
  },
  {
    key: 'publicVerified',
    label: 'System-Owned Verified',
    options: TrueBooleanGridOptions,
    type: FilterType.ListSelection,
    value: 'publicVerified'
  },
  {
    key: 'privateMaterial',
    label: 'Customer-Owned Material',
    options: [],
    type: FilterType.ListSelection,
    value: 'privateMaterial'
  },
  {
    key: 'privateVerified',
    label: 'Customer-Owned Verified',
    options: TrueBooleanGridOptions,
    type: FilterType.ListSelection,
    value: 'privateVerified'
  },
  {
    key: 'leadFittings',
    label: 'Lead Fittings',
    options: convertEnumToListItems(YesNoUnknown),
    type: FilterType.ListSelection,
    value: 'leadFittings'
  }
]

export default serviceLineFilters
