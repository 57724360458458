import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
  geocodeAddressRequest,
  geocodeAddressSuccess,
  geocodeAddressFailure
} from '../actions/geocoding'

import { Geocoding, GeocodingType } from '../types/Geocoding'
import { Address } from '@120wateraudit/envirio-components/dist/models'

const initialState = []

export const getInitialGeocoding = (type: GeocodingType, id: number) => {
  return {
    type,
    id,
    lat: (null as unknown) as undefined,
    lng: (null as unknown) as undefined,
    isFetching: true,
    error: (null as unknown) as undefined
  }
}

const getExistingGeocodingIndex = (
  type: GeocodingType,
  id: number,
  geocodings: Geocoding[]
): number => {
  return geocodings.findIndex(geocoding => {
    return geocoding.type === type && geocoding.id === id
  })
}

const reducer = reducerWithInitialState<Geocoding[]>(initialState)
  .case(
    geocodeAddressRequest,
    (
      state,
      {
        type,
        id
      }: { type: GeocodingType; id: number; address: Partial<Address> }
    ) => {
      const existingIndex = getExistingGeocodingIndex(type, id, state)
      const newState = state.map((g, i) => {
        return i === existingIndex ? getInitialGeocoding(type, id) : g
      })

      if (existingIndex === -1) {
        newState.push(getInitialGeocoding(type, id))
      }

      return newState
    }
  )
  .case(
    geocodeAddressSuccess,
    (state, { geocoding }: { geocoding: Geocoding }) => {
      const existingIndex = getExistingGeocodingIndex(
        geocoding.type,
        geocoding.id,
        state
      )

      const updatedGeocoding = {
        type: geocoding.type,
        id: geocoding.id,
        lat: geocoding.lat,
        lng: geocoding.lng,
        isFetching: false,
        error: (null as unknown) as undefined
      }

      const newState = state.map((g, i) => {
        return i === existingIndex ? updatedGeocoding : g
      })

      if (existingIndex === -1) {
        newState.push(updatedGeocoding)
      }

      return newState
    }
  )
  .case(
    geocodeAddressFailure,
    (
      state,
      { type, id, error }: { type: GeocodingType; id: number; error: string }
    ) => {
      const existingIndex = getExistingGeocodingIndex(type, id, state)

      const updatedGeocoding = {
        type,
        id,
        lat: (null as unknown) as undefined,
        lng: (null as unknown) as undefined,
        isFetching: false,
        error
      }

      const newState = state.map((g, i) => {
        return i === existingIndex ? updatedGeocoding : g
      })

      if (existingIndex === -1) {
        newState.push(updatedGeocoding)
      }

      return newState
    }
  )

export default reducer
