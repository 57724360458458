import React, { useMemo } from 'react'
import { TableCell } from 'semantic-ui-react'
import styled from 'styled-components'

import OverflowMenu from 'src/components/OverflowMenu'
import { Flags, useConfig } from 'src/hooks'
import { RowAction, RowData } from '../types'
import HeaderCell from './HeaderCell'

export const KebabHeader = (): null | JSX.Element => {
  const hasEditables = useConfig(Flags.Editables)
  if (!hasEditables) {
    return null
  }

  return <HeaderCell sortable={false} />
}

interface Props<T extends RowData> {
  actions: RowAction<T>[]
  record: T
}

function KebabCell<T extends RowData>({
  actions,
  record
}: Props<T>): null | JSX.Element {
  const hasEditables = useConfig(Flags.Editables)
  const mappedActions = useMemo(
    () => actions.map(a => ({ ...a, onClick: () => a.onClick(record) })),
    [record, actions]
  )
  if (!hasEditables) {
    return null
  }

  return (
    <Cell>
      <OverflowMenu actions={mappedActions} />
    </Cell>
  )
}

const Cell = styled(TableCell)`
  position: sticky;
  right: 0;
  & > button.icon.button {
    background: white !important;
    box-shadow: 0 0 0 1px rgb(34 36 38 / 10%) inset,
      0 0 0 0 rgb(34 36 38 / 15%) inset;
  }
`

export default KebabCell
