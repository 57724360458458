import {
  CommunicationType,
  AccountType
} from '@120wateraudit/envirio-components/dist/models'

export interface CommunicationTemplate {
  id: number | string
  communicationType: CommunicationType
  templateName: string
  name: string
  description?: string
  triggerType?: string
  triggerCondition?: string
  content: ContentBlock[]
  trigger?: CommunicationTrigger
  dynamicFields?: DynamicFields[]
}

export interface ContentBlock {
  id: number
  name: string
  html: string
  editableFields: EditableField[]
  parsedHtml?: string
  hoverIndex?: number
  dragIndex?: number
}

export interface CommunicationTrigger {
  accountType: AccountType
  type: string
  conditionLabel?: string
  conditions: TriggerCondition[]
  requireEventId: boolean
  toolbarItems: TriggerToolbarItem[]
  customFields?: {
    entities: Array<'Locations' | 'PwsSamples' | 'PwsSampleResults'>
    dataTypeExamples: Array<{
      key: string
      type: number
      example: string
    }>
  }
}

export interface TriggerCondition {
  code: string
  label: string
}

export interface EditableField {
  name: string
  key: string
  value: string
  type: FieldType
  isRemovable?: boolean
}

export interface DynamicFields {
  name: string
  key: string
  type: string
  example: string
  noValueText?: string
}

export enum FieldType {
  Image = 'Image',
  ColorPicker = 'Color Picker',
  Text = 'Text',
  WYSIWYG = 'WYSIWYG',
  Table = 'Table'
}

export interface ContentUpdate {
  updatedContentBlockName: ContentBlock['name']
  fieldKey: EditableField['key']
  value: EditableField['value']
}

export interface Communication {
  id: number
  createdOn: string
  createdBy: string
  lasModifiedBy: string
  lasModifiedOn: string
  communicationType: CommunicationType
  name: string
  accountId: number
  content: any
  sent: number
  triggerId: number
  eventId: number
  status: CommunicationStatus
  templateId: number
  eventName: string
  templateName: string
  triggerType?: string
  triggerCondition?: string
  trigger?: CommunicationTrigger
  dynamicFields?: DynamicFields[]
  font?: string
}

export interface CommunicationInput {
  id?: number
  communicationType: CommunicationType
  name?: string
  status: CommunicationStatus
  templateId: number
  triggerType?: string
  triggerCondition?: string
  eventId?: number
  content: ContentBlock[]
}

export enum CommunicationStatus {
  Draft = 'Draft',
  Active = 'Active',
  Archived = 'Archived'
}

export enum DocumentRelationType {
  LeadServiceLine,
  Location,
  Facility,
  District,
  Fixture,
  Communication
}

export enum DocumentType {
  Other,
  Image,
  PDF,
  Excel
}

export interface TriggerToolbarItem {
  icon: string
  tooltip: string
  label: string
  insertType: string
  insertText: string
}

export interface CommunicationData {
  communicationType: string
  createdOn: string
  eventId: number
  eventName: string
  id: number
  name: string
  sent: number
  status: string
  templateName: string
}

export interface CommunicationResponse {
  total: number
  totalPages: number
  pageSize: number
  pageNumber: number
  items: CommunicationData[]
}

export interface CommunicationQueryResponse {
  communications: CommunicationResponse
}

export interface CommunicationQueryParams {
  filters?: CommunicationFilters
  pageSize?: number
  pageNumber?: number
  isPaged?: boolean
}

export interface UseCommunicationParams {
  accountId: number
  communicationType: CommunicationType
  params?: CommunicationQueryParams
}

export interface CommunicationFilters {
  status?: string
}
