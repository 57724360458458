import React, { FC, ReactNode } from 'react'
import { Provider } from 'react-redux'
import store from 'src/store'

const ReduxProvider: FC<Props> = props => <Provider store={store} {...props} />

interface Props {
  children: ReactNode
}

export default ReduxProvider
