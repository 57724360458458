import React from 'react'
import { Field } from 'react-final-form'
import { TritonTextField } from 'src/components/Forms/TextField'
import styled from 'styled-components'

export const SignatureBlock = (): JSX.Element => {
  return (
    <Grid style={{ textAlign: 'start' }}>
      <Field<string>
        component={TritonTextField}
        label="Signature"
        name="signature"
      />
      <Field<string> component={TritonTextField} label="Name" name="name" />
      <Field<string>
        style={{ marginTop: '5px' }}
        component={TritonTextField}
        label="Phone"
        name="phone"
        trimmedInput
      />
      <Field<string>
        component={TritonTextField}
        label="Email"
        name="email"
        trimmedInput
      />
      <div
        style={{
          gridColumnStart: 'span 2',
          display: 'flex',
          gap: '8px',
          marginTop: '5px'
        }}>
        <Field<boolean>
          component="input"
          id="verified"
          name="verified"
          type="checkbox"
        />
        <label htmlFor="verified">
          I verify this submission to be accurate and true to the best of my
          knowledge.
        </label>
      </div>
    </Grid>
  )
}

const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  & > h3 {
    grid-column-start: span 2;
  }
  & > div.field {
    margin: 0 !important;
  }
`
