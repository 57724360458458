import { colors } from '@120wateraudit/envirio-components/dist/theme'
import styled from 'styled-components'
import { Button as BaseButton } from '@120wateraudit/envirio-components'

export const SendCommunicationButtonStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledAssignButton = styled.div`
  display: flex;
  margin-right: 8px;
  justify-content: flex-end;
`

export const ModalHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  position: relative;

  .dropdown {
    margin-bottom: 2rem;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CenteredTextContent = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`

export const Preview = styled.div`
  cursor: pointer;
  color: #00b5e1;
  text-decoration: underline;

  :hover {
    opacity: 75%;
  }
`

export const Button = styled(BaseButton)`
  width: 150px;
  margin: 0;
  ${props => props.fluid && `width: 100%`};
`

export const Icon = styled.img`
  width: 55px;
  height: 55px;
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`

export const Label = styled.div`
  color: rgba(0, 36, 45, 0.5);
  font-weight: 800;
  font-size: 1rem;
`

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.error};
  margin-bottom: 1.5rem;
`

export const Wrapper = styled.div`
  width: 350px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
