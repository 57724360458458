import { List } from 'semantic-ui-react'
import styled from 'styled-components'

import { SortDirection } from 'src/types/SortDirection'
import { formatDate, formatDateTime } from 'src/utils/formatDate'
import { Column, FilterDefinition, RowData, Sort } from './types'
import { SoftwarePackageName } from 'src/types/enums/SoftwarePackageName'
import {CustomFieldDefinition} from "@120wateraudit/custom-fields-ui-components";

export const convertSortToString = (sort: Sort): string => {
  return sort.direction === SortDirection.DESC ? `-${sort.key}` : sort.key
}

export const convertStringToSorts = (sortString: string): Sort[] => {
  return sortString.split(',').map(sort => {
    const trimmed = sort.trim()
    const isDescending = trimmed.startsWith('-')
    return {
      direction: isDescending ? SortDirection.DESC : SortDirection.ASC,
      key: isDescending ? trimmed.substring(1) : trimmed
    }
  })
}

export const FilterOptions = styled(List)`
  &&& {
    max-height: 400px;
    overflow-y: scroll;
    min-width: 150px;
    & > hr {
      border: 1px solid #e3e7ed;
    }
  }
`

export const FilterOption = styled(List.Item)`
  &&& {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

interface CustomFieldRowData extends RowData {
  customFieldValues?: {
    customFieldDefinitionId: number
    valueText?: string
    valueNumber?: number
    valueDateTime?: string
    valueBit?: number
  }[]
}

function makeCustomFieldAccessor(definition: CustomFieldDefinition) {
  return (row: CustomFieldRowData) => {
    if (!Array.isArray(row.customFieldValues)) {
      return '--'
    }
    const value = row.customFieldValues.find(
      value => {
        const defId: number = definition.id ? parseInt(definition.id.toString()) : 0
        return value.customFieldDefinitionId === defId
      }
    )
    if (!value) {
      return '--'
    }
    const dataType = definition.dataType?.toString() 

    switch (dataType) {
      case 'DATE':
      case '3':
        return value?.valueDateTime ? formatDate(value.valueDateTime) : '--'
      case 'STRING':
      case 'HYPERLINK':
      case 'LIST':
      case '1':
      case '6':
      case '7':
        return value?.valueText ?? '--'
      case 'NUMBER':
      case '2':
        return value?.valueNumber ?? '--'
      case 'DATETIME':
      case '4':
        return value?.valueDateTime ? formatDateTime(value.valueDateTime) : '--'
      case 'BOOLEAN':
      case '5':
        if (value?.valueBit === null) {
          return '--'
        }
        return value?.valueBit ? 'True' : 'False'
      default:
        return '--'
    }
  }
}

export function addCustomFieldColumns<D extends RowData>(
  columns: Column<D>[],
  customFieldDefinitions?: CustomFieldDefinition[]
): Column<D>[] {
  if (!customFieldDefinitions) {
    return [...columns]
  }

  const customFieldColumns = customFieldDefinitions.map(
    def =>
      ({
        Header: def.name,
        accessor: makeCustomFieldAccessor(def),
        hiddenByDefault: true,
        key: `${def.name}`,
        name: def.name,
        sortable: false
      } as Column<D>)
  )
  return [...columns, ...customFieldColumns]
}

export function setColumnsForUser(
  columns: Column[],
  softwarePackage
): Column[] {
  if (softwarePackage === SoftwarePackageName.PwsPortal) {
    return columns.filter(column => column.key !== 'programs')
  }
  return columns
}

export function setFiltersForUser(
  filters: FilterDefinition[],
  softwarePackage
): FilterDefinition[] {
  if (softwarePackage === SoftwarePackageName.PwsPortal) {
    return filters.filter(
      filter =>
        filter.key !== 'programId' &&
        filter.key !== 'programTypeId' &&
        filter.key !== 'samplingEventId'
    )
  }
  return filters
}
