import styled from 'styled-components'
import {
  fontSize,
  colors,
  Button as BaseButton
} from '@120wateraudit/envirio-components'
import { Link as BaseLink } from 'react-router-dom'

export const Title = styled.div`
  font-weight: bold;
  font-size: ${fontSize.body};
`
export const SubLabel = styled.div`
  color: ${colors.black50};
`

export const Bold = styled.span`
  font-weight: 700;
`

export const Link = styled(BaseLink)`
  color: inherit;
  text-decoration: none;

  :hover {
    color: inherit;
  }
`

export const Button = styled(BaseButton)`
  min-width: 150px;
`

export const centerElement = `
  display: flex;
  justify-content: center;
  align-items: center;
`

export const verticalAlignElement = `
  display: flex;
  align-items: center;
`
