import React from 'react'
import { Field } from 'react-final-form'

import {
  initialInventoryOrUpdateOptions,
  ownershipTypeOptions,
  yesNoDropdownOptions,
  yesNoUnknownDropdownOptions
} from 'src/modules/ServiceLines/dataAccess'
import { Divider } from 'semantic-ui-react'
import {
  GeneralContainer,
  BoldSpan,
  NoRedHighlightTextField,
  SimpleDropdown,
  SuperAsterisk
} from '../shared'

interface Props {
  questionOneRequired?: boolean
  questionTwoRequired?: boolean
  questionThreeRequired?: boolean
  questionFourRequired?: boolean
  questionFiveRequired?: boolean
  questionSixRequired?: boolean
  questionSevenRequired?: boolean
  questionEightRequired?: boolean
  questionNineRequired?: boolean
  questionThreeConditionallyRequired?: boolean
  questionFiveConditionallyRequired?: boolean
}

const SummaryPart1 = ({
  questionOneRequired,
  questionTwoRequired,
  questionThreeRequired,
  questionFourRequired,
  questionFiveRequired,
  questionSixRequired,
  questionSevenRequired,
  questionEightRequired,
  questionNineRequired,
  questionThreeConditionallyRequired,
  questionFiveConditionallyRequired
}: Props): JSX.Element => {
  return (
    <GeneralContainer>
      <Field<string>
        label={
          <p>
            Is this the <BoldSpan>Initial Inventory</BoldSpan> or an{' '}
            <BoldSpan>Inventory Update</BoldSpan>?
            {questionOneRequired && <SuperAsterisk />}
          </p>
        }
        name="inventorySummarypart1Question1initialInventoryOrUpdate"
        component={SimpleDropdown}
        fluid
        options={initialInventoryOrUpdateOptions}
      />
      <Field<string>
        label={
          <p>
            Who <BoldSpan>owns the service lines</BoldSpan> in your system? If
            other, please explain below.
            {questionTwoRequired && <SuperAsterisk />}
          </p>
        }
        name="inventorySummarypart1Question2aServiceLineOwnership"
        component={SimpleDropdown}
        options={ownershipTypeOptions}
        fluid
      />
      <Field<string>
        displayConditionallyRequired={questionThreeConditionallyRequired}
        displayRequiredAsterisk={questionThreeRequired}
        hideError
        label="If other please explain:"
        name="inventorySummarypart1Question2aServiceLineOwnershipOtherExplanation"
        component={NoRedHighlightTextField}
        textarea
      />
      <Divider />
      <Field<string>
        label={
          <p>
            Is there documentation that defines service line ownership in your
            system, such as a local ordinance? If yes, please describe below and
            explain where ownership is split (e.g., property line, curb stop).
            {questionFourRequired && <SuperAsterisk />}
          </p>
        }
        name="inventorySummarypart1Question2bServiceLineOwnership"
        component={SimpleDropdown}
        fluid
        options={yesNoDropdownOptions}
      />
      <Field<string>
        hideError
        displayConditionallyRequired={questionFiveConditionallyRequired}
        displayRequiredAsterisk={questionFiveRequired}
        label="If Yes, please describe:"
        name="inventorySummarypart1Question2bServiceLineOwnershipOtherExplanation"
        component={NoRedHighlightTextField}
        textarea
      />
      <Divider />
      <Field<string>
        displayRequiredAsterisk={questionSixRequired}
        hideError
        label="Describe when lead service lines were generally installed in your system."
        name="inventorySummarypart1Q3aInstallationExplanation"
        component={NoRedHighlightTextField}
        textarea
      />
      <Field<string>
        displayRequiredAsterisk={questionSevenRequired}
        hideError
        label="When were lead service lines banned in your system? Reference the state or local ordinance that banned the use of lead in your system."
        name="inventorySummarypart1Q3bBanningExplanation"
        component={NoRedHighlightTextField}
        textarea
      />
      <Divider />
      <Field<string>
        displayRequiredAsterisk={questionEightRequired}
        label={
          <p>
            Do you have lead goosenecks, pigtails or connectors in your system?
            {questionEightRequired && <SuperAsterisk />}
          </p>
        }
        name="inventorySummarypart1Q4LeadGoosenecks"
        component={SimpleDropdown}
        textarea
        options={yesNoUnknownDropdownOptions}
      />
      <Field<string>
        displayRequiredAsterisk={questionNineRequired}
        hideError
        label="What is your overall level of confidence in the inventory (i.e., Low, Medium, or High.) Please explain your rationale below."
        name="inventorySummarypart1Q5ConfidenceLevel"
        component={NoRedHighlightTextField}
        textarea
      />
    </GeneralContainer>
  )
}

export default SummaryPart1
