import gql from 'graphql-tag'

export const GET_LSL_PROBABILITY_GEO_INFO = gql`
  query getProbabilityGeoInfoResolver(
    $accountId: Int!
    $high: Boolean!
    $medium: Boolean!
    $low: Boolean!
  ) {
    getLSLProbabilityGeoInfoResolver(
      accountId: $accountId
      high: $high
      medium: $medium
      low: $low
    ) {
      result
      addressLine1
      city
      state
      zip
      latitude
      longitude
      predictionId
      locationId
      predictionDate
    }
  }
`
