import React, { useCallback, useMemo, useEffect } from 'react'
import { Loader } from '@120wateraudit/envirio-components'
import pluralize from 'pluralize'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'

import SendModalFooter from './SendModalFooter'
import {
  ButtonsWrapper,
  ErrorMessage,
  ModalContent,
  ModalHeading
} from './shared'
import SendModalContent from './SendModalContent'
import useProgramsAndEvents from 'src/hooks/useProgramsAndEvents'
import { ModalWrapper } from 'src/modules/Samples/modals/CompleteSamplesModal/shared'
import { pwsClient } from 'src/apollo-clients'
import { ADD_LOCATIONS_TO_PROGRAM } from '../../dataAccess'
import { SupportLink } from 'src/components/SupportLink'
import { useFeatures } from 'src/hooks'
import { usePerformBulkActionByTypeMutation } from 'src/modules/ServiceLines/dataAccess'
import { BulkActionType } from 'src/types/enums/BulkActionType'

const TOTAL_STEPS = 3

const AssignProgramsModal: React.FC<AssignProgramProps> = ({
  accountId,
  bulkActionType,
  isSelectAllOn,
  locationIds,
  paramsURL,
  onClose,
  onCompletion,
  programId,
  selectAllCount
}) => {
  const [stepNumber, setStepNumber] = React.useState<number>(0)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  )
  const {
    event,
    events: baseEvents,
    onEventSelect,
    onProgramSelect,
    program,
    programs: basePrograms,
    loading: loadingEventsOrPrograms
  } = useProgramsAndEvents(undefined, programId)

  const [assignToLocations] = useMutation(ADD_LOCATIONS_TO_PROGRAM, {
    client: pwsClient,
    onCompleted: () => {
      setLoading(false)
      setStepNumber(TOTAL_STEPS - 1)
    },
    onError: ({ graphQLErrors }) => {
      setLoading(false)
      setErrorMessage(graphQLErrors[0].message)
    }
  })

  const onNextClicked = useCallback(() => {
    setStepNumber(stepNumber =>
      stepNumber < TOTAL_STEPS - 1 ? stepNumber + 1 : TOTAL_STEPS - 1
    )
  }, [])

  const onBackClicked = useCallback(() => {
    setStepNumber(stepNumber => (stepNumber > 0 ? stepNumber - 1 : 0))
  }, [])

  const [assignBulkAssetLocations, { isLoading: isbulkAssigning, data }] =
    usePerformBulkActionByTypeMutation()
  const { uniqueLocations: totalBulkAssignedLocations } = data ?? {}

  const onAssign = useCallback(async () => {
    if (
      isSelectAllOn &&
      bulkActionType &&
      event?.id &&
      program?.id &&
      paramsURL
    ) {
      try {
        await assignBulkAssetLocations({
          bulkActionType,
          eventId: event?.id,
          paramsURL,
          programId: program?.id
        }).unwrap()

        setStepNumber(TOTAL_STEPS - 1)
      } catch (error) {
        const errorMessage = error.data?.errors[0].detail
          ? error.data?.errors[0].detail
          : 'An error occurred. Please try again.'

        setErrorMessage(errorMessage)
      }
    } else {
      setLoading(true)
      await assignToLocations({
        variables: {
          accountId,
          eventId: event?.id,
          programId: program?.id,
          locationIds
        }
      })
    }

    if (onCompletion) onCompletion()
  }, [
    accountId,
    bulkActionType,
    assignBulkAssetLocations,
    event,
    isSelectAllOn,
    paramsURL,
    program,
    locationIds,
    assignToLocations,
    onCompletion
  ])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage(undefined)
      }, 8000)
    }
  }, [errorMessage])

  const locationString =
    selectAllCount && isSelectAllOn
      ? pluralize('Location', selectAllCount)
      : pluralize('Location', locationIds.length)
  const renderHeading = useCallback(
    step => {
      const HEADINGS = {
        0: `Assign ${locationString} to Program`,
        1: `Assign ${locationString} to Event`,
        2: `Assign ${locationString} Complete`
      }
      return HEADINGS[step] || `Assign ${locationString}`
    },
    [locationString]
  )

  const optionsEvents = useMemo(() => {
    return (
      baseEvents?.map((p: any) => ({
        key: p.id,
        text: p.name,
        value: p.id
      })) ?? []
    )
  }, [baseEvents])

  const optionsPrograms = useMemo(() => {
    return (
      basePrograms?.map((p: any) => ({
        key: p.id,
        text: p.name,
        value: p.id
      })) ?? []
    )
  }, [basePrograms])
  const { features } = useFeatures()

  const hasProgramFeature = features?.some(f => f.code.startsWith('PT:'))

  if (loading || loadingEventsOrPrograms || isbulkAssigning) {
    return (
      <ModalWrapper>
        <ModalHeading>
          <h5>{renderHeading(stepNumber)}</h5>
        </ModalHeading>
        <Loader />
      </ModalWrapper>
    )
  }
  if (!hasProgramFeature) {
    return (
      <ModalWrapper>
        <ModalHeading>
          <h5>{renderHeading(stepNumber)}</h5>
        </ModalHeading>
        <StyledHeader>
          Want to use this feature?{' '}
          <SupportLink>Reach out to support</SupportLink> to upgrade your
          package!
        </StyledHeader>
      </ModalWrapper>
    )
  }
  return (
    <ModalWrapper>
      <ModalHeading>
        <h5>{renderHeading(stepNumber)}</h5>
      </ModalHeading>
      <React.Suspense fallback={<Loader />}>
        <>
          <ModalContent>
            <SendModalContent
              optionsEvents={optionsEvents}
              optionsPrograms={optionsPrograms}
              stepNumber={stepNumber}
              selectedEvent={event}
              selectedProgram={program}
              onEventSelect={onEventSelect}
              onProgramSelect={onProgramSelect}
              onClose={onClose}
              locationIds={locationIds}
              totalBulkAssignedLocations={totalBulkAssignedLocations}
            />
          </ModalContent>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <ButtonsWrapper>
            <SendModalFooter
              selectedEvent={event}
              selectedProgram={program}
              stepNumber={stepNumber}
              onAssign={onAssign}
              onBack={onBackClicked}
              onNext={onNextClicked}
              onClose={onClose}
              loading={loading || isbulkAssigning}
              inProgram={!!programId}
            />
          </ButtonsWrapper>
        </>
      </React.Suspense>
    </ModalWrapper>
  )
}

interface AssignProgramProps {
  accountId: number
  bulkActionType?: BulkActionType
  isSelectAllOn?: boolean
  locationIds: number[]
  paramsURL?: string
  programId?: number
  onClose: () => void
  onCompletion: () => void
  selectAllCount?: number
}

const StyledHeader = styled.h4`
  font-weight: 500;
`
export default React.memo(AssignProgramsModal)
