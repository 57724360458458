import React from 'react'
import { Button } from '@120wateraudit/envirio-components'
import { Form as FinalForm } from 'react-final-form'
import { Form, Loader } from 'semantic-ui-react'

import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TritonError } from 'src/components/Error'
import { DrawerContents } from 'src/modules/Modal/Drawer'
import ContactInfo from '../ContactInfo'
import LocationInfo from '../LocationInfo'
import { useInitialValues, useCreateLocation } from './hooks'
import { validate } from '../../dataAccess'
import { Row } from 'src/components/Layout'
import { useFeatures } from "src/hooks";

interface Props {
  onClose(): void
}

const AddLocationModal = ({ onClose }: Props): JSX.Element => {
  const {
    accountHasContactsFeature
  } = useFeatures()
  const initialValues = useInitialValues()
  const { errors, onSubmit } = useCreateLocation()
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <Form onSubmit={handleSubmit}>
          <DrawerContents
            header={
              <Header onClose={onClose} submitting={submitting} valid={valid} />
            }>
            {errors && (
              <TritonError style={{ margin: '12px 24px' }} messages={errors} />
            )}
            <LocationInfo />
            {accountHasContactsFeature && <ContactInfo />}
          </DrawerContents>
        </Form>
      )}
      validate={values => validate(values, 'create')}
    />
  )
}

const Header = ({
  onClose,
  submitting,
  valid
}: {
  submitting: boolean
  valid: boolean
  onClose(): void
}): JSX.Element => {
  return (
    <>
      <div>
        <h3>Create Location</h3>
      </div>
      <Row>
        <Button
          disabled={!valid || submitting}
          style={{ marginRight: '7px' }}
          variant="primary">
          <span style={{ display: 'flex' }}>
            {submitting ? 'Saving…' : 'Save'}{' '}
            <Loader
              style={{ marginLeft: '3px' }}
              active={submitting}
              inline
              size="tiny"
            />
          </span>
        </Button>
        <Button
          disabled={submitting}
          onClick={onClose}
          type="button"
          variant="error">
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </Row>
    </>
  )
}

export default AddLocationModal
