import React from 'react'
import { ModalWrapper } from './EditModal'
import { Button, ModalContent, ModalHeading } from 'src/modules/Modal/shared'
import { CircleArrowIcon } from './Icons/CircleArrowIcon'
import styled from 'styled-components'

interface Props {
    onClose: () => void
    isPlural: boolean
}

const LetterLogDownloadModal: React.FC<Props> = ({
    onClose,
    isPlural
}) => {
    return (
        <ModalWrapper>
            <ModalPadding>
                <ModalHeading>
                    <CircleArrowIcon />
                    <h4 style={{ fontWeight: 'bold' }}>Processsing your download</h4>
                </ModalHeading>
                <ModalContent>
                    <CenterContent>
                        <ModalParagraphText>
                            Hang tight! Your letter{isPlural ? 's' : ''} will download shortly to your device,
                            <br></br>
                            this could take a few moments.
                        </ModalParagraphText>
                        <Button onClick={onClose} variant="primary">
                            Close Window
                        </Button>
                    </CenterContent>
                </ModalContent>
            </ModalPadding>
        </ModalWrapper>
    )
}

const CenterContent = styled.div`
    text-align: center;
`

const ModalPadding = styled.div`
    padding: 3rem;
`

const ModalParagraphText = styled.p` 
    font-size: 1.5rem;
    text-align: center;
`

export default React.memo(LetterLogDownloadModal)