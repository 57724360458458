import { useCallback, useContext, useMemo } from 'react'

import { RowAction } from 'src/components/Table/types'
import { Submission } from 'src/types/Submission'

import { ModalContext } from 'src/modules/Modal'

interface ApiError {
  data: {
    error: string
  }
}

export const isApiError = (error: unknown): error is ApiError => {
  return (error as ApiError).data.error !== undefined
}

const useRowActions = (): RowAction<Submission>[] => {
  const { openModal } = useContext(ModalContext)
  const showActivityComments = useCallback(
    (submission: Submission) =>
      openModal('showSubmissionActivity', { modalSize: 'wide', submission }),
    [openModal]
  )

  return useMemo(
    () => [
      {
        label: 'Show Activity',
        onClick: showActivityComments
      }
    ],
    [showActivityComments]
  )
}

export default useRowActions
