import React from 'react'
import styled from 'styled-components'
import { Message } from 'semantic-ui-react'

type Props = {
  stateValidationErrors: any
  scroll?: boolean
}

const StateSubmissionValidationErrors = ({
  stateValidationErrors,
  scroll
}: Props) => {
  const fieldErrors = stateValidationErrors?.fieldErrors

  const handleClickScroll = val => {
    if (scroll) {
      const element = document.getElementById(val)
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  }

  return (
    <>
      {fieldErrors && (
        <>
          <StyledMessage color="yellow">
            <Message.Header>State Submission Validation Errors:</Message.Header>
            {fieldErrors.map((val, i) => (
              <ValidationMessage
                key={i}
                scroll={scroll}
                onClick={() => {
                  handleClickScroll(val?.field)
                }}>
                {val.errors}
              </ValidationMessage>
            ))}
          </StyledMessage>
        </>
      )}
    </>
  )
}

const StyledMessage = styled(Message)`
  word-wrap: break-word;
`

const ValidationMessage = styled.p`
  font-size: 14px;
  font-weight: normal;
  cursor: ${({ scroll }) => scroll && 'pointer'};
  &:hover {
    color: ${({ scroll }) => scroll && 'blue'};
  }
`

export default StateSubmissionValidationErrors
