import React, { memo } from "react";
import { Checkbox } from "semantic-ui-react";
import { CommunicationTypes } from "src/containers/Locations/SendCommunicationButton";
import styled from "styled-components";
import { SendCommunicationButtonProps } from ".";
import { Button } from "./shared";

const SendModalFooter: React.FC<Props> = ({
  isSaving,
  locationIds,
  onBack,
  onDownload,
  onNext,
  onSend,
  onSkipDuplicatesChange,
  previousSentCommLocationIds,
  selectedCommunication,
  selectedCommunicationType,
  stepNumber,
  toggleModal,
}) => {
  const isDownload =
    selectedCommunicationType === CommunicationTypes["Letter as PDF"];

  if (stepNumber === 0) {
    return (
      <>
        <Button onClick={toggleModal}>Cancel</Button>
        <Button
          disabled={
            selectedCommunication === undefined || !selectedCommunicationType
          }
          onClick={onNext}
          variant="primary"
        >
          Next
        </Button>
      </>
    );
  }

  if (stepNumber === 1) {
    if (
      Array.isArray(previousSentCommLocationIds) &&
      previousSentCommLocationIds.length > 0
    ) {
      return (
        <ButtonContainer>
          <ButtonGrid>
            {locationIds.length > 1 &&
              previousSentCommLocationIds.length >= 1 && (
                <Checkbox
                  label="Skip Duplicates"
                  onChange={onSkipDuplicatesChange}
                />
              )}
            <Button
              disabled={isSaving}
              fluid
              onClick={isDownload ? onDownload : onSend}
              variant="primary"
            >
              {previousSentCommLocationIds.length === 1 ? "Send" : "Send All"}
            </Button>
            <Button fluid onClick={onBack}>
              Back
            </Button>
          </ButtonGrid>
        </ButtonContainer>
      );
    }

    return (
      <>
        <Button onClick={onBack}>Back</Button>
        <Button
          disabled={isSaving}
          onClick={isDownload ? onDownload : onSend}
          variant="primary"
        >
          {isDownload ? "Download PDF" : isSaving ? "Sending..." : "Send"}
        </Button>
      </>
    );
  }

  return null;
};

type Props = Pick<
  SendCommunicationButtonProps,
  | "stepNumber"
  | "toggleModal"
  | "onNext"
  | "onBack"
  | "onSend"
  | "selectedCommunication"
  | "selectedCommunicationType"
  | "onDownload"
  | "isSaving"
  | "previousSentCommLocationIds"
  | "onSkipDuplicatesChange"
  | "locationIds"
>;

const ButtonContainer = styled.div<{ fluid?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 1rem;
  justify-items: center;
`;

export default memo(SendModalFooter);
