import GenerateCrudSaga from './helpers/CrudSaga'
import actions from '../actions/programTotals'
import { fetchProgramTotals, getProgramTotals } from '../API'

const saga = GenerateCrudSaga({
  entityName: 'programTotals',
  actions,
  fetchCollectionMethod: getProgramTotals,
  fetchEntityMethod: fetchProgramTotals,
  pagedCollection: false
})

export default saga
