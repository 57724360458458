import { Action, combineReducers } from 'redux'
import { ApplicationState } from '../'
import programs from './programs'
import samplingEvents from './samplingEvents'

const crossEntityReducer = combineReducers({
  programs,
  samplingEvents
})

const rootReducer = (state: ApplicationState | any, action: Action) => {
  const affectedState = {
    programs: state.programs,
    samplingEvents: state.samplingEvents
  }

  const intermediateState = crossEntityReducer(affectedState, action)

  return {
    ...state,
    ...intermediateState
  }
}

export default rootReducer
