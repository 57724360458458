import { PWSProgram } from '@120wateraudit/envirio-components/dist/models'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import ProgramActions from '../../actions/programs'
import { IndexReducerState, initialState } from '../helpers/IndexReducer'

const reducer = reducerWithInitialState<IndexReducerState<PWSProgram>>(
  initialState
)
  .case(
    ProgramActions.createActions.createSuccess,
    (
      state: IndexReducerState<PWSProgram>,
      { program }: { program: PWSProgram }
    ) => {
      const items = state.items.slice()
      items.push(program)
      return {
        ...state,
        items
      }
    }
  )
  .case(
    ProgramActions.updateActions.updateSuccess,
    (
      state: IndexReducerState<PWSProgram>,
      { program }: { program: PWSProgram }
    ) => {
      return {
        ...state,
        items: state.items.map(item => {
          return item.id === program.id ? program : item
        })
      }
    }
  )

export default reducer
