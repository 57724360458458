import gql from 'graphql-tag'

const SERVICE_LINE_FRAGMENTS = gql`
  fragment Profile on Profile {
    tier
  }

  fragment Location on Location {
    id
    addressLine1
    addressLine2
    city
    state
    zip
    latitude
    longitude
    profile {
      ...Profile
    }
    programs {
      id
      name
    }
    samplingEvents {
      name
      programId
    }
  }

  fragment ServiceLineAsset on ServiceLineAsset {
    id
    privateClassificationBasis
    privateMaterial {
      id
      selectionValue
      dataCode
      epaReportingCategory
      ptdDisplayDefault {
        displayColor
        ptdDisplay
      }
      rawValue
      overrideValues {
        overrideValues {
          displayColor
        }
      }
    }
    privateLeadSolderLeadLined
    privateNotes
    privateVerified
    privateVerifiedDate
    privateVerificationMethod
    privateRemovalDate
    privateInstalledDate
    privateLineDepth
    publicClassificationBasis
    publicMaterial {
      id
      selectionValue
      dataCode
      epaReportingCategory
      ptdDisplayDefault {
        displayColor
        ptdDisplay
      }
      rawValue
      overrideValues {
        overrideValues {
          displayColor
        }
      }
    }
    publicLeadSolderLeadLined
    publicNotes
    publicVerified
    publicVerifiedDate
    publicVerificationMethod
    publicRemovalDate
    publicInstalledDate
    publicLineDepth
    leadFittings
    fittingsVerifiedDate
    fittingsVerificationMethod
    status
    materialClassification
    privateVerifiedBy
    publicVerifiedBy
    fittingVerifiedBy
    publicPreviouslyLead
    leadSolder
    otherLeadEquipment
    privateLineDiameter {
      id
      decimalValue
      displayValue
    }
    publicLineDiameter {
      id
      decimalValue
      displayValue
    }
    publicMaterialOther
    publicVerificationMethodOther
    privateMaterialOther
    privateVerificationMethodOther
    fittingsVerificationMethodOther
    publicInstalledAfterLeadBan
    privateInstalledAfterLeadBan
    ownership
    publicDiameterOverTwoInches
    privateDiameterOverTwoInches
    consumerNoticeCompleted
    consumerNoticeCompletionDate
    privateClassificationBasisNotes
    publicClassificationBasisNotes
  }

  fragment ServiceLine on ServiceLine {
    id
    active
    externalId
    description
    latitude
    longitude
    location {
      ...Location
    }
    serviceLineAsset {
      ...ServiceLineAsset
    }
  }
`

export const SERVICE_LINES_QUERY = gql`
  ${SERVICE_LINE_FRAGMENTS}

  query ServiceLines(
    $accountId: ID!
    $locationId: ID
    $pageNumber: Int
    $pageSize: Int
    $search: ServiceLineSearchInput
    $sort: ServiceLineSort
    $direction: SortDirection
  ) {
    serviceLines(
      accountId: $accountId
      locationId: $locationId
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sort: $sort
      direction: $direction
    ) {
      items {
        ...ServiceLine
      }
      count
      pages
      pageNumber
      pageSize
    }
  }
`

export const SERVICE_LINE_MATERIAL_QUERY = gql`
  query serviceLineMaterials($accountId: Int!) {
    serviceLineMaterials(accountId: $accountId) {
      selectionValue
      dataCode
      id
    }
  }
`

export const SERVICE_LINE_DIAMETER_QUERY = gql`
  query serviceLineDiameters($accountId: Int!) {
    serviceLineDiameters(accountId: $accountId) {
      displayValue
      id
    }
  }
`

export const SERVICE_LINE_CREATE_MUTATION = gql`
  ${SERVICE_LINE_FRAGMENTS}

  mutation ServiceLineCreate(
    $accountId: ID!
    $asset: AssetInput!
    $serviceLine: ServiceLineInput!
  ) {
    serviceLineCreate(
      accountId: $accountId
      asset: $asset
      serviceLine: $serviceLine
    ) {
      ...ServiceLine
    }
  }
`

export const SERVICE_LINE_UPDATE_MUTATION = gql`
  ${SERVICE_LINE_FRAGMENTS}

  mutation ServiceLineUpdate(
    $accountId: ID!
    $assetId: ID!
    $asset: AssetInput!
    $serviceLine: ServiceLineInput!
  ) {
    serviceLineUpdate(
      accountId: $accountId
      assetId: $assetId
      asset: $asset
      serviceLine: $serviceLine
    ) {
      ...ServiceLine
    }
  }
`

export const SERVICE_LINE_DELETE_MUTATION = gql`
  mutation ServiceLineDelete($accountId: ID!, $assetId: ID!) {
    serviceLineDelete(accountId: $accountId, assetId: $assetId)
  }
`

export const PROFILE_UPDATE_MUTATION = gql`
  mutation ProfileUpdate(
    $accountId: ID!
    $locationId: ID!
    $profile: ProfileInput!
  ) {
    profileUpdate(
      accountId: $accountId
      locationId: $locationId
      profile: $profile
    ) {
      tier
    }
  }
`
