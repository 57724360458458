export const formatPhoneString = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return ''
  }
  const x = phoneNumber.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/)
  if (x === null) {
    return ''
  }
  return '(' + x[1] + ') ' + x[2] + '-' + x[3]
}

export const getStringFromFormattedPhone = (phoneNumber?: string) => {
  return phoneNumber
    ? phoneNumber.replace(/[^\w\s]/gi, '').replace(/\s+/g, '')
    : null
}
