import React, { memo } from 'react'
import { Checkbox } from 'semantic-ui-react'
import { CommunicationTypes } from 'src/containers/Locations/SendCommunicationButton'
import styled from 'styled-components'
import { Button } from 'src/modules/Modal/shared'
import { CommunicationOption } from 'src/containers/Locations/SendCommunicationButton/types'

const SendModalFooter: React.FC<Props> = ({
  disabledForError,
  stepNumber,
  onNext,
  onBack,
  onSend,
  selectedCommunication,
  selectedCommunicationType,
  onDownload,
  isSaving,
  isSelectAllOn,
  previousSentCommLocationIds,
  onSkipDuplicatesChange,
  locationIds,
  onClose
}) => {
  const isDownload =
    selectedCommunicationType === CommunicationTypes['Letter as PDF']

  if (stepNumber === 0) {
    return (
      <>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="primary"
          disabled={
            selectedCommunication === undefined ||
            !selectedCommunicationType ||
            disabledForError
          }
          onClick={onNext}>
          Next
        </Button>
      </>
    )
  }

  if (stepNumber === 1) {
    if (
      Array.isArray(previousSentCommLocationIds) &&
      previousSentCommLocationIds.length > 0 &&
      !isSelectAllOn
    ) {
      return (
        <ButtonContainer>
          <ButtonGrid>
            {locationIds.length > 1 &&
              previousSentCommLocationIds.length >= 1 && (
                <Checkbox
                  label="Skip Duplicates"
                  onChange={onSkipDuplicatesChange}
                />
              )}
            <Button
              variant="primary"
              onClick={isDownload ? onDownload : onSend}
              fluid
              disabled={isSaving || disabledForError}>
              {previousSentCommLocationIds.length === 1 ? 'Send' : 'Send All'}
            </Button>
            <Button onClick={onBack} fluid>
              Back
            </Button>
          </ButtonGrid>
        </ButtonContainer>
      )
    }

    return (
      <>
        <Button onClick={onBack}>Back</Button>
        <Button
          variant="primary"
          onClick={isDownload ? onDownload : onSend}
          disabled={isSaving || disabledForError}>
          {isDownload ? 'Download PDF' : isSaving ? 'Sending...' : 'Send'}
        </Button>
      </>
    )
  }

  return null
}

export interface Props {
  disabledForError: boolean
  stepNumber: number
  onNext: () => void
  onBack: () => void
  onSend: () => void
  onDownload: () => void
  selectedCommunication?: CommunicationOption
  selectedCommunicationType?: CommunicationTypes
  isSaving: boolean
  isSelectAllOn?: boolean
  previousSentCommLocationIds?: number[]
  locationIds: number[]
  onSkipDuplicatesChange: () => void
  onClose: () => void
}

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 1rem;
  justify-items: center;
`

export default memo(SendModalFooter)
