import React, { FC } from 'react'
import { Icon, Table, TableHeaderCellProps } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledHeaderCell = styled(Table.HeaderCell)`
  &&& {
    color: #6b7280 !important;
    font-size: 12px;
    line-height: 1.33;
    font-weight: 500;
    border-left: 0 !important;
    max-width: 300px;
    white-space: no-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &&&:hover {
    background: ${props => (props.sortable ? 'inherit' : '#f9fafb !important')};
    cursor: ${props => (props.sortable ? 'pointer' : 'inherit !important')};
    color: #00000f !important;
  }
`

interface Props extends TableHeaderCellProps {
  sortable?: boolean
  displayRequired?: boolean
  displayConditionallyRequired?: boolean
}

const HeaderCell: FC<Props> = ({
  children,
  displayRequired,
  displayConditionallyRequired,
  sortable = true,
  sorted,
  ...props
}) => {
  const onClick = sortable ? props.onClick : undefined

  return (
    <StyledHeaderCell
      {...props}
      onClick={onClick}
      sortable={sortable}
      sorted={sorted}>
      {children}
      {sortable && sorted === undefined && (
        <Icon name="sort" style={{ marginRight: '0px' }} />
      )}
      {displayRequired && (
        <sup>
          <Icon
            color="red"
            name="asterisk"
            style={{ fontSize: '6px', marginLeft: '1px' }}
          />
        </sup>
      )}
      {displayConditionallyRequired && (
        <sup>
          <Icon
            color="red"
            name="asterisk"
            style={{ fontSize: '6px', marginRight: '0px' }}
          />
          <Icon color="red" name="asterisk" style={{ fontSize: '6px' }} />
        </sup>
      )}
    </StyledHeaderCell>
  )
}

export default HeaderCell
