import GenerateCrudSaga from './helpers/CrudSaga'
import actions from '../actions/skus'
import { fetchSkus } from '../API'

const saga = GenerateCrudSaga({
  entityName: 'sku',
  actions,
  fetchCollectionMethod: fetchSkus
})

export default saga
