import React from 'react'
import { Field } from 'react-final-form'

import { Divider } from 'semantic-ui-react'
import {
  BoldSpan,
  DoubleInputGrid,
  FlexDiv,
  GeneralContainer,
  NoRedHighlightTextField
} from '../shared'

const ServiceLineInvestigations = (): JSX.Element => {
  return (
    <GeneralContainer>
      <p style={{ marginBottom: '0px' }}>
        {`Identify the service line investigation methods your system used to
        prepare the inventory (check all that apply). If a water system chooses
        an investigation method not specified by the state under 40 CFR
        141.84(a)(3)(iv), state approval is required. `}
        <BoldSpan>
          Note that investigations are not required by the LCRR but can be used
          by systems to assess accuracy of historical records and gather
          information when service line material is unknown.
        </BoldSpan>
      </p>
      <DoubleInputGrid>
        <FlexDiv>
          <Field<string>
            component="input"
            name="visualInspectionAtTheMeterPit"
            type="checkbox"
          />
          <label>Visual Inspection at the Meter Pit</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="customerSelfIdentification"
            type="checkbox"
          />
          <label>Customer Self-Identification</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="cctvInspectionAtCurbBoxExternal"
            type="checkbox"
          />
          <label>CCTV Inspection at Curb Box - External</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="cctvInspectionAtCurbBoxInternal"
            type="checkbox"
          />
          <label>CCTV Inspection at Curb Box - Internal</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="waterQualitySamplingTargeted"
            type="checkbox"
          />
          <label>Water Quality Sampling - Targeted</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="waterQualitySamplingFlushed"
            type="checkbox"
          />
          <label>Water Quality Sampling - Flushed</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="waterQualitySamplingSequential"
            type="checkbox"
          />
          <label>Water Quality sampling - Sequential</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="waterQualitySamplingOther"
            type="checkbox"
          />
          <label>Water Quality Sampling - Other</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="mechanicalExcavation"
            type="checkbox"
          />
          <label>Mechanical Excavation</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="vacuumExcavation"
            type="checkbox"
          />
          <label>Vacuum Excavation</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="predictiveModeling"
            type="checkbox"
          />
          <label>Predictive Modeling</label>
        </FlexDiv>
        <FlexDiv>
          <Field<string>
            component="input"
            name="otherInvestigation"
            type="checkbox"
          />
          <label>Other</label>
        </FlexDiv>
      </DoubleInputGrid>
      <Field<string>
        component={NoRedHighlightTextField}
        displayConditionallyRequired
        hideError
        label="If other please explain:"
        name="otherInvestigationExplanation"
        textarea
      />
      <Field<string>
        component={NoRedHighlightTextField}
        displayConditionallyRequired
        hideError
        label="If Predictive Modeling, please briefly describe the model and inputs used:"
        name="predictiveModelingExplanation"
        textarea
      />
      <Divider />
      <Field<string>
        component={NoRedHighlightTextField}
        label="How did you prioritize locations for service line materials investigations? For example, did you consider environmental justice and/or sensitive populations, did you use predictive modeling, and/or did you target areas with high number of unknowns?"
        name="locationPriorityExplanation"
        textarea
      />
    </GeneralContainer>
  )
}

export default ServiceLineInvestigations
