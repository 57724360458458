import * as React from 'react'
import styled from 'styled-components'
import { colors, spacing, fontSize } from '@120wateraudit/envirio-components'

import { SendOption } from '.'
import { Flags, useConfig } from 'src/hooks'

interface Props {
  sendOption: SendOption
  numberOfLetters: number
}

export const SendCompleted = ({ sendOption }: Props) => {
  const commsTwoEnabled = useConfig(Flags.CommsTwoEnabled)

  return (
    <Wrapper>
      {sendOption === SendOption.USPS
        ? letterOption()
        : downloadOption(commsTwoEnabled)}
    </Wrapper>
  )
}

const letterOption = () => {
  return (
    <CountLabel>
      Your result letters are in the queue to be sent via USPS. The send
      statuses will be available shortly.
    </CountLabel>
  )
}

const downloadOption = (commsTwoEnabled: boolean) => {
  if (commsTwoEnabled) {
    return (
      <>
        <Header>Please do not close your browser.</Header>
        <PDFLabel>
          Please note: Large quantities of letters may take longer to finish
          processing.
        </PDFLabel>
      </>
    )
  }

  return (
    <>
      <Header>Please do not close your browser.</Header>
      <PDFLabel>
        Your PDF will begin downloading as soon as it is ready.
        <br />
        Please note: large quantities of results letters may take longer to
        begin downloading.
      </PDFLabel>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const CountLabel = styled.div`
  color: ${colors.black50};
  padding-bottom: ${spacing.tiny};
  font-size: ${fontSize.body};
  text-align: center;
  max-width: 275px;
`

const PDFLabel = styled.div`
  color: ${colors.black50};
  padding-bottom: ${spacing.tiny};
  font-size: ${fontSize.body};
  line-height: 1.5em;
  text-align: center;
  max-width: 95%;
  padding-bottom: ${spacing.tiny};
`

const Header = styled.div`
  color: ${colors.black50};
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.2em;
  text-align: center;
  max-width: 95%;
  margin-bottom: 5%;
`
