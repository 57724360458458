import { Button, Dropdown } from '@120wateraudit/envirio-components'
import { PWSProgram } from '@120wateraudit/envirio-components/dist/models'
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { post } from 'src/API'
import { useDropdown, useToggle } from 'src/hooks'
import Modal from 'src/modules/Modal/ModalContents'
import { Location } from 'src/types/Location'
import { ProgramLocationStatusOptions } from 'src/types/Programs'
import { toastError, toastSuccess } from 'src/utils/toast'
import { useGetProgramLocationStatusOptionsQuery } from '../dataAccess'
import { useProgramTypeName } from '../hooks'

const VALID_REPLACEMENT_PROGRAM_STATUSES = [
  'Assigned',
  'Pre-Sampling',
  'Scheduled',
  'Replaced',
  'Post-Sampling',
  'Completed',
  'No Action Required'
]

const makeStatusOptions = (
  options: ProgramLocationStatusOptions[] = [],
  programType = 'default'
) => {
  return options
    .filter(opt =>
      programType === 'REPLACEMENT'
        ? VALID_REPLACEMENT_PROGRAM_STATUSES.includes(opt.name)
        : true
    )
    .map(opt => ({
      content: <StatusOption description={opt.description} name={opt.name} />,
      key: opt.name,
      text: opt.name,
      value: opt.name
    }))
}

const useProgramStatusOptions = (program: PWSProgram) => {
  const { programTypeId } = program
  const programTypeName = useProgramTypeName(program)
  const { data: statusOptions, isLoading } =
    useGetProgramLocationStatusOptionsQuery(programTypeId)
  const options = useMemo(
    () =>
      statusOptions && makeStatusOptions(statusOptions.data, programTypeName),
    [statusOptions, programTypeName]
  )
  // Hardcoding assigned to the starting value for the dropdown because it is always there.
  const [value, setValue] = useDropdown<string>('Assigned')
  return { isLoading, options, setValue, value }
}

const updateStatuses = async (
  locations: Location[],
  programId: number,
  status: string
) => {
  const { accountId } = locations[0]
  const updates = locations.map(l => ({ locationId: l.id, programId, status }))
  return post(`/pws/accounts/${accountId}/locations/statuses`, {
    data: updates
  })
}

interface Props {
  locations: Location[]
  program: PWSProgram
  onClose(): void
  onCompletion(): void
}

const LocationStatusUpdateModal = ({
  locations,
  onClose,
  onCompletion,
  program
}: Props) => {
  const { isLoading, options, setValue, value } =
    useProgramStatusOptions(program)
  const [submitting, toggleSubmit] = useToggle()
  const onUpdateClick = useCallback(async () => {
    toggleSubmit()
    try {
      if (!value) {
        throw Error('No Status selected.')
      }
      await updateStatuses(locations, program.id, value)
      onCompletion()
      onClose()
      toastSuccess(
        `${pluralize('Location', locations.length, true)} ${pluralize(
          'was',
          locations.length
        )} updated to status '${value}'`
      )
    } catch {
      toastError('An error occurred while updating the statuses')
    } finally {
      toggleSubmit()
    }
  }, [locations, program.id, value, onCompletion, onClose, toggleSubmit])
  const disableSubmit = isLoading || !value || submitting
  const primaryAction = (
    <Button
      disabled={disableSubmit}
      onClick={onUpdateClick}
      type="submit"
      variant="primary">
      Update
    </Button>
  )
  const cancelAction = <Button onClick={onClose}>Cancel</Button>

  return (
    <Modal
      cancelAction={cancelAction}
      primaryAction={primaryAction}
      title={`Update ${pluralize('Status', locations.length)}`}>
      <Container>
        <p>
          You are updating the status of{' '}
          {pluralize('Location', locations.length, true)} in the {program.name}{' '}
          Program.
        </p>
        <Dropdown
          addUndefinedOption={false}
          fluid
          label="Status"
          loading={isLoading}
          onChange={setValue}
          options={options}
          search={false}
          selection
          value={value}
        />
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  padding: 0 16%;
`

interface StatusOptionProps {
  description: string
  name: string
}

const StatusOption = ({ description, name }: StatusOptionProps) => {
  return (
    <Option>
      <p>{name}</p>
      <p>{description}</p>
    </Option>
  )
}

const Option = styled.div`
  & > p {
    margin: 0;
  }
  & > p:first-child {
    font-weight: bold;
    font-size: 14px;
  }
`

export default LocationStatusUpdateModal
