import React from 'react'
import { Field } from 'react-final-form'
import { Icon } from 'semantic-ui-react'

import {
  BasicContainer,
  ParagraphInputGrid,
  QuestionTitle,
  NoRedHighlightTextField
} from '../shared'

const HistoricalRecordsReview = (): JSX.Element => {
  return (
    <BasicContainer>
      <ParagraphInputGrid>
        <p>Type of Record</p>
        <p>
          Describe the records reviewed for your inventory and indicate your
          level of confidence (e.g., Low, Medium, or High). If not applicable,
          please put N/A.
        </p>
        <QuestionTitle>
          1. Previous Materials Evaluation{' '}
          <sup>
            <Icon
              color="red"
              name="asterisk"
              style={{ fontSize: '6px', marginLeft: '1px' }}
            />
          </sup>
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="previousMaterialsEvaluation"
          hide
          placeholder="Example: Locations of Tier 1 lead tap sampling locations that are
            served by a lead service line."
          textarea
        />
        <QuestionTitle>
          2. Construction Records and Plumbing Codes
          <sup>
            <Icon
              color="red"
              name="asterisk"
              style={{ fontSize: '6px', marginLeft: '1px' }}
            />
          </sup>
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="constructionRecordsAndPlumbingCodes"
          placeholder="Examples: Local ordinance adopting an international plumbing code.
            Permits for replacing lead service lines."
          textarea
        />
        <QuestionTitle>
          3. Water System Records{' '}
          <sup>
            <Icon
              color="red"
              name="asterisk"
              style={{ fontSize: '6px', marginLeft: '1px' }}
            />
          </sup>
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="waterSystemRecords"
          placeholder="Examples: Capital improvement plans. Standard operating procedures.
            Engineering standards."
          textarea
        />
        <QuestionTitle>
          4. Distribution System Inspections and Records
          <sup>
            <Icon
              color="red"
              name="asterisk"
              style={{ fontSize: '6px', marginLeft: '1px' }}
            />
          </sup>
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="distributionSystemInspectionsAndRecords"
          placeholder="Examples: Distribution system maps. Tap cards. Service line
            repair/replacement records. Inspection records. Meter installation
            records."
          textarea
        />
        <QuestionTitle>
          5. Other Records{' '}
          <sup>
            <Icon
              color="red"
              name="asterisk"
              style={{ fontSize: '6px', marginLeft: '1px' }}
            />
          </sup>
        </QuestionTitle>
        <Field<string>
          component={NoRedHighlightTextField}
          hideError
          name="historicalReviewOther"
          textarea
        />
      </ParagraphInputGrid>
    </BasicContainer>
  )
}

export default HistoricalRecordsReview
