import * as React from "react";
import styled from "../../theme";

interface Props {
  circleIcon: JSX.Element;
  color?: string;
  iconColor?: string;
  /** Defaults to `3rem` */
  iconSize?: string;
  style?: React.CSSProperties;
}

export const MetricCircle = ({
  circleIcon,
  color,
  iconColor,
  iconSize,
  style = {},
}: Props) => (
  <MetricCircleStyle
    color={color}
    iconColor={iconColor}
    iconSize={iconSize}
    style={style}
  >
    {circleIcon}
  </MetricCircleStyle>
);

interface MetricCircleStyleProps {
  color?: string;
  iconColor?: string;
  iconSize?: string;
}

export const MetricCircleStyle = styled.div<MetricCircleStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  height: ${(props) => props.height || '4rem'};
  width: 4rem;
  border-radius: 50%;
  margin-right: 1.33rem;

  & > svg {
    height: ${(props) => props.iconSize || "3rem"};
    width: ${(props) => props.iconSize || "3rem"};

    & rect {
      height: ${(props) => props.iconSize || "3rem"};
      width: ${(props) => props.iconSize || "3rem"};
    }

    g {
      fill: ${(props) => props.iconColor};
    }
  }
`;
