import React, { FC } from 'react'
import InsertableComponent from '../InsertableComponent'
import { DataComponentsWrapper } from 'src/modules/Modal/shared'
import { LocationData } from 'src/containers/Communications/Editor/types'

interface Props {
  data: LocationData
  onClick: (property: string) => void
  triggerType?: string
}

const LocationDataModelContent: FC<Props> = ({
  data,
  onClick,
  triggerType
}) => {
  return (
    <DataComponentsWrapper>
      {Object.keys(data).map((key, index) => (
        <InsertableComponent
          key={`${key}-${data[key]}-${index}`}
          propertyName={key}
          onClick={() => {
            key.startsWith('CF ')
              ? onClick(`{{[CF-Locations].[${key.replace('CF ', '')}]}}`)
              : key === 'fullAddress'
              ? onClick('{{address}}, {{city}}, {{state}} {{zip}}')
              : triggerType === 'AdHocLocation'
              ? onClick(`{{${key}}}`)
              : onClick(`{{l.${key}}}`)
          }}
        />
      ))}
    </DataComponentsWrapper>
  )
}

export default LocationDataModelContent
