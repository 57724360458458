import React from 'react'
import { Field } from 'react-final-form'

import {
  BoldSpan,
  DoubleInputGrid,
  FlexDiv,
  GeneralContainer,
  NoRedHighlightTextField,
  SuperAsterisk
} from '../../shared'

interface Props {
  questionOneRequired?: boolean
  questionOneAdditionalText?: string
  questionTwoConditionallyRequired?: boolean
  questionThreeConditionallyRequired?: boolean
}

const EpaMethodsPart3 = ({
  questionOneRequired,
  questionOneAdditionalText,
  questionTwoConditionallyRequired,
  questionThreeConditionallyRequired
}: Props): JSX.Element => {
  return (
    <GeneralContainer>
      <p style={{ marginBottom: '0px' }}>
        {`Identify the service line investigation methods your system used to
        prepare the inventory (check all that apply). If a water system chooses
        an investigation method not specified by the state under 40 CFR
        §141.84(a)(3)(iv), state approval is required. `}
        <BoldSpan>
          Note that investigations are not required by the LCRR but can be used
          by systems to assess accuracy of historical records and gather
          information when service line material is unknown.
        </BoldSpan>{' '}
        {questionOneAdditionalText && (
          <BoldSpan>{questionOneAdditionalText}</BoldSpan>
        )}
        {questionOneRequired && <SuperAsterisk />}
      </p>
      <DoubleInputGrid>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Visual Inspection at the Meter Pit"
          />
          <label>Visual Inspection at the Meter Pit</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Customer Self-Identification"
          />
          <label>Customer Self-Identification</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="CCTV Inspection at Curb Box - External"
          />
          <label>CCTV Inspection at Curb Box - External</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="CCTV Inspection at Curb Box - Internal"
          />
          <label>CCTV Inspection at Curb Box - Internal</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Water Quality Sampling - Targeted"
          />
          <label>Water Quality Sampling - Targeted</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Water Quality Sampling - Flushed"
          />
          <label>Water Quality Sampling - Flushed</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Water Quality sampling - Sequential"
          />
          <label>Water Quality sampling - Sequential</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Water Quality Sampling - Other"
          />
          <label>Water Quality Sampling - Other</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Mechanical Excavation"
          />
          <label>Mechanical Excavation</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Vacuum Excavation"
          />
          <label>Vacuum Excavation</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Predictive Modeling"
          />
          <label>Predictive Modeling</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryMethodsPart3Q1ServiceLineInvestigationChoices"
            type="checkbox"
            value="Other"
          />
          <label>Other</label>
        </FlexDiv>
      </DoubleInputGrid>
      <Field<string>
        component={NoRedHighlightTextField}
        displayConditionallyRequired={questionTwoConditionallyRequired}
        hideError
        label="If other please explain:"
        name="inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation"
        textarea
      />
      <Field<string>
        component={NoRedHighlightTextField}
        displayConditionallyRequired={questionThreeConditionallyRequired}
        hideError
        label="If Predictive Modeling, please briefly describe the model and inputs used:"
        name="inventoryMethodsPart3Q2PredictiveModelingExplanation"
        textarea
      />
      <Field<string>
        component={NoRedHighlightTextField}
        label="How did you prioritize locations for service line materials investigations? For example, did you consider environmental justice and/or sensitive populations, did you use predictive modeling, and/or did you target areas with high number of unknowns?"
        name="inventoryMethodsPart3Q3PrioritizingLocationsExplanation"
        textarea
      />
    </GeneralContainer>
  )
}

export default EpaMethodsPart3
