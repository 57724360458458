import React from 'react'
import { Field } from 'react-final-form'
import { Divider } from 'semantic-ui-react'

import Dropdown from 'src/components/Forms/Dropdown'
import { TritonTextField } from 'src/components/Forms/TextField'
import { yesNoDropdownOptions } from 'src/modules/ServiceLines/dataAccess'
import { BoldSpan, DoubleInputGrid, FlexDiv, GeneralContainer } from '../shared'

interface Props {
  publicAccessChoicesLabelOverride?: string
}

const EpaPublicAccessibilityDoc = ({publicAccessChoicesLabelOverride}: Props): JSX.Element => {
  return (
    <GeneralContainer>
      <span>
        Select the location identifiers that you use for your service line
        inventory. Check all that apply.
      </span>
      <DoubleInputGrid>
        <FlexDiv>
          <Field
            component="input"
            name="locationIdentifierChoices"
            type="checkbox"
            value="Address"
          />
          <label>Address</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="locationIdentifierChoices"
            type="checkbox"
            value="Street"
          />
          <label>Street</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="locationIdentifierChoices"
            type="checkbox"
            value="Block"
          />
          <label>Block</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="locationIdentifierChoices"
            type="checkbox"
            value="Intersection"
          />
          <label>Intersection</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="locationIdentifierChoices"
            type="checkbox"
            value="Landmark"
          />
          <label>Landmark</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="locationIdentifierChoices"
            type="checkbox"
            value="GPS Coordinates"
          />
          <label>GPS Coordinates</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="locationIdentifierChoices"
            type="checkbox"
            value="Other"
          />
          <label>Other</label>
        </FlexDiv>
      </DoubleInputGrid>
      <Field<string>
        component={TritonTextField}
        label="If Other, please describe:"
        name="locationIdentifierOtherExplanation"
        textarea
      />
      <Divider />
      <Field<string>
        component={Dropdown}
        fluid
        label={
          <p>
            Does every <BoldSpan>service line</BoldSpan> have a location
            identifier?
          </p>
        }
        name="locationIdentifierServiceLineYesNo"
        options={yesNoDropdownOptions}
      />
      <Field<string>
        component={TritonTextField}
        label="If No, explain. Remember that location identifiers are required for service lines that are lead and galvanized requiring replacement."
        name="locationIdentifierServiceLineNoExplanation"
        textarea
      />
      <Divider />
      <p style={{ marginBottom: '0px' }}>
        {
          publicAccessChoicesLabelOverride 
          || `How are you making your inventory publicly accessible? Check all that
          apply. Remember that if your system serves > 50,000 people, you must
          provide the inventory online.`
        }
      </p>
      <DoubleInputGrid>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryPublicAccessChoices"
            type="checkbox"
            value="Interactive online map"
          />
          <label>Interactive online map</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryPublicAccessChoices"
            type="checkbox"
            value="Static online map"
          />
          <label>Static online map</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryPublicAccessChoices"
            type="checkbox"
            value="Online spreadsheet"
          />
          <label>Online spreadsheet</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryPublicAccessChoices"
            type="checkbox"
            value="Printed service line map"
          />
          <label>Printed service line map</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryPublicAccessChoices"
            type="checkbox"
            value="Printed tabular Data"
          />
          <label>Printed tabular data</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryPublicAccessChoices"
            type="checkbox"
            value="Information on water utility mailings or newsletter"
          />
          <label>Information on water utility mailings or newsletter</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryPublicAccessChoices"
            type="checkbox"
            value="Hard copy information available in water system office"
          />
          <label>Hard copy information available in water system office</label>
        </FlexDiv>
        <FlexDiv>
          <Field
            component="input"
            name="inventoryPublicAccessChoices"
            type="checkbox"
            value="Other"
          />
          <label>Other</label>
        </FlexDiv>
      </DoubleInputGrid>
      <Field<string>
        component={TritonTextField}
        label="If Other, please describe:"
        name="inventoryPublicAccessOtherExplanation"
        textarea
      />
    </GeneralContainer>
  )
}

export default EpaPublicAccessibilityDoc
