import { createContext } from 'react'
import { Feature } from 'src/types/Feature'

export const FeaturesContext = createContext<FeaturesContextOptions>(
  {} as FeaturesContextOptions
)

export interface FeaturesContextOptions {
  accountHas120EnvelopeFeature: boolean
  accountHasActivitiesFeature: boolean
  accountHasAssetsFeature: boolean
  accountHasCertifiedMailFeature: boolean
  accountHasCombinedLocationServiceLineImportsFeature: boolean
  accountHasCommsAutomatedMailingFeature?: boolean
  accountHasCommsFeature?: boolean
  accountHasContactsFeature: boolean
  accountHasContactImportsFeature: boolean
  accountHasCustomEnvelopeFeature: boolean
  accountHasCustomFieldsFeature?: boolean
  accountHasLocationEnrichmentFeature: boolean
  accountHasLocationImportsFeature: boolean
  accountHasLSLProbabilityFeature: boolean
  accountHasReportsFeature: boolean
  accountHasServiceLineImportsFeature: boolean
  accountHasServiceLinesFeature: boolean
  features: Feature[]
  hasStateReporting: boolean
}
