import { Button, Loader } from '@120wateraudit/envirio-components'
import { AxiosError } from 'axios'
import React, { FC, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { post } from 'src/API'
import { Error } from 'src/components/Error'
import TextField from 'src/components/Forms/TextField'
import { ADMIN_ROLES } from 'src/constants/roles'
import { useHasRole } from 'src/hooks'
import { ModalWrapper, TwoButtonGrid } from 'src/modules/Modal/shared'
import { ViewType } from 'src/types/SavedView'
import { DEFAULT_TOAST } from 'src/utils/toast'

interface Props {
  savedViewType: ViewType
  view: string
  onClose(): void
  onComplete(viewName: string): void
}

const DEFAULT_ERROR =
  'An error occurred, please try again or contact support for assistance.'

const getErrorMessage = (error: unknown): string => {
  if (!error || typeof error !== 'object') {
    return DEFAULT_ERROR
  }

  if ('isAxiosError' in error) {
    const axiosError = error as AxiosError
    if (!axiosError.response) {
      return DEFAULT_ERROR
    }

    const errors = axiosError.response.data.errors || []
    if (!errors.length) {
      return DEFAULT_ERROR
    }

    return errors[0].detail
  }

  return DEFAULT_ERROR
}

const CreateSavedViewModal: FC<Props> = ({
  onClose,
  onComplete,
  savedViewType,
  view
}) => {
  const isAdmin = useHasRole(ADMIN_ROLES)
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const createNewView = async data => {
    const body = {
      data: {
        accountView: data.accountView,
        name: data.name,
        type: data.type,
        view: data.view
      }
    }

    try {
      toast('Submitting...', DEFAULT_TOAST)
      setLoading(true)
      await post('/pws/rest/savedViews', body)
      setLoading(false)
      toast('View saved', { ...DEFAULT_TOAST, type: 'success' })
      onComplete(data.name)
      onClose()
    } catch (error) {
      setLoading(false)
      setError(getErrorMessage(error))
      toast('Something went wrong!', { ...DEFAULT_TOAST, type: 'error' })
    }
  }

  const validate = e => {
    const errors: Record<string, string> = {}

    if (!e.name) {
      errors.name = 'Name is Required'
    } else if (e.name && e.name.length >= 200) {
      errors.name = 'Your view name is too long, please shorten.'
    }

    return errors
  }

  const initialData = {
    accountView: false,
    name: '',
    type: savedViewType,
    view
  }

  return (
    <ModalWrapper>
      <Header>{!error ? 'Save This View' : 'Oops...'}</Header>
      <Form
        initialValues={initialData}
        onSubmit={createNewView}
        render={({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <>
              <Container>
                {loading && (
                  <>
                    <Loader />
                  </>
                )}
                {!loading && (
                  <>
                    <Field<string>
                      fluid
                      component={TextField}
                      label="Saved View Name"
                      name="name"
                      placeholder="Add Name"
                      autoFocus
                    />
                    {isAdmin && (
                      <TeamViewCheckboxContainer>
                        <StyledLabel>Team View</StyledLabel>
                        <Field<boolean>
                          component="input"
                          name="accountView"
                          size="small"
                          type="checkbox"
                        />
                      </TeamViewCheckboxContainer>
                    )}
                    {error && <Error error={{ message: error }} />}
                  </>
                )}
              </Container>
              <TwoButtonGrid>
                <Button
                  type="button"
                  onClick={() => {
                    form.reset()
                    onClose()
                  }}
                  disabled={submitting}
                  variant="default">
                  Cancel
                </Button>
                <Button type="submit" disabled={submitting} variant="primary">
                  Save
                </Button>
              </TwoButtonGrid>
            </>
          </form>
        )}
        validate={validate}
      />
    </ModalWrapper>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
`

const Header = styled.h5`
  text-align: center;
  margin-bottom: 0;
`
const StyledLabel = styled.label`
  margin-right: 10px;
  color: #00242d;
  opacity: 0.5;
  font-weight: 800;
  font-size: 1rem;
`

const TeamViewCheckboxContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
`

export default CreateSavedViewModal
