import gql from 'graphql-tag'
import { CommunicationTemplate } from 'src/types/Communications'

export interface CommunicationTemplateVariables {
  accountId: number
  templateId: number
}

export interface CommunicationTemplesResponse {
  communicationTemplates: CommunicationTemplate[]
}

export const GET_TEMPLATES_QUERY = gql`
  query communicationTemplates($accountId: Int!) {
    communicationTemplates(accountId: $accountId) {
      id
      communicationType
      name
      description
      triggerCondition
    }
  }
`

export const GET_COMMUNICATIONS_QUERY = gql`
  query communications(
    $accountId: Int!
    $communicationType: CommunicationType!
    $params: CommunicationGridQueryParams
  ) {
    communications(
      accountId: $accountId
      communicationType: $communicationType
      params: $params
    ) {
      total
      totalPages
      pageSize
      pageNumber
      items {
        id
        name
        communicationType
        eventName
        templateName
        status
        createdOn
        sent
        eventId
      }
    }
  }
`

export const GET_AUDIT_LOG_QUERY = gql`
  query auditLogs(
    $accountId: Int!
    $locationId: Int
    $commInstanceOnly: Boolean
    $params: AuditLogGridQueryParams
  ) {
    auditLogs(accountId: $accountId, locationId: $locationId, commInstanceOnly: $commInstanceOnly, params: $params) {
      total
      totalPages
      pageSize
      pageNumber
      items {
        id
        entityId
        entityType
        documentId
        status
        communicationType
        name
        eventName
        createdOn
        programName
        isCertifiedMail
        trackingId
        location {
          address
          city
          stateOrProvince
          postalCode
        }
      }
    }
  }
`

export const GET_LOCATION_AUDIT_LOG_QUERY = gql`
  query locationAuditLogs(
    $accountId: Int!
    $locationId: Int
    $params: AuditLogGridQueryParams
  ) {
    locationAuditLogs(
      accountId: $accountId
      locationId: $locationId
      params: $params
    ) {
      total
      totalPages
      pageSize
      pageNumber
      items {
        id
        entityId
        entityType
        documentId
        status
        communicationType
        name
        eventName
        createdOn
        programName
        isCertifiedMail
        trackingId
        location {
          address
          city
          stateOrProvince
          postalCode
        }
        locationId
      }
    }
  }
`

export const SAMPLING_EVENTS_QUERY = gql`
  query samplingEvents($accountId: Int!, $programType: String) {
    samplingEvents(accountId: $accountId, programType: $programType) {
      id
      name
      programId
      samplingEventAnalytes {
        analyte {
          id
          name
          displayName
        }
      }
      protocolDefinitions {
        sku {
          id
          sampleQuantity
        }
      }
    }
  }
`

export const RESULTS_LETTER_BY_TEMPLATE_TYPE = gql`
  query resultLettersByTemplateType($accountId: Int!, $kitIds: [Int]!) {
    resultLettersByTemplateType(accountId: $accountId, kitIds: $kitIds) {
      nonDetect
      detect
      exceedance
      allResults
    }
  }
`
