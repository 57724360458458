import { DropdownItemProps } from 'semantic-ui-react'
import {getEnums, getEnumText, getEnumLabels} from 'src/utils/entityDefintions/entityDefinitionUtils'

export const getFittingsVerificationEnums = (value: string | null | undefined, entityDefinitions: Record<never, unknown> | undefined): DropdownItemProps[] => {
  return getEnums('pwsserviceline', '/properties/fittings/properties/verification/properties/method', value, entityDefinitions)
}

export const getSystemOwnedVerificationMethodEnums = (value: string | null | undefined, entityDefinitions: Record<never, unknown> | undefined): DropdownItemProps[] => {
  return getEnums('pwsserviceline', '/properties/systemOwned/properties/verification/properties/method', value, entityDefinitions)
}

export const getCustomerOwnedVerificationMethodEnums = (value: string | null | undefined, entityDefinitions: Record<never, unknown> | undefined): DropdownItemProps[] => {
  return getEnums('pwsserviceline', '/properties/customerOwned/properties/verification/properties/method', value, entityDefinitions)
}

export const getFittingsVerificationMethodEnums = (value: string | null | undefined, entityDefinitions: Record<never, unknown> | undefined): DropdownItemProps[] => {
  return getEnums('pwsserviceline', '/properties/fittings/properties/verification/properties/method', value, entityDefinitions)
}

export const getSystemOwnedClassificationBasisEnums = (value: string | null | undefined, entityDefinitions: Record<never, unknown> | undefined): DropdownItemProps[] => {
  return getEnums('pwsserviceline', '/properties/systemOwned/properties/classification/properties/basis', value, entityDefinitions)
}

export const getCustomerOwnedClassificationBasisEnums = (value: string | null | undefined, entityDefinitions: Record<never, unknown> | undefined): DropdownItemProps[] => {
  return getEnums('pwsserviceline', '/properties/customerOwned/properties/classification/properties/basis', value, entityDefinitions)
}

export const getClassificationBasisEnumText = (value: string | null | undefined, entityDefinitions: Record<never, unknown> | undefined): string => {
  return getEnumText('pwsserviceline', '/properties/systemOwned/properties/classification/properties/basis', value, entityDefinitions)
}

export const getVerificationMethodEnumText = (value: string | null | undefined, entityDefinitions: Record<never, unknown> | undefined): string => {
  return getEnumText('pwsserviceline', '/properties/systemOwned/properties/verification/properties/method', value, entityDefinitions)
}

export const getVerificationMethodEnumLabels = (entityDefinitions: Record<never, unknown> | undefined): Record<never, unknown> | undefined => {
  return getEnumLabels('pwsserviceline', '/properties/systemOwned/properties/verification/properties/method', entityDefinitions);
}

export const getFittingsVerificationEnumLabels = (entityDefinitions: Record<never, unknown> | undefined): Record<never, unknown> | undefined => {
  return getEnumLabels('pwsserviceline', '/properties/fittings/properties/verification/properties/method', entityDefinitions)
}