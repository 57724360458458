import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { useLocation } from 'react-router-dom'

import AppSkeleton from './Skeleton'

type LocationState = {
  referrer?: string
}

const Login = (): JSX.Element => {
  const { state } = useLocation<LocationState>()
  const { loginWithRedirect } = useAuth0()
  loginWithRedirect({ appState: { returnTo: state?.referrer || '/' } })

  return <AppSkeleton />
}

export default Login
