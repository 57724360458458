import createDetailsReducer from './helpers/DetailsReducers'
import actions from '../actions/programs'

const reducer = createDetailsReducer({
  entityName: 'program',
  fetchAction: actions.detailsActions.fetchRequest,
  fetchSuccessAction: actions.detailsActions.fetchSuccess,
  fetchFailureAction: actions.detailsActions.fetchFailure,
  unloadAction: actions.detailsActions.unload,
  deleteAction: actions.deleteActions.deleteRequest,
  deleteSuccessAction: actions.deleteActions.deleteSuccess,
  deleteFailureAction: actions.deleteActions.deleteFailure,
  updateActionRequest: actions.updateActions.updateRequest,
  updateActionSuccess: actions.updateActions.updateSuccess,
  updateActionFailure: actions.updateActions.updateFailure,
  createActionRequest: actions.createActions.createRequest,
  createActionSuccess: actions.createActions.createSuccess,
  createActionFailure: actions.createActions.createFailure,
  errorClearAction: actions.errorActions.clear
})

export default reducer
