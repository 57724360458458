import { useMemo } from 'react'

import { useData } from 'src/hooks'
import { SavedView } from 'src/types/SavedView'

export const useTableViews = (type?: string) => {
  const {
    data: views,
    loading,
    refetch
  } = useData<SavedView>(`/pws/rest/savedViews/${type}`, undefined, {
    isPaginated: true
  })
  const { myViews, teamViews, platformViews } = useMemo(() => {
    const myViews: SavedView[] = []
    const teamViews: SavedView[] = []
    const platformViews: SavedView[] = []

    views.forEach(view => {
      if (view.accountView) {
        teamViews.push(view)
      }
      if (view.isPlatformView) {
        platformViews.push(view)
      } else {
        myViews.push(view)
      }
    })
    return { myViews, teamViews, platformViews }
  }, [views])
  return { loading, myViews, refetch, teamViews, platformViews, views }
}
