import React, { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { Dropdown, DropdownItemProps } from 'semantic-ui-react'
import styled from 'styled-components'

const PAGE_SIZES = [
  { text: 10, value: 10 },
  { text: 20, value: 20 },
  { text: 50, value: 50 },
  { text: 100, value: 100 }
]

interface Props {
  pageSize: number
  onPageSizeChanged?(newSize: number): void
}

const PageSizeSelection = ({
  onPageSizeChanged,
  pageSize
}: Props): JSX.Element | null => {
  const changeSize = useCallback(
    (_: unknown, { value }: DropdownItemProps) => {
      if (!onPageSizeChanged || !value) return
      const numberValue = Number.isInteger(value)
        ? (value as number)
        : PAGE_SIZES[0].value
      ReactGA.event({
        action: 'Change Page Size',
        category: 'Table',
        value: numberValue
      })
      onPageSizeChanged(numberValue)
    },
    [onPageSizeChanged]
  )
  if (!onPageSizeChanged) {
    return null
  }

  return (
    <Wrapper>
      <PageSelection
        onChange={changeSize}
        options={PAGE_SIZES}
        value={pageSize}
      />
      <p>per page</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-left: auto;
  color: #6b7280;
  display: flex;
  flex-direction: row;
  align-items: center;
  > p {
    font-size: 14px;
    lineheight: 1.42857143;
    margin: 0 7px;
  }
`

const PageSelection = styled(Dropdown)`
  & > div {
    font-size: 14px;
    lineheight: 1.42857143;
  }
`

export default PageSizeSelection
