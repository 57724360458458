import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Progress, Button } from 'semantic-ui-react'
import moment from 'moment'

import { GeneralContainer } from './shared'
import Row from 'src/components/Row'

interface Props {
  onClose(): void
  latestSubmissionPeriodDueDate: string
}

const CustomerNotification = ({
  onClose,
  latestSubmissionPeriodDueDate
}: Props): JSX.Element => {
  const dueDate = moment(latestSubmissionPeriodDueDate).format('MMMM Do, YYYY')
  return (
    <GeneralContainer>
      <Progress style={{ width: '100%' }} percent={100} success />
      <StyledHeader>
        Your submission was successful! 🥳 Per LCRR requirements you have 30
        days from {dueDate} to notify all residents at locations served by an
        Unknown, Known Lead, or GRR service classification.
      </StyledHeader>

      <StyledHeader>
        <StyledLink
          to="/inventory/list?filters.epaReportingCategory=Lead%2CLead+Status+Unknown%2CGalvanized+Requiring+Replacement"
          onClick={onClose}>
          Click Here
        </StyledLink>
        to go to that list of inventory and complete your notifications.
      </StyledHeader>
      <Row style={{ justifyContent: 'center' }}>
        <Button onClick={onClose} type="button" primary>
          Close
        </Button>
      </Row>
    </GeneralContainer>
  )
}

const StyledHeader = styled.h4`
  font-weight: 500;
  font-size: 1.95rem;
`

const StyledLink = styled(Link)`
  cursor: pointer;
  color: black;
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
  margin-right: 5px;
`

export default CustomerNotification
