import gql from 'graphql-tag'

export interface CommunicationBatchesData {
  batchId: number
}

export interface CommunicationBatchesVariables {
  accountId: number
}

export const COMMUNICATION_BATCHES_READY = gql`
  query communicationBatchReady($accountId: Int!) {
    communicationBatchReady(accountId: $accountId) {
      id
    }
  }
`

export const GET_BATCH_INFO_FOR_DOWNLOAD = gql`
  query communicationBatchDownloadInfo($accountId: Int!, $batchId: Int!) {
    communicationBatchDownloadInfo(accountId: $accountId, batchId: $batchId) {
      documentId
    }
  }
`
