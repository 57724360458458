import pickBy from 'lodash/pickBy'
import uniqWith from 'lodash/unionWith'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'

import { ModalContext } from 'src/modules/Modal'
import { ViewType } from 'src/types/SavedView'
import TableContext from '../context'
import { ViewPayload } from '../types'
import { FilterOption } from '../utils'

interface Props {
  savedViewType?: ViewType
  refetch(): void
  toggle(): void
  disabled: boolean
}

const CreateViewButton = ({
  refetch,
  savedViewType,
  toggle,
  disabled
}: Props) => {
  const { openModal } = useContext(ModalContext)
  const {
    activeFilters = {},
    columns = [],
    hiddenColumns = [],
    setView,
    sort = {}
  } = useContext(TableContext)

  const columnKeys = columns.map(c => c.key)
  const viewToSave = JSON.stringify({
    activeFilters: pickBy(activeFilters, v => v !== undefined),
    columns: columnKeys,
    hiddenColumns: uniqWith(hiddenColumns, isEqual),
    sort
  })

  const onComplete = useCallback(
    (viewName: string) => {
      refetch()
      if (setView) {
        setView({
          activeFilters,
          activeViewName: viewName,
          columns: columnKeys,
          hiddenColumns,
          sort
        } as ViewPayload)
      }
    },
    [activeFilters, columnKeys, hiddenColumns, refetch, setView, sort]
  )
  const openSaveModal = useCallback(() => {
    toggle()
    openModal('createSavedView', {
      onComplete,
      savedViewType,
      view: viewToSave
    })
  }, [openModal, onComplete, savedViewType, toggle, viewToSave])

  if (!savedViewType) {
    return null
  }

  return (
    <Option disabled={disabled} key="Create View" onClick={openSaveModal}>
      <p>Save View</p>
    </Option>
  )
}

const Option = styled(FilterOption)`
  color: var(--aqua-blue);
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px !important;
  &:hover {
    background-color: rgb(194, 207, 224, 0.2);
  }
`

export default CreateViewButton
