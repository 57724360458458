import { ApplicationState } from '../reducers'

type ErrorSelector = (state: ApplicationState) => any

function buildErrorSelector(prop: keyof ApplicationState): ErrorSelector {
  return state => {
    const subject: any = state[prop]
    const error = subject.errors && subject.errors[0]

    return error || null
  }
}

export const getActivitiesError = buildErrorSelector('activities')
export const getLeadServiceLinesError = buildErrorSelector('leadServiceLines')
export const getLocationsError = buildErrorSelector('locations')
export const getProgramError = buildErrorSelector('program')
