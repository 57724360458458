import React, { memo } from 'react'

import ImportWizard from 'src/components/BulkImports/ImportWizard'
import { BatchType, BulkImportType } from 'src/modules/FileProcessor/dataAccess'
import { ModalWrapper, Wrapper } from 'src/modules/Modal/shared'

interface Props {
  batchType?: BatchType
  onClose: () => void
  selectedType: BulkImportType
}

const BulkImportModal = ({
  batchType = BatchType.Import,
  onClose,
  selectedType
}: Props) => {
  return (
    <ModalWrapper>
      <Wrapper>
        <ImportWizard
          batchType={batchType}
          onClose={onClose}
          selectedType={selectedType}
        />
      </Wrapper>
    </ModalWrapper>
  )
}

export default memo(BulkImportModal)
