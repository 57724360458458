import { PWSCustomFieldEntity } from '@120wateraudit/envirio-components/dist/models'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import gql from 'graphql-tag'

import { AWS_BASE } from 'src/API'
import { createClient } from 'src/apollo-clients'
import { CustomField } from 'src/modules/CustomFields/dataAccess'
import graphQLBaseQuery from './graphQLBaseQuery'

export const customFieldsClient = createClient({
  uri: `${AWS_BASE}/platform/custom-fields/graphql`
})

interface CustomFieldsEntityQueryVariables {
  entityId: number
  entityName: PWSCustomFieldEntity
}

interface CustomFieldsQueryArgs {
  accountId: number
  criteria: {
    entities: CustomFieldsEntityQueryVariables[]
  }
}

interface CustomFieldUpdateArgs {
  accountId: number | string
  data: {
    updates: {
      customFieldDefinitionId: number
      entityId: number
      entityName: string
      value: any
    }[]
  }
}

export const customFieldsApi = createApi({
  baseQuery: graphQLBaseQuery({ client: customFieldsClient }),
  reducerPath: 'customFields',
  tagTypes: [],
  endpoints: builder => ({
    getCustomFields: builder.query<CustomField[], CustomFieldsQueryArgs>({
      query: variables => ({
        query: GET_CUSTOM_FIELDS,
        variables
      }),
      transformResponse: response => response?.customFields ?? []
    }),
    updateCustomFields: builder.mutation<unknown, CustomFieldUpdateArgs>({
      query: variables => ({
        mutation: UPDATE_CUSTOM_FIELDS_MUTATION,
        variables
      })
    })
  })
})

export const { useGetCustomFieldsQuery, useUpdateCustomFieldsMutation } =
  customFieldsApi

export const GET_CUSTOM_FIELDS = gql`
  query customFields(
    $accountId: Float!
    $criteria: CustomFieldCriteriaInputType!
  ) {
    customFields(accountId: $accountId, criteria: $criteria) {
      entityId
      entityName
      fieldName
      dataType
      pickListValues
      isPickListMulti
      value
      customFieldDefinitionId
    }
  }
`

export const UPDATE_CUSTOM_FIELDS_MUTATION = gql`
  mutation updateCustomFields(
    $accountId: Float!
    $data: CustomFieldUpdatesInputType!
  ) {
    updateCustomFields(accountId: $accountId, data: $data) {
      entityId
      entityName
      fieldName
      dataType
      value
      customFieldDefinitionId
    }
  }
`

export default customFieldsApi
