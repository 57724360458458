import { createApi } from '@reduxjs/toolkit/dist/query/react'

import axiosBaseQuery from './axiosBaseQuery'
import { Tags, SearchParams } from './types'
import { ActiveFilters } from 'src/components/Table/types'
import { buildParameters } from 'src/services'

interface GetAllExportsParams {
  searchParams: SearchParams
}

interface CommsInstanceExportParams {
  activeFilters: ActiveFilters
  searchTerm?: string
}

export enum ExportStatus {
  Failed = 'Failed',
  GatheringData = 'GatheringData',
  CreatingFile = 'CreatingFile',
  Completed = 'Completed',
  Canceled = 'Cancelled'
}

export enum ExportType {
  OneSchema = 'oneschema',
  CommunicationsInstance = 'communications-instance',
  submission = 'submission'
}

export interface Export extends Record<string, unknown> {
  id: number
  accountId: number
  legacyId: number
  filters: Record<string, unknown>
  status: ExportStatus
  errors: string[]
  type: ExportType
  createdBy: string
  createdOn: Date
  createdByUsername: string
  modifiedBy: string
  modifiedOn: Date
  fileName: string
}

export const exportServiceApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: `/platform/exports`,
    skipAccount: true
  }),
  reducerPath: 'exportServiceApi',
  tagTypes: [Tags.CommunicationInstancesExports, Tags.Exports],
  endpoints: builder => ({
    getAllExports: builder.query<Export[], GetAllExportsParams>({
      providesTags: [Tags.Exports],
      query: ({ searchParams }) => {
        const params = buildParameters(searchParams, true).toString()

        return {
          method: 'GET',
          url: `/?${params}`
        }
      }
    }),
    createCommunicationInstanceExport: builder.mutation<
      Export,
      CommsInstanceExportParams
    >({
      invalidatesTags: [Tags.CommunicationInstancesExports],
      query: ({ activeFilters, searchTerm }) => {
        const {
          'instances.eventId': eventIds,
          'instances.programId': programIds,
          'instances.createdOn': newCreatedOn,
          'instances.status': status,
          communicationId: letterName,
          communicationType,
          locationId
        } = activeFilters

        const createdOn = newCreatedOn
          ? JSON.parse(newCreatedOn as string)
          : newCreatedOn

        const updatedFilters = {
          communicationType,
          createdOn,
          eventIds,
          programIds,
          letterName,
          locationId,
          status
        }

        return {
          data: { ...updatedFilters, search: searchTerm },
          method: 'POST',
          url: `/communications/instance`
        }
      }
    }),

    createInventoryExport: builder.mutation<Export, any>({
      invalidatesTags: [Tags.Exports],
      query: ({ params, exportType }) => {
        return {
          data: params,
          method: 'POST',
          url: `/inventory/${exportType}`
        }
      }
    }),
    createKitsExport: builder.mutation<Export, any>({
      invalidatesTags: [Tags.Exports],
      query: ({ params, exportType }) => {
        return {
          data: params,
          method: 'POST',
          url: `/sampling-events/${exportType}`
        }
      }
    })
  })
})

export const {
  useGetAllExportsQuery,
  useCreateCommunicationInstanceExportMutation,
  useCreateInventoryExportMutation,
  useCreateKitsExportMutation
} = exportServiceApi
