export const ACCEPTED_IMAGE_TYPES = ['image/png', 'image/jpg', 'image/jpeg']

export const ALL_ACCEPTED_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/xls',
  'application/excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/zip',
  'text/csv',
  'text/plain'
].join(',')

export const getFriendlyFileTypeList = (types: string[]) => {
  return types.map(type => type.split('/').pop()?.toUpperCase()).join(', ')
}
