import React from 'react'

import { Step } from 'src/components/Forms/Wizard'
import ArizonaMethodsPart1 from './InventoryMethods/ArizonaMethodsPart1'
import EpaMethodsPart2 from '../FederalWizard/InventoryMethods/EpaMethodsPart2'
import ArizonaMethodsPart3 from './InventoryMethods/ArizonaMethodsPart3'
import Signature from './Signature'
import {
  validateHistoricalRecordsReviewSchema,
  validateIdentifyingServiceLineMaterialDuringNormalOperationsSchema,
  validateServiceLineInvestigationsSchema
} from './Validation'

export const validateHistoricalStep = async (values: any) => {
  const historicalStepErrors = await validateHistoricalRecordsReviewSchema(
    values
  )
  if (historicalStepErrors) {
    return false
  }

  return true
}

export const validatevalidateIdentifyingServiceLineMaterialDuringNormalOperationsStep = async (values: any) => {
  const validateIdentifyingServiceLineMaterialDuringNormalOperationsErrors =
    await validateIdentifyingServiceLineMaterialDuringNormalOperationsSchema(values)
  if (validateIdentifyingServiceLineMaterialDuringNormalOperationsErrors) {
    return false
  }

  return true
}

export const validateServiceLineInvestigationStep = async (values: any) => {
  const serviceLineInvestigationStepErrors =
    await validateServiceLineInvestigationsSchema(values)
  if (serviceLineInvestigationStepErrors) {
    return false
  }

  return true
}

export const ARIZONA_STEPS: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Historical Records Review',
    render: () => <ArizonaMethodsPart1 />,
    validateStepValues: validateHistoricalStep
  },
  {
    nextButtonText: 'Next Step',
    title: 'Identifying Service Line Material During Normal Operations',
    render: () => <EpaMethodsPart2 questionTwoConditionallyRequired />,
    validateStepValues:
      validatevalidateIdentifyingServiceLineMaterialDuringNormalOperationsStep
  },
  {
    nextButtonText: 'Next Step',
    title: 'Service Line Investigations',
    render: () => <ArizonaMethodsPart3 />,
    validateStepValues: validateServiceLineInvestigationStep
  }
]

export const ARIZONA_SIGN_STEP: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Certificate of Service Line Inventory',
    render: () => <Signature />
  }
]
