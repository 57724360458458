import * as React from 'react'
import styled from 'styled-components'
import {
  colors,
  spacing,
  fontSize,
  Icons
} from '@120wateraudit/envirio-components'
import { OptionCard } from 'src/components/OptionCard'
import { SendOption } from './index'
import { useFeatures } from 'src/hooks'
import { RadioComponent } from 'src/components/Radio'

interface Props {
  numberOfLetters: number
  sendOption?: SendOption
  onSendOptionChanged: (sendOption: SendOption) => void
  hasAutomatedLetterMailingFeature: boolean
  isCertifiedMail: boolean
  toggleIsCertifiedMail: () => void
}

export const SendOptions = ({
  numberOfLetters,
  sendOption,
  onSendOptionChanged,
  hasAutomatedLetterMailingFeature,
  isCertifiedMail,
  toggleIsCertifiedMail
}: Props) => {
  const { accountHasCertifiedMailFeature } = useFeatures()

  const options: any[] = [
    {
      icon: <Icons.Download />,
      label: 'Download as PDF',
      text: 'Download results letters as a PDF to print and manually send.',
      relatedSendOption: SendOption.PDF
    }
  ]

  if (hasAutomatedLetterMailingFeature) {
    options.push({
      icon: <Icons.Email />,
      label: 'Send via USPS',
      text: 'Send results letters via USPS through the 120Water platform.',
      relatedSendOption: SendOption.USPS
    })
  }

  return (
    <Wrapper>
      <CountLabel>
        You have selected <b>{numberOfLetters} Results Letters.</b>
      </CountLabel>
      <div>
        {options.map((option, i) => {
          const { icon, label, text, relatedSendOption } = option
          return (
            <OptionCard
              key={`OptionCard${i}`}
              icon={icon}
              label={label}
              text={text}
              isSelected={sendOption === relatedSendOption}
              onClick={() => onSendOptionChanged(relatedSendOption)}
            />
          )
        })}
      </div>
      {sendOption === SendOption.USPS && accountHasCertifiedMailFeature && (
        <>
          <RadioComponent
            checked={isCertifiedMail}
            label={'Send Letter Certified Mail'}
            onChange={toggleIsCertifiedMail}
            size={'small'}
            subLabel={
              'Please note that sending Certified Mail will result in an additional charge per letter sent.'
            }
            subLabelColor={`${colors.error}`}
          />
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const CountLabel = styled.div`
  color: ${colors.black50};
  padding-bottom: ${spacing.tiny};
  font-size: ${fontSize.body};
`
