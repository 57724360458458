import { spacing } from '@120wateraudit/envirio-components'
import React from 'react'
import styled from 'styled-components'

import { CenteringWrapper } from 'src/components/Layout'

export const StepHeader = ({
  Icon,
  imageStyle = {},
  text
}: {
  text: string
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  imageStyle?: React.CSSProperties
}) => (
  <>
    <CenteringWrapper>
      <Icon style={imageStyle} />
    </CenteringWrapper>
    <ModalHeader>{text}</ModalHeader>
  </>
)

const ModalHeader = styled.h5`
  text-align: center;
  padding-bottom: ${spacing.tiny};
  margin-bottom: 0;
`
