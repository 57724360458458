import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import {
  BasicContainer,
  ParagraphInputGrid,
  QuestionTitle,
  NoRedHighlightTextField
} from '../../shared'

const ArizonaMethodsPart1 = (): JSX.Element => {
  return (
    <>
      <Message>At least one field must be filled in.</Message>
      <BasicContainer>
        <ParagraphInputGrid>
          <p>Type of Record</p>
          <p>Describe all types of Records Reviewed for Your Inventory</p>
          <QuestionTitle>1. Previous Materials Evaluation</QuestionTitle>
          <Field<string>
            component={NoRedHighlightTextField}
            hideError
            name="inventoryMethodsPart1PreviousMaterialsEvaluation"
            placeholder="Example: Locations of Tier 1 lead tap sampling locations that are
            served by a lead service line."
            textarea
          />
          <QuestionTitle>
            2. Construction Records and Plumbing Codes
          </QuestionTitle>
          <Field<string>
            component={NoRedHighlightTextField}
            hideError
            name="inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes"
            placeholder="Examples: Local ordinance adopting an international plumbing code.
            Permits for replacing lead service lines."
            textarea
          />
          <QuestionTitle>3. Water System Records</QuestionTitle>
          <Field<string>
            component={NoRedHighlightTextField}
            hideError
            name="inventoryMethodsPart1WaterSystemRecords"
            placeholder="Examples: Capital improvement plans. Standard operating procedures.
            Engineering standards."
            textarea
          />
          <QuestionTitle>
            4. Distribution System Inspections and Records
          </QuestionTitle>
          <Field<string>
            component={NoRedHighlightTextField}
            hideError
            name="inventoryMethodsPart1DistributionSystemInspectionsAndRecords"
            placeholder="Examples: Distribution system maps. Tap cards. Service line
            repair/replacement records. Inspection records. Meter installation
            records."
            textarea
          />
          <QuestionTitle>5. Other Records</QuestionTitle>
          <Field<string>
            component={NoRedHighlightTextField}
            hideError
            name="inventoryMethodsPart1otherRecords"
            textarea
          />
        </ParagraphInputGrid>
      </BasicContainer>
    </>
  )
}

const Message = styled.p`
  text-decoration: underline;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
`

export default ArizonaMethodsPart1
