import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from 'src/services/axiosBaseQuery'
import { Tags } from './types'

type GeoserverFeatureResponse = {
  features: GeoserverFeature[]
}

type GeoserverFeature = {
  properties: InventoryProperties
}

export type InventoryProperties = {
  asset_id: string
  location_address_line_1: string
  location_id: string
  location_name: string
  private_material_epa_category: string
  private_verified_date: string
  public_material_epa_category: string
  public_verified_date: string
}

type Params = {
  query: string
}

export const geoserverApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '/platform/map', skipAccount: true }),
  reducerPath: 'geoserver',
  tagTypes: [Tags.GeoserverInventory],
  endpoints: builder => ({
    getInventoryProperties: builder.query<InventoryProperties, Params>({
      providesTags: [Tags.GeoserverInventory],
      query: arg => ({
        url: `/wms${arg.query}`
      }),
      transformResponse: (response: GeoserverFeatureResponse) => {
        return response?.features[0]?.properties
      }
    })
  })
})

export const { useGetInventoryPropertiesQuery } = geoserverApi
