import { isEmptyObject } from './isEmptyObject'

export const buildQueryString = (queryMap: Record<string, unknown>): string => {
  if (!queryMap || isEmptyObject(queryMap)) {
    return ''
  }

  const queryString = new URLSearchParams()

  for (const key in queryMap) {
    if (queryMap.hasOwnProperty(key) && queryMap[key] === undefined) {
      continue
    }
    if (
      !(typeof queryMap[key] === 'object') &&
      !(typeof queryMap[key] === 'function')
    ) {
      queryString.set(key, `${queryMap[key]}`)
    }
  }

  const queryURL = '?' + queryString.toString()

  return queryURL
}
