import { TriggerType } from '@120wateraudit/communications-types'
import {
  Dropdown,
  Loader,
  TextField,
  colors
} from '@120wateraudit/envirio-components'
import { SamplingEvent } from '@120wateraudit/envirio-components/dist/models'
import { faCopy, faWarning } from '@fortawesome/free-solid-svg-icons'
import React, { FC, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Flags, useConfig, usePrograms } from 'src/hooks'
import { useAccount } from 'src/router/UserProvider'
import { buildCommunicationDropdownOptions } from 'src/utils/buildCommunicationDropdownOptions'
import { pushRoute } from 'src/utils/navigation'
import Modal from '../ModalContents'
import { ErrorMessage, ModalButton } from '../shared'
import {CommunicationV2} from "src/types/CommunicationsV2";
import {
  useCreateCommunicationMutation,
  CommunicationFormData,
  useGetAllCommunicationTemplatesQuery
} from "src/services";

interface Props {
  communication?: Partial<CommunicationV2>
  content: string
  samplingEventId: undefined | number
  samplingEvents: SamplingEvent[]
  onClose: () => void
}

const CopyCommunicationModalV2: FC<Props> = ({
  communication,
  content,
  onClose,
  samplingEventId,
  samplingEvents
}) => {
  const { id: accountId } = useAccount()
  const { loading: loadingPrograms, programs } = usePrograms()
  const [selectedProgramId, setSelectedProgramId] = useState<
    undefined | number
  >(undefined)
  const [selectedCommunicationTemplate, setSelectedCommunicationTemplate] =
    useState(communication?.templateId)
  const [eventId, setEventId] = useState<undefined | number>(undefined)
  const [communicationName, setCommunicationName] = useState('')
  const [isSameProgramType, setIsSameProgramType] = useState(true)
  const [isSameTemplate, setIsSameTemplate] = useState(true)
  const showAllResultsTemplate = useConfig(Flags.ShowAllResultsLetter)

  const [createCommunication, { isLoading: isSaving, error: createOrUpdateError }] =
      useCreateCommunicationMutation()

  const { data: templateData, isLoading: loadingTemplates } =
      useGetAllCommunicationTemplatesQuery()

  const isButtonDisabled = communicationName.length < 1

  const selectedTemplate = templateData?.data.find(
      t => t.id === selectedCommunicationTemplate
  )

  const createCommunicationInput = (): {
    communication: CommunicationFormData
  } => {
    const selectedTemplate = templateData?.data.find(
      t => t.id === selectedCommunicationTemplate
    )

    return {
      communication: {
        communicationType: communication?.communicationType,
        content: JSON.parse(content) as Record<string, unknown>,
        eventId,
        name: communicationName,
        status: communication?.status,
        templateId: selectedTemplate?.id,
        triggerCondition: selectedTemplate?.triggerCondition,
        triggerType: selectedTemplate?.triggerType
      } as CommunicationFormData
    }
  }

  const checkSave = () => {
    const originalProgramTypeId = programs.find(
      p =>
        p.id === samplingEvents.find(se => se.id === samplingEventId)?.programId
    )?.programTypeId

    const selectedProgramToCopyProgramTypeId = programs.find(
      p => p.id === selectedProgramId
    )?.programTypeId

    const isSameTemplateId =
      communication?.templateId === selectedCommunicationTemplate

    const isSameProgramTypeId =
      originalProgramTypeId === selectedProgramToCopyProgramTypeId || originalProgramTypeId === null

    if (!isSameTemplateId) {
      setIsSameTemplate(false)
    }

    if (!isSameProgramTypeId) {
      setIsSameProgramType(false)
    }

    if (isSameProgramTypeId && isSameTemplateId) {
      handleSave()
    }
  }

  const handleSave = useCallback(async () => {
    //Setting these to true so the modal displays the correct error
    setIsSameProgramType(true)
    setIsSameTemplate(true)
    await createCommunication(createCommunicationInput())

    if (!createOrUpdateError) {
      pushRoute('/communications/letters')
      onClose()
    }
  }, [
    useCreateCommunicationMutation,
    accountId,
    content,
    createCommunicationInput,
    createOrUpdateError,
    onClose
  ])

  const samplingEventOptions = useMemo(
    () =>
      buildCommunicationDropdownOptions(
        samplingEvents.filter(se => se.programId === selectedProgramId)
      ),
    [samplingEvents, selectedProgramId]
  )

  const programOptions = useMemo(
    () => buildCommunicationDropdownOptions(programs),
    [programs]
  )

  const templateOptions = useMemo(() => {
    if (templateData) {
      const isAdhocLocation =
        templateData.data.filter(
          ct => ct.id === communication?.templateId
        )?.[0].triggerType === TriggerType.AdHocLocation

      if (isAdhocLocation) {
        return buildCommunicationDropdownOptions(
          templateData.data
        )
      }
      let templates = templateData.data.filter(
        ct => ct.triggerType === TriggerType.SendKitResult
      )
      if (!showAllResultsTemplate) {
        templates = templates.filter(ct => ct.triggerCondition !== 'allResults')
      }
      return buildCommunicationDropdownOptions(templates)
    } else {
      return {
        key: '',
        text: '',
        value: ''
      }
    }
  }, [templateData, showAllResultsTemplate])

  if (isSaving || loadingPrograms || loadingTemplates) {
    return <Loader />
  }

  if (!isSameProgramType || !isSameTemplate) {
    return (
      <Modal
        cancelAction={
          <CopyLetterModalButton fontSize={'1.15rem'} onClick={onClose}>
            Cancel
          </CopyLetterModalButton>
        }
        icon={faWarning}
        iconOverrideColor={colors.warning}
        primaryAction={
          <CopyLetterModalButton
            fontSize={'1.15rem'}
            variant="primary"
            onClick={handleSave}>
            Copy Letter Template
          </CopyLetterModalButton>
        }
        title="Warning">
        <>
          {!isSameProgramType && (
            <ErrorMessage>
              Warning: You are copying a letter template to a different program
              type.
            </ErrorMessage>
          )}
          {!isSameTemplate && (
            <ErrorMessage>
              Warning: You are copying a letter template to a different template
              type.
            </ErrorMessage>
          )}
          <ErrorMessage>
            Make sure you update the text of the letter template to reflect
            these changes.
          </ErrorMessage>
        </>
      </Modal>
    )
  }

  return (
    <Modal
      cancelAction={
        <CopyLetterModalButton fontSize={'1.15rem'} onClick={onClose}>
          Cancel
        </CopyLetterModalButton>
      }
      icon={faCopy}
      primaryAction={
        <CopyLetterModalButton
          fontSize={'1.15rem'}
          disabled={isButtonDisabled || isSaving || createOrUpdateError}
          variant="primary"
          onClick={checkSave}>
          Copy Letter Template
        </CopyLetterModalButton>
      }
      title="Copy Letter Template">
      {!createOrUpdateError && (
        <InputFieldsWrapper>
          <TextField
            label="Communication Name"
            onChange={e => setCommunicationName(e.target.value)}
            placeholder="Add Communication Name"
            required
            style={{ width: '100%', marginBottom: '1rem' }}
            value={communicationName}
          />
          <ModalDropdown
            fluid
            label="Template"
            onChange={(_, { value }) => setSelectedCommunicationTemplate(value)}
            optionTextMaxLength={45}
            options={templateOptions}
            placeholder="Select a Template"
            search
            value={selectedCommunicationTemplate}
          />
          { selectedTemplate?.triggerType === TriggerType.SendKitResult &&
            <ModalDropdown
              fluid
              label="Program"
              onChange={(_, { value }) => setSelectedProgramId(value)}
              optionTextMaxLength={45}
              options={programOptions}
              placeholder="Select a Program"
              search
              value={selectedProgramId}
            /> }
            {selectedTemplate?.triggerType === TriggerType.SendKitResult && selectedProgramId && (
              <ModalDropdown
                disabled={samplingEventOptions.length < 1}
                fluid
                label="Related Event"
                onChange={(_, { value }) => setEventId(value)}
                optionTextMaxLength={45}
                options={samplingEventOptions}
                placeholder={
                  samplingEventOptions.length < 1
                    ? 'No Sampling Events found for this program'
                    : 'Select a Sampling Event'
                }
                search
                value={eventId}
              />
            )}
        </InputFieldsWrapper>
      )}
      {createOrUpdateError && (
        <ErrorMessage>
          {(createOrUpdateError as Error).message}
        </ErrorMessage>
      )}
    </Modal>
  )
}

export default CopyCommunicationModalV2

const InputFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ModalDropdown = styled(Dropdown)`
  margin-bottom: 1rem;
`

const CopyLetterModalButton = styled(ModalButton)`
  width: 181px;
`
