import { FormApi } from 'final-form'
import { useCallback, useContext, useEffect, useMemo } from 'react'

import {
  Contact,
  useGetPrimaryContactQuery
} from 'src/modules/Contacts/dataAccess'
import { ModalContext } from 'src/modules/Modal'
import { Location } from 'src/types/Location'
import { handleClearedFields } from 'src/utils/form'
import { toastError, toastSuccess } from 'src/utils/toast'
import { Values, useUpdateLocationMutation } from '../../dataAccess'

export const useInitialValues = (
  location: Location,
  contact?: Contact
): Values => {
  // TODO handle loading state in form when fetching primary contact
  const { primaryContact } = useGetPrimaryContactQuery(location.id, !!contact)
  const initialContact = contact || primaryContact

  return useMemo(() => {
    const { profile } = location
    const values: Values = {
      addressLine1: location.addressLine1,
      addressLine2: location.addressLine2,
      city: location.city,
      disadvantagedNeighborhood: location.disadvantagedNeighborhood,
      externalId: location.externalId,
      name: location.name,
      id: location.id,
      mailingAddressLine1: location.mailingAddressLine1,
      mailingAddressLine2: location.mailingAddressLine2,
      mailingCity: location.mailingCity,
      mailingState: location.mailingState,
      mailingZip: location.mailingZip,
      profile: {
        parcelNumber: profile?.parcelNumber,
        propertyClassification: profile?.propertyClassification ?? null,
        tier: profile?.tier ? Number(profile?.tier) : null
      },
      state: location.state,
      zip: location.zip
    }

    if (initialContact) {
      values.contact = {
        accountId: initialContact.accountId,
        addressLine1: initialContact.addressLine1,
        addressLine2: initialContact.addressLine2,
        alternativePhone: initialContact.alternativePhone,
        alternativePhoneExt: initialContact.alternativePhoneExt,
        city: initialContact.city,
        contactMethod: initialContact.contactMethod,
        email: initialContact.email,
        firstName: initialContact.firstName,
        id: initialContact.id,
        isAlternativePhoneMobile: initialContact.isAlternativePhoneMobile,
        isPhoneMobile: initialContact.isPhoneMobile,
        lastName: initialContact.lastName,
        primaryLanguageId: initialContact.primaryLanguageId,
        primaryPhone: initialContact.primaryPhone,
        primaryPhoneExt: initialContact.primaryPhoneExt,
        salutation: initialContact.salutation,
        state: initialContact.stateOrProvince,
        zip: initialContact.postalCode
      }
    }

    return values
  }, [location, initialContact])
}

interface ApiError {
  data: {
    errors?: { constraints: Record<string, string> }[]
  }
}

const isApiError = (error: unknown): error is ApiError => {
  return (error as ApiError).data !== undefined
}

interface GraphQLError {
  graphQLErrors: {
    validationErrors: { constraints: Record<string, string> }[]
  }[]
}

const isGraphQLError = (error: unknown): error is GraphQLError => {
  return Array.isArray((error as GraphQLError).graphQLErrors)
}

export const useUpdateLocation = () => {
  const { openModal } = useContext(ModalContext)
  const [
    trigger,
    { data, error, isError, isLoading, isSuccess, isUninitialized }
  ] = useUpdateLocationMutation()
  useEffect(() => {
    if (!isUninitialized && !isLoading && isSuccess) {
      openModal('viewLocation', { location: data }, 'drawer')
      toastSuccess('Location has been updated')
    }
  }, [data, isError, isLoading, isSuccess, isUninitialized, openModal])

  const onSubmit = useCallback(
    async (values: Values, form: FormApi) => {
      const { initialValues } = form.getState()
      const sanitizedValues = handleClearedFields(initialValues, values)
      try {
        await trigger(sanitizedValues).unwrap()
      } catch {
        // TODO scroll to top of container to show errors OR list errors in Toast
        toastError('An error occurred. Location could not be updated')
      }
    },
    [trigger]
  )

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined
    }

    if (isApiError(error)) {
      const errors = error.data?.errors || []
      return errors.flatMap(e => Object.values(e.constraints))
    }

    if (isGraphQLError(error)) {
      return error.graphQLErrors.flatMap(e =>
        e.validationErrors.flatMap(ve => Object.values(ve.constraints))
      )
    }

    return ['Please try again or contact support@120water.com']
  }, [error, isError])

  return useMemo(() => ({ errors, onSubmit }), [errors, onSubmit])
}
