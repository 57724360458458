import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { PWSSample } from 'src/types/Sample'
import {
  fetchWeeklyStatsDatasetsRequest,
  fetchWeeklyStatsDatasetsSuccess
} from '../actions/weeklyStats'

export type WeeklyStatsDatasetNames =
  | 'analyzedSamples'
  | 'exceedances'
  | 'orderedSamples'

export interface WeeklyExceedances {
  exceedancesCount: number
  key: 'exceedances'
  locationsCount: number
  samples: PWSSample[]
}

export interface WeeklySamples {
  key: 'analyzedSamples' | 'orderedSamples'
  locationsCount: number
  samples: PWSSample[]
}

export interface WeeklyStatsDataset {
  data: null | WeeklyExceedances | WeeklySamples
  error: null | string
  isFetched: boolean
  isLoading: boolean
}

export interface WeeklyStatsDatasetsState {
  data: { [key in WeeklyStatsDatasetNames]: WeeklyStatsDataset }
  isFetched: boolean
  isLoading: boolean
}

const getStatsInitialState = (): WeeklyStatsDataset => ({
  data: null,
  error: null,
  isFetched: false,
  isLoading: false
})

const initialState = {
  data: {
    analyzedSamples: getStatsInitialState(),
    exceedances: getStatsInitialState(),
    orderedSamples: getStatsInitialState()
  },
  isFetched: false,
  isLoading: false
}

const reducer = reducerWithInitialState<WeeklyStatsDatasetsState>(initialState)
  .case(fetchWeeklyStatsDatasetsRequest, (state, { datasetNames }) => {
    let newState = { data: {} }

    datasetNames.forEach(ds => {
      newState = {
        ...newState,
        data: {
          [ds]: {
            data: [],
            error: null,
            isLoading: true
          }
        }
      }
    })

    return {
      ...state,
      data: {
        ...state.data,
        ...newState.data
      },
      isLoading: true
    }
  })
  .case(
    fetchWeeklyStatsDatasetsSuccess,
    (state, { datasetNames, datasets }) => {
      let data = {}

      datasetNames.forEach(ds => {
        data = {
          ...data,
          [ds]: {
            data: datasets[ds],
            error: null,
            isLoading: false
          }
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          ...data
        },
        isFetched: true,
        isLoading: false
      }
    }
  )

export default reducer
