import { PWSSamplingEvent } from '@120wateraudit/envirio-components/dist/models'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import SamplingEventActions from '../../actions/samplingEvents'
import { IndexReducerState, initialState } from '../helpers/IndexReducer'

const reducer = reducerWithInitialState<IndexReducerState<PWSSamplingEvent>>(
  initialState
)
  .case(
    SamplingEventActions.createActions.createSuccess,
    (
      state: IndexReducerState<PWSSamplingEvent>,
      { samplingEvent }: { samplingEvent: PWSSamplingEvent }
    ) => {
      const items = state.items.slice()
      items.push(samplingEvent)
      return {
        ...state,
        items
      }
    }
  )
  .case(
    SamplingEventActions.updateActions.updateSuccess,
    (
      state: IndexReducerState<PWSSamplingEvent>,
      { samplingEvent }: { samplingEvent: PWSSamplingEvent }
    ) => {
      return {
        ...state,
        items: state.items.map(item => {
          return item.id === samplingEvent.id ? samplingEvent : item
        })
      }
    }
  )

export default reducer
