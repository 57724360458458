import { Button } from '@120wateraudit/envirio-components'
import React, { FC } from 'react'
import styled from 'styled-components'

import { ReactComponent as Check } from 'src/assets/images/Check.svg'
import {
  ConfirmNumberOfSamples,
  ModalButtonWrapper,
  ModalFooterWrapper,
  ModalHeader,
  ModalWrapper
} from './shared'

interface Props {
  selectedSamples: unknown[]
  onCancel(): void
  onComplete(): void
}

const CompletedModal: FC<Props> = ({ onComplete, selectedSamples }) => {
  return (
    <>
      <ModalWrapper>
        <Check />
        <ModalHeader>Completed</ModalHeader>
        <ConfirmMoveSamplesWrapper>
          <ConfirmNumberOfSamples>
            Your <b>{selectedSamples.length}</b> sample
            {selectedSamples.length === 1 ? ' is ' : 's are '}
            complete.
          </ConfirmNumberOfSamples>
        </ConfirmMoveSamplesWrapper>
      </ModalWrapper>
      <ModalFooterWrapper>
        <ModalButtonWrapper>
          <Button onClick={onComplete} variant="primary">
            Close
          </Button>
        </ModalButtonWrapper>
      </ModalFooterWrapper>
    </>
  )
}

const ConfirmMoveSamplesWrapper = styled.div`
  display: block;
  text-align: center;
`

export default CompletedModal
