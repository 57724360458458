import createIndexReducer from './helpers/IndexReducer'
import actions from '../actions/protocols'

const reducer = createIndexReducer({
  collectionName: 'protocols',
  entityName: 'protocol',
  fetchAction: actions.collectionActions.fetchRequest,
  fetchSuccessAction: actions.collectionActions.fetchSuccess,
  fetchFailureAction: actions.collectionActions.fetchFailure,
  createFailureAction: actions.createActions.createFailure,
  updateSuccessAction: actions.updateActions.updateSuccess,
  updateFailureAction: actions.updateActions.updateFailure,
  unloadAction: actions.collectionActions.unload,
  errorClearAction: actions.errorActions.clear
})

export default reducer
