import actionCreatorFactory from 'typescript-fsa'

const action = actionCreatorFactory()

export const addBatchDownload = action<{
  batchId: number
}>('ADD_BATCH_DOWNLOAD')
export const removeBatchDownload = action<{
  batchId: number | undefined
}>('REMOVE_BATCH_DOWNLOAD')
