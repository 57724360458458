import {
  colors,
  fontSize,
  spacing,
  Wizard
} from '@120wateraudit/envirio-components'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'

import { AWS_BASE, downloadDocument } from 'src/API'
import { ReactComponent as DotsUploadIcon } from 'src/assets/images/Dots-upload.svg'
import { OptionCard } from './BulkImportOptionsCard'
import { StepHeader } from 'src/components/StepHeader'
import { useAccount } from 'src/router/UserProvider'
import { BatchType, BulkImportType } from 'src/modules/FileProcessor/dataAccess'
import { pushRoute } from 'src/utils/navigation'

import { Flags, useConfig, useFeatures } from 'src/hooks'
import { titleCase } from 'src/utils/titleCase'
import { downloadingToast } from 'src/utils/toast'
import ExternalLink from 'src/components/ExternalLink'

const showNewTemplates = (templateType: BulkImportType): boolean => {
  switch (templateType) {
    case BulkImportType.ServiceLines:
    case BulkImportType.Combined:
    case BulkImportType.Locations:
    case BulkImportType.Contacts:
      return true
    default:
      return false
  }
}

const getImportTemplate = ({
  accountId,
  templateType,
  batchType
}: {
  accountId: number
  templateType: BulkImportType
  batchType: BatchType
}) => {
  let url = `/pws/rest/accounts/${accountId}/import-batch/template/${templateType
    .toLowerCase()
    .toString()}`
  let contentType = 'application/octet-stream'
  let extension = 'csv'

  if (showNewTemplates(templateType)) {
    url = `/file-processor/templates/${templateType
      .toLowerCase()
      .toString()}/${batchType.toString()}`

    contentType = 'text/csv'
  }

  if (
    templateType === BulkImportType.CombinedIN ||
    templateType === BulkImportType.CombinedGA ||
    templateType === BulkImportType.CombinedDE ||
    templateType === BulkImportType.CombinedAZ ||
    templateType === BulkImportType.CombinedRI ||
    templateType === BulkImportType.CombinedOR
  ) {
    url = `/file-processor/templates/${templateType
      .toLowerCase()
      .toString()}/xlsx`

    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    extension = 'xlsx'
  }

  const dateSuffix = moment().format('YYYYMMDD')
  const filePrefix = `${templateType}${batchType}Template`

  return downloadDocument(
    url,
    `${filePrefix}_${dateSuffix}.${extension}`,
    contentType
  )
}

interface Props {
  batchType: BatchType
  selectedType: BulkImportType
  onClose: () => void
}

const ImportWizard = ({ batchType, onClose, selectedType }: Props) => {
  const { id: accountId, primacyCode } = useAccount()
  const { accountHasCombinedLocationServiceLineImportsFeature } = useFeatures()
  const [modalHeading, setModalHeading] = useState<string>('')
  const [subtitleMessage, setSubTitleMessage] = useState<JSX.Element>()
  const [selectedOption, setSelectedOption] = useState<BulkImportType>()

  const isServiceLines = selectedType === BulkImportType.ServiceLines
  const CombinedImportsFeatureFlag = useConfig(
    Flags.CombinedLocationServiceLineImports
  )
  const useCombinedImports =
    isServiceLines &&
    accountHasCombinedLocationServiceLineImportsFeature &&
    CombinedImportsFeatureFlag

  useEffect(() => {
    switch (batchType) {
      case BatchType.Import:
        setModalHeading(`Import ${titleCase(selectedType)}`)
        setSubTitleMessage(
          <div>
            Select Upload Template or{' '}
            <ExternalLink
              to={{ pathname: `${AWS_BASE}/file-processor/documentation` }}>
              view template documentation
            </ExternalLink>
          </div>
        )
        break
      case BatchType.Enrich:
        setModalHeading(
          `Enrich ${titleCase(
            selectedType
          )} before uploading to the 120Water platform`
        )
        setSubTitleMessage(
          <span>Enrich your ${titleCase(selectedType)} in bulk.</span>
        )
        break
    }
  }, [batchType, selectedType])

  const getOptions = (importType: BulkImportType) => {
    // always add record type option
    const options = [
      {
        label: `${titleCase(importType)}`,
        text: `Import template for creating or updating ${titleCase(
          importType
        )} records`,
        importType: importType
      }
    ]
    if (importType === BulkImportType.ServiceLines && useCombinedImports) {
      // add combined template
      options.push({
        label: 'Locations & Service Lines',
        text: 'Import template for creating or updating location and service line records',
        importType: BulkImportType.Combined
      })
      // add IN primacy agency file if available
      if (primacyCode === 'IN') {
        options.push({
          label: 'Indiana Inventory',
          text: 'IDEM Inventory template for creating or updating location and service line records',
          importType: BulkImportType.CombinedIN
        })
      } else if (primacyCode === 'GA') {
        options.push({
          label: 'Georgia Inventory',
          text: 'GA EPD Inventory template for creating or updating location and service line records',
          importType: BulkImportType.CombinedGA
        })
      } else if (primacyCode === 'DE') {
        options.push({
          label: 'Delaware Inventory',
          text: 'DE Inventory template for creating or updating location and service line records',
          importType: BulkImportType.CombinedDE
        })
      } else if (primacyCode === 'AZ') {
        options.push({
          label: 'Arizona Inventory',
          text: 'AZ Inventory template for creating or updating location and service line records',
          importType: BulkImportType.CombinedAZ
        })
      } else if (primacyCode === 'RI') {
        options.push({
          label: 'Rhode Island Inventory',
          text: 'RI Inventory template for creating or updating location and service line records',
          importType: BulkImportType.CombinedRI
        })
      } else if (primacyCode === 'OR') {
        options.push({
          label: 'Oregon Inventory',
          text: 'Oregon Inventory template for creating or updating location and service line records',
          importType: BulkImportType.CombinedOR
        })
      }
    }

    return options
  }

  const onOptionSelected = (option: BulkImportType) => {
    setSelectedOption(option)
  }

  const onImport = async () => {
    switch (selectedOption) {
      case BulkImportType.Assets:
        onClose()
        pushRoute('/inventory-import')
        break
      case BulkImportType.Contacts:
        onClose()
        pushRoute(`/imports/contacts/${batchType.toLowerCase()}`)
        break
      case BulkImportType.Locations:
        onClose()
        pushRoute(`/imports/locations/${batchType.toLowerCase()}`)
        break
      case BulkImportType.ServiceLines:
        onClose()
        pushRoute(`/imports/servicelines/${batchType.toLowerCase()}`)
        break
      case BulkImportType.Combined:
        onClose()
        pushRoute(`/imports/combined/${batchType.toLowerCase()}`)
        break
      case BulkImportType.CombinedIN:
        onClose()
        pushRoute(`/imports/combinedIN/${batchType.toLowerCase()}`)
        break
      case BulkImportType.CombinedGA:
        onClose()
        pushRoute(`/imports/combinedGA/${batchType.toLowerCase()}`)
        break
      case BulkImportType.CombinedDE:
        onClose()
        pushRoute(`/imports/combinedDE/${batchType.toLowerCase()}`)
        break
      case BulkImportType.CombinedAZ:
        onClose()
        pushRoute(`/imports/combinedAZ/${batchType.toLowerCase()}`)
        break
      case BulkImportType.CombinedRI:
        onClose()
        pushRoute(`/imports/combinedRI/${batchType.toLowerCase()}`)
        break
      case BulkImportType.CombinedOR:
        onClose()
        pushRoute(`/imports/combinedOR/${batchType.toLowerCase()}`)
        break
    }
  }

  const onDownloadTemplate = async (templateType: BulkImportType) => {
    downloadingToast(
      "Your data template will begin downloading momentarily. Once you've transferred your data to the template, return here to upload the file."
    )
    await getImportTemplate({
      accountId,
      templateType: templateType,
      batchType: batchType
    })
  }

  const options = getOptions(selectedType)

  return (
    <Wizard
      completeButtonText={'Upload File'}
      disableNext={() => !selectedOption}
      hideTimeline
      onCancel={onClose}
      onCompleted={onImport}
      steps={[
        {
          title: modalHeading,
          render: () => (
            <>
              <StepHeader text={modalHeading} Icon={DotsUploadIcon} />
              <StepWrapper>
                <CountLabel>{subtitleMessage}</CountLabel>
                <div>
                  {options.map((option, i) => {
                    const { label, text, importType } = option
                    return (
                      <OptionCard
                        key={`OptionCard${i}`}
                        label={label}
                        text={text}
                        isSelected={selectedOption === importType}
                        onClick={() => onOptionSelected(importType)}
                        onDownloadClick={() => onDownloadTemplate(importType)}
                      />
                    )
                  })}
                </div>
              </StepWrapper>
            </>
          )
        }
      ]}
      style={{
        wrapper: { border: 'none' },
        footer: {
          background: 'white',
          justifyContent: 'center'
        }
      }}
    />
  )
}

export default memo(ImportWizard)

const StepWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const CountLabel = styled.div`
  color: ${colors.black50};
  padding-bottom: ${spacing.tiny};
  font-size: ${fontSize.body};
`
