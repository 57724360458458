import {
  colors,
  fontSize,
  lineHeight,
  spacing
} from '@120wateraudit/envirio-components'
import React, { FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Container as SUIRContainer } from 'semantic-ui-react'
import styled from 'styled-components'

import Radio from 'src/components/Forms/Radio'
import { Kit } from '../dataAccess'

type KitProps = FieldRenderProps<Kit>

const KitInput: FC<KitProps> = ({ input, kit }) => {
  const selected = input.value.id === kit.id
  const onClick = () => {
    input.onChange(selected ? undefined : kit)
    input.onBlur()
  }
  return (
    <KitWrapper>
      <CheckableWrapper onClick={onClick}>
        <Radio checked={selected} size="large" />
        <p>{kit.name}</p>
      </CheckableWrapper>
      <DetailsWrapper>
        <QuantityLabelWrapper>Number of Samples per Kit</QuantityLabelWrapper>
        <QuantityValueWrapper>{kit.sampleQuantity}</QuantityValueWrapper>
      </DetailsWrapper>
    </KitWrapper>
  )
}

interface Props {
  kits: Kit[]
}

const KitsForm = ({ kits }: Props): JSX.Element => {
  return (
    <Container>
      {kits
        .map(kit => (
          <Field<Kit> component={KitInput} key={kit.id} kit={kit} name="kit" />
        ))}
    </Container>
  )
}

const Container = styled(SUIRContainer)`
  &&& {
    max-width: 500px !important;
  }
`

const KitWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacing.small};
  & p {
    font-size: ${fontSize.body};
    line-height: 1.714285714;
  }
`

const CheckableWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  & > :first-child {
    margin-right: 4px;
  }
`

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const QuantityLabelWrapper = styled.div`
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption};
  font-weight: 700;
  color: ${colors.black50};
`

const QuantityValueWrapper = styled.div`
  .definitionQuantity > input {
    padding: 8px !important;
    font-size: ${fontSize.caption} !important;
    line-height: ${lineHeight.caption} !important;
  }
`

export default KitsForm
