import gql from 'graphql-tag'
import * as React from 'react'
import { Mutation, Query } from '@apollo/client/react/components'
import { MutationFunction, ApolloError } from '@apollo/client'
import { downloadKitResultLetters } from 'src/API'
import { pwsClient } from 'src/apollo-clients'
import { RESULTS_LETTER_BY_TEMPLATE_TYPE } from 'src/containers/Communications/data-access'

interface Props {
  accountId: number
  isCertifiedMail?: boolean
  isDownload?: boolean
  markCompleteOnResultLetterSent: boolean
  kitIds: number[]
  children: (props: RenderProps) => null | JSX.Element
}

interface State {
  downloadError: any
  downloadLoading: boolean
}

export type SendKitResultsMutation = MutationFunction<
  any,
  {
    accountId: number
    kitIds: number[]
    isDownload?: boolean
    isCertifiedMail?: boolean
    markCompleteOnResultLetterSent: boolean
  }
>

interface RenderProps extends Pick<State, 'downloadError'> {
  countsError?: ApolloError
  detectCount: number
  exceedanceCount: number
  loading: boolean
  allResultsCount: number
  nonDetectCount: number
  numberOfLetters: number
  sendError?: ApolloError
  sendKitResults: SendKitResultsMutation
  downloadKitResults: () => Promise<void>
}

export default class DataAccess extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      downloadError: undefined,
      downloadLoading: false
    }
  }

  downloadKitResults = async () => {
    const { accountId, kitIds, markCompleteOnResultLetterSent } = this.props

    try {
      this.setState({ downloadLoading: true })
      const result = await downloadKitResultLetters({
        accountId,
        kitIds,
        markCompleteOnResultLetterSent
      })
      return result
    } catch (error) {
      this.setState({ downloadError: error })
      throw error
    } finally {
      this.setState({ downloadLoading: false })
    }
  }

  getCountsFromData = countsData => {
    let numberOfLetters = 0,
      nonDetectCount = 0,
      detectCount = 0,
      exceedanceCount = 0,
      allResultsCount = 0

    if (countsData && countsData.resultLettersByTemplateType) {
      nonDetectCount = countsData.resultLettersByTemplateType.nonDetect || 0
      detectCount = countsData.resultLettersByTemplateType.detect || 0
      exceedanceCount = countsData.resultLettersByTemplateType.exceedance || 0
      allResultsCount = countsData.resultLettersByTemplateType.allResults || 0
      numberOfLetters = nonDetectCount + detectCount + exceedanceCount + allResultsCount
    }

    return { detectCount, exceedanceCount, nonDetectCount, allResultsCount, numberOfLetters }
  }

  render() {
    const { accountId, isDownload, isCertifiedMail, kitIds, markCompleteOnResultLetterSent } = this.props
    const { downloadError, downloadLoading } = this.state

    return (
      <Query
        client={pwsClient}
        query={RESULTS_LETTER_BY_TEMPLATE_TYPE}
        skip={!kitIds || !kitIds.length}
        variables={{
          accountId,
          kitIds,
          isDownload
        }}>
        {result => {
          const {
            data: countsData,
            error: countsError,
            loading: countsLoading
          } = result
          const {
            detectCount,
            exceedanceCount,
            nonDetectCount,
            allResultsCount,
            numberOfLetters
          } = this.getCountsFromData(countsData)

          return (
            <Mutation
              client={pwsClient}
              mutation={SEND_KIT_RESULT}
              variables={{
                accountId,
                kitIds,
                isCertifiedMail,
                markCompleteOnResultLetterSent
              }}>
              {(sendKitResults, data) => {
                const { error: sendError, loading: sendLoading } = data
                return this.props.children({
                  countsError,
                  detectCount,
                  downloadError,
                  downloadKitResults: this.downloadKitResults,
                  exceedanceCount,
                  loading: countsLoading || sendLoading || downloadLoading,
                  allResultsCount,
                  nonDetectCount,
                  numberOfLetters,
                  sendError,
                  sendKitResults
                })
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

const SEND_KIT_RESULT = gql`
  mutation sendKitResult(
    $accountId: Int!
    $kitIds: [Int]!
    $isDownload: Boolean
    $isCertifiedMail: Boolean
    $markCompleteOnResultLetterSent: Boolean
  ) {
    sendKitResult(
      accountId: $accountId
      kitIds: $kitIds
      isDownload: $isDownload
      isCertifiedMail: $isCertifiedMail
      markCompleteOnResultLetterSent: $markCompleteOnResultLetterSent
    )
  }
`
