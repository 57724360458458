import numeral from 'numeral'
import pluralize from 'pluralize'
import React from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
  name?: string
  shownCount: number
  totalCount: number
}

function ItemCount({
  className,
  name = 'Item',
  shownCount,
  totalCount
}: Props): JSX.Element {
  return (
    <Text className={className}>
      Showing {shownCount} of {numeral(totalCount).format('0,0')}{' '}
      {pluralize(name)}
    </Text>
  )
}

const Text = styled.p`
  color: #6b7280;
  font-size: 13px;
  line-height: 1.42857143;
  max-width: 200px;
  && {
    margin: 0;
  }
`

export default ItemCount
