import React from 'react'
import { Field } from 'react-final-form'
import { Container as SUIRContainer, Form } from 'semantic-ui-react'
import styled from 'styled-components'

import Datepicker from 'src/components/Forms/Datepicker'
import Dropdown from 'src/components/Forms/Dropdown'
import TextField from 'src/components/Forms/TextField'
import { Program } from 'src/types/Programs'
import { getReplacementProgramSettingsStats } from 'src/utils/replacementProgramSettings'
import { useGetEventTypesQuery } from '../dataAccess'
import { getEventTypeOptions } from '../hooks'

interface Props {
  isPitcherFilterProgram: boolean
  isReplacementProgram: boolean
  program: Program
  type: 'create' | 'edit'
}

const EventForm = ({
  isPitcherFilterProgram,
  isReplacementProgram,
  program,
  type
}: Props): JSX.Element => {
  const unitName = isPitcherFilterProgram ? 'Pitchers' : 'Samples'

  const { data: eventTypes = [], isLoading: eventTypesLoading } =
    useGetEventTypesQuery(program.programTypeId)

  const { hasBothSamplingTypes, singleSelectedSamplingType } =
    getReplacementProgramSettingsStats(program)

  const eventTypeOptions = getEventTypeOptions(
    eventTypes,
    // When we are editing we just show the type. So don't filter.
    type === 'create' ? hasBothSamplingTypes : true,
    type === 'create' ? singleSelectedSamplingType : undefined
  )

  return (
    <Container>
      <FieldWrapper inline>
        <label>Event Name</label>
        <Field<string>
          component={TextField}
          fluid
          name="name"
          placeholder="Event Name"
        />
      </FieldWrapper>
      <FieldWrapper inline>
        <label>Start Date</label>
        <Field<string>
          component={Datepicker}
          name="startsOn"
          style={{ width: '100%' }}
        />
      </FieldWrapper>
      <FieldWrapper inline>
        <label>End Date</label>
        <Field<string>
          component={Datepicker}
          name="endsOn"
          style={{ width: '100%' }}
        />
      </FieldWrapper>
      {isReplacementProgram && (
        <FieldWrapper inline>
          <label>Event Type</label>
          <Field<string>
            addUndefinedOption={false}
            component={Dropdown}
            disabled={type === 'edit'}
            fluid
            loading={eventTypesLoading}
            name="eventType"
            options={eventTypeOptions}
          />
        </FieldWrapper>
      )}
      {!isReplacementProgram && (
        <FieldWrapper inline>
          <label>Total {unitName} Goal</label>
          <Field<string>
            component={TextField}
            min="0"
            name="totalGoal"
            placeholder={`How many ${unitName} do you need?`}
            step="1"
            type="number"
          />
        </FieldWrapper>
      )}
    </Container>
  )
}

const Container = styled(SUIRContainer)`
  &&& {
    max-width: 500px !important;
  }
`

const FieldWrapper = styled(Form.Field)`
  &&& {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    & > * {
      flex: 1;
    }
  }
`

export default EventForm
