import GenerateCrudSaga from './helpers/CrudSaga'
import actions from '../actions/activityTypes'
import { fetchActivityTypes } from '../API'

const saga = GenerateCrudSaga({
  entityName: 'activityType',
  actions,
  fetchCollectionMethod: fetchActivityTypes,
  pagedCollection: false
})

export default saga
