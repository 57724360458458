import { Loader } from '@120wateraudit/envirio-components'
import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import { ReactComponent as Logo } from 'src/assets/images/Logo.svg'
import Footer from 'src/components/Footer'

const AppSkeleton: FC = ({ children }) => {
  return (
    <Wrapper>
      <SkeletonNavigation />
      <ChildrenContainer>{children || <Loader />}</ChildrenContainer>
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'header' 'main' 'footer';
`

const SkeletonNavigation = () => {
  return (
    <Nav>
      <Container>
        <Menu fluid text>
          <Menu.Item>
            <NavLink to="/login">
              <Logo />
            </NavLink>
          </Menu.Item>
        </Menu>
      </Container>
    </Nav>
  )
}

const Nav = styled.nav`
  grid-area: header;
  max-height: 56px;
  background: white;
  display: flex;
  align-items: center;
`

const ChildrenContainer = styled.section`
  grid-area: main;
  background-color: #f6f7f7;
`

export default AppSkeleton
