import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import React, { FC, ReactNode, memo } from 'react'

import { featuresClient } from 'src/apollo-clients'
import {
  FeaturesContext,
  FeaturesContextOptions
} from 'src/context/FeaturesContext'
import AppSkeleton from 'src/router/Skeleton'
import { useCurrentUser } from 'src/router/UserProvider'
import { Feature } from 'src/types/Feature'

export const FeaturesProvider: FC<Props> = memo(props => {
  const { account } = useCurrentUser()
  const { data: featuresData, loading: featuresLoading } = useQuery<
    FeaturesData,
    FeaturesVariables
  >(FEATURES, {
    client: featuresClient,
    skip: !account,
    variables: {
      accountId: account?.id || 0
    }
  })

  if (featuresLoading || !featuresData) {
    return <AppSkeleton />
  }

  const findFeature = (code: string) =>
    Boolean(featuresData.features.find(f => f.code === code))

  const values: FeaturesContextOptions = {
    accountHas120EnvelopeFeature: findFeature('COMM:ENV:120'),
    accountHasActivitiesFeature: findFeature('LOC:ACT'),
    accountHasAssetsFeature: findFeature('ASSETS'),
    accountHasCertifiedMailFeature: findFeature('COMM:CERTIFIED_MAIL'),
    accountHasCombinedLocationServiceLineImportsFeature:
      findFeature('IMPT:LOC_SERV'),
    accountHasCommsAutomatedMailingFeature: findFeature('COMM:ALM'),
    accountHasCommsFeature: findFeature('COMMS'),
    accountHasContactImportsFeature: findFeature('IMPT:CONT'),
    accountHasContactsFeature: findFeature('CONTACTS'),
    accountHasCustomEnvelopeFeature: findFeature('COMM:ENV:CUSTOM'),
    accountHasCustomFieldsFeature: findFeature('CF'),
    accountHasLSLProbabilityFeature: findFeature('LSL:PROB'),
    accountHasLocationImportsFeature: findFeature('IMPT:LOC'),
    accountHasLocationEnrichmentFeature: findFeature('ENR:LOC'),
    accountHasReportsFeature: findFeature('R'),
    accountHasServiceLineImportsFeature: findFeature('IMPT:SERV'),
    accountHasServiceLinesFeature: findFeature('LOC:LSL'),
    features: featuresData.features,
    hasStateReporting: findFeature('STATERPT')
  }

  return <FeaturesContext.Provider value={values} {...props} />
})

interface Props {
  children: ReactNode
}

const FEATURES = gql`
  query getFeaturesByAccount($accountId: Int!) {
    features: getFeaturesByAccount(accountId: $accountId) {
      code
      name
    }
  }
`

interface FeaturesData {
  features: Feature[]
}

interface FeaturesVariables {
  accountId: number
}
