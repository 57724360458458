import { ProductCategory } from '@120wateraudit/envirio-components/dist/models'

import {
  Maybe,
  PaginatedResponse,
  Tags,
  buildParameters,
  pwsApi
} from 'src/services'
import { AccountAnalyte } from 'src/types/Analyte'
import { SamplingEvent } from 'src/types/SamplingEvents'

export interface SkuProgramType {
  id: number
  programTypeId: number
  skuId: number
}

export interface Kit {
  id: number
  name: string
  productCategory?: ProductCategory
  sampleQuantity: number
  sku: string
  skuProgramTypes?: SkuProgramType[]
}

export interface EventType {
  id: number
  name: string
  programTypeId: number
}

type EventUpdate = Omit<Partial<SamplingEvent>, 'protocolDefinitions'> &
  Pick<SamplingEvent, 'id'>

type NewEvent = Omit<Partial<SamplingEvent>, 'id'> & {
  eventType?: string
  protocolDefinitions: { skuId: number; willDeliver: false }[]
}

const ONE_HOUR = 60 * 60

const fetchDatasets = async (
  eventId: number,
  names: string[],
  fetch
): Promise<Record<string, unknown>> => {
  const datasets: Record<string, unknown> = {}
  await Promise.all(
    names.map(async name => {
      switch (name) {
        case 'ExceedanceZips': {
          const response = await fetch({
            url: `/samplingevents/${eventId}/exceedancezips`
          })
          datasets[name] = response.data
          break
        }
        case 'KitLocations': {
          const response = await fetch({
            url: `/samplingevents/${eventId}/kitlocations`
          })
          datasets[name] = response.data
          break
        }
        case 'LSLLocations': {
          const response = await fetch({
            url: `/samplingeevents/${eventId}/lsllocations`
          })
          datasets[name] = response.data
          break
        }
        case 'OrderVolumeByMonth': {
          const response = await fetch({
            url: `/samplingevents/${eventId}/ordervolumebymonth`
          })
          datasets[name] = response.data
          break
        }
        case 'OrderZips': {
          const response = await fetch({
            url: `/samplingevents/${eventId}/orderzips`
          })
          datasets[name] = response.data
          break
        }
        case 'ReplacementsByStatus': {
          const response = await fetch({
            url: `/samplingevents/${eventId}/replacementsbystatus`
          })
          datasets[name] = response.data
          break
        }
        case 'SamplesByMonth': {
          const response = await fetch({
            url: `/samplingevents/${eventId}/samplesbymonth`
          })
          datasets[name] = response.data
          break
        }
        case 'ServiceLines': {
          const response = await fetch({
            url: `/samplingevents/${eventId}/servicelines`
          })
          datasets[name] = response.data
          break
        }
        case 'Totals': {
          const response = await fetch({
            url: `/samplingevents/${eventId}/totals`
          })
          datasets[name] = response?.data?.[0]
          break
        }
        default:
          throw new Error('Invalid Event dataset name')
      }
    })
  )
  return datasets
}

const eventsApi = pwsApi.injectEndpoints({
  endpoints: builder => ({
    createEvent: builder.mutation<SamplingEvent, NewEvent>({
      invalidatesTags: [Tags.Events],
      query: event => ({
        data: event,
        method: 'post',
        url: '/samplingevents'
      })
    }),
    getAccountAnalytes: builder.query<AccountAnalyte[], void>({
      query: () => ({
        overrideBaseUrl:
          '/platform/account-management/rest/account-analytes/accountId/',
        url: ''
      })
    }),
    getDatasets: builder.query<
      Record<string, unknown>,
      { datasets: string[]; eventId: number }
    >({
      queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
        const { datasets: datasetNames, eventId } = arg
        const datasets = await fetchDatasets(eventId, datasetNames, baseQuery)
        return { data: datasets }
      }
    }),
    getEvent: builder.query<SamplingEvent, number>({
      providesTags: (r, e, id) => [{ id, type: Tags.Events }],
      query: id => ({ url: `/samplingevents/${id}` })
    }),
    getEventTypes: builder.query<EventType[], Maybe<number>>({
      keepUnusedDataFor: ONE_HOUR,
      query: programTypeId => ({
        overrideBaseUrl: '/pws/accounts/',
        url: `/types${programTypeId ? `/${programTypeId}` : ''}`
      }),
      transformResponse: (original: { data: EventType[] }) => original.data
    }),
    getEventsInProgram: builder.query<PaginatedResponse<SamplingEvent>, number>(
      {
        providesTags: [Tags.Events],
        query: programId => ({
          url: `/samplingEvents?${buildParameters({
            activeFilters: { programId },
            page: 1,
            // We just show a big list of these and no one program has more than a handful
            pageSize: 1000
          })}`
        })
      }
    ),
    getSkus: builder.query<Kit[], Maybe<number>>({
      keepUnusedDataFor: ONE_HOUR,
      query: programTypeId => ({
        url: `/skus${programTypeId ? `?programTypeId=${programTypeId}` : ''}`
      }),
      transformResponse: (original: { items: Kit[] }) => original.items
    }),
    updateEvent: builder.mutation<SamplingEvent, EventUpdate>({
      invalidatesTags: [Tags.Events],
      query: event => ({
        data: event,
        method: 'put',
        url: `/samplingevents/${event.id}`
      })
    })
  })
})

export const {
  useCreateEventMutation,
  useGetAccountAnalytesQuery,
  useGetDatasetsQuery,
  useGetEventQuery,
  useGetEventTypesQuery,
  useGetEventsInProgramQuery,
  useGetSkusQuery,
  useUpdateEventMutation
} = eventsApi
