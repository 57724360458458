import { PWSProgram } from '@120wateraudit/envirio-components/dist/models'
import React, { FC, useState } from 'react'

import { hardDelete } from 'src/API'
import ConfirmationModal from 'src/modules/Modal/ConfirmationModal'
import { Location } from 'src/types/Location'

interface Props {
  accountId: number
  locationIds: number[]
  program: PWSProgram
  selectedLocations: Location[]
  onClose: () => void
  onCompletion: () => void
}

const RemoveProgramModal: FC<Props> = ({
  accountId,
  onClose,
  onCompletion,
  program,
  selectedLocations
}) => {
  const [removalError, setRemovalError] = useState<string>()
  const locationId = selectedLocations[0].id
  const removeProgramURL = `/pws/rest/accounts/${accountId}/programLocations/program/${program.id}/locations/`

  const removeLocationProgram = async (url: string) => {
    try {
      if (locationId) {
        await hardDelete(`${url}${locationId}`)
        onCompletion()
        onClose()
      }
    } catch (error) {
      if (
        error.response.data.message ===
        'Location cannot be removed due to having linked samples.'
      ) {
        setRemovalError(
          `This location cannot be removed from the program because samples have already been created.`
        )
      } else if (
        error.response.data.message ===
        'Location cannot be removed due to having linked kits.'
      ) {
        setRemovalError(
          `This location cannot be removed from the program because kits have already been created.`
        )
      } else {
        setRemovalError(
          'There seems to be an error, please try again or contact support for assistance.'
        )
      }
    }
  }

  return (
    <ConfirmationModal
      body={`This will remove this location from ${program.name} Program.`}
      disabled={!!removalError}
      error={removalError}
      onClose={onClose}
      onConfirm={() => removeLocationProgram(removeProgramURL)}
      title={!removalError ? 'Remove from Program' : 'Oops...'}
    />
  )
}

export default RemoveProgramModal
