import React, { DragEvent, useCallback } from 'react'
import { DropzoneProps, ImageFile } from 'react-dropzone'
import { Modal } from 'semantic-ui-react'

import FileUpload from 'src/components/FileUpload'
import {
  useCreateAttachmentsMutation,
  useUploadAttachmentsToEntitiesMutation
} from 'src/services/attachments-api'
import { toastError, toastSuccess } from 'src/utils/toast'

interface Props {
  entityIds?: string
  multiple?: boolean
  relatedEntityId: number
  relatedEntityType: 'inventory' | 'locations' | 'pwsSamples' | 'assets' | 'submissionTemplates'
  uploadPrompt?: string
  onClose(): void
  onCompletion?(): void
}

const UploadAttachmentsModal = ({
  entityIds,
  multiple = false,
  onClose,
  onCompletion,
  relatedEntityId,
  relatedEntityType,
  uploadPrompt
}: Props): JSX.Element => {
  const [createAttachments, { isLoading: isUploading }] =
    useCreateAttachmentsMutation()
  const [createAttachmentsForEntities, { isLoading: isUploadingToEntities }] =
    useUploadAttachmentsToEntitiesMutation()

  const uploadAttachments = useCallback(
    async (images: File[]) => {
      try {
        await createAttachments({
          images,
          relatedEntityId,
          relatedEntityType
        }).unwrap()
        toastSuccess('Successfully uploaded file')
      } catch {
        toastError('An error occurred. The Attachment could not be uploaded')
      }
    },
    [createAttachments, relatedEntityType, relatedEntityId]
  ) as DropzoneProps['onDrop']

  const uploadAttachmentsToEntities = useCallback(
    async (images: File[]) => {
      try {
        await createAttachmentsForEntities({
          entityIds,
          images,
          relatedEntityType
        }).unwrap()
        toastSuccess('Successfully uploaded file')
      } catch {
        toastError('An error occurred. The Attachment could not be uploaded')
      }
    },
    [createAttachmentsForEntities, relatedEntityType, entityIds]
  ) as DropzoneProps['onDrop']

  const uploadFile = (
    images: ImageFile[],
    rejected: ImageFile[],
    event: DragEvent<HTMLDivElement>
  ) => {
    if (uploadAttachments && relatedEntityId) {
      uploadAttachments(images, rejected, event)
    } else if (uploadAttachmentsToEntities && entityIds) {
      uploadAttachmentsToEntities(images, rejected, event)
    }

    onClose()

    if (onCompletion) onCompletion()
  }

  return (
    <>
      <Modal.Header>Upload Attachment Files</Modal.Header>
      <Modal.Content>
        <FileUpload
          headerText={uploadPrompt}
          multiple={multiple}
          uploadFile={uploadFile}
          uploading={isUploading || isUploadingToEntities}
        />
      </Modal.Content>
    </>
  )
}

export default UploadAttachmentsModal
