import React, { useContext } from 'react'
import styled from 'styled-components'
import { Radio, Label } from 'semantic-ui-react'

import { Row } from 'src/components/Layout'
import Filters from '../Filters'
import TableContext from '../context'
import { Action, RowDataWithId, SelectionMode } from '../types'
import { ExportButton } from './ExportButton'
import SelectionActions, {
  Props as SelectionActionProps
} from './SelectionActions'
import TopRow, { Props as TopRowProps } from './TopRow'
import { AQUA_BLUE } from 'src/constants/colors'

function hasSelectedItems(
  selectionMode?: SelectionMode,
  selected?: RowDataWithId | RowDataWithId[]
): boolean {
  if (selectionMode === 'single') {
    return !!selected
  }

  if (selectionMode === 'multi') {
    return Array.isArray(selected) && !!selected.length
  }

  return false
}

const BottomRow = ({
  actions,
  isSelectAllOn,
  exportAction,
  exportButton,
  hasSelectAllToggle,
  onClearSelection,
  toggleSelectAll,
  selected,
  selectionMode,
  tableIsEmpty,
  totalCount
}: Partial<SelectionActionProps>): null | JSX.Element => {
  const OverrideExportButton = exportButton
  if (!actions?.length && !exportAction && !exportButton) {
    return null
  }

  const showExportButton = exportAction && !tableIsEmpty
  const hasOverride = !!OverrideExportButton
  const hasSelection = hasSelectedItems(selectionMode, selected)
  let selectionCount = Array.isArray(selected) ? selected.length : 1

  if (totalCount === 0) {
    selectionCount = totalCount
  }
  if (totalCount && isSelectAllOn) {
    selectionCount = totalCount
  }

  return (
    <Row style={{ justifyContent: 'space-between' }}>
      {(hasSelection || selectionCount === 0) && (
        <SelectionCount
          style={{
            minWidth: hasSelectAllToggle ? '115px' : 'none',
            marginRight: hasSelectAllToggle ? '0px' : '5px',
            padding: 'none',
            fontSize: '1.1rem'
          }}
          horizontal
          size="large">
          {selectionCount} Selected
        </SelectionCount>
      )}
      {hasSelectAllToggle && toggleSelectAll && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Radio
            onChange={() => {
              toggleSelectAll()
              if (isSelectAllOn) {
                onClearSelection && onClearSelection()
              }
            }}
            toggle
          />
          <div
            style={{
              color: AQUA_BLUE,
              marginLeft: '10px',
              fontWeight: '700',
              fontSize: '1.1rem'
            }}>
            {' '}
            Select All
          </div>
        </div>
      )}
      <SelectionActions
        actions={actions}
        hasSelectAllToggle={hasSelectAllToggle}
        selected={selected}
        selectionMode={selectionMode}
        tableIsEmpty={tableIsEmpty || false}
      />
      {showExportButton && hasOverride && (
        <OverrideExportButton {...exportAction} />
      )}
      {showExportButton && !hasOverride && <ExportButton {...exportAction} />}
    </Row>
  )
}

interface ToolbarProps extends TopRowProps {
  actions?: Action[]
  allowHiddenColumns: boolean
  isSelectAllOn?: boolean
  exportAction?: Action
  filterable?: boolean
  hasSelectAllToggle?: boolean
  onClearSelection?(): void
  onSelectAll?: any
  orderable: boolean
  pageSize: number
  selected?: RowDataWithId | RowDataWithId[]
  selectionMode?: SelectionMode
  showTopCount?: boolean
  tableIsEmpty: boolean
  totalCount: number
}

const Toolbar = (props: ToolbarProps): null | JSX.Element => {
  const { savedViewType } = useContext(TableContext)
  if (
    !props.actions?.length &&
    !props.exportAction &&
    !props.searchable &&
    !props.filterable &&
    !props.orderable &&
    !props.allowHiddenColumns &&
    !savedViewType &&
    !props.showTopCount
  ) {
    return null
  }

  return (
    <Container>
      <TopRow {...props} />
      {props.filterable && <Filters />}
      <BottomRow {...props} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 14px 14px 0 14px;
`

const SelectionCount = styled(Label)`
  &&& {
    text-align: left;
    padding: 0.5833em 0px;
    background-color: white;
    font-weight: 500;
    color: #6b7280;
  }
`

export default Toolbar
