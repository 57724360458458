import * as Yup from 'yup'

const historicalRecordsReviewSchema = Yup.object().shape(
    {
        inventoryMethodsPart1PreviousMaterialsEvaluation: Yup.string().required(),
        inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes: Yup.string().required(),
        inventoryMethodsPart1WaterSystemRecords: Yup.string().required(),
        inventoryMethodsPart1DistributionSystemInspectionsAndRecords: Yup.string().required()
    }
)

const inventoryMethodsPart2Schema = Yup.object().shape(
    {
        inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices: Yup.array()
            .min(1, {
                inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices: 'Required'
            })
            .required({
                inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices: 'Required'
            }),
        inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation: Yup.string()
            .when(
                [
                    'inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices'
                ],
                {
                    is: inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices =>
                        inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices &&
                        inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices.includes(
                            'Other'
                        ),
                    then: () =>
                        Yup.string().required({
                            inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation: 'Required'
                        })
                }
            ),
        inventoryMethodsPart2Q2StandardProceduresYesNo: Yup.string().required(),
        inventoryMethodsPart2Q2StandardProceduresOtherExplanation: Yup.string()
            .when(
                [
                    'inventoryMethodsPart2Q2StandardProceduresYesNo'
                ],
                {
                    is: inventoryMethodsPart2Q2StandardProceduresYesNo =>
                        inventoryMethodsPart2Q2StandardProceduresYesNo === 'Yes',
                    then: () =>
                        Yup.string().required({
                            inventoryMethodsPart2Q2StandardProceduresOtherExplanation: 'Required'
                        })
                }
            )
    },
    [
        [
            'inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices',
            'inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation',
        ],
        [
            'inventoryMethodsPart2Q2StandardProceduresYesNo',
            'inventoryMethodsPart2Q2StandardProceduresOtherExplanation'
        ]
    ]
)

const inventoryMethodsPart3Schema = Yup.object().shape(
    {
        inventoryMethodsPart3Q1ServiceLineInvestigationChoices: Yup.array()
            .min(1, {
                inventoryMethodsPart3Q1ServiceLineInvestigationChoices: 'Required'
            })
            .required({
                inventoryMethodsPart3Q1ServiceLineInvestigationChoices: 'Required'
            }),
        inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation: Yup.string()
            .when(
                [
                    'inventoryMethodsPart3Q1ServiceLineInvestigationChoices'
                ],
                {
                    is: inventoryMethodsPart3Q1ServiceLineInvestigationChoices =>
                        inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
                        inventoryMethodsPart3Q1ServiceLineInvestigationChoices.includes(
                            'Other'
                        ),
                    then: () =>
                        Yup.string().required({
                            inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation: 'Required'
                        })
                }
            ),
        inventoryMethodsPart3Q2PredictiveModelingExplanation: Yup.string()
            .when(
                [
                    'inventoryMethodsPart3Q1ServiceLineInvestigationChoices'
                ],
                {
                    is: inventoryMethodsPart3Q1ServiceLineInvestigationChoices =>
                        inventoryMethodsPart3Q1ServiceLineInvestigationChoices &&
                        inventoryMethodsPart3Q1ServiceLineInvestigationChoices.includes(
                            'Predictive Modeling'
                        ),
                    then: () =>
                        Yup.string().required({
                            inventoryMethodsPart3Q2PredictiveModelingExplanation: 'Required'
                        })
                }
            )
    },
    [
        [
            'inventoryMethodsPart3Q1ServiceLineInvestigationChoices',
            'inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation'
        ],
        [
            'inventoryMethodsPart3Q1ServiceLineInvestigationChoices',
            'inventoryMethodsPart3Q2PredictiveModelingExplanation'
        ]
    ]
)

const oregonSchema = historicalRecordsReviewSchema
    .concat(inventoryMethodsPart2Schema)
    .concat(inventoryMethodsPart3Schema)
    .concat(inventoryMethodsPart3Schema)

export const validateHistoricalRecordsReviewSchema = async values => validateSchema(historicalRecordsReviewSchema, values)

export const validateInventoryMethodsPart2Schema = async values => validateSchema(inventoryMethodsPart2Schema, values)

export const validateInventoryMethodsPart3Schema = async values => validateSchema(inventoryMethodsPart3Schema, values)

export const validateOregonSchema = async values => validateSchema(oregonSchema, values)

const validateSchema = async (schema: any, values: any) => {
    return schema
        .validate(values, { abortEarly: false })
        .then(() => {
            return undefined
        })
        .catch(err => {
            return Object.assign({}, ...err.errors)
        })
}


