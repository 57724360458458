import React from 'react'
import styled from 'styled-components'

import { Submission } from 'src/types/Submission'

export const SubmitterCell = ({ submittedByName }: Submission): JSX.Element => {
  return (
    <Wrapper>
      <Submitter>{submittedByName}</Submitter>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
`

export const Submitter = styled.div`
  max-width: 180px;
  overflow-wrap: break-word;
`
