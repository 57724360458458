import { put, takeEvery } from 'redux-saga/effects'

import { PWSSample } from 'src/types/Sample'
import {
  getWeeklyAnalyzedSamples,
  getWeeklyExceedances,
  getWeeklyOrderedSamples
} from '../API'
import {
  fetchWeeklyStatsDatasetsFailure,
  fetchWeeklyStatsDatasetsRequest,
  fetchWeeklyStatsDatasetsSuccess
} from '../actions/weeklyStats'
import { WeeklyStatsDatasetNames } from '../reducers/weeklyStats'

interface FetchDatasetOptions {
  payload: {
    accountId: number
    datasetNames: WeeklyStatsDatasetNames[]
  }
  type: string
}

function* fetchDatasetSaga({
  payload: { accountId, datasetNames }
}: FetchDatasetOptions) {
  try {
    const datasets: any = {}

    yield Promise.all(
      datasetNames.map(async datasetName => {
        switch (datasetName) {
          case 'exceedances':
            const exceedances = (await getWeeklyExceedances({
              accountId
            })) as {
              samples: PWSSample[]
              exceedancesCount: number
              locationsCount: number
            }

            datasets[datasetName] = {
              key: datasetName,
              ...exceedances
            }

            return

          case 'orderedSamples':
            const orderedSamples = (await getWeeklyOrderedSamples({
              accountId
            })) as { samples: PWSSample[]; locationsCount: number }

            datasets[datasetName] = {
              key: datasetName,
              ...orderedSamples
            }

            return

          case 'analyzedSamples':
            const analyzedSamples = (await getWeeklyAnalyzedSamples({
              accountId
            })) as { samples: PWSSample[]; locationsCount: number }

            datasets[datasetName] = {
              key: datasetName,
              ...analyzedSamples
            }

            return

          default:
            throw new Error('Invalid weekly stat dataset name')
        }
      })
    )

    yield put(fetchWeeklyStatsDatasetsSuccess({ datasetNames, datasets }))
  } catch (error) {
    yield put(fetchWeeklyStatsDatasetsFailure({ error, datasetNames }))
  }
}

export default function* samplingEventDatasetsSaga() {
  yield takeEvery(fetchWeeklyStatsDatasetsRequest.toString(), fetchDatasetSaga)
}
