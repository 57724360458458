import React, { CSSProperties } from 'react'

type SupportLinkProps = {
  url?: string
  children: React.ReactNode
  style?: CSSProperties
}

export const SupportLink = ({
  url,
  children,
  style
}: SupportLinkProps): JSX.Element => (
  <a
    href={url ? url : 'https://120water.com/support/'}
    rel="noopener noreferrer"
    style={{ ...style, height: '24px' }}
    target="_blank">
    {children}
  </a>
)
