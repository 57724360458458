import * as React from 'react'
import styled from 'styled-components'
import { MetricCircle } from 'src/components/MetricCircle'

interface Props {
  icon: JSX.Element
  color: string
  iconColor: string
  label: string
  value: number
}

export const ConditionCountCard = ({
  icon,
  color,
  iconColor,
  label,
  value
}: Props) => (
  <Wrapper>
    <MetricCircle circleIcon={icon} color={color} iconColor={iconColor} />
    <InfoWrapper>
      <LabelWrapper>{label}</LabelWrapper>
      <ValueWrapper>{value}</ValueWrapper>
    </InfoWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`

const LabelWrapper = styled.div`
  color: rgba(0, 36, 45, 0.75);
  font-size: 0.99rem;
`

const ValueWrapper = styled.div`
  font-size: 1.667rem;
  font-weight: bold;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
