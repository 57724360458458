import { PWSAccount } from '@120wateraudit/envirio-components/dist/models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'src/store'

import { User } from 'src/types/User'
import { getDefaultAccount } from 'src/utils/getDefaultAccount'

type UsersSliceState = { account?: PWSAccount; user?: User }

const INITIAL_STATE: UsersSliceState = { account: undefined, user: undefined }

const slice = createSlice({
  initialState: INITIAL_STATE,
  name: 'users',
  reducers: {
    assignUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
      state.account = getDefaultAccount(action.payload)
      return state
    }
  }
})

export const { assignUser } = slice.actions

export const selectDefaultAccount = (state: RootState) => state.users.account

export default slice.reducer
