import React, { FC } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import styled from 'src/theme'

interface Props {
    title: string
    itemNumber?: number
    isSelected: boolean
    onSelect(): void
}

const ModalTitle:FC<Props> = ({ title, itemNumber, isSelected, onSelect }) => {
    return(
        <TitleWrapper onClick={onSelect}>
        <DataTitle>
          <span>{`${title}${itemNumber ? ` ${itemNumber}` : ''}`}</span>
        </DataTitle>
        <IconWrapper>
          <FontAwesomeIcon icon={isSelected ? faChevronDown : faChevronRight} size="lg"/>
        </IconWrapper>
      </TitleWrapper>
    )
}

export default ModalTitle

const DataTitle = styled.h5`
  margin: 0.5rem !important;
  font-style: italic;
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`